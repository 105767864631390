/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {
    Button,
    DialogContent,
    DialogActions,
    FormControl,
    FormControlLabel,
    Typography,
    InputAdornment,
    RadioGroup,
    Radio,
} from '@mui/material';

import {translateProjectAssembly} from '../../+store/actions/actions';
import {getData as getTree} from '../../+store/reducer/tree';
import {getValidationErrors} from '../../+store/reducer/validation_errors';
import {getData as getProject} from '../../+store/reducer/project';
import {TextValidator} from 'react-material-ui-form-validator';

import MyValidationForm from '../../../components/MyValidationForm';

import {BigNumber} from 'bignumber.js';

class AssemblyTranslate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            translate: 'absolute',
            x: props.assembly.x_calc,
            y: props.assembly.y_calc,
            z: props.assembly.z_calc,
            dx: '0',
            dy: '0',
            dz: '0',
        };

        this.form = React.createRef();

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(field_name, value) {
        const {assembly} = this.props;
        let state = {
            ...this.state,
            [field_name]: value,
        };

        switch (state.translate) {
            case 'absolute':
                state = {
                    ...state,
                    dx: BigNumber.sum(BigNumber(state.x || 0), -BigNumber(assembly.x_calc)).toString(),
                    dy: BigNumber.sum(BigNumber(state.y || 0), -BigNumber(assembly.y_calc)).toString(),
                    dz: BigNumber.sum(BigNumber(state.z || 0), -BigNumber(assembly.z_calc)).toString(),
                };
                break;
            case 'relative':
                state = {
                    ...state,
                    x: BigNumber.sum(BigNumber(assembly.x_calc), BigNumber(state.dx || 0)).toString(),
                    y: BigNumber.sum(BigNumber(assembly.y_calc), BigNumber(state.dy || 0)).toString(),
                    z: BigNumber.sum(BigNumber(assembly.z_calc), BigNumber(state.dz || 0)).toString(),
                };
                break;
        }
        this.setState(state);
    }

    handleSubmit() {
        this.form.current.isFormValid(false)
            .then(is_valid => {
                if (is_valid) {
                    const {onClose, assembly, translateProjectAssembly} = this.props;
                    translateProjectAssembly({
                        project_id: this.props.project_id,
                        assembly_id: this.props.assembly.id,
                        translate: {
                            dx: this.state.dx,
                            dy: this.state.dy,
                            dz: this.state.dz,
                        },
                    });

                }
            });
    }

    // renderInputFields = (field_names, units, ids) => {
    //     const {validation_errors} = this.props;
    //     return field_names.map((prop_name) => (
    //         <TextValidator
    //             id={ids}
    //             key={prop_name}
    //             label={prop_name.toUpperCase()}
    //             value={this.state[prop_name] || ''}
    //             onChange={(event) => this.handleChange(prop_name, event.target.value)}
    //             variant="standard"
    //             error={!!validation_errors[prop_name]}
    //             helperText={validation_errors[prop_name]}
    //             validators={['required', 'isCustomFloat']}
    //             errorMessages={['This field is required!', 'Enter a valid floating point number!']}
    //             InputProps={{
    //                 endAdornment: <InputAdornment position={'end'}>{units}</InputAdornment>,
    //             }}
    //         />
    //     ));
    // };

    render() {
        const {validation_errors, assembly} = this.props;


        return (
            <Fragment>
                <DialogContent dividers>
                    <MyValidationForm
                        ref={this.form}
                        onSubmit={this.handleSubmit}
                        className={'validation-form-sm'}
                    >
                        <RadioGroup row value={translate}
                                    onChange={(event) => this.handleChange('translate', event.target.value)}>
                            <FormControlLabel value={'absolute'} control={<Radio/>} label={'Absolute'}/>
                            <FormControlLabel value={'relative'} control={<Radio/>} label={'Relative'}/>
                        </RadioGroup>
                        <Typography variant={'h6'}>Current position</Typography>
                        <FormControl variant={'standard'}>
                            <TextValidator
                                disabled={true}
                                label={'X'}
                                value={assembly.x_calc}
                                variant={'standard'}
                                InputProps={{
                                    endAdornment: <InputAdornment position={'end'}>mm</InputAdornment>,
                                }}
                            />
                        </FormControl>
                        <FormControl variant={'standard'}>
                            <TextValidator
                                disabled={true}
                                label={'Y'}
                                value={assembly.y_calc}
                                variant={'standard'}
                                InputProps={{
                                    endAdornment: <InputAdornment position={'end'}>mm</InputAdornment>,
                                }}
                            />
                        </FormControl>
                        <FormControl variant={'standard'}>
                            <TextValidator
                                disabled={true}
                                label={'Z'}
                                value={assembly.z_calc}
                                variant={'standard'}
                                InputProps={{
                                    endAdornment: <InputAdornment position={'end'}>mm</InputAdornment>,
                                }}
                            />
                        </FormControl>
                        {
                            translate === 'absolute' &&
                            <>
                                <Typography variant={'h6'}>New position</Typography>
                                {
                                    this.renderInputFields(['x', 'y', 'z'], 'mm', 'location')
                                }
                                <Typography variant={'h6'}>Translation</Typography>
                                <FormControl variant={'standard'}>
                                    <TextValidator
                                        disabled={true}
                                        label={'DX'}
                                        value={dx}
                                        variant={'standard'}
                                        InputProps={{
                                            endAdornment: <InputAdornment position={'end'}>mm</InputAdornment>,
                                        }}
                                    />
                                </FormControl>
                                <FormControl variant={'standard'}>
                                    <TextValidator
                                        disabled={true}
                                        label={'DY'}
                                        value={dy}
                                        variant={'standard'}
                                        InputProps={{
                                            endAdornment: <InputAdornment position={'end'}>mm</InputAdornment>,
                                        }}
                                    />
                                </FormControl>
                                <FormControl variant={'standard'}>
                                    <TextValidator
                                        disabled={true}
                                        label={'DZ'}
                                        value={dz}
                                        variant={'standard'}
                                        InputProps={{
                                            endAdornment: <InputAdornment position={'end'}>mm</InputAdornment>,
                                        }}
                                    />
                                </FormControl>
                            </>
                        }
                        {
                            translate === 'relative' &&
                            <>
                                <Typography variant={'h6'}>New position</Typography>
                                <FormControl variant={'standard'}>
                                    <TextValidator
                                        disabled={true}
                                        className={classes.root}
                                        label={'X'}
                                        value={x}
                                        variant={'standard'}
                                        InputProps={{
                                            endAdornment: <InputAdornment position={'end'}>mm</InputAdornment>,
                                        }}
                                    />
                                </FormControl>
                                <FormControl variant={'standard'}>
                                    <TextValidator
                                        disabled={true}
                                        label={'Y'}
                                        value={y}
                                        variant={'standard'}
                                        InputProps={{
                                            endAdornment: <InputAdornment position={'end'}>mm</InputAdornment>,
                                        }}
                                    />
                                </FormControl>
                                <FormControl variant={'standard'}>
                                    <TextValidator
                                        disabled={true}
                                        className={classes.root}
                                        label={'Z'}
                                        value={z}
                                        variant={'standard'}
                                        InputProps={{
                                            endAdornment: <InputAdornment position={'end'}>mm</InputAdornment>,
                                        }}
                                    />
                                </FormControl>
                                <Typography variant={'h6'}>Translation</Typography>
                                {
                                    this.renderInputFields(['dx', 'dy', 'dz'], 'mm', 'location')
                                }
                            </>
                        }
                    </MyValidationForm>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleSubmit} id={'translate'} variant={'contained'} color={'primary'}
                            autoFocus>
                        Translate
                    </Button>
                </DialogActions>
            </Fragment>
        );
    }
}

AssemblyTranslate.propTypes = {
    project_id: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
    validation_errors: getValidationErrors(state),
    project_id: getProject(state).id,
    tree: getTree(state),
});

const mapDispatchToProps = {
    translateProjectAssembly,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssemblyTranslate);
