const colors = {
    white: '#FFFFFF',
    gray: '#303030',
    darker: '#606060',
    stroke: '#A6A6A6'
};

const style = {
    '.st9': {fill: 'none', stroke: colors.white, strokeLinejoin: 'round', strokeMiterlimit: 10},
};

export const MINI_ICONS = {
    ball_array: (
        <svg version={'1.1'} x={'0px'} y={'0px'}
             viewBox={'0 0 24 24'} style={{enableBackground: 'new 0 0 24 24'}}>
            <path style={style['.st9']}
                  d={'M8.2,5.8c-2.2,0-4,1.8-4,4c0,0.3,0,0.5,0.1,0.8c0.3-0.1,0.6-0.1,0.9-0.1c1.9,0,3.6,1.4,3.9,3.2c1.8-0.4,3.1-2,3.1-3.9C12.2,7.6,10.4,5.8,8.2,5.8z'}/>
            <g>
                <circle style={style['.st9']} cx={'5.2'} cy={'14.5'} r={'4'}/>
            </g>
            <path style={style['.st9']}
                  d={'M19.2,5.8c-2.2,0-4,1.8-4,4c0,0.3,0,0.5,0.1,0.8c0.3-0.1,0.6-0.1,0.9-0.1c1.9,0,3.6,1.4,3.9,3.2c1.8-0.4,3.1-2,3.1-3.9C23.2,7.6,21.4,5.8,19.2,5.8z'}/>
            <g transform={'translate(18)'}>
                <circle style={style['.st9']} cx={'-1.8'} cy={'14.5'} r={'4'}/>
            </g>
        </svg>
    ),
    box: (
        <svg version={'1.1'} x={'0px'} y={'0px'}
             viewBox={'0 0 24 24'} style={{enableBackground: 'new 0 0 24 24'}}>
            <g transform={'translate(6 12)'}>
                <rect x={'-4.5'} y={'-1.5'}
                      style={style['.st9']} width="13" height="8"/>
                <path style={style['.st9']}
                      d={'M4.2-6.5h12.3l-8,5h-13L4.2-6.5z'}/>
                <path style={style['.st9']}
                      d={'M16.5,1.5v-8l-8,5v8L16.5,1.5z'}/>
            </g>
        </svg>
    ),
    cylinder: (
        <svg version={'1.1'} x={'0px'} y={'0px'}
             viewBox={'0 0 24 24'} style={{enableBackground: 'new 0 0 24 24'}}>
            <g transform={'translate(12 12)'}>
                <path style={style['.st9']}
                      d={'M7.3-4.5H-8.5C-7.7-4.5-7-2.5-7,0c0,2.5-0.7,4.5-1.5,4.5H7.3c1.2,0,2.2-2,2.2-4.5C9.5-2.5,8.5-4.5,7.3-4.5z'}/>
                <ellipse style={style['.st9']}
                         cx={'-8.5'} cy={'0'} rx={'1.5'} ry={'4.5'}/>
            </g>
        </svg>
    ),
    heatsink: (
        <svg version={'1.1'} x={'0px'} y={'0px'}
             viewBox={'0 0 24 24'} style={{enableBackground: 'new 0 0 24 24'}}>
            <g transform={'translate(6 6)'}>
                <path style={style['.st9']} d="M-4.5,12.5v-8h3v5h3v-5h3v5h3v-5h3v8H-4.5z"/>
                <path style={style['.st9']}
                      d={'M1.5-1.5h3l-6,6h-3L1.5-1.5z'}/>
                <path style={style['.st9']}
                      d={'M7.5-1.5h3l-6,6h-3L7.5-1.5z'}/>
                <path style={style['.st9']}
                      d={'M13.5-1.5h3l-6,6h-3L13.5-1.5z'}/>
            </g>
            <path style={style['.st9']}
                  d={'M22.5,12.5v-8l-6,6v8L22.5,12.5z'}/>
        </svg>
    ),
    pcb: (
        <svg version={'1.1'} x={'0px'} y={'0px'}
             viewBox={'0 0 24 24'} style={{enableBackground: 'new 0 0 24 24'}}>
            <g transform={'translate(6 12)'}>

                <rect x={'-4.5'} y={'2.5'}
                      style={style['.st9']} width="13" height="4"/>
                <path style={style['.st9']}
                      d={'M4.2-6.5h12.3l-8,5h-13L4.2-6.5z'}/>
                <path style={style['.st9']}
                      d={'M16.5,1.5v-4l-8,5v4L16.5,1.5z'}/>

                <rect x={'-4.5'} y={'-1.5'}
                      style={style['.st9']}
                      width={'13'} height={'4'}/>
                <path style={style['.st9']}
                      d={'M16.5-2.5v-4l-8,5v4L16.5-2.5z'}/>
            </g>
        </svg>
    ),
    source: (
        <svg version={'1.1'} x={'0px'} y={'0px'}
             viewBox={'0 0 24 24'} style={{enableBackground: 'new 0 0 24 24'}}>
            <path style={style['.st9']}
                  d={'M9.2,9.5h14.3l-8,5h-15L9.2,9.5z'}/>
        </svg>
    ),
    transient_source: (
        <svg version={'1.1'} x={'0px'} y={'0px'}
             viewBox={'0 0 24 24'} style={{enableBackground: 'new 0 0 24 24'}}>
            <g transform={'translate(0 2.005)'} opacity={'0.5'}>
                <path style={style['.st9']} d={'M10.16,5.5H22.5l-8,5H1.5Z'} transform="translate(0 4)"/>
            </g>
            <path style={style['.st9']}
                  d={'M5635,271.5a1.5,1.5,0,0,1,3,0,1.5,1.5,0,0,0,3,0'}
                  transform={'translate(-5632 -264)'}/>
            <path style={style['.st9']}
                  d={'M5635,271.5a1.5,1.5,0,0,1,3,0,1.5,1.5,0,0,0,3,0'}
                  transform={'translate(-5626 -264)'}/>
            <path style={style['.st9']}
                  d={'M5635,271.5a1.5,1.5,0,0,1,3,0,1.5,1.5,0,0,0,3,0'}
                  transform={'translate(-5620 -264)'}/>
        </svg>
    ),
    via_array: (
        <svg version={'1.1'} x={'0px'} y={'0px'}
             viewBox={'0 0 24 24'} style={{enableBackground: 'new 0 0 24 24'}}>
            <path style={style['.st9']}
                  d={'M3.5,10.5c-1.1,0-2-0.4-2-1v8.7c0,0.6,0.9,1.1,2,1.1s2-0.5,2-1.1V9.5C5.5,10.1,4.6,10.5,3.5,10.5z'}/>
            <ellipse style={style['.st9']} cx={'3.5'}
                     cy={'9.5'} rx={'2'} ry={'1'}/>
            <path style={style['.st9']}
                  d={'M14.5,10.5c-1.1,0-2-0.4-2-1v8.7c0,0.6,0.9,1.1,2,1.1s2-0.5,2-1.1V9.5C16.5,10.1,15.6,10.5,14.5,10.5z'}/>
            <ellipse style={style['.st9']} cx={'14.5'}
                     cy={'9.5'} rx={'2'} ry={'1'}/>
            <path style={style['.st9']}
                  d={'M10.5,6.5c-1.1,0-2-0.4-2-1h0v8.7c0,0.6,0.9,1.1,2,1.1s2-0.5,2-1.1V5.5h0C12.5,6,11.6,6.5,10.5,6.5z'}/>
            <ellipse style={style['.st9']} cx={'10.5'}
                     cy={'5.5'} rx={'2'} ry={'1'}/>
            <path style={style['.st9']}
                  d={'M21.5,6.5c-1.1,0-2-0.4-2-1h0v8.7c0,0.6,0.9,1.1,2,1.1s2-0.5,2-1.1V5.5h0C23.5,6,22.6,6.5,21.5,6.5z'}/>
            <ellipse style={style['.st9']} cx={'21.5'}
                     cy={'5.5'} rx={'2'} ry={'1'}/>
        </svg>
    )
};