/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from "prop-types";
import _ from "lodash";
import MyValidationForm from "../../../../components/MyValidationForm";
import {confirmAlert} from "react-confirm-alert";
import {FormGroup} from "@mui/material";
import {SettingsDivider, SettingsHeader} from "../../../../components/BasicSettings";
import {ActiveInactiveStateIcon} from "../../../../components/ActiveStateIcon";
import {FONT_AWESOME_ICONS_TYPE_MAP, MAPPINGS} from "../../../../core/mappings";
import {IconsFontAwesome} from "../../../../components/IconsFontAwesome";
import {transform} from "../transformSettingsProps";
import {projectUtils} from "../../../utils";
import {getDefaultValues} from "./defaultValues";

/* In this component we keep the base logic of elements settings in order to avoid repetitiveness
 * The base functions are implemented by default but can be overwritten in a custom way in each setting component if needed
*/
export const withSettingsTemplate = (SettingsComponent) => (props) => {
    const {
        box,
        setSelectedObject,
        updateSelectedObject,
        deleteProjectObject,
        updateProjectObjectAndTree,
        createProjectObject,
        project_id,
        materials
    } = props;
    const [defaultState] = useState(getDefaultValues[box.type]({materials, box}));
    const [fields] = useState(Object.keys(defaultState));
    const [state, setState] = useState(Object.keys(box).length > 0 ? {
        ...box,
        ...projectUtils.updateSettingsProps({
            fields,
            props: box,
            defaultState
        })
    } : {...defaultState});
    const form = useRef();

    const debounceUpdateSelectedObject = useCallback(_.debounce((newState) => {
        if (box.id !== 'creating') return;
        const newObj = {...box, ...transform(newState)};
        setSelectedObject(newObj);
        updateSelectedObject(true)
    }, 500), []);

    // TODO: apply only position changes is there are some changes in the edit form, same for visible, active
    useEffect(() => {
        const newState = {...projectUtils.updateSettingsProps({fields, props: box})};
        setState((prevState) => !_.isEqual(prevState, newState) ? newState : prevState);
    }, [box])


    function handleChange(field, value) {
        setState((prevState) => {
            const newState = {...prevState, [field]: value}
            debounceUpdateSelectedObject(newState);
            return newState;
        });
    }

    function handleDelete() {
        confirmAlert({
            title: `Deleting ${state.name}`, message: 'Do you wish to proceed?', buttons: [{
                label: 'Delete', onClick: () => {
                    deleteProjectObject({
                        project_id, box: {
                            ...box, ...state,
                        },
                    });
                },
            }, {
                label: 'Cancel', onClick: () => {
                },
            }]
        });
    }

    function handleSubmit(e) {
        e.preventDefault();
        form.current.isFormValid(false)
            .then(is_valid => {
                if (is_valid) {
                    if (box && (box.id !== 'creating')) {
                        if (!boxHasChange()) return;
                        // since polygon value needs transformation we can include it here. If other properties start to come up this need to be abstracted.
                        const updatePolygon = {
                            ...state,
                            polygon: `POLYGON ((${state?.polygon}))`
                        };
                        const updatedObject = state.polygon ? updatePolygon : {...state};
                        updateProjectObjectAndTree({
                            project_id: props.project_id, box: {
                                ...box, ...updatedObject,
                            },
                        });
                    } else {
                        // no create for polygon so we don't need to include it here
                        createProjectObject({
                            project_id: project_id, box: {
                                ...state,
                            },
                        });
                    }
                }
            });
    }

    function handleRevert() {
        setState({
            ...box,
            ...projectUtils.updateSettingsProps({fields, props: {...state, ...box}}),
        });
    }

    // TODO: exclude visible and active when we make them separate
    function boxHasChange() {
        return Object.keys(state).some((key) => {
            return !_.isEqual(state[key], box[key])
        });
    }

    function handleCancel() {
        setSelectedObject();
        updateSelectedObject(true);
    }


    return <MyValidationForm
        ref={form}
        onSubmit={handleSubmit}
        style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            width: '100%',
            minHeight: '50%'
        }}
    >
        <FormGroup row>
            <SettingsHeader name={MAPPINGS.titles[box.type]}>
                {/*TODO: instant update when active, visible changes*/}
                <ActiveInactiveStateIcon active={state.active}
                                         activeIconType={FONT_AWESOME_ICONS_TYPE_MAP.powerActive}
                                         inactiveIconType={FONT_AWESOME_ICONS_TYPE_MAP.powerInactive}
                                         titleActive={'Set Inactive'}
                                         titleInactive={'Set Active'}
                                         iconsSX={{fontSize: '1rem', p: '6px'}}
                                         hoveredSX={{backgroundColor: 'background.paper'}}
                                         onClickHandler={() => handleChange('active', !state.active)}/>
                {box && box.id !== 'creating' && <ActiveInactiveStateIcon active={state.visible}
                                                                          activeIconType={FONT_AWESOME_ICONS_TYPE_MAP.eyeVisible}
                                                                          inactiveIconType={FONT_AWESOME_ICONS_TYPE_MAP.eyeNotVisible}
                                                                          titleActive={'Hide'}
                                                                          titleInactive={'Show'}
                                                                          secondClass={'smaller'}
                                                                          iconsSX={{p: '6px'}}
                                                                          hoveredSX={{backgroundColor: 'background.paper'}}
                                                                          onClickHandler={() => handleChange('visible', !state.visible)}/>}
                {box && box.id !== 'creating' && <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.delete}
                                                                   titleAccess={`Delete ${box.name}`}
                                                                   secondClass={'smaller'}
                                                                   iconsSX={{p: '6px'}}
                                                                   hoveredSX={{backgroundColor: 'background.paper'}}
                                                                   onClickHandler={handleDelete}/>}
            </SettingsHeader>
        </FormGroup>
        <SettingsDivider withSideMargin/>
        <SettingsComponent {...props}
                           boxHasChange={boxHasChange}
                           handleRevert={handleRevert}
                           handleCancel={handleCancel}
                           handleSubmit={handleSubmit}
                           handleChange={handleChange}
                           state={state}
            // for custom implementation/overwriting the base functions
                           debounceUpdateSelectedObject={debounceUpdateSelectedObject}
                           setState={setState}
                           form={form}
                           createProjectObject={createProjectObject}
                           updateProjectObjectAndTree={updateProjectObjectAndTree}
                           project_id={project_id}
        />
    </MyValidationForm>
}

withSettingsTemplate.propTypes = {
    defaultState: PropTypes.object.isRequired,
    createProjectObject: PropTypes.func.isRequired,
    updateProjectObject: PropTypes.func.isRequired,
    updateProjectObjectAndTree: PropTypes.func.isRequired,
    deleteProjectObject: PropTypes.func.isRequired,
    materials: PropTypes.array.isRequired,
    project_id: PropTypes.number.isRequired,
    setSelectedObject: PropTypes.func.isRequired,
    updateSelectedObject: PropTypes.func.isRequired,
    box: PropTypes.object,
};
