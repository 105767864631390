/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React from 'react';
import * as THREE from 'three';

import Draggable3DIcon from './component/Draggable3DIcon';


export default function Cylinder3DIcons() {
    return (
        <Draggable3DIcon materials={[new THREE.MeshPhongMaterial({color: 0x167db8})]}
                         boxes={[new THREE.CylinderBufferGeometry(3, 3, 20, 32)]}
                         lightPosition={{x: 10, y: 10, z: 10}}
                         tooltipTitle={'Create Cylinder object'}
                         objId={'cylinderID'}
                         dragType={'cylinder'}
        />
    );
}