/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {
    SET_VALIDATION_ERRORS
} from '../actions/actions';

export const defaultState = {};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_VALIDATION_ERRORS:
            return {
                ...action.payload
            };
        default:
            return state;
    }
};

export const getValidationErrors = ({ current_project }) => current_project.validation_errors;

