/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

export const UPDATE_PROJECT_POLYGON = 'UPDATE_PROJECT_POLYGON';
export const UPDATE_PROJECT_POLYGON_ERROR = 'UPDATE_PROJECT_POLYGON_ERROR';
export const UPDATE_PROJECT_POLYGON_SUCCESS = 'UPDATE_PROJECT_POLYGON_SUCCESS';

export const updateProjectPolygon =  payload => ({
    type: UPDATE_PROJECT_POLYGON,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/polygon/${payload.box.id}/`,
            method: 'PUT',
            body: JSON.stringify(payload.box),
        },
    },
});

export const UPDATE_PROJECT_POLYGON_AND_TREE = 'UPDATE_PROJECT_POLYGON_AND_TREE';
export const UPDATE_PROJECT_POLYGON_AND_TREE_SUCCESS = 'UPDATE_PROJECT_POLYGON_AND_TREE_SUCCESS';
export const UPDATE_PROJECT_POLYGON_AND_TREE_ERROR = 'UPDATE_PROJECT_POLYGON_AND_TREE_ERROR';

export const updateProjectPolygonAndTree =  payload => ({
    type: UPDATE_PROJECT_POLYGON_AND_TREE,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/polygon/${payload.box.id}/`,
            method: 'PUT',
            body: JSON.stringify(payload.box),
        },
    },
});


export const CREATE_PROJECT_POLYGON = 'CREATE_PROJECT_POLYGON';
export const CREATE_PROJECT_POLYGON_ERROR = 'CREATE_PROJECT_POLYGON_ERROR';
export const CREATE_PROJECT_POLYGON_SUCCESS = 'CREATE_PROJECT_POLYGON_SUCCESS';

export const createProjectPolygon =  payload => ({
    type: CREATE_PROJECT_POLYGON,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/polygon/`,
            method: 'POST',
            body: JSON.stringify(payload.box),
        },
    },
});


export const CLONE_PROJECT_POLYGON = 'CLONE_PROJECT_POLYGON';
export const CLONE_PROJECT_POLYGON_ERROR = 'CLONE_PROJECT_POLYGON_ERROR';
export const CLONE_PROJECT_POLYGON_SUCCESS = 'CLONE_PROJECT_POLYGON_SUCCESS';

export const cloneProjectPolygon =  payload => ({
    type: CLONE_PROJECT_POLYGON,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/polygon/${payload.box.id}/clone/`,
            method: 'POST',
            body: JSON.stringify(payload.box),
        },
    },
});


export const DELETE_PROJECT_POLYGON = 'DELETE_PROJECT_POLYGON';
export const DELETE_PROJECT_POLYGON_ERROR = 'DELETE_PROJECT_POLYGON_ERROR';
export const DELETE_PROJECT_POLYGON_SUCCESS = 'DELETE_PROJECT_POLYGON_SUCCESS';

export const deleteProjectPolygon = payload => ({
    type: DELETE_PROJECT_POLYGON,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/polygon/${payload.box.id}/`,
            method: 'DELETE',
        },
    }
});