/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import _ from 'lodash';
import * as THREE from 'three';
import {TrackballControls} from 'three/examples/jsm/controls/TrackballControls';

import TempSettings from '../../../../settings/TempSettings';

import SpriteText from 'three-spritetext';
import {Lut} from 'three/examples/jsm/math/Lut';

import {Alert, Slide, Snackbar} from '@mui/material';

import {createAxesScene, createMovingObjects, getBBox} from '../../../../functions';

import {
    fitProjectObject,
    fitAll,
    toggleObjectClipping,
    updateProjectThumbnail,
} from '../../../../../+store/actions/project';
import {
    clearProjectSolution,
    loadPlaneSolutions,
    loadProjectSolution,
    loadProjectTransient,
    loadProjectTransientSolution,
    loadTaskStatus,
    updateProjectObjectPosition,
    setSelectedObject,
    updateSelectedObject,
    translateProjectAssembly, forceUpdateSelectedObjectAction,
    loadClipFaceSolution_x0, loadClipFaceSolution_x1,
    loadClipFaceSolution_y0, loadClipFaceSolution_y1,
    loadClipFaceSolution_z0, loadClipFaceSolution_z1,
    storeClipFaceData, clearClipFaceSolutions,
} from '../../../../../+store/actions/actions';
import {updatePlane} from '../../../../../+store/actions/planes';

import {
    getAssemblySelectionState,
    getSelectedObject, getForceUpdatedSelectedObject,
    getUpdateSelectedObject,
} from '../../../../../+store/reducer/tree';
import {getTaskId, getTaskStatus, getTaskStatusTime, getTransient} from '../../../../../+store/reducer/solution';
import {
    getFitAll,
    getGridSnap,
    getProjectDrag,
    getProjectMaterials,
    getProjectMode,
} from '../../../../../+store/reducer/project';

import {MAPPINGS} from '../../../../../../core/mappings';
import {GridHelper} from '../../../../geoms/GridHelper';
import {boxMovementUtils, drawObjectUtils, projectUtils} from '../../../../../utils';
import DraggingInfoInputs from './dragging-info-inputs/DraggingInfoInputs';
import {DEFAULT_SETTINGS} from '../../../../../../core/constants/DEFAULT_SETTINGS';
import {clipFacePlaneSolutionsHasData, getClipFacePlaneSolutions} from '../../../../../+store/reducer/plane_solutions';
import SolutionPanel from './solution-panel/SolutionPanel';
import * as wkt from 'wicket';

const clipPlanesMapping = {
    x0: 0,
    y0: 1,
    z0: 2,
    z1: 3,
    y1: 4,
    x1: 5,
};
const oppositeBoxAxisFaceMapping = {
    x0: 'X1',
    x1: 'X0',
    y0: 'Y1',
    y1: 'Y0',
    z0: 'Z1',
    z1: 'Z0',
};

const CLIPPING_BBOX_OFFSET = 0.1; // 10%;
const axesScalePercentage = 0.01;
let newCoordinatesTimeOut = null;

const scenesMapping = {
    'box': 'scene',
    'plane': 'planeScene',
    'solution': 'solutionScene',
};
const drawObjectsByMode = {
    'box': 'drawObjects',
    'plane': 'drawPlane',
    'solution': 'drawSolution',
    'adjacency': 'drawAdjacencyTree',
};

class ResourceTracker {
    constructor() {
        this.resources = new Set();
    }

    track(resource) {
        if (resource.dispose) {
            this.resources.add(resource);
        }
        return resource;
    }

    untrack(resource) {
        this.resources.delete(resource);
    }

    dispose() {
        for (const resource of this.resources) {
            resource.dispose();
        }
        this.resources.clear();
    }
}

function updateAssemblyElementsPosition({
                                            axis,
                                            element,
                                            newCoordinates,
                                        }) {
    if (element.type !== 'assembly') {
        updateSingleElementPosition({
            axis,
            element,
            newCoordinates,
        });
    }

    if (element.type === 'assembly') {
        element[`${axis[0]}_calc`] = Math.round(newCoordinates[`d${axis[0]}`] * 100) / 100;

        if (axis[1]) {
            element[`${axis[1]}_calc`] = Math.round(newCoordinates[`d${axis[1]}`] * 100) / 100;
        }

        element.children.forEach(child => {
            updateAssemblyElementsPosition({
                axis,
                element: child,
                newCoordinates,
            });
        });
    }
}

function updateSingleElementPosition({
                                         axis,
                                         element,
                                         newCoordinates,
                                     }) {
    let newX, newY, newZ;

    switch (axis) {
        case 'x':
            newX = Math.round((+element.x_calc + newCoordinates.dx) * 100) / 100;
            element.x = newX;
            element.x_calc = newX;
            break;
        case 'y':
            newY = Math.round((+element.y_calc + newCoordinates.dy) * 100) / 100;
            element.y = newY;
            element.y_calc = newY;

            break;
        case 'z':
            newZ = Math.round((+element.z_calc + newCoordinates.dz) * 100) / 100;
            element.z = newZ;
            element.z_calc = newZ;

            break;
        case 'xz':
            newX = Math.round((+element.x_calc + newCoordinates.dx) * 100) / 100;
            newZ = Math.round((+element.z_calc + newCoordinates.dz) * 100) / 100;
            element.x = newX;
            element.z = newZ;

            element.x_calc = newX;
            element.z_calc = newZ;

            break;
        case 'xy':
            newX = Math.round((+element.x_calc + newCoordinates.dx) * 100) / 100;
            newY = Math.round((+element.y_calc + newCoordinates.dy) * 100) / 100;

            element.x = newX;
            element.y = newY;

            element.x_calc = newX;
            element.y_calc = newY;

            break;
        case 'yz':
            newY = Math.round((+element.y_calc + newCoordinates.dy) * 100) / 100;
            newZ = Math.round((+element.z_calc + newCoordinates.dz) * 100) / 100;

            element.y = newY;
            element.z = newZ;

            element.y_calc = newY;
            element.z_calc = newZ;
            break;
    }
}

function findParentAssemblyZCalcForZAdjustment(boxes, assemblyId) {
    for (const box of boxes) {
        if (box.type === 'assembly' && box.parent && box.parent === assemblyId) {
            return box;
        }

        if (box.children && box.children.length > 0) {
            const nestedAssembly = findParentAssemblyZCalcForZAdjustment(box.children, assemblyId);
            if (nestedAssembly) {
                return nestedAssembly;
            }
        }
    }

    return null;
}

const loadPlaneSolutionsTimeouts = {
    x0: null,
    x1: null,
    y0: null,
    y1: null,
    z0: null,
    z1: null,
};
const loadPlaneSolutionsTimePointTimeouts = {
    x0: null,
    x1: null,
    y0: null,
    y1: null,
    z0: null,
    z1: null,
};


class Boxes extends Component {
    textHeight = 0.04;
    fontWeight = 'normal';
    fontFace = 'Roboto';
    control_on = 0;
    control_on_delay_ms = 2000;
    drag = false;
    canvasWidth = 200;
    canvasHeight = 150;
    lineColorDark = new THREE.Color(0x0);
    lineColorLight = new THREE.Color(0xd0d0d0);
    selectedLineColor = new THREE.Color(0xff0000);
    drawJobs = [];
    drawSolutionJobs = [];
    pointTemp = null;
    objects = [];
    solutionBoxes = [];
    solutionPlanes = [];
    clipSolutionPlanes = [];
    materials = {};
    solutionTemp = {
        min: null,
        max: null,
    };
    solutionTempJob = null;
    movingJob = null;
    clipLines = [];
    clipFaces = [];
    dragClipFace = null;
    clippingBoxX0 = null;
    clippingBoxY0 = null;
    clippingBoxZ0 = null;
    clippingBoxX1 = null;
    clippingBoxY1 = null;
    clippingBoxZ1 = null;
    clippedBoxX0 = null;
    clippedBoxX1 = null;
    clippedBoxY0 = null;
    clippedBoxY1 = null;
    clippedBoxZ0 = null;
    clippedBoxZ1 = null;
    maxXAxisSize = 0;
    maxYAxisSize = 0;
    maxZAxisSize = 0;
    maxPlaneSize = 0;
    planeFaceOffset = 0;
    planeFaceOffsetStartEnd = 0;
    halfXAxisSize = 0;
    halfYAxisSize = 0;
    halfZAxisSize = 0;
    worldPositions = {};
    clipPlanes = [
        new THREE.Plane(new THREE.Vector3(1, 0, 0), 1),
        new THREE.Plane(new THREE.Vector3(0, 1, 0), 1),
        new THREE.Plane(new THREE.Vector3(0, 0, 1), 1),
        new THREE.Plane(new THREE.Vector3(0, 0, -1), 1),
        new THREE.Plane(new THREE.Vector3(0, -1, 0), 1),
        new THREE.Plane(new THREE.Vector3(-1, 0, 0), 1),
    ];
    nameSprites = [];
    nameSpritesSolution = [];
    dragPlane = new THREE.Mesh(new THREE.PlaneGeometry(10000, 10000, 4, 4),
        new THREE.MeshBasicMaterial({
            color: '#00ff00',
            transparent: true,
            opacity: 0,
        }));
    movementAxes = [];
    moveDirection = null;
    tempMoving3D = null;
    tempMoving3DPosition = {
        x: 0,
        y: 0,
        z: 0,
    };
    movementInitialCoords = null;
    intersectedMovementAxes = null;
    axesScale = null;
    solutionMaterial = new THREE.MeshBasicMaterial({
        vertexColors: THREE.VertexColors,
        clippingPlanes: this.clipPlanes,
        side: THREE.DoubleSide,
    });
    cameraRotate = false;
    cameraLookAt = new THREE.Vector3();

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            temp: {
                min_t: null,
                max_t: null,
                colormap: 'rainbow',
                filter: true,
            },
            message: '',
            severity: '',
            selectedBox: {},
            newCoordinates: null,
            lut: [],
        };

        this.animate = this.animate.bind(this);
        this.getMaterial = this.getMaterial.bind(this);
        this.drawObjects = this.drawObjects.bind(this);
        this.drawObjectsByType = this.drawObjectsByType.bind(this);
        this.drawSolution = this.drawSolution.bind(this);
        this.drawPlane = this.drawPlane.bind(this);
        this.drawAdjacencyTree = this.drawAdjacencyTree.bind(this);
        this.drawTemp = this.drawTemp.bind(this);
        this.drawCoords = this.drawCoords.bind(this);
        this.drawMovementAxesScene = this.drawMovementAxesScene.bind(this);
        this.drawClippingObjects = this.drawClippingObjects.bind(this);
        this.reAddClippingObjects = this.reAddClippingObjects.bind(this);

        this.drawClipPlaneSolutions = this.drawClipPlaneSolutions.bind(this);
        this.drawClipPlaneSolutionTemp = this.drawClipPlaneSolutionTemp.bind(this);

        this.onMouseMove = this.onMouseMove.bind(this);
        this.toggleCameraRotate = this.toggleCameraRotate.bind(this);
        this.init();
    }

    init() {
        this.objects = [];
        this.intersects = [];

        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            depth: true,
            precision: 'highp',
            logarithmicDepthBuffer: true,
            preserveDrawingBuffer: true,
        });

        this.canvasRenderer = undefined;
        this.renderer.autoClear = false;

        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color(0xb0b0b0);
        this.scene.autoUpdate = true;

        // this.logoScene = new THREE.Scene();
        // this.logoCamera = new THREE.OrthographicCamera();
        // this.logoCamera.position.set(0, 0, 1);
        // this.logoCamera.lookAt(0, 0, 0);

        // let texture = new THREE.TextureLoader().load('logo-name.png'),
        //     backgroundMesh = new THREE.Mesh(
        //         new THREE.PlaneGeometry(1, 1, 1),
        //         new THREE.MeshBasicMaterial({
        //             map: texture,
        //             transparent: true,
        //         }));
        //
        // backgroundMesh.material.depthTest = false;
        // backgroundMesh.material.depthWrite = false;

        // this.logoScene.add(backgroundMesh);
        // this.logoScene.add(new THREE.AmbientLight('#ffffff', 1));

        this.light0 = new THREE.PointLight(0xffffff, 1);
        this.light1 = new THREE.PointLight(0xffffff, 1);
        this.light2 = new THREE.PointLight(0xffffff, 1);
        this.light3 = new THREE.PointLight(0xffffff, 1);

        this.solutionScene = new THREE.Scene();
        this.solutionScene.background = new THREE.Color(0xb0b0b0);

        this.planeScene = new THREE.Scene();
        this.planeScene.background = new THREE.Color(0xb0b0b0);

        this.worldMaterial = new THREE.MeshBasicMaterial({
            color: new THREE.Color(0xa0a0a0),
            side: THREE.BackSide,
        });

        this.planeMaterialXY = new THREE.MeshBasicMaterial({
            color: 0x0000ff,
            transparent: true,
            opacity: 0.4,
            side: THREE.DoubleSide,
            polygonOffset: true,
            polygonOffsetFactor: Math.random(),
            clippingPlanes: this.clipPlanes,
        });
        this.planeMaterialXZ = new THREE.MeshBasicMaterial({
            color: 0x00ff00,
            transparent: true,
            opacity: 0.4,
            side: THREE.DoubleSide,
            polygonOffset: true,
            polygonOffsetFactor: Math.random(),
            clippingPlanes: this.clipPlanes,
        });
        this.planeMaterialYZ = new THREE.MeshBasicMaterial({
            color: 0xf00f00,
            transparent: true,
            opacity: 0.4,
            side: THREE.DoubleSide,
            polygonOffset: true,
            polygonOffsetFactor: Math.random(),
            clippingPlanes: this.clipPlanes,
        });

        this.tempScene = new THREE.Scene();

        this.coordScene = new THREE.Scene();
        this.movementAxesScene = new THREE.Scene();

        this.cameraFOV = 25;
        this.camera = new THREE.PerspectiveCamera(this.cameraFOV, this.innerWidth / window.innerHeight, 0.1, 10000);
        this.camera.position.set(0, 0, 60);

        this.canvasCamera = new THREE.PerspectiveCamera(this.cameraFOV, this.canvasWidth / this.canvasHeight, 0.1, 1000);
        this.canvasCamera.position.set(0, 0, 60);

        this.tempCamera = new THREE.OrthographicCamera(-1, 1, 1, -1, 1, 2);
        this.tempCamera.position.set(0, 0, 1);

        this.coordCamera = new THREE.OrthographicCamera(-1, 1, 1, -1, 1, 2);
        this.coordCamera.position.set(0, 0, 1);

        this.mouse = new THREE.Vector2();
        this.raycaster = new THREE.Raycaster();
        this.controls = new TrackballControls(this.camera, this.renderer.domElement);
        this.controls.addEventListener('change', () => {
            if (this.props.selectedObject?.id && this.movementAxes?.children) {
                this.axesScale = this.camera.position.distanceTo(this.movementAxes.position) * axesScalePercentage;

                this.movementAxes.children.forEach(axis => {
                    if (axis.userData?.axis || axis.userData?.line || axis.userData?.disabled) {
                        axis.scale.set(this.axesScale, this.axesScale, this.axesScale);
                    }
                });
            } else if (this.objects[0]) {
                this.axesScale = this.camera.position.distanceTo(this.objects[0].position) * axesScalePercentage;
            }
        });

        this.lineMaterialObjectDark = new THREE.MeshBasicMaterial({
            color: this.lineColorDark,
            clippingPlanes: this.clipPlanes,
        });
        this.lineMaterialObjectLight = new THREE.MeshBasicMaterial({
            color: this.lineColorLight,
            clippingPlanes: this.clipPlanes,
        });
        this.lineMaterial = new THREE.MeshBasicMaterial({
            color: this.lineColorDark,
        });

        this.lut = new Lut();
        this.lut.setColorMap(this.state.temp.colormap, 256);
        this.state.lut = this.lut.lut;
        this.intersectedAxesObj = null;

        this.fitObjects = [];
        this.axesSceneSize = 150;
        let axisLength = 12;
        this.axesCamera = new THREE.OrthographicCamera(
            -axisLength * 1.2, axisLength * 1.2, axisLength * 1.2, -axisLength * 1.2,
            0.1, 1000,
        );
        this.axesCamera.position.set(0, 0, 1);
        this.axesScene = createAxesScene(this.fitObjects, this.fit(this.camera, this.controls), axisLength);

        this.moving = createMovingObjects();
    }

    animate() {
        if (this.dom) {
            if (['box', 'solution', 'plane'].find(x => x === this.props.mode)) {
                if (this.cameraRotate) {
                    let spin_rate = 0.003,
                        dx = this.camera.position.x - this.controls.target.x,
                        dy = this.camera.position.y - this.controls.target.y,
                        r = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2)),
                        angle = Math.PI
                            - Math.PI / 2 * (1 + Math.sign(dx)) * (1 - Math.sign(Math.pow(dy, 2)))
                            - Math.PI / 4 * (2 + Math.sign(dx)) * Math.sign(dy)
                            - Math.sign(dx * dy) * Math.asin((Math.abs(dx) - Math.abs(dy)) / Math.sqrt(2 * Math.pow(dx, 2) + 2 * Math.pow(dy, 2))),
                        new_dx = (dx !== 0 && dy !== 0) ? Math.cos(angle + spin_rate) * r : 0,
                        new_dy = (dx !== 0 && dy !== 0) ? Math.sin(angle + spin_rate) * r : 0,
                        up_dx = this.camera.up.x,
                        up_dy = this.camera.up.y,
                        up_angle = (up_dx !== 0 && up_dy !== 0)
                            ? Math.PI
                            - Math.PI / 2 * (1 + Math.sign(up_dx)) * (1 - Math.sign(Math.pow(up_dy, 2)))
                            - Math.PI / 4 * (2 + Math.sign(up_dx)) * Math.sign(up_dy)
                            - Math.sign(up_dx * up_dy) * Math.asin((Math.abs(up_dx) - Math.abs(up_dy)) / Math.sqrt(2 * Math.pow(up_dx, 2) + 2 * Math.pow(up_dy, 2)))
                            : 0,
                        new_up_dx = (up_dx !== 0 && up_dy !== 0) ? Math.cos(up_angle + spin_rate) : 0,
                        new_up_dy = (up_dx !== 0 && up_dy !== 0) ? Math.sin(up_angle + spin_rate) : 0;

                    if (!isNaN(new_dx) && !isNaN(new_dy))
                        this.camera.position.set(this.controls.target.x + new_dx, this.controls.target.y + new_dy, this.camera.position.z);
                    if (!isNaN(new_up_dx) && !isNaN(new_up_dy))
                        this.camera.up.set(new_up_dx, new_up_dy, this.camera.up.z).normalize();
                    this.camera.updateProjectionMatrix();
                }
                // main
                this.controls.update();
                this.renderer.setViewport(0, 0, this.dom.offsetWidth, this.dom.offsetHeight);
                if (this.props.mode === 'solution') {
                    this.renderer.render(this.solutionScene, this.camera);
                    this.renderer.render(this.tempScene, this.tempCamera);
                    this.renderer.render(this.coordScene, this.coordCamera);
                    this.renderer.clearDepth();
                    this.renderer.render(this.movementAxesScene, this.camera);
                } else if (this.props.mode === 'plane') {
                    this.renderer.render(this.planeScene, this.camera);
                    this.renderer.render(this.tempScene, this.tempCamera);
                    this.renderer.render(this.coordScene, this.coordCamera);
                } else if (this.props.mode === 'box') {
                    this.renderer.render(this.scene, this.camera);
                    if (this.props.clipping) {
                        this.renderer.render(this.tempScene, this.tempCamera);
                    }
                    this.renderer.render(this.coordScene, this.coordCamera);
                    this.renderer.clearDepth();
                    this.renderer.render(this.movementAxesScene, this.camera);
                }
                // axes
                this.renderer.clearDepth();
                this.renderer.setViewport(this.dom.offsetWidth - 235, -5, 320, 32);
                // this.renderer.render(this.logoScene, this.logoCamera);
                this.renderer.setScissorTest(true);
                this.renderer.setScissor(this.dom.offsetWidth - this.axesSceneSize, 0, this.axesSceneSize, this.axesSceneSize);
                this.renderer.setViewport(this.dom.offsetWidth - this.axesSceneSize, 0, this.axesSceneSize, this.axesSceneSize);
                this.axesCamera.quaternion.copy(this.camera.quaternion);
                this.axesCamera.position.copy(this.camera.position);
                this.axesCamera.position.sub(this.controls.target);
                this.axesCamera.position.setLength(20);
                this.renderer.render(this.axesScene, this.axesCamera);
                this.renderer.setScissorTest(false);
                this.renderer.localClippingEnabled = this.props.clipping;
            }

        }

        requestAnimationFrame(this.animate);
    }

    handleSnackbarClose = () => {
        this.setState({open: false});
    };

    getMaterial = (color, opacity, doubleSided = false) => {
        let name = `${color}_${opacity}_${doubleSided}`;
        if (!this.materials.hasOwnProperty(name)) {
            let boxColor = new THREE.Color(color);
            let material = new THREE.MeshPhongMaterial({
                color: boxColor,
                side: THREE.DoubleSide,
                transparent: opacity !== 1,
                depthTest: true,
                polygonOffset: true,
                polygonOffsetUnits: 1,
                polygonOffsetFactor: Object.keys(this.materials).length,
                opacity: opacity,
                clippingPlanes: this.clipPlanes,
                roughness: 0.5,
            });
            material.polygonOffset = true;
            material.polygonOffsetFactor = Math.random();
            let y = 0.299 * boxColor.r + 0.587 * boxColor.g + 0.114 * boxColor.b;

            if (y < 0.3)
                this.materials[name] = {material, line_material: this.lineMaterialObjectLight};
            else
                this.materials[name] = {material, line_material: this.lineMaterialObjectDark};
        }
        return this.materials[name];
    };

    clearDrawJobs = () => {
        this.drawJobs.forEach((job) => {
            clearTimeout(job);
        });
        this.drawJobs.splice(0);
    };

    clearSolutionDrawJobs = () => {
        this.drawSolutionJobs.forEach((job) => {
            clearTimeout(job);
        });
        this.drawSolutionJobs.splice(0);
    };

    clearClipping = () => {
        this.clippedBoxX0 = null;
        this.clipFaces = [];
        this.clipLines = [];
        this.dragClipFace = null;
    };

    drawTemp(min_t, max_t) {
        min_t = this.solutionTemp.min !== null ? this.solutionTemp.min : min_t;
        max_t = this.solutionTemp.max !== null ? this.solutionTemp.max : max_t;

        if (min_t === max_t) {
            max_t = min_t + 1;
        }

        this.lut.setColorMap(this.state.temp.colormap, 256);
        this.lut.setMin(min_t);
        this.lut.setMax(max_t);

        this.tempScene.clear();
        this.sprite = new THREE.Sprite(new THREE.SpriteMaterial({
            color: 0xffffff,
            opacity: 0.5,
        }));
        this.sprite.position.x = -0.98;
        this.sprite.position.y = -0.98;
        this.sprite.scale.x = 0.27;
        this.sprite.scale.y = 0.57;
        this.sprite.center.x = 0;
        this.sprite.center.y = 0;
        this.tempScene.add(this.sprite);

        let sprite = new THREE.Sprite(new THREE.SpriteMaterial({
            map: new THREE.CanvasTexture(this.lut.createCanvas()),
        }));
        sprite.position.x = -0.95;
        sprite.position.y = -0.95;
        sprite.scale.x = 0.05;
        sprite.scale.y = 0.5;
        sprite.center.x = 0;
        sprite.center.y = 0;
        this.tempScene.add(sprite);

        let steps = Math.min(4, Math.ceil(max_t - min_t));

        for (let i = 0; i <= steps; ++i) {
            let t = min_t + i * (max_t - min_t) / steps;
            let t_text = new SpriteText(t.toFixed(1) + '°C');
            t_text.textHeight = this.textHeight;
            t_text.position.x = -0.86;
            t_text.position.y = -0.95 + i * 0.5 / steps;
            t_text.center.x = 0;
            t_text.center.y = 0.5;
            t_text.color = 'black';
            t_text.fontWeight = this.fontWeight;
            t_text.fontFace = this.fontFace;
            t_text.fontSize = 38;
            this.tempScene.add(t_text);
        }

        if (this.pointTemp) {
            let t_text = new SpriteText('⊲' + this.pointTemp.toFixed(1) + '°C ');
            t_text.textHeight = this.textHeight;
            t_text.position.x = -0.9;
            t_text.position.y = -0.95 + (Math.min(max_t, this.pointTemp) - min_t) / (max_t - min_t) * 0.5;
            t_text.center.x = 0;
            t_text.center.y = 0.5;
            t_text.color = 'white';
            t_text.backgroundColor = 'gray';
            t_text.fontWeight = this.fontWeight;
            t_text.fontFace = this.fontFace;
            t_text.fontSize = 38;
            this.tempScene.add(t_text);
        }
    }

    drawCoords(point, obj) {
        this.coordScene.clear();

        let translateY = 0;
        if (this.props.mode === 'box' && this.props.selectedObject && this.props.selectedObject.id) {
            translateY = 0.13;
        }

        if (this.props.mode === 'solution' && this.props.solution && this.props.transient && this.props.transient?.length) {
            translateY = 0.27;
        }

        let sprite = new THREE.Sprite(new THREE.SpriteMaterial({
            color: 0xffffff,
            opacity: 0.5,
        }));
        sprite.position.x = -0.98;
        sprite.position.y = 0.75 - translateY;
        sprite.scale.x = 0.35;
        sprite.scale.y = 0.25;
        sprite.center.x = 0;
        sprite.center.y = 0;
        this.coordScene.add(sprite);

        let t_text = new SpriteText('x= ' + point.x.toFixed(6));
        t_text.textHeight = this.textHeight;
        t_text.position.x = -0.95;
        t_text.position.y = 0.95 - translateY;
        t_text.center.x = 0;
        t_text.center.y = 0.5;
        t_text.color = 'black';
        t_text.fontWeight = this.fontWeight;
        t_text.fontFace = this.fontFace;
        t_text.fontSize = 38;
        this.coordScene.add(t_text);
        t_text = new SpriteText('y= ' + point.y.toFixed(6));
        t_text.textHeight = this.textHeight;
        t_text.position.x = -0.95;
        t_text.position.y = 0.9 - translateY;
        t_text.center.x = 0;
        t_text.center.y = 0.5;
        t_text.color = 'black';
        t_text.fontWeight = this.fontWeight;
        t_text.fontFace = this.fontFace;
        t_text.fontSize = 38;
        this.coordScene.add(t_text);
        t_text = new SpriteText('z= ' + point.z.toFixed(6));
        t_text.textHeight = this.textHeight;
        t_text.position.x = -0.95;
        t_text.position.y = 0.85 - translateY;
        t_text.center.x = 0;
        t_text.center.y = 0.5;
        t_text.color = 'black';
        t_text.fontWeight = this.fontWeight;
        t_text.fontFace = this.fontFace;
        t_text.fontSize = 38;
        this.coordScene.add(t_text);

        if (obj) {
            t_text = new SpriteText(obj.name);
            t_text.textHeight = this.textHeight;
            t_text.position.x = -0.95;
            t_text.position.y = 0.8 - translateY;
            t_text.center.x = 0;
            t_text.center.y = 0.5;
            t_text.color = 'black';
            t_text.fontWeight = this.fontWeight;
            t_text.fontFace = this.fontFace;
            t_text.fontSize = 38;
            this.coordScene.add(t_text);
        }
    }

    drawMovementAxesScene(obj) {
        this.movementAxesScene.clear();
        this.coordScene.clear();
        this.movementAxes = [];
        this.movementInitialCoords = null;

        if (this.scene) {
            this.movingObj && this.scene.remove(this.movingObj);
            this.placeHolderObject && this.scene.remove(this.placeHolderObject);
        }

        this.tempMoving3DPosition = boxMovementUtils.getPosition(obj, this.worldPositions);

        const {
            axesArrowsGroup,
            movingObj,
        } = boxMovementUtils.createBoxMovementAxes(obj, this.tempMoving3DPosition, this.props.assemblySelection, this.worldPositions, this.axesScale || 1);

        this.movementAxes = axesArrowsGroup;

        this.movingObj = movingObj.clone();
        this.scene.add(this.movingObj);

        if (this.props.selectedObject.id !== 'creating') {
            this.placeHolderObject = movingObj.clone();
            this.scene.add(this.placeHolderObject);
        }

        this.movementAxesScene.add(this.movementAxes);
    }

    clearMovementAxesScene = (clearScene = true) => {
        this.movementAxesScene.clear();
        if (clearScene && this.scene) {
            this.movingObj && this.scene.remove(this.movingObj);
            this.placeHolderObject && this.scene.remove(this.placeHolderObject);
        }

        this.movementAxes = [];
        this.moveDirection = null;
        this.tempMoving3D = null;
        this.movingObj = null;
        this.tempMoving3DPosition = {
            x: 0,
            y: 0,
            z: 0,
        };

        this.state.newCoordinates &&
        this.setState({
            newCoordinates: null,
        });
    };

    compute_bbox = (boxes) => {
        let wx0 = Number.POSITIVE_INFINITY, wy0 = Number.POSITIVE_INFINITY, wz0 = Number.POSITIVE_INFINITY,
            wx1 = Number.NEGATIVE_INFINITY, wy1 = Number.NEGATIVE_INFINITY, wz1 = Number.NEGATIVE_INFINITY;
        boxes.forEach(box => {
            if (!box.active)
                return;

            if (box.children) {
                const [tmpx0, tmpy0, tmpz0, tmpx1, tmpy1, tmpz1] = this.compute_bbox(box.children);
                wx0 = Math.min(wx0, tmpx0);
                wy0 = Math.min(wy0, tmpy0);
                wz0 = Math.min(wz0, tmpz0);
                wx1 = Math.max(wx1, tmpx1);
                wy1 = Math.max(wy1, tmpy1);
                wz1 = Math.max(wz1, tmpz1);
            } else {
                switch (box.type) {
                    case 'box':
                    case 'cylinder':
                    case 'source':
                    case 'pcb':
                    case 'ball_array':
                    case 'via_array':
                        wx0 = Math.min(wx0, +box.x_calc);
                        wy0 = Math.min(wy0, +box.y_calc);
                        wz0 = Math.min(wz0, +box.z_calc);
                        wx1 = Math.max(wx1, +box.x_calc + (+box.dx_calc));
                        wy1 = Math.max(wy1, +box.y_calc + (+box.dy_calc));
                        wz1 = Math.max(wz1, +box.z_calc + (+box.dz_calc));
                        break;
                    case 'heatsink':
                        wx0 = Math.min(wx0, +box.x_calc);
                        wy0 = Math.min(wy0, +box.y_calc);
                        wz0 = Math.min(wz0, +box.z_calc);
                        wx1 = Math.max(wx1, +box.x_calc + (+box.base_dx_calc));
                        wy1 = Math.max(wy1, +box.y_calc + (+box.base_dy_calc));
                        wz1 = Math.max(wz1, +box.z_calc + (+box.base_dz_calc) + (+box.fin_height_calc));
                        break;
                    case 'polygon':
                        let parser = new wkt.Wkt(),
                            objs = parser.read(box.polygon).toJson(),
                            min1 = +Infinity,
                            min2 = +Infinity,
                            max1 = -Infinity,
                            max2 = -Infinity
                        ;
                        let coords;
                        if (objs.type == 'MultiPolygon')
                            coords = objs.coordinates;
                        else if (objs.type == 'Polygon')
                            coords = [objs.coordinates];

                        coords.forEach(points => {
                            points[0].forEach(point => {
                                min1 = Math.min(min1, point[0]);
                                max1 = Math.max(max1, point[0]);
                                min2 = Math.min(min2, point[1]);
                                max2 = Math.max(max2, point[1]);
                            })

                        });
                        switch (box.plane) {
                            case 'XY':
                                wx0 = Math.min(wx0, box.x_calc + min1);
                                wx1 = Math.max(wx1, box.x_calc + max1);
                                wy0 = Math.min(wy0, box.y_calc + min2);
                                wy1 = Math.max(wy1, box.y_calc + max2);
                                wz0 = Math.min(wz0, box.z_calc);
                                wz1 = Math.max(wz0, box.z_calc + box.thickness_calc);
                                break;
                            case 'XZ':
                                wx0 = Math.min(wx0, box.x_calc + min1);
                                wx1 = Math.max(wx1, box.x_calc + max1);
                                wy0 = Math.min(wy0, box.y_calc);
                                wy1 = Math.max(wy0, box.y_calc + box.thickness_calc);
                                wz0 = Math.min(wz0, box.z_calc + min2);
                                wz1 = Math.max(wz1, box.z_calc + max2);
                                break;
                            case 'YZ':
                                wx0 = Math.min(wx0, box.x_calc);
                                wx1 = Math.max(wx0, box.x_calc + box.thickness_calc);
                                wy0 = Math.min(wy0, box.y_calc + min1);
                                wy1 = Math.max(wy1, box.y_calc + max1);
                                wz0 = Math.min(wz0, box.z_calc + min2);
                                wz1 = Math.max(wz1, box.z_calc + max2);
                                break;
                        }
                        break;
                }
            }
        });
        return [wx0, wy0, wz0, wx1, wy1, wz1];
    };

    drawWorld = (scene, add_draw_plane) => {
        if (!this.props.current_project.world)
            return;

        let [wx0, wy0, wz0, wx1, wy1, wz1] = this.compute_bbox(this.props.tree);

        if (wx0 === Number.POSITIVE_INFINITY) {
            wx0 = wy0 = wz0 = wx1 = wy1 = wz1 = 0;
        }

        this.worldPositions = {
            wx0,
            wx1,
            wy0,
            wy1,
            wz0,
            wz1,
        };

        if (this.props.current_project.world.absolute) {
            wx0 = -this.props.current_project.world.dxmin;
            wx1 = +this.props.current_project.world.dxmax;
            wy0 = -this.props.current_project.world.dymin;
            wy1 = +this.props.current_project.world.dymax;
            wz0 = -this.props.current_project.world.dzmin;
            wz1 = +this.props.current_project.world.dzmax;
        } else {
            wx0 = -this.props.current_project.world.dxmin + wx0;
            wx1 = +this.props.current_project.world.dxmax + wx1;
            wy0 = -this.props.current_project.world.dymin + wy0;
            wy1 = +this.props.current_project.world.dymax + wy1;
            wz0 = -this.props.current_project.world.dzmin + wz0;
            wz1 = +this.props.current_project.world.dzmax + wz1;
        }

        let worldGeom = new THREE.BoxBufferGeometry(
                (wx1 - wx0), (wy1 - wy0), (wz1 - wz0),
            ),

            worldEdges = new THREE.EdgesGeometry(worldGeom),
            worldLines = new THREE.LineSegments(worldEdges, this.lineMaterial),
            worldMesh = new THREE.Mesh(worldGeom, this.worldMaterial);

        let helper = new GridHelper(wx0, wx1, wy0, wy1, wz0, wz1, this.props.current_project.world.show_grid,
            0x808080, 0x606060, 0xff0000, 0x00ff00, 0x0000ff);
        scene.add(helper);

        worldMesh.position.set((wx0 + wx1) / 2, (wy0 + wy1) / 2, (wz0 + wz1) / 2);
        worldLines.position.set((wx0 + wx1) / 2, (wy0 + wy1) / 2, (wz0 + wz1) / 2);

        scene.add(worldMesh);
        scene.add(worldLines);

        if (add_draw_plane) {
            let plane = new THREE.Mesh(
                new THREE.PlaneBufferGeometry(wx1 - wx0, wy1 - wy0, 2),
                new THREE.MeshBasicMaterial({visible: false}));
            plane.applyMatrix4(new THREE.Matrix4().makeTranslation((wx0 + wx1) / 2, (wy0 + wy1) / 2, 0));
            scene.add(plane);
            this.intersects.push(plane);
        }

        this.light0.position.set(wx0, wy0, wz0);
        this.light1.position.set(wx1, wy1, wz0);
        this.light2.position.set(wx0, wy1, wz1);
        this.light3.position.set(wx1, wy0, wz1);
    };

    drawClippingObjects(scene) {
        if (this.props.current_project) {
            if (this.clippedBoxX0 === null) {
                // set initial clippingBbox
                this.clippingBoxX0 = this.worldPositions.wx0;
                this.clippingBoxX1 = this.worldPositions.wx1;
                this.clippingBoxY0 = this.worldPositions.wy0;
                this.clippingBoxY1 = this.worldPositions.wy1;
                this.clippingBoxZ0 = this.worldPositions.wz0;
                this.clippingBoxZ1 = this.worldPositions.wz1;

                // get all plane sizes
                const XSize = this.clippingBoxX1 - this.clippingBoxX0;
                const YSize = this.clippingBoxY1 - this.clippingBoxY0;
                const ZSize = this.clippingBoxZ1 - this.clippingBoxZ0;
                const maxAxisSize = Math.max(XSize, YSize, ZSize);

                this.planeFaceOffset = maxAxisSize * CLIPPING_BBOX_OFFSET;
                this.planeFaceOffsetStartEnd = this.planeFaceOffset * 2;

                [this.clippingBoxX0, this.clippingBoxY0, this.clippingBoxZ0, this.clippingBoxZ1, this.clippingBoxY1, this.clippingBoxX1]
                    .forEach((axisValue, idx) => this.clipPlanes[idx].constant = Math.abs(axisValue) + this.planeFaceOffset); // clipPlane indexes x1: 0, y1: 1, z1: 2, z0: 3, y0: 4, x0: 5

                this.clippedBoxX0 = this.clippingBoxX0;
                this.clippedBoxX1 = this.clippingBoxX1;
                this.clippedBoxY0 = this.clippingBoxY0;
                this.clippedBoxY1 = this.clippingBoxY1;
                this.clippedBoxZ0 = this.clippingBoxZ0;
                this.clippedBoxZ1 = this.clippingBoxZ1;
            }

            this.maxXAxisSize = this.clippedBoxX1 - this.clippedBoxX0;
            this.maxYAxisSize = this.clippedBoxY1 - this.clippedBoxY0;
            this.maxZAxisSize = this.clippedBoxZ1 - this.clippedBoxZ0;
            this.maxPlaneSize = Math.max(this.maxXAxisSize, this.maxYAxisSize, this.maxZAxisSize);

            this.halfXAxisSize = (this.maxXAxisSize / 2);
            this.halfYAxisSize = (this.maxYAxisSize / 2);
            this.halfZAxisSize = (this.maxZAxisSize / 2);

            const dragPlaneMaterial = new THREE.MeshBasicMaterial({
                color: 'black',
                transparent: true,
                opacity: 0.0,
                // side: THREE.DoubleSide,
            });

            const dragPlaneMeshX0 = projectUtils.getDragPlaneMesh({
                size: {
                    width: this.maxZAxisSize + this.planeFaceOffsetStartEnd,
                    height: this.maxYAxisSize + this.planeFaceOffsetStartEnd,
                },
                material: dragPlaneMaterial,
                userDataValue: 'x0',
                position: {
                    x: this.clippedBoxX0 - this.planeFaceOffset,
                    y: this.clippedBoxY0 + this.halfYAxisSize,
                    z: this.clippedBoxZ0 + this.halfZAxisSize,
                },
                rotate: {
                    axis: 'y',
                    value: -Math.PI / 2,
                },
            });
            const dragPlaneMeshX1 = projectUtils.getDragPlaneMesh({
                size: {
                    width: this.maxZAxisSize + this.planeFaceOffsetStartEnd,
                    height: this.maxYAxisSize + this.planeFaceOffsetStartEnd,
                },
                material: dragPlaneMaterial,
                userDataValue: 'x1',
                position: {
                    x: this.clippedBoxX0 + this.maxXAxisSize + this.planeFaceOffset,
                    y: this.clippedBoxY0 + this.halfYAxisSize,
                    z: this.clippedBoxZ0 + this.halfZAxisSize,
                },
                rotate: {
                    axis: 'y',
                    value: Math.PI / 2,
                },
            });
            const dragPlaneMeshY0 = projectUtils.getDragPlaneMesh({
                size: {
                    width: this.maxXAxisSize + this.planeFaceOffsetStartEnd,
                    height: this.maxZAxisSize + this.planeFaceOffsetStartEnd,
                },
                material: dragPlaneMaterial,
                userDataValue: 'y0',
                position: {
                    x: this.clippedBoxX0 + this.halfXAxisSize,
                    y: this.clippedBoxY0 - this.planeFaceOffset,
                    z: this.clippedBoxZ0 + this.halfZAxisSize,
                },
                rotate: {
                    axis: 'x',
                    value: Math.PI / 2,
                },
            });
            const dragPlaneMeshY1 = projectUtils.getDragPlaneMesh({
                size: {
                    width: this.maxXAxisSize + this.planeFaceOffsetStartEnd,
                    height: this.maxZAxisSize + this.planeFaceOffsetStartEnd,
                },
                material: dragPlaneMaterial,
                userDataValue: 'y1',
                position: {
                    x: this.clippedBoxX0 + this.halfXAxisSize,
                    y: this.clippedBoxY0 + this.maxYAxisSize + this.planeFaceOffset,
                    z: this.clippedBoxZ0 + this.halfZAxisSize,
                },
                rotate: {
                    axis: 'x',
                    value: -Math.PI / 2,
                },
            });
            const dragPlaneMeshZ0 = projectUtils.getDragPlaneMesh({
                size: {
                    width: this.maxXAxisSize + this.planeFaceOffsetStartEnd,
                    height: this.maxYAxisSize + this.planeFaceOffsetStartEnd,
                },
                material: dragPlaneMaterial,
                userDataValue: 'z0',
                position: {
                    x: this.clippedBoxX0 + this.halfXAxisSize,
                    y: this.clippedBoxY0 + this.halfYAxisSize,
                    z: this.clippedBoxZ0 - this.planeFaceOffset,
                },
                rotate: {
                    axis: 'x',
                    value: Math.PI,
                },
            });
            const dragPlaneMeshZ1 = projectUtils.getDragPlaneMesh({
                size: {
                    width: this.maxXAxisSize + this.planeFaceOffsetStartEnd,
                    height: this.maxYAxisSize + this.planeFaceOffsetStartEnd,
                },
                material: dragPlaneMaterial,
                userDataValue: 'z1',
                position: {
                    x: this.clippedBoxX0 + this.halfXAxisSize,
                    y: this.clippedBoxY0 + this.halfYAxisSize,
                    z: this.clippedBoxZ1 + this.planeFaceOffset,
                },
            });

            const lineX0Bottom = projectUtils.getClippingLine({
                vectorPoints: {
                    start: {
                        x: this.clippedBoxX0 - this.planeFaceOffset,
                        y: this.clippedBoxY0 - this.planeFaceOffset,
                        z: this.clippedBoxZ0 - this.planeFaceOffset,
                    },
                    end: {
                        x: this.clippedBoxX0 - this.planeFaceOffset,
                        y: this.clippedBoxY0 + this.maxYAxisSize + this.planeFaceOffset,
                        z: this.clippedBoxZ0 - this.planeFaceOffset,
                    },
                },
                userDataValue: {
                    fullIn: ['x0', 'z0'],
                    partialIn: ['y0', 'y1'],
                },
            });
            const lineX0Top = projectUtils.getClippingLine({
                vectorPoints: {
                    start: {
                        x: this.clippedBoxX0 - this.planeFaceOffset,
                        y: this.clippedBoxY0 - this.planeFaceOffset,
                        z: this.clippedBoxZ1 + this.planeFaceOffset,
                    },
                    end: {
                        x: this.clippedBoxX0 - this.planeFaceOffset,
                        y: this.clippedBoxY0 + this.maxYAxisSize + this.planeFaceOffset,
                        z: this.clippedBoxZ1 + this.planeFaceOffset,
                    },
                },
                userDataValue: {
                    fullIn: ['x0', 'z1'],
                    partialIn: ['y0', 'y1'],
                },
            });
            const lineX0Near = projectUtils.getClippingLine({
                vectorPoints: {
                    start: {
                        x: this.clippedBoxX0 - this.planeFaceOffset,
                        y: this.clippedBoxY0 - this.planeFaceOffset,
                        z: this.clippedBoxZ0 - this.planeFaceOffset,
                    },
                    end: {
                        x: this.clippedBoxX0 - this.planeFaceOffset,
                        y: this.clippedBoxY0 - this.planeFaceOffset,
                        z: this.clippedBoxZ1 + this.planeFaceOffset,

                    },
                },
                userDataValue: {
                    fullIn: ['x0', 'y0'],
                    partialIn: ['z0', 'z1'],
                },
            });
            const lineX0Far = projectUtils.getClippingLine({
                vectorPoints: {
                    start: {
                        x: this.clippedBoxX0 - this.planeFaceOffset,
                        y: this.clippedBoxY0 + this.maxYAxisSize + this.planeFaceOffset,
                        z: this.clippedBoxZ0 - this.planeFaceOffset,
                    },
                    end: {
                        x: this.clippedBoxX0 - this.planeFaceOffset,
                        y: this.clippedBoxY0 + this.maxYAxisSize + this.planeFaceOffset,
                        z: this.clippedBoxZ1 + this.planeFaceOffset,

                    },
                },
                userDataValue: {
                    fullIn: ['x0', 'y1'],
                    partialIn: ['z0', 'z1'],
                },
            });
            const lineX1Bottom = projectUtils.getClippingLine({
                vectorPoints: {
                    start: {
                        x: this.clippedBoxX0 + this.maxXAxisSize + this.planeFaceOffset,
                        y: this.clippedBoxY0 - this.planeFaceOffset,
                        z: this.clippedBoxZ0 - this.planeFaceOffset,
                    },
                    end: {
                        x: this.clippedBoxX0 + this.maxXAxisSize + this.planeFaceOffset,
                        y: this.clippedBoxY0 + this.maxYAxisSize + this.planeFaceOffset,
                        z: this.clippedBoxZ0 - this.planeFaceOffset,
                    },
                },
                userDataValue: {
                    fullIn: ['x1', 'z0'],
                    partialIn: ['y0', 'y1'],
                },
            });
            const lineX1Top = projectUtils.getClippingLine({
                vectorPoints: {
                    start: {
                        x: this.clippedBoxX0 + this.maxXAxisSize + this.planeFaceOffset,
                        y: this.clippedBoxY0 - this.planeFaceOffset,
                        z: this.clippedBoxZ1 + this.planeFaceOffset,
                    },
                    end: {
                        x: this.clippedBoxX0 + this.maxXAxisSize + this.planeFaceOffset,
                        y: this.clippedBoxY0 + this.maxYAxisSize + this.planeFaceOffset,
                        z: this.clippedBoxZ1 + this.planeFaceOffset,
                    },
                },
                userDataValue: {
                    fullIn: ['x1', 'z1'],
                    partialIn: ['y0', 'y1'],
                },
            });
            const lineX1Near = projectUtils.getClippingLine({
                vectorPoints: {
                    start: {
                        x: this.clippedBoxX0 + this.maxXAxisSize + this.planeFaceOffset,
                        y: this.clippedBoxY0 - this.planeFaceOffset,
                        z: this.clippedBoxZ0 - this.planeFaceOffset,
                    },
                    end: {
                        x: this.clippedBoxX0 + this.maxXAxisSize + this.planeFaceOffset,
                        y: this.clippedBoxY0 - this.planeFaceOffset,
                        z: this.clippedBoxZ1 + this.planeFaceOffset,

                    },
                },
                userDataValue: {
                    fullIn: ['x1', 'y0'],
                    partialIn: ['z0', 'z1'],
                },
            });
            const lineX1Far = projectUtils.getClippingLine({
                vectorPoints: {
                    start: {
                        x: this.clippedBoxX0 + this.maxXAxisSize + this.planeFaceOffset,
                        y: this.clippedBoxY0 + this.maxYAxisSize + this.planeFaceOffset,
                        z: this.clippedBoxZ0 - this.planeFaceOffset,
                    },
                    end: {
                        x: this.clippedBoxX0 + this.maxXAxisSize + this.planeFaceOffset,
                        y: this.clippedBoxY0 + this.maxYAxisSize + this.planeFaceOffset,
                        z: this.clippedBoxZ1 + this.planeFaceOffset,

                    },
                },
                userDataValue: {
                    fullIn: ['x1', 'y1'],
                    partialIn: ['z0', 'z1'],
                },
            });

            const lineY0Bottom = projectUtils.getClippingLine({
                vectorPoints: {
                    start: {
                        x: this.clippedBoxX0 - this.planeFaceOffset,
                        y: this.clippedBoxY0 - this.planeFaceOffset,
                        z: this.clippedBoxZ0 - this.planeFaceOffset,
                    },
                    end: {
                        x: this.clippedBoxX0 + this.maxXAxisSize + this.planeFaceOffset,
                        y: this.clippedBoxY0 - this.planeFaceOffset,
                        z: this.clippedBoxZ0 - this.planeFaceOffset,
                    },
                },
                userDataValue: {
                    fullIn: ['y0', 'z0'],
                    partialIn: ['x0', 'x1'],
                },
            });
            const lineY0Top = projectUtils.getClippingLine({
                vectorPoints: {
                    start: {
                        x: this.clippedBoxX0 - this.planeFaceOffset,
                        y: this.clippedBoxY0 - this.planeFaceOffset,
                        z: this.clippedBoxZ1 + this.planeFaceOffset,
                    },
                    end: {
                        x: this.clippedBoxX0 + this.maxXAxisSize + this.planeFaceOffset,
                        y: this.clippedBoxY0 - this.planeFaceOffset,
                        z: this.clippedBoxZ1 + this.planeFaceOffset,
                    },
                },
                userDataValue: {
                    fullIn: ['y0', 'z1'],
                    partialIn: ['x0', 'x1'],
                },
            });
            const lineY1Bottom = projectUtils.getClippingLine({
                vectorPoints: {
                    start: {
                        x: this.clippedBoxX0 - this.planeFaceOffset,
                        y: this.clippedBoxY0 + this.maxYAxisSize + this.planeFaceOffset,
                        z: this.clippedBoxZ0 - this.planeFaceOffset,
                    },
                    end: {
                        x: this.clippedBoxX0 + this.maxXAxisSize + this.planeFaceOffset,
                        y: this.clippedBoxY0 + this.maxYAxisSize + this.planeFaceOffset,
                        z: this.clippedBoxZ0 - this.planeFaceOffset,
                    },
                },
                userDataValue: {
                    fullIn: ['y1', 'z0'],
                    partialIn: ['x0', 'x1'],
                },
            });
            const lineY1Top = projectUtils.getClippingLine({
                vectorPoints: {
                    start: {
                        x: this.clippedBoxX0 - this.planeFaceOffset,
                        y: this.clippedBoxY0 + this.maxYAxisSize + this.planeFaceOffset,
                        z: this.clippedBoxZ1 + this.planeFaceOffset,
                    },
                    end: {
                        x: this.clippedBoxX0 + this.maxXAxisSize + this.planeFaceOffset,
                        y: this.clippedBoxY0 + this.maxYAxisSize + this.planeFaceOffset,
                        z: this.clippedBoxZ1 + this.planeFaceOffset,
                    },
                },
                userDataValue: {
                    fullIn: ['y1', 'z1'],
                    partialIn: ['x0', 'x1'],
                },
            });

            this.clipFaces.push(dragPlaneMeshX0, dragPlaneMeshX1, dragPlaneMeshY0, dragPlaneMeshY1, dragPlaneMeshZ0, dragPlaneMeshZ1);
            scene.add(dragPlaneMeshX0, dragPlaneMeshX1, dragPlaneMeshY0, dragPlaneMeshY1, dragPlaneMeshZ0, dragPlaneMeshZ1);
            this.clipLines.push(lineX0Bottom, lineX0Top, lineX1Top, lineX1Bottom, lineX0Near, lineX0Far, lineX1Near, lineX1Far, lineY0Bottom, lineY0Top, lineY1Bottom, lineY1Top);
            scene.add(lineX0Bottom, lineX0Top, lineX1Top, lineX1Bottom, lineX0Near, lineX0Far, lineX1Near, lineX1Far, lineY0Bottom, lineY0Top, lineY1Bottom, lineY1Top);
        }
    }

    reAddClippingObjects(scene) {
        this.clipFaces.forEach(clipFace => scene.add(clipFace));
        this.clipLines.forEach(clipLine => scene.add(clipLine));
    }

    drawObjects() {
        this.clearDrawJobs();

        this.objects = [];
        this.intersects = [];

        let [wx0, wy0, wz0, wx1, wy1, wz1] = this.compute_bbox(this.props.tree);
        if (wx0 === Number.POSITIVE_INFINITY) {
            wx0 = wy0 = wz0 = wx1 = wy1 = wz1 = 0;
        }

        const drawElement = (box, xOffset = 0, yOffset = 0, zOffset = 0, visible = true) => {
            if (box.children && box.type === 'assembly' && box.active) {
                if (box.is_array) {
                    for (let x = 0; x < box.xcount_calc; x++) {
                        for (let y = 0; y < box.ycount_calc; y++) {
                            for (let z = 0; z < box.zcount_calc; z++) {
                                for (const child of box.children) {
                                    // Adjust position based on xpitch_calc, ypitch_calc, zpitch_calc
                                    const offsetX = x * (+box.xpitch_calc / 1000) + xOffset;
                                    const offsetY = y * (+box.ypitch_calc / 1000) + yOffset;
                                    const offsetZ = z * (+box.zpitch_calc / 1000) + zOffset;

                                    // Draw the child element at the calculated position
                                    drawElement(child, offsetX, offsetY, offsetZ, visible && box.visible);
                                }
                            }
                        }
                    }
                } else {
                    for (const child of box.children) {
                        // Draw the child element at the calculated position
                        drawElement(child, xOffset, yOffset, zOffset, visible && box.visible);
                    }
                }
            } else if(box.active) {
                this.drawObjectsByType({
                    scene: this.scene,
                    box: {
                        ...box,
                        x_calc: +box.x_calc + xOffset,
                        y_calc: +box.y_calc + yOffset,
                        z_calc: +box.z_calc + zOffset,
                    },
                    visible: visible && box.visible,
                });
            }
        }

        const draw = (boxes) => {
            boxes.forEach(box => {
                drawElement(box)
            });
        };

        this.drawJobs.push(setTimeout(() => {
            this.scene.clear();
            this.objects.forEach(obj => obj?.geometry?.dispose());
            this.scene.add(this.light0);
            this.scene.add(this.light1);
            this.scene.add(this.light2);
            this.scene.add(this.light3);

            draw(this.props.tree, true);

            if (this.props.current_project) {
                this.drawWorld(this.scene, true);

                this.planes = [];
                this.props.planes &&
                this.props.planes
                    .filter(plane => plane.visible)
                    .forEach(plane => {
                        drawObjectUtils.drawPlane({
                            scene: this.scene,
                            box: plane,
                            getMaterial: this[`planeMaterial${plane.plane}`],
                            objects: this.planes,
                            worldPositions: this.worldPositions,
                        });
                    });

                this.nameSprites.forEach(data => {
                    this.scene.add(data.sprite);
                    this.scene.add(data.line);
                });
            }

            if (this.props.clipping) {
                if (this.clipFaces.length) {
                    this.reAddClippingObjects(this.scene);
                }
            }

            if (this.props.clipFacePlaneSolutionsHasData && this.props.clipFacePlaneSolutionsData) {
                this.drawClipPlaneSolutions();
            } else {
                this.tempScene.clear();
            }
        }, 0));
    }

    drawObjectsByType({
                          scene,
                          box,
                          visible,
                          solution = false,
                      }) {
        const drawObjectHandler = drawObjectUtils[MAPPINGS.drawObjectsByType[box.type]];

        if (drawObjectHandler) {
            drawObjectHandler({
                scene,
                box,
                visible: visible && box.active,
                getMaterial: this.getMaterial,
                objects: this.objects,
                intersects: this.intersects,
                current_project: this.props.current_project,
                solution,
            });
        } else {
            console.log('Don\'t know how to draw', box.type);
        }
    }

    drawSolutionTemp() {
        if (this.props.solution) {
            let min_t = Number.POSITIVE_INFINITY, max_t = Number.NEGATIVE_INFINITY;
            for (let key in this.props.solution.result) {
                min_t = Math.min(this.props.solution.result[key].min_t, min_t);
                max_t = Math.max(max_t, this.props.solution.result[key].max_t);
            }

            this.solutionTemp = {
                min: this.state.temp.min_t || min_t,
                max: this.state.temp.max_t || max_t,
            };
            this.drawTemp(this.solutionTemp.min, this.solutionTemp.max);
        }
    }

    drawSolution() {
        this.clearSolutionDrawJobs();
        this.drawSolutionJobs.push(setTimeout(() => {
            this.solutionScene.clear();
            this.solutionBoxes.forEach(box => box.geometry.dispose());
            this.solutionBoxes = [];

            // this.clearClipping();

            let drawPlane = (scene, planes, type, ccw, box, positions, colors, temps) => {
                planes &&
                planes.forEach(box => {
                    if (this.state.temp.filter && (box[5] > this.solutionTemp.max || box[5] < this.solutionTemp.min))
                        return;
                    let color = this.lut.getColor(box[5]);
                    if (!color)
                        return;
                    if (!ccw) {
                        switch (type) {
                            case 'xy':
                                positions.push(box[0], box[1], box[2]);
                                positions.push(box[0] + box[3], box[1], box[2]);
                                positions.push(box[0] + box[3], box[1] + box[4], box[2]);
                                positions.push(box[0], box[1], box[2]);
                                positions.push(box[0] + box[3], box[1] + box[4], box[2]);
                                positions.push(box[0], box[1] + box[4], box[2]);
                                break;
                            case 'xz':
                                positions.push(box[0], box[1], box[2]);
                                positions.push(box[0] + box[3], box[1], box[2]);
                                positions.push(box[0] + box[3], box[1], box[2] + box[4]);
                                positions.push(box[0], box[1], box[2]);
                                positions.push(box[0] + box[3], box[1], box[2] + box[4]);
                                positions.push(box[0], box[1], box[2] + box[4]);
                                break;
                            case 'yz':
                                positions.push(box[0], box[1], box[2]);
                                positions.push(box[0], box[1] + box[3], box[2]);
                                positions.push(box[0], box[1] + box[3], box[2] + box[4]);
                                positions.push(box[0], box[1], box[2]);
                                positions.push(box[0], box[1] + box[3], box[2] + box[4]);
                                positions.push(box[0], box[1], box[2] + box[4]);
                                break;
                        }
                    } else {
                        switch (type) {
                            case 'xy':
                                positions.push(box[0], box[1], box[2]);
                                positions.push(box[0] + box[3], box[1] + box[4], box[2]);
                                positions.push(box[0] + box[3], box[1], box[2]);
                                positions.push(box[0], box[1], box[2]);
                                positions.push(box[0], box[1] + box[4], box[2]);
                                positions.push(box[0] + box[3], box[1] + box[4], box[2]);
                                break;
                            case 'xz':
                                positions.push(box[0], box[1], box[2]);
                                positions.push(box[0] + box[3], box[1], box[2] + box[4]);
                                positions.push(box[0] + box[3], box[1], box[2]);
                                positions.push(box[0], box[1], box[2]);
                                positions.push(box[0], box[1], box[2] + box[4]);
                                positions.push(box[0] + box[3], box[1], box[2] + box[4]);
                                break;
                            case 'yz':
                                positions.push(box[0], box[1], box[2]);
                                positions.push(box[0], box[1] + box[3], box[2] + box[4]);
                                positions.push(box[0], box[1] + box[3], box[2]);
                                positions.push(box[0], box[1], box[2]);
                                positions.push(box[0], box[1], box[2] + box[4]);
                                positions.push(box[0], box[1] + box[3], box[2] + box[4]);
                                break;
                        }
                    }

                    temps.push(box[5]);

                    // get the colors as an array of values from 0 to 255
                    const rgb = color.toArray();
                    colors.push(rgb[0], rgb[1], rgb[2]);
                    colors.push(rgb[0], rgb[1], rgb[2]);
                    colors.push(rgb[0], rgb[1], rgb[2]);
                    colors.push(rgb[0], rgb[1], rgb[2]);
                    colors.push(rgb[0], rgb[1], rgb[2]);
                    colors.push(rgb[0], rgb[1], rgb[2]);
                });
            };

            let drawn = {};
            let draw = (boxes, xOffset, yOffset, zOffset, visible) => {
                boxes.forEach(box => {
                    if (box.children && box.type === "assembly" && box.active) {
                        if (box.is_array) {
                            for (let x = 0; x < box.xcount_calc; x++) {
                                for (let y = 0; y < box.ycount_calc; y++) {
                                    for (let z = 0; z < box.zcount_calc; z++) {
                                        for (const child of box.children) {
                                            // Adjust position based on xpitch_calc, ypitch_calc, zpitch_calc
                                            const offsetX = x * (+box.xpitch_calc / 1000) + xOffset;
                                            const offsetY = y * (+box.ypitch_calc / 1000) + yOffset;
                                            const offsetZ = z * (+box.zpitch_calc / 1000) + zOffset;

                                            // Draw the child element at the calculated position
                                            draw(box.children, offsetX, offsetY, offsetZ, visible && box.visible);
                                        }
                                    }
                                }
                            }
                        } else {
                        //     for (const child of box.children) {
                                // Draw the child element at the calculated position
                                draw(box.children, xOffset, yOffset, zOffset, visible && box.visible);
                            // }
                        }

                    } else if(box.active) {
                        if (this.props.solution && this.props.solution.result &&
                            visible && box.visible &&
                            this.props.solution.result.hasOwnProperty(box.id + '.' + box.type_id)) {
                            if(!drawn.hasOwnProperty(box.id + '.' + box.type_id)) {
                                const positions = [];
                                const colors = [];
                                const temps = [];
                                drawPlane(this.solutionScene, this.props.solution.result[box.id + '.' + box.type_id].top, 'xy', false, box, positions, colors, temps);
                                drawPlane(this.solutionScene, this.props.solution.result[box.id + '.' + box.type_id].bottom, 'xy', true, box, positions, colors, temps);
                                drawPlane(this.solutionScene, this.props.solution.result[box.id + '.' + box.type_id].south, 'xz', false, box, positions, colors, temps);
                                drawPlane(this.solutionScene, this.props.solution.result[box.id + '.' + box.type_id].north, 'xz', true, box, positions, colors, temps);
                                drawPlane(this.solutionScene, this.props.solution.result[box.id + '.' + box.type_id].east, 'yz', false, box, positions, colors, temps);
                                drawPlane(this.solutionScene, this.props.solution.result[box.id + '.' + box.type_id].west, 'yz', true, box, positions, colors, temps);
                                if (positions.length) {
                                    const geometry = new THREE.BufferGeometry();

                                    geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
                                    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
                                    let cube = new THREE.Mesh(geometry, this.solutionMaterial);
                                    cube.userData = {
                                        temps: temps,
                                        obj: box,
                                    };
                                    this.solutionScene.add(cube);
                                    this.solutionBoxes.push(cube);
                                }
                                drawn[box.id + '.' + box.type_id] = true;
                            }
                        }

                        this.drawObjectsByType({
                            scene: this.solutionScene,
                            box: {
                                ...box,
                                x_calc: +box.x_calc + xOffset,
                                y_calc: +box.y_calc + yOffset,
                                z_calc: +box.z_calc + zOffset,
                            },
                            visible: visible && box.active,
                            solution: true,
                        });
                    }
                });
            };

            this.drawSolutionTemp();
            draw(this.props.tree, 0, 0, 0,true);

            if (this.props.current_project) {
                this.drawWorld(this.solutionScene, false);
            }

            this.nameSpritesSolution.forEach(data => {
                this.solutionScene.add(data.sprite);
                this.solutionScene.add(data.line);
            });

            if (this.props.clipping) {
                if (this.clipFaces.length) {
                    this.reAddClippingObjects(this.solutionScene);
                }
            }
        }, 0));
    }

    drawAdjacencyTree() {
    }

    drawClipPlaneSolutionTemp() {
        if (this.props.solution) {
            let min_t = Number.POSITIVE_INFINITY, max_t = Number.NEGATIVE_INFINITY;
            for (let key in this.props.solution.result) {
                min_t = Math.min(this.props.solution.result[key].min_t, min_t);
                max_t = Math.max(max_t, this.props.solution.result[key].max_t);
            }
            this.solutionTemp = {
                min: this.state.temp.min_t || min_t,
                max: this.state.temp.max_t || max_t,
            };
        }
        this.drawTemp(this.solutionTemp.min, this.solutionTemp.max);
    }

    drawClipPlaneSolutions() {
        this.clipSolutionPlanes.forEach(plane => plane.geometry.dispose());
        this.clipSolutionPlanes.splice(0);

        this.drawClipPlaneSolutionTemp();

        let visibleBoxes = new Set();

        let checkVisible = (boxes, visible) => {
            boxes.forEach(box => {
                if (box.children) {
                    checkVisible(box.children, visible && box.visible);
                } else if (box.visible && visible) {
                    visibleBoxes.add(box.type_id + '.' + box.id);
                }

            });
        };

        checkVisible(this.props.tree, true);

        let positions = [];
        let colors = [];
        let temps = [];

        let clippingBox = {
            x0: this.clippedBoxX0 - this.planeFaceOffset,
            x1: this.clippedBoxX1 + this.planeFaceOffset,
            y0: this.clippedBoxY0 - this.planeFaceOffset,
            y1: this.clippedBoxY1 + this.planeFaceOffset,
            z0: this.clippedBoxZ0 - this.planeFaceOffset,
            z1: this.clippedBoxZ1 + this.planeFaceOffset,
        }
        // TODO: On play transient solution
        // visibleBoxes.has(box.type + '.' + box.id) always returns false -> box.id of result i greater than any
        // tree element id (commenting line 1686 is needed in order to display transient animation clip plane
        // box coordinates are / 1000
        // multiplying box coordinates with 1000 after this line.

        Object.values(this.props.clipFacePlaneSolutionsData).forEach(({result}, idx) => {
            result
                .filter(box => (box.temp <= this.solutionTemp.max && box.temp >= this.solutionTemp.min && visibleBoxes.has(box.type + '.' + box.id)))
                .forEach(box => {
                    let color = this.lut.getColor(box.temp);
                    const rgb = color.toArray();
                    switch (box.plane) {
                        case 'xy':
                            if (+box.x0 + box.dx > clippingBox.x0 && +box.x0 < clippingBox.x1 &&
                                +box.y0 + box.dy > clippingBox.y0 && +box.y0 < clippingBox.y1) {
                                positions.push(
                                    Math.max(+box.x0, clippingBox.x0),
                                    Math.max(+box.y0, clippingBox.y0),
                                    +box.z0);
                                positions.push(
                                    Math.min(+box.x0 + box.dx, clippingBox.x1),
                                    Math.max(+box.y0, clippingBox.y0),
                                    +box.z0);
                                positions.push(
                                    Math.min(+box.x0 + box.dx, clippingBox.x1),
                                    Math.min(+box.y0 + box.dy, clippingBox.y1),
                                    +box.z0);
                                positions.push(
                                    Math.max(+box.x0, clippingBox.x0),
                                    Math.max(+box.y0, clippingBox.y0),
                                    +box.z0);
                                positions.push(
                                    Math.min(+box.x0 + box.dx, clippingBox.x1),
                                    Math.min(+box.y0 + box.dy, clippingBox.y1),
                                    +box.z0);
                                positions.push(
                                    Math.max(+box.x0, clippingBox.x0),
                                    Math.min(+box.y0 + box.dy, clippingBox.y1),
                                    +box.z0);

                                temps.push(box.temp);

                                // get the colors as an array of values from 0 to 255
                                colors.push(rgb[0], rgb[1], rgb[2]);
                                colors.push(rgb[0], rgb[1], rgb[2]);
                                colors.push(rgb[0], rgb[1], rgb[2]);
                                colors.push(rgb[0], rgb[1], rgb[2]);
                                colors.push(rgb[0], rgb[1], rgb[2]);
                                colors.push(rgb[0], rgb[1], rgb[2]);
                            }
                            break;
                        case 'xz':
                            if (+box.x0 + box.dx > clippingBox.x0 && +box.x0 < clippingBox.x1 &&
                                +box.z0 + box.dz > clippingBox.z0 && +box.z0 < clippingBox.z1) {
                                positions.push(
                                    Math.max(+box.x0, clippingBox.x0),
                                    +box.y0,
                                    Math.max(+box.z0, clippingBox.z0));
                                positions.push(
                                    Math.min(+box.x0 + box.dx, clippingBox.x1),
                                    +box.y0,
                                    Math.max(+box.z0, clippingBox.z0));
                                positions.push(
                                    Math.min(+box.x0 + box.dx, clippingBox.x1),
                                    +box.y0,
                                    Math.min(+box.z0 + box.dz, clippingBox.z1));
                                positions.push(
                                    Math.max(+box.x0, clippingBox.x0),
                                    +box.y0,
                                    Math.max(+box.z0, clippingBox.z0));
                                positions.push(
                                    Math.min(+box.x0 + box.dx, clippingBox.x1),
                                    +box.y0,
                                    Math.min(+box.z0 + box.dz, clippingBox.z1));
                                positions.push(
                                    Math.max(+box.x0, clippingBox.x0),
                                    +box.y0,
                                    Math.min(+box.z0 + box.dz, clippingBox.z1));

                                temps.push(box.temp);

                                // get the colors as an array of values from 0 to 255
                                const rgb = color.toArray();

                                colors.push(rgb[0], rgb[1], rgb[2]);
                                colors.push(rgb[0], rgb[1], rgb[2]);
                                colors.push(rgb[0], rgb[1], rgb[2]);
                                colors.push(rgb[0], rgb[1], rgb[2]);
                                colors.push(rgb[0], rgb[1], rgb[2]);
                                colors.push(rgb[0], rgb[1], rgb[2]);
                            }
                            break;
                        case 'yz':
                            if (+box.y0 + box.dy > clippingBox.y0 && +box.y0 < clippingBox.y1 &&
                                +box.z0 + box.dz > clippingBox.z0 && +box.z0 < clippingBox.z1) {
                                positions.push(
                                    +box.x0,
                                    Math.max(+box.y0, clippingBox.y0),
                                    Math.max(+box.z0, clippingBox.z0));
                                positions.push(
                                    +box.x0,
                                    Math.min(+box.y0 + box.dy, clippingBox.y1),
                                    Math.max(+box.z0, clippingBox.z0));
                                positions.push(
                                    +box.x0,
                                    Math.min(+box.y0 + box.dy, clippingBox.y1),
                                    Math.min(+box.z0 + box.dz, clippingBox.z1));
                                positions.push(
                                    +box.x0,
                                    Math.max(+box.y0, clippingBox.y0),
                                    Math.max(+box.z0, clippingBox.z0));
                                positions.push(
                                    +box.x0,
                                    Math.min(+box.y0 + box.dy, clippingBox.y1),
                                    Math.min(+box.z0 + box.dz, clippingBox.z1));
                                positions.push(
                                    +box.x0,
                                    Math.max(+box.y0, clippingBox.y0),
                                    Math.min(+box.z0 + box.dz, clippingBox.z1));

                                temps.push(box.temp);

                                colors.push(rgb[0], rgb[1], rgb[2]);
                                colors.push(rgb[0], rgb[1], rgb[2]);
                                colors.push(rgb[0], rgb[1], rgb[2]);
                                colors.push(rgb[0], rgb[1], rgb[2]);
                                colors.push(rgb[0], rgb[1], rgb[2]);
                                colors.push(rgb[0], rgb[1], rgb[2]);
                            }
                            break;
                    }
                });
        });

        if (positions.length) {
            const geometry = new THREE.BufferGeometry();
            geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
            geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

            const material = new THREE.MeshBasicMaterial({
                vertexColors: THREE.VertexColors,
                side: THREE.DoubleSide,
                // clippingPlanes: this.clipPlanes,
            });

            let cube = new THREE.Mesh(geometry, material);
            cube.userData = temps;

            this.scene.add(cube);
            this.clipSolutionPlanes.push(cube);
        }
    }

    drawPlaneTemp() {
        let min_t = Number.POSITIVE_INFINITY, max_t = Number.NEGATIVE_INFINITY;
        this.props.plane_solutions && this.props.plane_solutions.forEach((plane_solution, idx) => {
            plane_solution.result && plane_solution.result.forEach(box => {
                min_t = Math.min(min_t, box.temp);
                max_t = Math.max(max_t, box.temp);
            });
        });

        this.solutionTemp = {
            min: this.state.temp.min_t || min_t,
            max: this.state.temp.max_t || max_t,
        };
        this.drawTemp(this.solutionTemp.min, this.solutionTemp.max);
    }

    drawPlane() {
        this.planeScene.clear();
        this.solutionPlanes.forEach(plane => plane.geometry.dispose());
        this.solutionPlanes.splice(0);

        this.clearDrawJobs();

        this.drawPlaneTemp();

        let visibleBoxes = new Set();
        let draw = (boxes, visible) => {
            boxes.forEach(box => {
                if (box.children) {
                    draw(box.children, visible && box.visible);
                } else {
                    if (box.visible && visible) {
                        visibleBoxes.add(box.type_id + '.' + box.id);
                        this.drawObjectsByType({
                            scene: this.planeScene,
                            box,
                            visible: visible && box.active,
                            solution: true,
                        });
                    }
                }
            });
        };

        draw(this.props.tree, true);

        let positions = [];
        let colors = [];
        let temps = [];

        visibleBoxes.size &&
        this.props.plane_solutions &&
        this.props.plane_solutions.forEach(({
                                                id,
                                                result,
                                            }, idx) => {
            let plane = this.props.planes.find(x => x.id === id);
            plane && plane.visible && result.forEach(box => {
                if (visibleBoxes.has(box.type + '.' + box.id)) {
                    if (box.temp > this.solutionTemp.max || box.temp < this.solutionTemp.min)
                        return;
                    switch (box.plane) {
                        case 'xy':
                            positions.push(+box.x, +box.y, +box.z);
                            positions.push(+box.x + box.dx, +box.y, +box.z);
                            positions.push(+box.x + box.dx, +box.y + box.dy, +box.z);
                            positions.push(+box.x, +box.y, +box.z);
                            positions.push(+box.x + box.dx, +box.y + box.dy, +box.z);
                            positions.push(+box.x, +box.y + box.dy, +box.z);
                            break;
                        case 'xz':
                            positions.push(+box.x, +box.y, +box.z);
                            positions.push(+box.x + box.dx, +box.y, +box.z);
                            positions.push(+box.x + box.dx, +box.y, +box.z + box.dz);
                            positions.push(+box.x, +box.y, +box.z);
                            positions.push(+box.x + box.dx, +box.y, +box.z + box.dz);
                            positions.push(+box.x, +box.y, +box.z + box.dz);
                            break;
                        case 'yz':
                            positions.push(+box.x, +box.y, +box.z);
                            positions.push(+box.x, +box.y + box.dy, +box.z);
                            positions.push(+box.x, +box.y + box.dy, +box.z + box.dz);
                            positions.push(+box.x, +box.y, +box.z);
                            positions.push(+box.x, +box.y + box.dy, +box.z + box.dz);
                            positions.push(+box.x, +box.y, +box.z + box.dz);
                            break;
                    }

                    temps.push(box.temp);
                    let color = this.lut.getColor(box.temp);

                    // get the colors as an array of values from 0 to 255
                    const rgb = color.toArray();

                    colors.push(rgb[0], rgb[1], rgb[2]);
                    colors.push(rgb[0], rgb[1], rgb[2]);
                    colors.push(rgb[0], rgb[1], rgb[2]);
                    colors.push(rgb[0], rgb[1], rgb[2]);
                    colors.push(rgb[0], rgb[1], rgb[2]);
                    colors.push(rgb[0], rgb[1], rgb[2]);
                }
            });
        });

        if (positions.length) {
            const geometry = new THREE.BufferGeometry();
            geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
            geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

            const material = new THREE.MeshBasicMaterial({
                vertexColors: THREE.VertexColors,
                side: THREE.DoubleSide,
                clippingPlanes: this.clipPlanes,
            });
            let cube = new THREE.Mesh(geometry, material);
            cube.userData = temps;
            this.planeScene.add(cube);
            this.solutionPlanes.push(cube);
        }

        if (this.props.current_project) {
            this.drawWorld(this.planeScene, true);
        }

        if (this.props.clipping) {
            if (this.clipFaces.length) {
                this.reAddClippingObjects(this.planeScene);
            }
        }
    }

    onMouseMove(event) {
        if (!this.dom) {
            return;
        }

        if (this.props.mode === 'box' &&
            this.props.selectedObject && this.props.selectedObject.id && this.movementAxes.children) {

            let rect = event.target.getBoundingClientRect(),
                new_mouse_x = ((event.clientX - rect.left) / this.dom.offsetWidth) * 2 - 1,
                new_mouse_y = -((event.clientY - rect.top) / this.dom.offsetHeight) * 2 + 1;

            this.raycaster.setFromCamera(new THREE.Vector2(new_mouse_x, new_mouse_y), this.camera);

            if (this.moveDirection) {
                event.stopPropagation();
                event.preventDefault();
                let intersects = this.raycaster.intersectObjects([this.dragPlane]);

                if (intersects.length) {
                    this.controls.enabled = false;
                    document.body.style.cursor = 'move';


                    const {
                        axis,
                        position,
                    } = this.moveDirection.userData;

                    if (!this.movementInitialCoords) {
                        this.movementInitialCoords = {...intersects[0].point};
                    }

                    let deltaAxis0 = (intersects[0].point[axis[0]] - (+this.movementInitialCoords[axis[0]])),
                        deltaAxis1 = (intersects[0].point[axis[1]] - (+this.movementInitialCoords[axis[1]])),
                        snappedIntersectPointX0,
                        snappedIntersectPointX1,
                        roundedAxis0Coord,
                        roundedAxis1Coord,
                        snap = false;

                    if (this.props.gridSnap !== 0) {
                        snappedIntersectPointX0 = boxMovementUtils.getGridSnapPosition({
                            gridSnap: this.props.gridSnap,
                            objCoord: intersects[0].point[axis[0]],
                        }); // get whole coordinate axis0
                        snappedIntersectPointX1 = boxMovementUtils.getGridSnapPosition({
                            gridSnap: this.props.gridSnap,
                            objCoord: intersects[0].point[axis[1]],
                        }); // get whole coordinate axis1

                        deltaAxis0 = (snappedIntersectPointX0 - (+this.movementInitialCoords[axis[0]]));
                        deltaAxis1 = (snappedIntersectPointX1 - (+this.movementInitialCoords[axis[1]]));

                        roundedAxis0Coord = boxMovementUtils.getGridSnapPosition({
                            gridSnap: this.props.gridSnap,
                            delta: deltaAxis0,
                            objCoord: this.movementAxes.position[axis[0]],
                        });
                        deltaAxis0 = roundedAxis0Coord - (+this.movementAxes.position[axis[0]]);

                        if (axis[1]) {
                            roundedAxis1Coord = boxMovementUtils.getGridSnapPosition({
                                gridSnap: this.props.gridSnap,
                                delta: deltaAxis1,
                                objCoord: this.movementAxes.position[axis[1]],
                            });
                            deltaAxis1 = roundedAxis1Coord - (+this.movementAxes.position[axis[1]]);
                        }

                        snap = true;
                    }

                    this.movementInitialCoords[axis[0]] = snap ? snappedIntersectPointX0 : intersects[0].point[axis[0]];
                    if (axis[1]) {
                        this.movementInitialCoords[axis[1]] = snap ? snappedIntersectPointX1 : intersects[0].point[axis[1]];
                    }


                    this.movementAxes.position[`set${axis[0].toUpperCase()}`](roundedAxis0Coord !== undefined ? roundedAxis0Coord : +this.movementAxes.position[axis[0]] + deltaAxis0);
                    axis[1] && this.movementAxes.position[`set${axis[1].toUpperCase()}`](roundedAxis1Coord !== undefined ? roundedAxis1Coord : +this.movementAxes.position[axis[1]] + deltaAxis1);

                    this.movingObj.position.copy(this.movementAxes.position);

                    let currentUpdatedCoords = {
                        x: 0,
                        y: 0,
                        z: 0,
                        dx: 0,
                        dy: 0,
                        dz: 0,
                    };
                    const deltas = {
                        dx: (+this.movementAxes.position.x - (+this.tempMoving3DPosition.x)),
                        dy: (+this.movementAxes.position.y - (+this.tempMoving3DPosition.y)),
                        dz: (+this.movementAxes.position.z - (+this.tempMoving3DPosition.z)),
                    };
                    switch (this.props.selectedObject.type) {
                        case 'assembly':
                            currentUpdatedCoords.x = +this.props.selectedObject['x_calc'] + deltas.dx;
                            currentUpdatedCoords.y = +this.props.selectedObject['y_calc'] + deltas.dy;
                            currentUpdatedCoords.z = +this.props.selectedObject['z_calc'] + deltas.dz;
                            currentUpdatedCoords.dx = deltas.dx;
                            currentUpdatedCoords.dy = deltas.dy;
                            currentUpdatedCoords.dz = deltas.dz;

                            if (snap) {
                                currentUpdatedCoords[axis[0]] = boxMovementUtils.getGridSnapPosition({
                                    gridSnap: this.props.gridSnap,
                                    delta: deltas[`d${axis[0]}`],
                                    objCoord: this.props.selectedObject[`${axis[0]}_calc`],
                                });
                                currentUpdatedCoords[`d${axis[0]}`] = currentUpdatedCoords[axis[0]] - (+this.props.selectedObject[`${axis[0]}_calc`]);
                                if (axis[1]) {
                                    currentUpdatedCoords[axis[1]] = boxMovementUtils.getGridSnapPosition({
                                        gridSnap: this.props.gridSnap,
                                        delta: deltas[`d${axis[1]}`],
                                        objCoord: this.props.selectedObject[`${axis[1]}_calc`],
                                    });
                                    currentUpdatedCoords[`d${axis[1]}`] = currentUpdatedCoords[axis[1]] - (+this.props.selectedObject[`${axis[1]}_calc`]);
                                }
                            }
                            break;
                        case 'plane':
                            switch (this.props.selectedObject.plane) {
                                case 'XY':
                                    if (snap) {
                                        currentUpdatedCoords.z = boxMovementUtils.getGridSnapPosition({
                                            gridSnap: this.props.gridSnap,
                                            delta: deltas.dz,
                                            objCoord: this.props.selectedObject['coordinate'],
                                        });
                                        currentUpdatedCoords.dz = currentUpdatedCoords.z - (+this.props.selectedObject['coordinate']);
                                        break;
                                    }

                                    currentUpdatedCoords.z = +this.props.selectedObject['coordinate'] + deltas.dz;
                                    currentUpdatedCoords.dz = currentUpdatedCoords.z - (+this.props.selectedObject['coordinate']);
                                    break;
                                case 'XZ':
                                    if (snap) {
                                        currentUpdatedCoords.y = boxMovementUtils.getGridSnapPosition({
                                            gridSnap: this.props.gridSnap,
                                            delta: deltas.dy,
                                            objCoord: this.props.selectedObject['coordinate'],
                                        });
                                        currentUpdatedCoords.dy = currentUpdatedCoords.y - (+this.props.selectedObject['coordinate']);
                                        break;
                                    }

                                    currentUpdatedCoords.y = +this.props.selectedObject['coordinate'] + deltas.dy;
                                    currentUpdatedCoords.dy = deltas.dy;
                                    break;
                                case 'YZ':
                                    if (snap) {
                                        currentUpdatedCoords.x = boxMovementUtils.getGridSnapPosition({
                                            gridSnap: this.props.gridSnap,
                                            delta: deltas.dx,
                                            objCoord: this.props.selectedObject['coordinate'],
                                        });
                                        currentUpdatedCoords.dx = currentUpdatedCoords.x - (+this.props.selectedObject['coordinate']);
                                        break;
                                    }

                                    currentUpdatedCoords.x = +this.props.selectedObject['coordinate'] + deltas.dx;
                                    currentUpdatedCoords.dx = deltas.dx;

                                    break;
                            }
                            break;
                        default:
                            currentUpdatedCoords.x = +this.props.selectedObject['x'] + deltas.dx;
                            currentUpdatedCoords.y = +this.props.selectedObject['y'] + deltas.dy;
                            currentUpdatedCoords.z = +this.props.selectedObject['z'] + deltas.dz;
                            currentUpdatedCoords.dx = deltas.dx;
                            currentUpdatedCoords.dy = deltas.dy;
                            currentUpdatedCoords.dz = deltas.dz;

                            if (snap) {
                                currentUpdatedCoords[axis[0]] = boxMovementUtils.getGridSnapPosition({
                                    gridSnap: this.props.gridSnap,
                                    delta: deltas[`d${axis[0]}`],
                                    objCoord: this.props.selectedObject[axis[0]],
                                });
                                // Math.round((+this.props.selectedObject[axis[0]] + deltas[`d${axis[0]}`]) / this.props.gridSnap) * this.props.gridSnap;
                                currentUpdatedCoords[`d${axis[0]}`] = currentUpdatedCoords[axis[0]] - (+this.props.selectedObject[axis[0]]);

                                if (axis[1]) {
                                    currentUpdatedCoords[axis[1]] = boxMovementUtils.getGridSnapPosition({
                                        gridSnap: this.props.gridSnap,
                                        delta: deltas[`d${axis[1]}`],
                                        objCoord: this.props.selectedObject[axis[1]],
                                    });
                                    currentUpdatedCoords[`d${axis[1]}`] = currentUpdatedCoords[axis[1]] - (+this.props.selectedObject[axis[1]]);
                                }
                            }

                    }

                    if (this.props.selectedObject && this.props.selectedObject.id) {
                        newCoordinatesTimeOut && clearTimeout(newCoordinatesTimeOut);
                        newCoordinatesTimeOut = setTimeout(() => {
                            this.setState({
                                newCoordinates: {
                                    ...this.movementAxes.position,
                                    updatedCoordinates: currentUpdatedCoords,
                                },
                            });
                        }, 20);
                    }
                }
            } else {
                let intersects = this.raycaster.intersectObjects([...this.movementAxes.children.filter(axis => axis.userData?.axis)], true);

                if (intersects.length > 0 && intersects[0].object.userData.hasOwnProperty('axis')) {
                    if (this.intersectedMovementAxes !== intersects[0].object) {
                        boxMovementUtils.axesHoverState({intersected: this.intersectedMovementAxes});

                        this.intersectedMovementAxes = intersects[0].object;
                        boxMovementUtils.axesHoverState({
                            intersected: this.intersectedMovementAxes,
                            opacity: 1,
                        });

                        document.body.style.cursor = 'move';
                    }
                } else if (this.intersectedMovementAxes) {
                    boxMovementUtils.axesHoverState({intersected: this.intersectedMovementAxes});

                    this.intersectedMovementAxes = null;
                    document.body.style.cursor = 'default';
                }
            }
        }

        if (this.props.clipping && this.dragClipFace) {
            event.stopPropagation();
            event.preventDefault();
            let rect = event.target.getBoundingClientRect(),
                new_mouse_x = ((event.clientX - rect.left) / this.dom.offsetWidth) * 2 - 1,
                new_mouse_y = -((event.clientY - rect.top) / this.dom.offsetHeight) * 2 + 1;

            this.raycaster.setFromCamera(new THREE.Vector2(new_mouse_x, new_mouse_y), this.camera);
            let intersects = this.raycaster.intersectObjects([this.dragPlane]);

            if (intersects.length) {
                const currentAxisPosition = this.dragClipFace.userData.planeAxis;
                const [axis, linePosition] = currentAxisPosition;
                const planeIndex = clipPlanesMapping[currentAxisPosition];
                const newPointPositionAccordingToMouse = intersects[0].point[axis];
                const currentAxisToUpperCase = axis.toUpperCase();

                const currentClippingBoxAxisValue = this[`clippedBox${oppositeBoxAxisFaceMapping[currentAxisPosition]}`];

                // drag plane only till opposite face position reached
                if (
                    (planeIndex > 2 && newPointPositionAccordingToMouse > (currentClippingBoxAxisValue - this.planeFaceOffset)) ||
                    (planeIndex < 3 && newPointPositionAccordingToMouse < (currentClippingBoxAxisValue + this.planeFaceOffset))
                ) {

                    // drag plane only to inner
                    // if (
                    //     (planeIndex > 2 && newPointPositionAccordingToMouse <= (currentClippingBoxAxisValue + this.planeFaceOffset)) ||
                    //     (planeIndex < 3 && newPointPositionAccordingToMouse >= (currentClippingBoxAxisValue - this.planeFaceOffset))
                    // ) {
                    for (let clipLine in this.clipLines) {
                        const {
                            fullIn,
                            partialIn,
                        } = this.clipLines[clipLine].userData.clipLineAxes;
                        const [x0, y0, z0, x1, y1, z1] = this.clipLines[clipLine].geometry.attributes.position.array;

                        const isFullLineInDraggedFace = fullIn.includes(currentAxisPosition);
                        const isPartialLineInDraggedFace = partialIn.includes(currentAxisPosition);

                        const points = [];
                        if (isFullLineInDraggedFace || isPartialLineInDraggedFace) {
                            switch (axis) {
                                case 'x':
                                    points.push(new THREE.Vector3(isFullLineInDraggedFace || +linePosition === 0 ? x0 - (x0 - newPointPositionAccordingToMouse) : x0, y0, z0));
                                    points.push(new THREE.Vector3(isFullLineInDraggedFace || +linePosition === 1 ? x1 - (x1 - newPointPositionAccordingToMouse) : x1, y1, z1));
                                    break;
                                case 'y':
                                    points.push(new THREE.Vector3(x0, isFullLineInDraggedFace || +linePosition === 0 ? y0 - (y0 - newPointPositionAccordingToMouse) : y0, z0));
                                    points.push(new THREE.Vector3(x1, isFullLineInDraggedFace || +linePosition === 1 ? y1 - (y1 - newPointPositionAccordingToMouse) : y1, z1));
                                    break;
                                case 'z':
                                    points.push(new THREE.Vector3(x0, y0, isFullLineInDraggedFace || +linePosition === 0 ? z0 - (z0 - newPointPositionAccordingToMouse) : z0));
                                    points.push(new THREE.Vector3(x1, y1, isFullLineInDraggedFace || +linePosition === 1 ? z1 - (z1 - newPointPositionAccordingToMouse) : z1));
                                    break;
                            }

                            this.clipLines[clipLine].geometry.setFromPoints(points);
                        }
                    }

                    switch (currentAxisPosition) {
                        case 'x0':
                            this.clippedBoxX0 = newPointPositionAccordingToMouse + this.planeFaceOffset;
                            break;
                        case 'x1':
                            this.clippedBoxX1 = newPointPositionAccordingToMouse - this.planeFaceOffset;
                            break;
                        case 'y0':
                            this.clippedBoxY0 = newPointPositionAccordingToMouse + this.planeFaceOffset;
                            break;
                        case 'y1':
                            this.clippedBoxY1 = newPointPositionAccordingToMouse - this.planeFaceOffset;
                            break;
                        case 'z0':
                            this.clippedBoxZ0 = newPointPositionAccordingToMouse + this.planeFaceOffset;
                            break;
                        case 'z1':
                            this.clippedBoxZ1 = newPointPositionAccordingToMouse - this.planeFaceOffset;
                            break;
                    }

                    this.maxXAxisSize = this.clippedBoxX1 - this.clippedBoxX0;
                    this.maxYAxisSize = this.clippedBoxY1 - this.clippedBoxY0;
                    this.maxZAxisSize = this.clippedBoxZ1 - this.clippedBoxZ0;

                    this.halfXAxisSize = (this.maxXAxisSize / 2);
                    this.halfYAxisSize = (this.maxYAxisSize / 2);
                    this.halfZAxisSize = (this.maxZAxisSize / 2);

                    let dragPlaneGeometryX, dragPlaneGeometryY, dragPlaneGeometryZ, newFacePositions;
                    switch (currentAxisPosition) {
                        case 'x0':
                        case 'x1':
                            dragPlaneGeometryY = new THREE.PlaneGeometry(this.maxXAxisSize + this.planeFaceOffsetStartEnd, this.maxZAxisSize + this.planeFaceOffsetStartEnd);
                            dragPlaneGeometryZ = new THREE.PlaneGeometry(this.maxXAxisSize + this.planeFaceOffsetStartEnd, this.maxYAxisSize + this.planeFaceOffsetStartEnd);
                            newFacePositions = this.clippedBoxX0 + this.halfXAxisSize;

                            this.clipFaces[2].geometry.copy(dragPlaneGeometryY);
                            this.clipFaces[3].geometry.copy(dragPlaneGeometryY);
                            this.clipFaces[4].geometry.copy(dragPlaneGeometryZ);
                            this.clipFaces[5].geometry.copy(dragPlaneGeometryZ);

                            this.clipFaces[2].position.setX(newFacePositions);
                            this.clipFaces[3].position.setX(newFacePositions);
                            this.clipFaces[4].position.setX(newFacePositions);
                            this.clipFaces[5].position.setX(newFacePositions);
                            break;

                        case 'y0':
                        case 'y1':
                            dragPlaneGeometryX = new THREE.PlaneGeometry(this.maxZAxisSize + this.planeFaceOffsetStartEnd, this.maxYAxisSize + this.planeFaceOffsetStartEnd);
                            dragPlaneGeometryZ = new THREE.PlaneGeometry(this.maxXAxisSize + this.planeFaceOffsetStartEnd, this.maxYAxisSize + this.planeFaceOffsetStartEnd);
                            newFacePositions = this.clippedBoxY0 + this.halfYAxisSize;

                            this.clipFaces[0].geometry.copy(dragPlaneGeometryX);
                            this.clipFaces[1].geometry.copy(dragPlaneGeometryX);
                            this.clipFaces[4].geometry.copy(dragPlaneGeometryZ);
                            this.clipFaces[5].geometry.copy(dragPlaneGeometryZ);

                            this.clipFaces[0].position.setY(newFacePositions);
                            this.clipFaces[1].position.setY(newFacePositions);
                            this.clipFaces[4].position.setY(newFacePositions);
                            this.clipFaces[5].position.setY(newFacePositions);
                            break;

                        case 'z0':
                        case 'z1':
                            dragPlaneGeometryX = new THREE.PlaneGeometry(this.maxZAxisSize + this.planeFaceOffsetStartEnd, this.maxYAxisSize + this.planeFaceOffsetStartEnd);
                            dragPlaneGeometryY = new THREE.PlaneGeometry(this.maxXAxisSize + this.planeFaceOffsetStartEnd, this.maxZAxisSize + this.planeFaceOffsetStartEnd);
                            newFacePositions = this.clippedBoxZ0 + this.halfZAxisSize;

                            this.clipFaces[0].geometry.copy(dragPlaneGeometryX);
                            this.clipFaces[1].geometry.copy(dragPlaneGeometryX);
                            this.clipFaces[2].geometry.copy(dragPlaneGeometryY);
                            this.clipFaces[3].geometry.copy(dragPlaneGeometryY);

                            this.clipFaces[0].position.setZ(newFacePositions);
                            this.clipFaces[1].position.setZ(newFacePositions);
                            this.clipFaces[2].position.setZ(newFacePositions);
                            this.clipFaces[3].position.setZ(newFacePositions);
                            break;
                    }

                    this.clipPlanes[planeIndex].constant = planeIndex > 2 ? newPointPositionAccordingToMouse : -newPointPositionAccordingToMouse;
                    this.dragClipFace.position[`set${currentAxisToUpperCase}`](newPointPositionAccordingToMouse);

                    if (this.props.mode === 'box') {
                        let plane;
                        switch (currentAxisPosition[0]) {
                            case 'x':
                                plane = 'yz';
                                break;
                            case 'y':
                                plane = 'xz';
                                break;
                            case 'z':
                                plane = 'xy';
                                break;
                        }

                        if (loadPlaneSolutionsTimePointTimeouts[currentAxisPosition]) {
                            clearTimeout(loadPlaneSolutionsTimePointTimeouts[currentAxisPosition]);
                        }

                        loadPlaneSolutionsTimePointTimeouts[currentAxisPosition] = setTimeout(() => {
                            this.props.storeClipFaceData({
                                coordinate: newPointPositionAccordingToMouse,
                                plane,
                                axis: currentAxisPosition,
                            })
                        }, 500);

                        if (loadPlaneSolutionsTimeouts[currentAxisPosition]) {
                            clearTimeout(loadPlaneSolutionsTimeouts[currentAxisPosition]);
                        }

                        loadPlaneSolutionsTimeouts[currentAxisPosition] = setTimeout(() => {
                            this.props.storeClipFaceData({
                                coordinate: newPointPositionAccordingToMouse,
                                plane,
                                axis: currentAxisPosition,
                            })

                            this.props[`loadClipFaceSolution_${currentAxisPosition}`]({
                                project_id: this.props.current_project.id,
                                coordinate: newPointPositionAccordingToMouse,
                                plane,
                            });
                        }, 200);
                    }
                }
            }
        } else {
            let rect = event.target.getBoundingClientRect(),
                new_mouse_x = ((event.clientX - rect.left - this.dom.offsetWidth + this.axesSceneSize) / this.axesSceneSize) * 2 - 1,
                new_mouse_y = -((event.clientY - rect.top - this.dom.offsetHeight + this.axesSceneSize) / this.axesSceneSize) * 2 + 1;

            this.raycaster.setFromCamera(new THREE.Vector2(new_mouse_x, new_mouse_y), this.axesCamera);

            let intersects = this.raycaster.intersectObjects(this.fitObjects);

            if (intersects.length) {
                if (this.intersectedAxesObj !== intersects[0].object) {
                    if (this.intersectedAxesObj) {
                        this.intersectedAxesObj.material.emissive.setHex(this.intersectedAxesObj.currentColor);
                    }
                    this.intersectedAxesObj = intersects[0].object;
                    this.intersectedAxesObj.currentColor = this.intersectedAxesObj.material.emissive.getHex();
                    this.intersectedAxesObj.material.emissive.setHex(0xa0a0a0);
                }
            } else if (this.intersectedAxesObj) {
                this.intersectedAxesObj.material.emissive.setHex(this.intersectedAxesObj.currentColor);
                this.intersectedAxesObj = null;
            }

            if (this.props.clipping)
                this.clipLines.forEach(line => line.material.color = this.lineColorDark);

            if (this.props.clipping && event.shiftKey) {
                let rect = event.target.getBoundingClientRect(),
                    new_mouse_x = ((event.clientX - rect.left) / this.dom.offsetWidth) * 2 - 1,
                    new_mouse_y = -((event.clientY - rect.top) / this.dom.offsetHeight) * 2 + 1;

                this.raycaster.setFromCamera(new THREE.Vector2(new_mouse_x, new_mouse_y), this.camera);
                let intersects = this.raycaster.intersectObjects(this.clipFaces);

                if (intersects.length > 0) {
                    let dragClipFace = intersects[0].object;
                    let intersectionPoint = intersects[0].point;

                    const [axis, linePosition] = dragClipFace.userData.planeAxis;

                    this.clipLines.forEach(line => {
                        if (line.userData.clipLineAxes.fullIn.includes(dragClipFace.userData.planeAxis)) {
                            line.material.color = this.selectedLineColor;
                        }
                    });
                }
            } else if (this.props.mode === 'solution') {
                if (!this.dom) {
                    return;
                }

                let new_mouse_x = ((event.clientX - rect.left) / this.dom.offsetWidth) * 2 - 1,
                    new_mouse_y = -((event.clientY - rect.top) / this.dom.offsetHeight) * 2 + 1;
                this.raycaster.setFromCamera(new THREE.Vector2(new_mouse_x, new_mouse_y), this.camera);
                let intersects = this.raycaster.intersectObjects(this.solutionBoxes, true);
                this.pointTemp = intersects.length && intersects[0].object.userData.temps[Math.floor(intersects[0].faceIndex / 2)];
                this.drawSolutionTemp();
                if (intersects.length) {
                    this.drawCoords(intersects[0].point, intersects[0].object.userData.obj);
                } else {
                    this.coordScene.clear();
                }
            } else if (this.props.mode === 'box') {
                if (!this.dom) {
                    return;
                }

                let new_mouse_x = ((event.clientX - rect.left) / this.dom.offsetWidth) * 2 - 1,
                    new_mouse_y = -((event.clientY - rect.top) / this.dom.offsetHeight) * 2 + 1;

                this.raycaster.setFromCamera(new THREE.Vector2(new_mouse_x, new_mouse_y), this.camera);
                let intersects = this.raycaster.intersectObjects(this.clipSolutionPlanes, true);

                if (intersects.length) {
                    this.pointTemp = intersects[0].object.userData[Math.floor(intersects[0].faceIndex / 2)];
                    this.drawClipPlaneSolutionTemp();
                } else {
                    if (this.pointTemp) {
                        this.pointTemp = null;
                        this.drawClipPlaneSolutionTemp();
                    }
                    intersects = this.raycaster.intersectObjects(this.objects, true);
                    if (intersects.length && !this.moveDirection && !this.intersectedMovementAxes) {
                        this.drawCoords(intersects[0].point, intersects[0].object.userData);
                    } else if (this.pointTemp && intersects.length) {
                        this.drawCoords(intersects[0].point);
                    } else {
                        this.coordScene.clear();
                    }
                }
            } else if (this.props.mode === 'plane') {
                if (!this.dom) {
                    return;
                }

                let new_mouse_x = ((event.clientX - rect.left) / this.dom.offsetWidth) * 2 - 1,
                    new_mouse_y = -((event.clientY - rect.top) / this.dom.offsetHeight) * 2 + 1;

                this.raycaster.setFromCamera(new THREE.Vector2(new_mouse_x, new_mouse_y), this.camera);
                let intersects = this.raycaster.intersectObjects(this.solutionPlanes, true);
                if (intersects.length) {
                    this.pointTemp = intersects[0].object.userData[Math.floor(intersects[0].faceIndex / 2)];
                    this.drawPlaneTemp();
                } else if (this.pointTemp) {
                    this.pointTemp = null;
                    this.drawPlaneTemp();
                }
            }
        }
    }

    onMouseUp = (event) => {
        this.scene.remove(this.dragPlane);
        this.controls.enabled = true;

        if (Math.abs(this.mouse.x - event.clientX) < 5 && Math.abs(this.mouse.y - event.clientY) < 5) {
            let rect = event.target.getBoundingClientRect(),
                new_mouse_x = ((event.clientX - rect.left - this.dom.offsetWidth + this.axesSceneSize) / this.axesSceneSize) * 2 - 1,
                new_mouse_y = -((event.clientY - rect.top - this.dom.offsetHeight + this.axesSceneSize) / this.axesSceneSize) * 2 + 1;

            this.raycaster.setFromCamera(new THREE.Vector2(new_mouse_x, new_mouse_y), this.axesCamera);
            let intersects = this.raycaster.intersectObjects(this.fitObjects);

            if (intersects.length) {
                intersects[0].object.userData.fit();
                return;
            }

            new_mouse_x = ((event.clientX - rect.left) / this.dom.offsetWidth) * 2 - 1;
            new_mouse_y = -((event.clientY - rect.top) / this.dom.offsetHeight) * 2 + 1;

            if (this.props.mode !== 'box' || this.props.clipFacePlaneSolutionsHasData) {
                this.raycaster.setFromCamera(new THREE.Vector2(new_mouse_x, new_mouse_y), this.tempCamera);
                intersects = this.raycaster.intersectObjects([this.sprite], true);

                if (intersects.length) {
                    this.props.showModal({
                        type: 'custom',
                        title: 'Edit Temp Limits',
                        content: (props) => {
                            return <TempSettings {...this.state.temp}
                                                 onClose={({
                                                               min_t,
                                                               max_t,
                                                               colormap,
                                                               filter,
                                                           }) => {
                                                     this.setState({
                                                         temp: {
                                                             min_t: isNaN(parseFloat(min_t)) ? null : parseFloat(min_t),
                                                             max_t: isNaN(parseFloat(max_t)) ? null : parseFloat(max_t),
                                                             colormap: colormap,
                                                             filter: filter,
                                                         },
                                                         lut: new Lut().setColorMap(colormap, 256).lut,
                                                     });
                                                 }}/>;
                        },
                    });
                    return;
                }
            }

            if (event.ctrlKey) {
                this.raycaster.setFromCamera(new THREE.Vector2(new_mouse_x, new_mouse_y), this.camera);
                let intersects = this.raycaster.intersectObjects(this.nameSprites.map(sprite => sprite.sprite), true);
                let removed = false;

                if (intersects.length) {
                    this.nameSprites = this.nameSprites.filter(sprite => {
                        if (sprite.sprite === intersects[0].object) {
                            this.scene.remove(sprite.sprite);
                            this.scene.remove(sprite.line);
                            removed |= true;
                            return false;
                        }

                        return true;
                    });
                }
                intersects = this.raycaster.intersectObjects(this.nameSpritesSolution.map(sprite => sprite.sprite), true);

                if (intersects.length) {
                    this.nameSpritesSolution = this.nameSpritesSolution.filter(sprite => {
                        if (sprite.sprite === intersects[0].object) {
                            this.solutionScene.remove(sprite.sprite);
                            this.solutionScene.remove(sprite.line);
                            removed |= true;
                            return false;
                        }

                        return true;
                    });
                }

                if (!removed && (this.props.mode === 'box' || this.props.mode === 'solution')) {
                    intersects = this.raycaster.intersectObjects(this.objects, true);
                    if (intersects.length) {
                        drawObjectUtils.drawSprite({
                            scene: this.scene,
                            intersect: intersects[0],
                            sprites: this.nameSprites,
                            spriteText: intersects[0].object.userData.name,
                            textSized: {
                                textHeight: this.textHeight,
                                fontFace: this.fontFace,
                                fontWeight: this.fontWeight,
                            },
                        });
                    }

                    intersects = this.raycaster.intersectObjects(this.solutionBoxes, true);
                    if (intersects.length) {
                        const pointTemp = intersects[0].object.userData.temps[Math.floor(intersects[0].faceIndex / 2)];

                        drawObjectUtils.drawSprite({
                            scene: this.solutionScene,
                            intersect: intersects[0],
                            sprites: this.nameSpritesSolution,
                            spriteText: intersects[0].object.userData.obj.name + '\n' + pointTemp.toFixed(1) + '°C',
                            textSized: {
                                textHeight: this.textHeight,
                                fontFace: this.fontFace,
                                fontWeight: this.fontWeight,
                            },
                        });
                    }

                    return;
                }
            } else {
                this.raycaster.setFromCamera(new THREE.Vector2(new_mouse_x, new_mouse_y), this.camera);
                intersects = this.raycaster
                    .intersectObjects([...this.objects, ...(this.props.mode !== 'solution' && this.planes || [])], true)
                    .filter(x => x.face);

                if (intersects.length && intersects[0].object.userData.id) {

                    if (event.altKey) {
                        const userData = intersects[0].object.userData;
                        const SettingsComponent = MAPPINGS.projectBoxSettings.intersectComponents[userData.type];
                        if (!SettingsComponent) {
                            return console.log(userData);
                        }

                        this.props.showModal({
                            type: 'custom',
                            title: `Edit ${MAPPINGS.titles[userData.type]}`,
                            content: (props) => {
                                return <SettingsComponent {...props} assembly={userData} box={userData}/>;
                            },
                        });
                    }

                    if (!this.props.selectedObject?.id || this.props.selectedObject?.id !== intersects[0].object?.userData?.id) {
                        this.axesScale = intersects[0].distance * axesScalePercentage;
                        this.props.setSelectedObject(intersects[0].object.userData);
                    }
                } else if (this.props.selectedObject?.id) {
                    this.props.setSelectedObject();
                }
            }
        }

        if (
            this.props.mode === 'box' &&
            this.props.selectedObject && this.props.selectedObject.id && this.moveDirection &&
            this.state.newCoordinates && this.state.newCoordinates.updatedCoordinates) {
            const {axis} = this.moveDirection.userData;
            const updatedDraggedObjSettings = JSON.parse(JSON.stringify(this.props.selectedObject));

            let newX = +this.props.selectedObject.x,
                newY = +this.props.selectedObject.y,
                newZ = +this.props.selectedObject.z;

            if (updatedDraggedObjSettings.type === 'assembly') {
                const translate = {
                    dx: 0,
                    dy: 0,
                    dz: 0,
                };

                switch (axis) {
                    case 'x':
                        newX = Math.round((this.state.newCoordinates.updatedCoordinates.x * 100)) / 100;
                        translate.dx = Math.round(this.state.newCoordinates.updatedCoordinates.dx * 100) / 100;
                        updatedDraggedObjSettings.x_calc = newX;

                        break;
                    case 'y':
                        newY = Math.round((this.state.newCoordinates.updatedCoordinates.y * 100)) / 100;
                        translate.dy = Math.round(this.state.newCoordinates.updatedCoordinates.dy * 100) / 100;
                        updatedDraggedObjSettings.y_calc = newY;

                        break;
                    case 'z':
                        newZ = Math.round((this.state.newCoordinates.updatedCoordinates.z * 100)) / 100;
                        translate.dz = Math.round(this.state.newCoordinates.updatedCoordinates.dz * 100) / 100;
                        updatedDraggedObjSettings.z_calc = newZ;

                        break;
                    case 'xz':
                        newX = Math.round((this.state.newCoordinates.updatedCoordinates.x * 100)) / 100;
                        newZ = Math.round((this.state.newCoordinates.updatedCoordinates.z * 100)) / 100;

                        translate.dx = Math.round(this.state.newCoordinates.updatedCoordinates.dx * 100) / 100;
                        translate.dz = Math.round(this.state.newCoordinates.updatedCoordinates.dz * 100) / 100;

                        updatedDraggedObjSettings.x_calc = newX;
                        updatedDraggedObjSettings.z_calc = newZ;

                        break;
                    case 'xy':
                        newX = Math.round((this.state.newCoordinates.updatedCoordinates.x * 100)) / 100;
                        newY = Math.round((this.state.newCoordinates.updatedCoordinates.y * 100)) / 100;

                        translate.dx = Math.round(this.state.newCoordinates.updatedCoordinates.dx * 100) / 100;
                        translate.dy = Math.round(this.state.newCoordinates.updatedCoordinates.dy * 100) / 100;

                        updatedDraggedObjSettings.x_calc = newX;
                        updatedDraggedObjSettings.y_calc = newY;

                        break;
                    case 'yz':
                        newY = Math.round((this.state.newCoordinates.updatedCoordinates.y * 100)) / 100;
                        newZ = Math.round((this.state.newCoordinates.updatedCoordinates.z * 100)) / 100;

                        translate.dy = Math.round(this.state.newCoordinates.updatedCoordinates.dy * 100) / 100;
                        translate.dz = Math.round(this.state.newCoordinates.updatedCoordinates.dz * 100) / 100;

                        updatedDraggedObjSettings.y_calc = newY;
                        updatedDraggedObjSettings.z_calc = newZ;

                        break;
                }

                updatedDraggedObjSettings.children.forEach(element => {
                    updateAssemblyElementsPosition({
                        axis,
                        element,
                        newCoordinates: this.state.newCoordinates.updatedCoordinates,
                    });
                });

                this.props.selectedObject.id !== 'creating' && this.props.translateProjectAssembly({
                    project_id: this.props.current_project.id,
                    assembly_id: updatedDraggedObjSettings.id,
                    translate,
                    box: updatedDraggedObjSettings,
                });
            } else if (updatedDraggedObjSettings.type === 'plane') {
                switch (axis) {
                    case 'x':
                        updatedDraggedObjSettings.coordinate = Math.round(this.state.newCoordinates.updatedCoordinates.x * 100) / 100;
                        break;
                    case 'y':
                        updatedDraggedObjSettings.coordinate = Math.round(this.state.newCoordinates.updatedCoordinates.y * 100) / 100;
                        break;
                    case 'z':
                        updatedDraggedObjSettings.coordinate = Math.round(this.state.newCoordinates.updatedCoordinates.z * 100) / 100;
                        break;
                }

                this.props.selectedObject.id !== 'creating' && this.props.updatePlane({
                    project_id: this.props.current_project.id,
                    plane: updatedDraggedObjSettings,
                });
            } else {
                updateSingleElementPosition({
                    axis,
                    element: updatedDraggedObjSettings,
                    newCoordinates: this.state.newCoordinates.updatedCoordinates,
                });

                this.props.selectedObject.id !== 'creating' && this.props.updateProjectObjectPosition({
                    project_id: this.props.current_project.id,
                    box: updatedDraggedObjSettings,
                    type: MAPPINGS.boxTypes[updatedDraggedObjSettings.type],
                });
            }

            this.tempMoving3DPosition = boxMovementUtils.getPosition(updatedDraggedObjSettings, this.worldPositions);
            this.props.setSelectedObject(updatedDraggedObjSettings);
        }

        this.moveDirection = null;
        this.movementInitialCoords = null;
        this.dragClipFace = null;
        this.clipLines.forEach(line => {
            line.material.color = this.lineColorDark;
        });

        document.body.style.cursor = 'default';
    };

    onMouseDown = (event) => {
        this.mouse.x = event.clientX;
        this.mouse.y = event.clientY;

        const vectorAxes = {
            x: 0,
            y: 0,
            z: 0,
        };

        if (this.props.clipping && event.shiftKey) {
            let rect = event.target.getBoundingClientRect(),
                new_mouse_x = ((event.clientX - rect.left) / this.dom.offsetWidth) * 2 - 1,
                new_mouse_y = -((event.clientY - rect.top) / this.dom.offsetHeight) * 2 + 1;

            this.raycaster.setFromCamera(new THREE.Vector2(new_mouse_x, new_mouse_y), this.camera);
            let intersects = this.raycaster.intersectObjects(this.clipFaces);

            if (intersects.length > 0) {
                this.dragClipFace = intersects[0].object;
                let intersectionPoint = intersects[0].point;

                const [axis, linePosition] = this.dragClipFace.userData.planeAxis;

                this.clipLines.forEach(line => {
                    if (line.userData.clipLineAxes.fullIn.includes(this.dragClipFace.userData.planeAxis)) {
                        line.material.color = this.selectedLineColor;
                    }
                });

                intersectionPoint[`set${axis.toUpperCase()}`](0);
                this.dragPlane.position.copy(intersectionPoint);

                vectorAxes[axis] = -1;
                let newNormal = this.camera.position.clone().sub(
                    this.camera.position.clone().projectOnVector(new THREE.Vector3(vectorAxes.x, vectorAxes.y, vectorAxes.z)),
                );

                newNormal = newNormal.add(intersectionPoint);

                this.dragPlane.lookAt(newNormal);
                this.controls.enabled = false;
                document.body.style.cursor = 'move';
            }
        }

        if (this.props.selectedObject && this.props.selectedObject.id && this.movementAxes.children) {
            let rect = event.target.getBoundingClientRect(),
                new_mouse_x = ((event.clientX - rect.left) / this.dom.offsetWidth) * 2 - 1,
                new_mouse_y = -((event.clientY - rect.top) / this.dom.offsetHeight) * 2 + 1;

            this.raycaster.setFromCamera(new THREE.Vector2(new_mouse_x, new_mouse_y), this.camera);
            let intersects = this.raycaster.intersectObjects([...this.movementAxes.children.filter(axis => axis.userData?.axis)], true);

            if (intersects.length > 0 && intersects[0].object.userData.hasOwnProperty('axis')) {
                this.moveDirection = intersects[0].object;
                const {axis} = this.moveDirection.userData;

                const intersectionPoint = intersects[0].point;
                intersectionPoint[`set${axis[0].toUpperCase()}`](0);
                axis[1] && intersectionPoint[`set${axis[1].toUpperCase()}`](0);

                this.dragPlane.position.copy(intersectionPoint);

                switch (axis) {
                    case 'x':
                    case 'xy':
                        vectorAxes.z = -1;
                        break;
                    case 'y':
                    case 'yz':
                        vectorAxes.x = -1;
                        break;
                    case 'z':
                    case 'xz':
                        vectorAxes.y = -1;
                        break;
                }

                let newNormal = intersectionPoint.clone().add(
                    this.camera.position.clone().projectOnVector(new THREE.Vector3(vectorAxes.x, vectorAxes.y, vectorAxes.z)),
                );

                newNormal = newNormal.add(intersectionPoint);

                this.dragPlane.lookAt(newNormal);
                this.controls.enabled = false;
                document.body.style.cursor = 'move';
            }
        }
    };

    onWindowsResize = () => {
        if (this.dom.offsetWidth && this.dom.offsetHeight) {
            this.camera.aspect = this.dom.offsetWidth / this.dom.offsetHeight;
            this.camera.updateProjectionMatrix();
            this.renderer.setSize(this.dom.offsetWidth, this.dom.offsetHeight);
            this.controls.handleResize();
            this.controls.update();
            this.adjCanvasRef.setAttribute('width', this.dom.offsetWidth);
            this.adjCanvasRef.setAttribute('height', this.dom.offsetHeight);
        }
    };

    setMovingBoxPosition(event, timeout) {
        clearTimeout(this.movingJob);
        event.persist();
        let f = () => {
            if (event.target) {
                let mouse = new THREE.Vector2(),
                    rect = event.target.getBoundingClientRect(),
                    new_mouse_x = ((event.clientX - rect.left) / this.dom.offsetWidth) * 2 - 1,
                    new_mouse_y = -((event.clientY - rect.top) / this.dom.offsetHeight) * 2 + 1;
                mouse.set(new_mouse_x, new_mouse_y);
                this.raycaster.setFromCamera(mouse, this.camera);

                let intersects = this.raycaster.intersectObjects(this.intersects);
                if (intersects.length && this.moving && this.props.drag) {
                    this.moving[this.props.drag].position.copy(intersects[0].point);
                    this.drawCoords(intersects[0].point);
                    return {
                        position: this.moving[this.props.drag].position,
                        object: intersects[0].object.userData,
                    };
                }
            }
            return {
                position: {
                    x: 0,
                    y: 0,
                    z: 0,
                },
                object: null,
            };
        };
        if (timeout)
            this.movingJob = setTimeout(f, 0);
        else
            return f();
    }

    onDragEnter = (event) => {
        if (this.props.mode === 'box')
            this.scene.add(this.moving[this.props.drag]);
        else if (this.props.mode === 'solution')
            this.solutionScene.add(this.moving[this.props.drag]);
        event.preventDefault();
        event.stopPropagation();
        this.setMovingBoxPosition(event, true);
    };

    onDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.setMovingBoxPosition(event, true);
    };

    onDragLeave = (event) => {
        this.coordScene.clear();
        event.preventDefault();
        event.stopPropagation();
        if (this.props.mode === 'box')
            this.scene.remove(this.moving[this.props.drag]);
        else
            this.solutionScene.remove(this.moving[this.props.drag]);
    };

    onDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const {
            position,
            object,
        } = this.setMovingBoxPosition(event);
        const {
            mode,
            drag,
        } = this.props;

        if (mode === 'box')
            this.scene.remove(this.moving[drag]);
        else
            this.solutionScene.remove(this.moving[drag]);

        if (position) {
            const SettingsComponent = MAPPINGS.projectBoxSettings.dragComponents[drag];

            if (!SettingsComponent) {
                return console.log('Unhandled drop of', drag);
            }

            const box = {
                x: (position.x || 0).toFixed(12),
                y: (position.y || 0).toFixed(12),
                z: (position.z || 0).toFixed(12),
                active: true,
                index: (object && object.index) ? object.index + 1 : 0,
                parent: (object && object.parent) || null,
            };

            const firstMaterial = this.props.projectMaterials.sort((a, b) => {
                if (a.name < b.name) return -1; else if (a.name > b.name) return 1;
                return 0;
            })[0];
            this.props.setSelectedObject({...DEFAULT_SETTINGS(firstMaterial, box)[drag]});
            // showModal({
            //     type: 'custom',
            //     title: `Add ${MAPPINGS.titles[drag]}`,
            //     content: (props) => {
            //         return <SettingsComponent {...props} assembly={box} box={box}/>;
            //     },
            // });
        }
    };

    fit = (camera, control) => {
        return (face, distance_multiplier) => () => {

            let bbox = new THREE.Box3();
            if (this.props.tree.length)
                getBBox(this.props.tree, bbox);

            if (bbox.isEmpty()) {
                bbox.min.x = -10;
                bbox.min.y = -10;
                bbox.min.z = -10;
                bbox.max.x = 10;
                bbox.max.y = 10;
                bbox.max.z = 10;
            }

            camera.position.set(
                (bbox.min.x + bbox.max.x) / 2,
                (bbox.min.y + bbox.max.y) / 2,
                (bbox.min.z + bbox.max.z) / 2,
            );

            if (control)
                control.target.set(
                    (bbox.min.x + bbox.max.x) / 2,
                    (bbox.min.y + bbox.max.y) / 2,
                    (bbox.min.z + bbox.max.z) / 2,
                );


            let distance = distance_multiplier / Math.tan(this.cameraFOV * Math.PI / 360) *
                    Math.sqrt(
                        Math.pow(bbox.max.x - bbox.min.x, 2) +
                        Math.pow(bbox.max.y - bbox.min.y, 2) +
                        Math.pow(bbox.max.z - bbox.min.z, 2),
                    ) / 2,
                cameraVec = new THREE.Vector3();

            switch (face) {
                case 'top':
                    cameraVec.set(0, 0, 1);
                    camera.up.set(0, 1, 0).normalize();
                    break;
                case 'bottom':
                    cameraVec.set(0, 0, -1);
                    camera.up.set(0, 1, 0).normalize();
                    break;
                case 'left':
                    cameraVec.set(-1, 0, 0);
                    camera.up.set(0, 0, 1).normalize();
                    break;
                case 'right':
                    cameraVec.set(1, 0, 0);
                    camera.up.set(0, 0, 1).normalize();
                    break;
                case 'front':
                    cameraVec.set(0, -1, 0);
                    camera.up.set(0, 0, 1).normalize();
                    break;
                case 'back':
                    cameraVec.set(0, 1, 0);
                    camera.up.set(0, 0, 1).normalize();
                    break;
                case 'top_front':
                    cameraVec.set(0, -1, 1);
                    camera.up.set(0, 1, 1).normalize();
                    break;
                case 'top_back':
                    cameraVec.set(0, 1, 1);
                    camera.up.set(0, -1, 1).normalize();
                    break;
                case 'top_left':
                    cameraVec.set(-1, 0, 1);
                    camera.up.set(1, 0, 1).normalize();
                    break;
                case 'top_right':
                    cameraVec.set(1, 0, 1);
                    camera.up.set(-1, 0, 1).normalize();
                    break;
                case 'bottom_front':
                    cameraVec.set(0, -1, -1);
                    camera.up.set(0, -1, 1).normalize();
                    break;
                case 'bottom_back':
                    cameraVec.set(0, 1, -1);
                    camera.up.set(0, 1, 1).normalize();
                    break;
                case 'bottom_left':
                    cameraVec.set(-1, 0, -1);
                    camera.up.set(-1, 0, 1).normalize();
                    break;
                case 'bottom_right':
                    cameraVec.set(1, 0, -1);
                    camera.up.set(1, 0, 1).normalize();
                    break;
                case 'left_front':
                    cameraVec.set(-1, -1, 0);
                    camera.up.set(0, 0, 1).normalize();
                    break;
                case 'left_back':
                    cameraVec.set(-1, 1, 0);
                    camera.up.set(0, 0, 1).normalize();
                    break;
                case 'right_front':
                    cameraVec.set(1, -1, 0);
                    camera.up.set(0, 0, 1).normalize();
                    break;
                case 'right_back':
                    cameraVec.set(1, 1, 0);
                    camera.up.set(0, 0, 1).normalize();
                    break;
                case 'top_left_front':
                    cameraVec.set(-1, -1, 1);
                    camera.up.set(1, 1, 1).normalize();
                    break;
                case 'top_left_back':
                    cameraVec.set(-1, 1, 1);
                    camera.up.set(1, -1, 1).normalize();
                    break;
                case 'bottom_left_front':
                    cameraVec.set(-1, -1, -1);
                    camera.up.set(-1, -1, 1).normalize();
                    break;
                case 'bottom_left_back':
                    cameraVec.set(-1, 1, -1);
                    camera.up.set(-1, 1, 1).normalize();
                    break;
                case 'top_right_front':
                    cameraVec.set(1, -1, 1);
                    camera.up.set(-1, 1, 1).normalize();
                    break;
                case 'top_right_back':
                    cameraVec.set(1, 1, 1);
                    camera.up.set(-1, -1, 1).normalize();
                    break;
                case 'bottom_right_front':
                    cameraVec.set(1, -1, -1);
                    camera.up.set(1, -1, 1).normalize();
                    break;
                case 'bottom_right_back':
                    cameraVec.set(1, 1, -1);
                    camera.up.set(1, 1, 1).normalize();
                    break;
            }
            camera.position.add(cameraVec.normalize().multiplyScalar(distance));
            if (!control) {
                this.cameraLookAt = new THREE.Vector3(
                    (bbox.min.x + bbox.max.x) / 2,
                    (bbox.min.y + bbox.max.y) / 2,
                    (bbox.min.z + bbox.max.z) / 2,
                );
                camera.lookAt(
                    this.cameraLookAt,
                );
            }
            camera.updateProjectionMatrix();
        };
    };

    fitObject = () => {
        if (this.props.fit_object) {
            let bbox = new THREE.Box3();
            getBBox([this.props.fit_object], bbox);
            if (!bbox.isEmpty()) {
                this.controls.target.set(
                    (bbox.min.x + bbox.max.x) / 2,
                    (bbox.min.y + bbox.max.y) / 2,
                    (bbox.min.z + bbox.max.z) / 2,
                );
            }
            this.props.fitProjectObject(null);
        }
    };

    componentDidMount() {
        this.canvasRenderer = new THREE.WebGLRenderer({
            canvas: this.canvasRef,
            antialias: true,
            depth: true,
            precision: 'highp',
            logarithmicDepthBuffer: true,
        });
        this.dom.addEventListener('pointerdown', this.onMouseDown, true);
        window.addEventListener('resize', this.onWindowsResize, false);
        this.dom.appendChild(this.renderer.domElement);

        this.onWindowsResize();

        this.fit(this.camera, this.controls)('top_right_front', 1)();

        if (this.props.mode === 'solution' && (this.props.solution || this.state.temp)) {
            this.drawSolution();
        }

        if (this.props.mode === 'plane') {
            this.drawPlane();
        }

        if (this.props.mode === 'box' && this.props.tree) {
            this.drawObjects();
        }

        this.animate();
    }

    componentWillUnmount() {
        this.clearDrawJobs();
        this.clearMovementAxesScene(false);

        if (this.props.clipping) {
            this.props.toggleObjectClipping();
            this.props.clearClipFaceSolutions();
        }

        this.props.setSelectedObject();
        this.dom.removeEventListener('pointerdown', this.onMouseDown, true);
        window.removeEventListener('resize', this.onWindowsResize, false);
        this.scene.remove.apply(this.scene, this.scene.children);
        this.solutionScene.remove.apply(this.solutionScene, this.scene.children);
        this.planeScene.remove.apply(this.planeScene, this.planeScene.children);

        this.objects.forEach(obj => obj?.geometry?.dispose());
        this.solutionBoxes.forEach(box => box.geometry.dispose());
        this.solutionPlanes.forEach(plane => plane.geometry.dispose());
        Object.values(this.materials).forEach(material => material.material.dispose());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.axesScale === null && this.objects[0]) {
            this.axesScale = this.camera.position.distanceTo(this.objects[0].position) * axesScalePercentage;
        }

        if (this.props.fit_all) {
            this.fit(this.camera, this.controls)('top_right_front', 1)();
            this.props.fitAll();
        }

        if (!this.props.current_project || !this.props.current_project.world) {
            return;
        }

        if (this.props.task_id && this.props.task_status_time !== prevProps.task_status_time) {
            if (this.props.task_status === 'SUCCESS') {
                this.setState({
                    open: true,
                    message: 'Solution completed successfully',
                    severity: 'success',
                });

                this.props.clearProjectSolution();
                this.props.loadProjectSolution({project_id: this.props.current_project.id});
                this.props.loadProjectTransient({project_id: this.props.current_project.id});
                this.props.loadPlaneSolutions({project_id: this.props.current_project.id});
            } else if (this.props.task_status === 'FAILURE') {
                this.setState({
                    open: true,
                    message: 'Solution failed',
                    severity: 'error',
                });
            }
        }

        if (this.props.fit_object !== prevProps.fit_object) {
            this.fitObject();
        }

        if (
            !this.props.clipping &&
            (
                (prevProps.solution?.timestamp !== this.props.solution?.timestamp) ||
                prevProps.tree !== this.props.tree ||
                prevProps.current_project && prevProps.current_project.world !== this.props.current_project.world ||
                this.state.temp !== prevState.temp
            )
        ) {
            this.drawSolution();
        }

        if (
            this.props.mode === 'plane' &&
            (
                (this.props.plane_solutions && this.props.plane_solutions.length) ||
                prevProps.tree !== this.props.tree ||
                (prevProps.current_project && prevProps.current_project.world !== this.props.current_project.world) ||
                this.state.temp !== prevState.temp ||
                prevProps.planes !== this.props.planes
            )
        ) {
            this.drawPlane();
        }
        if (
            this.props.mode === 'box' &&
            (
                prevProps.tree !== this.props.tree ||
                prevProps.planes !== this.props.planes ||
                prevProps.current_project !== this.props.current_project ||
                !_.isEqual(prevProps.clipFacePlaneSolutionsData, this.props.clipFacePlaneSolutionsData) ||
                (prevProps.current_project && prevProps.current_project.world && prevProps.current_project.world !== this.props.current_project.world) ||
                this.props.forceUpdatedSelectedObject
            )
        ) {
            this.drawObjects();

            if (prevProps.tree.length === 0 && this.props.tree.length !== 0)
                this.fit(this.camera, this.controls)('top_right_front', 1.2)();

            if (this.props.selectedObject?.id && prevProps.selectedObject?.id && this.props.selectedObject.id === prevProps.selectedObject.id) {
                if (this.state.newCoordinates) {
                    this.setState({
                        newCoordinates: {
                            ...this.state.newCoordinates,
                            updatedCoordinates: {
                                ...this.state.newCoordinates.updatedCoordinates,
                                dx: 0,
                                dy: 0,
                                dz: 0,
                            },
                        },
                    });
                }

                this.drawMovementAxesScene(this.props.selectedObject);
            }
        }

        if (prevProps.clipping !== this.props.clipping) {
            if (prevProps.clipping !== this.props.clipping) { // reset clippingBbox
                this.clippedBoxX0 = null;
            }

            if (!this.props.clipping) {
                Object.values(scenesMapping).forEach(scene => {
                    this.clipFaces.forEach(meshObj => {
                        this[scene].remove(meshObj);
                        meshObj.geometry.dispose();
                    });
                    this.clipLines.forEach(meshObj => {
                        this[scene].remove(meshObj);
                        meshObj.geometry.dispose();
                    });
                });

                this.clippedBoxX0 = null;
                this.clearClipping();
            } else {
                this.clipFaces.forEach(meshObj => {
                    this[scenesMapping[prevProps.mode]].remove(meshObj);
                    meshObj.geometry.dispose();
                });
                this.clipLines.forEach(meshObj => {
                    this[scenesMapping[prevProps.mode]].remove(meshObj);
                    meshObj.geometry.dispose();
                });

                this.drawClippingObjects(this[scenesMapping[this.props.mode]]);
            }

            this[drawObjectsByMode[this.props.mode]]();
        }

        if (prevProps.mode !== this.props.mode) {
            this[drawObjectsByMode[this.props.mode]]();
        }

        if (this.canvasRenderer && this.props.tree && this.props.current_project &&
            (this.props.current_project.thumbnail == null ||
                prevProps.tree.length && prevProps.tree !== this.props.tree)) {

            if (prevProps.current_project && prevProps.current_project.id === this.props.current_project.id) {
                if (this.canvasRef) {
                    setTimeout(() => {
                        if (!this.canvasRef) {
                            return;
                        }

                        this.fit(this.canvasCamera)('top_right_front', 0.9)();
                        this.canvasRenderer.render(this.scene, this.canvasCamera);
                        this.props.updateProjectThumbnail({
                            project_id: this.props.current_project.id,
                            thumbnail: {
                                image: this.canvasRef.toDataURL('image/png', 0.5),
                            },
                        });
                    }, 0);
                }
            }
        }

        // if (this.props.mode === 'box') {
        if (
            (this.props.selectedObject && prevProps.selectedObject && this.props.selectedObject?.visible &&
                this.props.selectedObject.id && this.props.selectedObject.id !== prevProps.selectedObject.id &&
                this.tempMoving3DPosition) ||
            (this.props.selectedObject?.id && !prevProps.selectedObject) ||
            this.props.updateSelected ||
            this.props.forceUpdatedSelectedObject
        ) {
            this.props.updateSelected && this.props.updateSelectedObject(false);

            if (this.props.forceUpdatedSelectedObject) {
                this.state.newCoordinates && this.setState({
                    newCoordinates: null,
                });
            }

            if (this.props.selectedObject.type === 'assembly' &&
                (this.props.selectedObject.xcount !== prevProps.selectedObject.xcount || this.props.selectedObject.ycount !== prevProps.selectedObject.ycount || this.props.selectedObject.zcount !== prevProps.selectedObject.zcount ||
                    this.props.selectedObject.xpitch !== prevProps.selectedObject.xpitch || this.props.selectedObject.ypitch !== prevProps.selectedObject.ypitch || this.props.selectedObject.zpitch !== prevProps.selectedObject.zpitch)
            ) {
                this.drawObjects();
            }

            this.drawMovementAxesScene(this.props.selectedObject);
        } else if (
            (this.props.selectedObject && !Object.keys(this.props.selectedObject).length && prevProps.selectedObject)
            || (this.props.selectedObject && !this.props.selectedObject.visible)
        ) {
            this.clearMovementAxesScene();
        }


        // } else if (
        //     (this.props.selectedObject && !Object.keys(this.props.selectedObject).length && prevProps.selectedObject)
        //     || (this.props.selectedObject && !this.props.selectedObject.visible)
        // ) {
        //     this.clearMovementAxesScene();
        // }

        if (this.props.forceUpdatedSelectedObject) {
            this.props.forceUpdateSelectedObjectAction(false);
        }
    }

    toggleCameraRotate() {
        this.cameraRotate = !this.cameraRotate;
    }

    render() {
        return (
            <>
                {
                    this.props.mode === 'box' &&
                    this.props.selectedObject &&
                    this.props.selectedObject.id &&
                    this.props.selectedObject.id !== 'creating' &&
                    <DraggingInfoInputs project_id={this.props.current_project.id}
                                        selectedObjectData={this.props.selectedObject}
                                        dragging={!!this.moveDirection}
                                        newPositions={this.state.newCoordinates}/>
                }
                <canvas style={{display: 'none'}}
                        width={this.canvasWidth} height={this.canvasHeight}
                        ref={ref => (this.canvasRef = ref)}
                />
                <Snackbar
                    open={this.state.open}
                    autoHideDuration={3000}
                    TransitionComponent={Slide}
                    onClose={this.handleSnackbarClose}>
                    <Alert variant='filled' onClose={this.handleSnackbarClose} severity={this.state.severity || 'info'}>
                        <div>{this.state.message}</div>
                    </Alert>
                </Snackbar>
                <div
                    style={{display: (this.props.mode === 'adjacency') ? 'none' : 'block'}}
                    id={'canvasID'}
                    ref={ref => (this.dom = ref)}
                    onMouseMove={this.onMouseMove}
                    onPointerDownCapture={this.onMouseDown}
                    onPointerUp={this.onMouseUp}
                    onDragStart={this.onDragStart}
                    onDragEnd={this.onDragEnd}
                    onDragEnter={this.onDragEnter}
                    onDragOver={this.onDragOver}
                    onDragLeave={this.onDragLeave}
                    onDrop={this.onDrop}
                />
                <canvas
                    style={{display: (this.props.mode === 'adjacency') ? 'block' : 'none'}}
                    width={this.canvasWidth} height={this.canvasHeight}
                    ref={ref => (this.adjCanvasRef = ref)}
                />
                <SolutionPanel
                    transient={this.props.transient}
                    onWindowsResize={this.onWindowsResize}
                    project_id={this.props.current_project.id}
                    draw={this.drawSolution}
                    loadNewSolution={this.props.loadProjectTransientSolution}
                    thermalGradientConfig={this.state.lut}
                    isSolutionLoading={this.props.isLoading}
                    toggleCameraRotate={this.toggleCameraRotate}
                />
            </>
        );
    }
}

Boxes.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    showModal: PropTypes.func.isRequired,
    current_project: PropTypes.object,
    adjacency: PropTypes.object,
    tree: PropTypes.arrayOf(PropTypes.object),
    solution: PropTypes.object,
    mode: PropTypes.string,
    transient: PropTypes.arrayOf(PropTypes.object),
    clearProjectSolution: PropTypes.func.isRequired,
    loadProjectSolution: PropTypes.func.isRequired,
    loadProjectTransient: PropTypes.func.isRequired,
    loadProjectTransientSolution: PropTypes.func.isRequired,
    selectedObject: PropTypes.object,
    task_id: PropTypes.number,
    task_status: PropTypes.string,
    task_status_time: PropTypes.number,
    loadPlaneSolutions: PropTypes.func.isRequired,
    plane_solutions: PropTypes.arrayOf(PropTypes.object),
    planes: PropTypes.arrayOf(PropTypes.object),
    clipping: PropTypes.bool,
    setSelectedObject: PropTypes.func,
    forceUpdateSelectedObjectAction: PropTypes.func,
    forceUpdatedSelectedObject: PropTypes.bool,
    updateSelected: PropTypes.bool,
    updateSelectedObject: PropTypes.func,
    projectMaterials: PropTypes.array,
    toggleObjectClipping: PropTypes.func,
    clearClipFaceSolutions: PropTypes.func,
    loadClipFaceSolution_x0: PropTypes.func,
    loadClipFaceSolution_x1: PropTypes.func,
    loadClipFaceSolution_y0: PropTypes.func,
    loadClipFaceSolution_y1: PropTypes.func,
    loadClipFaceSolution_z0: PropTypes.func,
    loadClipFaceSolution_z1: PropTypes.func,
    loadClipFaceSolution: PropTypes.func,
    storeClipFaceData: PropTypes.func,
    clipFacePlaneSolutionsHasData: PropTypes.bool,
    clipFacePlaneSolutionsData: PropTypes.object,
    translateProjectAssembly: PropTypes.func,
    updatePlane: PropTypes.func,
    updateProjectObjectPosition: PropTypes.func,
};

const mapStateToProps = state => ({
    fit_object: state.current_project.properties.fit_object,
    fit_all: getFitAll(state),
    url: state.router.location,
    mode: getProjectMode(state),
    task_id: getTaskId(state),
    task_status: getTaskStatus(state),
    task_status_time: getTaskStatusTime(state),
    drag: getProjectDrag(state),
    clipping: state.current_project.properties.clipping,
    selectedObject: getSelectedObject(state) || {},
    updateSelected: getUpdateSelectedObject(state),
    assemblySelection: getAssemblySelectionState(state),
    gridSnap: getGridSnap(state),
    projectMaterials: getProjectMaterials(state),
    transient: getTransient(state),
    forceUpdatedSelectedObject: getForceUpdatedSelectedObject(state),
    clipFacePlaneSolutionsHasData: clipFacePlaneSolutionsHasData(state),
    clipFacePlaneSolutionsData: getClipFacePlaneSolutions(state),
});

const mapDispatchToProps = {
    fitProjectObject,
    fitAll,
    loadTaskStatus,
    clearProjectSolution,
    loadProjectSolution,
    loadProjectTransient,
    loadProjectTransientSolution,
    loadPlaneSolutions,
    updateProjectThumbnail,
    toggleObjectClipping,
    clearClipFaceSolutions,
    setSelectedObject,
    updateSelectedObject,
    updateProjectObjectPosition,
    translateProjectAssembly,
    updatePlane,
    forceUpdateSelectedObjectAction,
    loadClipFaceSolution_x0,
    loadClipFaceSolution_x1,
    loadClipFaceSolution_y0,
    loadClipFaceSolution_y1,
    loadClipFaceSolution_z0,
    loadClipFaceSolution_z1,
    storeClipFaceData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Boxes);