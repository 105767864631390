/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose} from 'redux';

import withStyles from '@mui/styles/withStyles';
import {
    Button,
    TableCell,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableContainer,
} from '@mui/material';

import {getScenarioCases, runScenario, STOP_SCENARIOTasks} from '../../../../+store/actions/scenarios';
import {
    loadProjectScenarioCaseSolution,
    loadProjectScenarioCasePlaneSolutions
} from '../../../../+store/actions/actions';

import Spinner from '../../../../../components/Spinner';
import {FONT_AWESOME_ICONS_TYPE_MAP} from '../../../../../core/mappings';
import {IconsFontAwesome} from '../../../../../components/IconsFontAwesome';

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        display: 'flex',
    },
    button: {
        margin: 5,
    },
    parametersTab: {
        margin: theme.spacing(2),
        width: '100%',
    },
    tableContainer: {
        marginTop: theme.spacing(2),
        overflow: 'auto',
        maxHeight: 'calc( 100vh - 310px)',
        width: 'calc(85vw)',
    },

});


const ScenarioCaseComponent = ({
                                   classes, current_project, scenario, cases, loading,
                                   runScenario, STOP_SCENARIOTasks,
                                   getScenarioCases, loadProjectScenarioCaseSolution,
                                   loadProjectScenarioCasePlaneSolutions
                               }) => {

    useEffect(() => {
        getScenarioCases({project_id: current_project.id, scenario});
    }, []);

    return (
        <>
            <Button
                variant={'contained'}
                color={'primary'}
                className={classes.button}
                onClick={() => getScenarioCases({project_id: current_project.id, scenario})}
            >
                <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.refresh} hasHover={false}/>
                Load
            </Button>
            {
                (current_project.edit || current_project.owner) &&
                <Button
                    variant={'contained'}
                    color={'primary'}
                    className={classes.button}
                    onClick={() => runScenario({project_id: current_project.id, scenario: scenario})}
                >
                    <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.play} hasHover={false}/>
                    Run
                </Button>
            }
            {
                (current_project.edit || current_project.owner) &&
                <Button
                    variant={'contained'}
                    color={'secondary'}
                    className={classes.button}
                    onClick={() => STOP_SCENARIOTasks({project_id: current_project.id, scenario: scenario})}>
                    <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.stop} hasHover={false}/>
                    Stop
                </Button>
            }
            {
                loading > 0 && <Spinner style={{margin: 10}}/>
            }
            {
                cases.hasOwnProperty(scenario.id) && cases[scenario.id].length > 0 &&
                <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader={true}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Max T [&deg;C]</TableCell>
                                <TableCell>Dissipated power [mW]</TableCell>
                                <TableCell>Solution</TableCell>
                                <TableCell>Status</TableCell>
                                {
                                    Object.keys(cases[scenario.id][0].parameters)
                                        .sort()
                                        .map(param => (<TableCell key={param}>{param}</TableCell>))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                cases[scenario.id]
                                    .sort((a, b) => (b.max_t - a.max_t))
                                    .map(kase => {
                                        return <TableRow hover key={kase.id}>
                                            <TableCell>{kase.max_t && kase.max_t.toFixed(2)}</TableCell>
                                            <TableCell>{kase.power && kase.power.toFixed(2)}</TableCell>
                                            <TableCell>
                                                {
                                                    kase.solution_id && <Button
                                                    style={{whiteSpace: 'nowrap'}}
                                                    variant={'contained'}
                                                    onClick={() => {
                                                        loadProjectScenarioCaseSolution({
                                                            project_id: current_project.id,
                                                            scenario,
                                                            case: kase,
                                                        });
                                                        loadProjectScenarioCasePlaneSolutions({
                                                            project_id: current_project.id,
                                                            scenario,
                                                            case: kase,
                                                        });
                                                    }}>
                                                    Load solution
                                                </Button>
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    kase.task_status
                                                }
                                            </TableCell>
                                            {
                                                Object.keys(cases[scenario.id][0].parameters)
                                                    .sort()
                                                    .map(param => (
                                                        <TableCell
                                                            key={param}>{kase.parameters[param]}</TableCell>))
                                            }
                                        </TableRow>;
                                    })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </>
    );
};

ScenarioCaseComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    current_project: PropTypes.object,
    scenario: PropTypes.object.isRequired,
    getScenarioCases: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    scenarios: state.current_project.scenarios,
    loading: state.current_project.scenarios.loadingCases,
    cases: state.current_project.scenarios.cases,
});

const mapDispatchToProps = {
    runScenario,
    STOP_SCENARIOTasks,
    getScenarioCases,
    loadProjectScenarioCaseSolution,
    loadProjectScenarioCasePlaneSolutions
};

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(ScenarioCaseComponent);
