/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import AlertDialog from './AlertDialog';
import CustomDialog from './CustomDialog';

import {hideModal, showModal} from '../actions';
import {getModals} from '../reducer';


export class ModalRoot extends Component {

    render() {
        const { modals, hideModal } = this.props;

        if(!modals.length) {
            return null
        }
        const last_modal = modals.slice(-1)[0];

        const onClose = (params)=> {
            if(last_modal.onClose) {
                last_modal.onClose(params);
            }
            hideModal(last_modal);
        };

        const ModalDialog = last_modal.type === 'alert' ? AlertDialog: CustomDialog;

        return (<ModalDialog {...last_modal} onClose={onClose} />);
    }
}

ModalRoot.propTypes = {
    modals: PropTypes.arrayOf(PropTypes.object).isRequired,
    showModal: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    modals: getModals(state),
});

const mapDispatchToProps = {
    showModal,
    hideModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalRoot);
