/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {Fragment} from 'react';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import {Link} from 'react-router-dom';


export default function AccountMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Fragment>
            <IconButton id={'accountID'}
                        aria-label={'account of current user'}
                        aria-controls={'menu-appbar'}
                        aria-haspopup={'true'}
                        onClick={handleMenu}
                        color={'inherit'}
                        size={'large'}>
                <AccountCircle/>
            </IconButton>
            <Menu id={'menu-appbar'}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                  }}
                  transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                  }}
                  open={open}
                  keepMounted
                  onClose={handleClose}>
                <MenuItem onClick={handleClose}>
                    <Link className={'account-link'} to={'/account'}>My account</Link>
                </MenuItem>
                <MenuItem onClick={() => {
                    props.handleLogout();
                    handleClose();
                }}>Logout</MenuItem>
                <MenuItem disabled={true}>
                    Version {process.env.REACT_APP_VERSION}
                </MenuItem>
            </Menu>
        </Fragment>
    );
}