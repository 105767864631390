/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {createAction} from 'redux-actions';
import {requestController} from '../../../core/request.utils';

const activeRequestControllers = {};


export const LOAD_PROJECT = 'LOAD_PROJECT';
export const LOAD_PROJECT_ERROR = 'LOAD_PROJECT_ERROR';
export const LOAD_PROJECT_SUCCESS = 'LOAD_PROJECT_SUCCESS';

export const loadProject = payload => ({
    type: LOAD_PROJECT,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/?_=` + Date.now(),
        },
    },
});

export const LOAD_PROJECT_TREE = 'LOAD_PROJECT_TREE';
export const LOAD_PROJECT_TREE_ERROR = 'LOAD_PROJECT_TREE_ERROR';
export const LOAD_PROJECT_TREE_SUCCESS = 'LOAD_PROJECT_TREE_SUCCESS';

export const loadProjectTree = payload => {
    const {controller, currentRequest} = requestController(activeRequestControllers, 'loadProjectTree');

    return {
        type: LOAD_PROJECT_TREE,
        payload,
        meta: {
            request: {
                url: `/api/project/${payload.project_id}/tree/?_=` + Date.now(),
                signal: controller.signal,
                currentRequest,
            },
        },
    };
};

export const LOAD_PROJECT_ADJACENCY = 'LOAD_PROJECT_ADJACENCY';
export const LOAD_PROJECT_ADJACENCY_ERROR = 'LOAD_PROJECT_ADJACENCY_ERROR';
export const LOAD_PROJECT_ADJACENCY_SUCCESS = 'LOAD_PROJECT_ADJACENCY_SUCCESS';

export const loadProjectAdjacency = payload => {
    const {controller, currentRequest} = requestController(activeRequestControllers, 'loadProjectAdjacency');

    return {
        type: LOAD_PROJECT_ADJACENCY,
        payload,
        meta: {
            request: {
                url: `/api/project/${payload.project_id}/adjacency/?_=` + Date.now(),
                signal: controller.signal,
                currentRequest,
            },
        },
    };
};

export const LOAD_PROJECT_PERMS = 'LOAD_PROJECT_PERMS';
export const LOAD_PROJECT_PERMS_ERROR = 'LOAD_PROJECT_PERMS_ERROR';
export const LOAD_PROJECT_PERMS_SUCCESS = 'LOAD_PROJECT_PERMS_SUCCESS';

export const loadProjectPerms = payload => ({
    type: LOAD_PROJECT_PERMS,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/permissions/?_=` + Date.now(),
        },
    },
});

export const UPDATE_PROJECT_PERMS = 'UPDATE_PROJECT_PERMS';
export const UPDATE_PROJECT_PERMS_ERROR = 'UPDATE_PROJECT_PERMS_ERROR';
export const UPDATE_PROJECT_PERMS_SUCCESS = 'UPDATE_PROJECT_PERMS_SUCCESS';

export const updateProjectPerms = payload => ({
    type: UPDATE_PROJECT_PERMS,
    payload,
    meta: {
        request: {
            method: 'PUT',
            url: `/api/project/${payload.project_id}/permissions/`,
            body: JSON.stringify(payload.permissions),
            mode: 'text',
        },
    },
});

export const UPDATE_PROJECT_THUMBNAIL = 'UPDATE_PROJECT_THUMBNAIL';
export const UPDATE_PROJECT_THUMBNAIL_ERROR = 'UPDATE_PROJECT_THUMBNAIL_ERROR';
export const UPDATE_PROJECT_THUMBNAIL_SUCCESS = 'UPDATE_PROJECT_THUMBNAIL_SUCCESS';

export const updateProjectThumbnail = payload => {
    const {controller, currentRequest} = requestController(activeRequestControllers, 'updateProjectThumbnail');

    return {
        type: UPDATE_PROJECT_THUMBNAIL,
        payload,
        meta: {
            request: {
                method: 'PUT',
                url: `/api/project/${payload.project_id}/thumbnail/`,
                body: JSON.stringify(payload.thumbnail),
                mode: 'text',
                signal: controller.signal,
                currentRequest,
            },
        },
    };
};

export const UPDATE_THUMBNAIL = 'UPDATE_THUMBNAIL';
export const UPDATE_THUMBNAIL_ERROR = 'UPDATE_THUMBNAIL_ERROR';
export const UPDATE_THUMBNAIL_SUCCESS = 'UPDATE_THUMBNAIL_SUCCESS';

export const updateThumbnail = payload => ({
    type: UPDATE_THUMBNAIL,
    payload,
});

export const CHANGE_PROJECT_TREE = 'CHANGE_PROJECT_TREE';
export const changeProjectTree = createAction(CHANGE_PROJECT_TREE);

export const FIT_OBJECT = 'FIT_OBJECT';
export const fitProjectObject = createAction(FIT_OBJECT);

export const FIT_ALL = 'FIT_ALL';
export const fitAll = payload => ({
    type: FIT_ALL,
    payload,
});

export const SET_PROJECT_MODE = 'SET_PROJECT_MODE';
export const setProjectMode = createAction(SET_PROJECT_MODE);

export const START_DRAG = 'START_DRAG';
export const startProjectDrag = createAction(START_DRAG);

export const LOAD_PROJECT_MATERIAL = 'LOAD_PROJECT_MATERIAL';
export const LOAD_PROJECT_MATERIAL_ERROR = 'LOAD_PROJECT_MATERIAL_ERROR';
export const LOAD_PROJECT_MATERIAL_SUCCESS = 'LOAD_PROJECT_MATERIAL_SUCCESS';

export const loadProjectMaterials = payload => ({
    type: LOAD_PROJECT_MATERIAL,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/material/?_=` + Date.now(),
        },
    },
});

export const CREATE_PROJECT_MATERIAL = 'CREATE_PROJECT_MATERIAL';
export const CREATE_PROJECT_MATERIAL_ERROR = 'CREATE_PROJECT_MATERIAL_ERROR';
export const CREATE_PROJECT_MATERIAL_SUCCESS = 'CREATE_PROJECT_MATERIAL_SUCCESS';

export const createProjectMaterial = payload => ({
    type: CREATE_PROJECT_MATERIAL,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project.id}/material/`,
            method: 'POST',
            body: JSON.stringify(payload.materials),
        },
    },
});

export const UPDATE_PROJECT_MATERIAL = 'UPDATE_PROJECT_MATERIAL';
export const UPDATE_PROJECT_MATERIAL_ERROR = 'UPDATE_PROJECT_MATERIAL_ERROR';
export const UPDATE_PROJECT_MATERIAL_SUCCESS = 'UPDATE_PROJECT_MATERIAL_SUCCESS';

export const updateProjectMaterial = payload => ({
    type: UPDATE_PROJECT_MATERIAL,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project.id}/material/${payload.material.id}/`,
            method: 'PUT',
            body: JSON.stringify(payload.material),
        },
    },
});

export const DELETE_PROJECT_MATERIAL = 'DELETE_PROJECT_MATERIAL';
export const DELETE_PROJECT_MATERIAL_ERROR = 'DELETE_PROJECT_MATERIAL_ERROR';
export const DELETE_PROJECT_MATERIAL_SUCCESS = 'DELETE_PROJECT_MATERIAL_SUCCESS';

export const deleteProjectMaterial = payload => ({
    type: DELETE_PROJECT_MATERIAL,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project.id}/material/${payload.material.id}/`,
            method: 'DELETE',
        },
    },
});

export const LOAD_PROJECT_LOGS = 'LOAD_PROJECT_LOGS';
export const LOAD_PROJECT_LOGS_ERROR = 'LOAD_PROJECT_LOGS_ERROR';
export const LOAD_PROJECT_LOGS_SUCCESS = 'LOAD_PROJECT_LOGS_SUCCESS';

export const loadProjectLogs = payload => ({
    type: LOAD_PROJECT_LOGS,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.id}/log/?_=` + Date.now(),
        },
    },
});


export const UNDO_PROJECT = 'UNDO_PROJECT';
export const UNDO_PROJECT_ERROR = 'UNDO_PROJECT_ERROR';
export const UNDO_PROJECT_SUCCESS = 'UNDO_PROJECT_SUCCESS';

export const undoProject = payload => ({
    type: UNDO_PROJECT,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.id}/undo/`,
            method: 'POST',
            mode: 'text',
        },
    },
});


export const TOGGLE_OBJECT_CLIPPING = 'TOGGLE_OBJECT_CLIPPING';

export const toggleObjectClipping = (isActive) => ({
    type: TOGGLE_OBJECT_CLIPPING,
    payload: isActive,

});


export const SET_GRID_SNAP = 'SET_GRID_SNAP';
export const setGridSnap = (optionValue) => ({
    type: SET_GRID_SNAP,
    payload: optionValue,

});

export const SET_AXES_LOCK = 'SET_AXES_LOCK';
export const setAxesLock = (axes) => ({
    type: SET_AXES_LOCK,
    payload: axes,

});