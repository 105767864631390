/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from './DialogTitle';

export default function CustomDialog(props) {
    const {title, content, onClose} = props;
    return (
        <Dialog
            open={true}
            onClose={(event, reason) => {
                if (reason === 'backdropClick') {
                    return;
                }
                onClose(event);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={false}
        >
            {
                title && (
                    <DialogTitle onClose={onClose}>
                        {title}
                    </DialogTitle>
                )
            }
            {content({onClose})}
        </Dialog>
    );
}
