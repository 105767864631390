/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */


import React, {useEffect, useRef, useState} from 'react';
import PropTypes from "prop-types";
import {getValidationErrors} from "../../../+store/reducer/validation_errors";
import {getData as getProject} from "../../../+store/reducer/project";
import {getData as getTree, getSelectedObject} from "../../../+store/reducer/tree";
import {createPlane, deletePlane, updatePlane} from "../../../+store/actions/planes";
import {updateProjectTree} from "../../../+store/actions/actions";
import {connect} from "react-redux";
import {getDefaultValues} from "./defaultValues";
import {projectUtils} from "../../../utils";
import _ from "lodash";
import {confirmAlert} from "react-confirm-alert";
import MyValidationForm from "../../../../components/MyValidationForm";
import {FormControl, FormGroup, FormHelperText, InputAdornment, InputLabel, MenuItem, Select} from "@mui/material";
import {SettingsDivider, SettingsHeader, SettingsTabPlane} from "../../../../components/BasicSettings";
import {ActiveInactiveStateIcon} from "../../../../components/ActiveStateIcon";
import {FONT_AWESOME_ICONS_TYPE_MAP} from "../../../../core/mappings";
import {IconsFontAwesome} from "../../../../components/IconsFontAwesome";
import {TextValidator} from "react-material-ui-form-validator";

const PlaneSettingsNew = ({
                              createPlane,
                              deletePlane,
                              updatePlane,
                              project_id,
                              box,
                              validation_errors
                          }) => {
    const [defaultState] = useState(getDefaultValues[box.type]());
    const [fields] = useState(Object.keys(defaultState));
    const [state, setState] = useState(Object.keys(box).length > 0 ? {
        ...box,
        ...projectUtils.updateSettingsProps({
            fields,
            props: box,
            defaultState
        })
    } : {...defaultState});
    const form = useRef();
    const coordanate = state.plane === 'XY' ? 'z' : (state.plane === 'YZ' ? 'x' : 'y');


    useEffect(() => {
        const newState = {...projectUtils.updateSettingsProps({fields, props: box})};
        setState((prevState) => !_.isEqual(prevState, newState) ? newState : prevState);
    }, [box])

    function handleChange(field, value) {
        setState((prevState) => ({...prevState, [field]: value}));
    }

    function boxHasChange() {
        return Object.keys(state).some((key) => {
            return !_.isEqual(state[key], box[key])
        });
    }

    function handleSubmit(e) {
        e.preventDefault();
        form.current.isFormValid(false)
            .then(is_valid => {
                if (is_valid) {
                    if (box && box.id) {
                        updatePlane({
                            project_id: project_id,
                            plane: {
                                ...state,
                            },
                        });
                    } else {
                        createPlane({
                            project_id: project_id,
                            plane: {
                                ...state,
                                parent: null,
                                //new assembly should be expanded to enable adding/drag&drop child node
                                collapsed: false,
                            },
                        });
                    }

                }
            });
    }

    function handleDelete() {
        confirmAlert({
            title: `Deleting ${box.name}`, message: 'Do you wish to proceed?', buttons: [
                {
                    label: 'Delete', onClick: () => {
                        deletePlane({
                            project_id: project_id,
                            box: {
                                ...box,
                                ...state,
                            },
                        });
                    }
                }, {
                    label: 'Cancel', onClick: () => {
                    },
                },],
        });
    }

    function handleRevert() {
        setState({
            ...box,
            ...projectUtils.updateSettingsProps({fields, props: {...state, ...box}}),
        });
    }

    return <MyValidationForm
        ref={form}
        onSubmit={handleSubmit}
        style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            width: '100%',
            minHeight: '50%'
        }}>
        <FormGroup>
            <SettingsHeader name={box.type}>
                <ActiveInactiveStateIcon active={state.visible}
                                         activeIconType={FONT_AWESOME_ICONS_TYPE_MAP.eyeVisible}
                                         inactiveIconType={FONT_AWESOME_ICONS_TYPE_MAP.eyeNotVisible}
                                         titleActive={'Hide Box'}
                                         titleInactive={'Show Box'}
                                         iconsSX={{fontSize: '1rem', p: '6px'}}
                                         hoveredSX={{backgroundColor: 'background.paper'}}
                                         onClickHandler={() => handleChange('visible', !state.visible)}/>
                {
                    box &&
                    box.id &&
                    <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.delete}
                                      titleAccess={`Delete ${box.name}`}
                                      secondClass={'smaller'}
                                      iconsSX={{fontSize: '1rem', p: '6px'}}
                                      hoveredSX={{backgroundColor: 'background.paper'}}
                                      onClickHandler={handleDelete}/>
                }
            </SettingsHeader>
        </FormGroup>
        <SettingsDivider withSideMargin/>
        <SettingsTabPlane
            hasChange={boxHasChange()}
            onRevert={handleRevert}
            onSave={handleSubmit}
            object={box}>
            <TextValidator
                fullWidth
                autoFocus
                id={'planename'}
                key={'name'}
                label={'Name'}
                value={state['name'] || ''}
                onChange={(event) => handleChange('name', event.target.value)}
                variant={'standard'}
                error={!!validation_errors['name']}
                helperText={validation_errors['name']}
                validators={['required']}
                errorMessages={['This field is required!']}
            />
            <TextValidator
                fullWidth
                id={'coordinate'}
                key={'coordinate'}
                label={coordanate}
                value={state['coordinate'] || ''}
                onChange={(event) => handleChange('coordinate', event.target.value)}
                variant={'standard'}
                error={!!validation_errors['coordinate']}
                helperText={validation_errors['coordinate']}
                validators={['isCustomFloat', 'required']}
                errorMessages={['This field is required!']}
                InputProps={{
                    endAdornment: <InputAdornment position="end">{'mm'}</InputAdornment>,
                }}
            />
            <FormControl variant="standard" sx={{width: '31.8%'}}>
                <InputLabel error={!!validation_errors['plane']}
                            id={'planelabel'}>
                    Plane
                </InputLabel>
                <Select
                    id={'planeid'}
                    labelId={'planelabel'}
                    value={state.plane || 'XY'}
                    onChange={(event) => handleChange('plane', event.target.value)}
                    label={'Plane'}
                >
                    <MenuItem key={'XY'} value={'XY'}>XY</MenuItem>
                    <MenuItem key={'XZ'} value={'XZ'}>XZ</MenuItem>
                    <MenuItem key={'YZ'} value={'YZ'}>YZ</MenuItem>
                </Select>
                {validation_errors['plane'] &&
                    <FormHelperText>{validation_errors['plane']}</FormHelperText>
                }
            </FormControl>
        </SettingsTabPlane>
    </MyValidationForm>;
}

PlaneSettingsNew.propTypes = {
    createPlane: PropTypes.func.isRequired,
    deletePlane: PropTypes.func.isRequired,
    updatePlane: PropTypes.func.isRequired,
    validation_errors: PropTypes.object,
    box: PropTypes.object,
};

const mapStateToProps = state => ({
    validation_errors: getValidationErrors(state),
    project_id: getProject(state).id,
    tree: getTree(state),
    box: getSelectedObject(state),
});

const mapDispatchToProps = {
    createPlane,
    deletePlane,
    updatePlane,
    updateProjectTree,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaneSettingsNew);