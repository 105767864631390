/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React from 'react';
import {WarningOutlined} from "@mui/icons-material";
import {
    FormControl, FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    InputLabel,
    MenuItem, Radio,
    RadioGroup,
    Select,
    Tooltip
} from "@mui/material";
import {connect} from "react-redux";
import {getData as getProject, getProjectMaterials} from "../../../+store/reducer/project";
import {getValidationErrors} from "../../../+store/reducer/validation_errors";
import {getData as getTree, getSelectedObject} from "../../../+store/reducer/tree";
import {withSettingsTemplate} from "./SettingsTemplate";
import {
    createProjectBGA, deleteProjectBGA, updateProjectBGA, updateProjectBGAAndTree
} from "../../../+store/actions/bga";
import {setSelectedObject, updateSelectedObject} from "../../../+store/actions/actions";
import {IconCheckBoxField, InputFields, SettingsTabPlane} from "../../../../components/BasicSettings";
import HeatTransferCoeff from "../../../../components/HeatTranserCoeff";
import BasicTabs from "../../../../components/BasicTabs";
import {LumpedModelCheckbox, renderMaterialListSelectOptions} from "./commonComponents";
import {message_floating_point} from "../../../utils";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

const BGASettingsTemplate = ({
                                 // passed from SettingsTemplate
                                 boxHasChange,
                                 handleRevert,
                                 handleCancel,
                                 handleSubmit,
                                 handleChange,
                                 box,
                                 state,
                                 // directly from store
                                 validation_errors,
                                 materials
                             }) => {
    const {xcount, ycount, diameter, dx, dy, dz, inline, array_type} = state;
    const bga_eff_diam = Math.sqrt(Math.PI) / 2 * +diameter / 1000,
        bga_volume = +xcount * +ycount * Math.PI * Math.pow(bga_eff_diam / 2, 2) * +dz, box_volume = +dx * +dy * +dz;

    const tabs = [{
        label: 'Spatial', Component: <FormGroup style={{height: '100%', position: 'relative'}}>
            <SettingsTabPlane
                hasChange={boxHasChange()}
                onRevert={box.id !== 'creating' ? handleRevert : handleCancel}
                onSave={handleSubmit}
                object={box}>
                <InputFields
                    field_names={['name']}
                    state={state}
                    ids={'ballarrayname'}
                    validation_errors={validation_errors}
                    handleChange={handleChange}/>
                <FormControl>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            letterSpacing: 1.2,
                            marginLeft: (theme) => theme.spacing(1)
                        }}>
                        TYPE
                    </Typography>
                    <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={state.hasOwnProperty('array_type') ? state['array_type'] : 'ball'}
                        onChange={(event) => handleChange('array_type', event.target.value)}
                        name="radio-buttons-group"
                    >
                        <FormControlLabel value="ball" control={<Radio/>} label="Ball"/>
                        <FormControlLabel value="pillar" control={<Radio/>} label="Pillar"/>
                    </RadioGroup>
                </FormControl>
                <InputFields
                    groupTitle={'Location'}
                    field_names={['x', 'y', 'z']}
                    units={'mm'}
                    state={state}
                    ids={'balllocationid'}
                    validation_errors={validation_errors}
                    handleChange={handleChange}/>
                <InputFields
                    groupTitle={'Dimension'}
                    field_names={['dx', 'dy', 'dz']}
                    units={'mm'}
                    state={state}
                    ids={'balldimensionid'}
                    validation_errors={validation_errors}
                    handleChange={handleChange}/>
                <InputFields
                    groupTitle={'Ball Properties'}
                    field_names={['xcount', 'ycount']}
                    state={state}
                    ids={'ballcountid'}
                    validation_errors={validation_errors}
                    handleChange={handleChange}/>
                <IconCheckBoxField id={'isInline'}
                                   checked={inline} field_name={'inline'}
                                   handleChange={handleChange}
                                   checkBoxLabel={'Inline'}
                                   sx={{
                                       // color: pink[800],
                                       '&.Mui-checked': {
                                           color: '#3f51b5',
                                       },
                                   }}/>
                <InputFields
                    field_names={['xpitch', 'ypitch', 'diameter']}
                    state={state}
                    units={'µm'}
                    ids={'xpitch&ypitch&diameterid'}
                    validation_errors={validation_errors}
                    handleChange={handleChange}/>
                <InputFields
                    disabled
                    field_names={['Eff._ball_volume', 'Total_box_volume', 'Ratio']}
                    state={{
                        'Eff._ball_volume': isNaN(bga_volume) ? '—' : bga_volume.toFixed(3),
                        'Total_box_volume': isNaN(box_volume) ? '—' : box_volume.toFixed(3),
                        'Ratio': (isNaN(box_volume) || isNaN(bga_volume)) ? '—' : (bga_volume / box_volume * 100).toFixed(2)
                    }}
                    units={{'Eff._ball_volume': 'mm³', 'Total_box_volume': 'mm³', 'Ratio': '%'}}/>
                {(xcount && ycount && (+xcount * +ycount > 15e3)) && <Tooltip
                    sx={{position: 'absolute', right: '50%', transform: 'translateX(50%)'}}
                    title={<span>
                            WARNING: Due to large number of balls display
                            will be reduced to approximately 15,000 balls. Solver behavior is
                            unaffected.
                                </span>}>
                    <WarningOutlined fontSize={'inherit'} color={'warning'}/>
                </Tooltip>}
            </SettingsTabPlane>
        </FormGroup>
    }, {
        label: 'Thermal', Component: <FormGroup style={{height: '100%'}}>
            <SettingsTabPlane
                hasChange={boxHasChange()}
                onRevert={box.id !== 'creating' ? handleRevert : handleCancel}
                onSave={handleSubmit}
                object={box}>
                <FormControl variant={'standard'} fullWidth>
                    <InputLabel id={'materiallabel'} error={!!validation_errors['material']}>
                        Ball material
                    </InputLabel>
                    <Select
                        id={'ballmaterial'}
                        labelId={'materiallabel'}
                        value={state.material || ''}
                        onChange={(event) => handleChange('material', event.target.value)}
                        label={'Ball material'}>
                        {renderMaterialListSelectOptions(materials)}
                    </Select>
                    {validation_errors['material'] && <FormHelperText>{validation_errors['material']}</FormHelperText>}
                </FormControl>
                <FormControl variant={'standard'} fullWidth>
                    <InputLabel shrink error={!!validation_errors['fill_material']}
                                id={'fillmateriallabel'}>
                        Fill material
                    </InputLabel>
                    <Select
                        fullWidth
                        id={'fillmaterial'}
                        labelId={'fillmateriallabel'}
                        displayEmpty={true}
                        value={state.fill_material || ''}
                        onChange={(event) => handleChange('fill_material', event.target.value)}
                        label={'Fill material'}
                    >
                        <MenuItem key={'None'} value={null}>-</MenuItem>
                        {renderMaterialListSelectOptions(materials)}
                    </Select>
                    {validation_errors['fill_material'] &&
                        <FormHelperText>{validation_errors['fill_material']}</FormHelperText>}
                </FormControl>
                <HeatTransferCoeff
                    fullWidth
                    variant={'standard'}
                    validators={[]}
                    value={state.hc || ''}
                    onChange={(event) => handleChange('hc', event.target.value)}
                    error={!!validation_errors['hc']}
                    helperText={validation_errors['hc']}
                    errorMessages={[message_floating_point]}
                />
                <LumpedModelCheckbox handleChange={handleChange} state={state}/>
            </SettingsTabPlane>
        </FormGroup>
    },];

    return <BasicTabs tabs={tabs} title={'Properties'} isSwitch={true}/>

}

BGASettingsTemplate.propTypes = {
    boxHasChange: PropTypes.func.isRequired,
    handleRevert: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    box: PropTypes.object,
    state: PropTypes.object.isRequired,
    validation_errors: PropTypes.object,
    materials: PropTypes.array
};

const mapStateToProps = state => ({
    materials: getProjectMaterials(state) || [],
    validation_errors: getValidationErrors(state),
    project_id: getProject(state).id,
    tree: getTree(state),
    box: getSelectedObject(state),
});

const mapDispatchToProps = {
    createProjectObject: createProjectBGA,
    updateProjectObject: updateProjectBGA,
    updateProjectObjectAndTree: updateProjectBGAAndTree,
    deleteProjectObject: deleteProjectBGA,
    setSelectedObject,
    updateSelectedObject
};

export default connect(mapStateToProps, mapDispatchToProps)(withSettingsTemplate(BGASettingsTemplate));