/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {Grid} from '@mui/material';

import {getSelectedObject} from '../../../../../+store/reducer/tree';

import {MAPPINGS} from '../../../../../../core/mappings';


const Settings = ({selectedObject}) => {
    const SettingsComponent = selectedObject?.type ? (MAPPINGS.projectBoxSettings.intersectComponents[selectedObject.type] ?? null) : null;

    return (
        <Grid container sx={{flex: 1, overflow: 'hidden'}}>
            {
                SettingsComponent
                    ? <SettingsComponent/>
                    : <div style={{'overflowY': 'auto', flex: 1}}/>
            }
        </Grid>
    );
};


Settings.propTypes = {
    selectedObject: PropTypes.object,
};

const mapStateToProps = state => ({
    selectedObject: getSelectedObject(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
