/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';


import withStyles from '@mui/styles/withStyles';
import {
    FormControl,
    DialogActions,
    DialogContent,
    Button,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    InputAdornment,
} from '@mui/material';
import {TextValidator} from 'react-material-ui-form-validator';

import {showModal} from '../../../modals/actions';
import {updateProjectTree} from '../../+store/actions/actions';
import {createPlane, deletePlane, updatePlane} from '../../+store/actions/planes';

import {getData as getTree} from '../../+store/reducer/tree';
import {getValidationErrors} from '../../+store/reducer/validation_errors';
import {getData as getProject} from '../../+store/reducer/project';

import MyValidationForm from '../../../components/MyValidationForm';

import {projectUtils} from '../../utils';

const styles = (theme) => ({
    root: {
        '& > *': {
            marginBottom: theme.spacing(1),
            marginRight: theme.spacing(2),
        },
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
});

class CreatePlaneSettings extends Component {

    constructor(props) {
        super(props);

        this.state = props.box || {
            name: '',
            plane: 'XY',
            coordinate: '0.0',
        };

        this.form = React.createRef();

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    handleChange(field_name, value) {
        this.setState({
            [field_name]: value,
        });
    }

    handleSubmit() {
        this.form.current.isFormValid(false)
            .then(is_valid => {
                if (is_valid) {
                    const {onClose, box, createPlane, updatePlane} = this.props;
                    if (box && box.id) {
                        updatePlane({
                            project_id: this.props.project_id,
                            plane: {
                                ...this.state,
                            },
                        });
                    } else {
                        createPlane({
                            project_id: this.props.project_id,
                            plane: {
                                ...this.state,
                                parent: null,
                                //new assembly should be expanded to enable adding/drag&drop child node
                                collapsed: false,
                            },
                        });
                    }

                }
            });
    }

    handleDelete() {
        const {box, deletePlane, tree, updateProjectTree} = this.props;
        deletePlane({
            project_id: this.props.project_id,
            box: {
                ...box,
                ...this.state,
            },
        });

        // updateProjectTree(projectUtils.updatedTreeAfterDeleteNode(tree, box));
    }


    render() {
        const {classes, validation_errors, box} = this.props;

        return (
            <Fragment>
                <DialogContent dividers>
                    <MyValidationForm
                        ref={this.form}
                        onSubmit={this.handleSubmit}
                        className={'validation-form-sm'}>
                        <div>
                            <TextValidator
                                autoFocus
                                id={'planename'}
                                key={'name'}
                                className={classes.root}
                                label={'Name'}
                                value={this.state['name'] || ''}
                                onChange={(event) => this.handleChange('name', event.target.value)}
                                variant={'standard'}
                                error={!!validation_errors['name']}
                                helperText={validation_errors['name']}
                                validators={['required']}
                                errorMessages={['This field is required!']}
                            />
                            <FormControl variant={'standard'} fullWidth={true}>
                                <FormLabel>Plane</FormLabel>
                                <RadioGroup row value={this.state['plane']}
                                            onChange={(event) => this.handleChange('plane', event.target.value)}>
                                    <FormControlLabel control={<Radio/>} label={'XY'} value={'XY'}/>
                                    <FormControlLabel control={<Radio/>} label={'XZ'} value={'XZ'}/>
                                    <FormControlLabel control={<Radio/>} label={'YZ'} value={'YZ'}/>
                                </RadioGroup>
                            </FormControl>
                            <TextValidator
                                id={'coordinate'}
                                key={'coordinate'}
                                className={classes.root}
                                label={'Coordinate'}
                                value={this.state['coordinate'] || ''}
                                onChange={(event) => this.handleChange('coordinate', event.target.value)}
                                variant={'standard'}
                                error={!!validation_errors['coordinate']}
                                helperText={validation_errors['coordinate']}
                                validators={['isCustomFloat', 'required']}
                                errorMessages={['This field is required!']}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{'mm'}</InputAdornment>,
                                }}
                            />
                        </div>
                    </MyValidationForm>
                </DialogContent>
                <DialogActions>
                    {box && box.id && <Button onClick={this.handleDelete} variant={'contained'} color={'secondary'}>
                        Delete
                    </Button>}
                    <Button onClick={this.handleSubmit} id={'addplane'} variant={'contained'} color={'primary'}
                            autoFocus>
                        {box && box.id ? 'Update' : 'Add'}
                    </Button>
                </DialogActions>
            </Fragment>
        );
    }
}

CreatePlaneSettings.propTypes = {
    createPlane: PropTypes.func.isRequired,
    deletePlane: PropTypes.func.isRequired,
    updatePlane: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    project_id: PropTypes.number.isRequired,

};

const mapStateToProps = state => ({
    validation_errors: getValidationErrors(state),
    project_id: getProject(state).id,
    tree: getTree(state),
});

const mapDispatchToProps = {
    createPlane,
    deletePlane,
    updatePlane,
    updateProjectTree,
    showModal,
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(CreatePlaneSettings);
