import React from 'react';
import TabPanel from './TabPanel';
import {Tabs, Tab, Box} from '@mui/material';
import {SwitchToggle} from './SwitchToggle';

const BasicTabs = ({tabs, title, isSwitch, defaultValue = 0, id}) => {
    const [value, setValue] = React.useState(defaultValue);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleSwitchChange = (buttonValue) => {
        setValue(tabs.findIndex((tab) => tab.label === buttonValue));
    };

    return (
        <React.Fragment key={id || tabs[0].label}>
            <Box sx={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                root: {p: 0},
                padding: (theme) => `0 ${theme.spacing(1)}`,
                m: (theme) => `${theme.spacing(1)} 0`,
            }}>
                <Box sx={{
                    letterSpacing: 1.2,
                    fontSize: 12,
                    flex: '30%',
                    p: 0,
                    opacity: 0.65,
                    paddingRight: (theme) => theme.spacing(1)
                }}>
                    {title ? `${title?.toUpperCase()}:` : ''}
                </Box>
                {
                    isSwitch
                        ? (
                            <SwitchToggle
                                fullWidth={true}
                                options={tabs.map((tab) => ({key: tab.label, value: tab.label}))}
                                onChange={handleSwitchChange}
                                checked={tabs[value].label}
                                buttonSettingsSX={{flex: 1}}
                                groupSettingsSX={{width: '100%', height: '24px'}}
                            />
                        )
                        : (
                            <Tabs sx={{flex: '70%'}}
                                  value={value}
                                  onChange={handleChange}
                                  aria-label={'basic tabs example'}
                            >
                                {tabs.map(({label}, i) => (
                                    <Tab sx={{p: 0}} label={label} key={i}/>
                                ))}
                            </Tabs>
                        )
                }
            </Box>
            <>
                {
                    tabs.map(({Component}, i) => (
                        <TabPanel value={value} index={i} key={i}>
                            {Component}
                        </TabPanel>
                    ))
                }
            </>
        </React.Fragment>
    );
};

export default BasicTabs;