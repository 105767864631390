import React, {useState} from 'react';
import {Box, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material';


const boolOptions = [
    {key: 'ON', value: true},
    {key: 'OFF', value: false}
];

export const SwitchToggle = ({
                                 options,
                                 onChange,
                                 title,
                                 titleSX,
                                 titleClassName = '',
                                 checked,
                                 booleans,
                                 boxSX,
                                 groupSettings,
                                 groupSettingsSX,
                                 buttonSettings,
                                 buttonSettingsSX,
                                 orientation = 'horizontal',
                                 selectedColor
                             }) => {
    const [choices, setChoices] = useState(options || boolOptions);
    const handleChange = (e, newValue) => {
        if (newValue !== null && newValue !== checked) {
            onChange(newValue);
        }
    };

    return <Box sx={{
        display: 'flex',
        flexDirection: orientation === 'vertical' ? 'column' : 'row',
        width: '100%',
        alignItems: orientation === 'vertical' ? 'flex-start' : 'center',
        ...boxSX,
    }}
    >
        {title && <Typography variant={'faded'}
                              size={'info'}
                              className={titleClassName}
                              sx={{
                                  alignSelf: orientation === 'vertical' ? 'flex-start' : 'center',
                                  fontSize: 12,
                                  fontWeight: 400,
                                  marginLeft: (theme) => orientation === 'vertical' ? theme.spacing(1) : 0,
                                  marginBottom: (theme) => orientation === 'vertical' ? theme.spacing(1) : 0,
                                  ...titleSX
                              }}
        >{title?.toUpperCase()}</Typography>}
        <ToggleButtonGroup
            orientation={'horizontal'}
            value={checked}
            exclusive
            onChange={handleChange}
            {...groupSettings}
            sx={{display: 'flex', fontSize: '12px', borderRadius: '4px', height: '20px', ...groupSettingsSX}}
        >
            {choices?.map((choice) =>
                <ToggleButton key={choice.key} value={choice.value} aria-label={'list'}
                              sx={{
                                  lineHeight: '1rem',
                                  border: `1px solid rgba(0,0,0,0.33)`,
                                  letterSpacing: 1.2,
                                  fontWeight: 400,
                                  '&.Mui-selected, &:hover': {
                                      backgroundColor: (theme) => selectedColor && theme.palette[selectedColor].main,
                                      border: `1px solid rgba(0,0,0,0.33)`,
                                  },
                                  ...buttonSettingsSX
                              }}
                >
                    {choice.key}
                </ToggleButton>,
            )}
        </ToggleButtonGroup>
    </Box>;

};