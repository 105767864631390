/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

export const getDefaultValues = {
    box: ({materials = []}) => ({
        name: '',
        x: '',
        y: '',
        z: '',
        dx: '',
        dy: '',
        dz: '',
        power: '',
        index: 0,
        active: true,
        visible: true,
        hole: false,
        material: materials[0] && materials[0].id,
        hc: '',
        bound: false,
    }),
    ball_array: ({materials = []}) => ({
        name: '',
        x: '',
        y: '',
        z: '',
        dx: '',
        dy: '',
        dz: '',
        xcount: '',
        ycount: '',
        inline: true,
        xpitch: '',
        ypitch: '',
        diameter: '',
        active: true,
        visible: true,
        index: 0,
        material: materials[0] && materials[0].id,
        fill_material: '',
        fill_color: '',
        fill_material_name: '',
        composite_material: '',
        lumped: false,
        hc: '',
        array_type: 'ball_array'
    }),
    cylinder: ({materials = []}) => ({
        name: '',
        x: '',
        y: '',
        z: '',
        dx: '',
        dy: '',
        dz: '',
        plane: 'XZ',
        power: '',
        index: 0,
        active: true,
        visible: true,
        hole: false,
        material: materials[0] && materials[0].id,
        hc: '',
        bound: false
    }),
    heatsink: ({materials = []}) => ({
        name: '',
        x: '',
        y: '',
        z: '',
        base_dx: '',
        base_dy: '',
        base_dz: '',
        fin_axis: 'Y',
        fin_height: '',
        fin_thickness: '',
        fin_count: '',
        index: 0,
        active: true,
        visible: true,
        material: materials[0] && materials[0].id,
        hc: '',
        bound: false,
    }),
    via_array: ({materials = []}) => ({
        name: '',
        x: '',
        y: '',
        z: '',
        dx: '',
        dy: '',
        dz: '',
        xcount: '',
        ycount: '',
        xpitch: '',
        ypitch: '',
        diameter: '',
        active: true,
        visible: true,
        index: 0,
        material: materials[0] && materials[0].id,
        fill_material: '',
        is_plated: false,
        inner_diameter: 0,
        hole_material: '',
        lumped: false,
        hc: '',
    }),
    pcb: () => ({
        name: '',
        x: '',
        y: '',
        z: '',
        dx: '',
        dy: '',
        dz: '0',
        auto_via: true,
        layers: [],
        active: true,
        visible: true,
        index: 0,
        hc: '',
    }),
    source: () => ({
        name: '',
        x: '',
        y: '',
        z: '',
        dx: '',
        dy: '',
        dz: '',
        plane: 'XY',
        power: '',
        color: '#000',
        index: 0,
        active: true,
        visible: true,
        hc: '',
        bound: false,
    }),
    transient_source: () => ({
        name: '',
        x: '',
        y: '',
        z: '',
        dx: '',
        dy: '',
        dz: '',
        plane: 'XY',
        color: '#000',
        index: 0,
        active: true,
        visible: true,
        hc: '',
        bound: false,
        powers: []
    }),
    polygon: ({materials, box = []}) => ({
        name: '',
        x: '',
        y: '',
        z: '',
        plane: 'XY',
        power: '',
        color: '#000',
        material: materials[0] && materials[0].id,
        index: 0,
        active: true,
        visible: true,
        hc: '',
        bound: false,
        thickness: '', ...box,
        polygon: box.polygon //"POLYGON ((0.0000000000000000 10.0000000000000000, 8.0000000000000000 8.0000000000000000, ..., 0.0000000000000000 10.0000000000000000))",
            ? box.polygon.replace('POLYGON ((', '').replace('))', '') : '',
    }),
    assembly: () => ({
        name: '',
        active: true,
        visible: true,
        index: 0,
        lumped: false,
        hc: '',
        translate: 'absolute',
        is_array: false,
        type: 'assembly',
        children: [],
        x: 0,
        y: 0,
        z: 0,
        dx: '0',
        dy: '0',
        dz: '0',
        x_calc: 0,
        y_calc: 0,
        z_calc: 0,
        xcount: '1',
        ycount: '1',
        zcount: '1',
        xpitch: '0',
        ypitch: '0',
        zpitch: '0',
    }),
    plane: () => ({
        name: '',
        plane: 'XY',
        coordinate: '0.0',
    })
};