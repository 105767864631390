/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {useState, useMemo} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';

import logo from '../../logo-name.png';
import {styled} from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import {
    VerifiedUser as VerifiedUserIcon,
    AccountCircle,
    Menu as MenuIcon,
    Close as CloseIcon,
} from '@mui/icons-material';
import {
    AppBar,
    Paper,
    TableCell,
    Toolbar,
    Button,
    Tabs,
    Tab,
    IconButton,
    Drawer,
    List,
    ListItemText,
    ListItemIcon,
    Divider,
    ListItemButton
} from '@mui/material';

import {history} from '../../index';
import TabPanel from '../../components/TabPanel';
import {useWindowSize} from '../../core/hooks/useWindowSize';

const drawerWidth = 160;

const styles = (theme) => ({
    carousel: {
        height: 'calc(100vh - 48px)',
    },
    title: {
        flexGrow: 1,
        textAlign: 'center',
    },
    navbar: {
        backgroundColor: 'white',
        color: '#202020',
        padding: 4,
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    table: {
        width: '90%',
    },
    video: {
        width: '96%', margin: '2%', position: 'relative',
    },
    menuButton: {
        marginRight: 0,
    },
    iframe: {
        width: '100%',
        height: '100vh',
        border: 0
    }
});

function Item(props) {
    return (
        <Paper style={{}}
        >
            <div style={{
                backgroundImage: `url(${props.item.image})`,
                backgroundSize: '100% 100%',
                width: '96%',
                margin: '2%',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'calc(100vh - 48px)',
                position: 'relative',
            }}>
                <div style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    width: '70%',
                    position: 'absolute',
                    bottom: '3vh',
                    left: '3vw',
                    textAlign: 'left',
                    color: 'white',
                    padding: 20,
                }}>
                    <p style={{
                        fontSize: 'min(7vh, 7vw)',
                    }}>
                        {props.item.title}
                    </p>
                    <p style={{
                        fontSize: 'min(6vh, 6vw)',
                    }}>
                        {props.item.subtitle}
                    </p>
                    <p style={{
                        fontSize: 'min(2.5vh, 2.5vw)',
                    }}>
                        {props.item.text}
                    </p>
                </div>
                <Button
                    color="inherit"
                    variant={'outlined'}
                    size={'large'}
                    style={{
                        position: 'absolute',
                        bottom: '5vh',
                        right: '5vw',
                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    }}
                    href={'mailto:contact@thracesystems.com?subject=I would like to know more about Danka Thermal Solver'}
                >
                    <span>FIND OUT MORE</span>
                </Button>
            </div>
        </Paper>
    );
}

const MyTableCell = styled(TableCell)({
    border: 'none',
    padding: 5,
});

const navigationMenuItems = [
    {title: 'Home', tabKey: 0},
    {title: 'Blog', tabKey: 1},
    {title: 'Contacts', tabKey: 2}];

export const Home = ({classes}) => {
    const [openMenu, setOpenMenu] = useState(false);
    const [width, height] = useWindowSize();
    const [tab, setTab] = useState(navigationMenuItems[0].tabKey);

    const isTabletPhoneView = width <= 980;

    var items = [
        {
            title: "Chiplet Thermal Design",
            subtitle: "Solved. Now.",
            text: <span>
                        The biggest unknown to any Chiplet System - heat dissipation! We have the answer. Quickly explore multiple architectures and solutions to uncover problems. Quickly capture your complex design as it is, including TSVs and micro bumps. Our solver has the capacity, speed and performance you need.
                        <br/>
                        <br/>
                        Focus on what you do best - <em>the design of your Advanced Chiplet-based System!</em>
                    </span>,
            image: "https://anemoi-videos.s3.us-west-2.amazonaws.com/carousel_chiplet.png",
        },
    ];

    const container = useMemo(() => {
        return window !== undefined ? window.document.body : undefined;
    }, []);

    function toggleMenu() {
        setOpenMenu(!openMenu);
    }

    function handleTabChange(event, value) {
        setTab(value);
    }

    function handleMenuItemClick(selectedTab) {
        if (tab !== selectedTab) {
            setTab(selectedTab);
        }

        toggleMenu();
    }


    return (
        <>
            {/*<div style={{height: "200px"}}>*/}
            {/*    <span style={{fontSize: "20px", fontFamily: "Times New Roman"}}>*/}
            {/*        Thrace Thermal Solver*/}
            {/*    </span>*/}
            {/*</div>*/}
            <div>
                <Button
                    style={{
                        background: 'white',
                        position: 'absolute',
                        right: '20px',
                        top: '20px',
                    }}
                    color={'inherit'}
                    href={'/#/login'}
                    variant={'outlined'}
                    size={'large'}
                >
                    <AccountCircle/>
                    Login
                </Button>
                <iframe src={"home3/main.html"} className={classes.iframe}></iframe>
            </div>
        </>
    );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(Home);
