/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {ACTIVATE_USER_SUCCESS,} from '../actions/actions';

export const defaultState = {
    validation_errors: [],
};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case ACTIVATE_USER_SUCCESS:
            return {
                validation_errors: []
            };
        default:
            return state;
    }
};


