/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {createAction} from 'redux-actions';

export const LOAD_MATERIALS = 'LOAD_MATERIALS';
export const LOAD_MATERIALS_ERROR = 'LOAD_MATERIALS_ERROR';
export const LOAD_MATERIALS_SUCCESS = 'LOAD_MATERIALS_SUCCESS';

export const loadMaterials = payload => ({
    type: LOAD_MATERIALS,
    payload,
    meta: {
        request: {
            url: '/api/material/?_=' + Date.now(),
        },
    },
});

export const CREATE_MATERIAL = 'CREATE_MATERIAL';
export const CREATE_MATERIAL_ERROR = 'CREATE_MATERIAL_ERROR';
export const CREATE_MATERIAL_SUCCESS = 'CREATE_MATERIAL_SUCCESS';

export const createMaterial = payload => ({
    type: CREATE_MATERIAL,
    payload,
    meta: {
        request: {
            url: '/api/material/',
            method: 'POST',
            body: JSON.stringify(payload),
        },
    }
});

export const UPDATE_MATERIAL = 'UPDATE_MATERIAL';
export const UPDATE_MATERIAL_ERROR = 'UPDATE_MATERIAL_ERROR';
export const UPDATE_MATERIAL_SUCCESS = 'UPDATE_MATERIAL_SUCCESS';

export const updateMaterial = payload => ({
    type: UPDATE_MATERIAL,
    payload,
    meta: {
        request: {
            url: `/api/material/${payload.id}/`,
            method: 'PUT',
            body: JSON.stringify(payload),
        },
    }
});

export const DELETE_MATERIAL = 'DELETE_MATERIAL';
export const DELETE_MATERIAL_ERROR = 'DELETE_MATERIAL_ERROR';
export const DELETE_MATERIAL_SUCCESS = 'DELETE_MATERIAL_SUCCESS';

export const deleteMaterial = payload => ({
    type: DELETE_MATERIAL,
    payload,
    meta: {
        request: {
            url: `/api/material/${payload.id}/`,
            method: 'DELETE',
        },
    }
});


export const SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS';

export const setValidationErrors = createAction(SET_VALIDATION_ERRORS);

export const LOAD_LIBRARY = 'LOAD_LIBRARY';
export const LOAD_LIBRARY_ERROR = 'LOAD_LIBRARY_ERROR';
export const LOAD_LIBRARY_SUCCESS = 'LOAD_LIBRARY_SUCCESS';

export const loadLibrary = payload => ({
    type: LOAD_LIBRARY,
    payload,
    meta: {
        request: {
            url: '/api/library/?_=' + Date.now(),
        },
    },
});

export const CREATE_LIBRARY = 'CREATE_LIBRARY';
export const CREATE_LIBRARY_ERROR = 'CREATE_LIBRARY_ERROR';
export const CREATE_LIBRARY_SUCCESS = 'CREATE_LIBRARY_SUCCESS';

export const createLibrary = payload => ({
    type: CREATE_LIBRARY,
    payload,
    meta: {
        request: {
            url: '/api/library/',
            method: 'POST',
            body: JSON.stringify(payload)
        },
    },
});

export const UPDATE_LIBRARY = 'UPDATE_LIBRARY';
export const UPDATE_LIBRARY_ERROR = 'UPDATE_LIBRARY_ERROR';
export const UPDATE_LIBRARY_SUCCESS = 'UPDATE_LIBRARY_SUCCESS';

export const updateLibrary = payload => ({
    type: UPDATE_LIBRARY,
    payload,
    meta: {
        request: {
            url: `/api/library/${payload.id}/`,
            method: 'PUT',
            body: JSON.stringify(payload)
        },
    },
});

export const DELETE_LIBRARY = 'DELETE_LIBRARY';
export const DELETE_LIBRARY_ERROR = 'DELETE_LIBRARY_ERROR';
export const DELETE_LIBRARY_SUCCESS = 'DELETE_LIBRARY_SUCCESS';

export const deleteLibrary = payload => ({
    type: DELETE_LIBRARY,
    payload,
    meta: {
        request: {
            url: `/api/library/${payload.id}/`,
            method: 'DELETE',
        },
    },
});

export const LOAD_LIBRARY_PERM = 'LOAD_LIBRARY_PERM';
export const LOAD_LIBRARY_PERM_ERROR = 'LOAD_LIBRARY_PERM_ERROR';
export const LOAD_LIBRARY_PERM_SUCCESS = 'LOAD_LIBRARY_PERM_SUCCESS';

export const loadLibraryPerms = payload => ({
    type: LOAD_LIBRARY_PERM,
    payload,
    meta: {
        request: {
            url: `/api/library/${payload.library.id}/permissions/?_=` + Date.now(),
        },
    },
});

export const UPDATE_LIBRARY_PERM = 'UPDATE_LIBRARY_PERM';
export const UPDATE_LIBRARY_PERM_ERROR = 'UPDATE_LIBRARY_PERM_ERROR';
export const UPDATE_LIBRARY_PERM_SUCCESS = 'UPDATE_LIBRARY_PERM_SUCCESS';

export const updateLibraryPerms = payload => ({
    type: UPDATE_LIBRARY_PERM,
    payload,
    meta: {
        request: {
            url: `/api/library/${payload.library.id}/permissions/`,
            method: 'PUT',
            body: JSON.stringify(payload.permissions),
            mode: 'text',
        },
    },
});

export const CREATE_LIBRARY_MATERIAL = 'CREATE_LIBRARY_MATERIAL';
export const CREATE_LIBRARY_MATERIAL_ERROR = 'CREATE_LIBRARY_MATERIAL_ERROR';
export const CREATE_LIBRARY_MATERIAL_SUCCESS = 'CREATE_LIBRARY_MATERIAL_SUCCESS';

export const createLibraryMaterial = payload => ({
    type: CREATE_LIBRARY_MATERIAL,
    payload,
    meta: {
        request: {
            url: `/api/library/${payload.library.id}/material/`,
            method: 'POST',
            body: JSON.stringify(payload.material),
        },
    },
});

export const DELETE_LIBRARY_MATERIAL = 'DELETE_LIBRARY_MATERIAL';
export const DELETE_LIBRARY_MATERIAL_ERROR = 'DELETE_LIBRARY_MATERIAL_ERROR';
export const DELETE_LIBRARY_MATERIAL_SUCCESS = 'DELETE_LIBRARY_MATERIAL_SUCCESS';

export const deleteLibraryMaterial = payload => ({
    type: DELETE_LIBRARY_MATERIAL,
    payload,
    meta: {
        request: {
            url: `/api/library/${payload.library.id}/material/${payload.material.id}/`,
            method: 'DELETE',
        },
    },
});

export const UPDATE_LIBRARY_MATERIAL = 'UPDATE_LIBRARY_MATERIAL';
export const UPDATE_LIBRARY_MATERIAL_ERROR = 'UPDATE_LIBRARY_MATERIAL_ERROR';
export const UPDATE_LIBRARY_MATERIAL_SUCCESS = 'UPDATE_LIBRARY_MATERIAL_SUCCESS';

export const updateLibraryMaterial = payload => ({
    type: UPDATE_LIBRARY_MATERIAL,
    payload,
    meta: {
        request: {
            url: `/api/library/${payload.library.id}/material/${payload.material.id}/`,
            method: 'PUT',
            body: JSON.stringify(payload.material),
        },
    },
});

