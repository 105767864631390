/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {put, select, takeLatest} from 'redux-saga/effects';
import {getLocation, LOCATION_CHANGE} from 'react-router-redux';

import {
    clearProjectSolution,
    CLONE_PROJECT_ASSEMBLY_SUCCESS,
    CLONE_PROJECT_BOX_SUCCESS,
    CREATE_PROJECT_ASSEMBLY_SUCCESS,
    CREATE_PROJECT_BOX_ERROR,
    CREATE_PROJECT_BOX_SUCCESS,
    DELETE_PROJECT_ASSEMBLY_SUCCESS,
    DELETE_PROJECT_BOX_ERROR,
    DELETE_PROJECT_BOX_SUCCESS,
    LOAD_PLANE_SOLUTIONS_SUCCESS,
    LOAD_PROJECT_CASE_SOLUTION_ERROR,
    LOAD_PROJECT_SOLUTION_ERROR,
    loadProjectSolution,
    loadProjectTask,
    loadProjectTransient,
    setValidationErrors,
    SOLVE_PROJECT_SUCCESS,
    TRANSLATE_PROJECT_ASSEMBLY_ERROR,
    TRANSLATE_PROJECT_ASSEMBLY_SUCCESS,
    UPDATE_PROJECT_ASSEMBLY_AND_TREE_ERROR,
    UPDATE_PROJECT_ASSEMBLY_AND_TREE_SUCCESS,
    UPDATE_PROJECT_ASSEMBLY_SUCCESS,
    UPDATE_PROJECT_BOX_AND_TREE_ERROR,
    UPDATE_PROJECT_BOX_AND_TREE_SUCCESS,
    UPDATE_PROJECT_BOX_ERROR,
    UPDATE_PROJECT_BOX_SUCCESS,
    LOAD_CLIP_FACE_SOLUTION_X0_SUCCESS,
    LOAD_CLIP_FACE_SOLUTION_X1_SUCCESS,
    LOAD_CLIP_FACE_SOLUTION_Y0_SUCCESS,
    LOAD_CLIP_FACE_SOLUTION_Y1_SUCCESS,
    LOAD_CLIP_FACE_SOLUTION_Z0_SUCCESS,
    LOAD_CLIP_FACE_SOLUTION_Z1_SUCCESS,
    LOAD_CLIP_FACE_SOLUTION_TIME_POINT_X0_SUCCESS,
    LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Y0_SUCCESS,
    LOAD_CLIP_FACE_SOLUTION_TIME_POINT_X1_SUCCESS,
    LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Z1_SUCCESS,
    LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Z0_SUCCESS, LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Y1_SUCCESS,
    DOWNLOAD_PROJECT_STEP_SUCCESS, DOWNLOAD_PROJECT_STEP_ERROR
} from '../actions/actions';

import {
    CLONE_PROJECT_HEATSINK_SUCCESS, CREATE_PROJECT_HEATSINK_ERROR,
    CREATE_PROJECT_HEATSINK_SUCCESS, DELETE_PROJECT_HEATSINK_ERROR,
    DELETE_PROJECT_HEATSINK_SUCCESS, UPDATE_PROJECT_HEATSINK_AND_TREE_ERROR,
    UPDATE_PROJECT_HEATSINK_AND_TREE_SUCCESS, UPDATE_PROJECT_HEATSINK_ERROR,
    UPDATE_PROJECT_HEATSINK_SUCCESS,
} from '../actions/heatsink';

import {
    CLONE_PROJECT_BGA_SUCCESS, CREATE_PROJECT_BGA_ERROR,
    CREATE_PROJECT_BGA_SUCCESS, DELETE_PROJECT_BGA_ERROR,
    DELETE_PROJECT_BGA_SUCCESS, UPDATE_PROJECT_BGA_AND_TREE_ERROR,
    UPDATE_PROJECT_BGA_AND_TREE_SUCCESS, UPDATE_PROJECT_BGA_ERROR, UPDATE_PROJECT_BGA_SUCCESS,
} from '../actions/bga';

import {
    CLONE_PROJECT_VIA_ARRAY_ERROR,
    CLONE_PROJECT_VIA_ARRAY_SUCCESS, CREATE_PROJECT_VIA_ARRAY_ERROR,
    CREATE_PROJECT_VIA_ARRAY_SUCCESS, DELETE_PROJECT_VIA_ARRAY_ERROR,
    DELETE_PROJECT_VIA_ARRAY_SUCCESS, UPDATE_PROJECT_VIA_ARRAY_AND_TREE_ERROR,
    UPDATE_PROJECT_VIA_ARRAY_AND_TREE_SUCCESS, UPDATE_PROJECT_VIA_ARRAY_ERROR, UPDATE_PROJECT_VIA_ARRAY_SUCCESS,
} from '../actions/via_array';

import {
    CREATE_PLANE_ERROR,
    CREATE_PLANE_SUCCESS,
    DELETE_PLANE_ERROR,
    DELETE_PLANE_SUCCESS,
    loadPlanes, UPDATE_PLANE_ERROR,
    UPDATE_PLANE_SUCCESS
} from '../actions/planes';

import {
    CREATE_PROJECT_MATERIAL_ERROR,
    CREATE_PROJECT_MATERIAL_SUCCESS,
    DELETE_PROJECT_MATERIAL_ERROR,
    loadProject,
    loadProjectMaterials,
    loadProjectTree,
    UPDATE_PROJECT_MATERIAL_SUCCESS,
    UPDATE_PROJECT_MATERIAL_ERROR,
    UPDATE_PROJECT_PERMS_SUCCESS,
    UNDO_PROJECT_SUCCESS,
    UNDO_PROJECT_ERROR,
    loadProjectAdjacency,
    LOAD_PROJECT_ADJACENCY_SUCCESS,
    LOAD_PROJECT_ADJACENCY_ERROR,
} from '../actions/project';

import {
    setValidationErrors as setProjectValidationErrors,
    UPDATE_PROJECT_AND_TREE_SUCCESS,
    UPDATE_PROJECT_ERROR,
} from '../../../dashboard/actions';

import {
    CREATE_PARAMETER_SUCCESS,
    UPDATE_PARAMETER_SUCCESS,
    getParameters,
    DELETE_PARAMETER_ERROR, UPDATE_PARAMETER_ERROR, CREATE_PARAMETER_ERROR, GET_PARAMETERS_ERROR
} from '../actions/parameters';

import {hasData as hasMaterials} from '../../../materials/reducer';
import {HIDE_MODAL, hideModal} from '../../../modals/actions';
import {getModals} from '../../../modals/reducer';
import {
    CLONE_PROJECT_PCB_SUCCESS,
    CREATE_PROJECT_PCB_ERROR,
    CREATE_PROJECT_PCB_SUCCESS,
    DELETE_PROJECT_PCB_ERROR,
    DELETE_PROJECT_PCB_SUCCESS, UPDATE_PROJECT_PCB_AND_TREE_ERROR,
    UPDATE_PROJECT_PCB_AND_TREE_SUCCESS,
    UPDATE_PROJECT_PCB_ERROR, UPDATE_PROJECT_PCB_SUCCESS,
} from '../actions/pcb';
import {
    CLONE_PROJECT_CYLINDER_SUCCESS,
    CREATE_PROJECT_CYLINDER_SUCCESS, CREATE_PROJECT_CYLINDER_ERROR,
    DELETE_PROJECT_CYLINDER_SUCCESS, DELETE_PROJECT_CYLINDER_ERROR,
    UPDATE_PROJECT_CYLINDER_AND_TREE_SUCCESS, UPDATE_PROJECT_CYLINDER_ERROR, UPDATE_PROJECT_CYLINDER_AND_TREE_ERROR,
    UPDATE_PROJECT_CYLINDER_SUCCESS,
} from '../actions/cylinder';
import {
    CLONE_PROJECT_SOURCE_SUCCESS, CLONE_PROJECT_SOURCE_ERROR,
    CREATE_PROJECT_SOURCE_SUCCESS, CREATE_PROJECT_SOURCE_ERROR,
    DELETE_PROJECT_SOURCE_SUCCESS, DELETE_PROJECT_SOURCE_ERROR,
    UPDATE_PROJECT_SOURCE_AND_TREE_SUCCESS, UPDATE_PROJECT_SOURCE_ERROR, UPDATE_PROJECT_SOURCE_AND_TREE_ERROR,
    UPDATE_PROJECT_SOURCE_SUCCESS,
} from '../actions/source';
import {
    CLONE_PROJECT_TRANSIENT_SOURCE_SUCCESS,
    CLONE_PROJECT_TRANSIENT_SOURCE_ERROR,
    CREATE_PROJECT_TRANSIENT_SOURCE_SUCCESS,
    CREATE_PROJECT_TRANSIENT_SOURCE_ERROR,
    DELETE_PROJECT_TRANSIENT_SOURCE_SUCCESS,
    DELETE_PROJECT_TRANSIENT_SOURCE_ERROR,
    UPDATE_PROJECT_TRANSIENT_SOURCE_AND_TREE_SUCCESS,
    UPDATE_PROJECT_TRANSIENT_SOURCE_ERROR,
    UPDATE_PROJECT_TRANSIENT_SOURCE_AND_TREE_ERROR,
    UPDATE_PROJECT_TRANSIENT_SOURCE_SUCCESS,
    UPDATE_PROJECT_TRANSIENT_SOURCE_AND_TREE,
} from '../actions/transient_source';
import {
    CLONE_PROJECT_POLYGON_SUCCESS, CLONE_PROJECT_POLYGON_ERROR,
    CREATE_PROJECT_POLYGON_SUCCESS, CREATE_PROJECT_POLYGON_ERROR,
    DELETE_PROJECT_POLYGON_SUCCESS, DELETE_PROJECT_POLYGON_ERROR,
    UPDATE_PROJECT_POLYGON_AND_TREE_SUCCESS, UPDATE_PROJECT_POLYGON_ERROR, UPDATE_PROJECT_POLYGON_AND_TREE_ERROR,
    UPDATE_PROJECT_POLYGON_SUCCESS,
} from '../actions/polygon';
import {
    CREATE_SCENARIO_ERROR,
    CREATE_SCENARIO_SUCCESS, DELETE_SCENARIO_ERROR, GET_SCENARIO_CASES_ERROR, GET_SCENARIO_CASES_SUCCESS,
    getScenarios, UPDATE_SCENARIO_ERROR, UPDATE_SCENARIO_PARAM_ERROR,
    UPDATE_SCENARIO_PARAM_SUCCESS,
    UPDATE_SCENARIO_SUCCESS,
} from '../actions/scenarios';


function* clearValidationRules({payload}) {
    if (payload) {
        yield put(setValidationErrors({}));
    }
}

function* handleRequestError({payload}) {
    if (payload?.response?.status === 400) {
        try {
            const errors = yield payload.response.json();
            yield put(setValidationErrors(errors));
        } catch (e) {
        }
    }
}

function* handleRequestSuccess() {
    const modals = yield select(getModals);
    if (modals.length) {
        yield put(hideModal({title: 'BOX_MODAL'}));
    }
}

function* handleRequestSuccessAndLoadTree() {
    const modals = yield select(getModals);
    if (modals.length) {
        yield put(hideModal({title: 'BOX_MODAL'}));
    }
    const location = yield select(getLocation);
    const project_id = location.pathname.split('/').pop();

    yield put(loadProjectTree({project_id}));
    yield put(loadProjectAdjacency({project_id}));
}

function* handleUpdateProjectError({payload}) {
    if (payload?.response?.status === 400) {
        const errors = yield payload.response.json();
        yield put(setProjectValidationErrors(errors));
    }
}

function* handleObjectCloneSuccess() {
    const location = yield select(getLocation);
    const project_id = location.pathname.split('/').pop();

    yield put(loadProjectTree({project_id}));
    yield put(loadProjectAdjacency({project_id}));
}

function* handleSolveProjectSuccess() {
    // yield setTimeout(() => {
    //     taskStatus({payload: {task_id: 'xxxx'}});
    // }, 0);
    // const location = yield select(getLocation);
    // const project_id = location.pathname.split('/').pop();
    // const mode = yield select(getProjectMode);
    //
    // if(mode == 'solution') {
    //     yield put(loadProjectSolution({project_id}));
    // } else if(mode.substr(0, 6) == 'plane/') {
    //     const plane_id = mode.split('/').pop();
    //     yield put(clearProjectSolution());
    //     yield put(loadPlaneSolution({project_id, plane_id}));
    // }
}

function* handleProjectUndo() {
    const location = yield select(getLocation);
    const project_id = location.pathname.split('/').pop();

    // yield put(loadProject({project_id, mode: 'box', fit_object: null}));
    yield put(loadProjectTree({project_id}, {undo: true}));
    yield put(loadProjectMaterials({project_id}));
    yield put(loadPlanes({project_id}));
    // yield put(loadProjectTask({project_id}));
    // yield put(loadProjectSolution({project_id}));
    yield put(getParameters({project_id}));
    yield put(getScenarios({project_id}));
    yield put(loadProjectAdjacency({project_id}));
}

export function* handleProjectLoading() {

    yield put(clearProjectSolution());

    const location = yield select(getLocation);
    const project_id = location.pathname.split('/').pop();

    yield put(loadProject({project_id, mode: 'box', fit_object: null}));
    yield put(loadProjectTree({project_id}));
    yield put(loadProjectMaterials({project_id}));
    yield put(loadPlanes({project_id}));
    yield put(loadProjectTask({project_id}));
    yield put(loadProjectSolution({project_id}));
    yield put(loadProjectTransient({project_id}));
    yield put(getParameters({project_id}));
    yield put(getScenarios({project_id}));
    yield put(loadProjectAdjacency({project_id}));

    yield takeLatest([
        CREATE_PROJECT_BOX_SUCCESS, UPDATE_PROJECT_BOX_AND_TREE_SUCCESS, DELETE_PROJECT_BOX_SUCCESS, // box
        CREATE_PROJECT_CYLINDER_SUCCESS, UPDATE_PROJECT_CYLINDER_AND_TREE_SUCCESS, DELETE_PROJECT_CYLINDER_SUCCESS, // cylinder
        CREATE_PROJECT_SOURCE_SUCCESS, UPDATE_PROJECT_SOURCE_AND_TREE_SUCCESS, DELETE_PROJECT_SOURCE_SUCCESS, // source
        CREATE_PROJECT_TRANSIENT_SOURCE_SUCCESS, UPDATE_PROJECT_TRANSIENT_SOURCE_AND_TREE_SUCCESS, DELETE_PROJECT_TRANSIENT_SOURCE_SUCCESS, // transient source
        CREATE_PROJECT_POLYGON_SUCCESS, UPDATE_PROJECT_POLYGON_AND_TREE_SUCCESS, DELETE_PROJECT_POLYGON_SUCCESS, // polygon
        CREATE_PROJECT_ASSEMBLY_SUCCESS, TRANSLATE_PROJECT_ASSEMBLY_SUCCESS, UPDATE_PROJECT_ASSEMBLY_AND_TREE_SUCCESS, DELETE_PROJECT_ASSEMBLY_SUCCESS, // assembly
        CREATE_PROJECT_HEATSINK_SUCCESS, UPDATE_PROJECT_HEATSINK_AND_TREE_SUCCESS, DELETE_PROJECT_HEATSINK_SUCCESS, // heatsink
        CREATE_PROJECT_BGA_SUCCESS, UPDATE_PROJECT_BGA_AND_TREE_SUCCESS, DELETE_PROJECT_BGA_SUCCESS, // bga
        CREATE_PROJECT_VIA_ARRAY_SUCCESS, UPDATE_PROJECT_VIA_ARRAY_AND_TREE_SUCCESS, DELETE_PROJECT_VIA_ARRAY_SUCCESS,// via array
        CREATE_PROJECT_PCB_SUCCESS, UPDATE_PROJECT_PCB_AND_TREE_SUCCESS, DELETE_PROJECT_PCB_SUCCESS, // pcb
        UPDATE_PARAMETER_SUCCESS, // parameter
        UPDATE_PROJECT_AND_TREE_SUCCESS, // project tree
    ], handleRequestSuccessAndLoadTree);
    yield takeLatest([
        UPDATE_PROJECT_BOX_SUCCESS, // box
        UPDATE_PROJECT_HEATSINK_SUCCESS, // heatsink
        UPDATE_PROJECT_ASSEMBLY_SUCCESS, // assembly
        UPDATE_PLANE_SUCCESS, DELETE_PLANE_SUCCESS, CREATE_PLANE_SUCCESS, LOAD_PLANE_SOLUTIONS_SUCCESS, // plane
        LOAD_CLIP_FACE_SOLUTION_X0_SUCCESS, LOAD_CLIP_FACE_SOLUTION_X1_SUCCESS, // clip face solution
        LOAD_CLIP_FACE_SOLUTION_TIME_POINT_X0_SUCCESS, LOAD_CLIP_FACE_SOLUTION_TIME_POINT_X1_SUCCESS, // clip face solution
        LOAD_CLIP_FACE_SOLUTION_Y0_SUCCESS, LOAD_CLIP_FACE_SOLUTION_Y1_SUCCESS,
        LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Y0_SUCCESS, LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Y1_SUCCESS,
        LOAD_CLIP_FACE_SOLUTION_Z0_SUCCESS, LOAD_CLIP_FACE_SOLUTION_Z1_SUCCESS,
        LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Z0_SUCCESS, LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Z1_SUCCESS,
        UPDATE_PROJECT_PERMS_SUCCESS, // permission
        CREATE_PROJECT_MATERIAL_SUCCESS, UPDATE_PROJECT_MATERIAL_SUCCESS, // material
        UPDATE_PROJECT_CYLINDER_SUCCESS, // cylinder
        CREATE_PARAMETER_SUCCESS, // parameter
        CREATE_SCENARIO_SUCCESS, UPDATE_SCENARIO_SUCCESS, UPDATE_SCENARIO_PARAM_SUCCESS, GET_SCENARIO_CASES_SUCCESS, // scenario
        UPDATE_PROJECT_SOURCE_SUCCESS, // source
        UPDATE_PROJECT_TRANSIENT_SOURCE_SUCCESS, // source
        UPDATE_PROJECT_POLYGON_SUCCESS, // polygon
        UPDATE_PROJECT_VIA_ARRAY_SUCCESS, // via array
        UPDATE_PROJECT_PCB_SUCCESS, // pcb
        UPDATE_PROJECT_BGA_SUCCESS, // bga
        DOWNLOAD_PROJECT_STEP_SUCCESS,
    ], handleRequestSuccess);
    yield takeLatest([
        CREATE_PROJECT_BOX_ERROR, UPDATE_PROJECT_BOX_ERROR, DELETE_PROJECT_BOX_ERROR, UPDATE_PROJECT_BOX_AND_TREE_ERROR, // box and tree
        UPDATE_PROJECT_ASSEMBLY_AND_TREE_ERROR, TRANSLATE_PROJECT_ASSEMBLY_ERROR, // assembly
        CREATE_PROJECT_CYLINDER_ERROR, UPDATE_PROJECT_CYLINDER_ERROR, DELETE_PROJECT_CYLINDER_ERROR, UPDATE_PROJECT_CYLINDER_AND_TREE_ERROR, //cylinder
        LOAD_PROJECT_SOLUTION_ERROR, // project solution
        LOAD_PROJECT_CASE_SOLUTION_ERROR, //project case
        CREATE_PROJECT_PCB_ERROR, UPDATE_PROJECT_PCB_ERROR, DELETE_PROJECT_PCB_ERROR, UPDATE_PROJECT_PCB_AND_TREE_ERROR, //pcb
        CREATE_PROJECT_MATERIAL_ERROR, UPDATE_PROJECT_MATERIAL_ERROR, DELETE_PROJECT_MATERIAL_ERROR, // material
        CREATE_SCENARIO_ERROR, UPDATE_SCENARIO_ERROR, UPDATE_SCENARIO_PARAM_ERROR, UPDATE_SCENARIO_ERROR, DELETE_SCENARIO_ERROR, // scenario
        CREATE_PROJECT_VIA_ARRAY_ERROR, UPDATE_PROJECT_VIA_ARRAY_ERROR, UPDATE_PROJECT_VIA_ARRAY_AND_TREE_ERROR, DELETE_PROJECT_VIA_ARRAY_ERROR, // via array
        CREATE_PROJECT_SOURCE_ERROR, UPDATE_PROJECT_SOURCE_ERROR, UPDATE_PROJECT_SOURCE_AND_TREE_ERROR, DELETE_PROJECT_SOURCE_ERROR, // source
        CREATE_PROJECT_TRANSIENT_SOURCE_ERROR, UPDATE_PROJECT_TRANSIENT_SOURCE_ERROR, UPDATE_PROJECT_TRANSIENT_SOURCE_AND_TREE_ERROR, DELETE_PROJECT_TRANSIENT_SOURCE_ERROR, // source
        CREATE_PROJECT_POLYGON_ERROR, UPDATE_PROJECT_POLYGON_ERROR, UPDATE_PROJECT_POLYGON_AND_TREE_ERROR, DELETE_PROJECT_POLYGON_ERROR, // polygon
        CREATE_PARAMETER_ERROR, UPDATE_PARAMETER_ERROR, DELETE_PARAMETER_ERROR,  // parameter
        CREATE_PLANE_ERROR, UPDATE_PLANE_ERROR, DELETE_PLANE_ERROR, // plane
        CREATE_PROJECT_BGA_ERROR, UPDATE_PROJECT_BGA_ERROR, UPDATE_PROJECT_BGA_AND_TREE_ERROR, DELETE_PROJECT_BGA_ERROR, // bga
        CREATE_PROJECT_HEATSINK_ERROR, UPDATE_PROJECT_HEATSINK_ERROR, UPDATE_PROJECT_HEATSINK_AND_TREE_ERROR, DELETE_PROJECT_HEATSINK_ERROR, // heatsink
        UNDO_PROJECT_ERROR,
        LOAD_PROJECT_ADJACENCY_ERROR,
        DOWNLOAD_PROJECT_STEP_ERROR
    ], handleRequestError);

    yield takeLatest([HIDE_MODAL], clearValidationRules);

    yield takeLatest([UPDATE_PROJECT_ERROR], handleUpdateProjectError);

    yield takeLatest(SOLVE_PROJECT_SUCCESS, handleSolveProjectSuccess);

    yield takeLatest([
        CLONE_PROJECT_BGA_SUCCESS,
        CLONE_PROJECT_PCB_SUCCESS,
        CLONE_PROJECT_HEATSINK_SUCCESS,
        CLONE_PROJECT_BOX_SUCCESS,
        CLONE_PROJECT_ASSEMBLY_SUCCESS,
        CLONE_PROJECT_VIA_ARRAY_SUCCESS,
        CLONE_PROJECT_CYLINDER_SUCCESS,
        CLONE_PROJECT_SOURCE_SUCCESS,
        CLONE_PROJECT_TRANSIENT_SOURCE_SUCCESS,
        CLONE_PROJECT_POLYGON_SUCCESS,
    ], handleObjectCloneSuccess);

    yield takeLatest([
        UNDO_PROJECT_SUCCESS,
    ], handleProjectUndo);
    if (yield select(hasMaterials)) {
        return;
    }
}

export const sagas = function* () {
    yield takeLatest(
        action => action.type === LOCATION_CHANGE && action.payload.pathname.includes('/project/'),
        handleProjectLoading,
    );
};
