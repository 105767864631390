/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {LineSegments} from 'three/src/objects/LineSegments';
import {LineBasicMaterial} from 'three/src/materials/LineBasicMaterial.js';
import {Float32BufferAttribute} from 'three/src/core/BufferAttribute.js';
import {BufferGeometry} from 'three/src/core/BufferGeometry.js';
import {Color} from 'three/src/math/Color.js';

class GridHelper extends LineSegments {

    constructor(minx, maxx, miny, maxy, minz, maxz, drawGrid, color1, color2, color3, color4, color5) {
        color1 = new Color(color1 !== undefined ? color1 : 0x888888);
        color2 = new Color(color2 !== undefined ? color2 : 0x666666);
        color3 = new Color(color3 !== undefined ? color3 : 0x000000);
        color4 = new Color(color4 !== undefined ? color4 : 0x000000);
        color5 = new Color(color5 !== undefined ? color5 : 0x0000ff);

        const vertices = [], colors = [];

        let j = 0;

        if (drawGrid) {
            for (let x = Math.ceil(minx); x <= Math.floor(maxx); ++x) {
                if (x === 0)
                    continue;

                vertices.push(x, miny, 0, x, maxy, 0);

                const color = x === 0 ? color3 : (x % 10 === 0 ? color2 : color1);

                color.toArray(colors, j);
                j += 3;
                color.toArray(colors, j);
                j += 3;

            }
            for (let y = Math.ceil(miny); y <= Math.floor(maxy); ++y) {
                if (y === 0)
                    continue;
                vertices.push(minx, y, 0, maxx, y, 0);

                const color = y === 0 ? color4 : (y % 10 === 0 ? color2 : color1);

                color.toArray(colors, j);
                j += 3;
                color.toArray(colors, j);
                j += 3;

            }


            vertices.push(minx, 0, 0, maxx, 0, 0);
            color3.toArray(colors, j);
            j += 3;
            color3.toArray(colors, j);
            j += 3;
            vertices.push(0, miny, 0, 0, maxy, 0);
            color4.toArray(colors, j);
            j += 3;
            color4.toArray(colors, j);
            j += 3;
            vertices.push(0, 0, minz, 0, 0, maxz);
            color5.toArray(colors, j);
            j += 3;
            color5.toArray(colors, j);
            j += 3;
        }
        const geometry = new BufferGeometry();
        geometry.setAttribute('position', new Float32BufferAttribute(vertices, 3));
        geometry.setAttribute('color', new Float32BufferAttribute(colors, 3));

        const material = new LineBasicMaterial({vertexColors: true, toneMapped: false});
        material.polygonOffset = true;
        material.polygonOffsetFactor = Math.random();
        super(geometry, material);


        this.type = 'GridHelper';

    }

}


export {GridHelper};
