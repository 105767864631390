import React from 'react';
import PropTypes from 'prop-types';
import './CustomIconsSVG.scss';

const svgElementsCommonProps = {
    xmlns: 'http://www.w3.org/2000/svg',
    width: '72', height: '42',
    viewBox: '0 0 72 42',
};

const svgAxisCommonProps = {
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 32 32',
};

const customIconsSVGMap = {
    // Axis
    xy({ isActive, onClickHandler, ...rest }) {
        return (
            <svg id={'lock xy'}
                 data-name={'lock-xy'}
                 onClick={onClickHandler}
                 className={`axis-lock-icon xy ${isActive ? 'active' : ''}`}
                 {...svgAxisCommonProps}
                 {...rest}>
                <path style={{ fill: '#fff' }}
                      d={'M19.32,10.74l1.61-3.27h1.26l-2.22,4.23,2.27,4.3h-1.27l-1.64-3.33-1.65,3.33h-1.27l2.28-4.3-2.23-4.23h1.25l1.62,3.27Z'}/>
                <path style={{ fill: '#fff' }}
                      d={'M26.95,11.75l1.75-4.28h1.21l-2.43,5.35v3.18h-1.07v-3.18l-2.43-5.35h1.22l1.75,4.28Z'}/>
                <path style={{ fill: '#fff', isolation: 'isolate' }}
                      d={'M8.66,21.02c.04-.07,.03-.15-.01-.21-.26-.24-.44-.56-.51-.91-.01-.06-.05-.11-.1-.13-.04-.02-.1-.02-.14,0l-4.2,2.39v-3.08c-.01-.12-.08-.24-.18-.3-.11-.07-.24-.07-.35,0l-.8,.46c-.1,.06-.17,.17-.17,.29v4.13c0,.12,.07,.24,.17,.3l3.56,2.06c.11,.07,.24,.07,.35,0l.8-.46c.05-.03,.1-.08,.13-.13,.05-.07,.06-.16,.04-.25-.03-.09-.09-.16-.17-.21l-2.64-1.52,4.17-2.37s.04-.04,.05-.06Zm7.19-2.1c-.03,.05-.05,.11-.05,.18v3.07l-4.15-2.4c-.08-.05-.18-.02-.23,.06,0,.02-.02,.03-.02,.05-.08,.34-.26,.66-.5,.9-.07,.07-.07,.18,0,.25,.01,.01,.02,.02,.03,.03l4.12,2.38-2.66,1.54c-.05,.03-.1,.08-.13,.13-.09,.16-.04,.37,.12,.46l.8,.46c.11,.07,.24,.07,.35,0l3.57-2.07c.05-.03,.1-.08,.13-.13,.03-.05,.05-.11,.05-.18v-4.13c.01-.12-.05-.24-.16-.3-.96-.52-1.18-.46-1.27-.31Zm-5.35,1.01c.23-.39,.09-.89-.3-1.12s-.89-.09-1.12,.3c-.23,.39-.09,.89,.3,1.12h0c.39,.23,.89,.09,1.12-.3Z'}/>
            </svg>
        );
    },
    xz({ isActive, onClickHandler, ...rest }) {
        return (
            <svg id={'lock xz'}
                 data-name={'lock-xz'}
                 onClick={onClickHandler}
                 className={`axis-lock-icon xz ${isActive ? 'active' : ''}`}
                 {...svgAxisCommonProps}
                 {...rest}>
                <path style={{ fill: '#fff' }}
                      d={'M19.36,10.74l1.61-3.27h1.26l-2.22,4.23,2.27,4.3h-1.27l-1.64-3.33-1.65,3.33h-1.27l2.28-4.3-2.23-4.23h1.25l1.62,3.27Z'}/>
                <path style={{ fill: '#fff' }}
                      d={'M25.58,15.08h4.05v.92h-5.24v-.84l3.88-6.77h-3.84v-.92h5.04v.82l-3.89,6.79Z'}/>
                <path style={{ fill: '#fff', isolation: 'isolate' }}
                      d={'M11.83,19.83c-.08,0-.15,.05-.18,.12-.08,.35-.27,.66-.53,.9-.05,.04-.07,.1-.06,.15,0,.05,.03,.09,.07,.12l4.17,2.44-2.66,1.55c-.11,.06-.17,.18-.18,.3,0,.13,.06,.24,.18,.3l.8,.46c.11,.06,.23,.06,.34,0l3.58-2.07c.11-.06,.17-.18,.18-.3v-4.11c0-.13-.06-.24-.18-.3l-.8-.46c-.05-.03-.11-.05-.18-.04-.09,0-.17,.03-.24,.09-.06,.07-.1,.16-.1,.25v3.05l-4.14-2.42s-.05-.02-.08-.02Zm-5.41-5.17c.06,0,.12-.02,.18-.04l2.66-1.54v4.79c0,.09,.07,.17,.17,.17,.02,0,.04,0,.06,0,.34-.1,.7-.11,1.03-.01,.09,.03,.19-.03,.22-.12,0-.01,0-.03,0-.04v-4.76l2.66,1.54c.05,.03,.11,.05,.18,.04,.19,0,.34-.15,.34-.34v-.93c0-.13-.06-.24-.18-.3l-3.58-2.06c-.05-.03-.11-.05-.18-.04-.06,0-.12,.02-.18,.04l-3.58,2.05c-.11,.06-.17,.18-.18,.3,.03,1.09,.2,1.25,.37,1.25Zm3.55,4.13c-.45,0-.82,.37-.82,.82s.37,.82,.82,.82c.45,0,.82-.37,.82-.82h0c0-.45-.37-.82-.82-.82Z'}/>
            </svg>
        );
    },
    yz({ isActive, onClickHandler, ...rest }) {
        return (
            <svg id={'lock yz'}
                 data-name={'lock-yz'}
                 onClick={onClickHandler}
                 className={`axis-lock-icon yz ${isActive ? 'active' : ''}`}
                 {...svgAxisCommonProps}
                 {...rest}>
                <path style={{ fill: '#fff' }}
                      d={'M19.24,11.75l1.75-4.28h1.21l-2.43,5.35v3.18h-1.07v-3.18l-2.43-5.35h1.22l1.75,4.28Z'}/>
                <path style={{ fill: '#fff' }}
                      d={'M25.31,15.08h4.05v.92h-5.24v-.84l3.88-6.77h-3.84v-.92h5.04v.82l-3.89,6.79Z'}/>
                <path style={{ fill: '#fff', isolation: 'isolate' }}
                      d={'M8.88,16.96c.04,.07,.11,.1,.19,.09,.34-.11,.71-.1,1.05,.01,.06,.02,.12,.01,.16-.03,.04-.03,.06-.07,.07-.12l.03-4.83,2.67,1.53c.11,.06,.24,.06,.35,0,.11-.06,.18-.18,.17-.3v-.92c0-.12-.07-.23-.17-.29l-3.58-2.06c-.11-.06-.24-.06-.35,0l-3.56,2.06c-.11,.06-.18,.18-.17,.3v.92c0,.06,.02,.12,.05,.18,.04,.08,.11,.13,.2,.16,.09,.02,.19,0,.27-.04l2.64-1.52-.03,4.8s.01,.05,.03,.08Zm-1.78,7.27c-.03-.05-.08-.1-.13-.13l-2.66-1.54,4.15-2.4c.08-.05,.11-.15,.07-.23,0-.02-.02-.03-.04-.04-.26-.24-.44-.55-.53-.89-.02-.09-.12-.15-.21-.13-.01,0-.03,0-.04,.02l-4.12,2.38v-3.07c0-.06-.02-.12-.05-.18-.1-.16-.3-.22-.46-.12l-.8,.46c-.11,.06-.18,.18-.17,.3v4.13c0,.06,.03,.12,.06,.18,.03,.05,.07,.1,.13,.13l3.57,2.07c.11,.06,.24,.06,.35,0,.93-.57,.99-.8,.9-.95Zm1.81-5.14c.23,.39,.73,.53,1.12,.3s.53-.73,.3-1.12c-.23-.39-.73-.53-1.12-.3h0c-.39,.23-.53,.73-.3,1.12Z'}/>
            </svg>
        );
    },

    // Elements
    box({ isActive, onClickHandler, ...rest }) {
        return (
            <svg id={'element-box'}
                 data-name={'element-box'}
                 onClick={onClickHandler}
                 className={`create-element box ${isActive ? 'active' : ''}`}
                 {...svgElementsCommonProps}
                 {...rest}>
                <rect className={'container'} width={'72'} height={'42'} rx={'4'}/>
                <g transform={'translate(6 12)'}>
                    <rect width={'42'} height={'12'}
                          transform={'translate(0 12)'}// fill={'#303030'}
                          stroke={'#a6a6a6'}
                          strokeLinejoin={'round'} strokeWidth={'1'}/>
                    <path d={'M18,0H60L42,12H0Z'}/>
                    <path d={'M66,12V0L48,12V24Z'} transform={'translate(-6)'}/>
                </g>
            </svg>
        );
    },
    heatsink({ isActive, onClickHandler, ...rest }) {
        return (
            <svg id={'element-heatsink'}
                 data-name={'element-heatsink'}
                 onClick={onClickHandler}
                 className={`create-element heatsink ${isActive ? 'active' : ''}`}
                 {...svgElementsCommonProps}
                 {...rest}>
                <rect className={'container'} width={'72'} height={'42'} rx={'4'}/>
                <g transform={'translate(6 6)'}>
                    <path d={'M42,18H0V0H6V12h6V0h6V12h6V0h6V12h6V0h6V18Z'} transform={'translate(0 11.999)'}/>
                    <path d={'M18,0h6L6,12H0Z'}/>
                    <path d={'M66,10V-8L48,4V22Z'} transform={'translate(-6 8)'}/>
                    <path d={'M18,0h6L6,12H0Z'} transform={'translate(12)'}/>
                    <path d={'M18,0h6L6,12H0Z'} transform={'translate(24)'}/>
                    <path d={'M18,0h6L6,12H0Z'} transform={'translate(36)'}/>
                </g>
            </svg>
        );
    },
    via_array({ isActive, onClickHandler, ...rest }) {
        return (
            <svg id={'element-via_array'}
                 data-name={'element-via_array'}
                 onClick={onClickHandler}
                 className={`create-element via_array ${isActive ? 'active' : ''}`}
                 {...svgElementsCommonProps}
                 {...rest}>
                <rect className={'container'} width={'72'} height={'42'} rx={'4'}/>
                <g transform={'translate(6 9)'}>
                    <g transform={'translate(24)'}>
                        <g transform={'translate(30)'}>
                            <path d={'M0,12V0H6V12c0,.828-1.343,1.5-3,1.5S0,12.828,0,12Z'}
                                  transform={'translate(0 1.5)'}/>
                            <ellipse cx={'3'} cy={'1.5'} rx={'3'} ry={'1.5'}/>
                        </g>
                        <g>
                            <path d={'M0,12V0H6V12c0,.828-1.343,1.5-3,1.5S0,12.828,0,12Z'}
                                  transform={'translate(0 1.5)'}/>
                            <ellipse cx={'3'} cy={'1.5'} rx={'3'} ry={'1.5'}/>
                        </g>
                        <g transform={'translate(15)'}>
                            <path d={'M0,12V0H6V12c0,.828-1.343,1.5-3,1.5S0,12.828,0,12Z'}
                                  transform={'translate(0 1.5)'}/>
                            <ellipse cx={'3'} cy={'1.5'} rx={'3'} ry={'1.5'}/>
                        </g>
                    </g>
                    <g transform={'translate(12 6)'}>
                        <g transform={'translate(30)'}>
                            <path d={'M0,12V0H6V12c0,.828-1.343,1.5-3,1.5S0,12.828,0,12Z'}
                                  transform={'translate(0 1.5)'}/>
                            <ellipse cx={'3'} cy={'1.5'} rx={'3'} ry={'1.5'}/>
                        </g>
                        <g>
                            <path d={'M0,12V0H6V12c0,.828-1.343,1.5-3,1.5S0,12.828,0,12Z'}
                                  transform={'translate(0 1.5)'}/>
                            <ellipse cx={'3'} cy={'1.5'} rx={'3'} ry={'1.5'}/>
                        </g>
                        <g transform={'translate(15)'}>
                            <path d={'M0,12V0H6V12c0,.828-1.343,1.5-3,1.5S0,12.828,0,12Z'}
                                  transform={'translate(0 1.5)'}/>
                            <ellipse cx={'3'} cy={'1.5'} rx={'3'} ry={'1.5'}/>
                        </g>
                    </g>
                    <g transform={'translate(0 12)'}>
                        <g transform={'translate(30)'}>
                            <path d={'M0,12V0H6V12c0,.828-1.343,1.5-3,1.5S0,12.828,0,12Z'}
                                  transform={'translate(0 1.5)'}/>
                            <ellipse cx={'3'} cy={'1.5'} rx={'3'} ry={'1.5'}/>
                        </g>
                        <g>
                            <path d={'M0,12V0H6V12c0,.828-1.343,1.5-3,1.5S0,12.828,0,12Z'}
                                  transform={'translate(0 1.5)'}/>
                            <ellipse cx={'3'} cy={'1.5'} rx={'3'} ry={'1.5'}/>
                        </g>
                        <g transform={'translate(15)'}>
                            <path d={'M0,12V0H6V12c0,.828-1.343,1.5-3,1.5S0,12.828,0,12Z'}
                                  transform={'translate(0 1.5)'}/>
                            <ellipse cx={'3'} cy={'1.5'} rx={'3'} ry={'1.5'}/>
                        </g>
                    </g>
                </g>
            </svg>
        );
    },
    cylinder({ isActive, onClickHandler, ...rest }) {
        return (
            <svg id={'element-cylinder'}
                 data-name={'element-cylinder'}
                 onClick={onClickHandler}
                 className={`create-element cylinder ${isActive ? 'active' : ''}`}
                 {...svgElementsCommonProps}
                 {...rest}>
                <rect className={'container'} width={'72'} height={'42'} rx={'4'}/>
                <g transform={'translate(12 12)'}>
                    <g id="Group_10" data-name="Group 10" transform="translate(0 18) rotate(-90)">
                        <path d={'M0,36.667V0H18V36.667c0,2.531-4.03,4.583-9,4.583S0,39.2,0,36.667Z'}
                              transform={'translate(0 5.25)'}/>
                        <ellipse cx={'9'} cy={'4.5'} rx={'9'} ry={'4.5'}/>
                    </g>
                </g>
            </svg>
        );
    },
    pcb({ isActive, onClickHandler, ...rest }) {
        return (
            <svg id={'element-pcb'}
                 data-name={'element-pcb'}
                 onClick={onClickHandler}
                 className={`create-element pcb ${isActive ? 'active' : ''}`}
                 {...svgElementsCommonProps}
                 {...rest}>
                <rect className={'container'} width={'72'} height={'42'} rx={'4'}/>
                <g transform={'translate(6 12)'}>
                    <rect width={'42'} height={'6'} transform={'translate(0 18)'}/>
                    <path d={'M66,6V0L48,12v6Z'} transform={'translate(-6 6)'}/>
                    <rect width={'42'} height={'6'} transform={'translate(0 15)'}/>
                    <path d={'M66,6V0L48,12v6Z'} transform={'translate(-6 3)'}/>
                    <rect width={'42'} height={'6'} transform={'translate(0 12)'}/>
                    <path d={'M18,0H60L42,12H0Z'}/>
                    <path d={'M66,6V0L48,12v6Z'} transform={'translate(-6)'}/>
                </g>
            </svg>
        );
    },
    ball_array({ isActive, onClickHandler, ...rest }) {
        return (
            <svg id={'element-ball_array'}
                 data-name={'element-ball_array'}
                 onClick={onClickHandler}
                 className={`create-element ball_array ${isActive ? 'active' : ''}`}
                 {...svgElementsCommonProps}
                 {...rest}>
                <rect className={'container'} width={'72'} height={'42'} rx={'4'}/>
                <g transform={'translate(6 6)'}>
                    <g transform={'translate(12 4)'}>
                        <g>
                            <circle cx={'6'} cy={'6'} r={'6'}/>
                            <circle cx={'6'} cy={'6'} r={'5.5'}/>
                        </g>
                        <g transform={'translate(18)'}>
                            <circle cx={'6'} cy={'6'} r={'6'}/>
                            <circle cx={'6'} cy={'6'} r={'5.5'}/>
                        </g>
                        <g transform={'translate(36)'}>
                            <circle cx={'6'} cy={'6'} r={'6'}/>
                            <circle cx={'6'} cy={'6'} r={'5.5'}/>
                        </g>
                    </g>
                    <g transform={'translate(6 11)'}>
                        <g>
                            <circle cx={'6'} cy={'6'} r={'6'}/>
                            <circle cx={'6'} cy={'6'} r={'5.5'}/>
                        </g>
                        <g transform={'translate(18)'}>
                            <circle cx={'6'} cy={'6'} r={'6'}/>
                            <circle cx={'6'} cy={'6'} r={'5.5'}/>
                        </g>
                        <g transform={'translate(36)'}>
                            <circle cx={'6'} cy={'6'} r={'6'}/>
                            <circle cx={'6'} cy={'6'} r={'5.5'}/>
                        </g>
                    </g>
                    <g transform={'translate(0 18)'}>
                        <g>
                            <circle cx={'6'} cy={'6'} r={'6'}/>
                            <circle cx={'6'} cy={'6'} r={'5.5'}/>
                        </g>
                        <g transform={'translate(18)'}>
                            <circle cx={'6'} cy={'6'} r={'6'}/>
                            <circle cx={'6'} cy={'6'} r={'5.5'}/>
                        </g>
                        <g transform={'translate(36)'}>
                            <circle cx={'6'} cy={'6'} r={'6'}/>
                            <circle cx={'6'} cy={'6'} r={'5.5'}/>
                        </g>
                    </g>
                </g>
            </svg>
        )
            ;
    },
    source({ isActive, onClickHandler, ...rest }) {
        return (
            <svg id={'element-source'}
                 data-name={'element-source'}
                 onClick={onClickHandler}
                 className={`create-element source ${isActive ? 'active' : ''}`}
                 {...svgElementsCommonProps}
                 {...rest}>
                <rect className={'container'} width={'72'} height={'42'} rx={'4'}/>
                <g transform={'translate(6 15)'}>
                    <path d={'M18,0H60L42,12H0Z'}/>
                </g>
            </svg>
        );
    },
    transient_source({ isActive, onClickHandler, ...rest }) {
        return (
            <svg id={'element-transient-source'}
                 data-name={'element-transient-source'}
                 onClick={onClickHandler}
                 className={`create-element transient-source ${isActive ? 'active' : ''}`}
                 {...svgElementsCommonProps}
                 {...rest}>
                <rect className={'container'} width={'72'} height={'42'} rx={'4'}/>
                <g transform={'translate(6 15)'}>
                    <path d={'M18,0H60L42,12H0Z'}/>
                </g>
                <g transform={'translate(6 6)'}>
                    <path d={'M5635,272a2,2,0,0,1,4,0,2,2,0,0,0,4,0'} transform={'translate(-5621 -257)'}/>
                    <path d={'M5635,272a2,2,0,0,1,4,0,2,2,0,0,0,4,0'} transform={'translate(-5613 -257)'}/>
                    <path d={'M5635,272a2,2,0,0,1,4,0,2,2,0,0,0,4,0'} transform={'translate(-5605 -257)'}/>
                    <path d={'M5635,272a2,2,0,0,1,4,0,2,2,0,0,0,4,0'} transform={'translate(-5597 -257)'}/>
                </g>
            </svg>
        );
    },
    plane({ isActive, onClickHandler, ...rest }) {
        return (
            <svg id={'element-plane'}
                 data-name={'element-plane'}
                 onClick={onClickHandler}
                 className={`create-element plane ${isActive ? 'active' : ''}`}
                 {...svgElementsCommonProps}
                 {...rest}>
                <g transform={'translate(0 0.5)'}>
                    <g transform={'translate(6 15)'}>
                        <path d={'M18-0.5h42l-18,12H0L18-0.5z'}/>
                    </g>
                    <g transform={'translate(38 -9) rotate(90)'}>
                        <path className={'no-fill'} d={'M9.5-6H37l11.5,16H21.9L9.5-6z'}/>
                        <line className={'line'} x1={'35.5'} y1={'10'} x2={'35.5'} y2={'-6'}/>
                    </g>
                    <line className={'line dashed'} x1={'28'} y1={'26.5'} x2={'44'} y2={'14.4'}/>
                </g>
            </svg>
        );
    },
};


export const CustomIconsSVG = ({
                                   iconType = '',
                                   isActive = false,
                                   onClickHandler = () => {
                                   },
                                   ...rest
                               }) => {
    return (
        <>
            {customIconsSVGMap[iconType]({ isActive, onClickHandler, ...rest })}
        </>
    );
};

CustomIconsSVG.propTypes = {
    iconType: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    onClickHandler: PropTypes.func,
};

CustomIconsSVG.defaultProps = {
    iconType: '',
    isActive: false,
    onClickHandler: () => {
    },
};