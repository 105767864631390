/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {confirmAlert} from 'react-confirm-alert';

import {
    Icon,
    Accordion, AccordionSummary, AccordionDetails,
    ListItemButton, ListItemText,
} from '@mui/material';

import {showModal} from '../../../../../../../modals/actions';
import {setSelectedObject} from '../../../../../../+store/actions/actions';
import {deletePlane, updatePlane} from '../../../../../../+store/actions/planes';

import {getSelectedObject} from '../../../../../../+store/reducer/tree';
import {getData as getPlanes} from '../../../../../../+store/reducer/planes';

import {IconsFontAwesome} from '../../../../../../../components/IconsFontAwesome';
import {CustomizedSwitch} from '../../../../../../../components/CustomizedSwitch';

import CreatePlaneSettings from '../../../../../settings/CreatePlaneSettings';

import {FONT_AWESOME_ICONS, FONT_AWESOME_ICONS_TYPE_MAP} from '../../../../../../../core/mappings';
import {projectUtils} from '../../../../../../utils';


const PlanesSection = ({
                           current_project, expanded, handleExpandCollapse,
                           planes, updatePlane, deletePlane, showModal,
                           selectedObject, setSelectedObject,
                       }) => {
    const [sectionsShowMoreMenu, setSectionsShowMoreMenu] = useState('');

    const showPlaneModal = (event) => {
        projectUtils.stopPreventDefault(event);

        showModal({
            type: 'custom',
            title: 'Add Plane',
            content: (props) => {
                return <CreatePlaneSettings {...props} />;
            },
        });
    };

    const handleToggleSectionsMoreMenu = (event, sectionId = '') => {
        setSectionsShowMoreMenu(sectionId);
    };

    const toggleVisibility = (event, plane) => {
        plane.visible = !plane.visible;
        updatePlane({
            project_id: current_project.id,
            plane,
        });

        projectUtils.stopPreventDefault(event);
    };


    return (
        <>
            <ListItemButton onClick={handleExpandCollapse}
                            className={'aside-panel-list-header'}>
                <ListItemText primary={'PLANES'} className={'text'}/>
                {
                    expanded
                        ? <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.caretCollapse}
                                            secondClass={'model-arrow-icon'}
                                            titleAccess={'Collapse Section'}/>
                        : <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.caretExpand}
                                            secondClass={'model-arrow-icon'}
                                            titleAccess={'Expand Section'}/>
                }
                {
                    current_project &&
                    (current_project.owner || current_project.edit) &&
                    <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.plus}
                                      secondClass={'add-plane-icon'}
                                      titleAccess={'Add Plane'}
                                      onClickHandler={showPlaneModal}/>
                }
            </ListItemButton>
            <Accordion disableGutters
                       elevation={0}
                       TransitionProps={{unmountOnExit: true}}
                       expanded={expanded || false}
                       sx={{
                           width: '100%', position: 'relative',
                           overflow: 'auto', height: expanded ? '194px' : '100%',
                       }}>
                <AccordionSummary aria-controls={'planes-section-content'}
                                  sx={{
                                      display: 'none',
                                      height: '0',
                                      backgroundColor: 'secondary.main',
                                      p: '0 24px 0 8px',
                                      width: '100%',
                                      '& .MuiAccordion-region': {
                                          width: '100%',
                                      },
                                  }}
                />
                <AccordionDetails
                    className={'planes-section-wrapper'}
                    sx={{
                        backgroundImage: 'none',
                        width: 'inherit',
                        '&.MuiCollapse-wrapperInner': {
                            width: '100%',
                        },
                    }}>
                    {planes && planes.length
                        ? <ul style={{listStyleType: 'none'}}>
                            {planes
                                .sort((a, b) => {
                                    if (a.name < b.name) return -1;
                                    else if (a.name > b.name) return 1;
                                    else return 0;
                                })
                                .map(plane => {
                                    return (
                                        <li key={plane.id}
                                            style={{opacity: plane.visible ? '1' : '0.5'}}
                                            className={selectedObject?.type === 'plane' && selectedObject?.id === plane.id ? 'selected' : ''}
                                            onMouseLeave={handleToggleSectionsMoreMenu}
                                        >
                                            <div className={'plane-item-content'}>
                                                                    <span className={'plane-title'}
                                                                          onClick={() => setSelectedObject(plane)}>
                                                                    {plane.name}
                                                                    </span>
                                                <div className={'main-actions-container'}>
                                                    <div className={'more-menu-icons'}
                                                         style={{
                                                             zIndex: sectionsShowMoreMenu !== plane.id ? -1 : 0,
                                                             opacity: sectionsShowMoreMenu !== plane.id ? 0 : 1,
                                                             transform: sectionsShowMoreMenu !== plane.id ? 'translateX(0)' : 'translateX(-25px)',
                                                         }}>
                                                        <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.delete}
                                                                          secondClass={'smaller'}
                                                                          titleAccess={`Delete ${plane.name}`}
                                                                          onClickHandler={() => {
                                                                              confirmAlert({
                                                                                  title: `Deleting ${plane.name}`,
                                                                                  message: 'Do you wish to proceed?',
                                                                                  buttons: [
                                                                                      {
                                                                                          label: 'Delete',
                                                                                          onClick: () => {
                                                                                              deletePlane({
                                                                                                  project_id: current_project.id,
                                                                                                  box: {...plane},
                                                                                              });
                                                                                          },
                                                                                      }, {
                                                                                          label: 'Cancel',
                                                                                          onClick: () => {
                                                                                          },
                                                                                      },
                                                                                  ],
                                                                              });
                                                                          }}/>
                                                        {/*<IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.clone}*/}
                                                        {/*                  iconsSX={{color: '#F50057'}}*/}
                                                        {/*                  titleAccess={`Duplicate ${plane.name}`}*/}
                                                        {/*                  onClickHandler={() => {}}/>*/}
                                                    </div>
                                                    <CustomizedSwitch checked={plane.visible}
                                                                      onChange={(event) => toggleVisibility(event, plane)}/>

                                                    <Icon title={'Show More'}
                                                          aria-label={'Show More'}
                                                          baseClassName={FONT_AWESOME_ICONS[FONT_AWESOME_ICONS_TYPE_MAP.moreVertDots].main.baseClassName} // main icon
                                                          className={FONT_AWESOME_ICONS[FONT_AWESOME_ICONS_TYPE_MAP.moreVertDots].className}
                                                          onMouseEnter={(e) => handleToggleSectionsMoreMenu(e, plane.id)}/>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                        </ul>
                        : <div className={'empty centered'}>There are no planes created!</div>
                    }
                </AccordionDetails>
            </Accordion>
        </>
    );
};


PlanesSection.propTypes = {
    current_project: PropTypes.object,
    expanded: PropTypes.bool.isRequired,
    selectedObject: PropTypes.object,
    showModal: PropTypes.func.isRequired,
    handleExpandCollapse: PropTypes.func.isRequired,
    setSelectedObject: PropTypes.func.isRequired,
    planes: PropTypes.arrayOf(PropTypes.object),
    updatePlane: PropTypes.func.isRequired,
    deletePlane: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    planes: getPlanes(state),
    selectedObject: getSelectedObject(state),
});

const mapDispatchToProps = {
    showModal,
    setSelectedObject,
    updatePlane,
    deletePlane,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanesSection);
