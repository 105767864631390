/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

function EULA() {
    return <div>
        <h2>Anemoi Software Inc. Terms of Use and End User License Agreement</h2>

        <p>
            Please read this End-User License Agreement (referred to herein as “EULA”) carefully. By accessing the
            webpages at https://anemoisoftware.com/, you, the licensee, (referred to herein as “<b>You</b>”) are deemed to
            have read, understood, and accepted the terms of this EULA by opening, installing, or using any or all
            portions of this software (referred to herein as <b>“Anemoi Thermal Solver”</b> or <b>"ATS"</b>), or by selecting the button
            labeled “I Agree To Be Bound By Term of This EULA” or performing a similar electronic function.<br/>
            <br/>
            This EULA is a legally binding agreement between <b>You</b> and Anemoi Software, Inc of San Jose, California
            (referred to herein as “<b>Anemoi</b>”) regarding <b>ATS</b>. This EULA is enforceable against <b>You</b> and against any
            entity that obtained <b>ATS</b> and on whose behalf it is used.
            <br/>
            <br/>
            It is illegal and strictly prohibited to distribute, publish, offer for sale, license,
            sublicense, give, or disclose <b>ATS</b> to any other party in hard copy, digital form, or any
            other medium whether existing or not yet existing, except as specifically permitted below.<br/>
            <br/>
            The use of <b>ATS</b> is governed by the terms and conditions set forth herein. This EULA
            is applicable to any and all parts of <b>ATS</b>.
        </p>

        <h3>1. LIMITED LICENSE</h3>

        <p>
            <b>You</b> are granted a limited, non-exclusive, non-transferable license for use of <b>ATS</b> by the registered
            individual, provided that the computer does not render <b>ATS</b> accessible to other users (such as
            through local networks—private or public, Internet networks, or other methods). <b>ATS</b> is in use on a
            computer when it is loaded into temporary memory RAM or installed into a permanent memory of a computer
            (e.g., hard disk flash drive, or any storage device). <b>ATS</b> is also in use when its features are
            accessible to <b>You</b> via a web interface even though <b>ATS</b> source code resides in a cloud-computing
            network.
            <br/>
            <br/>
            The license granted to <b>You</b> shall remain in effect perpetually, but shall terminate upon use by <b>You</b> of
            <b>ATS</b> beyond the scope licensed herein, or upon violation of any term or condition hereof.<br/>
            <br/>
            All protections with which <b>ATS</b>. is provided under this EULA shall survive the termination of the
            license to <b>You</b> to use <b>ATS</b>.
            <br/>

        </p>

        <h3>2. PERMITTED USAGE</h3>

        Upon fulfilling your obligations toward properly opening, installing, and using <b>ATS</b>, <b>You</b> are permitted
        to do only the following:<br/>

        <li>Use <b>ATS</b> to design and/or develop one or more electronic chips or modules.</li>
        <li>Use <b>ATS</b> to create, access, or save one or more files (referred to herein as “Files”) pertaining to
            the design and/or development of the electronic chips or modules.
        </li>
        <li>Use <b>ATS</b> to share one or more Files with a receiving party.</li>
        <br/>
        In order to share one or more Files with a receiving party, a receiving party must also have a license to
        use <b>ATS</b>. Screenshots of <b>ATS</b> may be shared with unlicensed individuals.<br/>
        <br/>
        <b>You</b> shall make no efforts to reverse engineer <b>ATS</b>, or make any modifications or enhancements
        without the express written consent of <b>Anemoi</b>.<br/>

        <h3>3. SUPPORT AND MAINTENANCE</h3>

        <b>Anemoi</b> will maintain <b>ATS</b> by providing software updates and enhancement to <b>You</b>. All software
        updates and enhancements are provided on an as-available basis and include the items listed below:<br/>

        <li>Bug fixes;</li>
        <li>Enhancements for schematic capture, layout, version control, design rule checking, and design
            support;
        </li>
        <li>Enhancements to keep <b>ATS</b> up-to-date with new releases of web navigators and/or operating
            systems generally used in the industry; and
        </li>
        <li>Enhancements of <b>ATS</b> to work with other <b>Anemoi</b> tools</li>

        <br/>

        Software updates may not include: support for different hardware platforms and special operating
        systems not widely used in the industry.<br/>

        <br/>
        Maintenance services do not include: custom programming services, on-site support including
        installation of hardware or software, training, or out-of-pocket and other expenses including
        hardware and related supplies.<br/>

        <br/>
        <b>Anemoi</b> may provide <b>You</b> basic technical support for operating <b>ATS</b>. The support may be
        in any form deemed appropriate by <b>Anemoi</b>. For example, <b>Anemoi</b> may provide online technical
        support as frequently-asked-questions (FAQs) and corresponding answers. <b>Anemoi</b> may also make
        available a technical support hot-line to <b>You</b> for <b>ATS</b> related issues. Beyond basic
        technical support, <b>Anemoi</b> reserves the right to charge you a fee for one-time or continued
        support and maintenance of <b>ATS</b>.<br/>

        <br/>
        <b>Anemoi</b> may also make available new version(s) of <b>ATS</b>. A new version may make an
        older version of <b>ATS</b> obsolete and/or non-functional. <b>Anemoi</b> will not provide <b>You</b>
        any support for the older <b>ATS</b> version beyond a period of 6 months from the time
        the new version is made available to <b>You</b>. <b>You</b> are encouraged to download and/or
        reinstall the new version of <b>ATS</b>.<br/>

        <br/>
        <b>Anemoi</b> will attempt best efforts to make the transition between versions of <b>ATS</b> seamless. However, <b>Anemoi</b> will not be held responsible for any loss of
        data or work product due to changes in versions of <b>ATS</b>. For example, <b>Anemoi</b>
        assumes no responsibility for the correctness of, performance of, or compatibility
        with current or future releases of <b>ATS</b>, if <b>You</b> made changes to the system
        hardware/software configuration or modifications to any supplied source code that
        effect the performance of <b>ATS</b> and were made without prior notification and
        written approval by <b>Anemoi</b>. <b>Anemoi</b> assumes no responsibility for the operation or
        performance of any client-written or third-party application.<br/>

        <h3>4. INTELLECTUAL PROPERTY RIGHTS</h3>
        <p>
            <b>Anemoi</b> retains all title, ownership, and intellectual property rights in <b>ATS</b>, including any and all supporting documentation, files, programs,
            applications, and marketing materials. <b>ATS</b> is protected by copyright
            laws, trade secret laws, other intellectual property laws, and international
            treaties. Here, intellectual property rights include: patents, copyrights,
            trademarks, trade service, trade secrets, etc.<br/>

            <br/>
            <b>You</b> must treat <b>ATS</b> and its documentation like any other copyrighted
            material. For example, <b>You</b> may not: copy the documentation, copy <b>ATS</b>
            except to make a backup copy, modify or adapt <b>ATS</b> or merge it into
            another program; reverse engineer, dis-assemble, decompile, or make any
            attempt to discover the source code of <b>ATS</b>; place <b>ATS</b> onto a
            server so that it is accessible via a public network such as the internet or
            cloud; or sublicense, rent, lease or lend any portion of <b>ATS</b> or its
            documentation.<br/>

            <br/>
            <b>Anemoi</b> permits <b>You</b> to open, install, use, or otherwise benefit from the
            functionality of <b>ATS</b>, and the intellectual property rights in <b>ATS</b>, only in accordance with the
            terms of this EULA. <b>ATS</b> may include security features to control or limit access to authorized
            use, and <b>You</b> agree not to interfere with such security features.<br/>

            <br/>
            Use of third-party material included in <b>ATS</b> may be subject
            to terms and conditions typically found in the license agreements of
            the third party.
        </p>

        <h3>5. LIMITATION OF LIABILITY</h3>
        <p>Except as required by law, <b>Anemoi</b> and its employees, directors, licensors,
            contributors, and agents will not be liable for any direct, indirect, special,
            incidental, consequential, or exemplary damages arising out of, or in any way
            relating to, this EULA or the use of or inability to use <b>ATS</b>, including
            without limitation damages for loss of goodwill, work stoppage, lost profits,
            loss of data, and computer failure or malfunction, even if advised of the
            possibility of such damages and regardless of the theory (contract, tort, or
            otherwise) upon which such claim is based. <b>Anemoi</b>’s liability under this EULA is
            limited to provision of or access to replacement of <b>ATS</b> and will not
            exceed five dollars (USD $5.00).<br/>
        </p>

        <h3>6. CONFIDENTIALITY</h3>

        <p>
            Each party agrees that it shall not disclose to any third party any confidential
            information of the other party, including intellectual property and design
            and/or development information, which is learned during the course of
            performance of this EULA, without the prior written consent of that other party.<br/>

            <br/>
            <b>ATS</b> contains trade secrets and proprietary know-how that belong to <b>Anemoi</b>, and which are being
            made available to <b>You</b> in strict confidence. Any
            use or disclosure of <b>ATS</b>, or of its interfaces, protocols, or
            algorithms, other than in strict accordance with this EULA, may be
            actionable as a violation of <b>Anemoi</b>’s trade secret rights and other
            intellectual property rights.<br/>

            <br/>
            The files produced by <b>ATS</b> for the user will not be disclosed to
            third parties by <b>Anemoi</b> except in accordance with <b>Anemoi</b>’s privacy policy
            or with the prior written approval of the user. For example, design
            information may be shared with <b>Anemoi</b>’s partners in order to build
            prototypes or manufacture production units in response to a request by
            the user.<br/>

            <br/>
            <b>You</b> shall not refer to the existence of this Agreement in any press
            release, advertising or materials distributed to prospective
            customers without the prior written consent of <b>Anemoi</b>.<br/>

            <br/>
            This obligation shall survive the cancellation or other
            termination of this EULA.

            <br/>
        </p>

        <h3>7. DISCLAIMER OF WARRANTY</h3>
        <p>
            <b>You</b> accept <b>ATS</b> “as is” and with all faults (if any). <b>Anemoi</b> disclaims all
            warranties, express or implied, including but not limited to warranties of
            merchantability and fitness for a particular purpose. <b>Anemoi</b> makes no warranty or
            representation whatsoever regarding the suitability of <b>ATS</b> to meet your
            specific needs.<br/>

            <br/>
            Since <b>Anemoi</b> makes no warranty or representation regarding <b>ATS</b> as
            described above, there should be no reason for <b>You</b> to bring any grievance
            against <b>Anemoi</b>. However, if <b>You</b> do bring any action, claim, suit, threat, or
            demand against <b>Anemoi</b>, and <b>You</b> do not substantially prevail, <b>You</b> shall pay
            <b>Anemoi</b>’s entire attorney fees and costs attached to that action, claim, suit,
            threat, or demand. Moreover, if <b>Anemoi</b> is forced to take legal action to enforce
            this EULA or any of its rights described herein or under any state or federal
            law, <b>You</b> shall pay <b>Anemoi</b>’s entire attorney fees and costs attached to that legal
            action.<br/>

            <br/>
        </p>

        <h3>8. INDEMNITY</h3>
        <p>
            <b>You</b> must indemnify, defend, and hold harmless <b>Anemoi</b>, its board members,
            officers, employees, and agents, from and against any and all claims (including
            third-party claims), demands, actions, suits, expenses (including attorney’s
            fees), and damages (including indirect or consequential loss) resulting in any
            way from reliance by <b>You</b> on <b>ATS</b>, on any breach of the terms of this EULA
            by <b>You</b>, or on any other act by <b>You</b>.<br/>

            <br/>
            This obligation shall survive the cancellation or other termination of this
            EULA.<br/>


            <br/>
        </p>

        <h3>9. WAIVER AND SEVERABILITY</h3>
        <p>
            <b>Anemoi</b>'s waiver of any breach of this EULA shall not constitute an amendment to
            this EULA, or a waiver by <b>Anemoi</b> of subsequent breaches. If any part of this EULA
            is found void and unenforceable, it will not affect the validity of the balance
            of this EULA, which will remain valid and enforceable according to its
            terms.<br/>
            <br/>

        </p>

        <h3>10. GOVERNING LAW</h3>
        <p>
            This EULA shall be governed by and construed under the laws of the State of
            California. <b>You</b> consent and agree that all legal proceedings relating to the
            subject matter of this EULA shall be maintained in courts sitting with the State
            of California, and that Jurisdiction and venue for those legal proceedings shall
            lie exclusively with those courts.<br/>
            <br/>

        </p>

        <h3>11. TERMINATION</h3>
        <p>

            <b>Anemoi</b> shall have the sole and exclusive right to immediately terminate this EULA
            and suspend the license to <b>You</b> to use <b>ATS</b>, should <b>You</b> fail to perform
            any obligation required under this EULA, engage in activity that <b>Anemoi</b> deems
            harmful to its well-being, or become bankrupt or insolvent.<br/>

            <br/>
            This EULA shall take effect upon your opening, installing, or using <b>ATS</b> as discussed herein. Having
            taken effect, this EULA shall remain
            effective until terminated. Upon termination of this EULA by <b>Anemoi</b>, <b>You</b> shall delete or
            return to <b>Anemoi</b> any and all copies of <b>ATS</b>.<br/>
            <br/>
        </p>

        <h3>12. AMENDMENTS</h3>
        <p>
            <b>Anemoi</b> reserves the right to unilaterally amend all offers, terms, or other
            matters pertaining to <b>ATS</b>, <b>Anemoi</b>’s website, or this EULA. No course of
            dealing or trade usage shall be deemed to amend the terms of this EULA.<br/>

            <br/>
        </p>

        <h3>13. ENTIRE AGREEMENT</h3>
        <p>
            This EULA is the entire agreement between <b>Anemoi</b> and <b>You</b> relating to <b>ATS</b>,
            and it supersedes any prior representations, discussions, undertakings,
            warranties, communications, or advertising related to <b>ATS</b>.<br/>
            <br/>
        </p>
    </div>
}

export default EULA;
