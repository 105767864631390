import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {confirmAlert} from 'react-confirm-alert';

import {
    Button,
    Input,
    InputAdornment,
    Paper,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip, Typography
} from '@mui/material';

import {getProjectMaterials} from '../../../+store/reducer/project';

import {IconsFontAwesome} from '../../../../components/IconsFontAwesome';
import {FONT_AWESOME_ICONS_TYPE_MAP} from '../../../../core/mappings';
import {projectUtils} from '../../../utils';
import ImportMaterial from '../../ImportMaterial';
import MaterialSettings from '../../settings/MaterialSettings';
import {deleteProjectMaterial} from '../../../+store/actions/project';
import {showModal} from '../../../../modals/actions';


const MaterialsComponent = ({classes, materials, deleteProjectMaterial, current_project, showModal}) => {
    const [filter, setFilter] = useState('');

    const showImportMaterialModal = (event) => {
        projectUtils.stopPreventDefault(event);

        showModal({
            type: 'custom',
            title: 'Import material',
            content: (props) => {
                return <ImportMaterial project={current_project} {...props} />;
            },
        });
    };

    const showUpdateProjectMaterialModal = (event, material) => {
        projectUtils.stopPreventDefault(event);

        showModal({
            type: 'custom',
            title: 'Edit material',
            content: (props) => {
                return <MaterialSettings project={current_project} material={material} {...props} />;
            },
        });
    };

    const showCreateProjectMaterialModal = (event) => {
        projectUtils.stopPreventDefault(event);

        showModal({
            type: 'custom',
            title: 'Add material',
            content: (props) => {
                return <MaterialSettings project={current_project} {...props} />;
            },
        });
    };

    return (
        <Typography component={'div'} className={classes.materialsPanel} sx={{padding: '5px'}}>
            {
                current_project &&
                (current_project.owner || current_project.edit) &&
                <>
                    <Tooltip title={'Import one or more materials from your libraries'}>
                        <Button
                            id="importmaterialbutton"
                            className={classes.materialButton}
                            color={'primary'}
                            variant={'contained'}
                            onClick={(event) => showImportMaterialModal(event)}
                        >
                            <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.importArrow}
                                              titleAccess={`Import Material`}
                                              secondClass={'smaller'}
                                              hasHover={false}/>
                            Import
                        </Button>
                    </Tooltip>
                    <Tooltip title={'Create project specific material'}>
                        <Button
                            id="creatematerialbutton"
                            className={classes.materialButton}
                            color={'primary'}
                            variant={'contained'}
                            onClick={(event) => showCreateProjectMaterialModal(event)}
                        >
                            <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.plus}
                                              titleAccess={`Add Material`}
                                              hasHover={false}/>
                            Material
                        </Button>
                    </Tooltip>
                </>
            }
            <Input
                className={classes.search}
                // fullWidth={true}
                sx={{width: '98%'}}
                onChange={(event) => setFilter(event.target.value)}
                startAdornment={
                <InputAdornment position={'start'}>
                    <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.search} secondClass={'smaller'} hasHover={false}/>
                </InputAdornment>}
                placeholder={'Find material'}
            />
            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table className={classes.table} aria-label={'simple table'} size={'small'}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Source library</TableCell>
                            <TableCell align={'right'}>Thermal Conductivity</TableCell>
                            <TableCell align={'right'}>Color</TableCell>
                            <TableCell align={'right'}>Density</TableCell>
                            <TableCell align={'right'}>Specific Heat</TableCell>
                            <TableCell align={'right'}/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            materials &&
                            materials
                                .filter(material => (!filter || material.name.toUpperCase().includes(filter.toUpperCase())))
                                .sort((a, b) => {
                                    if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
                                    if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
                                    return 0;
                                })
                                .map((material) => (
                                    <TableRow key={material.id} hover>
                                        <TableCell component={'th'} scope={'row'}>
                                            {material.name}
                                            {
                                                material.source_material &&
                                                material.name !== material['source_material'].name &&
                                                <Tooltip title={
                                                    <React.Fragment>
                                                        <Typography>
                                                            This material's name
                                                            is <em>{material['source_material'].name}</em> in
                                                            the source library
                                                        </Typography>
                                                    </React.Fragment>}>
                                                    <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.warning}
                                                                      titleAccess={`Show ${material.name} Properties`}
                                                                      hasHover={false}
                                                                      secondClass={'smaller'}/>
                                                </Tooltip>
                                            }
                                        </TableCell>
                                        <TableCell>{material['library_name']}</TableCell>
                                        <TableCell align={'right'}>
                                            {material.anisotropic ?
                                                [material.conductivity, material.conductivity_y, material.conductivity_z].join(' / ') :
                                                material.conductivity} W/m&#8729;K
                                            {
                                                material.source_material &&
                                                (
                                                    material.anisotropic !== material.source_material.anisotropic ||
                                                    (material['conductivity_calc'] !== material.source_material.conductivity) ||
                                                    (material.anisotropic && material['conductivity_y_calc'] !== material.source_material.conductivity_y) ||
                                                    (material.anisotropic && material['conductivity_z_calc'] !== material.source_material.conductivity_z)
                                                ) &&
                                                <Tooltip title={
                                                    <React.Fragment>
                                                        <Typography>
                                                            This material's conductivity
                                                            is <em>{
                                                            material.source_material.anisotropic ?
                                                                [material.source_material.conductivity, material.source_material.conductivity_y, material.source_material.conductivity_z].join(' / ') :
                                                                material.source_material.conductivity
                                                        }</em> W/m&#8729;K
                                                            in the source library
                                                        </Typography>
                                                    </React.Fragment>
                                                }>
                                                    <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.warning}
                                                                      titleAccess={`Show ${material.name} Properties`}
                                                                      hasHover={false}
                                                                      secondClass={'smaller'}/>
                                                </Tooltip>
                                            }
                                        </TableCell>
                                        <TableCell align={'right'} style={{
                                            background: material.color,
                                            float: 'right',
                                            border: '1px solid black',
                                            width: '32px',
                                            height: '16px',
                                        }}>
                                        </TableCell>
                                        <TableCell align={'right'}>
                                            {
                                                material.density &&
                                                <span>{material.density} kg/m&sup3;</span>
                                            }
                                            {
                                                material.source_material &&
                                                material.density !== material.source_material.density &&
                                                <Tooltip title={
                                                    <React.Fragment>
                                                        {
                                                            material.source_material.density ?
                                                                <Typography>
                                                                    This material's density
                                                                    is <em>{material.source_material.density}</em> kg/m&sup3; in
                                                                    the source library :
                                                                </Typography> :
                                                                <Typography>
                                                                    This material does not have density
                                                                    value
                                                                    is in the source library
                                                                </Typography>
                                                        }
                                                    </React.Fragment>}>
                                                    <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.warning}
                                                                      titleAccess={`Show ${material.name} Properties`}
                                                                      hasHover={false}
                                                                      secondClass={'smaller'}/>
                                                </Tooltip>
                                            }
                                        </TableCell>
                                        <TableCell align={'right'}>
                                            {
                                                material.specific_heat &&
                                                <span>{material.specific_heat} J/kg&#8729;K</span>
                                            }
                                            {
                                                material.source_material &&
                                                material.specific_heat !== material.source_material.specific_heat &&
                                                <Tooltip title={
                                                    <React.Fragment>
                                                        {
                                                            material.source_material.specific_heat ?
                                                                <Typography>
                                                                    This material's specific heat
                                                                    is <em>{material.source_material.specific_heat}</em> J/kg&#8729;K
                                                                    in the source library
                                                                </Typography> :
                                                                <Typography>
                                                                    This material does not have specific
                                                                    heat value
                                                                    in the source library
                                                                </Typography>

                                                        }
                                                    </React.Fragment>}>
                                                    <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.warning}
                                                                      titleAccess={`Show ${material.name} Properties`}
                                                                      hasHover={false}
                                                                      secondClass={'smaller'}/>
                                                </Tooltip>
                                            }
                                        </TableCell>
                                        <TableCell align={'right'}>
                                            {
                                                current_project &&
                                                (current_project.owner || current_project.edit) &&
                                                <IconsFontAwesome id={'updatematerial'}
                                                                  iconType={FONT_AWESOME_ICONS_TYPE_MAP.properties}
                                                                  titleAccess={`Show ${material.name} Properties`}
                                                                  secondClass={'smaller'}
                                                                  onClickHandler={(event) => showUpdateProjectMaterialModal(event, material)}/>
                                            }
                                            {
                                                !material.used &&
                                                <IconsFontAwesome id={'deletematerial'}
                                                                  iconType={FONT_AWESOME_ICONS_TYPE_MAP.delete}
                                                                  titleAccess={`Delete ${material.name}`}
                                                                  secondClass={'smaller'}
                                                                  onClickHandler={() => {
                                                                      confirmAlert({
                                                                          title: `Delete ${material.name}?`,
                                                                          message: 'Are you sure?',
                                                                          buttons: [
                                                                              {
                                                                                  label: 'Delete',
                                                                                  onClick: () => {
                                                                                      deleteProjectMaterial({
                                                                                          project: current_project,
                                                                                          material,
                                                                                      });
                                                                                  },
                                                                              },
                                                                              {
                                                                                  label: 'Cancel',
                                                                                  onClick: () => {
                                                                                  }
                                                                              },
                                                                          ]
                                                                      });
                                                                  }}/>
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Typography>
    );
};

MaterialsComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    current_project: PropTypes.object,
    showModal: PropTypes.func,
    materials: PropTypes.array,
};

const mapStateToProps = (state) => ({
    materials: getProjectMaterials(state),
});

const mapDispatchToProps = {
    showModal, deleteProjectMaterial
};

export default connect(mapStateToProps, mapDispatchToProps)(MaterialsComponent);