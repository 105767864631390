/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Node from 'react-ui-sortable-tree/dist/node';
import Tree from 'react-ui-sortable-tree/dist/tree';
import './Tree.scss';

const initialDragging = {
    id: null,
    x: null,
    y: null,
    w: null,
    h: null,
};


export default class ReactTree extends Component {

    constructor(props) {
        super(props);

        this.state = this.init(this.props);

        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.getDraggingDom = this.getDraggingDom.bind(this);
        this.dragStart = this.dragStart.bind(this);
        this.dragEnd = this.dragEnd.bind(this);
        this.drag = this.drag.bind(this);
        this.change = this.change.bind(this);
    }


    componentWillReceiveProps(nextProps, prevProps) {
        this.setState(this.init(nextProps));
    }

    init(props) {
        const tree = new Tree(props.tree);
        tree.isNodeCollapsed = props.isNodeCollapsed;
        tree.renderNode = props.renderNode;
        tree.changeNodeCollapsed = props.changeNodeCollapsed;
        tree.updateNodesPosition();

        return {
            tree: tree,
            dragging: {...initialDragging},
        };
    }

    getDraggingDom() {
        const {tree, dragging} = this.state;

        if (dragging && dragging.id) {
            const draggingIndex = tree.getIndex(dragging.id);
            const draggingStyles = {
                top: dragging.y,
                left: dragging.x,
                width: dragging.w,
            };

            return React.createElement(
                'div',
                {className: 'm-draggable', style: draggingStyles},
                React.createElement(Node, {
                    tree,
                    index: draggingIndex,
                    paddingLeft: this.props.paddingLeft,
                }),
            );
        }

        if (this.props.loadTreeCallBack) {
            this.props.loadTreeCallBack({loadTree: false});
        }

        return null;
    }

    dragStart(id, dom, e) {
        const treeEl = dom.closest('.m-tree');
        const treeElContainer = treeEl.closest('.m-tree-container') || dom.closest('body');
        const scrollTop = treeElContainer.scrollTop;

        this.dragging = {
            id: id,
            w: dom.offsetWidth,
            h: dom.offsetHeight,
            x: dom.offsetLeft,
            y: dom.offsetTop,
            treeEl: treeEl,
            treeElContainer: treeElContainer,
        };

        this._startX = dom.offsetLeft;
        this._startY = dom.offsetTop;
        this._startScrollTop = scrollTop;
        this._offsetX = e.clientX;
        this._offsetY = e.clientY;
        this._start = true;
        this._changed = false;

        const tree = this.state.tree;
        tree.onDragStart();

        if (this.props.loadTreeCallBack) {
            this.props.loadTreeCallBack({loadTree: false});
        }

        this.props.setIsDragging(true);

        window.addEventListener('mousemove', this.drag);
        window.addEventListener('mouseup', this.dragEnd);
    }

    // oh
    drag(e) {
        if (this._start) {
            this.setState({
                dragging: this.dragging,
            });
            this._start = false;
        }

        const tree = this.state.tree;
        const dragging = this.dragging;
        const paddingLeft = this.props.paddingLeft;
        const scrollTop = dragging.treeElContainer.scrollTop;

        let newIndex = null;
        let index = tree.getIndex(dragging.id);

        if (!index) {
            //todo: see Bug #1
            return;
        }

        const {_startX, _startY, _offsetX, _offsetY, _startScrollTop} = this;

        dragging.x = _startX + e.clientX - _offsetX;
        dragging.y = _startY + e.clientY - _offsetY + (scrollTop - _startScrollTop);
        dragging.paddingLeft = paddingLeft;

        newIndex = tree.moveIndex(index, dragging, e, this.props.canMoveNode, this.props.canMoveToCollapaed || false);

        if (newIndex) {
            dragging.id = newIndex.id;
            this._changed = true;
        } else {
            e.preventDefault();
        }

        this.setState({
            tree,
            dragging,
        });
    }

    dragEnd() {
        this.dragging = {...initialDragging};
        this.setState({
            dragging: this.dragging,
        });

        const tree = this.state.tree;
        tree.onDragEnd();

        if (this._changed) {
            this.change(tree);
        }

        this.props.setIsDragging(false);

        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.dragEnd);
    }

    change(tree) {
        this.props.onChange && this.props.onChange(tree.obj);
    }

    toggleCollapse(nodeId) {
        const tree = this.state.tree;
        const index = tree.getIndex(nodeId);
        const node = index.node;
        node.collapsed = !node.collapsed;
        tree.updateNodesPosition();

        this.props.onToggleCollapseNode && this.props.onToggleCollapseNode(node);

        this.setState({tree});
    }


    render() {
        const {tree, dragging} = this.state;

        return React.createElement(
            'div',
            {className: 'm-tree'},
            this.getDraggingDom(),
            React.createElement(Node, {
                tree,
                index: tree.getIndex(1),
                key: 1,
                paddingLeft: this.props.paddingLeft,
                onDragStart: this.dragStart,
                onCollapse: this.toggleCollapse,
                dragging: dragging && dragging.id,
            }),
        );
    }
}

ReactTree.propTypes = {
    tree: PropTypes.object.isRequired,
    paddingLeft: PropTypes.number,
    renderNode: PropTypes.func.isRequired,
};
