/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {createAction} from 'redux-actions';

function generateId() {
    var width   = 6;
    var number  = Math.round( ( Math.pow( 10, width ) - 1) * Math.random() );
    width -= number.toString().length;
    return  number;
};

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';


export const showModal = createAction(
    SHOW_MODAL,
    payload => ({
        id: generateId(),
        ...payload
    })
);

export const hideModal = createAction(HIDE_MODAL);

