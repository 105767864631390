/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React from 'react';
import {getData as getProject, getProjectMaterials} from "../../../+store/reducer/project";
import {getValidationErrors} from "../../../+store/reducer/validation_errors";
import {getData as getTree, getSelectedObject} from "../../../+store/reducer/tree";
import {
    setSelectedObject, updateSelectedObject
} from "../../../+store/actions/actions";
import {connect} from "react-redux";
import {withSettingsTemplate} from "./SettingsTemplate";
import {
    createProjectCylinder,
    deleteProjectCylinder, updateProjectCylinder,
    updateProjectCylinderAndTree
} from "../../../+store/actions/cylinder";
import {FormControl, FormGroup, FormHelperText, InputLabel, MenuItem, Select} from "@mui/material";
import {InputFields, SettingsSubtitle, SettingsTabPlane} from "../../../../components/BasicSettings";
import HeatTransferCoeff from "../../../../components/HeatTranserCoeff";
import BasicTabs from "../../../../components/BasicTabs";
import {BindToAmbientCheckbox, HoleCheckbox, renderMaterialListSelectOptions} from "./commonComponents";
import {message_floating_point} from "../../../utils";
import PropTypes from "prop-types";

const CylinderSettingsTemplate = ({
                                      // passed from SettingsTemplate
                                      boxHasChange,
                                      handleRevert,
                                      handleCancel,
                                      handleSubmit,
                                      handleChange,
                                      box,
                                      state,
                                      // directly from store
                                      validation_errors,
                                      materials
                                  }) => {
    const tabs = [
        {
            label: 'Spatial',
            Component: <FormGroup style={{height: '100%'}}>
                <SettingsTabPlane
                    hasChange={boxHasChange()}
                    onRevert={box.id !== 'creating' ? handleRevert : handleCancel}
                    onSave={handleSubmit}
                    object={box}>
                    <InputFields
                        field_names={['name']}
                        state={state}
                        ids={'cylindername'}
                        validation_errors={validation_errors}
                        handleChange={handleChange}/>
                    <InputFields
                        groupTitle={'Location'}
                        field_names={['x', 'y', 'z']}
                        units={'mm'}
                        state={state}
                        ids={'cylinderlocationid'}
                        validation_errors={validation_errors}
                        handleChange={handleChange}/>
                    <InputFields
                        groupTitle={'Dimension'}
                        field_names={['dx', 'dy', 'dz']}
                        units={'mm'}
                        state={state}
                        ids={'cylinderdimensionid'}
                        validation_errors={validation_errors}
                        handleChange={handleChange}/>
                    <FormControl variant={'standard'} style={{width: '32.5%'}}>
                        <InputLabel error={!!validation_errors['plane']}
                                    id={'planelabel'}>
                            Plane
                        </InputLabel>
                        <Select
                            id={'planeid'}
                            labelId={'planelabel'}
                            value={state.plane || ''}
                            onChange={(event) => handleChange('plane', event.target.value)}
                            label={'Plane'}
                        >
                            <MenuItem key={'XY'} value={'XY'}>XY</MenuItem>
                            <MenuItem key={'XZ'} value={'XZ'}>XZ</MenuItem>
                            <MenuItem key={'YZ'} value={'YZ'}>YZ</MenuItem>
                        </Select>
                        {validation_errors['plane'] &&
                            <FormHelperText>{validation_errors['plane']}</FormHelperText>
                        }
                    </FormControl>
                    <SettingsSubtitle title="Solid Properties"/>
                    <HoleCheckbox state={state} handleChange={handleChange}/>
                </SettingsTabPlane>
            </FormGroup>
        },
        {
            label: 'Thermal',
            Component: <FormGroup style={{height: '100%'}}>
                <SettingsTabPlane hasChange={boxHasChange()}
                                  onRevert={box.id !== 'creating' ? handleRevert : handleCancel}
                                  onSave={handleSubmit}
                                  object={box}>
                    <FormControl variant={'standard'} fullWidth={true}>
                        <InputLabel error={!!validation_errors['material']}
                                    id={'materiallabel'}>
                            Material
                        </InputLabel>
                        <Select
                            labelId={'materiallabel'}
                            id={'cylindermaterial'}
                            value={state.material || ''}
                            onChange={(event) => handleChange('material', event.target.value)}
                            label={'Material'}
                        >
                            {renderMaterialListSelectOptions(materials)}
                        </Select>
                        {validation_errors['material'] &&
                            <FormHelperText>{validation_errors['material']}</FormHelperText>
                        }
                    </FormControl>
                    <InputFields
                        field_names={['power']}
                        state={state}
                        ids={'cylinderpowerid'}
                        units={'mW'}
                        validation_errors={validation_errors}
                        handleChange={handleChange}/>
                    <HeatTransferCoeff
                        fullWidth
                        variant={'standard'}
                        validators={[]}
                        value={state.hc || ''}
                        onChange={(event) => handleChange('hc', event.target.value)}
                        error={!!validation_errors['hc']}
                        helperText={validation_errors['hc']}
                        errorMessages={[message_floating_point]}
                    />
                    <BindToAmbientCheckbox handleChange={handleChange} state={state}/>
                </SettingsTabPlane>
            </FormGroup>
        }
    ];

    return <BasicTabs tabs={tabs} title={'Properties'} isSwitch={true}/>
}

CylinderSettingsTemplate.propTypes = {
    boxHasChange: PropTypes.func.isRequired,
    handleRevert: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    box: PropTypes.object,
    state: PropTypes.object.isRequired,
    validation_errors:  PropTypes.object,
    materials: PropTypes.array
};

const mapStateToProps = state => ({
    materials: getProjectMaterials(state) || [],
    validation_errors: getValidationErrors(state),
    project_id: getProject(state).id,
    tree: getTree(state),
    box: getSelectedObject(state),
});

const mapDispatchToProps = {
    createProjectObject: createProjectCylinder,
    updateProjectObject: updateProjectCylinder,
    updateProjectObjectAndTree: updateProjectCylinderAndTree,
    deleteProjectObject: deleteProjectCylinder,
    setSelectedObject,
    updateSelectedObject
};

export default connect(mapStateToProps, mapDispatchToProps)(withSettingsTemplate(CylinderSettingsTemplate));