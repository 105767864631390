/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {
    CLONE_PROJECT_SUCCESS,
    CREATE_PROJECT_JSON_SUCCESS,
    CREATE_PROJECT_SUCCESS,
    DELETE_PROJECT_SUCCESS,
    LOAD_TASKS,
    LOAD_TASKS_ERROR,
    LOAD_TASKS_SUCCESS,
    SET_PROJECT_VALIDATION_ERRORS,
    UPDATE_PROJECT_SUCCESS,
    SET_ACTIVE_FOLDER,
    UPDATE_TREE_DATA,
    LOAD_FOLDER_DATA,
    LOAD_FOLDER_DATA_SUCCESS,
    CREATE_FOLDER,
    CREATE_FOLDER_SUCCESS,
    UPDATE_FOLDER,
    UPDATE_FOLDER_SUCCESS,
    DELETE_FOLDER,
    DELETE_FOLDER_SUCCESS,
    PROJECT_CARD_DRAG_START,
    SET_DRAGGED_PROJECT_NEXT_PARENT,
    PROJECT_CARD_DRAG_END,
    LOAD_ACTIVE_FOLDER_PROJECTS,
    LOAD_ACTIVE_FOLDER_PROJECTS_SUCCESS,
    LOAD_SHARED_FOLDER_PROJECTS,
    LOAD_SHARED_FOLDER_PROJECTS_SUCCESS,
    LOAD_ROOT_FOLDER_PROJECTS,
    LOAD_ROOT_FOLDER_PROJECTS_SUCCESS,
    CREATE_PROJECT_JSON,
    CREATE_PROJECT_JSON_ERROR,
    CLEAR_TASKS_SUCCESS,
    CLEAR_TASKS,
    CLEAR_TASKS_ERROR,
    RESET_STATE,
} from './actions';
import {MAIN_FOLDER_NAMES} from '../core/constants/PROJECTS_TREE';
import {dashboardUtils} from '../core/dashboardUtils';

const initialProjectCardDragState = {
    draggedProject: null,
    newParentId: 0,
};

export const defaultState = {
    data: null,
    treeData: [
        {
            id: null,
            name: MAIN_FOLDER_NAMES.myProjects,
            root: true,
            rootType: MAIN_FOLDER_NAMES.myProjects,
            isDirectory: false,
            expanded: true,
            children: [],
        },
    ],
    tasks: [],
    loading: false,
    projectCardsLoading: false,
    importing: false,
    validation_errors: {},
    activeFolder: {path: [0], node: {id: null, name: 'My Projects'}},
    responseFolder: null,
    projectCardDragState: initialProjectCardDragState,
};

export const reducer = (state = defaultState, action) => {
        switch (action.type) {
            case CREATE_PROJECT_SUCCESS:
                return {
                    ...state, data: state.data.concat(action.payload), validation_errors: {},
                };
            case CREATE_PROJECT_JSON:
                return {
                    ...state,
                    importing: true,
                };
            case CREATE_PROJECT_JSON_SUCCESS:
                return {
                    ...state, data: state.data.concat(action.payload), validation_errors: {},
                    importing: false,
                };
            case CREATE_PROJECT_JSON_ERROR:
                return {
                    ...state,
                    importing: false,
                };
            case UPDATE_PROJECT_SUCCESS:
                if (!state.data) {
                    return {
                        ...state, validation_errors: {},
                    };
                }

                return {
                    ...state,
                    data: state.data
                        .map(item => {
                            if (action.payload.id === item.id) {
                                return action.payload;
                            }
                            return item;
                        }).filter(({folder}) => folder === state.activeFolder.node.id),
                    validation_errors: {},
                    projectCardDragState: initialProjectCardDragState,
                };
            case SET_PROJECT_VALIDATION_ERRORS:
                return {
                    ...state, validation_errors: action.payload,
                };
            case DELETE_PROJECT_SUCCESS:
                return {
                    ...state, data: state.data.filter(item => item.id !== action.payload.projectid),
                };
            case CLONE_PROJECT_SUCCESS:
                return {
                    ...state,
                    data: state.activeFolder.path !== null ? state.data.concat(action.payload) : state.data,
                    validation_errors: {},
                };
            case LOAD_TASKS:
            case CLEAR_TASKS:
                return {
                    ...state, loading: true,
                };
            case LOAD_TASKS_SUCCESS:
            case CLEAR_TASKS_SUCCESS:
                return {
                    ...state, loading: false, tasks: action.payload,
                };
            case LOAD_TASKS_ERROR:
            case CLEAR_TASKS_ERROR:
                return {
                    ...state, tasks: [], loading: false,
                };
            case SET_ACTIVE_FOLDER:
                return {
                    ...state, activeFolder: action.payload,
                };
            case UPDATE_TREE_DATA:
                const [myProjectsFolder] = action.payload.treeData;
                const myProjectsFolderChildrens = !action.payload.hasOwnProperty('sort')
                    ? dashboardUtils.sortAlphabeticallyTreeData({data: myProjectsFolder.children})
                    : myProjectsFolder.children;

                return {
                    ...state,
                    [action.payload.treeType]: [
                        {
                            ...myProjectsFolder,
                            children: myProjectsFolderChildrens,
                        },
                    ],
                    responseFolder: null,
                };
            case LOAD_FOLDER_DATA:
                return {
                    ...state, loading: true,
                };
            case LOAD_FOLDER_DATA_SUCCESS:
                const [myProjects] = state.treeData;

                return {
                    ...state,
                    treeData: [
                        {
                            ...myProjects,
                            children: dashboardUtils.generateTreeData(action.payload),
                        },
                    ],
                    loading: false,
                };
            case LOAD_ROOT_FOLDER_PROJECTS:
            case LOAD_ACTIVE_FOLDER_PROJECTS:
            case LOAD_SHARED_FOLDER_PROJECTS:
                return {
                    ...state,
                    data: null,
                    loading: false,
                    projectCardsLoading: true,
                };
            case LOAD_ROOT_FOLDER_PROJECTS_SUCCESS:
            case LOAD_ACTIVE_FOLDER_PROJECTS_SUCCESS:
            case LOAD_SHARED_FOLDER_PROJECTS_SUCCESS:
                return {
                    ...state,
                    data: action.payload,
                    loading: false,
                    projectCardsLoading: false
                };
            case CREATE_FOLDER_SUCCESS:
                return {
                    ...state,
                    responseFolder: action.payload,
                };
            case UPDATE_FOLDER_SUCCESS:
                return {
                    ...state,
                    responseFolder: action.payload,
                };
            case DELETE_FOLDER_SUCCESS:
                return {
                    ...state,
                };
            case PROJECT_CARD_DRAG_START:
                return {
                    ...state,
                    projectCardDragState: {
                        ...state.projectCardDragState,
                        draggedProject: action.payload.project,
                    },
                };
            case PROJECT_CARD_DRAG_END:
                return {
                    ...state,
                    projectCardDragState: initialProjectCardDragState,
                };
            case SET_DRAGGED_PROJECT_NEXT_PARENT:
                return {
                    ...state,
                    projectCardDragState: {
                        ...state.projectCardDragState,
                        newParentId: action.payload,
                    },
                };
            case RESET_STATE:
                return {
                    ...defaultState
                }
            default:
                return state;
        }
    }
;

export const hasData = ({projects}) => !!projects.data;
export const getData = ({projects}) => projects.data;
export const getProjectsTreeData = ({projects}) => projects.treeData;
export const getSharedProjectsTreeData = ({projects}) => projects.sharedTreeData;
export const getActiveFolder = ({projects}) => projects.activeFolder;
export const getFolderContextMenu = ({projects}) => projects.contextMenu;
export const getResponseFolder = ({projects}) => projects.responseFolder;
export const getDraggedProject = ({projects}) => projects.projectCardDragState.draggedProject;
export const getProjectCartNextParentId = ({projects}) => projects.projectCardDragState.newParentId;
export const isLoading = ({projects}) => projects.loading;
export const isImporting = ({projects}) => projects.importing;
export const getValidationErrors = ({projects}) => projects.validation_errors;
export const getTasks = ({projects}) => projects.tasks;
