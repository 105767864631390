/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */


import React, {Component, Fragment} from "react";
import PropTypes from 'prop-types';
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import {compose} from "redux";
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import {Checkbox, FormControlLabel, TableCell, TextField} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import MyValidationForm from "../../components/MyValidationForm";
import FormControl from "@mui/material/FormControl";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Typography from "@mui/material/Typography";
import {loadLibraryPerms, updateLibraryPerms} from "../actions";
import {getLibraryPerms} from "../reducer";

const styles = (theme) => ({
    username: {
        width: '50ch'
    },
});

class Permissions extends Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.setPerms = this.setPerms.bind(this);
        this.addUser = this.addUser.bind(this);
        this.deletePermission = this.deletePermission.bind(this);

        const {library} = this.props;

        this.props.loadLibraryPerms({library});

        this.state = {
            new_user: {
                username: '',
                permissions: {
                    owner: false,
                    editor: false,
                    viewer: false,
                }
            },
            permissions: [],
        }
    }

    handleSubmit() {
        const {library} = this.props;
        const {permissions} = this.state;

        this.props.updateLibraryPerms({
            library,
            permissions,
        });
    }

    addUser() {
        this.setState({
            new_user: {
                username: '',
                permissions: {
                    owner: false,
                    editor: false,
                    viewer: false,
                }
            },
            permissions: this.state.permissions.concat(this.state.new_user)
        })
    }

    deletePermission(index) {
        this.setState({
            ...this.state,
            permissions: this.state.permissions.filter((item, item_index) => item_index !== index)
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.permissions != prevProps.permissions) {
            this.setState({permissions: this.props.permissions});
        }
    }

    setPerms(username, perms) {
        this.setState({
            permissions: this.state.permissions.map(x => {
                return x.username == username ? {
                    username: x.username,
                    permissions: {
                        ...x.permissions,
                        ...perms
                    }
                } : x;
            })
        });
    }

    render() {
        const perms = ['owner', 'editor', 'viewer'];
        const {classes} = this.props;
        return (
            <Fragment>
                <DialogContent dividers>
                    <MyValidationForm>
                        <TextField
                            className={classes.username}
                            label={"Username"}
                            value={this.state.new_user.username}
                            onChange={(event) => this.setState({
                                new_user: {
                                    ...this.state.new_user,
                                    username: event.target.value
                                }
                            })}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={this.state.new_user.permissions.owner}
                                    checked={this.state.new_user.permissions.owner}
                                    onChange={() => this.setState({
                                        new_user: {
                                            ...this.state.new_user,
                                            permissions: {
                                                ...this.state.new_user.permissions,
                                                owner: !this.state.new_user.permissions.owner
                                            }
                                        }
                                    })}
                                />
                            }
                            label={"Owner"}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={this.state.new_user.permissions.editor}
                                    checked={this.state.new_user.permissions.editor}
                                    onChange={() => this.setState({
                                        new_user: {
                                            ...this.state.new_user,
                                            permissions: {
                                                ...this.state.new_user.permissions,
                                                editor: !this.state.new_user.permissions.editor
                                            }
                                        }
                                    })}
                                />
                            }
                            label={"Editor"}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={this.state.new_user.permissions.viewer}
                                    checked={this.state.new_user.permissions.viewer}
                                    onChange={() => this.setState({
                                        new_user: {
                                            ...this.state.new_user,
                                            permissions: {
                                                ...this.state.new_user.permissions,
                                                viewer: !this.state.new_user.permissions.viewer
                                            }
                                        }
                                    })}
                                />
                            }
                            label={"Viewer"}
                        />
                        <IconButton
                            onClick={() => this.addUser()}
                            color={"secondary"}
                            variant={"contained"}
                            size="large">
                            <AddCircleIcon/>
                        </IconButton>
                    </MyValidationForm>
                    <Typography variant="h6">
                    </Typography>
                    <TableContainer component={Paper} style={{marginTop: 20}}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Username</TableCell>
                                    <TableCell>Owner</TableCell>
                                    <TableCell>Editor</TableCell>
                                    <TableCell>Viewer</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.state &&
                                    this.state.permissions
                                        .sort((a, b) => {
                                            if (a.username.toUpperCase() < b.username.toUpperCase()) return -1;
                                            else if (a.username.toUpperCase() > b.username.toUpperCase()) return 1;
                                            return 0;
                                        })
                                        .map((perm, index) => {
                                            return <TableRow key={perm.username}>
                                                <TableCell>{perm.username}</TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        size={"small"}
                                                        value={perm.permissions.owner}
                                                        checked={perm.permissions.owner}
                                                        onChange={() => this.setPerms(perm.username, {owner: !perm.permissions.owner})}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        size={"small"}
                                                        value={perm.permissions.editor}
                                                        checked={perm.permissions.editor}
                                                        onChange={() => this.setPerms(perm.username, {editor: !perm.permissions.editor})}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        size={"small"}
                                                        value={perm.permissions.viewer}
                                                        checked={perm.permissions.viewer}
                                                        onChange={() => this.setPerms(perm.username, {viewer: !perm.permissions.viewer})}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton size={"small"}
                                                                onClick={event => this.deletePermission(index)}>
                                                        <DeleteIcon fontSize={"small"}/>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={this.state.permissions == this.props.permissions}
                        onClick={this.handleSubmit}
                        variant="contained"
                        color="secondary"
                        autoFocus>
                        Submit
                    </Button>
                </DialogActions>
            </Fragment>
        );
    }
}

Permissions.propTypes = {
    project: PropTypes.object,
}

const mapStateToProps = state => ({
    permissions: getLibraryPerms(state),
});

const mapDispatchToProps = {
    loadLibraryPerms,
    updateLibraryPerms
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(Permissions);
