/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {TextValidator} from 'react-material-ui-form-validator';

import withStyles from '@mui/styles/withStyles';
import {DialogContent, Button, FormControl, DialogActions} from '@mui/material';

import MyValidationForm from '../../../../../components/MyValidationForm';

import {createParameter, updateParameter} from '../../../../+store/actions/parameters';

import {getValidationErrors} from '../../../../+store/reducer/validation_errors';

const styles = (theme) => ({
    root: {
        '& > *': {
            width: '100ch',
        },
    },
    inputs: {
        '& > *': {
            marginBottom: theme.spacing(1),
            marginRight: theme.spacing(2),
            width: 445,
        },
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
});


const ParameterSettingsComponent = ({
                                        classes, validation_errors, project_id, parameter,
                                        createParameter, updateParameter,
                                        ...rest
                                    }) => {
    const form = useRef();
    const [fields, setFields] = useState(parameter || {
        id: undefined,
        name: '',
        description: '',
        value: '',
    });

    function handleChange(name, value) {
        setFields({
            ...fields,
            [name]: value,
        });
    }

    function handleSubmit() {
        form.current.isFormValid(false)
            .then(is_valid => {
                if (is_valid) {
                    parameter
                        ? updateParameter({
                            project_id,
                            parameter: fields,
                        })
                        : createParameter({
                            project_id,
                            parameter: fields,
                        });
                }
            });
    }


    return (
        <>
            <DialogContent dividers>
                <MyValidationForm
                    ref={form}
                    onSubmit={handleSubmit}
                    className={'validation-form-sm'}>
                    <div>
                        <FormControl variant={'standard'} fullWidth={true}>
                            <TextValidator
                                autoFocus
                                id={'parameterDescription'}
                                key={'name'}
                                className={classes.root}
                                label={'Description'}
                                value={fields.description}
                                onChange={(event) => handleChange('description', event.target.value)}
                                variant={'standard'}
                                error={!!validation_errors['description']}
                                helperText={validation_errors['description']}
                                validators={['required']}
                                errorMessages={['Description is required!']}/>
                        </FormControl>
                        <FormControl variant={'standard'}>
                            <TextValidator
                                autoFocus
                                id={'parameterName'}
                                key={'name'}
                                className={classes.inputs}
                                label={'Name'}
                                value={fields.name}
                                onChange={(event) => handleChange('name', event.target.value)}
                                variant={'standard'}
                                error={!!validation_errors['name']}
                                helperText={validation_errors['name']}
                                validators={['required']}
                                errorMessages={['Name is required!']}/>
                        </FormControl>
                        <FormControl variant={'standard'}>
                            <TextValidator
                                autoFocus
                                id={'parameterValue'}
                                key={'value'}
                                className={classes.inputs}
                                label={'Value'}
                                value={fields.value}
                                onChange={(event) => handleChange('value', event.target.value)}
                                variant={'standard'}
                                error={!!validation_errors['value']}
                                helperText={validation_errors['value']}
                                validators={['required', 'matchRegexp:^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)*$']}
                                errorMessages={['Value is required!', 'Enter a valid number!']}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder={'-'}
                                type={'number'}/>
                        </FormControl>
                    </div>
                </MyValidationForm>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit} id={'addParameter'} variant={'contained'} color={'primary'} autoFocus>
                    {parameter ? 'Update' : 'Add'}
                </Button>
            </DialogActions>
        </>
    );
};


ParameterSettingsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  createParameter: PropTypes.func.isRequired,
  parameter: PropTypes.bool.isRequired,
  project_id: PropTypes.number.isRequired,
  updateParameter: PropTypes.func.isRequired,
  validation_errors: PropTypes.object
}

const mapStateToProps = state => ({
    validation_errors: getValidationErrors(state),
});

const mapDispatchToProps = {
    createParameter,
    updateParameter,
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(ParameterSettingsComponent);
