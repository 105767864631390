export const DEFAULT_SETTINGS = (material, settings) => {
    const id = 'creating';
    return {
        box: {
            id,
            name: 'box',
            dx: '1',
            dy: '1',
            dz: '1',
            dx_calc: 1,
            dy_calc: 1,
            dz_calc: 1,
            power: '0',
            sphere: false,
            material: material?.id,
            material_name: material?.name,
            hc: null,
            bound: false,
            hole: false,
            index: 0,
            leaf: true,
            parent: null,
            visible: true,
            active: true,
            type: 'box',
            type_id: 2,
            ...settings,
            x_calc: +settings.x,
            y_calc: +settings.y,
            z_calc: +settings.z,
        },
        heatsink: {
            id,
            name: 'heatsink',
            active: true,
            base_dx: '1',
            base_dy: '1',
            base_dz: '1',
            base_dy_calc: 1,
            base_dx_calc: 1,
            base_dz_calc: 1,
            bound: false,
            fin_axis: 'Y',
            fin_count: '5',
            fin_count_calc: 5,
            fin_height: '5',
            fin_height_calc: 5,
            fin_thickness: '0.5',
            fin_thickness_calc: 0.5,
            hc: null,
            index: 0,
            leaf: true,
            material: material?.id,
            material_name: material?.name,
            parent: null,
            type: 'heatsink',
            type_id: 3,
            visible: true,
            x_calc: +settings.x,
            y_calc: +settings.y,
            z_calc: +settings.z,
            ...settings
        },
        via_array : {
            id,
            active: true,
            composite_material: null,
            diameter: "1000",
            diameter_calc: 1000,
            dx: "1",
            dx_calc: 1,
            dy: "1",
            dy_calc: 1,
            dz: "1",
            dz_calc: 1,
            fill_color: null,
            fill_material: null,
            fill_material_name: null,
            hc: null,
            index: 0,
            leaf: true,
            lumped: false,
            material: material?.id,
            material_name:material?.name,
            name: "via",
            parent: null,
            type: "via_array",
            type_id: 5,
            visible: true,
            xcount: "3",
            xcount_calc: 3,
            xpitch: "1000",
            xpitch_calc: 1000,
            ycount: '3',
            ycount_calc: 3,
            ypitch: '1000',
            ypitch_calc: 1000,
            x_calc: +settings.x,
            y_calc: +settings.y,
            z_calc: +settings.z,
            ...settings
        },
        cylinder: {
            id,
            active: true,
            bound: false,
            dx: "1",
            dx_calc: 1,
            dy: "1",
            dy_calc: 1,
            dz: "1",
            dz_calc: 1,
            hc: null,
            hole: false,
            index: 0,
            leaf: true,
            material: material?.id,
            material_name: material?.name,
            name: "cylinder",
            parent: null,
            plane: "YZ",
            type: "cylinder",
            type_id: 6,
            visible: true,
            ...settings,
            x_calc: +settings.x,
            y_calc: +settings.y,
            z_calc: +settings.z,
        },
        pcb: {
            id,
            active: false,
            auto_via: false,
            dx: "1",
            dx_calc: 1,
            dy: "1",
            dy_calc: 1,
            dz: "1",
            dz_calc: 1,
            hc: null,
            index: 0,
            layers: [],
            leaf: true,
            name: "pcb",
            parent: null,
            type: "pcb",
            type_id: 4,
            visible: true,
            ...settings,
            x_calc: +settings.x,
            y_calc: +settings.y,
            z_calc: +settings.z,
        },
        ball_array: {
            id,
            active: true,
            diameter: "5000",
            diameter_calc: 5000,
            dx: "10",
            dx_calc: 10,
            dy: "10",
            dy_calc: 10,
            dz: "10",
            dz_calc: 10,
            hc: null,
            index: 0,
            leaf: true,
            lumped: false,
            material: material?.id,
            material_name: material?.name,
            name: "bga",
            parent: null,
            pitch: "5000",
            pitch_calc: 5000,
            type: "ball_array",
            type_id: 1,
            visible: true,
            xcount: "3",
            xcount_calc: 3,
            ycount: "3",
            ycount_calc: 3,
            ...settings,
            x_calc: +settings.x,
            y_calc: +settings.y,
            z_calc: +settings.z,
        },
        source: {
            id,
            active: true,
            bound: false,
            color: "#000",
            dx: "100",
            dx_calc: 100,
            dy: "100",
            dy_calc: 100,
            dz: "0",
            dz_calc: 0,
            hc: null,
            index: 0,
            leaf: true,
            name: "source",
            parent: null,
            plane: "XY",
            power: "0",
            power_calc: 0,
            type: "source",
            type_id: -1,
            visible: true,
            ...settings,
            x_calc: +settings.x,
            y_calc: +settings.y,
            z_calc: +settings.z,
        },
        transient_source: {
            id,
            active: true,
            bound: false,
            color: "#000",
            dx: "100",
            dx_calc: 100,
            dy: "100",
            dy_calc: 100,
            dz: "0",
            dz_calc: 0,
            hc: null,
            index: 0,
            leaf: true,
            name: "transient_source",
            parent: null,
            plane: "XY",
            power: "0",
            power_calc: 0,
            powers: [],
            type: "transient_source",
            type_id: -1,
            visible: true,
            ...settings,
            x_calc: +settings.x,
            y_calc: +settings.y,
            z_calc: +settings.z,
        }

    }
}