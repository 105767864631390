/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {getData as getProject, getProjectMaterials} from '../../../+store/reducer/project';
import {getValidationErrors} from '../../../+store/reducer/validation_errors';
import {getData as getTree, getSelectedObject} from '../../../+store/reducer/tree';
import {setSelectedObject, updateSelectedObject} from '../../../+store/actions/actions';
import {connect} from 'react-redux';
import {withSettingsTemplate} from './SettingsTemplate';
import {
    createProjectPCB,
    deleteProjectPCB,
    updateProjectPCB,
    updateProjectPCBAndTree
} from '../../../+store/actions/pcb';
import {
    Box, Button,
    ButtonGroup,
    FormControl,
    FormGroup, FormHelperText,
    IconButton, InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from '@mui/material';
import {
    CollapsableListItem,
    InputFields,
    SettingsSubtitle,
    SettingsTabPlane
} from '../../../../components/BasicSettings';
import HeatTransferCoeff from '../../../../components/HeatTranserCoeff';
import {SwitchToggle} from '../../../../components/SwitchToggle';
import {Add, ArrowDownward, ArrowUpward} from '@mui/icons-material';
import {IconsFontAwesome} from '../../../../components/IconsFontAwesome';
import {FONT_AWESOME_ICONS_TYPE_MAP} from '../../../../core/mappings';
import {confirmAlert} from 'react-confirm-alert';
import {TextValidator} from 'react-material-ui-form-validator';
import {message_floating_point} from '../../../utils';
import {renderMaterialListSelectOptions} from './commonComponents';
import BasicTabs from '../../../../components/BasicTabs';

const PCBSettingsTemplate = ({
                                 // passed from SettingsTemplate
                                 boxHasChange,
                                 handleRevert,
                                 handleCancel,
                                 handleChange,
                                 box,
                                 state,
                                 // specially for pcb
                                 setState,
                                 debounceUpdateSelectedObject,
                                 form,
                                 createProjectObject,
                                 updateProjectObjectAndTree,
                                 project_id,
                                 // directly from store
                                 validation_errors,
                                 materials
                             }) => {
    const computeDzJob = useRef();

    function computeDz() {
        clearTimeout(computeDzJob.current);

        computeDzJob.current = setTimeout(() => {
            let dz = state.layers.reduce((a, b) => a + +b.thickness_calc, 0) / 1e3;
            if (state.dz !== dz.toString())
                setState((prevState) => ({
                    ...prevState,
                    dz: dz.toString(),
                }));
        }, 300);
    }

    function handleLayerChange(index, field_name, value) {
        let a = JSON.parse(JSON.stringify(state.layers));
        a[index][field_name] = value;
        a[index].ratio = a[index].fill_material ? (a[index].ratio || 100) : 100;
        setState((prevState) => {
            const newState = {
                ...prevState,
                layers: a,
            };
            debounceUpdateSelectedObject(newState);
            return newState;
        });
        computeDz();
    }

    function renderAddLayerBtn(isButton) {
        const handleAdd = () => {
            setState((prevState) => ({
                ...prevState,
                layers: prevState.layers.concat({
                    name: '',
                    thickness: 0,
                    material: '',
                }),
                updateLayers: prevState.layers.length
            }));
        };
        return isButton ?
            <Button
                fullWidth variant={'contained'}
                color={'secondary'}
                onClick={handleAdd}>
                <Add size={'small'}/>
            </Button>
            : <IconButton id={'mloaddlayer'} onClick={handleAdd} color={'neutral'} disableRipple>
                <Add size={'small'}/>
            </IconButton>;
    }

    function handleCopyLayer(layer) {
        const {id, name, ...layerWithoutId} = layer;
        setState((prevState) => ({
            ...prevState,
            layers: prevState.layers.concat({
                ...layerWithoutId,
                name: name
            })
        }));
    }

    function handleSubmit(e) {
        e.preventDefault();
        form.current.isFormValid(false)
            .then(is_valid => {
                if (is_valid) {
                    if (box && (box.id !== 'creating')) {
                        if (!boxHasChange()) return;
                        updateProjectObjectAndTree({
                            project_id,
                            box: {
                                ...box,
                                ...state,
                            },
                        });
                    } else {
                        createProjectObject({
                            project_id,
                            box: {
                                ...state,
                            },
                        });
                    }
                } else {
                    // to open array of layers
                    setState((prevState) => ({
                        ...prevState,
                        updateLayers: new Date()
                    }));
                }
            });
    }

    const tabs = [
        {
            label: 'General',
            Component: <FormGroup style={{height: '100%'}}>
                <SettingsTabPlane
                    hasChange={boxHasChange()}
                    onRevert={box.id !== 'creating' ? handleRevert : handleCancel}
                    onSave={handleSubmit}
                    object={box}>
                    <InputFields
                        field_names={['name']}
                        state={state}
                        ids={'mloname'}
                        validation_errors={validation_errors}
                        handleChange={handleChange}/>
                    <InputFields
                        groupTitle={'Location'}
                        field_names={['x', 'y', 'z']}
                        units={'mm'}
                        state={state}
                        ids={'mlolocationid'}
                        validation_errors={validation_errors}
                        handleChange={handleChange}/>
                    <InputFields
                        inline
                        disabled={['dz']}
                        groupTitle={'Dimension'}
                        field_names={['dx', 'dy', 'dz']}
                        units={'mm'}
                        state={state}
                        ids={'mlodimensionid'}
                        validation_errors={validation_errors}
                        handleChange={handleChange}/>
                    <SettingsSubtitle title="Thermal" sx={{marginTop: 0}}/>
                    <HeatTransferCoeff
                        fullWidth
                        variant={'standard'}
                        validators={[]}
                        value={state.hc || ''}
                        onChange={(event) => handleChange('hc', event.target.value)}
                        error={!!validation_errors['hc']}
                        helperText={validation_errors['hc']}
                        errorMessages={[message_floating_point]}
                    />
                    <SettingsSubtitle title="Via Properties"/>
                    <FormControl fullWidth>
                        <SwitchToggle
                            booleans={true}
                            onChange={() => handleChange('auto_via', !state.auto_via)}
                            checked={state.auto_via}
                            title={'Automatic vias'}
                            orientation={'vertical'}
                        />
                    </FormControl>

                </SettingsTabPlane>
            </FormGroup>
        },
        {
            label: 'Layers',
            Component: <FormGroup style={{height: '100%'}}>
                <SettingsTabPlane
                    hasChange={boxHasChange()}
                    onRevert={box.id !== 'creating' ? handleRevert : handleCancel}
                    onSave={handleSubmit}
                    object={box}>
                    <Box sx={{display: 'flex', flexWrap: 'noWrap'}}>
                        <InputFields
                            inline
                            disabled
                            field_names={['layers_number', 'total_thickness']}
                            units={{total_thickness: 'um'}}
                            state={{
                                layers_number: state.layers.length,
                                total_thickness: state.layers.reduce((sum, l) => {
                                    if (isNaN(l.thickness)) {
                                        return '-';
                                    }
                                    if (sum !== '-') {
                                        sum += +l.thickness;
                                    }
                                    return sum;
                                }, 0)
                            }}
                        />
                        {renderAddLayerBtn()}
                    </Box>
                    {(state.layers).map((item, index) => (
                        <CollapsableListItem
                            key={index}
                            shouldUpdate={state.updateLayers}
                            formErrors={form.current?.errors}
                            hasErrors={(validation_errors.layers && validation_errors.layers[index])}
                            idx={index}
                            openColor={'background.primary'}
                            closedColor={'secondary.main'}
                            headerBoxSx={{
                                borderRadius: '4px',
                                cursor: 'pointer',
                            }}
                            header={
                                <Box sx={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    flex: 1,
                                }}>
                                    <ButtonGroup>
                                        <IconButton
                                            sx={{p: '5px'}}
                                            disabled={index === 0}
                                            onClick={(e) => {
                                                let x = [].concat(
                                                    state.layers.slice(0, index - 1),
                                                    [state.layers[index]],
                                                    [state.layers[index - 1]],
                                                    state.layers.slice(index + 1)
                                                );
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    layers: x
                                                }));
                                                e.stopPropagation();
                                            }}
                                            title={'Move up'}
                                        >
                                            <ArrowUpward fontSize={'small'}/>
                                        </IconButton>
                                        <IconButton
                                            sx={{p: '5px'}}
                                            disabled={index === state.layers.length - 1}
                                            onClick={(e) => {
                                                let x = [].concat(
                                                    state.layers.slice(0, index),
                                                    [state.layers[index + 1]],
                                                    [state.layers[index]],
                                                    state.layers.slice(index + 2)
                                                );
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    layers: x
                                                }));
                                                e.stopPropagation();
                                            }}
                                            title={'Move down'}
                                        >
                                            <ArrowDownward fontSize={'small'}/>
                                        </IconButton>
                                    </ButtonGroup>
                                    <Box sx={{
                                        display: 'flex',
                                        flex: 1,
                                        width: '100%',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}>
                                        <Typography sx={{width: '100%'}}>{state.layers[index].name}</Typography>
                                        <Box sx={{'& > *': {margin: '5px'}, display: 'flex', flexWrap: 'nowrap'}}>
                                            <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.delete}
                                                              secondClass={'smaller'}
                                                              onClickHandler={(e) => {
                                                                  confirmAlert({
                                                                      title: `Deleting ${state.layers[index].name}`,
                                                                      message: 'Do you wish to proceed?',
                                                                      buttons: [{
                                                                          label: 'Delete', onClick: () => {
                                                                              setState((prevState) => ({
                                                                                  ...prevState,
                                                                                  layers: state.layers.filter((item, idx) => {
                                                                                      return idx !== index;
                                                                                  }),
                                                                              }));
                                                                          },
                                                                      }, {
                                                                          label: 'Cancel', onClick: () => {
                                                                          },
                                                                      },],
                                                                  });
                                                                  e.stopPropagation();
                                                              }}/>

                                            <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.clone}
                                                              secondClass={'smaller'}
                                                              onClickHandler={(e) => {
                                                                  handleCopyLayer(state.layers[index]);
                                                                  e.stopPropagation();
                                                              }}/>
                                        </Box>
                                    </Box>
                                </Box>
                            }>
                            <FormGroup row key={index} sx={{
                                backgroundColor: 'background.primary',
                                padding: '8px',
                                marginTop: '-4px',
                                borderBottomRightRadius: '4px',
                                borderBottomLeftRadius: '4px'
                            }}>
                                <InputFields
                                    idx={index}
                                    field_names={['name']}
                                    state={state.layers[index]}
                                    ids={'mlolayername'}
                                    validation_errors={(validation_errors.layers && validation_errors.layers[index])}
                                    handleChange={(prop_name, event) => handleLayerChange(index, prop_name, event)}/>
                                <FormControl fullWidth variant={'standard'} required>
                                    <InputLabel
                                        error={!!(validation_errors.layers && validation_errors.layers[index] && validation_errors.layers[index]['material'])}
                                        id={'material'}>
                                        Primary Material
                                    </InputLabel>
                                    <Select
                                        required
                                        idx={index}
                                        labelId={'material'}
                                        id={'mlolayermaterial'}
                                        value={item.material || ''}
                                        onChange={(event) => handleLayerChange(index, 'material', event.target.value)}
                                    >
                                        {renderMaterialListSelectOptions(materials)}
                                    </Select>
                                    {validation_errors.layers && validation_errors.layers[index] && validation_errors.layers[index]['material'] &&
                                        <FormHelperText>{validation_errors.layers[index]['material']}</FormHelperText>
                                    }
                                </FormControl>
                                <FormControl fullWidth variant={'standard'}>
                                    <InputLabel
                                        error={!!validation_errors.layers && validation_errors.layers[index] && validation_errors.layers[index]['fill_material']}
                                        id={'fill_material'} shrink={true}>
                                        Secondary Material
                                    </InputLabel>
                                    <Select
                                        idx={index}
                                        labelId={'fill_material'}
                                        id={'mlolayersecondarymaterial'}
                                        value={item.fill_material || ''}
                                        onChange={(event) => handleLayerChange(index, 'fill_material', event.target.value)}
                                    >
                                        <MenuItem value={null}>-</MenuItem>
                                        {renderMaterialListSelectOptions(materials)}
                                    </Select>
                                    {validation_errors.layers && validation_errors.layers[index] && validation_errors.layers[index]['fill_material'] &&
                                        <FormHelperText>{validation_errors.layers[index]['fill_material']}</FormHelperText>
                                    }
                                </FormControl>

                                <Box sx={{
                                    display: 'flex',
                                    flex: 1,
                                    '& > div': {
                                        width: '100%',
                                        marginRight: 0
                                    },
                                    gap: (theme) => theme.spacing(1),
                                }}>
                                    <InputFields
                                        inline
                                        idx={index}
                                        field_names={['thickness']}
                                        state={state.layers[index]}
                                        units={'um'}
                                        ids={'thickness&priamrymaterialratio'}
                                        validation_errors={(validation_errors.layers && validation_errors.layers[index])}
                                        handleChange={(prop_name, event) => handleLayerChange(index, prop_name, event)}
                                        sxFormBox={{border: '1px solid transparent'}}/>
                                    <Box sx={{
                                        '& > div': {
                                            '& > div': {
                                                marginRight: '0 !important',
                                                width: '100%',
                                                boxSizing: 'border-box',
                                                border: '1px solid transparent'
                                            }
                                        }
                                    }}>
                                        <TextValidator
                                            id={'mlolayerratio'}
                                            label={'Primary Material Ratio'}
                                            disabled={!item.fill_material}
                                            InputProps={{
                                                sx: {
                                                    root: {
                                                        width: '100%',
                                                        marginTop: 0,
                                                    }
                                                },
                                                endAdornment: (<InputAdornment
                                                    position={'end'}
                                                >
                                                    %
                                                </InputAdornment>)
                                            }}
                                            onChange={(event) => handleLayerChange(index, 'ratio', event.target.value)}
                                            value={item.fill_material && state.layers[index].ratio || '100'}
                                            error={!!(validation_errors.layers && validation_errors.layers[index].ratio)}
                                            helperText={validation_errors.layers && validation_errors.layers[index].ratio}
                                            validators={item.fill_material && ['required'] || []}
                                            errorMessages={item.fill_material && ['This field is required!'] || []}
                                        />
                                    </Box>
                                </Box>
                            </FormGroup>
                        </CollapsableListItem>
                    ))}
                    {renderAddLayerBtn(true)}
                </SettingsTabPlane>

            </FormGroup>
        },
    ];

    return <BasicTabs tabs={tabs} title={'Properties'} isSwitch={true}/>;
};

PCBSettingsTemplate.propTypes = {
    boxHasChange: PropTypes.func.isRequired,
    handleRevert: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    debounceUpdateSelectedObject: PropTypes.func.isRequired,
    form: PropTypes.object,
    createProjectObject: PropTypes.func.isRequired,
    updateProjectObjectAndTree: PropTypes.func.isRequired,
    box: PropTypes.object,
    state: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
    validation_errors: PropTypes.object,
    materials: PropTypes.array
};

const mapStateToProps = state => ({
    materials: getProjectMaterials(state) || [],
    validation_errors: getValidationErrors(state),
    project_id: getProject(state).id,
    tree: getTree(state),
    box: getSelectedObject(state),
});

const mapDispatchToProps = {
    createProjectObject: createProjectPCB,
    updateProjectObject: updateProjectPCB,
    updateProjectObjectAndTree: updateProjectPCBAndTree,
    deleteProjectObject: deleteProjectPCB,
    setSelectedObject,
    updateSelectedObject
};

export default connect(mapStateToProps, mapDispatchToProps)(withSettingsTemplate(PCBSettingsTemplate));