/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {confirmAlert} from 'react-confirm-alert';
import {connect} from 'react-redux';
import {compose} from 'redux';

import withStyles from '@mui/styles/withStyles';
import {
    Button,
    Input,
    InputAdornment,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
} from '@mui/material';

import {
    Search as SearchIcon,
} from '@mui/icons-material';

import {showModal} from '../../../../modals/actions';
import {deleteParameter} from '../../../+store/actions/parameters';

import ParameterSettingsComponent
    from '../../../../project/components/tabs/parameters/components/parameter-settings.component';
import Spinner from '../../../../components/Spinner';
import {IconsFontAwesome} from '../../../../components/IconsFontAwesome';
import {FONT_AWESOME_ICONS_TYPE_MAP} from '../../../../core/mappings';

const styles = (theme) => ({
    icon: {
        cursor: 'pointer',
        margin: theme.spacing(1),
    },
    search: {
        margin: theme.spacing(2),
    },
    parametersTab: {
        margin: theme.spacing(2),
    },
});


const ParametersComponent = ({classes, parameters: {data, loading}, current_project, showModal, deleteParameter}) => {
    const [filter, setFilter] = useState('');

    function showSettingsComponent({project_id, parameter = false}) {
        showModal({
            type: 'custom',
            title: `${parameter ? 'Edit' : 'Create'} Parameter`,
            content: (props) => {
                return <ParameterSettingsComponent project_id={project_id} parameter={parameter} {...props}/>;
            },
        });
    }


    return (
        <Typography variant={'h6'} className={classes.parametersTab}>
            {
                current_project &&
                (current_project.owner || current_project.edit) &&
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => showSettingsComponent({project_id: current_project.id})}
                >
                    <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.plus}
                                      secondClass={'smaller'}/>
                    Parameter
                </Button>
            }
            {loading
                ? <Spinner/>
                : <>
                    <Input
                        className={classes.search}
                        fullWidth={true}
                        onChange={(event) => setFilter(event.target.value)}
                        startAdornment={<InputAdornment position={'start'}><SearchIcon/></InputAdornment>}
                        placeholder={'Find parameter'}/>
                    <TableContainer component={Paper} className={classes.tableContainer}>
                        <Table className={classes.table} aria-label={'simple table'} size={'small'}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell align={'right'}>Value</TableCell>
                                    <TableCell align={'right'}/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    data
                                        .filter(parameter => (!filter || parameter.name.toUpperCase().includes(filter.toUpperCase())))
                                        .sort((a, b) => {
                                            if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
                                            if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
                                            return 0;
                                        })
                                        .map((parameter) => (
                                            <TableRow key={parameter.id} hover>
                                                <TableCell component={'th'} scope={'row'}>
                                                    {parameter.name}
                                                </TableCell>
                                                <TableCell>{parameter.description}</TableCell>
                                                <TableCell align={'right'}>
                                                    {parameter.value}
                                                </TableCell>
                                                <TableCell align={'right'}>
                                                    {
                                                        current_project &&
                                                        (current_project.owner || current_project.edit) &&
                                                        <IconsFontAwesome id={'updateParameter'}
                                                                          iconType={FONT_AWESOME_ICONS_TYPE_MAP.properties}
                                                                          titleAccess={`Show ${parameter.name} Properties`}
                                                                          secondClass={'smaller1'}
                                                                          iconsSX={{ml: '8px'}}
                                                                          onClickHandler={() => showSettingsComponent({
                                                                              project_id: current_project.id,
                                                                              parameter,
                                                                          })}/>
                                                    }
                                                    {
                                                        <IconsFontAwesome id={'deleteParameter'}
                                                                          iconType={FONT_AWESOME_ICONS_TYPE_MAP.delete}
                                                                          titleAccess={`Delete ${parameter.name}`}
                                                                          secondClass={'smaller1'}
                                                                          iconsSX={{ml: '8px'}}
                                                                          onClickHandler={() => {
                                                                              confirmAlert({
                                                                                  title: 'Delete material?',
                                                                                  message: 'Are you sure?',
                                                                                  buttons: [
                                                                                      {
                                                                                          label: 'Delete',
                                                                                          onClick: () => {
                                                                                              deleteParameter({
                                                                                                  project_id: current_project.id,
                                                                                                  parameterId: parameter.id,
                                                                                              });
                                                                                          },
                                                                                      },
                                                                                      {
                                                                                          label: 'Cancel',
                                                                                          onClick: () => {},
                                                                                      },
                                                                                  ],
                                                                              });
                                                                          }}/>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            }
        </Typography>
    );
};

ParametersComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    current_project: PropTypes.object,
    deleteParameter: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    parameters: state.current_project.parameters,
});

const mapDispatchToProps = {
    showModal,
    deleteParameter,
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(ParametersComponent);
