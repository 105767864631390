/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {Typography, Box, List} from '@mui/material';

import {getData} from '../../../+store/reducer/project';
import {getData as getTree} from '../../../+store/reducer/tree';

import Spinner from '../../../../components/Spinner';
import ProjectControls from './components/project-controls/ProjectControls';
import BoxTreeSection from './components/sections/box-tree/BoxTreeSection';
import PlanesSection from './components/sections/planes/PlanesSection';
import {PanelDivider} from '../../../../components/BasicSettings';

const AsidePanel = ({current_project, tree, tab}) => {
    const [expandedSection, setExpandedSection] = useState({
        plane: true,
        model: true,
    });

    const handleExpandCollapse = (event, section = 'plane') => {
        setExpandedSection(prevState => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };


    return (
        <>
            <Box sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                mb: '10px',
                height: '28px',
                p: '2px 0 4px 14px',
                position: 'relative',
            }}>
                <Typography variant={'faded'} size={'infoSmall'}>PROJECT</Typography>
            </Box>

            {
                current_project?.id && tree
                    ? <>
                        <ProjectControls/>

                        <PanelDivider/>

                        <Box sx={{ bgcolor: 'background.main'}}>
                            <List
                                sx={{width: '100%'}}
                                component={'nav'}
                                className={'aside-panel'}
                                aria-labelledby={'aside-panel-sections'}
                            >
                                {/*Box Tree Section*/}
                                <BoxTreeSection tab={tab}
                                                current_project={current_project}
                                                expanded={expandedSection.model}
                                                handleExpandCollapse={handleExpandCollapse}/>
                                {!expandedSection.model
                                    ? <PanelDivider/>
                                    : null
                                }

                                {/*Planes Section*/}
                                <PlanesSection current_project={current_project}
                                               expanded={expandedSection.plane}
                                               handleExpandCollapse={handleExpandCollapse}/>
                                {!expandedSection.plane
                                    ? <PanelDivider/>
                                    : null
                                }
                            </List>
                        </Box>
                    </>
                    : <Spinner/>
            }
        </>
    );
};


AsidePanel.propTypes = {
    current_project: PropTypes.object,
    tab: PropTypes.number.isRequired,
    tree: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
    current_project: getData(state),
    tree: getTree(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AsidePanel);
