/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {Component} from 'react';
import {Typography} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import {compose} from 'redux';
import {connect} from 'react-redux';
import Button from '@mui/material/Button';
import {TextValidator} from 'react-material-ui-form-validator';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {activateUser} from '../actions/actions';

import MyValidationForm from '../../components/MyValidationForm';

import logo from '../../logo.svg';
import '../../index.css';
import {setToken} from '../../authentication/actions';

import EULA from '../EULA';

const styles = (theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

export class Activate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: props.match.params.token,
            first_name: '',
            last_name: '',
            password: '',
            show_password: false,
        };
        this.submit = this.submit.bind(this);
        this.props.setToken(null);
    }

    handleChange(field, value) {
        this.setState({
            [field]: value,
        });
    }

    handleClickShowPassword = () => {
        this.setState({
            show_password: !this.state.show_password,
        });
    };

    submit(event) {
        event.preventDefault();
        this.props.activateUser(this.state);
    }

    render() {
        const {classes} = this.props;
        return (
            <Container component="main">
                <CssBaseline/>
                <div className={classes.paper}>
                    <img src={logo} className="App-logo" width={150}/>
                    <Typography component="h1">
                        Account activation
                    </Typography>
                    <Typography component="h6">
                        Please provide the following information
                    </Typography>
                    <MyValidationForm className={classes.form} noValidate onSubmit={this.submit}>
                        <Container maxWidth="xs">
                            <TextValidator
                                type={this.state.show_password ? 'text' : 'password'}
                                onChange={event => this.handleChange('password', event.target.value)}
                                variant="standard"
                                margin="normal"
                                required
                                fullWidth
                                id="password"
                                label="Password"
                                name="password"
                                autoComplete="password"
                                autoFocus
                                value={this.state.password}
                                validators={['required']}
                                errorMessages={['This field is required!']}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position={'end'}>
                                            <IconButton
                                                aria-label={'toggle password visibility'}
                                                onClick={this.handleClickShowPassword}
                                                size="large">
                                                {this.state.show_password ? <Visibility/> : <VisibilityOff/>}
                                            </IconButton>
                                        </InputAdornment>,

                                }}
                            />
                            <TextValidator
                                type="text"
                                onChange={event => this.handleChange('first_name', event.target.value)}
                                variant="standard"
                                margin="normal"
                                required
                                fullWidth
                                id="first_name"
                                label="First name"
                                name="first_name"
                                autoComplete="first_name"
                                value={this.state.first_name}
                                validators={['required']}
                                errorMessages={['This field is required!']}
                            />
                            <TextValidator
                                type="text"
                                onChange={event => this.handleChange('last_name', event.target.value)}
                                variant="standard"
                                margin="normal"
                                required
                                fullWidth
                                id="last_name"
                                label="Last name"
                                name="last_name"
                                autoComplete="first_name"
                                value={this.state.last_name}
                                validators={['required']}
                                errorMessages={['This field is required!']}
                            />
                        </Container>
                        <Container maxWidth="md">
                            <EULA/>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                I AGREE TO BE BOUND BY THE TERMS OF THIS EULA
                            </Button>
                        </Container>
                    </MyValidationForm>
                </div>
            </Container>
        );
    }
}

const mapDispatchToProps = {
    activateUser,
    setToken,
};

export default compose(
    withStyles(styles),
    connect(() => {
    }, mapDispatchToProps),
)(Activate);
