import React, {useEffect, useState, useRef} from 'react';
import { connect } from 'react-redux';

import {IconsFontAwesome} from "./IconsFontAwesome";
import {FONT_AWESOME_ICONS_TYPE_MAP} from "../core/mappings";
import {Alert, Slide, Snackbar} from "@mui/material";

import {
    loadClipFaceSolutionTimePoint_x0, loadClipFaceSolutionTimePoint_x1,
    loadClipFaceSolutionTimePoint_y0, loadClipFaceSolutionTimePoint_y1,
    loadClipFaceSolutionTimePoint_z0, loadClipFaceSolutionTimePoint_z1,
} from '../project/+store/actions/actions';
import { getClipFacesStoredData } from '../project/+store/reducer/plane_solutions';

import { drawTransientSolutionUtils } from '../project/utils';

const PlayTransientLoadSolutions = ({
                                               loadNewSolution,
                                               transient,
                                               projectId,
                                               isSolutionLoading,
                                               currentPlayedSolutionId,
                                               setCurrentPlayedSolutionId,
                                               currentViewPoints,
                                               clipFacesStoredData,
                                               ...rest
                                           }) => {
    const [isPlaying, setIsPlaying] = useState(false)
    const [open, setOpen] = useState(false)
    const generatorRef = useRef();

    useEffect(() => {
        async function* generator() {
            let index = 0;

            while (true) {
                if (currentViewPoints.length <= index) {
                    index = 0;
                }

                setCurrentPlayedSolutionId(currentViewPoints[index].id);
                yield loadNewSolution({project_id: projectId, solution_id: currentViewPoints[index].id});

                index++;
            }
        }

        generatorRef.current = generator();
    }, [currentViewPoints])

    const togglePlayLoadTransientSolutions = () => {
        setIsPlaying((prevState) => !prevState);
        setOpen(true);
    }

    useEffect(() => {
        if (isPlaying && !isSolutionLoading) {
            generatorRef.current.next();
        }
        if (!isPlaying)
            setCurrentPlayedSolutionId(undefined);
    }, [isPlaying, isSolutionLoading])

    useEffect(() => {
        if (isPlaying) {
            if (document.querySelector('.clip-rect'))
                document.querySelector('.clip-rect').style.pointerEvents = 'none'
        }

        return () => {
            if (document.querySelector('.clip-rect'))
                document.querySelector('.clip-rect').style.pointerEvents = 'all'
        }
    }, [isPlaying])

    useEffect(() => {
        if (isPlaying && currentPlayedSolutionId) {
            drawTransientSolutionUtils.drawClipPlanes({
                clipFacesStoredData, currentPlayedSolutionId, loaderFns: rest, projectId
            })
        }
    }, [currentPlayedSolutionId, isPlaying, clipFacesStoredData]);

    return (<>
            <IconsFontAwesome
                iconType={!isPlaying ? FONT_AWESOME_ICONS_TYPE_MAP.play : FONT_AWESOME_ICONS_TYPE_MAP.stop}
                titleAccess={`Play transient solutions loader`}
                secondClass={'smaller'}
                mainSX={{marginLeft: '10px'}}
                hoveredSX={{marginLeft: '10px'}}
                onClickHandler={togglePlayLoadTransientSolutions}/>
            <Snackbar
                open={open}
                autoHideDuration={3000}
                TransitionComponent={Slide}
                onClose={() => setOpen(false)}>
                <Alert variant="filled" severity={'info'}>
                    <div>{isPlaying ? 'Started solutions auto-loader.' : 'Stopped solutions auto-loader.'}</div>
                </Alert>
            </Snackbar>
        </>
    )
}

const mapStateToProps = state => ({
    clipFacesStoredData: getClipFacesStoredData(state),
});

const mapDispatchToProps = {
    loadClipFaceSolutionTimePoint_x0, loadClipFaceSolutionTimePoint_x1,
    loadClipFaceSolutionTimePoint_y0, loadClipFaceSolutionTimePoint_y1,
    loadClipFaceSolutionTimePoint_z0, loadClipFaceSolutionTimePoint_z1,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayTransientLoadSolutions);