/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {TextValidator} from 'react-material-ui-form-validator';

import withStyles from '@mui/styles/withStyles';
import {
    Button,
    DialogContent,
    MenuItem,
    DialogActions,
    InputLabel,
    Select,
    FormControl,
    FormControlLabel,
    Checkbox,
    InputAdornment,
    FormHelperText,
} from '@mui/material';

import {hideModal} from "../../../modals/actions";

import MyValidationForm from '../../../components/MyValidationForm';
import {getValidationErrors} from '../../+store/reducer/validation_errors';

const styles = (theme) => ({
    root: {
        '& > *': {
            marginBottom: theme.spacing(1),
            marginRight: theme.spacing(2),
            // maxWidth: 120,
        },
    },
    selectEmpty: {
        minWidth: 180,
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
});


class TempSettings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            min_t: props.min_t,
            max_t: props.max_t,
            colormap: props.colormap,
            filter: props.filter,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(field_name, value) {
        this.setState({
            [field_name]: value,
        });
    }

    handleSubmit() {
        const {onClose, hideModal} = this.props;
        onClose(this.state);
        hideModal();
    }

    renderInputFields(field_names, ids) {
        const {classes} = this.props;
        return field_names.map((prop_name) => (
            <TextValidator
                id={ids}
                key={prop_name}
                type={'number'}
                step={'any'}
                className={classes.root}
                label={prop_name.toUpperCase()}
                value={this.state[prop_name] || ''}
                onChange={(event) => this.handleChange(prop_name, event.target.value)}
                variant={'standard'}
                validators={['required', 'isCustomFloat']}
                errorMessages={['This field is required!', 'Enter a valid floating point number!']}
                InputProps={{
                    endAdornment: <InputAdornment position={'end'}>&deg;C</InputAdornment>,
                }}
            />
        ));
    }

    render() {
        const {classes, validation_errors} = this.props;

        return (
            <Fragment>
                <DialogContent dividers>
                    <MyValidationForm
                        ref={this.form}
                        onSubmit={this.handleSubmit}
                        className={'validation-form-sm'}>
                        {this.renderInputFields(['min_t', 'max_t'], 'temps')}
                        <FormControl variant={"standard"}>
                            <FormControlLabel
                                id="filter"
                                control={
                                    <Checkbox
                                        checked={this.state.filter}
                                        onChange={(event) => this.handleChange('filter', !this.state.filter)}
                                    />}
                                label={"Do not draw temps outside of this range"}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth={true}>
                            <InputLabel error={!!validation_errors['colormap']}
                                        id={'colorlabel'}>
                                Color scheme
                            </InputLabel>
                            <Select
                                id={'colorid'}
                                labelId={'colorlabel'}
                                className={classes.selectEmpty}
                                value={this.state.colormap}
                                onChange={(event) => this.handleChange('colormap', event.target.value)}
                                label={'Color scheme'}
                            >
                                <MenuItem key={"rainbow"} value={"rainbow"}>Rainbow</MenuItem>
                                <MenuItem key={"blackbody"} value={"blackbody"}>Blackbody</MenuItem>
                                <MenuItem key={"grayscale"} value={"grayscale"}>Grayscale</MenuItem>
                            </Select>
                            {validation_errors['colormap'] &&
                                <FormHelperText>{validation_errors['colormap']}</FormHelperText>
                            }
                        </FormControl>

                    </MyValidationForm>
                </DialogContent>
                <DialogActions>
                    <Button id={'submit'} onClick={this.handleSubmit} variant={'contained'} color={'primary'} autoFocus>
                        Update
                    </Button>
                </DialogActions>
            </Fragment>
        );
    }
}

TempSettings.propTypes = {
    onClose: PropTypes.func.isRequired,
    min_t: PropTypes.number.isRequired,
    max_t: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
    validation_errors: getValidationErrors(state),
});

const mapDispatchToProps = {
    hideModal,
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(TempSettings);
