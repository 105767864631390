/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {createAction} from 'redux-actions';
import {DELETE_PROJECT_BGA} from '../project/+store/actions/bga';

export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SET_TOKEN = 'SET_TOKEN';
export const LOGOUT = 'LOGOUT';

export const login = createAction(
    LOGIN,
    payload => payload,
    body => ({
        request: {
            url: '/api/token/',
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
            },
        },
    }),
);
export const loginError = createAction(LOGIN_ERROR);
export const loginSuccess = createAction(LOGIN_SUCCESS);
export const setToken = createAction(SET_TOKEN);
export const logout = createAction(LOGOUT);

export const USER_INFO = 'USER_INFO';
export const USER_INFO_ERROR = 'USER_INFO_ERROR';
export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';

export const getUserInfo = createAction(
    USER_INFO,
    payload => payload,
    body => ({
        request: {
            url: '/api/user/',
            method: 'GET',
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
            },
        },
    }),
);

export const CLEAR_PASSWORD_RESET = 'CLEAR_PASSWORD_RESET';
export const clearPasswordReset = createAction(CLEAR_PASSWORD_RESET);

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';

export const forgotPassword = payload => ({
    type: FORGOT_PASSWORD,
    payload,
    meta: {
        request: {
            url: '/api/user/forgot_password/',
            method: 'POST',
            body: JSON.stringify(payload),
            mode: 'text',
        },
    },
});

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

export const resetPassword = payload => ({
    type: RESET_PASSWORD,
    payload,
    meta: {
        request: {
            url: '/api/user/reset_password/',
            method: 'POST',
            body: JSON.stringify(payload),
            mode: 'text',
        },
    },
});

export const LICENSE_REQUEST = 'LICENSE_REQUEST';
export const LICENSE_REQUEST_ERROR = 'LICENSE_REQUEST_ERROR';
export const LICENSE_REQUEST_SUCCESS = 'LICENSE_REQUEST_SUCCESS';

export const requestLicense = payload => ({
    type: LICENSE_REQUEST,
    payload,
    meta: {
        request: {
            url: '/api/user/license/',
            method: 'PUT',
            body: JSON.stringify(payload),
        },
    },
});

