/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

export const CREATE_PROJECT = 'CREATE_PROJECT';
export const CREATE_PROJECT_ERROR = 'CREATE_PROJECT_ERROR';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';

export const createProject = payload => ({
    type: 'CREATE_PROJECT',
    payload,
    meta: {
        request: {
            url: '/api/project/',
            method: 'POST',
            body: JSON.stringify(payload),
        },
    },
});


export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const UPDATE_PROJECT_ERROR = 'UPDATE_PROJECT_ERROR';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';

export const updateProject = payload => ({
    type: 'UPDATE_PROJECT',
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.id}/`,
            method: 'PUT',
            body: JSON.stringify(payload),
        },
    },
});


export const UPDATE_PROJECT_AND_TREE = 'UPDATE_PROJECT_AND_TREE';
export const UPDATE_PROJECT_AND_TREE_ERROR = 'UPDATE_PROJECT_AND_TREE_ERROR';
export const UPDATE_PROJECT_AND_TREE_SUCCESS = 'UPDATE_PROJECT_AND_TREE_SUCCESS';

export const updateProjectAndTree = payload => ({
    type: 'UPDATE_PROJECT_AND_TREE',
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.id}/`,
            method: 'PUT',
            body: JSON.stringify(payload),
        },
    },
});


export const DELETE_PROJECT = 'DELETE_PROJECT';
export const DELETE_PROJECT_ERROR = 'DELETE_PROJECT_ERROR';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';

export const deleteProject = payload => ({
    type: 'DELETE_PROJECT',
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.id}/`,
            method: 'DELETE',
        },
    },
});

export const CLONE_PROJECT = 'CLONE_PROJECT';
export const CLONE_PROJECT_ERROR = 'CLONE_PROJECT_ERROR';
export const CLONE_PROJECT_SUCCESS = 'CLONE_PROJECT_SUCCESS';

export const cloneProject = payload => ({
    type: CLONE_PROJECT,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.id}/clone/`,
            method: 'POST',
        },
    },
});


export const SET_PROJECT_VALIDATION_ERRORS = 'SET_PROJECT_VALIDATION_ERRORS';

export const setValidationErrors = payload => ({
    type: 'SET_PROJECT_VALIDATION_ERRORS',
    payload,
});


export const CREATE_PROJECT_JSON = 'CREATE_PROJECT_JSON';
export const CREATE_PROJECT_JSON_ERROR = 'CREATE_PROJECT_JSON_ERROR';
export const CREATE_PROJECT_JSON_SUCCESS = 'CREATE_PROJECT_JSON_SUCCESS';
export const createProjectFromJSONFile = (payload) => ({
    type: CREATE_PROJECT_JSON,
    payload,
    meta: {
        request: {
            url: `/api/project/json/`,
            method: 'POST',
            body: JSON.stringify(payload),
        },
    },
});


export const CREATE_PROJECT_CSV = 'CREATE_PROJECT_CSV';
export const CREATE_PROJECT_CSV_ERROR = 'CREATE_PROJECT_CSV_ERROR';
export const CREATE_PROJECT_CSV_SUCCESS = 'CREATE_PROJECT_CSV_SUCCESS';
export const createProjectFromCSVFile = (payload) => ({
    type: CREATE_PROJECT_CSV,
    payload,
    meta: {
        request: {
            url: `/api/project/csv/`,
            method: 'POST',
            body: payload,
        },
    },
});


export const LOAD_TASKS = 'LOAD_TASKS';
export const LOAD_TASKS_ERROR = 'LOAD_TASKS_ERROR';
export const LOAD_TASKS_SUCCESS = 'LOAD_TASKS_SUCCESS';
export const loadTasks = (payload) => ({
    type: LOAD_TASKS,
    payload,
    meta: {
        request: {
            url: `/api/tasks/?_=` + Date.now(),
        },
    },
});


export const CLEAR_TASKS = 'CLEAR_TASKS';
export const CLEAR_TASKS_ERROR = 'CLEAR_TASKS_ERROR';
export const CLEAR_TASKS_SUCCESS = 'CLEAR_TASKS_SUCCESS';
export const clearOldTasks = (payload) => ({
    type: CLEAR_TASKS,
    payload,
    meta: {
        request: {
            url: `/api/tasks/?_=` + Date.now(),
            method: 'DELETE'
        },
    },
});


export const SET_ACTIVE_FOLDER = 'SET_ACTIVE_FOLDER';
export const setActiveFolderAction = (payload) => ({
    type: SET_ACTIVE_FOLDER,
    payload,
});


export const UPDATE_TREE_DATA = 'UPDATE_TREE_DATA';
export const updateTreeDataAction = (payload) => ({
    type: UPDATE_TREE_DATA,
    payload,
});


export const LOAD_FOLDER_DATA = 'LOAD_FOLDER_DATA';
export const LOAD_FOLDER_DATA_ERROR = 'LOAD_FOLDER_DATA_ERROR';
export const LOAD_FOLDER_DATA_SUCCESS = 'LOAD_FOLDER_DATA_SUCCESS';
export const loadFolderAction = (payload) => ({
    type: LOAD_FOLDER_DATA,
    payload,
    meta: {
        request: {
            url: '/api/folder/',
        },
    },
});

export const LOAD_ROOT_FOLDER_PROJECTS = 'LOAD_ROOT_FOLDER_PROJECTS';
export const LOAD_ROOT_FOLDER_PROJECTS_ERROR = 'LOAD_ROOT_FOLDER_PROJECTS_ERROR';
export const LOAD_ROOT_FOLDER_PROJECTS_SUCCESS = 'LOAD_ROOT_FOLDER_PROJECTS_SUCCESS';
export const loadRootFolderProjectsAction = (payload) => ({
    type: LOAD_ROOT_FOLDER_PROJECTS,
    payload,
    meta: {
        request: {
            url: `/api/folder/project/`,
        },
    },
});


export const LOAD_ACTIVE_FOLDER_PROJECTS = 'LOAD_ACTIVE_FOLDER_PROJECTS';
export const LOAD_ACTIVE_FOLDER_PROJECTS_ERROR = 'LOAD_ACTIVE_FOLDER_PROJECTS_ERROR';
export const LOAD_ACTIVE_FOLDER_PROJECTS_SUCCESS = 'LOAD_ACTIVE_FOLDER_PROJECTS_SUCCESS';
export const loadActiveFolderProjectsAction = (payload) => ({
    type: LOAD_ACTIVE_FOLDER_PROJECTS,
    payload,
    meta: {
        request: {
            url: `/api/folder/${payload.id}/project/`,
        },
    },
});

export const LOAD_SHARED_FOLDER_PROJECTS = 'LOAD_SHARED_FOLDER_PROJECTS';
export const LOAD_SHARED_FOLDER_PROJECTS_ERROR = 'LOAD_SHARED_FOLDER_PROJECTS_ERROR';
export const LOAD_SHARED_FOLDER_PROJECTS_SUCCESS = 'LOAD_SHARED_FOLDER_PROJECTS_SUCCESS';
export const loadSharedFolderProjectsAction = (payload) => ({
    type: LOAD_SHARED_FOLDER_PROJECTS,
    payload,
    meta: {
        request: {
            url: `/api/folder/shared/`,
        },
    },
});


export const CREATE_FOLDER = 'CREATE_FOLDER';
export const CREATE_FOLDER_ERROR = 'CREATE_FOLDER_ERROR';
export const CREATE_FOLDER_SUCCESS = 'CREATE_FOLDER_SUCCESS';
export const createFolderAction = (payload) => ({
    type: CREATE_FOLDER,
    payload,
    meta: {
        request: {
            url: `/api/folder/`,
            method: 'POST',
            body: JSON.stringify(payload),
        },
    },
});


export const UPDATE_FOLDER = 'UPDATE_FOLDER';
export const UPDATE_FOLDER_ERROR = 'UPDATE_FOLDER_ERROR';
export const UPDATE_FOLDER_SUCCESS = 'UPDATE_FOLDER_SUCCESS';
export const updateFolderAction = (payload) => ({
    type: UPDATE_FOLDER,
    payload,
    meta: {
        request: {
            url: `/api/folder/${payload.folderId}/`,
            method: 'PUT',
            body: JSON.stringify(payload.body),
        },
    },
});


export const DELETE_FOLDER = 'DELETE_FOLDER';
export const DELETE_FOLDER_ERROR = 'DELETE_FOLDER_ERROR';
export const DELETE_FOLDER_SUCCESS = 'DELETE_FOLDER_SUCCESS';
export const deleteFolderAction = (payload) => ({
    type: DELETE_FOLDER,
    payload,
    meta: {
        request: {
            url: `/api/folder/${payload.folderId}/`,
            method: 'DELETE',
            body: JSON.stringify(payload.body),
        },
    },
});


export const PROJECT_CARD_DRAG_START = 'PROJECT_CARD_DRAG_START';
export const PROJECT_CARD_DRAG_END = 'PROJECT_CARD_DRAG_END';
export const projectCardDragStart = (payload) => ({
    type: PROJECT_CARD_DRAG_START,
    payload,
});

export const projectCardDragEnd = (payload) => ({
    type: PROJECT_CARD_DRAG_END,
    payload,
});

export const SET_DRAGGED_PROJECT_NEXT_PARENT = 'SET_DRAGGED_PROJECT_NEXT_PARENT';
export const setDraggedProjectNextParent = (payload) => ({
    type: SET_DRAGGED_PROJECT_NEXT_PARENT,
    payload,
});


export const RESET_STATE = 'RESET_STATE';
export const resetState = () => ({
    type: RESET_STATE
});
