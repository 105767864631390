/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React from 'react';
import BasicTabs from '../../../../components/BasicTabs';
import PropTypes from 'prop-types';
import {getData as getProject, getProjectMaterials} from '../../../+store/reducer/project';
import {getValidationErrors} from '../../../+store/reducer/validation_errors';
import {getData as getTree, getSelectedObject} from '../../../+store/reducer/tree';
import {setSelectedObject, updateSelectedObject} from '../../../+store/actions/actions';
import {connect} from 'react-redux';
import {withSettingsTemplate} from './SettingsTemplate';
import {
    createProjectTransientSource,
    deleteProjectTransientSource,
    updateProjectTransientSource,
    updateProjectTransientSourceAndTree,
} from '../../../+store/actions/transient_source';
import {
    Box,
    Button,
    FormControl,
    FormGroup,
    FormHelperText,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    List,
    ListItem,
    Tooltip, Typography
} from '@mui/material';
import {
    InputFields,
    SettingsSubtitle,
    SettingsTabPlane
} from '../../../../components/BasicSettings';
import ColorPicker from '../../../../components/ColorPicker';
import HeatTransferCoeff from '../../../../components/HeatTranserCoeff';
import {SwitchToggle} from '../../../../components/SwitchToggle';
import {Add, InfoOutlined} from '@mui/icons-material';
import {message_floating_point, projectUtils} from '../../../utils';
import {IconsFontAwesome} from '../../../../components/IconsFontAwesome';
import {FONT_AWESOME_ICONS_TYPE_MAP} from '../../../../core/mappings';
import {confirmAlert} from 'react-confirm-alert';

const TransientSourceSettingsTemplate = ({
                                             // passed from SettingsTemplate
                                             boxHasChange,
                                             handleRevert,
                                             handleCancel,
                                             handleChange,
                                             box,
                                             state,
                                             // specially for transient source
                                             setState,
                                             form,
                                             createProjectObject,
                                             updateProjectObjectAndTree,
                                             debounceUpdateSelectedObject,
                                             project_id,
                                             // directly from store
                                             validation_errors,
                                         }) => {
    function handleSubmit(e) {
        e.preventDefault();
        form.current.isFormValid(false)
            .then(is_valid => {
                if (is_valid) {
                    if (box && (box.id !== 'creating')) {
                        if (!boxHasChange()) {
                            return;
                        }
                        updateProjectObjectAndTree({
                            project_id,
                            box: {
                                ...box,
                                ...state,
                                dx: state.plane !== 'YZ' && state.dx || 0,
                                dy: state.plane !== 'XZ' && state.dy || 0,
                                dz: state.plane !== 'XY' && state.dz || 0,
                            },
                        });
                    } else {
                        createProjectObject({
                            project_id,
                            box: {
                                ...state,
                                dx: state.plane !== 'YZ' && state.dx || 0,
                                dy: state.plane !== 'XZ' && state.dy || 0,
                                dz: state.plane !== 'XY' && state.dz || 0,
                            },
                        });
                    }
                }
            });
    }

    const dimensions = projectUtils.sourceDimensions({state});

    function handlePowerLayerChange(index, field_name, value) {
        let a = JSON.parse(JSON.stringify(state.powers));
        a[index][field_name] = value;
        setState((prevState) => {
            const newState = {
                ...prevState,
                powers: a,
            };
            debounceUpdateSelectedObject(newState);
            return newState;
        });
    }

    function handleAdd() {
        setState((prevState) => ({
            ...prevState,
            powers: prevState.powers.concat({
                id: -1,
                time: '0',
                power: '0',
                power_calc: 0,
            }),
        }));
    }

    function handleCopyPower(power) {
        setState((prevState) => ({
            ...prevState,
            powers: prevState.powers.concat(power)
        }));
    }

    const tabs = [
        {
            label: 'Spatial',
            Component: <FormGroup style={{height: '100%'}}>
                <SettingsTabPlane
                    hasChange={boxHasChange()}
                    onRevert={box.id !== 'creating' ? handleRevert : handleCancel}
                    onSave={handleSubmit}
                    object={box}>
                    <InputFields
                        field_names={['name']}
                        state={state}
                        ids={'transientsourcename'}
                        validation_errors={validation_errors}
                        handleChange={handleChange}/>
                    <InputFields
                        groupTitle={'Location'}
                        field_names={['x', 'y', 'z']}
                        units={'mm'}
                        state={state}
                        ids={'transientsourcelocationid'}
                        validation_errors={validation_errors}
                        handleChange={handleChange}/>
                    <InputFields
                        inline
                        groupTitle={'Dimension'}
                        field_names={dimensions}
                        units={'mm'}
                        state={state}
                        ids={'transientsourcedimensionid'}
                        validation_errors={validation_errors}
                        handleChange={handleChange}/>
                    <FormControl variant="standard" sx={{width: '32.5%'}}>
                        <InputLabel error={!!validation_errors['plane']}
                                    id={'planelabel'}>
                            Plane
                        </InputLabel>
                        <Select
                            id="planeid"
                            labelId="planelabel"
                            value={state.plane || 'XY'}
                            onChange={(event) => handleChange('plane', event.target.value)}
                            label={'Plane'}
                        >
                            <MenuItem key={'XY'} value={'XY'}>XY</MenuItem>
                            <MenuItem key={'XZ'} value={'XZ'}>XZ</MenuItem>
                            <MenuItem key={'YZ'} value={'YZ'}>YZ</MenuItem>
                        </Select>
                        {validation_errors['plane'] &&
                            <FormHelperText>{validation_errors['plane']}</FormHelperText>
                        }
                    </FormControl>

                    <SettingsSubtitle title={'Transient Source Properties'}/>
                    <ColorPicker
                        label={'Color'}
                        id={'color'}
                        variant={'standard'}
                        value={state['color'] || ''}
                        onChange={color => handleChange('color', color)}
                        TextFieldProps={{value: state['color']}}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            sx: {width: '80%'},
                            startAdornment:
                                <InputAdornment position={'start'}>
                                    <div style={{
                                        background: state['color'],
                                        border: '1px solid black',
                                        width: '24px',
                                        height: '24px',
                                        marginRight: '5px',
                                    }}>

                                    </div>
                                </InputAdornment>,
                            endAdornment:
                                <InputAdornment position={'end'}>
                                    HEX
                                </InputAdornment>
                        }}
                    />
                </SettingsTabPlane>
            </FormGroup>
        },
        {
            label: 'Thermal',
            Component: <FormGroup style={{height: '100%'}}>
                <SettingsTabPlane hasChange={boxHasChange()}
                                  onRevert={box.id !== 'creating' ? handleRevert : handleCancel}
                                  onSave={handleSubmit}
                                  object={box}>
                    <HeatTransferCoeff
                        fullWidth
                        variant={'standard'}
                        validators={[]}
                        value={state.hc || ''}
                        onChange={(event) => handleChange('hc', event.target.value)}
                        error={!!validation_errors['hc']}
                        helperText={validation_errors['hc']}
                        errorMessages={[message_floating_point]}
                    />
                    <Typography
                        sx={{
                            fontSize: '12px',
                            letterSpacing: 1.2,
                            marginTop: (theme) => theme.spacing(1),
                            marginLeft: (theme) => theme.spacing(1)
                        }}>
                        POWERS
                    </Typography>
                    {state.powers.length
                        ? (
                            <List>
                                {state.powers.map((power, index) => {
                                    return (
                                        <ListItem
                                            component={'div'}
                                            sx={{height: 60}}
                                            key={index}
                                            disablePadding
                                        >
                                            <InputFields
                                                field_names={['time', 'power']}
                                                state={state.powers[index]}
                                                units={{time: 's', power: 'mw'}}
                                                ids={['transienttime', 'transientpower']}
                                                validation_errors={(validation_errors.powers && validation_errors.powers[index])}
                                                handleChange={(prop_name, event) => handlePowerLayerChange(index, prop_name, event)}/>
                                            <Box sx={{'& > *': {margin: '5px'}, display: 'flex', flexWrap: 'nowrap'}}>
                                                <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.delete}
                                                                  secondClass={'smaller'}
                                                                  onClickHandler={(e) => {
                                                                      confirmAlert({
                                                                          title: `Deleting ${state.powers[index].time}`,
                                                                          message: 'Do you wish to proceed?',
                                                                          buttons: [{
                                                                              label: 'Delete', onClick: () => {
                                                                                  setState((prevState) => ({
                                                                                      ...prevState,
                                                                                      powers: state.powers.filter((item, idx) => {
                                                                                          return idx !== index;
                                                                                      }),
                                                                                  }));
                                                                              },
                                                                          }, {
                                                                              label: 'Cancel', onClick: () => {
                                                                              },
                                                                          },],
                                                                      });
                                                                      e.stopPropagation();
                                                                  }}/>

                                                <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.clone}
                                                                  secondClass={'smaller'}
                                                                  onClickHandler={(e) => {
                                                                      handleCopyPower(state.powers[index]);
                                                                      e.stopPropagation();
                                                                  }}/>
                                            </Box>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        )
                        : null
                    }
                    <Button
                        fullWidth
                        variant={'contained'}
                        color={'secondary'}
                        sx={{marginBottom: (theme) => theme.spacing(2)}}
                        onClick={handleAdd}>
                        <Add size={'small'}/>
                    </Button>

                    <FormControl>
                        <SwitchToggle
                            id={'bindcheckbox'}
                            booleans={true}
                            onChange={() => handleChange('bound', !state.bound)}
                            checked={state.bound}
                            title={'Bind to ambient'}
                            orientation={'vertical'}
                        />
                    </FormControl>
                    <Tooltip title={
                        <span>
                            Forces all exposed object walls to ambient temperature.
                            </span>
                    }>
                        <InfoOutlined fontSize={'inherit'}/>
                    </Tooltip>

                </SettingsTabPlane>
            </FormGroup>
        }
    ];

    return <BasicTabs tabs={tabs} title={'Properties'} isSwitch={true}/>;
};

TransientSourceSettingsTemplate.propTypes = {
    boxHasChange: PropTypes.func.isRequired,
    handleRevert: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    form: PropTypes.object,
    createProjectObject: PropTypes.func.isRequired,
    updateProjectObjectAndTree: PropTypes.func.isRequired,
    debounceUpdateSelectedObject: PropTypes.func.isRequired,
    box: PropTypes.object,
    state: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
    validation_errors: PropTypes.object,
    materials: PropTypes.array
};

const mapStateToProps = state => ({
    materials: getProjectMaterials(state) || [],
    validation_errors: getValidationErrors(state),
    project_id: getProject(state).id,
    tree: getTree(state),
    box: getSelectedObject(state),
});

const mapDispatchToProps = {
    createProjectObject: createProjectTransientSource,
    updateProjectObject: updateProjectTransientSource,
    updateProjectObjectAndTree: updateProjectTransientSourceAndTree,
    deleteProjectObject: deleteProjectTransientSource,
    setSelectedObject,
    updateSelectedObject,
};

export default connect(mapStateToProps, mapDispatchToProps)(withSettingsTemplate(TransientSourceSettingsTemplate));
