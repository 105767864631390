export const transform = (selectedObject) => {
    return {
        ...selectedObject,
        x_calc: +selectedObject.x,
        y_calc: +selectedObject.y,
        z_calc: +selectedObject.z,
        dx_calc: +selectedObject?.dx,
        dy_calc: +selectedObject?.dy,
        dz_calc: +selectedObject?.dz,
        base_dx_calc: +selectedObject?.base_dx,
        base_dy_calc: +selectedObject?.base_dy,
        base_dxz_calc: +selectedObject?.base_dz,
        fin_count_calc: +selectedObject?.fin_count,
        fin_height_calc: +selectedObject?.fin_height,
        fin_thickness_calc:  +selectedObject?.fin_thickness,
        diameter_calc: +selectedObject?.diameter,
        ycount_calc: +selectedObject?.ycount,
        xcount_calc: +selectedObject?.xcount,
        ypitch_calc: +selectedObject?.ypitch,
        xpitch_calc: +selectedObject?.xpitch,
        pitch_calc: +selectedObject?.pitch,
        layers: selectedObject?.layers?.map(layer => ({...layer, thickness_calc: +layer?.thickness}))
    }
}

