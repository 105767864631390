/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {Component} from "react";
import {isLoading} from "../../dashboard/reducer";
import {showModal} from "../../modals/actions";
import {getUserInfo, requestLicense} from "../../authentication/actions";
import {compose} from "redux";
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import {hasUserInfo} from "../../authentication/reducer";
import {TableCell, Tooltip} from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import TableHead from "@mui/material/TableHead";
import Header from "../../components/Header";
import {yellow, red} from "@mui/material/colors";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import Button from "@mui/material/Button";
import EULA from '../../user_setup/EULA';
import {MAIN_FOLDER_NAMES} from "../../core/constants/PROJECTS_TREE";

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
    },
});


class MyAccount extends Component {
    constructor(props) {
        super(props);
        this.props.getUserInfo();
    }

    handleRequestLicense (license) {
        this.props.requestLicense({feature: license.feature});
    }

    render() {
        const {user_info, classes} = this.props;
        return (<div className={classes.root}>
            <Header/>
            <Container>
                <Grid container spacing={2}>
                    <Grid xs={5} item>
                        <Typography component="h4" variant="h6" gutterBottom>
                            User information
                        </Typography>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell component={"th"}>Email address</TableCell>
                                    <TableCell align={"right"}>
                                        {user_info.user && user_info.user.email}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component={"th"}>First name</TableCell>
                                    <TableCell id="firstname" align={"right"}>
                                        {user_info.user && user_info.user.first_name}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component={"th"}>Last name</TableCell>
                                    <TableCell id="lastname" align={"right"}>
                                        {user_info.user && user_info.user.last_name}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component={"th"}>Groups</TableCell>
                                    <TableCell id="groups" align={"right"}>
                                        {user_info.user && user_info.user.groups.join(', ')}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component={"th"}>API token</TableCell>
                                    <TableCell id="groups" align={"right"}>
                                        {user_info.user && user_info.user.api_token}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid xs={7} item>
                        <Typography component="h4" variant="h6" gutterBottom>
                            Assigned Licenses
                        </Typography>
                        {
                            user_info.licenses && user_info.licenses.length > 0 &&
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Feature</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Expiration date</TableCell>
                                        <TableCell>Job limit</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        user_info.licenses.map(license => (
                                            <TableRow key={license.feature}>
                                                <TableCell>
                                                    {license.feature}
                                                    {
                                                        (license.expiration &&
                                                            (new Date(license.expiration) > Date.now()) &&
                                                            (new Date(license.expiration) - Date.now() < 14 * 24 * 60 * 60 * 1000)
                                                        ) &&
                                                        <Tooltip title={"Expiring soon"}>
                                                            <WarningIcon style={{color: yellow[800]}}
                                                                         fontSize={"small"}/>
                                                        </Tooltip>
                                                    }
                                                    {
                                                        (license.expiration && new Date(license.expiration) <= Date.now()) &&
                                                        <Tooltip title={"Expired"}>
                                                            <ErrorIcon style={{color: red[800]}}
                                                                       fontSize={"small"}/>
                                                        </Tooltip>
                                                    }
                                                </TableCell>
                                                <TableCell>{license.description}</TableCell>
                                                <TableCell>{
                                                    license.expiration ||
                                                    !license.requested &&
                                                    <Button id={'addFolder'}
                                                            className={'add-folder'}
                                                            variant={'contained'}
                                                            color={'primary'}
                                                            onClick={() => this.handleRequestLicense(license)}
                                                    >
                                                        Request trial
                                                    </Button> ||
                                                    license.requested &&
                                                    <Button id={'addFolder'}
                                                            className={'add-folder'}
                                                            variant={'contained'}
                                                            color={'primary'}
                                                            disabled={true}
                                                    >
                                                        Pending
                                                    </Button>

                                                }</TableCell>
                                                <TableCell>{license.group_limit}</TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        }
                        {
                            user_info.licenses && user_info.licenses.length == 0 &&
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => this.props.requestLicense()}
                            >
                                Request a Trial license
                            </Button>
                        }
                    </Grid>
                    <Grid xs={12} item>
                        <EULA/>
                    </Grid>
                </Grid>
            </Container>
        </div>)
    }
}

const mapStateToProps = state => ({
    isLoading: isLoading(state),
    user_info: hasUserInfo(state),
});

const mapDispatchToProps = {
    showModal,
    getUserInfo,
    requestLicense,
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(MyAccount);
