/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';
import './node-content-renderer.scss';

import {projectCardDragEnd, setActiveFolderAction, setDraggedProjectNextParent, updateProject} from '../../../actions';
import {
    getActiveFolder,
    getDraggedProject,
    getProjectCartNextParentId,
    getProjectsTreeData,
    getSharedProjectsTreeData,
} from '../../../reducer';

import ContextMenuComponent from '../contextMenu/context-menu.component';

function isDescendant(older, younger) {
    return (
        !!older.children &&
        typeof older.children !== 'function' &&
        older.children.some(
            child => child === younger || isDescendant(child, younger),
        )
    );
}

// eslint-disable-next-line react/prefer-stateless-function
const FileThemeNodeContentRenderer = ({
                                          scaffoldBlockPxWidth,
                                          toggleChildrenVisibility,
                                          connectDragPreview,
                                          connectDragSource,
                                          isDragging,
                                          canDrop,
                                          canDrag,
                                          node,
                                          title,
                                          draggedNode,
                                          path,
                                          treeIndex,
                                          isSearchMatch,
                                          isSearchFocus,
                                          icons,
                                          buttons,
                                          className,
                                          style,
                                          didDrop,
                                          lowerSiblingCounts,
                                          listIndex,
                                          swapFrom,
                                          swapLength,
                                          swapDepth,
                                          treeId, // Not needed, but preserved for other renderers
                                          isOver, // Not needed, but preserved for other renderers
                                          parentNode, // Needed for dndManager
                                          rowDirection,
                                          activeFolder,
                                          setActiveFolderAction,
                                          treeData,
                                          sharedTreeData,
                                          setDraggedProjectNextParent,
                                          draggedProject,
                                          draggedProjectNextParentId,
                                          projectCardDragEnd,
                                          updateProject,
                                          ...otherProps
                                      }) => {
    const [contextMenuInfo, setContextMenuInfo] = useState(null);

    const nodeTitle = title || node.title || node.name;
    const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
    const isLandingPadActive = !didDrop && isDragging;

    // Construct the scaffold representing the structure of the tree
    const scaffold = [];
    lowerSiblingCounts.forEach((lowerSiblingCount, i) => {
        scaffold.push(
            <div
                key={`pre_${1 + i}`}
                style={{width: scaffoldBlockPxWidth}}
                className={'lineBlock'}
            />,
        );

        // if (treeIndex !== listIndex && i === swapDepth) {
        //     // This row has been shifted, and is at the depth of
        //     // the line pointing to the new destination
        //     let highlightLineClass = 'highlightLineVertical';
        //
        //     if (listIndex === swapFrom + swapLength - 1) {
        //         // This block is on the bottom (target) line
        //         // This block points at the target block (where the row will go when released)
        //         highlightLineClass = 'highlightBottomLeftCorner';
        //     }
        //
        //     if (treeIndex === swapFrom) {
        //         // This block is on the top (source) line
        //         highlightLineClass = 'highlightTopLeftCorner';
        //     }
        //
        //     scaffold.push(
        //         <div
        //             key={`highlight_${1 + i}`}
        //             style={{
        //                 width: scaffoldBlockPxWidth,
        //                 left: scaffoldBlockPxWidth * i,
        //             }}
        //             className={`absoluteLineBlock ${highlightLineClass}`}
        //         />,
        //     );
        // }
    });

    const nodeContent = (
        <div style={{height: '100%'}}
             onMouseEnter={() => {
                 if (draggedProject === null) {
                     return;
                 }

                 setDraggedProjectNextParent(node.id);
             }}
             onMouseUp={(e) => {
                 e.stopPropagation();
                 e.preventDefault();

                 if (draggedProjectNextParentId !== 0 && draggedProjectNextParentId !== draggedProject?.folder) {
                     return updateProject({...draggedProject, folder: draggedProjectNextParentId});
                 }

                 if (draggedProject !== null) {
                     projectCardDragEnd();
                 }
             }}
             {...otherProps}>
            {toggleChildrenVisibility &&
            node.children &&
            node.children.length > 0 && (
                <button
                    type="button"
                    aria-label={node.expanded ? 'Collapse' : 'Expand'}
                    className={node.expanded ? 'collapseButton' : 'expandButton'}
                    style={{
                        left: (lowerSiblingCounts.length - 0.5) * scaffoldBlockPxWidth,
                    }}
                    onClick={() => toggleChildrenVisibility({node, path, treeIndex})}
                />
            )}
            <div
                className={`rowWrapper${!canDrag ? ` rowWrapperDragDisabled` : ''}`}
            >
                {/* Set the row preview to be used during drag and drop */}
                {connectDragPreview(
                    <div className={'rowInnerWrapper'} style={{display: 'flex'}}>
                        {scaffold}
                        <div className={
                            'row' +
                            (isLandingPadActive ? ' rowLandingPad' : '') +
                            (isLandingPadActive && !canDrop
                                ? ' rowCancelPad'
                                : '') +
                            (isSearchMatch ? ' rowSearchMatch' : '') +
                            (isSearchFocus ? ' rowSearchFocus' : '') +
                            (className ? ` ${className}` : '') +
                            (node.id === activeFolder.node.id ? ' active' : '') +
                            (draggedProject && ((node.id === draggedProject.folder && node.id === draggedProjectNextParentId))
                                ? ' disabled-drop-project'
                                : node.id === draggedProjectNextParentId && node.id !== 0
                                    ? ' drop-folder'
                                    : '') +
                            (draggedProjectNextParentId === 0 && node.id === draggedProjectNextParentId ? ' disabled-drop-project' : '')
                        }
                             style={{
                                 opacity: isDraggedDescendant ? 0.5 : 1,
                                 ...style,
                                 height: '100%',
                             }}
                             onClick={() => {
                                 if (activeFolder.node.id !== node.id) {
                                     setActiveFolderAction({
                                         path,
                                         node: {id: node.id, name: node.name},
                                     });
                                 }
                             }}
                             onContextMenu={(e) => {
                                 e.preventDefault();

                                 if (node.hasOwnProperty('parent_folder') || node.id === null) {
                                     setContextMenuInfo({
                                         treeData,
                                         path,
                                         node,
                                         createFolderName: '',
                                         editFolderName: node.name,
                                         id: node.id,
                                         isOpen: true,
                                         anchor: e.currentTarget,
                                     });
                                 }
                             }}>
                            {contextMenuInfo && contextMenuInfo.node.id === node.id
                                ? <ContextMenuComponent contextMenuInfo={contextMenuInfo}
                                                        setContextMenuInfo={setContextMenuInfo}/>
                                : null}
                            <div
                                className={`rowContents ${!canDrag ? 'rowContentsDragDisabled' : ''}`}>
                                <div className={'rowToolbar'}>
                                    {icons.map((icon, index) => (
                                        <div
                                            key={index} // eslint-disable-line react/no-array-index-key
                                            className={'toolbarButton'}>
                                            {icon}
                                        </div>
                                    ))}
                                </div>
                                <div onClick={() => {}}
                                     className={'rowLabel'}>
                    <span id="foldertreeid" className={'rowTitle'}>
                      {typeof nodeTitle === 'function'
                          ? nodeTitle({
                              node,
                              path,
                              treeIndex,
                          })
                          : nodeTitle}
                    </span>
                                </div>
                                <div className={'rowToolbar'}>
                                    {buttons.map((btn, index) => (
                                        <div
                                            key={index} // eslint-disable-line react/no-array-index-key
                                            className={'toolbarButton'}
                                        >
                                            {btn}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>,
                )}
            </div>
        </div>
    );

    return canDrag
        ? connectDragSource(nodeContent, {dropEffect: 'copy'})
        : nodeContent;
};

FileThemeNodeContentRenderer.defaultProps = {
    buttons: [],
    canDrag: false,
    canDrop: false,
    className: '',
    draggedNode: null,
    icons: [],
    isSearchFocus: false,
    isSearchMatch: false,
    parentNode: null,
    style: {},
    swapDepth: null,
    swapFrom: null,
    swapLength: null,
    title: null,
    toggleChildrenVisibility: null,
    rowDirection: 'ltr',
};

FileThemeNodeContentRenderer.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.node),
    canDrag: PropTypes.bool,
    className: PropTypes.string,
    icons: PropTypes.arrayOf(PropTypes.node),
    isSearchFocus: PropTypes.bool,
    isSearchMatch: PropTypes.bool,
    listIndex: PropTypes.number.isRequired,
    lowerSiblingCounts: PropTypes.arrayOf(PropTypes.number).isRequired,
    node: PropTypes.shape({}).isRequired,
    path: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ).isRequired,
    scaffoldBlockPxWidth: PropTypes.number.isRequired,
    style: PropTypes.shape({}),
    swapDepth: PropTypes.number,
    swapFrom: PropTypes.number,
    swapLength: PropTypes.number,
    title: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    toggleChildrenVisibility: PropTypes.func,
    treeIndex: PropTypes.number.isRequired,
    treeId: PropTypes.string.isRequired,
    rowDirection: PropTypes.string.isRequired,

    // Drag and drop API functions
    // Drag source
    connectDragPreview: PropTypes.func.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    didDrop: PropTypes.bool.isRequired,
    draggedNode: PropTypes.shape({}),
    isDragging: PropTypes.bool.isRequired,
    parentNode: PropTypes.shape({}), // Needed for dndManager
    // Drop target
    canDrop: PropTypes.bool,
    isOver: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    // isLoading: isLoading(state),
    treeData: getProjectsTreeData(state),
    sharedTreeData: getSharedProjectsTreeData(state),
    activeFolder: getActiveFolder(state),
    draggedProject: getDraggedProject(state),
    draggedProjectNextParentId: getProjectCartNextParentId(state),
});

const mapDispatchToProps = {
    setActiveFolderAction,
    setDraggedProjectNextParent,
    updateProject,
    projectCardDragEnd,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
)(FileThemeNodeContentRenderer);

