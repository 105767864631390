import React, {useEffect, useState} from 'react';
import {
    InputAdornment,
    FormControlLabel,
    Checkbox,
    FormControl,
    Box,
    Paper,
    Button,
    Typography,
    Divider, Collapse
} from '@mui/material';
import {TextValidator} from 'react-material-ui-form-validator';
import {Add, Cached, ExpandLess, ExpandMore} from '@mui/icons-material';

const labelsMap = {
    dz: 'Δz',
    dy: 'Δy',
    dx: 'Δx'
};

export const InputFields = ({
                                field_names,
                                units,
                                ids,
                                type,
                                idx = undefined,
                                validation_errors,
                                handleChange,
                                state,
                                groupTitle,
                                errorMessages,
                                sxFormBox,
                                disabled,
                                inline,
                                wrapperSx,
                                required = true
                            }) => {

    const transformLabels = (label) => {
        if (field_names.some(f => f === 'dx' || f === 'dy' || f === 'dx') && labelsMap[label]) {
            return labelsMap[label];
        }
        return label;
    };

    return (
        <Box
            sx={{
                display: inline ? 'inline-flex' : 'flex',
                flexDirection: 'column',
                width: inline ? 'auto' : '100%',
                marginBottom: '10px',
                ...wrapperSx
            }}>
            <Typography
                sx={{
                    fontSize: '12px',
                    letterSpacing: 1.2,
                    marginLeft: (theme) => theme.spacing(1)
                }}>
                {groupTitle?.toUpperCase()}
            </Typography>
            <Box sx={{
                display: 'flex',
                flex: 1,
                '& > div': {
                    width: '100%',
                    '& > .MuiFormControl-root': {
                        ...sxFormBox
                    }
                },
                gap: (theme) => theme.spacing(1),
            }}>
                {
                    field_names.map((prop_name, propIdx) => (
                        <TextValidator fullWidth
                                       idx={idx !== undefined ? idx : propIdx}
                                       id={ids && ids[propIdx] ? ids[propIdx] : ids} key={prop_name}
                                       label={transformLabels(prop_name).toUpperCase().replaceAll('_', ' ')}
                                       value={state.hasOwnProperty(prop_name) ? state[prop_name] : ''}
                                       onChange={(event) => handleChange(prop_name, event.target.value)}
                                       error={
                                           validation_errors && !!validation_errors[prop_name]
                                           || (state.hasOwnProperty(prop_name) && state[prop_name] === '')
                                       }
                                       type={type || ''}
                                       helperText={validation_errors && validation_errors[prop_name]}
                                       validators={(disabled === true || disabled?.some((disabledBtn) => disabledBtn === prop_name)) || !required ? [] : ['required']}
                                       errorMessages={errorMessages || ['This field is required!']}
                                       disabled={disabled === true || disabled?.some((disabledBtn) => disabledBtn === prop_name)}
                                       InputProps={{
                                           sx: {
                                               marginTop: 0,
                                               '&.Mui-disabled': {
                                                   backgroundColor: 'transparent',
                                                   border: '1px solid black',
                                                   '&::before': {
                                                       display: 'none'
                                                   },
                                               }
                                           },
                                           endAdornment: (
                                               <InputAdornment position={'end'}>
                                                   {typeof units === 'object' ? units[prop_name] : units}
                                               </InputAdornment>
                                           ),
                                       }}
                        />
                    ))
                }
            </Box>
        </Box>
    );
};

export const IconCheckBoxField = ({
                                      id,
                                      checked,
                                      handleChange,
                                      icon,
                                      field_name,
                                      checkedIcon,
                                      checkBoxLabel,
                                      ...props
                                  }) => {
    return (
        <FormControl>
            <FormControlLabel style={{margin: 0}}
                              id={id}
                              label={checkBoxLabel || ''}
                              control={
                                  <Checkbox color={'default'}
                                            disableRipple={true}
                                            disableFocusRipple={true}
                                            onChange={() => handleChange(field_name, !checked)}
                                            checked={checked}
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            {...props}
                                  />
                              }
            />
        </FormControl>
    );
};

export const SettingsTabPlane = ({children, ...actionButtonsProps}) => {
    return (
        <Paper elevation={0}
               sx={{
                   p: (theme) => theme.spacing(1),
                   display: 'flex',
                   flex: '1 1 auto',
                   justifyContent: 'space-between',
                   flexDirection: 'column',
                   height: '100%',
               }}>
            <Box sx={{maxHeight: 'calc(100vh - 420px)', flex: '1 0 auto', overflowY: 'auto', overflowX: 'hidden',}}>
                {children}
            </Box>
            <Box>
                <SettingsDivider/>
                <SettingsActionButtons {...actionButtonsProps} />
            </Box>
        </Paper>
    );
};

export const SettingsActionButtons = ({hasChange, onRevert, onSave, object}) => {
    return (
        <Box sx={{
            display: 'flex', flex: 1, alignItems: 'flex-end',
            '& > button': {width: '100%', textTransform: 'none', fontSize: '16px'},
            gap: '16px',
        }}>
            <Button variant="contained" color="secondary"
                    id={'revert'}
                    onClick={onRevert}
                    disabled={!hasChange && object.id !== 'creating'}
            >
                {object.id === 'creating' ? 'Cancel' : 'Revert'}
            </Button>
            <Button variant="contained"
                    id={'submit'}
                    type="submit"
                    onClick={onSave}
                    disabled={!hasChange && object.id !== 'creating'}
                    startIcon={
                        (object && (object.id === 'creating'))
                            ? <Add fontSize={'inherit'}/>
                            : <Cached fontSize={'inherit'}/>
                    }
            >
                {(object && (object.id === 'creating')) ? 'Add' : 'Update'}
            </Button>
        </Box>
    );
};

export const SettingsSubtitle = ({title, marginLeft = '8px', opacity = 1, sx = {}, ...props}) => {
    return (
        <Typography {...props}
                    sx={{
                        fontSize: '12px',
                        letterSpacing: 1.2,
                        marginLeft: marginLeft,
                        width: '100%',
                        opacity: opacity,
                        marginTop: (theme) => theme.spacing(1),
                        ...sx
                    }}
        >
            {title?.toUpperCase()}
        </Typography>
    );
};

export const SettingsHeader = ({children, name}) => {
    return (
        <>
            <SettingsSubtitle title={'Selection'} opacity={0.65} sx={{marginTop: 0}}/>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                height: '25px',
                margin: (theme) => `${theme.spacing(1)} 0`
            }}>
                <Typography noWrap
                            sx={{
                                marginLeft: (theme) => theme.spacing(2),
                                textTransform: 'capitalize',
                            }}>
                    {name}
                </Typography>
                <Box sx={{
                    display: 'flex', justifyContent: 'center',
                    '& .MuiIcon-root': {
                        mr: '5px',
                        borderRadius: '4px'
                    }
                }}>
                    {children}
                </Box>
            </Box>
        </>
    );
};

export const SettingsDivider = ({withSideMargin, sxProps}) => {
    return (
        <Divider sx={{
            borderColor: 'background.input',
            m: (theme) => `${withSideMargin ? 0 : theme.spacing(1)} ${withSideMargin ? theme.spacing(1) : 0}`,
            ...sxProps
        }}/>
    );
};
export const CollapsableListItem = ({
                                        children, header, headerBoxSx, idx, formErrors, shouldUpdate, openColor,
                                        closedColor
                                    }) => {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen((prevState) => !prevState);
    };

    useEffect(() => {
        let shouldOpen = false;
        if (shouldUpdate === idx) {
            shouldOpen = true;
        }

        formErrors?.forEach((e) => {
            if (e.props.idx === idx) {
                shouldOpen = true;
            }
        });
        shouldOpen && setOpen(shouldOpen);
    }, [formErrors?.length, shouldUpdate]);

    return (
        <Box sx={{margin: '8px 0'}} key={idx}>
            <Box onClick={handleClick}
                 sx={{
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'space-between',
                     width: '100%',
                     backgroundColor: open ? openColor : closedColor,
                     ...headerBoxSx
                 }}
            >
                {header}
                {open ? <ExpandLess/> : <ExpandMore/>}
            </Box>
            <Collapse key={idx} in={open} timeout={'auto'}>
                {children}
            </Collapse>
        </Box>
    );
};
export const PanelDivider = ({sxProps, ...restProps}) => {
    return (
        <Divider orientation={'horizontal'}
                 flexItem
                 variant={'middle'}
                 sx={{borderColor: 'background.input', m: '0 8px', ...sxProps}}
                 {...restProps}/>
    );
};