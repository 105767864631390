import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Box, CircularProgress, Divider, InputAdornment, SvgIcon, TextField} from '@mui/material';
import Typography from '@mui/material/Typography';
import {SwitchToggle} from '../../../../../../../components/SwitchToggle';
import Button from '@mui/material/Button';
import {projectUtils} from '../../../../../../utils';
import {IconsFontAwesome} from '../../../../../../../components/IconsFontAwesome';
import {FONT_AWESOME_ICONS_TYPE_MAP} from '../../../../../../../core/mappings';
import {
    getData as getSolution, getTaskId, getTaskStatus, getTaskStatusTime
} from '../../../../../../+store/reducer/solution';
import {getData as getProject} from '../../../../../../+store/reducer/project';

import {solveProject, stopTask} from '../../../../../../+store/actions/actions';
import {connect} from 'react-redux';
import {getData as getTree, getSelectedObject} from '../../../../../../+store/reducer/tree';
import {CopyToClipboard} from '../../../../../../../components/CopyToClipboard';
import {MINI_ICONS} from '../../../../../assets/icons/svg/components-mini-icons/MiniIcons.map';
import PlayTransientLoadSolutions from '../../../../../../../components/PlayTransientLoadSolutions';
import DownloadTransientData from '../../../../../../../components/DownloadTransientData';
import Transient from './Transient';

const SOLUTION_TYPE = {
    STEADY: 'steady', TRANSIENT: 'transient'
};

const SOLUTION_OPTIONS = [
    {key: SOLUTION_TYPE.STEADY, value: SOLUTION_TYPE.STEADY},
    {key: SOLUTION_TYPE.TRANSIENT, value: SOLUTION_TYPE.TRANSIENT}
];

const SolutionPanel = ({
                           transient,
                           selectedObject,
                           solution,
                           tree,
                           solve_task_status,
                           current_project,
                           onWindowsResize,
                           task_id,
                           stopTask,
                           solveProject,
                           project_id,
                           loadNewSolution,
                           thermalGradientConfig,
                           draw,
                           isSolutionLoading,
                           toggleCameraRotate,
                       }) => {
    const [currentPlayedSolutionId, setCurrentPlayedSolutionId] = useState();
    const [solutionType, setSolutionType] = useState(SOLUTION_OPTIONS[0].key);
    const [currentViewPoints, setCurrentViewPoints] = useState();
    const isRunning = current_project && ['PENDING', 'RUNNING'].includes(solve_task_status);

    const treeTimeStamp = useMemo(() => {
        return projectUtils.getTreeTimestamp(tree);
    }, [tree]);

    const hasSolutionResult = useMemo(() => {
        return solution && Object.keys(solution.result || {}).length > 0;
    }, [solution]);


    const renderSolutionStatus = useMemo(() => {
        if (isRunning) {
            return <><CircularProgress size={14} style={{color: 'white', animation: 'none', marginRight: 10}}
                                       thickness={5}/>
                <Typography
                    size={'infoSmall'}>{solve_task_status}
                </Typography></>;
        }
        if (solution && solution.timestamp && (new Date(solution.timestamp)) < treeTimeStamp) {
            return <>
                <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.warning}
                                  color={'warning'}
                                  hasHover={false}
                                  iconsSX={{
                                      fontSize: '1rem', width: 'auto', mr: '10px', '&:hover': {cursor: 'default'},
                                  }}/>
                <Typography size={'infoSmall'}>
                    {solution.result && !Object.keys(solution.result).length ? 'No solution' : 'Stale'}
                </Typography>
            </>;
        }

        return solution && solution.timestamp && hasSolutionResult ? (<>
            <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.circleCheck}
                              hasHover={false}
                              iconsSX={{
                                  fontSize: '1rem',
                                  width: 'auto',
                                  mr: '10px',
                                  '&:hover': {cursor: 'default'},
                                  color: 'green',
                              }}/>
            <Typography size={'infoSmall'}>UPDATED</Typography>
        </>) : <>
            <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.warning}
                              color={'warning'}
                              hasHover={false}
                              iconsSX={{
                                  fontSize: '1rem', width: 'auto', mr: '10px', '&:hover': {cursor: 'default'},
                              }}/>
            <Typography size={'infoSmall'}>NO SOLUTION</Typography>
        </>;
    }, [solution, solve_task_status, treeTimeStamp, hasSolutionResult]);

    useEffect(() => {
        const canvas = document.querySelector('#canvasID');
        if (hasSolutionResult) {
            if (selectedObject && Object.keys(selectedObject).length > 0) {
                if (!canvas.classList.contains('selectedObject')) {
                    canvas.classList.add('selectedObject');
                    onWindowsResize();
                }
            } else {
                if (canvas.classList.contains('selectedObject')) {
                    canvas.classList.remove('selectedObject');
                    onWindowsResize();
                }
            }
        }

    }, [selectedObject, hasSolutionResult]);

    const handleChangeSolutionType = (type) => {
        const canvas = document.querySelector('#canvasID');
        if (type === SOLUTION_TYPE.TRANSIENT && transient.length > 0) {
            if (!canvas.classList.contains('transient')) {
                if (canvas.classList.contains('steady')) {
                    canvas.classList.remove('steady');
                }
                canvas.classList.add('transient');
                onWindowsResize();
            }
        } else {
            if (canvas.classList.contains('transient')) {
                canvas.classList.remove('transient');
                if (!canvas.classList.contains('steady')) {
                    canvas.classList.add('steady');
                }
                onWindowsResize();
            }
        }
        setSolutionType(type);
    };

    useEffect(() => {
        if (Object.keys(transient).length) {
            const canvas = document.querySelector('#canvasID');
            canvas.classList.add('has-transient');
            onWindowsResize();
        } else {
            const canvas = document.querySelector('#canvasID');
            if (
                canvas.classList.contains('has-transient')
            ) {
                canvas.classList.remove('has-transient');
                onWindowsResize();
            }
        }
    }, [transient]);

    const handleSolve = () => {
        solveProject({
            project_id: current_project.id, transient: solutionType === SOLUTION_TYPE.TRANSIENT ? 1 : 0,
        });
    };

    const handleStop = () => {
        projectUtils.updateStorageTaskStatus({taskId: task_id, deleteStatus: true});
        stopTask({project_id: current_project.id, task_id});
    };

    return <>
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            height: '45px',
            backgroundColor: 'background.primary',
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px',
                paddingLeft: 0
            }}>
                <Typography variant={'faded'} size={'infoSmall'} sx={{margin: '10px'}}>SOLUTION</Typography>
                {renderSolutionStatus}
                <Divider orientation={'vertical'}
                         variant={'middle'}
                         sx={{borderColor: 'background.input', margin: '0 10px'}}/>
                <CopyToClipboard/>
                {transient.length > 0 &&
                    <PlayTransientLoadSolutions loadNewSolution={loadNewSolution} projectId={project_id}
                                                transient={transient} isSolutionLoading={isSolutionLoading}
                                                setCurrentPlayedSolutionId={setCurrentPlayedSolutionId}
                                                currentPlayedSolutionId={currentPlayedSolutionId}
                                                currentViewPoints={currentViewPoints}/>}
                <IconsFontAwesome
                    iconType={FONT_AWESOME_ICONS_TYPE_MAP.cameraRotate}
                    titleAccess={"Auto rotate camera"}
                    secondClass={"smaller"}
                    mainSX={{marginLeft: '10px'}}
                    hoveredSX={{marginLeft: '10px'}}
                    onClickHandler={toggleCameraRotate}
                />
                {transient.length > 0 && <DownloadTransientData
                    project={current_project} transient={transient} />}
            </Box>
            <Box
                sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <SwitchToggle isSwitch title={'Solution type'} titleSX={{marginRight: '5px'}}
                              buttonSettings={{
                                  lineHeight: '1',
                                  letterSpacing: 0.75,
                                  height: 'auto',
                                  fontSize: 'auto',
                                  p: 0
                              }}
                              groupSettingsSX={{marginRight: '10px', height: '24px'}}
                              buttonSettingsSX={{
                                  lineHeight: '1',
                                  '&.Mui-selected:hover': {
                                      backgroundColor: (theme) => theme.palette.primary.main,
                                  }
                              }}
                              checked={solutionType}
                              options={SOLUTION_OPTIONS}
                              selectedColor={'primary'}
                              onChange={handleChangeSolutionType}/>
                {isRunning ?
                    <Button variant={'contained'} size={'large'} color={'error'}
                            sx={{padding: '0 20px', marginRight: '10px', width: '100px'}} onClick={handleStop}>
                        <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.stop}
                                          hasHover={false}
                                          secondClass={'smaller'}
                                          iconsSX={{width: 'auto', mr: '10px'}}
                                          titleAccess={'Stop solution'}/>
                        STOP
                    </Button> :
                    <Button variant={'contained'} size={'small'} color={'solve'}
                            sx={{padding: '0 20px', marginRight: '10px', width: '100px'}} onClick={handleSolve}>
                        <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.play}
                                          hasHover={false}
                                          secondClass={'smaller'}
                                          iconsSX={{width: 'auto', mr: '10px'}}
                                          titleAccess={'Stop solution'}/>
                        SOLVE
                    </Button>}
            </Box>
        </Box>

        {hasSolutionResult && selectedObject && Object.keys(selectedObject).length > 0 &&
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                height: '45px',
                backgroundColor: 'background.paper',
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <Typography variant={'faded'} size={'infoSmall'} sx={{margin: '10px'}}>SELECTION</Typography>
                    {selectedObject && MINI_ICONS[selectedObject.type] &&
                        <SvgIcon sx={{
                            marginRight: '12px',
                            opacity: 0.6,
                        }}>
                            {MINI_ICONS[selectedObject.type]}
                        </SvgIcon>}
                    <Typography size={'small'} sx={{margin: '10px'}}>{selectedObject?.name}</Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '60%'
                }}>
                    <Divider orientation={'vertical'}
                             variant={'middle'}
                             sx={{borderColor: 'background.input', margin: '0 10px'}}/>
                    <Typography variant={'faded'} size={'infoSmall'} sx={{margin: '10px'}}>T<sub>min</sub>
                    </Typography>
                    <TextField
                        size={'small'}
                        inputProps={{sx: {padding: 0}}}
                        name={'Min Temperature'}
                        value={solution.result && selectedObject && solution.result[`${selectedObject.id}.${selectedObject.type_id}`]?.min_t?.toFixed(2)}
                        disabled
                        InputProps={{
                            endAdornment: <InputAdornment position={'end'}>°C</InputAdornment>,
                        }}
                    />
                    <Typography variant={'faded'} size={'infoSmall'} sx={{margin: '10px'}}>T<sub>max</sub>
                    </Typography>
                    <TextField
                        inputProps={{sx: {padding: 0}}}
                        size={'small'}
                        name={'Min Temperature'}
                        value={solution.result && selectedObject && solution.result[`${selectedObject.id}.${selectedObject.type_id}`]?.max_t?.toFixed(2)}
                        disabled
                        InputProps={{
                            endAdornment: <InputAdornment position={'end'}>°C</InputAdornment>,
                        }}
                    />
                    <Typography variant={'faded'} size={'infoSmall'} sx={{margin: '10px'}}>P<sub>out</sub>
                    </Typography>
                    <TextField
                        inputProps={{sx: {padding: 0}}}
                        size={'small'}
                        name={'Min Temperature'}
                        value={solution.result && selectedObject && solution.result[`${selectedObject.id}.${selectedObject.type_id}`]?.dissipated_power?.toFixed(2)}
                        disabled
                        InputProps={{
                            endAdornment: <InputAdornment position={'end'}>mW</InputAdornment>,
                        }}
                    />
                </Box>
            </Box>}
        {transient.length > 0 &&
            <Transient {...{
                data: transient,
                project_id,
                draw,
                loadNewSolution,
                thermalGradientConfig,
                currentPlayedSolutionId,
                setCurrentViewPoints
            }} />}
    </>;
};

SolutionPanel.propTypes = {
    transient: PropTypes.array.isRequired,
    selectedObject: PropTypes.object,
    solution: PropTypes.object,
    solve_task_status: PropTypes.string,
    tree: PropTypes.array,
    project_id: PropTypes.number.isRequired,
    loadNewSolution: PropTypes.func.isRequired,
    draw: PropTypes.func.isRequired,
    thermalGradientConfig: PropTypes.array.isRequired,
    isSolutionLoading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    current_project: getProject(state),
    solve_task_status: getTaskStatus(state),
    solve_task_status_time: getTaskStatusTime(state),
    task_id: getTaskId(state),
    solution: getSolution(state),
    tree: getTree(state),
    selectedObject: getSelectedObject(state)
});

const mapDispatchToProps = {
    stopTask, solveProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(SolutionPanel);
