/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import * as THREE from 'three';
import {BufferGeometryUtils} from 'three/examples/jsm/utils/BufferGeometryUtils';
import SpriteText from 'three-spritetext';

import _ from 'lodash';

import {getBBox} from './components/functions';
import SphereArrayGeometry from './components/geoms/SphereArrayGeometry';
import {CylinderArrayGeometry} from './components/geoms/CylinderArrayGeometry';

import * as wkt from 'wicket';

const movingPlaneMaterial = new THREE.MeshBasicMaterial({
    color: '#fafafa',
    transparent: true,
    opacity: 0.25,
    side: THREE.DoubleSide,
});
const movingObjMaterial = new THREE.MeshBasicMaterial({
    color: '#fafafa',
    transparent: true,
    opacity: 0.25,
});
const materialTransparent = new THREE.MeshBasicMaterial({
    transparent: true,
    opacity: 0,
    wireframe: true,
    side: THREE.DoubleSide,
});
const maxArrayObjects = 15e3;

export const axesArrowSettings = {
    shaft: {
        radius: .1,
        height: 4,
    },
    point: {
        radius: .4,
        segments: 10,
        height: 2,
    },
    colors: {
        x: 'red',
        y: 'green',
        z: 'blue',
        xy: ['red', 'green'],
        xz: ['red', 'blue'],
        yz: ['blue', 'green'],
        // xy: '0x0000ff',
        // xz: '0x0000ff',
        // yz: '0x0000ff',
        disabledColor: 'grey',
    },
};

export const message_floating_point = 'Enter a valid floating point number!';

const getMaterialAxes = ({color, transparent = true, opacity = 0.65, side = THREE.DoubleSide}) => {
    return new THREE.MeshBasicMaterial({color, transparent, opacity, side});
};


export const drawObjectUtils = {
    drawBox({
                scene, box, visible, solution = false, getMaterial, objects, intersects, current_project,
                arrowPosition = false,
            }) {
        let x0 = +box.x_calc + box.dx_calc / 2,
            y0 = +box.y_calc + box.dy_calc / 2,
            z0 = +box.z_calc + box.dz_calc / 2,
            geometry;

        const {material, line_material} = arrowPosition ? {
            material: movingObjMaterial,
            line_material: null
        } : getMaterial(box.color, box.hole ? 0.2 : 1);

        if (box.sphere) {
            geometry = new THREE.SphereBufferGeometry(box.dx / 2, 16, 16);
            const sphere = new THREE.Mesh(geometry, material);

            if (arrowPosition) {
                sphere.position.set(x0 - arrowPosition.x, y0 - arrowPosition.y, z0 - arrowPosition.z);
                scene.add(sphere);
            } else if (visible && box.visible && !solution) {
                sphere.position.set(x0, y0, z0);
                sphere.userData = box;

                scene.add(sphere);
                objects.push(sphere);
                intersects.push(sphere);
            }
        } else {
            geometry = new THREE.BoxBufferGeometry(+box.dx_calc, +box.dy_calc, +box.dz_calc);
            const cube = new THREE.Mesh(geometry, material);

            if (arrowPosition) {
                cube.position.set(x0 - arrowPosition.x, y0 - arrowPosition.y, z0 - arrowPosition.z);
                scene.add(cube);
            } else if (visible && box.visible || current_project.world.show_outline) {
                if (!solution && visible && box.visible) {
                    cube.position.set(x0, y0, z0);
                    cube.userData = box;

                    scene.add(cube);
                    objects.push(cube);
                    intersects.push(cube);
                }
                // draws cube edges
                const edges = new THREE.EdgesGeometry(geometry);
                const lines = new THREE.LineSegments(edges, line_material);
                lines.raycast = null;
                lines.position.set(x0, y0, z0);

                scene.add(lines);
            }
        }
    },
    drawPolygon({
                    scene, box, visible, solution = false, getMaterial, objects, intersects, current_project,
                    arrowPosition = false
                }) {
        let x0 = +box.x_calc, // + box.dx_calc / 2,
            y0 = +box.y_calc, // + box.dy_calc / 2,
            z0 = +box.z_calc; // + box.dz_calc / 2;

        const {material, line_material} = arrowPosition ? {
            material: movingObjMaterial,
            line_material: null
        } : getMaterial(box.color, box.hole ? 0.2 : 1);

        let parser = new wkt.Wkt();
        let objs = parser.read(box.polygon).toJson();
        let coords;
        if (objs.type == 'MultiPolygon')
            coords = objs.coordinates;
        else if (objs.type == 'Polygon')
            coords = [objs.coordinates];

        coords.forEach(points => {
            const polygonShape = new THREE.Shape(points[0].map(point => new THREE.Vector3(...point)));
            polygonShape.holes = points.slice(1).map(array => {
                return new THREE.Path(array.map(point => new THREE.Vector3(...point)));
            });
            const extrudeSettings = {
                depth: (box.thickness / 100) * 2,
                bevelEnabled: true,
                bevelSegments: 1,
                steps: 1,
                bevelSize: 0,
                bevelThickness: box.thickness / 2,
                bavelOffset: -4,
            };

            // extruded shape
            const geometry = new THREE.ExtrudeGeometry(polygonShape, extrudeSettings);

            let pX = x0,
                pY = y0,
                pZ = z0;

            switch (box.plane) {
                case 'XZ':
                    pY += box.thickness_calc / 2;
                    geometry.rotateX(Math.PI / 2);
                    break;
                case 'YZ':
                    pX += box.thickness_calc / 2;
                    geometry.rotateY(-Math.PI / 2);
                    break;
                default:
                    pZ += box.thickness_calc / 2;
            }

            const polygon = new THREE.Mesh(geometry, material);

            if (arrowPosition) {
                polygon.position.set(pX - arrowPosition.x, pY - arrowPosition.y, pZ - arrowPosition.z);
                scene.add(polygon);
            } else if (visible && box.visible) {
                if (!solution) {
                    polygon.position.set(pX, pY, pZ);
                    polygon.userData = box;

                    scene.add(polygon);
                    objects.push(polygon);
                    intersects.push(polygon);
                }

                // draws cube edges
                if (!box.hole && (visible && !box.visible || current_project && current_project.world.show_outline)) {
                    let edges = new THREE.EdgesGeometry(geometry);
                    let lines = new THREE.LineSegments(edges, line_material);
                    lines.raycast = null;
                    lines.position.set(pX, pY, pZ);
                    scene.add(lines);
                }
            }
        });
    },
    drawCylinder({
                     scene, box, visible, solution = false, getMaterial, objects, intersects, current_project,
                     arrowPosition = false
                 }) {
        let x0 = +box.x_calc + box.dx_calc / 2, y0 = +box.y_calc + box.dy_calc / 2, z0 = +box.z_calc + box.dz_calc / 2;

        const {material, line_material} = arrowPosition ? {
            material: movingObjMaterial,
            line_material: null
        } : getMaterial(box.color, box.hole ? 0.2 : 1);

        const geometry = new THREE.CylinderBufferGeometry(0.5, 0.5, 1, 32, 1);

        if (box.plane === 'XY') {
            geometry.rotateX(Math.PI / 2);
        } else if (box.plane === 'YZ') {
            geometry.rotateZ(Math.PI / 2);
        }

        geometry.scale(+box.dx_calc, +box.dy_calc, +box.dz_calc);

        const cube = new THREE.Mesh(geometry, material);
        if (arrowPosition) {
            cube.position.set(x0 - arrowPosition.x, y0 - arrowPosition.y, z0 - arrowPosition.z);
            scene.add(cube);

        } else if (visible && box.visible || current_project && current_project.world.show_outline) {
            if (!solution && visible && box.visible) {
                cube.position.set(x0, y0, z0);
                cube.userData = box;

                scene.add(cube);
                objects.push(cube);
                intersects.push(cube);
            }

            // draws cube edges
            let edges = new THREE.EdgesGeometry(geometry);
            let lines = new THREE.LineSegments(edges, line_material);
            lines.raycast = null;
            lines.position.set(x0, y0, z0);
            scene.add(lines);
        }
    },
    drawSource({
                   scene, box, visible, solution = false, getMaterial, objects, intersects, current_project,
                   arrowPosition = false
               }) {
        let x0 = box.x_calc + box.dx_calc / 2,
            y0 = box.y_calc + box.dy_calc / 2,
            z0 = box.z_calc + box.dz_calc / 2,
            geometry;

        const {material, line_material} = arrowPosition ? {
            material: movingObjMaterial,
            line_material: null
        } : getMaterial(box.color, box.hole ? 0.2 : 1);

        switch (box.plane) {
            case 'XZ':
                geometry = new THREE.PlaneGeometry(box.dx_calc, box.dz_calc);
                geometry.rotateX(Math.PI / 2);
                break;
            case 'YZ':
                geometry = new THREE.PlaneGeometry(box.dy_calc, box.dz_calc);
                geometry.rotateY(Math.PI / 2);
                break;
            default:
                geometry = new THREE.PlaneGeometry(box.dx_calc, box.dy_calc);
                break;
        }

        const source = new THREE.Mesh(geometry, material);
        if (arrowPosition) {
            source.position.set(x0 - arrowPosition.x, y0 - arrowPosition.y, z0 - arrowPosition.z);
            scene.add(source);
        } else if (visible && box.visible && !solution) {
            source.position.set(x0, y0, z0);
            source.userData = box;

            scene.add(source);
            objects.push(source);
            intersects.push(source);

            if (current_project && (visible && box.visible || current_project.world.show_outline)) {
                let edges = new THREE.EdgesGeometry(geometry);
                let lines = new THREE.LineSegments(edges, line_material);
                lines.raycast = null;
                lines.position.set(x0, y0, z0);

                scene.add(lines);

            }
        }
    },
    drawTransientSource({
                            scene, box, visible, solution = false, getMaterial, objects, intersects, current_project,
                            arrowPosition = false
                        }) {
        let x0 = box.x_calc + box.dx_calc / 2,
            y0 = box.y_calc + box.dy_calc / 2,
            z0 = box.z_calc + box.dz_calc / 2,
            geometry;

        const {material, line_material} = arrowPosition ? {
            material: movingObjMaterial,
            line_material: null
        } : getMaterial(box.color, box.hole ? 0.2 : 1);

        switch (box.plane) {
            case 'XZ':
                geometry = new THREE.PlaneGeometry(box.dx_calc, box.dz_calc);
                geometry.rotateX(Math.PI / 2);
                break;
            case 'YZ':
                geometry = new THREE.PlaneGeometry(box.dy_calc, box.dz_calc);
                geometry.rotateY(Math.PI / 2);
                break;
            default:
                geometry = new THREE.PlaneGeometry(box.dx_calc, box.dy_calc);
                break;
        }

        const transientSource = new THREE.Mesh(geometry, material);
        if (arrowPosition) {
            transientSource.position.set(x0 - arrowPosition.x, y0 - arrowPosition.y, z0 - arrowPosition.z);
            scene.add(transientSource);
        } else if (visible && box.visible && !solution) {
            transientSource.position.set(x0, y0, z0);
            transientSource.userData = box;

            scene.add(transientSource);
            objects.push(transientSource);
            intersects.push(transientSource);

            if (current_project && (visible && box.visible || current_project.world.show_outline)) {
                let edges = new THREE.EdgesGeometry(geometry);
                let lines = new THREE.LineSegments(edges, line_material);
                lines.raycast = null;
                lines.position.set(x0, y0, z0);

                scene.add(lines);

            }
        }
    },
    drawPCB({
                scene, box, visible, solution = false, getMaterial, objects, intersects, current_project,
                arrowPosition = false
            }) {
        let x0 = +box.x_calc + box.dx_calc / 2,
            y0 = +box.y_calc + box.dy_calc / 2,
            z0 = +box.z_calc + box.dz_calc / 2;

        const {material, line_material} = arrowPosition ? {
            material: movingObjMaterial,
            line_material: null
        } : getMaterial(box.color, box.hole ? 0.2 : 1);

        if (arrowPosition) {
            let z = +box.z_calc;
            box.layers.slice().reverse().forEach((layer, index) => {
                if (layer['thickness_calc']) {
                    const geometry = new THREE.BoxBufferGeometry(+box.dx_calc, +box.dy_calc, +layer['thickness_calc'] / 1e3),
                        cube = new THREE.Mesh(geometry, [
                            material, material,
                            material, material,
                            (index < box.layers.length - 1) ? materialTransparent : material,
                            (index !== 0) ? materialTransparent : material,
                        ]);

                    cube.position.set(x0 - arrowPosition.x, y0 - arrowPosition.y, (z + layer['thickness_calc'] / 1e3 / 2) - arrowPosition.z);
                    scene.add(cube);

                    z += (+layer['thickness_calc'] / 1e3);
                }

            });
        } else if (visible && box.visible || current_project && current_project.world.show_outline) {
            const pcbGroup = new THREE.Group;

            let z = +box.z_calc;
            box.layers.slice().reverse().forEach((layer, index) => {
                if (layer['thickness_calc']) {
                    const geometry = new THREE.BoxBufferGeometry(+box.dx_calc, +box.dy_calc, +layer['thickness_calc'] / 1e3);
                    if (visible && box.visible && !solution) {
                        const {material, line_material} = getMaterial(layer.material_color, 1),
                            cube = new THREE.Mesh(geometry, [
                                material, material,
                                material, material,
                                (index < box.layers.length - 1) ? materialTransparent : material,
                                (index !== 0) ? materialTransparent : material,
                            ]);

                        cube.position.set(x0, y0, z + layer['thickness_calc'] / 1e3 / 2);
                        cube.userData = box;
                        pcbGroup.add(cube);
                        objects.push(cube);
                    }
                    // draws cube edges
                    let edges = new THREE.EdgesGeometry(geometry),
                        lines = new THREE.LineSegments(edges, line_material);
                    lines.raycast = null;
                    lines.position.set(x0, y0, z + layer['thickness_calc'] / 1e3 / 2);
                    scene.add(lines);
                    z += +layer['thickness_calc'] / 1e3;
                }

            });

            scene.add(pcbGroup);
            intersects.push(pcbGroup);
        }
    },
    drawHeatsink({
                     scene, box, visible, solution = false, getMaterial, objects, intersects, current_project,
                     arrowPosition = false
                 }) {
        let x0 = +box.x_calc + box['base_dx_calc'] / 2,
            y0 = +box.y_calc + box['base_dy_calc'] / 2,
            z0 = +box.z_calc,
            hs, edges, mergedGeometry;

        const {material, line_material} = arrowPosition ? {
            material: movingObjMaterial,
            line_material: null
        } : getMaterial(box.color, box.hole ? 0.2 : 1);

        const geometries = [];
        const base = new THREE.BoxBufferGeometry(+box['base_dx_calc'], +box['base_dy_calc'], +box['base_dz_calc']);

        if (arrowPosition) {
            base.applyMatrix4(new THREE.Matrix4().makeTranslation(x0 - arrowPosition.x, y0 - arrowPosition.y, (z0 + (+box['base_dz_calc'] / 2 - arrowPosition.z))));
            geometries.push(base);
            if (box.fin_axis === 'X') {
                let fin_pitch = (+box['fin_count_calc'] > 1) ? (+box['base_dy_calc'] - (+box['fin_thickness_calc'])) / (+box['fin_count_calc'] - 1) : 1,
                    fin_extra = (+box['fin_count_calc'] > 1) ? 0 : (+box['base_dy_calc'] - (+box['fin_thickness_calc'])) / 2;

                for (let y = fin_extra + box['fin_thickness_calc'] / 2; y <= +box['base_dy_calc']; y += fin_pitch) {
                    let fin = new THREE.BoxBufferGeometry(+box['base_dx_calc'], +box['fin_thickness_calc'], +box['fin_height_calc']);
                    fin.applyMatrix4(new THREE.Matrix4().makeTranslation(x0 - arrowPosition.x, +box.y_calc + y - arrowPosition.y, (z0 + (+box['base_dz_calc']) + (box['fin_height_calc'] / 2) - arrowPosition.z)));
                    geometries.push(fin);
                }
            } else {
                let fin_pitch = (+box['fin_count_calc'] > 1) ? (+box['base_dx_calc'] - (+box['fin_thickness_calc'])) / (+box['fin_count_calc'] - 1) : 1,
                    fin_extra = (+box['fin_count_calc'] > 1) ? 0 : (+box['base_dx_calc'] - (+box['fin_thickness_calc'])) / 2;

                for (let x = fin_extra + box['fin_thickness_calc'] / 2; x <= +box['base_dx_calc']; x += fin_pitch) {
                    let fin = new THREE.BoxBufferGeometry(+box['fin_thickness_calc'], +box['base_dy_calc'], +box['fin_height_calc']);
                    fin.applyMatrix4(new THREE.Matrix4().makeTranslation(+box.x_calc + x - arrowPosition.x, y0 - arrowPosition.y, (z0 + (+box['base_dz_calc']) + (box['fin_height_calc'] / 2) - arrowPosition.z)));
                    geometries.push(fin);
                }
            }

            mergedGeometry = BufferGeometryUtils.mergeBufferGeometries(
                geometries, false);
            hs = new THREE.Mesh(mergedGeometry, material);
            scene.add(hs);
        } else {
            base.applyMatrix4(new THREE.Matrix4().makeTranslation(x0, y0, z0 + (+box['base_dz_calc']) / 2));
            geometries.push(base);
            if (box.fin_axis === 'X') {
                let fin_pitch = (+box['fin_count_calc'] > 1) ? (+box['base_dy_calc'] - (+box['fin_thickness_calc'])) / (+box['fin_count_calc'] - 1) : 1,
                    fin_extra = (+box['fin_count_calc'] > 1) ? 0 : (+box['base_dy_calc'] - (+box['fin_thickness_calc'])) / 2;

                for (let y = fin_extra + box['fin_thickness_calc'] / 2; y <= +box['base_dy_calc']; y += fin_pitch) {
                    let fin = new THREE.BoxBufferGeometry(+box['base_dx_calc'], +box['fin_thickness_calc'], +box['fin_height_calc']);
                    fin.applyMatrix4(new THREE.Matrix4().makeTranslation(x0, +box.y_calc + y, z0 + (+box['base_dz_calc']) + (+box['fin_height_calc']) / 2));
                    geometries.push(fin);
                }
            } else {
                let fin_pitch = (+box['fin_count_calc'] > 1) ? (+box['base_dx_calc'] - (+box['fin_thickness_calc'])) / (+box['fin_count_calc'] - 1) : 1,
                    fin_extra = (+box['fin_count_calc'] > 1) ? 0 : (+box['base_dx_calc'] - (+box['fin_thickness_calc'])) / 2;

                for (let x = fin_extra + box['fin_thickness_calc'] / 2; x <= +box['base_dx_calc']; x += fin_pitch) {
                    let fin = new THREE.BoxBufferGeometry(+box['fin_thickness_calc'], +box['base_dy_calc'], +box['fin_height_calc']);
                    fin.applyMatrix4(new THREE.Matrix4().makeTranslation(+box.x_calc + x, y0, z0 + (+box['base_dz_calc']) + (+box['fin_height_calc']) / 2));
                    geometries.push(fin);
                }
            }

            mergedGeometry = BufferGeometryUtils.mergeBufferGeometries(
                geometries, false);

            if (visible && box.visible && !solution) {
                hs = new THREE.Mesh(mergedGeometry, material);
                hs.userData = box;
                scene.add(hs);
                objects.push(hs);
                intersects.push(hs);
            }

            edges = new THREE.EdgesGeometry(mergedGeometry);
        }

        if (visible && box.visible || current_project && current_project.world.show_outline) {
            let lines = new THREE.LineSegments(edges, line_material);
            lines.raycast = null;
            scene.add(lines);
        }
    },
    drawBGA({
                scene, box, visible, solution = false, getMaterial, objects, intersects,
                current_project,
                arrowPosition = false
            }) {
        let x0 = +box.x_calc + box.dx_calc / 2 - (box.inline ? (+box['xpitch_calc'] / 1e3) : (+box['xpitch_calc'] / 1e3 / 2)) * (+box['xcount_calc'] - 1) / 2,
            y0 = +box.y_calc + box.dy_calc / 2 - (box.inline ? (+box['ypitch_calc'] / 1e3) : (+box['ypitch_calc'] / 1e3 / 2)) * (+box['ycount_calc'] - 1) / 2,
            z0 = +box.z_calc + box.dz_calc / 2,
            ratio = Math.max(1, Math.sqrt(box['xcount_calc'] * box['ycount_calc'] / maxArrayObjects)),
            x_count = Math.ceil(+box['xcount_calc'] / ratio),
            y_count = Math.ceil(+box['ycount_calc'] / ratio),
            x_pitch = +box['xpitch_calc'] * +box['xcount_calc'] / x_count,
            y_pitch = +box['ypitch_calc'] * +box['ycount_calc'] / y_count,
            outline, sphereArray, bga, fill;

        const {material, line_material} = arrowPosition ? {
            material: movingObjMaterial,
            line_material: null
        } : getMaterial(box.color, box.hole ? 0.2 : 1);

        if (arrowPosition) {
            sphereArray = new SphereArrayGeometry(
                x0 - arrowPosition.x, y0 - arrowPosition.y, z0 - arrowPosition.z,
                x_count, y_count, 1,
                x_pitch / 1e3, y_pitch / 1e3, 1,
                box.inline,
                box['diameter_calc'] / 2 / 1e3, 10, 5);

            bga = new THREE.Mesh(sphereArray, material);
            scene.add(bga);

            if (box.fill_material) {
                const fill_geometry = new THREE.BoxBufferGeometry(+box.dx_calc, +box.dy_calc, +box.dz_calc),
                    x0 = +box.x_calc + box.dx_calc / 2,
                    y0 = +box.y_calc + box.dy_calc / 2,
                    z0 = +box.z_calc + box.dz_calc / 2;
                fill_geometry.applyMatrix4(new THREE.Matrix4().makeTranslation(x0 - arrowPosition.x, y0 - arrowPosition.y, z0 - arrowPosition.z));
                let fill = new THREE.Mesh(fill_geometry, material);
                fill.userData = box;
                scene.add(fill);
            }

            return;
        }

        if (visible && box.visible) {
            if (!solution) {
                sphereArray = new SphereArrayGeometry(
                    x0, y0, z0,
                    x_count, y_count, 1,
                    x_pitch / 1e3, y_pitch / 1e3, 1,
                    box.inline,
                    box['diameter_calc'] / 2 / 1e3, 10, 5);

                bga = new THREE.Mesh(sphereArray, material);
                bga.userData = box;
                scene.add(bga);
                objects.push(bga);
                intersects.push(bga);

                if (box.fill_material) {
                    const fill_material = getMaterial(box['fill_color'], 0.5);

                    const fill_geometry = new THREE.BoxBufferGeometry(+box.dx_calc, +box.dy_calc, +box.dz_calc);
                    x0 = +box.x_calc + box.dx_calc / 2;
                    y0 = +box.y_calc + box.dy_calc / 2;
                    z0 = +box.z_calc + box.dz_calc / 2;

                    fill_geometry.applyMatrix4(new THREE.Matrix4().makeTranslation(x0, y0, z0));
                    fill = new THREE.Mesh(fill_geometry, fill_material);
                    fill.userData = box;
                    scene.add(fill);
                    objects.push(fill);
                    intersects.push(fill);
                }
            }
        } else if ((!visible || !box.visible) && current_project && current_project.world.show_outline) {
            outline = new THREE.BoxBufferGeometry(+box.dx_calc, +box.dy_calc, +box.dz_calc);
            x0 = +box.x_calc + box.dx_calc / 2;
            y0 = +box.y_calc + box.dy_calc / 2;
            z0 = +box.z_calc + box.dz_calc / 2;
            outline.applyMatrix4(new THREE.Matrix4().makeTranslation(x0, y0, z0));
            let edges = new THREE.EdgesGeometry(outline),
                lines = new THREE.LineSegments(edges, line_material);
            lines.raycast = null;
            scene.add(lines);
        }
    },
    drawViaArray({
                     scene, box, visible, solution = false, getMaterial, objects, intersects,
                     current_project,
                     arrowPosition = false
                 }) {
        let x0 = +box.x_calc + box.dx_calc / 2 - +box['xpitch_calc'] / 1e3 * (+box['xcount_calc'] - 1) / 2,
            y0 = +box.y_calc + box.dy_calc / 2 - +box['ypitch_calc'] / 1e3 * (+box['ycount_calc'] - 1) / 2,
            z0 = +box.z_calc + box.dz_calc / 2,
            ratio = Math.max(1, Math.sqrt(+box['xcount_calc'] * (+box['ycount_calc']) / maxArrayObjects)),
            x_count = Math.ceil(+box['xcount_calc'] / ratio),
            y_count = Math.ceil(+box['ycount_calc'] / ratio),
            x_pitch = +box['xpitch_calc'] * (+box['xcount_calc']) / x_count,
            y_pitch = +box['ypitch_calc'] * (+box['ycount_calc']) / y_count,
            outline, lines, edges, viaArray, via, fill_geometry,
            fill;

        const {material, line_material} = arrowPosition ? {
            material: movingObjMaterial,
            line_material: null
        } : getMaterial(box.color, box.hole ? 0.2 : 1);

        if (
            ((visible && box.visible) || arrowPosition) &&
            box.fill_material
        ) {
            fill_geometry = new THREE.BoxBufferGeometry(+box.dx_calc, +box.dy_calc, +box.dz_calc);
        }

        if (arrowPosition) {
            viaArray = new CylinderArrayGeometry(
                0, 0, 0,
                x_count, 1, y_count,
                x_pitch / 1e3, 1, y_pitch / 1e3,
                +box['diameter_calc'] / 2 / 1e3, +box['diameter_calc'] / 2 / 1e3, +box.dz_calc, 10);

            via = new THREE.Mesh(viaArray, material);
            via.rotateX(-Math.PI / 2);
            via.position.set(x0 - arrowPosition.x, y0 - arrowPosition.y, z0 - arrowPosition.z);
            scene.add(via);

            if (box.fill_material) {
                x0 = +box.x_calc + box.dx_calc / 2;
                y0 = +box.y_calc + box.dy_calc / 2;
                z0 = +box.z_calc + box.dz_calc / 2;

                fill_geometry.applyMatrix4(new THREE.Matrix4().makeTranslation(x0 - arrowPosition.x, y0 - arrowPosition.y, z0 - arrowPosition.z));
                fill = new THREE.Mesh(fill_geometry, material);
                fill.userData = box;
                scene.add(fill);
            }
        } else if (visible && box.visible) {
            if (!solution) {
                viaArray = new CylinderArrayGeometry(
                    0, 0, 0,
                    x_count, 1, y_count,
                    x_pitch / 1e3, 1, y_pitch / 1e3,
                    +box['diameter_calc'] / 2 / 1e3, +box['diameter_calc'] / 2 / 1e3, +box.dz_calc, 10);

                via = new THREE.Mesh(viaArray, material);
                via.rotateX(-Math.PI / 2);
                via.position.set(x0, y0, z0);
                via.userData = box;
                scene.add(via);
                objects.push(via);
                intersects.push(via);


                if (box.fill_material) {
                    x0 = +box.x_calc + box.dx_calc / 2;
                    y0 = +box.y_calc + box.dy_calc / 2;
                    z0 = +box.z_calc + box.dz_calc / 2;

                    const fill_material = getMaterial(box['fill_color'], 0.5);
                    fill_geometry.applyMatrix4(new THREE.Matrix4().makeTranslation(x0, y0, z0));

                    fill = new THREE.Mesh(fill_geometry, fill_material);
                    fill.userData = box;
                    scene.add(fill);
                    objects.push(fill);
                    intersects.push(fill);
                }
            }
        } else if ((!visible || !box.visible) && current_project && current_project.world.show_outline) {
            x0 = +box.x_calc + box.dx_calc / 2;
            y0 = +box.y_calc + box.dy_calc / 2;
            z0 = +box.z_calc + box.dz_calc / 2;

            outline = new THREE.BoxBufferGeometry(+box.dx_calc, +box.dy_calc, +box.dz_calc);
            outline.applyMatrix4(new THREE.Matrix4().makeTranslation(x0, y0, z0));

            edges = new THREE.EdgesGeometry(outline);
            lines = new THREE.LineSegments(edges, line_material);
            lines.raycast = null;
            scene.add(lines);
        }
    },
    drawPlane({
                  scene, box, visible, solution = false, getMaterial, objects, intersects,
                  current_project, worldPositions,
                  arrowPosition = false
              }) {
        let obj, mesh, material;

        if (arrowPosition) {
            switch (box.plane) {
                case 'XY':
                    obj = new THREE.PlaneBufferGeometry(worldPositions.wx1 - worldPositions.wx0 + 2, worldPositions.wy1 - worldPositions.wy0 + 2);
                    mesh = new THREE.Mesh(obj, movingPlaneMaterial);
                    mesh.position.set(((worldPositions.wx0 + worldPositions.wx1) / 2) - arrowPosition.x, ((worldPositions.wy0 + worldPositions.wy1) / 2) - arrowPosition.y, +box.coordinate - arrowPosition.z);
                    break;
                case 'XZ':
                    obj = new THREE.PlaneBufferGeometry(worldPositions.wx1 - worldPositions.wx0 + 2, worldPositions.wz1 - worldPositions.wz0 + 2);
                    mesh = new THREE.Mesh(obj, movingPlaneMaterial);
                    mesh.rotateX(Math.PI / 2);
                    mesh.position.set(((worldPositions.wx0 + worldPositions.wx1) / 2) - arrowPosition.x, +box.coordinate - arrowPosition.y, ((worldPositions.wz0 + worldPositions.wz1) / 2) - arrowPosition.z);

                    break;
                case 'YZ':
                    obj = new THREE.PlaneBufferGeometry(worldPositions.wy1 - worldPositions.wy0 + 2, worldPositions.wz1 - worldPositions.wz0 + 2);
                    mesh = new THREE.Mesh(obj, movingPlaneMaterial);
                    mesh.rotateX(Math.PI / 2);
                    mesh.rotateY(Math.PI / 2);
                    mesh.position.set(+box.coordinate - arrowPosition.x, ((worldPositions.wy0 + worldPositions.wy1) / 2) - arrowPosition.y, ((worldPositions.wz0 + worldPositions.wz1) / 2) - arrowPosition.z);

                    break;
                default:
                    console.log('Unknown plane', box.plane);
            }

            if (obj && mesh) {
                scene.add(mesh);
            }
        } else {
            material = getMaterial;

            switch (box.plane) {
                case 'XY':
                    obj = new THREE.PlaneBufferGeometry(worldPositions.wx1 - worldPositions.wx0 + 2, worldPositions.wy1 - worldPositions.wy0 + 2);
                    mesh = new THREE.Mesh(obj, material);
                    mesh.position.set((worldPositions.wx0 + worldPositions.wx1) / 2, (worldPositions.wy0 + worldPositions.wy1) / 2, +box.coordinate);

                    break;
                case 'XZ':
                    obj = new THREE.PlaneBufferGeometry(worldPositions.wx1 - worldPositions.wx0 + 2, worldPositions.wz1 - worldPositions.wz0 + 2);
                    mesh = new THREE.Mesh(obj, material);
                    mesh.rotateX(Math.PI / 2);
                    mesh.position.set((worldPositions.wx0 + worldPositions.wx1) / 2, +box.coordinate, (worldPositions.wz0 + worldPositions.wz1) / 2);

                    break;
                case 'YZ':
                    obj = new THREE.PlaneBufferGeometry(worldPositions.wy1 - worldPositions.wy0 + 2, worldPositions.wz1 - worldPositions.wz0 + 2);
                    mesh = new THREE.Mesh(obj, material);
                    mesh.rotateX(Math.PI / 2);
                    mesh.rotateY(Math.PI / 2);
                    mesh.position.set(+box.coordinate, (worldPositions.wy0 + worldPositions.wy1) / 2, (worldPositions.wz0 + worldPositions.wz1) / 2);

                    break;
                default:
                    console.log('Unknown plane', box.plane);
            }

            if (obj && mesh) {
                mesh.userData = box;
                scene.add(mesh);
                objects.push(mesh);
            }
        }
    },
    drawObjects({type, scene, box, arrowPosition}) {
        switch (type) {
            case 'box':
                drawObjectUtils.drawBox({scene, box, arrowPosition});
                break;
            case 'polygon' :
                drawObjectUtils.drawPolygon({scene, box, arrowPosition});
                break;
            case 'cylinder' :
                drawObjectUtils.drawCylinder({scene, box, arrowPosition});
                break;
            case 'pcb' :
                drawObjectUtils.drawPCB({scene, box, arrowPosition});
                break;
            case 'heatsink' :
                drawObjectUtils.drawHeatsink({scene, box, arrowPosition});
                break;
            case 'ball_array' :
                drawObjectUtils.drawBox({scene, box, arrowPosition});
                // drawObjectUtils.drawBGA({scene, box, arrowPosition});
                break;
            case 'via_array' :
                drawObjectUtils.drawBox({scene, box, arrowPosition});
                // drawObjectUtils.drawViaArray({scene, box, arrowPosition});
                break;
            case 'source' :
                drawObjectUtils.drawSource({scene, box, arrowPosition});
                break;
            case 'transient_source' :
                drawObjectUtils.drawTransientSource({scene, box, arrowPosition});
                break;
            case 'plane' :
                drawObjectUtils.drawPlane({scene, box, arrowPosition});
                break;

        }
    },


    // can not get correct scale for Sprite, not drawing!!!

    drawSprite({scene, intersect, sprites, spriteText, textSized: {textHeight, fontFace, fontWeight,}}) {
        let focus = new THREE.Vector3(),
            text = new THREE.Vector3(),
            sprite = new SpriteText(spriteText);

        focus.copy(intersect.point);
        text.copy(intersect.point);
        text.add(intersect.face.normal.multiplyScalar(20));

        sprite.backgroundColor = 'rgba(255, 255, 255, 0.5)';
        sprite.material.sizeAttenuation = false;
        sprite.textHeight = textHeight / 3;
        sprite.position.copy(text);
        sprite.color = 'black';
        sprite.fontWeight = fontWeight;
        sprite.fontFace = fontFace;
        sprite.fontSize = 120;

        const geometry = new THREE.BufferGeometry().setFromPoints([focus, text]);
        const line = new THREE.Line(geometry, new THREE.LineBasicMaterial({color: 0x555555}));

        scene.add(sprite, line);
        sprites.push({sprite, line});
        scene.updateMatrixWorld();
    },

};

export const projectUtils = {
    parseTree({tree, project}) {
        return {
            // name: project && project.name,
            children: tree,
        };
    },
    findNode(element, node_id) {
        if (element.id === node_id) {
            return element;
        } else if (element.children != null) {
            let result = null;
            for (let i = 0; result == null && i < element.children.length; i++) {
                result = this.findNode(element.children[i], node_id);
            }
            return result;
        }
        return null;
    },
    findParent(element, parent, node_id) {
        if (element.id === node_id) {
            return parent;
        } else if (element.children != null) {
            let result = null;
            for (let i = 0; result == null && i < element.children.length; i++) {
                result = this.findParent(element.children[i], element, node_id);
            }
            return result;
        }
        return null;
    },
    updateNodeVisibility(treeData, node) {
        return treeData.map(currentNode => {
            if (currentNode.parent === node.parent) {
                if (currentNode.id === node.id && currentNode.index === node.index && currentNode.type === node.type) {
                    return node;
                }

                if (currentNode.hasOwnProperty('children')) {
                    return {
                        ...currentNode,
                        children: this.updateNodeVisibility(currentNode.children, node),
                    };
                }
            }

            return currentNode;
        });
    },
    updateTreeIndexes({treeData, movedNodeId, oldParentId, newParentId}) {
        return treeData.map((node, idx) => {
            if (node.parent === oldParentId || node.parent === newParentId) {
                node.index = idx;
            }

            if (node.id === movedNodeId) {
                node.parent = newParentId;
            }

            if (node.hasOwnProperty('children')) {
                return {
                    ...node,
                    children: this.updateTreeIndexes(
                        {
                            treeData: node.children,
                            movedNodeId,
                            oldParentId,
                            newParentId,
                        }),
                };
            }

            return node;
        });
    },
    // addClonedNodeUnder(tree, node) {
    //     const clonedNodeIndex = node.index + 1;
    //     const updatedClonedNode = {
    //         ...node,
    //         index: clonedNodeIndex,
    //     };
    //     const parent = {...projectUtils.findParent(tree, null, node.id)};
    //
    //     parent.children.splice(clonedNodeIndex, 0, updatedClonedNode);
    //     parent.children = parent.children.map((child, idx) => ({...child, index: idx}));
    //
    //     return projectUtils.updateTree(tree.children, parent);
    // },
    updatedTreeAfterDeleteNode(tree, node) {
        const parent = {...projectUtils.findParent({name: '', children: tree}, null, node.id)};

        parent.children.splice(node.index, 1);
        parent.children = parent.children.map((child, idx) => ({...child, index: idx}));

        return projectUtils.updateParentChildren(tree, parent);
    },
    updateParentChildren(treeData, updatedParent) {
        return treeData.map(treeNode => {
            if (treeNode.id === updatedParent.id) {
                treeNode.children = updatedParent.children;
                return treeNode;
            }

            if (treeNode.hasOwnProperty('children')) {
                this.updateParentChildren(treeNode.children, updatedParent);
            }

            return treeNode;
        });
    },
    shouldUpdateTreeWithPayloadData(currentData, payLoadData) {
        if (!currentData || currentData.length !== payLoadData.length) {
            return true;
        } else {
            for (let i = 0; i < currentData.length; i++) {
                const payloadNode = payLoadData.find(currentPayloadNode => currentPayloadNode.id === currentData[i].id && currentPayloadNode.index === currentData[i].index && currentPayloadNode.type === currentData[i].type);

                if (currentData[i].id !== payloadNode?.id && currentData[i]?.index !== payloadNode?.index && currentData[i]?.type !== payloadNode?.type) {
                    return true;
                }

                const {timestamp: currentDataTimestamp, ...currentDataRest} = currentData[i];
                const {timestamp: payloadNodeTimestamp, ...payloadNodeRest} = payloadNode;

                if (!_.isEqual(currentDataRest, payloadNodeRest)) {
                    if (currentDataRest.hasOwnProperty('layers') && payloadNodeRest.hasOwnProperty('layers')) {
                        const {
                            timestamp: currentDataRestTimestamp,
                            layers: currentDataLayers,
                            ...restCurrentData
                        } = currentDataRest;
                        const {
                            timestamp: payloadNodeRestTimestamp,
                            layers: payloadNodeLayers,
                            ...restPayloadNode
                        } = payloadNodeRest;

                        if (!_.isEqual(restCurrentData, restPayloadNode)) {
                            return true;
                        }

                        if (currentDataLayers.length && payloadNodeLayers.length && currentDataLayers.length !== payloadNodeLayers.length) {
                            return true;
                        }

                        for (let layerIdx = 0; layerIdx < currentDataLayers.length; layerIdx++) {
                            const {
                                timestamp: currentDataLayerTimeStamp, ...restCurrentDataLayersProps
                            } = currentDataLayers[layerIdx];
                            const {
                                timestamp: payloadNodeLayerTimeStamp, ...restPayloadNodeProps
                            } = payloadNodeLayers[layerIdx];

                            if (!_.isEqual(restCurrentDataLayersProps, restPayloadNodeProps)) {
                                return true;
                            }
                        }
                    } else {
                        return true;
                    }
                }
                if (currentData[i].hasOwnProperty('children')) {
                    if (this.shouldUpdateTreeWithPayloadData(currentData[i].children, payloadNode.children))
                        return true;
                }
            }
        }
        return false;
    },
    getDragPlaneMesh({
                         size,
                         material,
                         rotate = false,
                         userDataKey = 'planeAxis',
                         userDataValue,
                         position,
                     }) {
        const dragPlaneGeometry = new THREE.PlaneGeometry(size.width, size.height);
        const dragPlaneMesh = new THREE.Mesh(dragPlaneGeometry, material);
        dragPlaneMesh.userData = {[userDataKey]: userDataValue};
        dragPlaneMesh.position.set(position.x, position.y, position.z);

        if (rotate) {
            dragPlaneMesh.rotation[rotate.axis] = rotate.value;
        }

        return dragPlaneMesh;
    },
    getClippingLine({material, vectorPoints, userDataKey = 'clipLineAxes', userDataValue}) {
        const lineMaterial = material || new THREE.LineBasicMaterial({color: 0xd0d0d0});
        const points = [];
        points.push(new THREE.Vector3(vectorPoints.start.x, vectorPoints.start.y, vectorPoints.start.z));
        points.push(new THREE.Vector3(vectorPoints.end.x, vectorPoints.end.y, vectorPoints.end.z));

        const lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
        const line = new THREE.Line(lineGeometry, lineMaterial);

        if (userDataValue) {
            line.userData = {[userDataKey]: userDataValue};
        }

        return line;
    },
    getTreeTimestamp(tree) {
        let d = null;
        tree.forEach(obj => {
            if (d === null) d = new Date(obj.timestamp);
            let tmp;
            if (obj.children) tmp = this.getTreeTimestamp(obj.children); else tmp = new Date(obj.timestamp);
            if (tmp && tmp > d) d = tmp;
        });
        return d;
    },
    toggleProjectTableTree(tableTree, node) {
        const trackNode = {isFound: false};

        return this.cycleTableNodes(tableTree, node, trackNode);
    },
    cycleTableNodes(tableTree, node, trackNode) {
        return tableTree.map(currentNode => {
            if (trackNode.isFound) {
                return currentNode;
            }

            if (currentNode.id === node.id) {
                currentNode.collapsed = !node.collapsed;
                trackNode.isFound = true;
            } else if (currentNode.hasOwnProperty('children')) {
                return {
                    ...currentNode,
                    children: this.cycleTableNodes(currentNode.children, node, trackNode),
                };
            }

            return currentNode;
        });
    },
    stopPreventDefault(event) {
        event.preventDefault();
        event.stopPropagation();
    },
    updateStorageTaskStatus({taskId, status, deleteStatus = false}) {
        const storedStatus = JSON.parse(localStorage.getItem('solveStatus'));

        if (deleteStatus) {
            delete storedStatus[taskId];
        } else {
            storedStatus[taskId] = status;
        }

        // if (status === 'SUCCESS' || status === 'FAILURE') {
        //     delete storedStatus[taskId];
        // }

        localStorage.setItem('solveStatus', JSON.stringify(storedStatus));
    },
    updateSettingsProps({fields, props, defaultState = {}}) {
        const newProps = {};
        fields.forEach(field_name => {
            if (field_name === 'polygon') {
                newProps[field_name] = props.polygon.replace('POLYGON ((', '').replace('))', '');
            } else {
                if (props.hasOwnProperty(field_name)) {
                    newProps[field_name] = props[field_name];
                } else {
                    newProps[field_name] = defaultState[field_name];
                }
            }
        });

        return newProps;
    },
    sourceDimensions({state}) {
        return [
            state.plane !== 'YZ' && 'dx',
            state.plane !== 'XZ' && 'dy',
            state.plane !== 'XY' && 'dz'
        ].filter(Boolean);
    }
};

export const boxMovementUtils = {
    createBoxMovementAxes(selectedObj, position, assemblyElements = false, worldPositions = false, scale) {
        const {x, y, z} = selectedObj;
        const canDragOnX = (!isNaN(x) && !isNaN(parseFloat(x))) || selectedObj.type === 'assembly' || (selectedObj.type === 'plane' && selectedObj.plane === 'YZ');
        const canDragOnY = (!isNaN(y) && !isNaN(parseFloat(y))) || selectedObj.type === 'assembly' || (selectedObj.type === 'plane' && selectedObj.plane === 'XZ');
        const canDragOnZ = (!isNaN(z) && !isNaN(parseFloat(z))) || selectedObj.type === 'assembly' || (selectedObj.type === 'plane' && selectedObj.plane === 'XY');

        const axesArrowsGroup = new THREE.Group;

        const xArrow = this.drawAxisArrow({
            position, canDrag: canDragOnX,
            pointPositionAxis: 'y',
            shaftRotationAxis: 'z',
            shaftRotationValue: -Math.PI / 2
        });
        const yArrow = this.drawAxisArrow({
            axis: 'y', position, canDrag: canDragOnY,
            pointPositionAxis: 'y',
        });
        const zArrow = this.drawAxisArrow({
            axis: 'z', position, canDrag: canDragOnZ,
            pointPositionAxis: 'y',
            shaftRotationAxis: 'x',
            shaftRotationValue: Math.PI / 2
        });

        xArrow.scale.set(scale, scale, scale);
        yArrow.scale.set(scale, scale, scale);
        zArrow.scale.set(scale, scale, scale);

        const movingObj = new THREE.Group;

        // draw shadow/reference object by selectedObject type
        switch (selectedObj.type) {
            case 'assembly':
                Object.values(assemblyElements).forEach(box => typeof box === 'object' && box.visible && drawObjectUtils.drawObjects({
                    type: box.type, scene: movingObj, box, arrowPosition: position,
                }));
                break;
            case 'plane':
                drawObjectUtils.drawPlane({
                    type: selectedObj.type,
                    scene: movingObj,
                    box: selectedObj,
                    arrowPosition: position,
                    worldPositions,
                });
                break;
            default:
                drawObjectUtils.drawObjects({
                    type: selectedObj.type, scene: movingObj, box: selectedObj, arrowPosition: position,
                });
        }


        // rotate and set position by selectedObject type
        switch (selectedObj.type) {
            case 'plane':
                let mirroredAxis, possibleMovementAxis;
                switch (selectedObj.plane) {
                    case 'YZ':
                        possibleMovementAxis = xArrow;
                        mirroredAxis = xArrow.clone();
                        mirroredAxis.rotation.z = Math.PI / 2;

                        break;
                    case 'XZ':
                        possibleMovementAxis = yArrow;
                        mirroredAxis = yArrow.clone();
                        mirroredAxis.rotation.x = Math.PI;

                        break;
                    case 'XY':
                        possibleMovementAxis = zArrow;
                        mirroredAxis = zArrow.clone();
                        mirroredAxis.rotation.x = -Math.PI / 2;

                        break;
                }

                axesArrowsGroup.add(possibleMovementAxis, mirroredAxis);
                break;
            default:
                const {planeMesh: yzPlane, line0: yzLine0, line1: yzLine1} = this.drawAxisPlane({
                    axis: 'yz',
                    position,
                    canDrag: canDragOnY && canDragOnZ,
                }); // green
                const {planeMesh: xzPlane, line0: xzLine0, line1: xzLine1} = this.drawAxisPlane({
                    axis: 'xz',
                    position,
                    canDrag: canDragOnX && canDragOnZ,
                }); // blue
                const {planeMesh: xyPlane, line0: xyLine0, line1: xyLine1} = this.drawAxisPlane({
                    position,
                    canDrag: canDragOnX && canDragOnY,
                }); // red

                yzPlane.rotation.y = -Math.PI / 2; // green
                yzLine0.rotation.y = -Math.PI / 2;
                yzLine1.rotation.y = -Math.PI / 2;

                xzPlane.rotation.x = Math.PI / 2; // blue
                xzLine0.rotation.x = Math.PI / 2;
                xzLine1.rotation.x = Math.PI / 2;

                yzPlane.scale.set(scale, scale, scale);
                yzLine0.scale.set(scale, scale, scale);
                yzLine1.scale.set(scale, scale, scale);

                xzPlane.scale.set(scale, scale, scale);
                xzLine0.scale.set(scale, scale, scale);
                xzLine1.scale.set(scale, scale, scale);

                xyPlane.scale.set(scale, scale, scale);
                xyLine0.scale.set(scale, scale, scale);
                xyLine1.scale.set(scale, scale, scale);

                axesArrowsGroup.add(
                    xArrow, yArrow, zArrow,
                    yzPlane, yzLine0, yzLine1,
                    xzPlane, xzLine0, xzLine1,
                    xyPlane, xyLine0, xyLine1);
        }

        axesArrowsGroup.position.set(position.x, position.y, position.z);
        movingObj.position.set(position.x, position.y, position.z);

        return {axesArrowsGroup, movingObj};
    },
    drawAxisArrow({
                      axis = 'x', position, canDrag, pointPositionAxis,
                      pointPositionValue = axesArrowSettings.shaft.height,
                      shaftRotationAxis, shaftRotationValue
                  }) {
        const shaftMesh = new THREE.Mesh( // We want to ensure our arrow is completely offset into one direction, So the translation ensure every bit of it is in Y+
            new THREE.CylinderGeometry(axesArrowSettings.shaft.radius, axesArrowSettings.shaft.radius, axesArrowSettings.shaft.height).translate(0, axesArrowSettings.shaft.height / 2, 0),
            getMaterialAxes({color: axesArrowSettings.colors[canDrag ? axis : 'disabledColor']}),
        );
        const pointMesh = new THREE.Mesh(  // Same thing, translate to all vertices or +Y
            new THREE.ConeGeometry(axesArrowSettings.point.radius, axesArrowSettings.point.height, axesArrowSettings.point.segments).translate(0, axesArrowSettings.point.height / 2, 0),
            getMaterialAxes({color: axesArrowSettings.colors[canDrag ? axis : 'disabledColor']}),
        );

        pointMesh.position[pointPositionAxis] = pointPositionValue; // Place the point at the top of the shaft

        if (shaftRotationAxis) {
            shaftMesh.rotation[shaftRotationAxis] = shaftRotationValue; // Point the shaft into the x-direction
        }

        shaftMesh.userData = canDrag ? {axis, position} : {disabled: true};
        pointMesh.userData = canDrag ? {axis, position} : {disabled: true};

        shaftMesh.add(pointMesh);

        return shaftMesh;
    },
    drawAxisPlane({axis = 'xy', position, canDrag}) {
        const widthHeight = axesArrowSettings.shaft.height / 2;

        const planeMesh = new THREE.Mesh(
            new THREE.PlaneBufferGeometry(widthHeight, widthHeight).translate(widthHeight / 2, widthHeight / 2, 0),
            getMaterialAxes({color: axesArrowSettings.colors[canDrag ? axis : 'disabledColor']}),
        );

        const line0 = projectUtils.getClippingLine({
            material: new THREE.LineBasicMaterial({
                color: canDrag ? axesArrowSettings.colors[axis][0] : axesArrowSettings.colors.disabledColor,
                opacity: 0.65
            }),
            vectorPoints: {
                start: {
                    x: 0,
                    y: widthHeight,
                    z: 0,
                },
                end: {
                    x: widthHeight,
                    y: widthHeight,
                    z: 0,
                },
            },
            userDataKey: 'line',
            userDataValue: 'line',
        });

        const line1 = projectUtils.getClippingLine({
            material: new THREE.LineBasicMaterial({
                color: canDrag ? axesArrowSettings.colors[axis][1] : axesArrowSettings.colors.disabledColor,
                opacity: 0.65
            }),
            vectorPoints: {
                start: {
                    x: widthHeight,
                    y: 0,
                    z: 0,
                },
                end: {
                    x: widthHeight,
                    y: widthHeight,
                    z: 0,
                },
            },
            userDataKey: 'line',
            userDataValue: 'line',
        });

        planeMesh.userData = canDrag ? {axis, position} : {disabled: true};

        return {planeMesh, line0, line1};
    },
    getPosition(box, worldPositions) {
        const position = {};

        switch (box.type) {
            case 'heatsink':
                position.x = +box.x_calc + (+box['base_dx_calc'] / 2);
                position.y = +box.y_calc + (+box['base_dy_calc'] / 2);
                position.z = +box.z_calc + (+box['base_dz_calc'] / 2) + ((+box['fin_height_calc'] / 2) || 0);
                break;

            case 'pcb':
                position.x = +box.x_calc + (+box.dx_calc / 2);
                position.y = +box.y_calc + (+box.dy_calc / 2);
                position.z = +box.z_calc;
                break;

            case 'plane':
                let x, y, z;
                switch (box.plane) {
                    case 'XY':
                        x = (worldPositions.wx0 + worldPositions.wx1) / 2;
                        y = (worldPositions.wy0 + worldPositions.wy1) / 2;
                        z = +box.coordinate;
                        break;
                    case 'XZ':
                        x = (worldPositions.wx0 + worldPositions.wx1) / 2;
                        y = +box.coordinate;
                        z = (worldPositions.wz0 + worldPositions.wz1) / 2;
                        break;
                    case 'YZ':
                        x = +box.coordinate;
                        y = (worldPositions.wy0 + worldPositions.wy1) / 2;
                        z = (worldPositions.wz0 + worldPositions.wz1) / 2;
                        break;
                }
                position.x = x;
                position.y = y;
                position.z = z;
                break;
            default : // box, ball_array, cylinder, pcb, polygon, source, via_array
                position.x = +box.x_calc + (+box.dx_calc / 2);
                position.y = +box.y_calc + (+box.dy_calc / 2);
                position.z = +box.z_calc + (+box.dz_calc / 2);
        }

        return position;
    },
    axesHoverState({intersected, opacity = 0.65}) {
        if (intersected) {
            intersected.material.opacity = opacity;

            intersected.children.forEach((child => {
                if (child && child.userData.hasOwnProperty('axis')) {
                    child.material.opacity = opacity;
                }
            }));

            if (intersected.parent.userData.hasOwnProperty('axis')) {
                intersected.parent.material.opacity = opacity;
            }
        }
    },
    getGridSnapPosition({gridSnap, objCoord, delta = 0}) {
        if (gridSnap === 1) {
            return Number(Math.round(+objCoord + +delta).toFixed(0));
        }

        return Number(Math.round((+objCoord + +delta) / (gridSnap)) * gridSnap);
    },
};

export const downloadUtils = {
    // CSV
    addText({items, baseName = '', text}) {
        items.forEach(item => {
            switch (item.type) {
                case 'assembly':
                    this.addText({items: item.children, baseName: baseName + item.name + '/', text});
                    break;
                case 'box':
                    text.csv += `${baseName + item.name},${item.type},${item.x_calc},${item.y_calc},${item.z_calc},`;
                    text.csv += `${item.dx_calc},${item.dy_calc},${item.dz_calc},`;
                    text.csv += `${item.material_name},${item.power_calc},`;
                    text.csv += '\n';
                    break;
                case 'cylinder':
                    text.csv += `${baseName + item.name},${item.type},${item.x_calc},${item.y_calc},${item.z_calc},`;
                    text.csv += `${item.dx_calc},${item.dy_calc},${item.dz_calc},`;
                    text.csv += `${item.material_name},${item.power_calc},`;
                    text.csv += `${item.plane},`;
                    text.csv += '\n';
                    break;
                case 'ball_array':
                    text.csv += `${baseName + item.name},${item.type},${item.x_calc},${item.y_calc},${item.z_calc},`;
                    text.csv += `${item.dx_calc},${item.dy_calc},${item.dz_calc},`;
                    text.csv += `${item.material_name},`;
                    text.csv += `${item.xcount_calc},${item.ycount_calc},${item.xpitch_calc},${item.diameter_calc},`;
                    text.csv += '\n';
                    break;
                case 'via_array':
                    text.csv += `${baseName + item.name},${item.type},${item.x_calc},${item.y_calc},${item.z_calc},`;
                    text.csv += `${item.dx_calc},${item.dy_calc},${item.dz_calc},`;
                    text.csv += `${item.material_name},`;
                    text.csv += `${item.xcount_calc},${item.ycount_calc},${item.xpitch_calc},${item.ypitch_calc},${item.diameter_calc},`;
                    text.csv += '\n';
                    break;
                case 'heatsink':
                    text.csv += `${baseName + item.name},${item.type},${item.x_calc},${item.y_calc},${item.z_calc},`;
                    text.csv += `${item.base_dx_calc},${item.base_dy_calc},${item.base_dz_calc + item.fin_height_calc},`;
                    text.csv += `${item.material_name},`;
                    text.csv += `${item.fin_axis},${item.base_dz_calc},${item.fin_thickness_calc},${item.fin_height_calc},${item.fin_count_calc},`;
                    text.csv += '\n';
                    break;
                case 'pcb':
                    text.csv += `${baseName + item.name},${item.type},${item.x_calc},${item.y_calc},${item.z_calc},`;
                    text.csv += `${item.dx_calc},${item.dy_calc},${item.dz_calc},`;
                    text.csv += `,`;
                    item.layers.forEach(layer => {
                        text.csv += `${layer.name},${layer.thickness_calc},${layer.material_name},`;
                    });
                    text.csv += '\n';
                    break;
                case 'source':
                    text.csv += `${baseName + item.name},${item.type},${item.x_calc},${item.y_calc},${item.z_calc},`;
                    text.csv += `${item.dx_calc},${item.dy_calc},${item.dz_calc},`;
                    text.csv += `,`;
                    text.csv += `${item.power},${item.color},${item.plane},`;
                    text.csv += '\n';
                    break;
                case 'transient_source':
                    text.csv += `${baseName + item.name},${item.type},${item.x_calc},${item.y_calc},${item.z_calc},`;
                    text.csv += `${item.dx_calc},${item.dy_calc},${item.dz_calc},`;
                    text.csv += `,`;
                    item.powers.forEach(power => {
                        text.csv += `${power.id},${power.time},${power.power_calc},`;
                    });
                    text.csv += `${item.color},${item.plane},`;
                    text.csv += '\n';
                    break;
                default:
                    console.log(`Unknown object ${item.type} to save to CSV`);
            }
        });

        return text;
    },

    // ECXML
    writeSolutionDomain({node, tree, current_project, doc}) {
        let elem = doc.createElement('solutionDomain'),
            bbox = new THREE.Box3();
        getBBox(tree, bbox);
        if (bbox.isEmpty()) {
            bbox.min.x = 0;
            bbox.min.y = 0;
            bbox.min.z = 0;
            bbox.max.x = 0;
            bbox.max.y = 0;
            bbox.max.z = 0;
        }

        node.appendChild(elem);
        this.writeXYZ({
            node: elem,
            name: 'location',
            x: bbox.min.x - current_project.world.dxmin,
            y: bbox.min.y - current_project.world.dymin,
            z: bbox.min.z - current_project.world.dzmin,
            doc,
        });

        this.writeXYZ({
            node: elem,
            name: 'size',
            x: +current_project.world.dxmin + 1 * current_project.world.dxmax + bbox.max.x - bbox.min.x,
            y: +current_project.world.dymin + 1 * current_project.world.dymax + bbox.max.y - bbox.min.y,
            z: +current_project.world.dzmin + 1 * current_project.world.dzmax + bbox.max.z - bbox.min.z,
            doc,
        });
        // TODO: write ambient conditions
    },
    writeMaterials({node, tree, materials, composites, doc}) {
        let elem = doc.createElement('materials');
        node.appendChild(elem);
        materials.concat({
            name: 'Dummy Source Material',
            color: '#000000',
            density: 1,
            specific_heat: 1,
            anisotropic: false,
            conductivity_calc: 1,
        }).forEach(material => {
            let matElem = doc.createElement('material'),
                nameElem = doc.createElement('name'),
                colorElem = doc.createElement('ats:color'),
                densityElem = doc.createElement('density'),
                shElem = doc.createElement('specific_heat'),
                seElem = doc.createElement('surfaceEmissivity'),
                tcElem = doc.createElement('thermalConductivity')
            ;
            elem.appendChild(matElem);
            matElem.appendChild(nameElem);
            matElem.appendChild(colorElem);
            matElem.appendChild(densityElem);
            matElem.appendChild(shElem);
            matElem.appendChild(seElem);
            matElem.appendChild(tcElem);

            nameElem.innerHTML = material.name;
            colorElem.innerHTML = material.color;
            densityElem.innerHTML = material.density || 0;
            shElem.innerHTML = material.specific_heat || 0;
            seElem.innerHTML = material.surface_emissivity || 0;
            if (material.anisotropic) {
                let ortho = doc.createElement('orthotropic'),
                    x = doc.createElement('x'),
                    y = doc.createElement('y'),
                    z = doc.createElement('z');
                tcElem.appendChild(ortho);
                ortho.appendChild(x);
                ortho.appendChild(y);
                ortho.appendChild(z);
                x.innerHTML = material.conductivity_calc;
                y.innerHTML = material.conductivity_y_calc;
                z.innerHTML = material.conductivity_z_calc;

            } else {
                let iso = doc.createElement('isotropic'),
                    cond = doc.createElement('conductivity');
                tcElem.appendChild(iso);
                iso.appendChild(cond);
                cond.innerHTML = material.conductivity_calc;
            }
        });


        this.writeComposites({hier_name: '/', tree, elem, composites, doc});
    },
    writeGeometry({node, tree, hier_name, lumped, composites, doc}) {
        let geom = doc.createElement('geometry');
        node.appendChild(geom);

        tree.slice(0).reverse().forEach(item => {
            if (item.children) {
                this.writeGeometry({
                    node: this.writeAssembly({node: geom, assy: item, doc}),
                    tree: item.children,
                    hier_name: hier_name + item.name + '/',
                    lumped: item.lumped || lumped,
                    composites,
                    doc,
                });
            } else {
                switch (item.type) {
                    case 'box':
                        this.writeBox({node: geom, box: item, doc});
                        break;
                    case 'pcb':
                        this.writePCB({node: geom, box: item, doc});
                        break;
                    case 'ball_array':
                        this.writeBGA({node: geom, bga: item, lumped: item.lumped || lumped, composites, doc});
                        break;
                    case 'via_array':
                        this.writeViaArray({node: geom, via: item, lumped: item.lumped || lumped, composites, doc});
                        break;
                    case 'heatsink':
                        this.writeHeatsink({node: geom, heatsink: item, doc});
                        break;
                    case 'cylinder':
                        this.writeCylinder({node: geom, box: item, doc});
                        break;
                    case 'source':
                        this.writeSource({node: geom, source: item, doc});
                        break;
                    case 'transient_source':
                        this.writeTransientSource({node: geom, transientSource: item, doc});
                        break;
                    default:
                        console.error('Unhandled type to write', item);
                }
            }
        });
    },

    writeXYZ({node, name, x, y, z, doc}) {
        let elem = doc.createElement(name),
            xelem = doc.createElement('x'),
            yelem = doc.createElement('y'),
            zelem = doc.createElement('z');
        node.appendChild(elem);
        elem.appendChild(xelem);
        elem.appendChild(yelem);
        elem.appendChild(zelem);
        xelem.innerHTML = (x / 1e3).toFixed(7);
        yelem.innerHTML = (y / 1e3).toFixed(7);
        zelem.innerHTML = (z / 1e3).toFixed(7);
    },
    writeComposites({hier_name, tree, elem, composites, doc}) {
        tree.forEach(item => {
            if (item.children) {
                this.writeComposites({
                    hier_name: hier_name + item.name + '/',
                    tree: item.children,
                    elem,
                    composites,
                    doc,
                });
            } else if (item.composite_material) {
                let matElem = doc.createElement('material'),
                    nameElem = doc.createElement('name'),
                    densityElem = doc.createElement('density'),
                    shElem = doc.createElement('specific_heat'),
                    seElem = doc.createElement('surfaceEmissivity'),
                    tcElem = doc.createElement('thermalConductivity')
                ;
                elem.appendChild(matElem);
                matElem.appendChild(nameElem);
                matElem.appendChild(densityElem);
                matElem.appendChild(shElem);
                matElem.appendChild(seElem);
                matElem.appendChild(tcElem);

                nameElem.innerHTML = hier_name + item.name;
                composites[`${item.type}.${item.id}`] = hier_name + item.name;
                densityElem.innerHTML = 0;
                shElem.innerHTML = 0;
                seElem.innerHTML = 0;
                let ortho = doc.createElement('orthotropic'),
                    x = doc.createElement('x'),
                    y = doc.createElement('y'),
                    z = doc.createElement('z');
                tcElem.appendChild(ortho);
                ortho.appendChild(x);
                ortho.appendChild(y);
                ortho.appendChild(z);
                x.innerHTML = item.composite_material[0].toFixed(4);
                y.innerHTML = item.composite_material[1].toFixed(4);
                z.innerHTML = item.composite_material[2].toFixed(4);
            }
        });
    },
    writeBox({node, box, doc}) {
        let elem = doc.createElement('solid3dBlock'),
            name = doc.createElement('name'),
            active = doc.createElement('active'),
            visible = doc.createElement('ats:visible'),
            material = doc.createElement('material'),
            power = doc.createElement('powerDissipation');

        node.appendChild(elem);
        elem.appendChild(name);
        elem.appendChild(active);
        elem.appendChild(visible);

        this.writeXYZ({node: elem, name: 'location', x: box.x_calc, y: box.y_calc, z: box.z_calc, doc});
        this.writeXYZ({node: elem, name: 'size', x: box.dx_calc, y: box.dy_calc, z: box.dz_calc, doc});

        elem.appendChild(material);
        elem.appendChild(power);

        name.innerHTML = box.name;
        active.innerHTML = box.active;
        visible.innerHTML = box.visible;
        material.innerHTML = box.material_name;
        power.innerHTML = (box.power_calc || 0) / 1e3;
    },
    writeCylinder({node, box, doc}) {
        let elem = doc.createElement('solidCylinder'),
            name = doc.createElement('name'),
            active = doc.createElement('active'),
            visible = doc.createElement('ats:visible'),
            plane = doc.createElement('plane'),
            material = doc.createElement('material'),
            power = doc.createElement('powerDissipation');

        node.appendChild(elem);
        elem.appendChild(name);
        elem.appendChild(active);
        elem.appendChild(visible);

        this.writeXYZ({node: elem, name: 'location', x: box.x_calc, y: box.y_calc, z: box.z_calc, doc});
        this.writeXYZ({node: elem, name: 'size', x: box.dx_calc, y: box.dy_calc, z: box.dz_calc, doc});

        elem.appendChild(plane);
        elem.appendChild(material);
        elem.appendChild(power);

        name.innerHTML = box.name;
        active.innerHTML = box.active;
        visible.innerHTML = box.visible;
        plane.innerHTML = '+' + box.plane;
        material.innerHTML = box.material_name;
        power.innerHTML = box.power_calc || 0;
    },
    writePCB({node, box, doc}) {
        let assy = this.writeAssembly({node, assy: box, doc}),
            geom = doc.createElement('geometry'),
            z = +box.z_calc;

        assy.appendChild(geom);

        box.layers.forEach(layer => {
            this.writeBox(
                {
                    node: geom,
                    box: {
                        name: layer.name,
                        active: box.active,
                        visible: box.visible,
                        x_calc: box.x_calc,
                        y_calc: box.y_calc,
                        z_calc: z,
                        dx_calc: box.dx_calc,
                        dy_calc: box.dy_calc,
                        dz_calc: layer.thickness_calc / 1e3,
                        material_name: layer.material_name,
                    },
                    doc,
                },
            );
            z += layer.thickness_calc / 1e3;
        });
    },
    writeAssembly({node, assy, doc}) {
        let assembly = doc.createElement('assembly'),
            name = doc.createElement('name'),
            active = doc.createElement('active'),
            visible = doc.createElement('ats:visible');

        node.appendChild(assembly);
        assembly.appendChild(name);
        assembly.appendChild(active);
        assembly.appendChild(visible);

        name.innerHTML = assy.name;
        active.innerHTML = assy.active;
        visible.innerHTML = assy.visible;

        return assembly;
    },
    writeBGA({node, bga, lumped, composites, doc}) {
        if (lumped) {
            this.writeBox({
                node,
                box: {
                    ...bga,
                    material_name: composites[`${bga.type}.${bga.id}`],
                },
                doc,
            });
        } else {
            let assy = this.writeAssembly({node, assy: bga, doc}),
                geom = doc.createElement('geometry');

            assy.appendChild(geom);

            assy.setAttribute('ats:type', 'ball_array');
            assy.setAttribute('ats:material', bga.material_name);
            bga.fill_material_name && assy.setAttribute('ats:fill_material', bga.fill_material_name);
            assy.setAttribute('ats:x', bga.x_calc / 1e3);
            assy.setAttribute('ats:y', bga.y_calc / 1e3);
            assy.setAttribute('ats:z', bga.z_calc / 1e3);
            assy.setAttribute('ats:dx', bga.dx_calc / 1e3);
            assy.setAttribute('ats:dy', bga.dy_calc / 1e3);
            assy.setAttribute('ats:dz', bga.dz_calc / 1e3);
            assy.setAttribute('ats:xcount', bga.xcount_calc);
            assy.setAttribute('ats:ycount', bga.ycount_calc);
            assy.setAttribute('ats:xpitch', bga.xpitch_calc / 1e6);
            assy.setAttribute('ats:ypitch', bga.ypitch_calc / 1e6);
            assy.setAttribute('ats:diameter', bga.diameter_calc / 1e6);

            let x0 = bga.x_calc + bga.dx_calc / 2 - bga.xpitch_calc / 1e3 * (bga.xcount_calc - 1) / 2 - bga.diameter_calc / 1e3 / 2,
                y0 = bga.y_calc + bga.dy_calc / 2 - bga.ypitch_calc / 1e3 * (bga.ycount_calc - 1) / 2 - bga.diameter_calc / 1e3 / 2;
            for (let x = 0; x < bga.xcount_calc; ++x) {
                for (let y = 0; y < bga.ycount_calc; ++y) {
                    this.writeCylinder({
                        node: geom,
                        box: {
                            name: `ball_${x}_${y}`,
                            active: bga.active,
                            visible: bga.visible,
                            x_calc: x0 + x * bga.xpitch_calc / 1e3,
                            y_calc: y0 + y * bga.ypitch_calc / 1e3,
                            z_calc: bga.z_calc,
                            dx_calc: bga.diameter_calc / 1e3,
                            dy_calc: bga.diameter_calc / 1e3,
                            dz_calc: bga.dz_calc,
                            material_name: bga.material_name,
                            plane: 'XY',
                        },
                        doc,
                    });
                }
            }

            bga.fill_material &&
            this.writeBox({
                node: geom,
                box: {
                    name: 'fill',
                    active: bga.active,
                    visible: bga.visible,
                    x_calc: bga.x_calc,
                    y_calc: bga.y_calc,
                    z_calc: bga.z_calc,
                    dx_calc: bga.dx_calc,
                    dy_calc: bga.dy_calc,
                    dz_calc: bga.dz_calc,
                    material_name: bga.fill_material_name,
                },
                doc,
            });
        }
    },
    writeViaArray({node, via, lumped, composites, doc}) {
        if (lumped) {
            this.writeBox({
                node,
                box: {
                    ...via,
                    material_name: composites[`${via.type}.${via.id}`],
                },
                doc,
            });
        } else {
            let assy = this.writeAssembly({node, assy: via, doc}),
                geom = doc.createElement('geometry');

            assy.appendChild(geom);

            assy.setAttribute('ats:type', 'via_array');
            assy.setAttribute('ats:material', via.material_name);
            via.fill_material_name && assy.setAttribute('ats:fill_material', via.fill_material_name);
            assy.setAttribute('ats:x', via.x_calc / 1e3);
            assy.setAttribute('ats:y', via.y_calc / 1e3);
            assy.setAttribute('ats:z', via.z_calc / 1e3);
            assy.setAttribute('ats:dx', via.dx_calc / 1e3);
            assy.setAttribute('ats:dy', via.dy_calc / 1e3);
            assy.setAttribute('ats:dz', via.dz_calc / 1e3);
            assy.setAttribute('ats:xcount', via.xcount_calc);
            assy.setAttribute('ats:ycount', via.ycount_calc);
            assy.setAttribute('ats:xpitch', via.xpitch_calc / 1e6);
            assy.setAttribute('ats:ypitch', via.ypitch_calc / 1e6);
            assy.setAttribute('ats:diameter', via.diameter_calc / 1e6);

            let x0 = via.x_calc + via.dx_calc / 2 - via.xpitch_calc / 1e3 * (via.xcount_calc - 1) / 2 - via.diameter_calc / 1e3 / 2,
                y0 = via.y_calc + via.dy_calc / 2 - via.ypitch_calc / 1e3 * (via.ycount_calc - 1) / 2 - via.diameter_calc / 1e3 / 2;
            for (let x = 0; x < via.xcount_calc; ++x) {
                for (let y = 0; y < via.ycount_calc; ++y) {
                    this.writeCylinder({
                        node: geom,
                        box: {
                            name: `via_${x}_${y}`,
                            active: via.active,
                            visible: via.visible,
                            x_calc: x0 + x * via.xpitch_calc / 1e3,
                            y_calc: y0 + y * via.ypitch_calc / 1e3,
                            z_calc: via.z_calc,
                            dx_calc: via.diameter_calc / 1e3,
                            dy_calc: via.diameter_calc / 1e3,
                            dz_calc: via.dz_calc,
                            material_name: via.material_name,
                            plane: 'XY',
                        },
                        doc,
                    });
                }
            }

            via.fill_material &&
            this.writeBox({
                node: geom,
                box: {
                    name: 'fill',
                    active: via.active,
                    visible: via.visible,
                    x_calc: via.x_calc,
                    y_calc: via.y_calc,
                    z_calc: via.z_calc,
                    dx_calc: via.dx_calc,
                    dy_calc: via.dy_calc,
                    dz_calc: via.dz_calc,
                    material_name: via.fill_material_name,
                },
                doc,
            });
        }
    },
    writeHeatsink({node, heatsink, doc}) {
        let assy = this.writeAssembly({node, assy: heatsink, doc}),
            geom = doc.createElement('geometry');
        assy.setAttribute('ats:type', 'heatsink');
        assy.setAttribute('ats:material', heatsink.material_name);
        assy.setAttribute('ats:x', heatsink.x_calc / 1e3);
        assy.setAttribute('ats:y', heatsink.y_calc / 1e3);
        assy.setAttribute('ats:z', heatsink.z_calc / 1e3);
        assy.setAttribute('ats:base_dx', heatsink.base_dx_calc / 1e3);
        assy.setAttribute('ats:base_dy', heatsink.base_dy_calc / 1e3);
        assy.setAttribute('ats:base_dz', heatsink.base_dz_calc / 1e3);
        assy.setAttribute('ats:fin_axis', heatsink.fin_axis);
        assy.setAttribute('ats:fin_count', heatsink.fin_count_calc);
        assy.setAttribute('ats:fin_height', heatsink.fin_height_calc / 1e3);
        assy.setAttribute('ats:fin_thickness', heatsink.fin_thickness_calc / 1e3);

        assy.appendChild(geom);

        this.writeBox({
            node: geom,
            box: {
                name: 'Base',
                active: heatsink.active,
                visible: heatsink.visible,
                material_name: heatsink.material_name,
                x_calc: heatsink.x_calc,
                y_calc: heatsink.y_calc,
                z_calc: heatsink.z_calc,
                dx_calc: heatsink.base_dx_calc,
                dy_calc: heatsink.base_dy_calc,
                dz_calc: heatsink.base_dz_calc,
            },
            doc,
        });

        for (let i = 0; i < heatsink.fin_count_calc; ++i) {
            if (heatsink.fin_axis === 'X') {
                let fin_pitch = (heatsink.fin_count_calc > 1) ? ((heatsink.base_dy_calc - heatsink.fin_thickness_calc) / (heatsink.fin_count_calc - 1)) : 0,
                    fin_extra = (heatsink.fin_count_calc < 2) ? (heatsink.base_dy_calc - heatsink.fin_thickness_calc) / 2 : 0;
                this.writeBox({
                    node: geom,
                    box: {
                        name: `fin${i}`,
                        active: heatsink.active,
                        visible: heatsink.visible,
                        x_calc: heatsink.x_calc,
                        y_calc: heatsink.y_calc + i * fin_pitch + fin_extra,
                        z_calc: heatsink.z_calc + heatsink.base_dz_calc,
                        dx_calc: heatsink.base_dx_calc,
                        dy_calc: heatsink.fin_thickness_calc,
                        dz_calc: heatsink.fin_height_calc,
                        material_name: heatsink.material_name,
                    },
                    doc,
                });
            } else {
                let fin_pitch = (heatsink.fin_count_calc > 1) ? ((heatsink.base_dx_calc - heatsink.fin_thickness_calc) / (heatsink.fin_count_calc - 1)) : 0,
                    fin_extra = (heatsink.fin_count_calc < 2) ? (heatsink.base_dx_calc - heatsink.fin_thickness_calc) / 2 : 0;
                this.writeBox({
                    node: geom,
                    box: {
                        name: `fin${i}`,
                        active: heatsink.active,
                        visible: heatsink.visible,
                        x_calc: heatsink.x_calc + i * fin_pitch + fin_extra,
                        y_calc: heatsink.y_calc,
                        z_calc: heatsink.z_calc + heatsink.base_dz_calc,
                        dx_calc: heatsink.fin_thickness_calc,
                        dy_calc: heatsink.base_dy_calc,
                        dz_calc: heatsink.fin_height_calc,
                        material_name: heatsink.material_name,
                    },
                    doc,
                });
            }
        }
    },
    writeSource({node, source, doc}) {
        let elem = doc.createElement('solid2dBlock'),
            name = doc.createElement('name'),
            active = doc.createElement('active'),
            visible = doc.createElement('ats:visible'),
            material = doc.createElement('material'),
            color = doc.createElement('ats:color'),
            plane = doc.createElement('plane'),
            power = doc.createElement('powerDissipation');

        node.appendChild(elem);
        elem.appendChild(name);
        elem.appendChild(active);
        elem.appendChild(visible);

        this.writeXYZ({node: elem, name: 'location', x: source.x_calc, y: source.y_calc, z: source.z_calc, doc});
        this.writeXYZ({node: elem, name: 'size', x: source.dx_calc, y: source.dy_calc, z: source.dz_calc, doc});

        elem.appendChild(plane);
        elem.appendChild(material);
        elem.appendChild(color);
        elem.appendChild(power);

        name.innerHTML = source.name;
        active.innerHTML = source.active;
        visible.innerHTML = source.visible;
        material.innerHTML = source.material_name || 'Dummy Source Material';
        color.innerHTML = source.color;
        plane.innerHTML = '+' + source.plane;
        power.innerHTML = (source.power_calc || 0) / 1e3;
    },
    writeTransientSource({node, transientSource, doc}) {
        let elem = doc.createElement('solid2dBlock'),
            name = doc.createElement('name'),
            active = doc.createElement('active'),
            visible = doc.createElement('ats:visible'),
            material = doc.createElement('material'),
            color = doc.createElement('ats:color'),
            plane = doc.createElement('plane'),
            power = doc.createElement('powerDissipation');

        node.appendChild(elem);
        elem.setAttribute('ats:transient', 'true');

        elem.appendChild(name);
        elem.appendChild(active);
        elem.appendChild(visible);

        this.writeXYZ({
            node: elem, name: 'location', x: transientSource.x_calc, y: transientSource.y_calc,
            z: transientSource.z_calc, doc
        });
        this.writeXYZ({
            node: elem, name: 'size', x: transientSource.dx_calc, y: transientSource.dy_calc,
            z: transientSource.dz_calc, doc
        });

        elem.appendChild(plane);
        elem.appendChild(material);
        elem.appendChild(color);
        elem.appendChild(power);

        transientSource.powers.forEach(power => {
            let powerNode = doc.createElement('ats:transientPowerDissipation');
            elem.appendChild(powerNode);
            powerNode.setAttribute('time', power.time);
            powerNode.setAttribute('power', power.power_calc / 1e3);
        });

        name.innerHTML = transientSource.name;
        active.innerHTML = transientSource.active;
        visible.innerHTML = transientSource.visible;
        material.innerHTML = transientSource.material_name || 'Dummy Transient Source Material';
        color.innerHTML = transientSource.color;
        plane.innerHTML = '+' + transientSource.plane;
        power.innerHTML = 0;
    },


    getFileDownloadUrl({blobParts}) {
        return URL.createObjectURL(new Blob([blobParts]));
    },

    downloadFileAs({string, fileName, fileType = 'text/plain'}) {
        const element = document.createElement("a");
        const file = new Blob([string], {type: fileType});

        element.href = URL.createObjectURL(file);
        element.download = `${fileName}`;

        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }
};

export const drawTransientSolutionUtils = {
    drawClipPlanes: ({clipFacesStoredData, projectId, currentPlayedSolutionId, loaderFns}) => {
        const clipFacesEntries = Object.entries(clipFacesStoredData);

        if (clipFacesEntries.length) {
            for (let entry in clipFacesEntries) {
                const [axis, {coordinate, plane}] = clipFacesEntries[entry];

                const clipFaceLoader = loaderFns[`loadClipFaceSolutionTimePoint_${axis}`];

                clipFaceLoader && clipFaceLoader({
                    project_id: projectId,
                    coordinate,
                    solution_id: currentPlayedSolutionId,
                    plane,
                })
            }
        }
    }
}