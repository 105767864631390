/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import * as d3 from 'd3';
import _ from "lodash";

import { Box } from '@mui/material';
import { drawTransientSolutionUtils } from '../../../../../../utils';
import { getClipFacesStoredData } from '../../../../../../+store/reducer/plane_solutions';
import {
    loadClipFaceSolutionTimePoint_x0, loadClipFaceSolutionTimePoint_x1,
    loadClipFaceSolutionTimePoint_y0, loadClipFaceSolutionTimePoint_y1,
    loadClipFaceSolutionTimePoint_z0, loadClipFaceSolutionTimePoint_z1
} from '../../../../../../+store/actions/actions';

const wrapperSelector = 'transient-info-wrapper';
const chartOffset = 10;
const transitionDuration = 0;
const horizontalLineGroupSelector = 'horizontal-hover-line-g';
const margin = {
    top: 15,
    left: 55,
    right: 15,
    bottom: 25,
};
const gridStyles = {
    horizontalGridLineColor: '#000000',
    verticalGridLineColor: '#000000',
    hover: {
        pointerColor: '#fff',
        temperatureColor: '#fff',
        timeColor: '#fff',
    },
    activeThermalPointCircleColor: '#000000'
}

const formatScientificNotationNumberToDegrees = (number) => {
    if (number) {
        const stringifiedScientificNotation = number.toString().split('.');
        const [beforeDecimal, afterDecimal] = stringifiedScientificNotation;
        return `${beforeDecimal}.${afterDecimal ? afterDecimal.slice(0, 2) : '00'} °C`;
    }
    return number;
}

const formatScientificNotationNumber = (number) => {
    if (number) {
        const stringifiedScientificNotation = number.toString().split('.');
        const [beforeDecimal, afterDecimal] = stringifiedScientificNotation;
        return parseFloat(`${beforeDecimal}.${afterDecimal ? afterDecimal.slice(0, 2) : '00'}`);
    }
    return number;
}

const Transient = ({
    project_id,
    data,
    loadNewSolution,
    draw,
    thermalGradientConfig,
    currentPlayedSolutionId,
    setCurrentViewPoints,
    clipFacesStoredData,
    ...rest
}) => {
    const svgRef = useRef();
    const xAxisRef = useRef();
    const yAxisRef = useRef();
    const viewGroupRef = useRef();
    const chartDimensions = useRef();
    const xScaleRef = useRef();
    const yScaleRef = useRef();
    const closestThermalPoint = useRef();
    const visibleThermalPoints = useRef(data);
    const previousData = useRef(null);
    const localClipFaceSolutionsData = useRef(null);

    // this useEffect will run only once, appending all elements which will not change throughout renders
    useEffect(() => {
        // get the containing box dimensions
        const { width, height } = d3.select(`.${wrapperSelector}`)?.node()?.getBoundingClientRect();
        console.log(width);
        chartDimensions.current = {
            width: width - margin.left - margin.right,
            height: height - margin.top - margin.bottom,
        }

        // append the root svg and main group element
        svgRef.current = d3.select(`.${wrapperSelector}`)
            .append("svg")
            .attr("viewBox", `0 0 ${chartDimensions.current.width + margin.left + margin.right} ${chartDimensions.current.height + margin.top + margin.bottom}`)
            .attr('height', chartDimensions.current.height + margin.top + margin.bottom)
            .attr('width', '100%')
            .attr("preserveAspectRatio", "none")
            .append("g")
            .attr("transform", `translate(${margin.left}, ${margin.top})`);

        // create a group which will contain the horizontal line when hovering
        const horizontalGroup = svgRef.current.append('g')
            .attr('class', horizontalLineGroupSelector)
            .classed('mouse', true)
            .style('display', 'none');

        // create the horizontal line
        horizontalGroup
            .append('line')
            .attr('stroke-width', 1)
            .attr('stroke', '#525252')
            .attr('x1', 0)
            .attr('y1', chartOffset)
            .attr('x2', 0)
            .attr('y2', chartDimensions.current.height - chartOffset)
            .style("stroke-dasharray", ("3, 3"));

        // add the small pointer on top of the horizontal line
        const trianglePointer = d3.symbol().type(d3.symbolTriangle).size(20);
        horizontalGroup.append('path').attr('d', trianglePointer).attr('fill', gridStyles.hover.pointerColor).attr('opacity', '80%').attr("transform", 'translate(0, 4) rotate(180)')

        // add the temperature text on the horizontal line
        horizontalGroup.append('text').attr('class', 'temperature-text').attr('fill', gridStyles.hover.temperatureColor).attr('font-size', 8)

        // add the time text beneath the horizontal line
        horizontalGroup.append('text').attr('class', 'termal-point-time-text').attr('fill', gridStyles.hover.timeColor).attr('font-size', 8).attr("transform", `translate(0, ${chartDimensions.current.height + chartOffset / 2})`)

        // create groups for x-axis and y-axis
        xAxisRef.current = svgRef.current.append('g').attr('class', 'xAxis').attr("transform", `translate(0, ${chartDimensions.current.height})`);
        yAxisRef.current = svgRef.current.append('g').attr('class', 'yAxis');

        // create a clipPath - everything out of this area won't be drawn
        svgRef.current.append("defs").append("clipPath")
            .attr("id", "clip-path")
            .append("rect")
            .attr("width", chartDimensions.current.width)
            .attr("height", chartDimensions.current.height)
            .attr("x", 0)
            .attr("y", 0);

        // create a group which will control the visible area while zooming
        viewGroupRef.current = svgRef.current.append('g')
            .attr("clip-path", "url(#clip-path)");
    }, [])

    useEffect(() => {
        const hasDataChanged = !_.isEqual(data, previousData.current);
        const clipFacesDataChanged = !_.isEqual(clipFacesStoredData, localClipFaceSolutionsData.current);

        if (hasDataChanged || clipFacesDataChanged) {
            setCurrentViewPoints(data);
            visibleThermalPoints.current = data;
            d3.select('.thermal-line').remove();
            d3.select('.clip-rect').remove();
            d3.selectAll('.x-grid-line').remove();
            d3.selectAll('.y-grid-line').remove();
            d3.selectAll('.diagram-points').remove();

            // create the x and y scales and append them to corresponding groups
            const xScale = d3.scaleLinear()
                .domain(d3.extent(data, (dataPoint) => dataPoint.time))
                .range([chartOffset, chartDimensions.current.width - chartOffset]);
            xScaleRef.current = xScale;
            const yScale = d3.scaleLinear()
                .domain(d3.extent(data, function (dataPoint) {
                    return dataPoint.max_t;
                }))
                .range([chartDimensions.current.height - chartOffset, chartOffset]);
            yScaleRef.current = yScale;
            yAxisRef.current.call(d3.axisLeft(yScale).ticks(5).tickPadding(10).tickFormat((temperature) => d3.format('~s')(temperature) + '°C'));
            xAxisRef.current.call(d3.axisBottom(xScale).tickPadding(10).tickSize(0).tickFormat(time => d3.format('~s')(time) + 's'));

            // Set the zoom and Pan features: how much you can zoom, on which part, and what to do when there is a zoom
            const zoom = d3.zoom()
                .scaleExtent([1, 1e15])
                .extent([[0, 0], [chartDimensions.current.width, chartDimensions.current.height]])
                .translateExtent([[0, 0], [chartDimensions.current.width, chartDimensions.current.height]])
                .on("zoom", ({ transform }) => {
                    // recover the new scale
                    const rescaleX = transform.rescaleX(xScale);
                    // override the current scale, so when hovering d3 uses the new scale
                    xScaleRef.current = rescaleX;
                    // update axes with these new boundaries
                    xAxisRef.current.transition().duration(transitionDuration).call(d3.axisBottom(rescaleX).tickPadding(10).tickFormat(time => d3.format('~s')(time) + 's'))

                    // get the visible time on the chart after zoom, filter out the only visible points
                    const thermalPointsTimeRange = rescaleX.domain()
                    visibleThermalPoints.current = data.filter((dataPoint) => {
                        if (dataPoint) {
                            const [smallestVisibleTimeValue, largetVisibleTimeValue] = thermalPointsTimeRange;
                            return dataPoint.time >= smallestVisibleTimeValue && dataPoint.time <= largetVisibleTimeValue;

                        }
                    })
                    setCurrentViewPoints(visibleThermalPoints.current);

                    //add offset, so items (circles etc.) don't get cut out from the clip-path if we are at the initial zoom level 
                    if (transform.k === 1) {
                        d3.select('#clip-path').select('rect').attr("width", chartDimensions.current.width)
                            .attr("height", chartDimensions.current.height)
                            .attr("x", 0)
                            .attr("y", 0);
                    } else {
                        d3.select('#clip-path').select('rect').attr("width", chartDimensions.current.width - 2 * chartOffset)
                            .attr("height", chartDimensions.current.height + 2 * chartOffset)
                            .attr("x", chartOffset)
                            .attr("y", -chartOffset);
                    }

                    // update the clipped rect, redraw line and circles, redraw x grid lines, update the horizontal line position
                    d3.select('.clip-rect').attr('transform', event.transform);
                    d3.select('.thermal-line').transition().duration(transitionDuration).attr('d', d3.line().curve(d3.curveMonotoneX)
                        .x((dataPoint) => {
                            if (dataPoint) {
                                return rescaleX(dataPoint.time)
                            }
                        })
                        .y((dataPoint) => {
                            if (dataPoint) {
                                return yScale(formatScientificNotationNumber(dataPoint.max_t))
                            }
                        })
                    );
                    d3.selectAll(".diagram-points")
                        .transition().duration(transitionDuration)
                        .attr('cx', (dataPoint) => {
                            if (dataPoint) {
                                return rescaleX(dataPoint.time)
                            }

                        })
                        .attr('cy', (dataPoint) => {
                            if (dataPoint) {
                                return yScale(formatScientificNotationNumber(dataPoint.max_t))
                            }
                        })
                    d3.selectAll('.x-grid-line').remove();
                    d3.selectAll("g.xAxis g.tick")
                        .append('line')
                        .attr("class", "x-grid-line")
                        .attr('stroke', gridStyles.horizontalGridLineColor)
                        .attr('stroke-width', '0.2px')
                        .attr("x1", 0)
                        .attr("y1", -chartDimensions.current.height + chartOffset)
                        .attr("x2", 0)
                        .attr("y2", -chartOffset);
                    d3.select(`.${horizontalLineGroupSelector}`).transition().duration(transitionDuration)
                        .attr('transform', `translate(${rescaleX(closestThermalPoint.current?.time)}, 0)`)
                });

            // add an invisible rect on top of the chart area
            svgRef.current.append("rect")
                .attr("width", chartDimensions.current.width)
                .attr("height", chartDimensions.current.height)
                .attr("class", 'clip-rect')
                .style("fill", "none")
                .style("pointer-events", "all")
                .attr('transform', `translate(0, 0)`)
                .call(zoom)
                .on("mousedown.zoom", null)
                .on("touchstart.zoom", null)
                .on("touchmove.zoom", null)
                .on("touchend.zoom", null);


            // append horizontal grid lines
            d3.selectAll("g.xAxis g.tick")
                .append("line")
                .attr("class", "x-grid-line")
                .attr('stroke', gridStyles.horizontalGridLineColor)
                .attr('stroke-width', 0.2)
                .attr("x1", 0)
                .attr("y1", -chartDimensions.current.height + chartOffset)
                .attr("x2", 0)
                .attr("y2", -chartOffset);
            // append vertical grid lines
            d3.selectAll("g.yAxis g.tick")
                .append("line")
                .attr("class", "y-grid-line")
                .attr('stroke', gridStyles.verticalGridLineColor)
                .attr('stroke-width', 0.2)
                .attr("x1", chartOffset)
                .attr("y1", 0)
                .attr("x2", chartDimensions.current.width - chartOffset)
                .attr("y2", 0);

            // draw the thermal line
            viewGroupRef.current
                .datum(data)
                .append('path')
                .attr('class', 'thermal-line')
                .attr('fill', 'none')
                .attr("stroke", "url(#thermal-gradient)")
                .attr('stroke-width', '3px')
                .attr('d', d3.line().curve(d3.curveMonotoneX)
                    .x((dataPoint) => xScale(dataPoint.time))
                    .y((dataPoint) => yScale(formatScientificNotationNumber(dataPoint.max_t)))
                );

            // create the circles
            viewGroupRef.current.selectAll(".diagram-points")
                .data(data)
                .enter()
                .append("circle")
                .attr('id', (dataPoint) => dataPoint.id)
                .attr('class', 'diagram-points')
                .attr("fill", "url(#thermal-gradient)")
                .attr("stroke", "none")
                .attr("stroke-width", '1px')
                .attr("cx", (dataPoint) => xScale(dataPoint.time))
                .attr("cy", (dataPoint) => yScale(formatScientificNotationNumber(dataPoint.max_t)))
                .attr("r", 3)

            // handle mouse enter on the chart (display horizontal line)
            d3.select('.clip-rect').on('mouseover', () => {
                d3.select(`.${horizontalLineGroupSelector}`).style('display', 'block');
                d3.select('.clip-rect').style('cursor', 'pointer');
            });

            // handle mouse move (move horizontal line)
            d3.select('.clip-rect').on('mousemove', function (mouse) {
                if (visibleThermalPoints.current.length) {
                    const [mouseX] = d3.pointer(mouse);
                    // find the closest thermal point to the mouse position
                    closestThermalPoint.current = visibleThermalPoints.current.reduce((prev, curr) => (Math.abs(xScaleRef.current(curr.time) - mouseX)) < Math.abs(xScaleRef.current(prev.time) - mouseX) ? curr : prev);
                    // move the line to the closest thermal point of mouse position
                    d3.select(`.${horizontalLineGroupSelector}`).attr('transform', `translate(${xScaleRef.current(closestThermalPoint.current.time)}, 0)`);
                    // revert the non highlighted points
                    d3.selectAll('.diagram-points').attr('stroke-width', 0).attr("stroke", "none").attr('stroke-opacity', '100%');
                    // highlight thermal point (circle)
                    d3.select(`[id="${closestThermalPoint.current.id}"]`).attr('stroke-width', 2).attr("stroke", gridStyles.activeThermalPointCircleColor).attr('stroke-opacity', '80%');
                    // update the temperature text on the horizontal line
                    d3.select('.temperature-text').text(() => {
                        return formatScientificNotationNumberToDegrees(closestThermalPoint.current.max_t);
                    })
                    // update the time text on the horizontal line
                    d3.select('.termal-point-time-text').text(() => {
                        return d3.format('~s')(closestThermalPoint.current.time) + 's';
                    })
                }
            });

            // handle mouse leave
            d3.select('.clip-rect').on('mouseleave', () => {
                d3.select(`.${horizontalLineGroupSelector}`).style('display', 'none');
                d3.selectAll('.diagram-points').attr('stroke-width', 0).attr("stroke", "none").attr('stroke-opacity', '100%');
            })

            // handle mouse click
            d3.select('.clip-rect').on('click', () => {
                const solution_id = closestThermalPoint.current.id;

                loadNewSolution({ project_id, solution_id });

                drawTransientSolutionUtils.drawClipPlanes({
                    clipFacesStoredData, currentPlayedSolutionId: solution_id, loaderFns: rest, projectId: project_id
                })

                draw();
            })
        }
        previousData.current = data;
        localClipFaceSolutionsData.current = clipFacesStoredData;
    }, [data, thermalGradientConfig, chartDimensions.current, clipFacesStoredData, project_id, rest]);

    useEffect(() => {
        if (currentPlayedSolutionId) {
            d3.select(`.${horizontalLineGroupSelector}`).style('display', 'block');
            d3.select('.clip-rect').style('cursor', 'pointer');

            closestThermalPoint.current = visibleThermalPoints.current.find((point) => point.id === currentPlayedSolutionId);
            d3.select(`.${horizontalLineGroupSelector}`).attr('transform', `translate(${xScaleRef.current(closestThermalPoint.current?.time)}, 0)`);
            d3.selectAll('.diagram-points').attr('stroke-width', 0).attr("stroke", "none").attr('stroke-opacity', '100%');
            d3.select(`[id="${closestThermalPoint.current?.id}"]`).attr('stroke-width', 2).attr("stroke", gridStyles.activeThermalPointCircleColor).attr('stroke-opacity', '80%');
            d3.select('.temperature-text').text(() => {
                return formatScientificNotationNumberToDegrees(closestThermalPoint.current?.max_t);
            })
            d3.select('.termal-point-time-text').text(() => {
                return d3.format('~s')(closestThermalPoint.current?.time) + 's';
            })
        }
    }, [currentPlayedSolutionId]);

    useEffect(() => {
        d3.select('linearGradient').remove();

        const lineFillColors = thermalGradientConfig.map((color) => {
            const { r, g, b } = color
            return `rgb(${r * 255},${g * 255},${b * 255})`
        })

        // Create the gradient for the line and points
        const gradient = svgRef.current.append("linearGradient")
            .attr("id", "thermal-gradient")
            .attr("gradientUnits", "userSpaceOnUse")
            .attr("x1", 0)
            .attr("y1", yScaleRef.current(0))
            .attr("x2", 0)
            .attr("y2", yScaleRef.current(d3.max(data, (dataPoint) => dataPoint.max_t)));
        gradient.selectAll('stop')
            .data(lineFillColors)
            .enter()
            .append('stop')
            .style('stop-color', (color) => color)
            .attr('offset', (_, index) => 100 * (index / (lineFillColors.length - 1)) + '%');
        d3.selectAll('.diagram-points').attr("fill", "url(#thermal-gradient)");
        d3.select('.thermal-line').attr("stroke", "url(#thermal-gradient)")

    }, [thermalGradientConfig, data]);

    return (
        <Box className={wrapperSelector} />
    );
}

Transient.propTypes = {
    project_id: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
    loadNewSolution: PropTypes.func.isRequired,
    draw: PropTypes.func.isRequired,
    thermalGradientConfig: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
    clipFacesStoredData: getClipFacesStoredData(state),
});

const mapDispatchToProps = {
    loadClipFaceSolutionTimePoint_x0, loadClipFaceSolutionTimePoint_x1,
    loadClipFaceSolutionTimePoint_y0, loadClipFaceSolutionTimePoint_y1,
    loadClipFaceSolutionTimePoint_z0, loadClipFaceSolutionTimePoint_z1,
};

export default connect(mapStateToProps, mapDispatchToProps)(Transient);