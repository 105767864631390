/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {SET_SCENARIO_TABS} from '../actions/scenarios';

const defaultState = {
    scenarioTabs: {
        tab: 0,
        tabItem: 0
    },
}

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_SCENARIO_TABS:
            return {
                ...state,
                scenarioTabs: action.payload
            }
        default:
            return state;
    }
}

export const scenarioTabs = ({ui}) => ui.scenarios.scenarioTabs;