/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {Grid} from '@mui/material';

import Elements from './components/elements/Elements';
import Settings from './components/settings/Settings';
import {PanelDivider} from '../../../../components/BasicSettings';


const RightPanel = () => {
    return (
        <Grid container
              sx={{
                  display: 'inline-flex',
                  flexDirection: 'column',
                  alignItems: 'flexStart'
              }}>
            <Elements/>

            <PanelDivider sxProps={{width: 'fill-available', marginBottom: '8px'}}/>

            <Settings/>
        </Grid>
    );
};


RightPanel.propTypes = {};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RightPanel);
