/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React from 'react';
import * as THREE from 'three';
import Draggable3DIcon from './component/Draggable3DIcon';


export default function Heatsink3DIcon() {
    return (
        <Draggable3DIcon
            materials={[new THREE.MeshPhysicalMaterial({color: 0xffffff, metalness: 1, roughness: 1})]}
            boxes={[
                new THREE.BoxBufferGeometry(20, 1, 20),
                new THREE.BoxBufferGeometry(20, 1, 18),
                new THREE.BoxBufferGeometry(20, 1, 16)]}
            customMeshes={
                {
                    type: 'heatsink',
                    material: new THREE.MeshPhysicalMaterial({color: 0xffffff, metalness: 1, roughness: 1}),
                    box: new THREE.BoxBufferGeometry(1, 5, 20),
                    multiplier: 7,
                    position: {
                        x: 'dynamic',
                        y: 3,
                        z: 0,
                    },
                }
            }
            lightPosition={{x: 10, y: 10, z: 10}}
            rotateObj={false}
            tooltipTitle={'Create Heatsink object'}
            objId={'heatsinkID'}
            dragType={'heatsink'}
        />
    );
}
//
// class Heatsink3DIcon extends Component {
//     aspect = 1.3;
//     rotate = 0.002;
//     intensity = 1.5;
//
//     constructor(props) {
//         super(props);
//
//         this.animate = this.animate.bind(this);
//
//         this.scene = new THREE.Scene();
//         this.light = new THREE.DirectionalLight(0xffffff, this.intensity);
//         this.light.position.set(10, 10, 10);
//         this.light.target.position.set(0, 0, 0);
//         this.scene.add(this.light);
//
//         this.cameraFOV = 25;
//         this.camera = new THREE.PerspectiveCamera(this.cameraFOV, this.aspect, 0.01, 10000);
//         this.camera.position.set(50, 20, 0);
//         this.camera.lookAt(0, 0, 0);
//
//         this.renderer = new THREE.WebGLRenderer({
//             antialias: true,
//             alpha: true,
//         });
//
//
//         let material = new THREE.MeshPhysicalMaterial({color: 0xffffff, metalness: 1, roughness: 1}),
//             box = new THREE.BoxBufferGeometry(20, 1, 20),
//             mesh = new THREE.Mesh(box, material),
//             group = new THREE.Group();
//
//         group.add(mesh);
//
//         mesh.position.set(0, 0, 0);
//
//         for (let i = 0; i < 7; ++i) {
//             let box = new THREE.BoxBufferGeometry(1, 5, 20),
//                 mesh = new THREE.Mesh(box, material);
//
//             mesh.position.set((i - 1) * 3 - 6, 3, 0);
//             group.add(mesh);
//         }
//
//         this.object = group;
//         this.scene.add(group);
//     }
//
//     onWindowsResize = () => {
//         this.renderer.setSize(this.dom.offsetWidth, this.dom.offsetWidth / this.aspect);
//     };
//
//     animate() {
//         if (this.dom) {
//             this.object.rotateY(this.rotate);
//             this.renderer.setViewport(0, 0, this.dom.offsetWidth, this.dom.offsetHeight);
//             this.renderer.render(this.scene, this.camera);
//         }
//         requestAnimationFrame(this.animate);
//     }
//
//     componentDidMount() {
//         window.addEventListener('resize', this.onWindowsResize, false);
//
//         this.dom.appendChild(this.renderer.domElement);
//         this.animate();
//         this.onWindowsResize();
//     }
//
//     componentWillUnmount() {
//         window.removeEventListener('resize', this.onWindowsResize, false);
//     }
//
//     onDragStart = () => {
//         this.props.startProjectDrag('heatsink');
//     };
//
//     onDragEnd = () => {
//         this.props.startProjectDrag(null);
//     };
//
//     render() {
//         return (
//             <Tooltip title={'Create Heatsink object'}>
//                 <div draggable
//                      id={'heatsinkID'}
//                      ref={ref => (this.dom = ref)}
//                      onDragStart={this.onDragStart}
//                      onDragEnd={this.onDragEnd}
//                 />
//             </Tooltip>
//         );
//     }
// }
//
// Heatsink3DIcon.propTypes = {
//     width: PropTypes.number,
//     height: PropTypes.number,
// };
//
// const mapStateToProps = state => {
//     return {};
// };
//
// const mapDispatchToProps = {
//     startProjectDrag,
// };
//
// export default connect(mapStateToProps, mapDispatchToProps)(Heatsink3DIcon);
