/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {useState, useCallback} from 'react';
import {Button, TextField, Link, Grid, Typography, Container, Paper, Box} from '@mui/material';
import {GoogleReCaptchaProvider, useGoogleReCaptcha} from 'react-google-recaptcha-v3';

import logo from '../../logo.svg';
import '../../index.css';

const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

import host from '../../services/host';

export const SignInForm = ({onSubmit, error_message}) => {
    return (
        <Container maxWidth={'sm'}>
            {error_message && <p>{error_message}</p>}
            <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey} language={'en'}>
                <SignIn handleSubmit={onSubmit}/>
            </GoogleReCaptchaProvider>
        </Container>
    );
};


const SignIn = ({handleSubmit}) => {
    const {executeRecaptcha} = useGoogleReCaptcha();
    const [state, setState] = useState({
        username: '',
        password: ''
    });

    const handleReCaptchaVerify = useCallback((e) => {
        e.preventDefault();

        if (!executeRecaptcha) {
            return;
        }

        (async () => {
            try {
                const token = await executeRecaptcha('login');
                handleSubmit({...state, reCaptchaToken: token});
            } catch (error) {
                console.log(error);
            }
        })();
    }, [executeRecaptcha, state]);

    const handleChange = (event) => {
        event.preventDefault();
        const {name, value} = event.target;

        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <Container>
            <Paper style={{padding: 30}}>
                <Box sx={{display: "flex", justifyContent: 'center'}}>
                    <img src={logo} className={'App-logo'} alt={'App-logo'} width={150}/>
                </Box>
                    <Typography component={'h1'} variant={'h5'} textAlign={'center'}>
                        Sign in
                    </Typography>
                    <form noValidate onSubmit={handleReCaptchaVerify}>
                        <TextField variant={'standard'}
                                   margin={'normal'}
                                   required
                                   fullWidth
                                   id={'username'}
                                   label={'Email Address'}
                                   name={'username'}
                                   autoComplete={'username'}
                                   autoFocus
                                   value={state.username}
                                   onChange={handleChange}
                                   validators={['required']}
                                   errormessages={['This field is required!']}
                        />
                        <TextField variant={'standard'}
                                   margin={'normal'}
                                   required
                                   fullWidth
                                   name={'password'}
                                   label={'Password'}
                                   type={'password'}
                                   id={'password'}
                                   value={state.password}
                                   onChange={handleChange}
                                   autoComplete={'current-password'}
                                   validators={['required']}
                                   errormessages={['This field is required!']}
                        />
                        <Button id={'signin'}
                                type={'submit'}
                                fullWidth
                                variant={'contained'}
                                color={'primary'}>
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href={'#/forgot-password'} variant={'body2'}>
                                    Forgot password?
                                </Link>
                            </Grid>
                        </Grid>
                        <hr/>
                        <h3 style={{textAlign: "center"}}>OR</h3>
                        <Button
                            href={`/api/chipletus/login/?next=/api/user/chipletus/success/`}
                            fullWidth
                            variant={"contained"}
                        >
                            Log in with Chiplet.US account
                        </Button>
                    </form>

            </Paper>
        </Container>
    );
};
