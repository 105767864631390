/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {Collapse, List, ListItemButton, ListItemText} from '@mui/material';

import {showModal} from '../../../../../../../modals/actions';

import BoxTree from './components/BoxTree';
import CreateAssemblySettings from '../../../../../settings/CreateAssemblySettings';
import {IconsFontAwesome} from '../../../../../../../components/IconsFontAwesome';
import {ActiveInactiveStateIcon} from '../../../../../../../components/ActiveStateIcon';

import {FONT_AWESOME_ICONS_TYPE_MAP} from '../../../../../../../core/mappings';
import {projectUtils} from '../../../../../../utils';


const BoxTreeSection = ({expanded, handleExpandCollapse, current_project, showModal, tab}) => {
    const showAssemblyModal = (event) => {
        showModal({
            type: 'custom',
            title: 'Add Assembly',
            content: (props) => {
                return <CreateAssemblySettings {...props} />;
            },
        });

        projectUtils.stopPreventDefault(event);
    };

    return (
        <>
            <ListItemButton onClick={(e) => handleExpandCollapse(e, 'model')}
                            className={'aside-panel-list-header model'}>
                <ListItemText primary={'MODEL'} className={'text'} />
                {
                    <ActiveInactiveStateIcon active={expanded}
                                             activeIconType={'caretCollapse'} inactiveIconType={'caretExpand'}
                                             titleActive={'Collapse Section'} titleInactive={'Expand Section'}
                                             secondClass={'model-arrow-icon'}/>
                }
                {
                    current_project &&
                    (current_project.owner || current_project.edit) &&
                    <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.addFolder}
                                      secondClass={'add-assembly-icon'}
                                      titleAccess={'Add Assembly'}
                                      onClickHandler={showAssemblyModal}/>
                }
            </ListItemButton>
            <Collapse in={expanded} timeout={'auto'}>
                <List component={'div'} disablePadding>
                    <ListItemButton sx={{pl: 4}} disableRipple>
                        <BoxTree tab={tab} project={current_project}/>
                    </ListItemButton>
                </List>
            </Collapse>
        </>
    );
};


BoxTreeSection.propTypes = {
    current_project: PropTypes.object,
    tab: PropTypes.number.isRequired,
    showModal: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {showModal};

export default connect(mapStateToProps, mapDispatchToProps)(BoxTreeSection);
