/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

// Derived from material-ui-color-picker

import React from 'react'
import PropTypes from 'prop-types'
import {ChromePicker} from 'react-color'

const PickerDialog = ({
                          value,
                          onClick,
                          onChange,

                      }) => (
    <div style={{position: 'relative'}}>
        <div style={{position: 'absolute', zIndex: '2'}}>
            <div
                style={{position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px'}}
                onClick={onClick}
            />
            <ChromePicker
                color={value}
                onChange={onChange}
                disableAlpha={true}
            />
        </div>
    </div>
)

PickerDialog.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    onClick: PropTypes.func
}

export default PickerDialog
