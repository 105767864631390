/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {handleActions} from 'redux-actions';
import {HIDE_MODAL, SHOW_MODAL} from './actions';

export const defaultState = [];

export const reducer = handleActions(
    {
        [SHOW_MODAL]: (state, { payload }) => {
            return state.concat(payload);
        },
        [HIDE_MODAL]: (state, { payload }) => {
            if(payload && payload.id) {
                return state.filter(item => item.id !== payload.id);
            }
            // remove top modal form the collection
            return state.slice(0, state.length -1);
        },
    },
    defaultState,
);


export const getModals = ({ modals }) => modals;
