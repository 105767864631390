/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

export const UPDATE_PLANE = 'UPDATE_PLANE';
export const UPDATE_PLANE_ERROR = 'UPDATE_PLANE_ERROR';
export const UPDATE_PLANE_SUCCESS = 'UPDATE_PLANE_SUCCESS';

export const updatePlane = payload => ({
    type: UPDATE_PLANE,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/plane/${payload.plane.id}/`,
            method: 'PUT',
            body: JSON.stringify(payload.plane),
        },
    },
});


export const CREATE_PLANE = 'CREATE_PLANE';
export const CREATE_PLANE_ERROR = 'CREATE_PLANE_ERROR';
export const CREATE_PLANE_SUCCESS = 'CREATE_PLANE_SUCCESS';

export const createPlane = payload => ({
    type: CREATE_PLANE,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/plane/`,
            method: 'POST',
            body: JSON.stringify(payload.plane),
        },
    },
});


export const DELETE_PLANE = 'DELETE_PLANE';
export const DELETE_PLANE_ERROR = 'DELETE_PLANE_ERROR';
export const DELETE_PLANE_SUCCESS = 'DELETE_PLANE_SUCCESS';

export const deletePlane = payload => ({
    type: DELETE_PLANE,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/plane/${payload.box.id}/`,
            method: 'DELETE',
        },
    },
});

export const LOAD_PROJECT_PLANES = 'LOAD_PROJECT_PLANES';
export const LOAD_PROJECT_PLANES_ERROR = 'LOAD_PROJECT_PLANES_ERROR';
export const LOAD_PROJECT_PLANES_SUCCESS = 'LOAD_PROJECT_PLANES_SUCCESS';

export const loadPlanes = payload => ({
    type: LOAD_PROJECT_PLANES,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/plane/?_=` + Date.now(),
        },
    },
});


