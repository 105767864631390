/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {connect} from 'react-redux';
import _ from 'lodash';
import Tree from '../../../../../../Tree';
// import { children } from 'react-ui-sortable-tree/example/tree';

import {Box, Icon, SvgIcon} from '@mui/material';

import {
    changeProjectTree,
    fitProjectObject,
    loadProjectTree,
    updateThumbnail,
} from '../../../../../../../+store/actions/project';
import {updateProjectAndTree as updateProject} from '../../../../../../../../dashboard/actions';
import {
    cloneAssembly,
    cloneProjectBox,
    updateProjectAssembly, deleteProjectAssembly, updateProjectAssemblyAndTree,
    updateProjectBox, deleteProjectBox, updateProjectBoxAndTree,
    updateProjectTree,
    toggleUpdateProjectUI,
    setSelectedObject,
} from '../../../../../../../+store/actions/actions';
import {
    cloneProjectPCB,
    updateProjectPCB,
    deleteProjectPCB,
    updateProjectPCBAndTree,
} from '../../../../../../../+store/actions/pcb';
import {
    cloneProjectBGA,
    updateProjectBGA,
    deleteProjectBGA,
    updateProjectBGAAndTree,
} from '../../../../../../../+store/actions/bga';
import {
    cloneProjectSource,
    updateProjectSource,
    deleteProjectSource,
    updateProjectSourceAndTree,
} from '../../../../../../../+store/actions/source';
import {
    cloneProjectTransientSource,
    updateProjectTransientSource,
    deleteProjectTransientSource,
    updateProjectTransientSourceAndTree,
    projectTransientSourcePower
} from '../../../../../../../+store/actions/transient_source';
import {
    cloneProjectPolygon,
    updateProjectPolygon,
    deleteProjectPolygon,
    updateProjectPolygonAndTree,
} from '../../../../../../../+store/actions/polygon';
import {
    cloneProjectCylinder,
    updateProjectCylinder,
    deleteProjectCylinder,
    updateProjectCylinderAndTree,
} from '../../../../../../../+store/actions/cylinder';
import {
    cloneProjectHeatsink,
    updateProjectHeatsink,
    deleteProjectHeatsink,
    updateProjectHeatsinkAndTree,
} from '../../../../../../../+store/actions/heatsink';
import {
    cloneProjectViaArray,
    updateProjectViaArray,
    deleteProjectViaArray,
    updateProjectViaArrayAndTree,
} from '../../../../../../../+store/actions/via_array';

import {
    getData as getTree,
    isLoading as loadingTree,
    updateProjectTreeUI,
    getSelectedObject,
} from '../../../../../../../+store/reducer/tree';
import {getModals} from '../../../../../../../../modals/reducer';

import {projectUtils} from '../../../../../../../utils';
import {FONT_AWESOME_ICONS, FONT_AWESOME_ICONS_TYPE_MAP, MAPPINGS} from '../../../../../../../../core/mappings';

import Spinner from '../../../../../../../../components/Spinner';
import {IconsFontAwesome} from '../../../../../../../../components/IconsFontAwesome';
import {confirmAlert} from 'react-confirm-alert';
import {ActiveInactiveStateIcon} from '../../../../../../../../components/ActiveStateIcon';
import {MINI_ICONS} from '../../../../../../assets/icons/svg/components-mini-icons/MiniIcons.map';

let loadTreeTimeout = null;
let loadedTree = false;


const BoxTree = ({
                     tree,
                     tab,
                     updateProjectTreeUI,
                     project,
                     loading,
                     loadProjectTree,
                     updateProject,
                     updateProjectTree,
                     updateThumbnail,
                     setSelectedObject,
                     modals,
                     fitProjectObject,
                     toggleUpdateProjectUI,
                     selectedObject,
                     ...restProps
                 }) => {
    const [active, setActive] = useState(null);
    const [movedNode, setMovedNode] = useState(null);
    const [initialLoading, setInitialLoading] = useState(true);
    const [localTree, setLocalTree] = useState({
        // name: '',
        children: [],
    });
    const [hoveredNodeMenuId, setHoveredNodeMenuId] = useState('');
    const [isDragging, setIsDragging] = useState(false);

    const loadTreeCallBack = useCallback(({
                                              loadTree = true,
                                              project_id = false,
                                          }) => {
        if (!loadedTree) {
            loadedTree = true;
            return;
        }

        if (loadTreeTimeout) {
            clearTimeout(loadTreeTimeout);
        }

        if (loadTree && project_id) {
            loadTreeTimeout = setTimeout(() => loadProjectTree({project_id}), 500);
        }
    }, [project]);

    const debouncedUpdateNode = useCallback(
        _.debounce(({
                        node,
                        update_tree = false,
                        loadTree = true,
                        project,
                    }) => {
            if (project && project.id) {
                const updateObject = node.type !== 'assembly'
                    ? {
                        project_id: project.id,
                        box: node,
                    }
                    // remove assembly children from request payload
                    : (() => {
                        const {
                            children,
                            ...rest
                        } = node;
                        return {
                            project_id: project.id,
                            assembly: {
                                ...rest,
                            },
                        };
                    })();

                const updateHandler = update_tree ? restProps[MAPPINGS.updateProjectObjectAndTree[node.type]] : restProps[MAPPINGS.updateProjectObject[node.type]];
                if (updateHandler) {
                    updateHandler(updateObject);
                } else {
                    console.log('Unknown object', node.type);
                }

                loadTreeCallBack({
                    loadTree,
                    project_id: project.id,
                });
            }

        }, 100),
        [project],
    );

    function handleChange(tree) {
        if (movedNode) {
            const newParentNode = projectUtils.findParent(tree, null, movedNode.id);
            const newNodeIndex = newParentNode.children.findIndex(node => node.id === movedNode.id && node.index === movedNode.index && node.type === movedNode.type);
            const updatedNode = {
                ...movedNode,
                parent: newParentNode.id || null,
                index: newNodeIndex,
            };

            debouncedUpdateNode({
                node: updatedNode,
                update_tree: true,
                project,
            });

            const updatedTree = projectUtils.updateTreeIndexes({
                treeData: tree.children,
                movedNodeId: movedNode.id,
                oldParentId: movedNode.parent,
                newParentId: updatedNode.parent,
            });

            setMovedNode(null);
            setLocalTree(prevLocalTree => {
                return {
                    ...prevLocalTree,
                    children: updatedTree,
                };
            });

            updateProjectTree(updatedTree);
        }
    }

    function canMoveNode(from) {
        if (!movedNode) {
            setMovedNode(from);
        }

        return true;
    }

    function togglePowerActiveAndVisibility(event, node, prop) {
        projectUtils.stopPreventDefault(event);

        node[prop] = !node[prop];

        debouncedUpdateNode({
            node,
            project,
            loadTree: false,
        });

        const updatedTreeDataChildren = projectUtils.updateNodeVisibility(localTree.children, node);
        setLocalTree(prevLocalTree => {
            return {
                ...prevLocalTree,
                children: updatedTreeDataChildren,
            };
        });

        updateProjectTree(updatedTreeDataChildren);
        updateThumbnail();
    }

    function deleteBox(node) {
        confirmAlert({
            title: `Deleting ${node.name}`,
            message: 'Do you wish to proceed?',
            buttons: [
                {
                    label: 'Delete',
                    onClick: () => {
                        const deleteHandler = restProps[MAPPINGS.deleteProjectObject[node.type]];

                        if (deleteHandler) {
                            deleteHandler({
                                project_id: project.id,
                                box: node,
                            });
                        } else {
                            console.log('Unknown object type', node);
                        }
                    },
                }, {
                    label: 'Cancel',
                    onClick: () => {
                    },
                },
            ],
        });
    }

    function cloneBox(event, node) {
        projectUtils.stopPreventDefault(event);

        const cloneHandler = restProps[MAPPINGS.cloneProjectObject[node.type]];

        if (cloneHandler) {
            cloneHandler({
                project_id: project.id,
                box: node,
            });
        } else {
            console.log('Unknown object type', node.type, MAPPINGS.cloneProjectObject, MAPPINGS.cloneProjectObject[node.type]);
        }
    }

    function onToggleCollapseNode(node) {
        debouncedUpdateNode({
            node,
            project,
            loadTree: false,
        });
    }

    function changeActiveNode(node) {
        if (isDragging) {
            return;
        }

        if (!active) {
            setActive(node);
            setSelectedObject(node);
        } else if (active.id !== node.id) {
            setActive(node);
            setSelectedObject(node);
        }
    }

    function toggleMenuMore(nodeId) {
        !isDragging && setHoveredNodeMenuId(nodeId || '');
    }

    function renderNode(node) {
        const currentNodeMenuIdForHover = `${node.id}-${node.type}`;
        return (
            <span
                className={cx('node', {
                    'is-active': active &&
                        node.id === active.id &&
                        node.type_id === active.type_id,
                })}
                onClick={() => changeActiveNode(node)}
                onMouseLeave={toggleMenuMore}>
                    {
                        node.id
                            ? (
                                <span style={{
                                    opacity: node.visible ? '1' : '0.5',
                                    textDecoration: node.active ? '' : 'line-through red',
                                }}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                        {
                                            MINI_ICONS[node.type] &&
                                            <SvgIcon sx={{
                                                marginRight: '12px',
                                                opacity: 0.6,
                                            }}>
                                                {MINI_ICONS[node.type]}
                                            </SvgIcon>
                                        }
                                        {node.name}
                                        {!!node.lumped?<sup style={{fontSize: "60%", backgroundColor: "blue"}}>Q</sup>:''}
                                        {!!node.bound?<sup style={{fontSize: "60%", backgroundColor: "green"}}>B</sup>:''}
                                    </Box>
                                    <span className={`inner-node ${node.type === 'assembly' ? 'assembly-node' : ''}`}
                                          onMouseDown={projectUtils.stopPreventDefault}>

                                    <div className={'more-menu-icons box-tree'}
                                         style={{
                                             zIndex: hoveredNodeMenuId !== currentNodeMenuIdForHover ? -1 : 0,
                                             opacity: hoveredNodeMenuId !== currentNodeMenuIdForHover ? 0 : 1,
                                             // animate: hoveredNodeMenuId !== node.id ? '' : 'animateNodeMenu 500ms forwards',
                                             transform: hoveredNodeMenuId !== currentNodeMenuIdForHover ? 'translateX(25px)' : 'translateX(0)',
                                         }}>
                                        <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.delete}
                                                          titleAccess={`Delete ${node.name}`}
                                                          secondClass={'smaller'}
                                                          onClickHandler={() => deleteBox(node)}/>

                                        <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.clone}
                                                          titleAccess={`Duplicate ${node.name}`}
                                                          secondClass={'smaller'}
                                                          onClickHandler={(event) => cloneBox(event, node)}/>

                                        <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.zoomToElement}
                                                          secondClass={'smaller'}
                                                          titleAccess={`Zoom to ${node.name}`}
                                                          onClickHandler={(event) => {
                                                              projectUtils.stopPreventDefault(event);
                                                              fitProjectObject(node);
                                                          }}/>
                                    </div>

                                        <ActiveInactiveStateIcon active={node.active}
                                                                 activeIconType={FONT_AWESOME_ICONS_TYPE_MAP.powerActive}
                                                                 inactiveIconType={FONT_AWESOME_ICONS_TYPE_MAP.powerInactive}
                                                                 titleActive={`Set Inactive ${node.name}`}
                                                                 titleInactive={`Set Active ${node.name}`}
                                                                 iconsSX={{
                                                                     fontSize: '1rem',
                                                                     mr: '10px',
                                                                 }}
                                                                 onClickHandler={(event) => togglePowerActiveAndVisibility(event, node, 'active')}/>

                                        <ActiveInactiveStateIcon active={node.visible}
                                                                 activeIconType={FONT_AWESOME_ICONS_TYPE_MAP.eyeVisible}
                                                                 inactiveIconType={FONT_AWESOME_ICONS_TYPE_MAP.eyeNotVisible}
                                                                 titleActive={`Hide ${node.name}`}
                                                                 titleInactive={`Show ${node.name}`}
                                                                 secondClass={'smaller'}
                                                                 iconsSX={{mr: '8px'}}
                                                                 onClickHandler={(event) => togglePowerActiveAndVisibility(event, node, 'visible')}/>



                                    <Icon title={'Show More'}
                                          baseClassName={FONT_AWESOME_ICONS[FONT_AWESOME_ICONS_TYPE_MAP.moreVertDots].main.baseClassName}
                                          className={FONT_AWESOME_ICONS[FONT_AWESOME_ICONS_TYPE_MAP.moreVertDots].className}
                                          sx={{
                                              fontSize: '1.3rem',
                                              mr: '5px',
                                          }}
                                          aria-label={'Show More'}
                                          onMouseEnter={() => toggleMenuMore(`${node.id}-${node.type}`)}/>
                                </span>
                            </span>
                            )
                            : null
                        // : <span className={'tree-root'}>MODEL</span>
                    }
                </span>
        );
    }

    useEffect(() => {
        if (localTree.children.length !== tree.length || updateProjectTreeUI !== 'noUpdate' || tab === 0) {
            setLocalTree(projectUtils.parseTree({
                tree,
                project,
            }));
        }

        updateProjectTreeUI === 'update' && toggleUpdateProjectUI();

    }, [tree, project, updateProjectTreeUI, tab]);

    useEffect(() => {
        if (!loading && tree !== null) {
            setInitialLoading(loading);
        }
    }, [loading, tree]);

    useEffect(() => {
        if (selectedObject) {
            if (!active || (active && active.id !== selectedObject.id)) {
                setActive(selectedObject);
            }

            loadTreeTimeout && clearTimeout(loadTreeTimeout);
        }
    }, [selectedObject]);

    useEffect(() => {
        if (project && (project.owner || project.edit) && !modals.length) {
            loadTreeCallBack({project_id: project.id});
        }
    }, [modals.length, project]);

    useEffect(() => {
        isDragging && loadTreeTimeout && clearTimeout(loadTreeTimeout);
    }, [isDragging]);

    if (initialLoading) {
        return <Spinner/>;
    }


    return (
        <Tree
            paddingLeft={16}
            tree={localTree}
            onChange={handleChange}
            renderNode={(node) => renderNode(node)}
            canMoveNode={canMoveNode}
            onToggleCollapseNode={onToggleCollapseNode}
            loadTreeCallBack={loadTreeCallBack}
            setIsDragging={setIsDragging}
        />
    );
};


BoxTree.propTypes = {
    loading: PropTypes.bool.isRequired,
    tree: PropTypes.array.isRequired,
    project: PropTypes.object,
    updateProjectBox: PropTypes.func.isRequired,
    updateProjectBoxAndTree: PropTypes.func.isRequired,
    updateProjectCylinder: PropTypes.func.isRequired,
    updateProjectCylinderAndTree: PropTypes.func.isRequired,
    updateProjectSource: PropTypes.func.isRequired,
    updateProjectTransientSource: PropTypes.func.isRequired,
    updateProjectSourceAndTree: PropTypes.func.isRequired,
    updateProjectTransientSourceAndTree: PropTypes.func.isRequired,
    updateProjectHeatsink: PropTypes.func.isRequired,
    updateProjectHeatsinkAndTree: PropTypes.func.isRequired,
    updateProjectBGA: PropTypes.func.isRequired,
    updateProjectBGAAndTree: PropTypes.func.isRequired,
    updateProjectViaArray: PropTypes.func.isRequired,
    updateProjectViaArrayAndTree: PropTypes.func.isRequired,
    updateProjectPCB: PropTypes.func.isRequired,
    updateProjectPCBAndTree: PropTypes.func.isRequired,
    updateProjectAssembly: PropTypes.func.isRequired,
    updateProjectAssemblyAndTree: PropTypes.func.isRequired,
    loadProjectTree: PropTypes.func.isRequired,
    changeProjectTree: PropTypes.func.isRequired,
    toggleUpdateProjectUI: PropTypes.func.isRequired,
    projectTransientSourcePower: PropTypes.func.isRequired,
    updateProjectTreeUI: PropTypes.string.isRequired,
    tab: PropTypes.number,
};

const mapStateToProps = state => ({
    loading: loadingTree(state),
    tree: getTree(state),
    updateProjectTreeUI: updateProjectTreeUI(state),
    modals: getModals(state),
    selectedObject: getSelectedObject(state),
});

const mapDispatchToProps = {
    updateProjectBox,
    deleteProjectBox,
    updateProjectBoxAndTree,
    updateProjectPolygon,
    deleteProjectPolygon,
    updateProjectPolygonAndTree,
    updateProjectCylinder,
    deleteProjectCylinder,
    updateProjectCylinderAndTree,
    updateProjectSource,
    updateProjectTransientSource,
    deleteProjectSource,
    deleteProjectTransientSource,
    updateProjectSourceAndTree,
    updateProjectTransientSourceAndTree,
    updateProjectHeatsink,
    deleteProjectHeatsink,
    updateProjectHeatsinkAndTree,
    updateProjectBGA,
    deleteProjectBGA,
    updateProjectBGAAndTree,
    updateProjectViaArray,
    deleteProjectViaArray,
    updateProjectViaArrayAndTree,
    updateProjectPCB,
    deleteProjectPCB,
    updateProjectPCBAndTree,
    updateProjectAssembly,
    deleteProjectAssembly,
    updateProjectAssemblyAndTree,
    loadProjectTree,
    changeProjectTree,
    fitProjectObject,
    cloneProjectBGA,
    cloneProjectPCB,
    cloneProjectBox,
    cloneProjectPolygon,
    cloneProjectHeatsink,
    cloneProjectViaArray,
    cloneProjectCylinder,
    cloneProjectSource,
    cloneProjectTransientSource,
    cloneAssembly,
    updateProject,
    updateProjectTree,
    updateThumbnail,
    toggleUpdateProjectUI,
    setSelectedObject,
    projectTransientSourcePower
};

export default connect(mapStateToProps, mapDispatchToProps)(BoxTree);