/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

export const ACTIVATE_USER = 'ACTIVATE_USER';
export const ACTIVATE_USER_ERROR = 'ACTIVATE_USER_ERROR';
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';

export const activateUser = payload => ({
    type: ACTIVATE_USER,
    payload,
    meta: {
        request: {
            url: `/api/user/activate/${payload.token}/`,
            method: 'POST',
            body: JSON.stringify(payload),
            mode: 'text',
        },
    },
});
