/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {
    CREATE_LIBRARY_MATERIAL_SUCCESS,
    CREATE_LIBRARY_SUCCESS,
    CREATE_MATERIAL_SUCCESS,
    DELETE_LIBRARY_MATERIAL_SUCCESS,
    DELETE_LIBRARY_SUCCESS,
    DELETE_MATERIAL_SUCCESS,
    LOAD_LIBRARY,
    LOAD_LIBRARY_ERROR,
    LOAD_LIBRARY_PERM_SUCCESS,
    LOAD_LIBRARY_SUCCESS,
    LOAD_MATERIALS,
    LOAD_MATERIALS_ERROR,
    LOAD_MATERIALS_SUCCESS,
    SET_VALIDATION_ERRORS,
    UPDATE_LIBRARY_MATERIAL_SUCCESS,
    UPDATE_LIBRARY_SUCCESS,
    UPDATE_MATERIAL_SUCCESS
} from './actions';

export const defaultState = {
    data: null,
    library: [],
    loading: false,
    permissions: [],
    validation_errors: [],
};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case LOAD_MATERIALS:
        case LOAD_LIBRARY:
            return {
                ...state,
                loading: true,
            };
        case LOAD_MATERIALS_ERROR:
        case LOAD_LIBRARY_ERROR:
            return {
                ...state,
                loading: false,
            };
        case CREATE_LIBRARY_SUCCESS:
            return {
                ...state,
                library: state.library.concat(action.payload),
            }
        case LOAD_MATERIALS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case LOAD_LIBRARY_SUCCESS:
            return {
                ...state,
                loading: false,
                library: action.payload
                    .sort((a, b) => {
                        if (a.name > b.name) return 1;
                        else if (a.name < b.name) return -1;
                        return 0;
                    }),
            };
        case UPDATE_LIBRARY_SUCCESS:
            return {
                ...state,
                library: state.library.map(lib => {
                    return lib.id === action.payload.id ? action.payload : lib
                })
            }
        case DELETE_LIBRARY_SUCCESS:
            return {
                ...state,
                library: state.library.filter(lib => {
                    return lib.id !== action.payload.libraryid
                })
            }

        case CREATE_MATERIAL_SUCCESS:
            return {
                ...state,
                data: state.data.concat(action.payload),
                validation_errors: []
            };
        case CREATE_LIBRARY_MATERIAL_SUCCESS:
            return {
                ...state,
                library: state.library.map(lib => {
                    if (lib.id === action.payload.library_id)
                        return {
                            ...lib,
                            materials: lib.materials.concat(action.payload),
                        }
                    else
                        return lib;
                }),
                validation_errors: []
            }
        case UPDATE_MATERIAL_SUCCESS:
            return {
                ...state,
                data: state.data.map(item => {
                    if (action.payload.id === item.id) {
                        return action.payload;
                    }
                    return item;
                }),
                validation_errors: []
            };
        case UPDATE_LIBRARY_MATERIAL_SUCCESS:
            return {
                ...state,
                library: state.library.map(lib => {
                    if(lib.id === action.payload.library_id)
                        return {
                        ...lib,
                            materials: lib.materials.map(material => {
                                if(material.id == action.payload.id)
                                    return action.payload;
                                else
                                    return material;
                            })
                        }
                    else
                        return lib;
                })
            }
        case DELETE_MATERIAL_SUCCESS:
            return {
                ...state,
                data: state.data.filter(item => item.id !== action.payload.materialid),
            };
        case DELETE_LIBRARY_MATERIAL_SUCCESS:
            return {
                ...state,
                library: state.library.map(lib => {
                    return {
                        ...lib,
                        materials: lib.materials.filter(material => (material.id !== action.payload.materialid))
                    }
                })
            }
        case SET_VALIDATION_ERRORS:
            return {
                ...state,
                validation_errors: action.payload
            };
        case LOAD_LIBRARY_PERM_SUCCESS:
            return {
                ...state,
                permissions: action.payload
            }
        default:
            return state;
    }
};

export const hasData = ({materials}) => !!materials.data;
export const getData = ({materials}) => materials.data;
export const getValidationErrors = ({materials}) => materials.validation_errors;
export const isLoading = ({materials}) => materials.loading;
export const getLibrary = ({materials}) => materials.library;
export const getLibraryPerms = ({materials}) => materials.permissions;
