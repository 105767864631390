/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

export const UPDATE_PROJECT_TRANSIENT_SOURCE = 'UPDATE_PROJECT_TRANSIENT_SOURCE';
export const UPDATE_PROJECT_TRANSIENT_SOURCE_ERROR = 'UPDATE_PROJECT_TRANSIENT_SOURCE_ERROR';
export const UPDATE_PROJECT_TRANSIENT_SOURCE_SUCCESS = 'UPDATE_PROJECT_TRANSIENT_SOURCE_SUCCESS';

export const updateProjectTransientSource =  payload => ({
    type: UPDATE_PROJECT_TRANSIENT_SOURCE,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/transient_source/${payload.box.id}/`,
            method: 'PUT',
            body: JSON.stringify(payload.box),
        },
    },
});

export const UPDATE_PROJECT_TRANSIENT_SOURCE_AND_TREE = 'UPDATE_PROJECT_TRANSIENT_SOURCE_AND_TREE';
export const UPDATE_PROJECT_TRANSIENT_SOURCE_AND_TREE_SUCCESS = 'UPDATE_PROJECT_TRANSIENT_SOURCE_AND_TREE_SUCCESS';
export const UPDATE_PROJECT_TRANSIENT_SOURCE_AND_TREE_ERROR = 'UPDATE_PROJECT_TRANSIENT_SOURCE_AND_TREE_ERROR';

export const updateProjectTransientSourceAndTree =  payload => ({
    type: UPDATE_PROJECT_TRANSIENT_SOURCE_AND_TREE,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/transient_source/${payload.box.id}/`,
            method: 'PUT',
            body: JSON.stringify(payload.box),
        },
    },
});


export const CREATE_PROJECT_TRANSIENT_SOURCE = 'CREATE_PROJECT_TRANSIENT_SOURCE';
export const CREATE_PROJECT_TRANSIENT_SOURCE_ERROR = 'CREATE_PROJECT_TRANSIENT_SOURCE_ERROR';
export const CREATE_PROJECT_TRANSIENT_SOURCE_SUCCESS = 'CREATE_PROJECT_TRANSIENT_SOURCE_SUCCESS';

export const createProjectTransientSource =  payload => ({
    type: CREATE_PROJECT_TRANSIENT_SOURCE,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/transient_source/`,
            method: 'POST',
            body: JSON.stringify(payload.box),
        },
    },
});


export const CLONE_PROJECT_TRANSIENT_SOURCE = 'CLONE_PROJECT_TRANSIENT_SOURCE';
export const CLONE_PROJECT_TRANSIENT_SOURCE_ERROR = 'CLONE_PROJECT_TRANSIENT_SOURCE_ERROR';
export const CLONE_PROJECT_TRANSIENT_SOURCE_SUCCESS = 'CLONE_PROJECT_TRANSIENT_SOURCE_SUCCESS';

export const cloneProjectTransientSource =  payload => ({
    type: CLONE_PROJECT_TRANSIENT_SOURCE,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/transient_source/${payload.box.id}/clone/`,
            method: 'POST',
            body: JSON.stringify(payload.box),
        },
    },
});


export const DELETE_PROJECT_TRANSIENT_SOURCE = 'DELETE_PROJECT_TRANSIENT_SOURCE';
export const DELETE_PROJECT_TRANSIENT_SOURCE_ERROR = 'DELETE_PROJECT_TRANSIENT_SOURCE_ERROR';
export const DELETE_PROJECT_TRANSIENT_SOURCE_SUCCESS = 'DELETE_PROJECT_TRANSIENT_SOURCE_SUCCESS';

export const deleteProjectTransientSource = payload => ({
    type: DELETE_PROJECT_TRANSIENT_SOURCE,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/transient_source/${payload.box.id}/`,
            method: 'DELETE',
        },
    }
});


export const PROJECT_TRANSIENT_SOURCE_POWER = 'PROJECT_TRANSIENT_SOURCE_POWER';
export const PROJECT_TRANSIENT_SOURCE_POWER_ERROR = 'PROJECT_TRANSIENT_SOURCE_POWER_ERROR';
export const PROJECT_TRANSIENT_SOURCE_POWER_SUCCESS = 'PROJECT_TRANSIENT_SOURCE_POWER_SUCCESS';

export const projectTransientSourcePower = payload => ({
    type: PROJECT_TRANSIENT_SOURCE_POWER,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/transient_source/${payload.box.id}/power/`,
            method: 'GET',
        },
    }
});


export const UPDATE_PROJECT_TRANSIENT_SOURCE_POWER = 'UPDATE_PROJECT_TRANSIENT_SOURCE_POWER';
export const UPDATE_PROJECT_TRANSIENT_SOURCE_POWER_ERROR = 'UPDATE_PROJECT_TRANSIENT_SOURCE_POWER_ERROR';
export const UPDATE_PROJECT_TRANSIENT_SOURCE_POWER_SUCCESS = 'UPDATE_PROJECT_TRANSIENT_SOURCE_POWER_SUCCESS';

export const updateProjectTransientSourcePower = payload => ({
    type: UPDATE_PROJECT_TRANSIENT_SOURCE_POWER,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/transient_source/${payload.box.id}/power/`,
            method: 'PUT',
        },
    }
});


export const DELETE_PROJECT_TRANSIENT_SOURCE_POWER = 'DELETE_PROJECT_TRANSIENT_SOURCE_POWER';
export const DELETE_PROJECT_TRANSIENT_SOURCE_POWER_ERROR = 'DELETE_PROJECT_TRANSIENT_SOURCE_POWER_ERROR';
export const DELETE_PROJECT_TRANSIENT_SOURCE_POWER_SUCCESS = 'DELETE_PROJECT_TRANSIENT_SOURCE_POWER_SUCCESS';

export const deleteProjectTransientSourcePower = payload => ({
    type: DELETE_PROJECT_TRANSIENT_SOURCE_POWER,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/transient_source/${payload.box.id}/power/${payload.power.id}`,
            method: 'DELETE',
        },
    }
});

export const PROJECT_TRANSIENT_SOURCE_POWER_ID = 'PROJECT_TRANSIENT_SOURCE_POWER_ID';
export const PROJECT_TRANSIENT_SOURCE_POWER_ID_ERROR = 'PROJECT_TRANSIENT_SOURCE_POWER_ID_ERROR';
export const PROJECT_TRANSIENT_SOURCE_POWER_ID_SUCCESS = 'PROJECT_TRANSIENT_SOURCE_POWER_ID_SUCCESS';
export const projectTransientSourcePowerById = payload => ({
    type: PROJECT_TRANSIENT_SOURCE_POWER_ID,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/transient_source/${payload.box.id}/power/${payload.power.id}`,
            method: 'GET',
        },
    }
});


export const UPDATE_PROJECT_TRANSIENT_SOURCE_POWER_ID = 'UPDATE_PROJECT_TRANSIENT_SOURCE_POWER_ID';
export const UPDATE_PROJECT_TRANSIENT_SOURCE_POWER_ID_ERROR = 'UPDATE_PROJECT_TRANSIENT_SOURCE_POWER_ID_ERROR';
export const UPDATE_PROJECT_TRANSIENT_SOURCE_POWER_ID_SUCCESS = 'UPDATE_PROJECT_TRANSIENT_SOURCE_POWER_ID_SUCCESS';

export const updateProjectTransientSourcePowerById = payload => ({
    type: UPDATE_PROJECT_TRANSIENT_SOURCE_POWER_ID,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/transient_source/${payload.box.id}/power/${payload.power.id}`,
            method: 'PUT',
        },
    }
});


export const DELETE_PROJECT_TRANSIENT_SOURCE_POWER_ID = 'DELETE_PROJECT_TRANSIENT_SOURCE_POWER_ID';
export const DELETE_PROJECT_TRANSIENT_SOURCE_POWER_ID_ERROR = 'DELETE_PROJECT_TRANSIENT_SOURCE_ERROR';
export const DELETE_PROJECT_TRANSIENT_SOURCE_POWER_ID_SUCCESS = 'DELETE_PROJECT_TRANSIENT_SOURCE_SUCCESS';

export const deleteProjectTransientSourcePowerById = payload => ({
    type: DELETE_PROJECT_TRANSIENT_SOURCE_POWER_ID,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/transient_source/${payload.box.id}/power/`,
            method: 'DELETE',
        },
    }
});