/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

export const MAIN_FOLDER_NAMES = {
    myProjects: 'My Projects',
    sharedProjects: 'Shared with me',
};

export const DND_TYPE = 'projectsTree';
