/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React from 'react';
import {Link} from 'react-router-dom';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import makeStyles from '@mui/styles/makeStyles';

export default function NotFound () {
    return (
        <div>
            <h1>404</h1>
            <p>Page not found</p>
            <p>Maybe the page you are looking for has been removed, or you typed in the wrong URL</p>
            <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                <Button onClick={()=> window.history.back()} >GO BACK</Button>
                <Link to="/" style={{ textDecoration: 'none' }} ><Button>GO TO HOMEPAGE</Button></Link>
            </ButtonGroup>
        </div>
    );
};

