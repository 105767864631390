/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React from 'react';
import PropTypes from "prop-types";
import {getData as getProject, getProjectMaterials} from "../../../+store/reducer/project";
import {getValidationErrors} from "../../../+store/reducer/validation_errors";
import {getData as getTree, getSelectedObject} from "../../../+store/reducer/tree";
import {setSelectedObject, updateSelectedObject} from "../../../+store/actions/actions";
import {connect} from "react-redux";
import {withSettingsTemplate} from "./SettingsTemplate";
import {
    createProjectPolygon,
    deleteProjectPolygon,
    updateProjectPolygon,
    updateProjectPolygonAndTree
} from "../../../+store/actions/polygon";
import {
    FormControl,
    FormGroup,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextareaAutosize,
    Tooltip
} from "@mui/material";
import {InputFields, SettingsSubtitle, SettingsTabPlane} from "../../../../components/BasicSettings";
import HeatTransferCoeff from "../../../../components/HeatTranserCoeff";
import {SwitchToggle} from "../../../../components/SwitchToggle";
import {InfoOutlined} from "@mui/icons-material";
import BasicTabs from "../../../../components/BasicTabs";
import {message_floating_point} from "../../../utils";
import {renderMaterialListSelectOptions} from "./commonComponents";

const PolygonSettingsTemplate = ({
                                            // passed from SettingsTemplate
                                            boxHasChange,
                                            handleRevert,
                                            handleCancel,
                                            handleChange,
                                            handleSubmit,
                                            box,
                                            state,
                                            materials,
                                            validation_errors
                                        }) => {
    const tabs = [
        {
            label: 'Spatial', Component: <FormGroup style={{height: '100%'}}>
                <SettingsTabPlane
                    hasChange={boxHasChange()}
                    onRevert={box.id !== 'creating' ? handleRevert : handleCancel}
                    onSave={handleSubmit}
                    object={box}>
                    <InputFields
                        field_names={['name']}
                        state={state}
                        ids={'polygonname'}
                        validation_errors={validation_errors}
                        handleChange={handleChange}/>
                    <InputFields
                        groupTitle={'Location'}
                        field_names={['x', 'y', 'z']}
                        units={'mm'}
                        state={state}
                        ids={'boxlocationid'}
                        validation_errors={validation_errors}
                        handleChange={handleChange}/>
                    <SettingsSubtitle title={'Polygon Points'}/>
                    <FormControl variant={'standard'} fullWidth={true}>
                        <TextareaAutosize aria-label={'minimum height'}
                                          minRows={2}
                                          placeholder={'Input polygon points: 0.0 0.1, 0.1 0.2, .., 0.0 0.1'}
                                          style={{width: '100%', background: '#141414', color: '#fff'}}
                                          defaultValue={state.polygon || ''}
                                          onChange={(event) => handleChange('polygon', event.target.value)}
                        />
                    </FormControl>
                    <InputFields
                        field_names={['thickness']}
                        units={'mm'}
                        state={state}
                        ids={'polygonthickness'}
                        validation_errors={validation_errors}
                        handleChange={handleChange}/>
                    <FormControl variant={'standard'} fullWidth={true}>
                        <InputLabel error={!!validation_errors['plane']}
                                    id={'planelabel'}>
                            Plane
                        </InputLabel>
                        <Select
                            id={'planeid'}
                            labelId={'planelabel'}
                            value={state.plane || 'XY'}
                            onChange={(event) => handleChange('plane', event.target.value)}
                            label={'Plane'}
                        >
                            <MenuItem key={'XY'} value={'XY'}>XY</MenuItem>
                            <MenuItem key={'XZ'} value={'XZ'}>XZ</MenuItem>
                            <MenuItem key={'YZ'} value={'YZ'}>YZ</MenuItem>
                        </Select>
                        {validation_errors['plane'] &&
                            <FormHelperText>{validation_errors['plane']}</FormHelperText>
                        }
                    </FormControl>
                </SettingsTabPlane>
            </FormGroup>
        },
        {
            label: 'Thermal',
            Component: <FormGroup style={{height: '100%'}}>
                <SettingsTabPlane hasChange={boxHasChange()}
                                  onRevert={box.id !== 'creating' ? handleRevert : handleCancel}
                                  onSave={handleSubmit}
                                  object={box}>
                    <FormControl variant={'standard'} fullWidth={true} error={!!validation_errors['material']}>
                        <InputLabel id={'materiallabel'}>Material</InputLabel>
                        <Select
                            labelId={'materiallabel'}
                            id={'boxmaterial'}
                            value={state.material}
                            onChange={(event) => handleChange('material', event.target.value)}
                            label={'Material'}
                        >
                            {renderMaterialListSelectOptions(materials)}
                        </Select>
                        {validation_errors['material'] &&
                            <FormHelperText>{validation_errors['material']}</FormHelperText>}
                    </FormControl>
                    <InputFields
                        field_names={['power']}
                        state={state}
                        ids={'boxpowerid'}
                        units={'mW'}
                        validation_errors={validation_errors}
                        handleChange={handleChange}/>
                    <HeatTransferCoeff
                        fullWidth
                        variant={'standard'}
                        validators={[]}
                        value={state.hc || ''}
                        onChange={(event) => handleChange('hc', event.target.value)}
                        error={!!validation_errors['hc']}
                        helperText={validation_errors['hc']}
                        errorMessages={[message_floating_point]}
                    />
                    <FormControl>
                        <SwitchToggle
                            id={'bindcheckbox'}
                            booleans={true}
                            onChange={() => handleChange('bound', !state.bound)}
                            checked={state.bound}
                            title={'Bind to ambient'}
                            orientation={'vertical'}
                        />
                    </FormControl>
                    <Tooltip title={
                        <span>
                        Forces all exposed object walls to ambient temperature.
                        </span>
                    }>
                        <InfoOutlined fontSize={'inherit'}/>
                    </Tooltip>
                </SettingsTabPlane>
            </FormGroup>
        }
    ];

    return <BasicTabs tabs={tabs} title={'Properties'} isSwitch={true}/>

}
PolygonSettingsTemplate.propTypes = {
    boxHasChange: PropTypes.func.isRequired,
    handleRevert: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    form: PropTypes.object,
    createProjectObject: PropTypes.func.isRequired,
    updateProjectObjectAndTree: PropTypes.func.isRequired,
    box: PropTypes.object,
    state: PropTypes.object.isRequired,
    validation_errors: PropTypes.object,
    materials: PropTypes.array
};

const mapStateToProps = state => ({
    materials: getProjectMaterials(state) || [],
    validation_errors: getValidationErrors(state),
    project_id: getProject(state).id,
    tree: getTree(state),
    box: getSelectedObject(state),
});

const mapDispatchToProps = {
    createProjectObject: createProjectPolygon,
    updateProjectObject: updateProjectPolygon,
    updateProjectObjectAndTree: updateProjectPolygonAndTree,
    deleteProjectObject: deleteProjectPolygon,
    setSelectedObject,
    updateSelectedObject
};

export default connect(mapStateToProps, mapDispatchToProps)(withSettingsTemplate(PolygonSettingsTemplate));