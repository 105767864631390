/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import * as THREE from 'three';
import SpriteText from 'three-spritetext';
import * as wkt from "wicket";

export function createMovingObjects() {
    let moving = {
        box: new THREE.Group(),
        cylinder: new THREE.Group(),
        ball_array: new THREE.Group(),
        via_array: new THREE.Group(),
        chip: new THREE.Group(),
        heatsink: new THREE.Group(),
        pcb: new THREE.Group(),
        pkg: new THREE.Group(),
        source: new THREE.Group(),
        transient_source: new THREE.Group(),
        polygon: new THREE.Group(),

    };

    let objMaterial = new THREE.MeshBasicMaterial({
            color: 0xb0b0b0,
            transparent: true,
            opacity: 0.8,
        }),
        edgeMaterial = new THREE.MeshBasicMaterial({color: 0x00c0c0c});

    // box
    moving.box.add(
        new THREE.Mesh(
            new THREE.BoxBufferGeometry(4, 4, 1),
            objMaterial
        )
    );
    moving.box.add(
        new THREE.LineSegments(
            new THREE.EdgesGeometry(
                new THREE.BoxGeometry(4, 4, 1)
            ),
            edgeMaterial
        )
    );
    moving.box.children.forEach(obj => {
        obj.translateZ(0.5);
    });

    // cylinder
    moving.cylinder.add(
        new THREE.Mesh(
            new THREE.CylinderBufferGeometry(2, 2, 5, 16),
            objMaterial
        )
    );
    moving.cylinder.add(
        new THREE.LineSegments(
            new THREE.EdgesGeometry(
                new THREE.CylinderBufferGeometry(2, 2, 5, 16),
            ),
            edgeMaterial
        )
    );
    moving.cylinder.children.forEach(obj => {
        obj.rotateX(Math.PI / 2);
        obj.translateZ(0.5);
    });

    // source
    moving.source.add(
        new THREE.Mesh(
            new THREE.PlaneGeometry(20, 20),
            objMaterial
        )
    );
    moving.source.children.forEach(obj => {
        obj.translateZ(0.05);
    });

    // transient_source
    moving.transient_source.add(
        new THREE.Mesh(
            new THREE.PlaneGeometry(20, 20),
            objMaterial
        )
    );
    moving.transient_source.children.forEach(obj => {
        obj.translateZ(0.05);
    });

    // polygon
    moving.polygon.add(
        new THREE.Mesh(
            new THREE.BoxBufferGeometry(4, 4, 1),
            objMaterial
        )
    );
    moving.polygon.add(
        new THREE.LineSegments(
            new THREE.EdgesGeometry(
                new THREE.BoxGeometry(4, 4, 1)
            ),
            edgeMaterial
        )
    );
    moving.polygon.children.forEach(obj => {
        obj.translateZ(0.5);
    });

    // heatsink
    let base = new THREE.Mesh(
        new THREE.BoxBufferGeometry(4, 4, 0.2),
        objMaterial
    );
    base.translateZ(0.1);
    moving.heatsink.add(base);
    let base_line = new THREE.LineSegments(
        new THREE.EdgesGeometry(
            new THREE.BoxGeometry(4, 4, 0.2)
        ),
        edgeMaterial
    );
    base_line.translateZ(0.1);
    moving.heatsink.add(base_line);
    for (let fin = -2; fin <= 2; ++fin) {
        let base = new THREE.Mesh(
            new THREE.BoxBufferGeometry(4, 0.3, 0.8),
            objMaterial
        );
        base.translateZ(0.6);
        base.translateY(fin * 0.925);
        moving.heatsink.add(base);
        let base_line = new THREE.LineSegments(
            new THREE.EdgesGeometry(
                new THREE.BoxGeometry(4, 0.3, 0.8)
            ),
            edgeMaterial
        );
        base_line.translateZ(0.6);
        base_line.translateY(fin * 0.925);
        moving.heatsink.add(base_line);
    }
    moving.heatsink.children.forEach(obj => {
        obj.translateZ(0.5);
    });

    // bga
    for (let x = 0; x < 4; ++x) {
        for (let y = 0; y < 4; ++y) {
            let sphere = new THREE.SphereBufferGeometry(0.3, 7, 7),
                mesh = new THREE.Mesh(
                    sphere,
                    objMaterial),
                wireframe = new THREE.WireframeGeometry(sphere),
                lines = new THREE.LineSegments(wireframe, edgeMaterial);
            mesh.position.set(x - 1.5, y - 1.5, 0.15);
            lines.position.set(x - 1.5, y - 1.5, 0.15);
            moving.ball_array.add(mesh, lines);
        }
    }

    // via array
    for (let x = 0; x < 4; ++x) {
        for (let y = 0; y < 4; ++y) {
            let cylinder = new THREE.CylinderBufferGeometry(0.1, 0.1, 1, 16),
                mesh = new THREE.Mesh(
                    cylinder,
                    objMaterial),
                wireframe = new THREE.WireframeGeometry(cylinder),
                lines = new THREE.LineSegments(wireframe, edgeMaterial);
            mesh.rotateX(Math.PI / 2);
            mesh.position.set(x - 1.5, y - 1.5, 0.5);
            lines.rotateX(Math.PI / 2);
            lines.position.set(x - 1.5, y - 1.5, 0.5);
            moving.via_array.add(mesh, lines);
        }
    }

    // pcb
    moving.pcb.add(
        new THREE.Mesh(
            new THREE.BoxBufferGeometry(4, 4, 0.1),
            objMaterial
        )
    );
    moving.pcb.add(
        new THREE.LineSegments(
            new THREE.EdgesGeometry(
                new THREE.BoxGeometry(4, 4, 0.1)
            ),
            edgeMaterial
        )
    );
    moving.pcb.children.forEach(obj => {
        obj.translateZ(0.05);
    });

    return moving;
}

export function createAxesScene(fitObjects, fitFn, axisLength) {
    let axesScene = new THREE.Scene();

    axesScene.add(new THREE.AmbientLight());

    axesScene.add(new THREE.AxesHelper(axisLength));

    let label = new SpriteText('X');
    label.color = 'red';
    label.textHeight = 3;
    label.position.x = axisLength;
    axesScene.add(label);
    label = new SpriteText('Y');
    label.color = 'green';
    label.textHeight = 3;
    label.position.y = axisLength;
    axesScene.add(label);
    label = new SpriteText('Z');
    label.color = 'blue';
    label.textHeight = 3;
    label.position.z = axisLength;
    axesScene.add(label);

    let axesBoxSize = 10;
    let front = new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.6 * axesBoxSize, 0, 0.6 * axesBoxSize),
            new THREE.MeshLambertMaterial({color: 0xe0e0e0})
        ),
        back = new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.6 * axesBoxSize, 0, 0.6 * axesBoxSize),
            new THREE.MeshLambertMaterial({color: 0xe0e0e0})
        ),
        top = new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.6 * axesBoxSize, 0.6 * axesBoxSize, 0),
            new THREE.MeshLambertMaterial({color: 0xe0e0e0})
        ),
        bottom = new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.6 * axesBoxSize, 0.6 * axesBoxSize, 0),
            new THREE.MeshLambertMaterial({color: 0xe0e0e0})
        ),
        left = new THREE.Mesh(
            new THREE.BoxBufferGeometry(0, 0.6 * axesBoxSize, 0.6 * axesBoxSize),
            new THREE.MeshLambertMaterial({color: 0xe0e0e0})
        ),
        right = new THREE.Mesh(
            new THREE.BoxBufferGeometry(0, 0.6 * axesBoxSize, 0.6 * axesBoxSize),
            new THREE.MeshLambertMaterial({color: 0xe0e0e0})
        ),
        top_left = new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.2 * axesBoxSize, 0.6 * axesBoxSize, 0.2 * axesBoxSize),
            new THREE.MeshLambertMaterial({color: 0xd0d0d0})
        ),
        top_right = new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.2 * axesBoxSize, 0.6 * axesBoxSize, 0.2 * axesBoxSize),
            new THREE.MeshLambertMaterial({color: 0xd0d0d0})
        ),
        top_front = new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.6 * axesBoxSize, 0.2 * axesBoxSize, 0.2 * axesBoxSize),
            new THREE.MeshLambertMaterial({color: 0xd0d0d0})
        ),
        top_back = new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.6 * axesBoxSize, 0.2 * axesBoxSize, 0.2 * axesBoxSize),
            new THREE.MeshLambertMaterial({color: 0xd0d0d0})
        ),
        bottom_left = new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.2 * axesBoxSize, 0.6 * axesBoxSize, 0.2 * axesBoxSize),
            new THREE.MeshLambertMaterial({color: 0xd0d0d0})
        ),
        bottom_right = new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.2 * axesBoxSize, 0.6 * axesBoxSize, 0.2 * axesBoxSize),
            new THREE.MeshLambertMaterial({color: 0xd0d0d0})
        ),
        bottom_front = new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.6 * axesBoxSize, 0.2 * axesBoxSize, 0.2 * axesBoxSize),
            new THREE.MeshLambertMaterial({color: 0xd0d0d0})
        ),
        bottom_back = new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.6 * axesBoxSize, 0.2 * axesBoxSize, 0.2 * axesBoxSize),
            new THREE.MeshLambertMaterial({color: 0xd0d0d0})
        ),
        left_front = new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.2 * axesBoxSize, 0.2 * axesBoxSize, 0.6 * axesBoxSize),
            new THREE.MeshLambertMaterial({color: 0xd0d0d0})
        ),
        left_back = new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.2 * axesBoxSize, 0.2 * axesBoxSize, 0.6 * axesBoxSize),
            new THREE.MeshLambertMaterial({color: 0xd0d0d0})
        ),
        right_front = new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.2 * axesBoxSize, 0.2 * axesBoxSize, 0.6 * axesBoxSize),
            new THREE.MeshLambertMaterial({color: 0xd0d0d0})
        ),
        right_back = new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.2 * axesBoxSize, 0.2 * axesBoxSize, 0.6 * axesBoxSize),
            new THREE.MeshLambertMaterial({color: 0xd0d0d0})
        ),
        tlf = new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.2 * axesBoxSize, 0.2 * axesBoxSize, 0.2 * axesBoxSize),
            new THREE.MeshLambertMaterial({color: 0xc0c0c0})
        ),
        tlb = new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.2 * axesBoxSize, 0.2 * axesBoxSize, 0.2 * axesBoxSize),
            new THREE.MeshLambertMaterial({color: 0xc0c0c0})
        ),
        trf = new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.2 * axesBoxSize, 0.2 * axesBoxSize, 0.2 * axesBoxSize),
            new THREE.MeshLambertMaterial({color: 0xc0c0c0})
        ),
        trb = new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.2 * axesBoxSize, 0.2 * axesBoxSize, 0.2 * axesBoxSize),
            new THREE.MeshLambertMaterial({color: 0xc0c0c0})
        ),
        blf = new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.2 * axesBoxSize, 0.2 * axesBoxSize, 0.2 * axesBoxSize),
            new THREE.MeshLambertMaterial({color: 0xc0c0c0})
        ),
        blb = new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.2 * axesBoxSize, 0.2 * axesBoxSize, 0.2 * axesBoxSize),
            new THREE.MeshLambertMaterial({color: 0xc0c0c0})
        ),
        brf = new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.2 * axesBoxSize, 0.2 * axesBoxSize, 0.2 * axesBoxSize),
            new THREE.MeshLambertMaterial({color: 0xc0c0c0})
        ),
        brb = new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.2 * axesBoxSize, 0.2 * axesBoxSize, 0.2 * axesBoxSize),
            new THREE.MeshLambertMaterial({color: 0xc0c0c0})
        )
    ;

    let distance_mult = 1.1;
    front.position.y = -5;
    front.userData.fit = fitFn('front', distance_mult);
    back.position.y = 5;
    back.userData.fit = fitFn('back', distance_mult);
    top.position.z = 5;
    top.userData.fit = fitFn('top', distance_mult);
    bottom.position.z = -5;
    bottom.userData.fit = fitFn('bottom', distance_mult);
    left.position.x = -5;
    left.userData.fit = fitFn('left', distance_mult);
    right.position.x = 5;
    right.userData.fit = fitFn('right', distance_mult);
    top_left.position.x = -4;
    top_left.position.z = 4;
    top_left.userData.fit = fitFn('top_left', distance_mult);
    top_right.position.x = 4;
    top_right.position.z = 4;
    top_right.userData.fit = fitFn('top_right', distance_mult);
    top_front.position.y = -4;
    top_front.position.z = 4;
    top_front.userData.fit = fitFn('top_front', distance_mult);
    top_back.position.y = 4;
    top_back.position.z = 4;
    top_back.userData.fit = fitFn('top_back', distance_mult);

    tlf.position.x = -4;
    tlf.position.y = -4;
    tlf.position.z = 4;
    tlf.userData.fit = fitFn('top_left_front', distance_mult);
    tlb.position.x = -4;
    tlb.position.y = 4;
    tlb.position.z = 4;
    tlb.userData.fit = fitFn('top_left_back', distance_mult);
    trf.position.x = 4;
    trf.position.y = -4;
    trf.position.z = 4;
    trf.userData.fit = fitFn('top_right_front', distance_mult);
    trb.position.x = 4;
    trb.position.y = 4;
    trb.position.z = 4;
    trb.userData.fit = fitFn('top_right_back', distance_mult);

    bottom_left.position.x = -4;
    bottom_left.position.z = -4;
    bottom_left.userData.fit = fitFn('bottom_left', distance_mult);
    bottom_right.position.x = 4;
    bottom_right.position.z = -4;
    bottom_right.userData.fit = fitFn('bottom_right', distance_mult);
    bottom_front.position.y = -4;
    bottom_front.position.z = -4;
    bottom_front.userData.fit = fitFn('bottom_front', distance_mult);
    bottom_back.position.y = 4;
    bottom_back.position.z = -4;
    bottom_back.userData.fit = fitFn('bottom_back', distance_mult);

    blf.position.x = -4;
    blf.position.y = -4;
    blf.position.z = -4;
    blf.userData.fit = fitFn('bottom_left_front', distance_mult);
    blb.position.x = -4;
    blb.position.y = 4;
    blb.position.z = -4;
    blb.userData.fit = fitFn('bottom_left_back', distance_mult);
    brf.position.x = 4;
    brf.position.y = -4;
    brf.position.z = -4;
    brf.userData.fit = fitFn('bottom_right_front', distance_mult);
    brb.position.x = 4;
    brb.position.y = 4;
    brb.position.z = -4;
    brb.userData.fit = fitFn('bottom_right_back', distance_mult);

    left_front.position.x = -4;
    left_front.position.y = -4;
    left_front.userData.fit = fitFn('left_front', distance_mult);
    left_back.position.x = -4;
    left_back.position.y = 4;
    left_back.userData.fit = fitFn('left_back', distance_mult);
    right_front.position.x = 4;
    right_front.position.y = -4;
    right_front.userData.fit = fitFn('right_front', distance_mult);
    right_back.position.x = 4;
    right_back.position.y = 4;
    right_back.userData.fit = fitFn('right_back', distance_mult);

    fitObjects.push(front, back, top, bottom, left, right,
        top_left, top_right, top_front, top_back,
        tlf, tlb, trf, trb,
        bottom_left, bottom_right, bottom_front, bottom_back,
        blf, blb, brf, brb,
        left_front, left_back, right_front, right_back
    );
    axesScene.add(...fitObjects);
    axesScene.add(
        new THREE.LineSegments(
            new THREE.EdgesGeometry(
                new THREE.BoxGeometry(10, 10, 10)
            ),
            new THREE.MeshBasicMaterial({color: 0xc0c0c0})
        )
    );

    return axesScene;
}

export function getBBox(tree, bbox, all) {
    tree
        .filter(obj => (all || obj.visible && obj.active))
        .forEach(obj => {
            if (obj.children)
                getBBox(obj.children, bbox);
            else {
                switch (obj.type) {
                    case 'box':
                    case 'cylinder':
                    case 'source':
                    case 'transient_source':
                    case 'pcb':
                    case 'ball_array':
                    case 'via_array':
                        bbox.expandByPoint(new THREE.Vector3(+obj.x_calc, +obj.y_calc, +obj.z_calc));
                        bbox.expandByPoint(new THREE.Vector3(+obj.x_calc + 1 * obj.dx_calc, +obj.y_calc + 1 * obj.dy_calc, +obj.z_calc + 1 * obj.dz_calc));
                        break;
                    case 'heatsink':
                        bbox.expandByPoint(new THREE.Vector3(+obj.x_calc, +obj.y_calc, +obj.z_calc));
                        bbox.expandByPoint(new THREE.Vector3(+obj.x_calc + 1 * obj.base_dx_calc, +obj.y_calc + 1 * obj.base_dy_calc, +obj.z_calc + 1 * obj.base_dz_calc + 1 * obj.fin_height_calc));
                        break;
                    case 'polygon':
                        let parser = new wkt.Wkt(),
                            objs = parser.read(obj.polygon).toJson(),
                            min1 = +Infinity,
                            min2 = +Infinity,
                            max1 = -Infinity,
                            max2 = -Infinity
                        ;
                        let coords;
                        if (objs.type == 'MultiPolygon')
                            coords = objs.coordinates;
                        else if (objs.type == 'Polygon')
                            coords = [objs.coordinates];

                        coords.forEach(points => {
                            points[0].forEach(point => {
                                min1 = Math.min(min1, point[0]);
                                max1 = Math.max(max1, point[0]);
                                min2 = Math.min(min2, point[1]);
                                max2 = Math.max(max2, point[1]);
                            })

                        });
                        switch (obj.plane) {
                            case 'XY':
                                bbox.expandByPoint(new THREE.Vector3(obj.x_calc + min1, obj.y_calc + min2, obj.z_calc));
                                bbox.expandByPoint(new THREE.Vector3(obj.x_calc + min1, obj.y_calc + min2, obj.z_calc + obj.thickness_calc));
                                bbox.expandByPoint(new THREE.Vector3(obj.x_calc + max1, obj.y_calc + max2, obj.z_calc));
                                bbox.expandByPoint(new THREE.Vector3(obj.x_calc + max1, obj.y_calc + max2, obj.z_calc + obj.thickness_calc));
                                break;
                            case 'XZ':
                                bbox.expandByPoint(new THREE.Vector3(obj.x_calc + min1, obj.y_calc, obj.z_calc + min2));
                                bbox.expandByPoint(new THREE.Vector3(obj.x_calc + min1, obj.y_calc + obj.thickness_calc, obj.z_calc + min2));
                                bbox.expandByPoint(new THREE.Vector3(obj.x_calc + max1, obj.y_calc, obj.z_calc + max2));
                                bbox.expandByPoint(new THREE.Vector3(obj.x_calc + max1, obj.y_calc + obj.thickness_calc, obj.z_calc + max2));
                                break;
                            case 'YZ':
                                bbox.expandByPoint(new THREE.Vector3(obj.x_calc, obj.y_calc + min1, obj.z_calc + min2));
                                bbox.expandByPoint(new THREE.Vector3(obj.x_calc + obj.thickness_calc, obj.y_calc + min1, obj.z_calc + min2));
                                bbox.expandByPoint(new THREE.Vector3(obj.x_calc, obj.y_calc + max1, obj.z_calc + max2));
                                bbox.expandByPoint(new THREE.Vector3(obj.x_calc + obj.thickness_calc, obj.y_calc + max1, obj.z_calc + max2));
                                break;
                        }
                        break;
                }
            }
        });
}