/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

export const UPDATE_PROJECT_PCB = 'UPDATE_PROJECT_PCB';
export const UPDATE_PROJECT_PCB_ERROR = 'UPDATE_PROJECT_PCB_ERROR';
export const UPDATE_PROJECT_PCB_SUCCESS = 'UPDATE_PROJECT_PCB_SUCCESS';

export const updateProjectPCB = payload => ({
    type: UPDATE_PROJECT_PCB,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/pcb/${payload.box.id}/`,
            method: 'PUT',
            body: JSON.stringify(payload.box),
        },
    },
});


export const UPDATE_PROJECT_PCB_AND_TREE = 'UPDATE_PROJECT_PCB_AND_TREE';
export const UPDATE_PROJECT_PCB_AND_TREE_ERROR = 'UPDATE_PROJECT_PCB_AND_TREE_ERROR';
export const UPDATE_PROJECT_PCB_AND_TREE_SUCCESS = 'UPDATE_PROJECT_PCB_AND_TREE_SUCCESS';

export const updateProjectPCBAndTree = payload => ({
    type: UPDATE_PROJECT_PCB_AND_TREE,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/pcb/${payload.box.id}/`,
            method: 'PUT',
            body: JSON.stringify(payload.box),
        },
    },
});


export const CREATE_PROJECT_PCB = 'CREATE_PROJECT_PCB';
export const CREATE_PROJECT_PCB_ERROR = 'CREATE_PROJECT_PCB_ERROR';
export const CREATE_PROJECT_PCB_SUCCESS = 'CREATE_PROJECT_PCB_SUCCESS';

export const createProjectPCB = payload => ({
    type: CREATE_PROJECT_PCB,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/pcb/`,
            method: 'POST',
            body: JSON.stringify(payload.box),
        },
    },
});


export const CLONE_PROJECT_PCB = 'CLONE_PROJECT_PCB';
export const CLONE_PROJECT_PCB_ERROR = 'CLONE_PROJECT_PCB_ERROR';
export const CLONE_PROJECT_PCB_SUCCESS = 'CLONE_PROJECT_PCB_SUCCESS';

export const cloneProjectPCB = payload => ({
    type: CLONE_PROJECT_PCB,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/pcb/${payload.box.id}/clone/`,
            method: 'POST',
        },
    },
});


export const DELETE_PROJECT_PCB = 'DELETE_PROJECT_PCB';
export const DELETE_PROJECT_PCB_ERROR = 'DELETE_PROJECT_PCB_ERROR';
export const DELETE_PROJECT_PCB_SUCCESS = 'DELETE_PROJECT_PCB_SUCCESS';

export const deleteProjectPCB = payload => ({
    type: DELETE_PROJECT_PCB,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/pcb/${payload.box.id}/`,
            method: 'DELETE',
        },
    },
});