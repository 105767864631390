/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {call, put, select, takeLatest} from 'redux-saga/effects';
import {LOCATION_CHANGE, push} from 'react-router-redux';

import {LOGIN_SUCCESS, LOGOUT, logout, setToken} from './actions';
import {isLogged as getIsLogged} from './reducer';

const saveToken = token => window.localStorage.setItem('token', token);
const clearToken = () => window.localStorage.removeItem('token');
export const getToken = () => window.localStorage.getItem('token');

export function* loginSuccess({ payload: { access } }) {
    yield put(push('/dashboard'));
    yield call(saveToken, access);
}

export function* disconnect() {
    yield put(logout());
    yield put(push('/'));
    yield call(clearToken);
}

export function* handleLogout() {
    yield put(push('/'));
}

export function* handleAuth({ payload: { pathname } }) {
    if (pathname === '/login' || pathname === "/") {
        return;
    }

    const isLogged = yield select(getIsLogged);

    if (isLogged) {
        return;
    }
    const token = yield call(getToken);
    if (token) {
        yield put(setToken(token));
        return;
    }

}

export const sagas = function*() {
    yield takeLatest(LOGIN_SUCCESS, loginSuccess);
    yield takeLatest(({ meta }) => !!(meta && meta.disconnect), disconnect);
    yield takeLatest(LOGOUT, handleLogout);
    yield takeLatest(LOCATION_CHANGE, handleAuth);
};
