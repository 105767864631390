import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Icon from '@mui/material/Icon';
import {FONT_AWESOME_ICONS} from '../core/mappings';


export const IconsFontAwesome = ({
                                     iconType = '',
                                     hasHover = true,
                                     baseClassName = 'far', className = '', secondClass = '',
                                     titleAccess = '', fontSize = 'small',
                                     onClickHandler = () => {
                                     },
                                     mainSX = {}, hoveredSX = {},
                                     iconsSX = {},
                                     ...restProps
                                 }) => {
    const [hovered, setHovered] = useState(false);

    function changeIcon() {
        hasHover && setHovered(!hovered);
    }

    return (hovered && FONT_AWESOME_ICONS[iconType]?.hover?.baseClassName)
        ? <Icon fontSize={fontSize}
                baseClassName={iconType ? FONT_AWESOME_ICONS[iconType]?.hover?.baseClassName : baseClassName} // hovered icon
                className={iconType ? `${FONT_AWESOME_ICONS[iconType].className} ${secondClass}` : className}
                title={titleAccess}
                aria-label={titleAccess}
                sx={{
                    cursor: 'pointer',
                    overflow: 'inherit',
                    backgroundColor: 'transparent',
                    ...iconsSX,
                    ...hoveredSX,
                }}
                onMouseLeave={changeIcon}
                onClick={onClickHandler}
                {...restProps}/>
        : <Icon fontSize={fontSize}
                baseClassName={iconType ? FONT_AWESOME_ICONS[iconType].main.baseClassName : baseClassName} // main icon
                className={iconType ? `${FONT_AWESOME_ICONS[iconType].className} ${secondClass}` : className}
                title={titleAccess}
                aria-label={titleAccess || iconType}
                sx={{
                    cursor: FONT_AWESOME_ICONS[iconType]?.hover?.baseClassName ? 'pointer' : 'arrow',
                    overflow: 'inherit',
                    backgroundColor: 'transparent',
                    ...iconsSX,
                    ...mainSX,
                }}
                onMouseEnter={changeIcon}
                onClick={onClickHandler}
                {...restProps}/>;
};

IconsFontAwesome.propTypes = {
    iconType: PropTypes.string,
    baseClassName: PropTypes.string,
    className: PropTypes.string.isRequired,
    fontSize: PropTypes.string,
    hoveredSX: PropTypes.object,
    iconsSX: PropTypes.object,
    mainSX: PropTypes.object,
    onClickHandler: PropTypes.any,
    titleAccess: PropTypes.string,
    variantType: PropTypes.string,
};

IconsFontAwesome.defaultProps = {
    baseClassName: 'far',
    className: '',
    fontSize: 'small',
    hoveredSX: {},
    iconsSX: {},
    mainSX: {},
    onClickHandler: () => {
    },
    titleAccess: '',
};