/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import fetch from 'isomorphic-fetch';
import host from '../host';


export const parseResponse = (mode = 'json', method = 'GET') => response => {
    if (response?.status === 204) {
        return null;
    }

    if (response?.status && response?.status >= 200 && response?.status < 300) {
        return response[mode]();
    }

    const error = new Error(response.statusText);
    error.response = response;
    error.code = response?.status || 'no status';
    throw error;
};

export const appFetch = ({url, mode, ...config}, token) => {
    const {currentRequest, ...restConf} = config;

    return fetch(`${host || ''}${url}`, {
        ...restConf,
        headers: token ? {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        } : {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then(parseResponse(mode, restConf.method))
        .then(response => {
            if (response === undefined && currentRequest.aborted) {
                return Promise.resolve({aborted: currentRequest.aborted});
            }

            return {response};
        })
        .catch(error => ({error}));
};
