/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React from 'react';
import * as THREE from 'three';

import Draggable3DIcon from './component/Draggable3DIcon';


export default function TransientSource3DIcon() {
    return <Draggable3DIcon materials={[new THREE.MeshBasicMaterial({color: 0xffffff, side: THREE.DoubleSide, opacity: 1})]}
                            boxes={[new THREE.PlaneGeometry(20, 20)]}
                            lightPosition={{x: 10, y: 10, z: 10}}
                            tooltipTitle={'Create Transient Source object'}
                            objId={'transientSourceID'}
                            dragType={'transient_source'}
    />;
}