/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {put, takeLatest} from 'redux-saga/effects';
import {LOCATION_CHANGE} from 'react-router-redux';
import {HIDE_MODAL, hideModal} from '../modals/actions';

import {
    CREATE_LIBRARY_ERROR,
    CREATE_LIBRARY_MATERIAL_ERROR,
    CREATE_LIBRARY_MATERIAL_SUCCESS,
    CREATE_LIBRARY_SUCCESS,
    CREATE_MATERIAL_ERROR,
    CREATE_MATERIAL_SUCCESS,
    DELETE_LIBRARY_ERROR,
    DELETE_LIBRARY_SUCCESS,
    setValidationErrors,
    UPDATE_LIBRARY_ERROR,
    UPDATE_LIBRARY_MATERIAL_ERROR,
    UPDATE_LIBRARY_MATERIAL_SUCCESS,
    UPDATE_LIBRARY_PERM_ERROR,
    UPDATE_LIBRARY_PERM_SUCCESS,
    UPDATE_LIBRARY_SUCCESS,
    UPDATE_MATERIAL_ERROR,
    UPDATE_MATERIAL_SUCCESS,
} from './actions';


function* clearValdationRules() {
    yield put(setValidationErrors([]));
}

function* handelRequestError({payload}) {
    if(payload.response.status === 400){
        const errors = yield payload.response.json();
        yield put(setValidationErrors(errors));
    }
}

function* handelRequestSuccess() {
    yield put(hideModal());
}

export function* handleMaterialsLoading() {
    yield takeLatest([
        CREATE_MATERIAL_SUCCESS, UPDATE_MATERIAL_SUCCESS,
        CREATE_LIBRARY_MATERIAL_SUCCESS, UPDATE_LIBRARY_MATERIAL_SUCCESS,
        CREATE_LIBRARY_SUCCESS, UPDATE_LIBRARY_SUCCESS, DELETE_LIBRARY_SUCCESS,
        UPDATE_LIBRARY_PERM_SUCCESS
    ], handelRequestSuccess);
    yield takeLatest([
        CREATE_MATERIAL_ERROR, UPDATE_MATERIAL_ERROR,
        CREATE_LIBRARY_MATERIAL_ERROR, UPDATE_LIBRARY_MATERIAL_ERROR,
        CREATE_LIBRARY_ERROR, UPDATE_LIBRARY_ERROR, DELETE_LIBRARY_ERROR,
        UPDATE_LIBRARY_PERM_ERROR
    ], handelRequestError);
    yield takeLatest([HIDE_MODAL], clearValdationRules);
}

export const sagas = function*() {
    yield takeLatest(
        action =>
            action.type === LOCATION_CHANGE &&
            action.payload.pathname === '/materials',
            handleMaterialsLoading,
    );
};
