/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {useState, useRef} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {TextValidator} from 'react-material-ui-form-validator';

import withStyles from '@mui/styles/withStyles';
import {DialogContent, Button, FormControl, DialogActions} from '@mui/material';

import MyValidationForm from '../../../../../components/MyValidationForm';

import {createScenario, updateScenario} from '../../../../+store/actions/scenarios';

import {getValidationErrors} from '../../../../+store/reducer/validation_errors';

const styles = (theme) => ({
    root: {
        '& > *': {
            width: '100ch',
        },
    },
    inputs: {
        '& > *': {
            marginBottom: theme.spacing(1),
            marginRight: theme.spacing(2),
            width: 445,
        },
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
});


const ScenarioSettingsComponent = ({
                                        classes, validation_errors, project_id, scenario,
                                        createScenario, updateScenario,
                                        ...rest
                                    }) => {
    const form = useRef();
    const [fields, setFields] = useState(scenario || {
        id: undefined,
        name: '',
    });

    function handleChange(name, value) {
        setFields({
            ...fields,
            [name]: value,
        });
    }

    function handleSubmit() {
        form.current.isFormValid(false)
            .then(is_valid => {
                if (is_valid) {
                    scenario
                        ? updateScenario({
                            project_id,
                            scenario: fields,
                        })
                        : createScenario({
                            project_id,
                            scenario: fields,
                        });
                }
            });
    }


    return (
        <>
            <DialogContent dividers>
                <MyValidationForm
                    ref={form}
                    onSubmit={handleSubmit}
                    className={'validation-form-sm'}>
                    <div>
                        <FormControl variant={'standard'} fullWidth={true}>
                            <TextValidator
                                autoFocus
                                id={'scenarioDescription'}
                                key={'name'}
                                className={classes.root}
                                label={'Name'}
                                value={fields.name}
                                onChange={(event) => handleChange('name', event.target.value)}
                                variant={'standard'}
                                error={!!validation_errors['name']}
                                helperText={validation_errors['name']}
                                validators={['required']}
                                errorMessages={['Name is required!']}/>
                        </FormControl>
                    </div>
                </MyValidationForm>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit} id={'addScenario'} variant={'contained'} color={'primary'} autoFocus>
                    {scenario ? 'Update' : 'Add'}
                </Button>
            </DialogActions>
        </>
    );
};


ScenarioSettingsComponent.propTypes = {};

const mapStateToProps = state => ({
    validation_errors: getValidationErrors(state),
});

const mapDispatchToProps = {
    createScenario,
    updateScenario,
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(ScenarioSettingsComponent);
