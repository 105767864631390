import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {
    Button, ButtonGroup, ClickAwayListener,
    Grow,
    Box, Paper, Popper,
    MenuItem, MenuList,
    CircularProgress, Icon, Typography,
} from '@mui/material';

import {
    solveProject,
    stopTask,
} from '../project/+store/actions/actions';

import {getTaskId, getTaskStatus, getTaskStatusTime} from '../project/+store/reducer/solution';

import {IconsFontAwesome} from './IconsFontAwesome';

import {FONT_AWESOME_ICONS, FONT_AWESOME_ICONS_TYPE_MAP} from '../core/mappings';

import {projectUtils} from '../project/utils';


const SolveButton = ({
                         options,
                         solution,
                         current_project,
                         solveProject,
                         solve_task_status,
                         solve_task_status_time,
                         stopTask,
                         task_id,
                         tree,
                         btnVariant = 'solve',
                         wrapperClass = '',
                         stopSolvingBtnClassName = '',
                         btnColor = '',
                     }) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedSolveMode, setSelectedSolveMode] = React.useState(0);
    const [hoveredElement, setHoveredElement] = useState('');

    const toggleHover = (event, elementType = '') => {
        setHoveredElement(elementType);
    };

    const handleSelectOption = (event, index) => {
        setSelectedSolveMode(index);
        setOpen(false);
    };

    const toggleOptionsMenu = (event) => {
        projectUtils.stopPreventDefault(event);
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };


    return (
        <Box className={`solve-btn-wrapper ${wrapperClass}`}>
            {
                solution &&
                solution.timestamp &&
                (new Date(solution.timestamp)) < projectUtils.getTreeTimestamp(tree) &&
                <Box sx={{
                    color: (theme) => theme.palette.warning.main,
                    mr: '10px',
                    letterSpacing: '0',
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.warning}
                                      hasHover={false}
                                      iconsSX={{
                                          fontSize: '1.25rem', width: 'auto', mr: '10px',
                                          '&:hover': {cursor: 'default'},
                                      }}
                                      titleAccess={'Zoom All'}/>
                    <Typography variant={'faded'} size={'info'}>
                        {solution.result && !Object.keys(solution.result).length ? 'No solution' : 'Solution is stale'}
                    </Typography>
                </Box>
            }
            {
                current_project &&
                (current_project.owner || current_project.edit) &&
                (['PENDING', 'RUNNING'].includes(solve_task_status)
                        ? <Button id={'stopsolvingbutton'}
                                  title={'Cancel solving  task and discard intermediate result'}
                                  disabled={!solve_task_status_time}
                                  style={{margin: '10px 0', width: '144px', height: '100%'}}
                                  className={`stop-solvig ${stopSolvingBtnClassName}`}
                                  variant={btnVariant}
                                  color={btnColor}
                                  onClick={() => {
                                      projectUtils.updateStorageTaskStatus({taskId: task_id, deleteStatus: true});
                                      stopTask({project_id: current_project.id, task_id});
                                  }}
                                  onMouseEnter={(event) => toggleHover(event, 'stopButton')}
                                  onMouseLeave={toggleHover}>
                            <Icon className={FONT_AWESOME_ICONS[FONT_AWESOME_ICONS_TYPE_MAP.stop].className}
                                  baseClassName={FONT_AWESOME_ICONS[FONT_AWESOME_ICONS_TYPE_MAP.stop][hoveredElement === 'stopButton' ? 'hover' : 'main'].baseClassName} // main icon
                                  sx={{fontSize: '1rem', mr: '4px'}}
                                  title={'Stop Solving'}
                                  aria-label={'Stop Solving'}/>
                            <Typography sx={{fontSize: '1rem'}}>{solve_task_status}</Typography>
                            <CircularProgress size={20}
                                              style={{color: 'white'}}
                                              thickness={5}/>
                        </Button>
                        : <>
                            <ButtonGroup variant={'contained'}
                                         ref={anchorRef}
                                         sx={{height: '45px', width: '144px'}}
                                         aria-label={'split button'}>
                                <Button id={'startsolvingbutton'}
                                        title={'Start solving task'}
                                        disabled={!solve_task_status_time}
                                        style={{
                                            width: '144px',
                                            height: '100%',
                                        }}
                                        variant={btnVariant}
                                        color={btnColor}
                                        onMouseEnter={(event) => toggleHover(event, 'solveButton')}
                                        onMouseLeave={toggleHover}
                                        onClick={() => {
                                            solveProject({
                                                project_id: current_project.id, transient: selectedSolveMode,
                                            });
                                        }}>
                                    <Box className={'solve-btn-icon'}>
                                        <Icon className={FONT_AWESOME_ICONS[FONT_AWESOME_ICONS_TYPE_MAP.play].className}
                                              baseClassName={FONT_AWESOME_ICONS[FONT_AWESOME_ICONS_TYPE_MAP.play][hoveredElement === 'solveButton' ? 'hover' : 'main'].baseClassName} // main icon
                                              sx={{fontSize: '1rem', mr: '4px'}}
                                              title={'Solve'}
                                              aria-label={'Solve'}/>
                                        <Typography sx={{fontSize: '1rem'}}>
                                            {options[selectedSolveMode]}
                                        </Typography>
                                    </Box>
                                </Button>
                                <Button size={'small'}
                                        aria-controls={open ? 'solve-button-menu' : undefined}
                                        aria-expanded={open ? 'true' : undefined}
                                        aria-label={'select solve option'}
                                        aria-haspopup={'menu'}
                                        variant={btnVariant}
                                        color={btnColor}
                                        className={'solve-btn-menu'}
                                        onMouseEnter={(event) => toggleHover(event, 'menuCaret')}
                                        onMouseLeave={toggleHover}
                                        disableRipple
                                        sx={{
                                            height: 'inherit',
                                            width: '30px',
                                        }}
                                        onClick={toggleOptionsMenu}>
                                    {
                                        open
                                            ? <Icon aria-label={'collapse-menu'}
                                                    className={`${FONT_AWESOME_ICONS[FONT_AWESOME_ICONS_TYPE_MAP.caretArrowUp].className} noMR`}
                                                    baseClassName={FONT_AWESOME_ICONS[FONT_AWESOME_ICONS_TYPE_MAP.caretArrowUp][hoveredElement === 'menuCaret' ? 'hover' : 'main'].baseClassName} // main icon
                                                    sx={{fontSize: '1rem'}}/>
                                            : <Icon aria-label={'expand-menu'}
                                                    className={`${FONT_AWESOME_ICONS[FONT_AWESOME_ICONS_TYPE_MAP.caretArrowDown].className} noMR`}
                                                    baseClassName={FONT_AWESOME_ICONS[FONT_AWESOME_ICONS_TYPE_MAP.caretArrowDown][hoveredElement === 'menuCaret' ? 'hover' : 'main'].baseClassName} // main icon
                                                    sx={{fontSize: '1rem'}}/>
                                    }
                                </Button>
                            </ButtonGroup>
                            <Popper open={open}
                                    className={'solve-button-dropdown'}
                                    anchorEl={anchorRef.current}
                                    role={undefined}
                                    transition
                                    placement={'bottom-end'}
                                    disablePortal>
                                {({TransitionProps, placement}) => (
                                    <Grow {...TransitionProps}>
                                        <Paper>
                                            <ClickAwayListener onClickAway={handleClose}>
                                                <MenuList id={'solve-button-menu'} autoFocusItem>
                                                    {options.map((option, index) => (
                                                        <MenuItem key={option}
                                                                  selected={index === selectedSolveMode}
                                                                  onClick={(event) => handleSelectOption(event, index)}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </>
                )
            }
        </Box>
    );
};

SolveButton.defaultProps = {
    options: ['Steady', 'Transient'],
};

SolveButton.propTypes = {
    tree: PropTypes.array.isRequired,
    options: PropTypes.array.isRequired,
    solution: PropTypes.object,
    current_project: PropTypes.object.isRequired,
    solveProject: PropTypes.func.isRequired,
    solve_task_status: PropTypes.string,
    solve_task_status_time: PropTypes.number,
    stopTask: PropTypes.func.isRequired,
    task_id: PropTypes.number,
};

const mapStateToProps = state => ({
    solve_task_status: getTaskStatus(state),
    solve_task_status_time: getTaskStatusTime(state),
    task_id: getTaskId(state),
});

const mapDispatchToProps = {
    stopTask,
    solveProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(SolveButton);