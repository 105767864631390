/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {useEffect, useState, useRef, useCallback, Fragment} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';
import _ from 'lodash';
import {BigNumber} from 'bignumber.js';

import {InputAdornment, Box, Collapse, Typography, Divider} from '@mui/material';

import {
    setSelectedObject,
    translateProjectAssembly,
    updateProjectObjectPosition, updateSelectedObject,
} from '../../../../../../+store/actions/actions';
import {updatePlane} from '../../../../../../+store/actions/planes';

import {
    getForceUpdatedSelectedObject,
    getSelectedObject,
} from '../../../../../../+store/reducer/tree';
import {getValidationErrors} from '../../../../../../+store/reducer/validation_errors';

import MyValidationForm, {validations} from '../../../../../../../components/MyValidationForm';
import {MAPPINGS} from '../../../../../../../core/mappings';
import {TextValidator} from 'react-material-ui-form-validator';


const getMainPositionFields = (boxType) => {
    let fields;

    switch (boxType) {
        case 'assembly':
            fields = ['x_calc', 'y_calc', 'z_calc'];
            break;
        case 'plane':
            fields = ['x_coordinate', 'y_coordinate', 'z_coordinate'];
            break;
        default:
            fields = ['x', 'y', 'z'];
    }

    return fields;
};
const initialTranslateState = {dx: 0, dy: 0, dz: 0};

function updateAssemblyElementsPosition({element, translateState}) {
    if (element.type !== 'assembly') {
        updateSingleElementPosition({element, translateState});
    }

    if (element.type === 'assembly') {
        if (validations.isNumber(element.x_calc) || validations.isFloat(element.x_calc) || !validations.isString(element.x_calc)) {
            element.x_calc = BigNumber.sum(element.x_calc, translateState.dx);
        }

        if (validations.isNumber(element.y_calc) || validations.isFloat(element.y_calc) || !validations.isString(element.y_calc)) {
            element.y_calc = BigNumber.sum(element.y_calc, translateState.dy);
        }

        if (validations.isNumber(element.z_calc) || validations.isFloat(element.z_calc) || !validations.isString(element.z_calc)) {
            element.z_calc = BigNumber.sum(element.z_calc, translateState.dz);
        }

        element.children.forEach(child => {
            updateAssemblyElementsPosition({element: child, translateState});
        });
    }
}

function updateSingleElementPosition({element, translateState}) {
    if (validations.isNumber(element.x) || validations.isFloat(element.x) || !validations.isString(element.x)) {
        element.x = BigNumber.sum(element.x, translateState.dx);
        element.x_calc = BigNumber.sum(element.x_calc, translateState.dx);
    }

    if (validations.isNumber(element.y) || validations.isFloat(element.y) || !validations.isString(element.y)) {
        element.y = BigNumber.sum(element.y, translateState.dy);
        element.y_calc = BigNumber.sum(element.y_calc, translateState.dy);
    }

    if (validations.isNumber(element.z) || validations.isFloat(element.z) || !validations.isString(element.z)) {
        element.z = BigNumber.sum(element.z, translateState.dz);
        element.z_calc = BigNumber.sum(element.z_calc, translateState.dz);
    }
}

function updatePlanePosition({element, translateState}) {
    switch (element.plane) {
        case 'XY':
            element.coordinate = BigNumber.sum(element.coordinate, translateState.dz);
            break;
        case 'XZ':
            element.coordinate = BigNumber.sum(element.coordinate, translateState.dy);
            break;
        default:
            element.coordinate = BigNumber.sum(element.coordinate, translateState.dx);

    }
}


const DraggingInfoInputs = ({
                                setSelectedObject,
                                updateSelectedObject,
                                updateProjectObjectPosition,
                                translateProjectAssembly,
                                updatePlane,
                                project_id,
                                validation_errors,
                                selectedObject,
                                forceUpdatedSelectedObject,
                                newPositions,
                                dragging,
                            }) => {
    const form = useRef(null);
    const [state, setState] = useState({
        selected: {...selectedObject},
        translate: {...initialTranslateState},
    });
    const [holdUpdate, setHoldUpdate] = useState(false);
    const [errors, setErrors] = useState({});
    const [inputFocused, setInputFocused] = useState(false);
    const [copyOfSelectedObject, setCopyOfSelectedObject] = useState(null);

    // const cancelChanges = () => {
    //     if (translateState.dx !== initialTranslateState.dx || translateState.dy !== initialTranslateState.dy || translateState.dz !== initialTranslateState.dz) {
    //         setState(selectedObject);
    //         setTranslateState({...initialTranslateState});
    //     }
    // };

    const handleSubmit = useCallback(
        ({state, sendRequest = false}) => {
            let translatedObject = JSON.parse(JSON.stringify(copyOfSelectedObject));

            switch (translatedObject.type) {
                case 'assembly':
                        updateAssemblyElementsPosition({element: translatedObject, translateState: state.translate});

                    sendRequest && translateProjectAssembly({
                        project_id,
                        assembly_id: translatedObject.id,
                        translate: state.translate,
                        box: translatedObject,
                    });
                    break;
                case 'plane':
                    updatePlanePosition({
                        element: translatedObject, translateState: state.translate,
                    });

                    sendRequest && updatePlane({
                        project_id,
                        plane: translatedObject,
                    });
                    break;
                default:
                    updateSingleElementPosition({
                        element: translatedObject, translateState: state.translate,
                    });

                    sendRequest && updateProjectObjectPosition({
                        project_id,
                        box: translatedObject,
                        type: MAPPINGS.boxTypes[translatedObject.type],
                    });
            }

            setSelectedObject(translatedObject);

            if (sendRequest) {
                setHoldUpdate(false);
                setCopyOfSelectedObject(JSON.parse(JSON.stringify(translatedObject)));
            } else {
                updateSelectedObject(true);
            }
        },
        [state],
    );

    const savePosition = useCallback((e) => {
            if (e.key === 'Enter' && holdUpdate && inputFocused) {
                e.preventDefault();
                handleSubmit({state, sendRequest: true});
            }
        }, [state],
    );

    const debouncedSetSelectedObject = useCallback(
        _.debounce(({state}) => {
            handleSubmit({state});
        }, 100),
        [state],
    );

    const handleChange = (name, value) => {
        let translateName,
            stateName = name;
        switch (stateName) {
            case 'x':
            case 'x_calc':
                translateName = 'dx';
                break;
            case 'y':
            case 'y_calc':
                translateName = 'dy';
                break;
            case 'z':
            case 'z_calc':
                translateName = 'dz';
                break;
            case 'x_coordinate':
            case 'y_coordinate':
            case 'z_coordinate':
                const [axis, coordName] = stateName.split('_');
                stateName = coordName;
                translateName = `d${axis}`;
                break;
        }

        const currentValue = value || 0;
        if (validations.isNumber(currentValue) || validations.isFloat(currentValue) || !validations.isString(currentValue)) {
            setState({
                selected: {
                    ...state.selected,
                    [stateName]: currentValue,
                },
                translate: {
                    ...state.translate,
                    [translateName]: BigNumber.sum(currentValue, -copyOfSelectedObject[stateName]),
                },
            });

            errors[stateName] && setErrors(prevErrors => {
                delete prevErrors[stateName];

                return {
                    ...prevErrors,
                };
            });
            setHoldUpdate(true);
        } else {
            setErrors({
                ...errors,
                [stateName]: true,
            });
        }
    };

    const handleDeltaChange = (name, value) => {
        let stateName;
        if (selectedObject.type === 'plane') {
            stateName = 'coordinate';
        } else {
            switch (name) {
                case 'dx':
                    stateName = selectedObject.type === 'assembly' ? 'x_calc' : 'x';
                    break;
                case 'dy':
                    stateName = selectedObject.type === 'assembly' ? 'y_calc' : 'y';
                    break;
                case 'dz':
                    stateName = selectedObject.type === 'assembly' ? 'z_calc' : 'z';
                    break;
            }
        }

        const currentValue = value || 0;
        if (validations.isNumber(currentValue) || validations.isFloat(currentValue) || !validations.isString(currentValue)) {
            setState({
                selected: {
                    ...state.selected,
                    [stateName]: BigNumber.sum(copyOfSelectedObject[stateName], currentValue),
                },
                translate: {
                    ...state.translate,
                    [name]: currentValue,
                },
            });

            errors[name] && setErrors(prevErrors => {
                delete prevErrors[name];

                return {
                    ...prevErrors,
                };
            });
            setHoldUpdate(true);
        } else {
            setErrors({
                ...errors,
                [name]: true,
            });
        }
    };

    const renderInputFields = (field_names, units = 'mm', ids) => (
        field_names.map((prop_name) => {
            let disabled, planeCoordinate,
                currentPropName = prop_name;
            if (selectedObject.type === 'plane') {
                switch (selectedObject.plane) {
                    case 'XY':
                        disabled = prop_name[0] !== 'z';
                        planeCoordinate = 'z';
                        break;
                    case 'XZ':
                        disabled = prop_name[0] !== 'y';
                        planeCoordinate = 'y';
                        break;
                    case 'YZ':
                        disabled = prop_name[0] !== 'x';
                        planeCoordinate = 'x';
                        break;
                }

                if (planeCoordinate === prop_name[0]) {
                    currentPropName = 'coordinate';
                }
            } else {
                disabled = validations.isString(state.selected[currentPropName]) && (!validations.isNumber(state.selected[currentPropName]) && !validations.isFloat(state.selected[currentPropName]));
            }

            return (
                <Fragment key={currentPropName}>
                    <Typography variant={'faded'} size={'info'}
                                sx={{mr: '4px'}}>{prop_name[0].toUpperCase()}</Typography>
                    <TextValidator fullWidth
                                   id={ids}
                                   key={prop_name}
                                   name={currentPropName}
                                   value={
                                       disabled
                                           ? state.selected[currentPropName]
                                           : dragging && newPositions
                                               ? newPositions.updatedCoordinates[planeCoordinate || currentPropName.split('_')[0]]
                                               : state.selected[currentPropName]
                                   }
                                   onChange={(event) => handleChange(prop_name, event.target.value)}
                                   onFocus={() => !inputFocused && setInputFocused(true)}
                                   onBlur={() => inputFocused && setInputFocused(false)}
                                   error={(validation_errors && !!validation_errors[currentPropName]) || errors[currentPropName]}
                                   helperText={(validation_errors && validation_errors[currentPropName]) || errors[currentPropName]}
                                   validators={['required']}
                                   errorMessages={['This field is required!']}
                                   disabled={disabled}

                                   InputProps={{
                                       sx: {
                                           width: '10%', maxWidth: '96px', minWidth: '80px',
                                           height: '32px',
                                           marginTop: 0,

                                           '& .MuiInput-input': {
                                               height: '16px',
                                           },
                                       },
                                       endAdornment: (
                                           <InputAdornment position={'end'}
                                                           sx={{bottom: '7px'}}>{units}</InputAdornment>),
                                   }}
                    />
                </Fragment>
            );
        })
    );

    const renderDeltaInputFields = (field_names, units = 'mm', ids) => (
        field_names.map((prop_name) => {
            let disabled;
            if (selectedObject.type === 'plane') {
                disabled = selectedObject.plane.includes(prop_name[1].toUpperCase());

            } else {
                const statePropName = selectedObject.type === 'assembly' ? `${prop_name[1]}_calc` : prop_name[1];
                disabled = validations.isString(state.selected[statePropName]) && (!validations.isNumber(state.selected[statePropName]) && !validations.isFloat(state.selected[statePropName]));
            }

            return (
                <Fragment key={prop_name}>
                    <Typography variant={'faded'} size={'infoSmall'} sx={{
                        // fontSize: '0.75rem',
                        marginRight: '1px',
                        marginTop: '3px',
                    }}
                    >&#916;</Typography>
                    <Typography variant={'faded'} size={'info'}
                                sx={{mr: '4px'}}>{prop_name[1].toUpperCase()}</Typography>
                    <TextValidator fullWidth
                                   id={ids}
                                   key={prop_name}
                                   name={prop_name}
                                   value={disabled
                                       ? 0
                                       : dragging && newPositions
                                           ? newPositions.updatedCoordinates[prop_name]
                                           : state.translate[prop_name]}
                                   onChange={(event) => handleDeltaChange(prop_name, event.target.value)}
                                   onFocus={() => !inputFocused && setInputFocused(true)}
                                   onBlur={() => inputFocused && setInputFocused(false)}
                                   error={(validation_errors && !!validation_errors[prop_name]) || !!errors[prop_name]}
                                   helperText={(validation_errors && validation_errors[prop_name]) || errors[prop_name]}
                                   validators={['required']}
                                   errorMessages={['This field is required!']}
                                   disabled={disabled}
                                   InputProps={{
                                       sx: {
                                           width: '15%', maxWidth: '114px', minWidth: '80px',
                                           height: '32px',
                                           marginTop: 0,

                                           '& .MuiInput-input': {
                                               height: '16px',
                                           },
                                       },
                                       endAdornment: (
                                           <InputAdornment position={'end'}
                                                           sx={{bottom: '7px'}}>{units}</InputAdornment>),
                                   }}
                    />
                </Fragment>
            );
        })
    );

    useEffect(() => {
        if ((!_.isEqual(selectedObject, state.selected) && !holdUpdate) || selectedObject.id !== state.selected.id) {
            setState({
                selected: {...selectedObject},
                translate: {...initialTranslateState},
            });
        }
    }, [selectedObject, holdUpdate]);

    useEffect(() => {
        if (holdUpdate) {
            debouncedSetSelectedObject({state});
        }
    }, [holdUpdate, state]);

    useEffect(() => {
        document.removeEventListener('keydown', savePosition);

        if (inputFocused) {
            document.addEventListener('keydown', savePosition);
        }
        return () => document.removeEventListener('keydown', savePosition);
    }, [savePosition, state, inputFocused]);

    useEffect(() => {
        if (!copyOfSelectedObject || (copyOfSelectedObject.id !== selectedObject.id && copyOfSelectedObject.type !== selectedObject.type) || forceUpdatedSelectedObject) {
            setCopyOfSelectedObject(JSON.parse(JSON.stringify(selectedObject)));
        }
    }, [selectedObject, holdUpdate, forceUpdatedSelectedObject]);


    return (
        <Box variant={'flex'}
             sx={{
                 flexWrap: 'wrap',
                 position: 'absolute',
                 width: '100%',
                 backgroundColor: 'background.paper',
             }}>
            <Collapse in={!!selectedObject}
                      collapsedSize={45}
                      sx={{
                          display: 'flex',
                          position: 'absolute',
                          justifyContent: 'space-around', alignItems: 'center',
                          width: '100%',
                          backgroundColor: 'background.paper',
                      }}
                      timeout={{enter: 1000, exit: 200}}
                      mountOnEnter
                      unmountOnExit>
                <Box sx={{
                    display: 'flex', borderRight: '1px',
                    justifyContent: 'flex-start', alignItems: 'center',
                    height: 'inherit', width: '100%',
                }}>
                    <MyValidationForm
                        ref={form}
                        onSubmit={() => {
                        }}
                        className={'validation-form'}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            borderRight: '1px ',
                            height: '32px',
                        }}>
                        <Typography variant={'faded'}
                                    size={'infoSmall'}
                                    sx={{
                                        marginRight: '1.5rem',
                                        marginLeft: '1rem',
                                        backgroundColor: 'background.paper',
                                        fontWeight: 400,
                                        fontSize: '12px'
                                    }}
                        >POSITION:</Typography>

                        {renderInputFields(getMainPositionFields(selectedObject.type), 'mm', `${selectedObject.type}locationid`)}

                        <Divider orientation={'vertical'} variant={'middle'} flexItem
                                 sx={{
                                     borderColor: 'background.input', height: '32px',
                                     mt: 0,
                                     mr: '19.5px',
                                 }}/>

                        {renderDeltaInputFields(['dx', 'dy', 'dz'], 'mm', `${selectedObject.type}deltaid`)}
                    </MyValidationForm>
                </Box>

                {/*<DialogActions>*/}
                {/*    <Button variant={'contained'} color={'secondary'} id={'cancelUpdatePosition'}*/}
                {/*            onClick={cancelChanges}>*/}
                {/*        Cancel*/}
                {/*    </Button>*/}
                {/*    <Button variant={'contained'} color={'secondary'} id={'updatePosition'} onClick={handleSubmit}>*/}
                {/*        OK*/}
                {/*    </Button>*/}
                {/*</DialogActions>*/}
            </Collapse>
        </Box>
    );
};

DraggingInfoInputs.propTypes = {
    setSelectedObject: PropTypes.func.isRequired,
    updateSelectedObject: PropTypes.func.isRequired,
    updateProjectObjectPosition: PropTypes.func.isRequired,
    translateProjectAssembly: PropTypes.func.isRequired,
    updatePlane: PropTypes.func.isRequired,
    project_id: PropTypes.number.isRequired,
    validation_errors: PropTypes.object,
    selectedObject: PropTypes.object,
    forceUpdatedSelectedObject: PropTypes.bool,
};

const mapStateToProps = state => ({
    validation_errors: getValidationErrors(state),
    selectedObject: getSelectedObject(state),
    forceUpdatedSelectedObject: getForceUpdatedSelectedObject(state),
});

const mapDispatchToProps = {
    setSelectedObject,
    updateSelectedObject,
    updateProjectObjectPosition,
    translateProjectAssembly,
    updatePlane,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(DraggingInfoInputs);