/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React from 'react';
import * as THREE from "three";

import SphereArrayGeometry from "../../geoms/SphereArrayGeometry";

import Draggable3DIcon from './component/Draggable3DIcon';


export default function BallArray3DIcon() {
    return (
        <Draggable3DIcon materials={[new THREE.MeshPhongMaterial({color: 0xffffff})]}
                         boxes={[new SphereArrayGeometry(
                             -10, 0, -10,
                             4, 1, 4,
                             6, 6, 6, true,
                             2, 16, 8
                         )]}
                         lightPosition={{x: 1, y: 1, z: 1}}
                         rotateObj={false}
                         tooltipTitle={'Create Ball Array object'}
                         objId={'ballarrayID'}
                         dragType={'ball_array'}
        />
    );
}