/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import {getValidationErrors} from '../+store/reducer/validation_errors';
import {getData as getProject} from '../+store/reducer/project';
import FormGroup from "@mui/material/FormGroup";

import MyValidationForm from '../../components/MyValidationForm';
import {getLibrary} from "../../materials/reducer";
import {InputLabel, Select} from "@mui/material";
import {loadLibrary} from "../../materials/actions";
import {createProjectMaterial} from "../+store/actions/project";

const styles = (theme) => ({
    root: {
        '& > *': {
            marginBottom: theme.spacing(1),
            marginRight: theme.spacing(2),
        }
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    select: {
        height: 400
    }
});

class ImportMaterial extends Component {

    constructor(props) {
        super(props);

        this.props.loadLibrary();

        this.state = {
            selected: [],
            materials: [],
        };

        this.form = React.createRef();

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = (event) => {
        const {options} = event.target;
        const {library} = this.props;

        const selected = [];
        const materials = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                selected.push(options[i].value);
                library.forEach(lib => {
                    let mat = lib.materials.find(x => {
                        return x.id === +options[i].value;
                    });
                    if(mat)
                        materials.push({
                            ...mat,
                            library_id: lib.id,
                            material: mat.id,
                        })
                })
            }
        }
        this.setState({
            materials,
            selected,
        });
    }

    handleSubmit() {
        const {project, createProjectMaterial} = this.props;
        const {materials} = this.state;

        this.form.current.isFormValid(false)
            .then(is_valid => {
                if (is_valid) {
                    createProjectMaterial({project, materials});
                }
            });
    }


    render() {
        const {classes, validation_errors, library} = this.props;
        const {selected} = this.state;

        return (
            <Fragment>
                <DialogContent dividers>
                    <MyValidationForm
                        ref={this.form}
                        onSubmit={this.handleSubmit}
                        className="validation-form-sm"
                    >
                        <div>
                            <FormGroup row>
                                <FormControl variant="standard">
                                    <InputLabel shrink htmlFor="select-multiple-native">
                                        Available materials
                                    </InputLabel>
                                    <Select
                                        multiple
                                        native
                                        value={selected}
                                        onChange={this.handleChange}
                                        fullWidth={true}
                                        inputProps={{
                                            id: 'select-multiple-native',
                                            style: {
                                                height: 350,
                                                width: 500,
                                            }
                                        }}
                                    >
                                        {
                                            library
                                                .sort((a, b) => {
                                                    if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
                                                    else if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
                                                    return 0;
                                                })
                                                .map(lib => (
                                                    <optgroup key={lib.name} label={lib.name}>
                                                        {
                                                            lib.materials
                                                                .sort((a, b) => {
                                                                    if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
                                                                    else if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
                                                                    return 0;
                                                                })
                                                                .map(mat => (
                                                                    <option key={mat.id} value={mat.id}>{mat.name}</option>
                                                                ))
                                                        }
                                                    </optgroup>
                                                ))
                                        }
                                    </Select>
                                </FormControl>
                            </FormGroup>
                        </div>
                    </MyValidationForm>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleSubmit} id='import' variant="contained" color="primary" autoFocus>
                        Import
                    </Button>
                </DialogActions>
            </Fragment>
        );
    }
}

ImportMaterial.propTypes = {
    onClose: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    validation_errors: getValidationErrors(state),
    project: getProject(state),
    library: getLibrary(state),
});

const mapDispatchToProps = {
    loadLibrary,
    createProjectMaterial
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(ImportMaterial);
