/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './Draggable3DIcon.scss'

import * as THREE from 'three';
import {Tooltip} from '@mui/material';
import {connect} from 'react-redux';

import {startProjectDrag} from '../../../../+store/actions/project';
import {DRAGGABLE_ICON_PROPS} from '../../../../../core/constants/3DICON';
import {CustomIconsSVG} from '../svg/icons-map/CustomIconsSVG';
import {setSelectedObject} from "../../../../+store/actions/actions";

const {aspect, rotate, intensity, cameraFOV} = DRAGGABLE_ICON_PROPS;

// const icons = ['box', "heatsink", 'via_array', 'cylinder', 'pcb, ball_array', 'source'];

class Draggable3DIcon extends Component {
    constructor(props) {
        super(props);

        const {materials, boxes, lightPosition, rotateObj = true, customMeshes = false} = this.props;

        this.animate = this.animate.bind(this);

        this.scene = new THREE.Scene();
        this.light = new THREE.DirectionalLight(0xffffff, intensity);
        this.light.position.set(lightPosition.x || 1, lightPosition.y || 1, lightPosition.z || 1);
        this.light.target.position.set(0, 0, 0);
        this.scene.add(this.light);

        this.camera = new THREE.PerspectiveCamera(cameraFOV, aspect, 0.01, 10000);
        this.camera.position.set(50, 20, 0);
        this.camera.lookAt(0, 0, 0);

        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true,
        });

        const allMaterials = {};
        const allBoxes = {};
        const allMeshes = {};
        const group = new THREE.Group();

        materials.forEach((material, idx) => {
            allMaterials[idx] = material;
            allBoxes[idx] = boxes[idx];

            allMeshes[idx] = new THREE.Mesh(boxes[idx], material);
            allMeshes[idx].position.set(0, idx, idx);
            group.add(allMeshes[idx]);

            if (rotateObj) {
                allMeshes[idx].rotateX(Math.PI / 2);
                allMeshes[idx].position.set(0, 0, 0);
            }
        });

        if (customMeshes) {
            if (customMeshes.type === 'heatsink')
                for (let i = 0; i < customMeshes.multiplier; ++i) {
                    const heatsinkCustomMesh = new THREE.Mesh(customMeshes.box, customMeshes.material);

                    heatsinkCustomMesh.position.set((i - 1) * 3 - 6, customMeshes.position.y, customMeshes.position.z);
                    group.add(heatsinkCustomMesh);
                }
        }

        this.object = group;
        this.scene.add(group);
    }

    onWindowsResize = () => {
        this.renderer.setSize(this.dom.offsetWidth, this.dom.offsetWidth / aspect);
    };

    animate() {
        if (this.dom) {
            this.object.rotateY(rotate);
            this.renderer.setViewport(0, 0, this.dom.offsetWidth, this.dom.offsetHeight);
            this.renderer.render(this.scene, this.camera);
        }

        // requestAnimationFrame(this.animate);
    }

    componentDidMount() {
        window.addEventListener('resize', this.onWindowsResize, false);

        this.dom.appendChild(this.renderer.domElement);

        this.animate();
        this.onWindowsResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowsResize, false);
    }

    onDragStart = () => {
        this.props.startProjectDrag(this.props.dragType);
        this.props.setSelectedObject();
    };

    onDragEnd = () => {
        this.props.startProjectDrag(null);
    };

    render() {
        const {tooltipTitle, objId} = this.props;

        return (
            <Tooltip title={tooltipTitle} followCursor={true}>
                <div draggable
                     className={'draggable-3d-icon-wrapper'}
                     id={objId}
                     ref={ref => (this.dom = ref)}
                     onDragStart={this.onDragStart}
                     onDragEnd={this.onDragEnd}>
                    <CustomIconsSVG iconType={this.props.dragType}/>
                </div>
            </Tooltip>
        );
    }
}

Draggable3DIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    startProjectDrag: PropTypes.func,
    setSelectedObject: PropTypes.func
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {
    startProjectDrag,
    setSelectedObject
};

export default connect(mapStateToProps, mapDispatchToProps)(Draggable3DIcon);
