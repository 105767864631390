/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';
import SortableTree, {addNodeUnderParent, changeNodeAtPath} from '@nosferatu500/react-sortable-tree';
import '@nosferatu500/react-sortable-tree/style.css';
import './SideMenuProjectCardsTree.scss';

import {TreeTheme} from './theme'; // from https://github.com/frontend-collective/react-sortable-tree-theme-file-explorer and modified
import {Button} from '@mui/material';

import {
    getActiveFolder,
    getDraggedProject,
    getProjectCartNextParentId,
    getProjectsTreeData,
    getResponseFolder,
} from '../../reducer';
import {
    updateTreeDataAction,
    setActiveFolderAction,
    updateFolderAction,
    createFolderAction,
    setDraggedProjectNextParent,
    updateProject,
    projectCardDragEnd,
} from '../../actions';


import {dashboardUtils} from '../../../core/dashboardUtils';
import {INITIAL_FOLDER_DIALOG} from '../../../core/constants/INITIAL_USE_STATE';

import FolderInputComponent from './folder-input/folder-input.component';
import {MAIN_FOLDER_NAMES} from '../../../core/constants/PROJECTS_TREE';
import {FONT_AWESOME_ICONS_TYPE_MAP} from '../../../core/mappings';
import {IconsFontAwesome} from '../../../components/IconsFontAwesome';


const SideMenuProjectCardsTree = ({
                                      treeData,
                                      updateTreeDataAction,
                                      updateFolderAction,
                                      createFolderAction,
                                      activeFolder,
                                      responseFolder,
                                      setDraggedProjectNextParent,
                                      draggedProject,
                                      draggedProjectNextParentId,
                                      updateProject,
                                      projectCardDragEnd,
                                      setActiveFolderAction,
                                  }) => {
    const [folderDialogAction, setFolderDialogAction] = useState(INITIAL_FOLDER_DIALOG);

    const sharedFolderDropClassName = (draggedProject && draggedProjectNextParentId === 0) ? ' disabled-drop-project' : '';

    function updateFolderParent({treeData, parentFolderId, node, nextPath}) {
        Promise.allSettled([updateFolderAction({
            folderId: node.id,
            body: {
                name: node.name,
                parent_folder: parentFolderId,
                child_folders: node.children,
            },
        })]).then((res) => {
            const newTree = changeNodeAtPath({
                treeData,
                path: nextPath,
                getNodeKey: dashboardUtils.getNodeKey,
                newNode: {
                    ...node,
                    parent_folder: parentFolderId,
                },
            });

            updateTreeDataAction({treeData: newTree, treeType: 'treeData'});
        }).catch(e => {
            console.log({e});
        });
    }

    function addFolder(name) {
        createFolderAction({
            name,
            parent_folder: activeFolder.node.id,
            child_folders: [],
        });
    }

    function closeDialog() {
        setFolderDialogAction(INITIAL_FOLDER_DIALOG);
    }

    useEffect(() => {
        if (responseFolder && folderDialogAction.open) {
            const {child_folders, ...restResponseData} = responseFolder;

            const newTreeData = addNodeUnderParent({
                treeData,
                parentKey: activeFolder.path[activeFolder.path.length - 1],
                expandParent: true,
                getNodeKey: dashboardUtils.getNodeKey,
                newNode: {
                    ...restResponseData,
                    children: child_folders,
                    isDirectory: true,
                    expanded: false,
                },
            });

            updateTreeDataAction({treeData: newTreeData.treeData, treeType: 'treeData'});
            closeDialog();
        }
    }, [responseFolder]);


    return (
        <div className={'projects-sortable-tree-container'}>
            <div className={'my-projects-tree'}>
                <Button id={'addFolder'}
                        className={'add-folder'}
                        variant={'contained'}
                        color={'primary'}
                        disabled={activeFolder.path === null && activeFolder.node.id === 0 && activeFolder.node.name === MAIN_FOLDER_NAMES.sharedProjects}
                        onClick={() => setFolderDialogAction({open: true, actionType: 'Create Folder'})}>
                    <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.plus}
                                      hasHover={false}
                                      secondClass={'smaller'}/>
                    Folder
                </Button>
                {folderDialogAction.open && <FolderInputComponent folderDialogAction={folderDialogAction}
                                                                  onSubmitHandler={addFolder}
                                                                  closeDialog={closeDialog}/>}
                <div className={'tree-container'}>
                    <div
                        className={`row shared-with-me${activeFolder.node.id === 0 ? ' active' : ''}${sharedFolderDropClassName}`}
                        onClick={() => setActiveFolderAction({
                            path: null,
                            node: {id: 0, name: MAIN_FOLDER_NAMES.sharedProjects},
                        })}
                        onMouseEnter={() => {
                            if (draggedProject === null) {
                                return;
                            }

                            setDraggedProjectNextParent(0);
                        }}
                        onMouseUp={(e) => {
                            e.stopPropagation();
                            e.preventDefault();

                            if (!sharedFolderDropClassName && draggedProject) {
                                updateProject({...draggedProject, folder: draggedProjectNextParentId});
                            }

                            if (draggedProject !== null) {
                                projectCardDragEnd();
                            }
                        }}>
                        Shared with me
                    </div>
                    <SortableTree
                        treeData={treeData}
                        theme={TreeTheme}
                        rowHeight={28}
                        onChange={(changedTreeData) => updateTreeDataAction({
                            treeData: changedTreeData,
                            treeType: 'treeData',
                            sort: false,
                        })}
                        canDrag={({node}) => node.isDirectory}
                        onMoveNode={({treeData, node, nextParentNode, nextPath}) => {
                            updateFolderParent({treeData, parentFolderId: nextParentNode.id, node, nextPath});
                        }}
                        canDrop={({prevParent, nextParent}) => {
                            return (nextParent && (!nextParent.hasOwnProperty('shared') || nextParent.isDirectory) && prevParent.id !== nextParent.id);
                        }}
                        onlyExpandSearchedNodes={true}
                        generateNodeProps={(rowInfo) => ({
                            //rowInfo: {isSearchFocus, isSearchMatch, lowerSiblingCounts, node, parentNode, path, treeIndex,}
                            style: {
                                height: '25px',
                            },
                        })}
                    />
                </div>
            </div>
        </div>
    );
};

SideMenuProjectCardsTree.propTypes = {
    treeData: PropTypes.array.isRequired,
    updateTreeDataAction: PropTypes.func.isRequired,
    setActiveFolderAction: PropTypes.func.isRequired,
    responseFolder: PropTypes.object,
};

const mapStateToProps = state => ({
    treeData: getProjectsTreeData(state),
    activeFolder: getActiveFolder(state),
    responseFolder: getResponseFolder(state),
    draggedProject: getDraggedProject(state),
    draggedProjectNextParentId: getProjectCartNextParentId(state),
});

const mapDispatchToProps = {
    updateTreeDataAction,
    setActiveFolderAction,
    updateFolderAction,
    createFolderAction,
    setDraggedProjectNextParent,
    updateProject,
    projectCardDragEnd,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(SideMenuProjectCardsTree);

