/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import './Project.scss';

import { Tabs, Tab, Grid, Box } from '@mui/material';

import {
    ThreeDRotation as ThreeDRotationIcon,
    Description as DescriptionIcon,
    TableChart as TableChartIcon,
    Computer as ComputerIcon,
    Tune as TuneIcon,
    BarChart as BarChartIcon,
} from '@mui/icons-material';
import withStyles from '@mui/styles/withStyles';

import { updatePlane } from './+store/actions/planes';
import {
    loadPlaneSolutions, loadProjectObjectTransient,
    loadProjectSolution,
    loadProjectTask, loadProjectTransient, loadTaskStatus,
} from './+store/actions/actions';
import {
    loadProjectMaterials,
    setProjectMode,
    undoProject,
    loadProjectTree,
} from './+store/actions/project';
import { updateProjectAndTree as updateProject } from '../dashboard/actions';

import { getData as getPlanes } from './+store/reducer/planes';
import { getData as getTree, getSelectedObject, isLoading as loadingTree } from './+store/reducer/tree';
import { getData, getProjectMode } from './+store/reducer/project';
import { getData as getPlaneSolutions, isLoading as loadingPlaneSolutions } from './+store/reducer/plane_solutions';
import {
    getData as getSolution, getTaskId, getTaskStatus, getTaskStatusTime,
    isLoading as loadingSolution,
} from './+store/reducer/solution';

import Spinner from '../components/Spinner';
import Logs from './components/tabs/logs/Logs.component';
import Header from '../components/Header';
import TabPanel from '../components/TabPanel';
import ProjectTable from './components/tabs/table/ProjectTable';

import MaterialsComponent from './components/tabs/materials/Materials.component';
import ScenariosComponent from './components/tabs/scenarios/Scenarios.component';
import ParametersComponent from './components/tabs/parameters/Parameters.component';

import AsidePanel from './components/panels/aside-panel/AsidePanel';
import MiddlePanel from './components/panels/middle-panel/MiddlePanel';
import RightPanel from './components/panels/right-panel/RightPanel';
import _ from "lodash/fp";

const styles = (theme) => ({
    root: {
        color: theme.palette.text.primary,
    },
    icon: {
        cursor: 'pointer',
    },
    asidePanel: {
        padding: theme.spacing(1),
        width: '350px',
    },
    rightPanel: {
        padding: theme.spacing(0.875),
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column !important',
        width: '350px',
    },
    d3panel: {
        width: 'calc(100vw - 705px)',
    },
    projectPanel: {
        padding: 0,
    },
    search: {
        margin: theme.spacing(2),
    },
    materialsPanel: {
        margin: theme.spacing(2),
    },
    tabIcon: {
        verticalAlign: 'middle',
        marginRight: 5,
    },
    materialButton: {
        marginRight: 10,
    },
});
let taskStatusTimeout = null;


class Project extends PureComponent {
    storedSolveStatus = {};

    constructor(props) {
        super(props);

        this.state = {
            tree: [],
            solution: {},
            plane_solutions: null,
            tab: 0,
            solveHovered: false,
        };
    };

    componentDidMount() {
        document.addEventListener('keydown', this.undoEvent);

        if (localStorage.getItem('solveStatus') === null) {
            localStorage.setItem('solveStatus', JSON.stringify({}));
        } else {
            this.storedSolveStatus = JSON.parse(localStorage.getItem('solveStatus'));
        }

        if (this.props.current_project?.id !== this.props.match.params.project_id) {
            this.props.loadProjectTree({ project_id: this.props.match.params.project_id })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.current_project) {
            document.title = `${this.props.current_project.name} - DANKA THERMAL (${this.props.current_project.id})`;
        }

        if (
            (this.props.task_id && this.props.task_status_time !== prevProps.task_status_time) ||
            (this.storedSolveStatus?.task_id && ['PENDING', 'RUNNING'].includes(this.storedSolveStatus.task_id))
        ) {
            taskStatusTimeout && clearTimeout(taskStatusTimeout);

            taskStatusTimeout = setTimeout(() => {
                this.props.task_id && this.props.loadTaskStatus({
                    project_id: this.props.current_project.id,
                    task_id: this.props.task_id,
                });
            }, 3000);
        }

        if (!_.isEqual(prevProps.selectedObject, this.props.selectedObject)) {
            if (Object.keys(this.props.selectedObject || {}).length > 0) {
                this.props.loadProjectObjectTransient({ ...this.props.selectedObject, project_id: this.props.current_project.id });
            } else if (this.props.current_project) {
                this.props.loadProjectTransient({ project_id: this.props.current_project.id });
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.undoEvent);
        taskStatusTimeout && clearTimeout(taskStatusTimeout);
    }

    undoEvent = (e) => {
        if (!this.props.undoing && (e.type === 'click' || e.type === 'keydown' && (e.ctrlKey || e.metaKey) && (e.keyCode === 90 || e.keyCode === 122))) {
            // alert('ctrl+z', this.props.current_project);
            this.props.undoProject(this.props.current_project);
        }
    };

    handleTabChange = (event, value) => {
        this.setState({ tab: value });
    };

    render() {
        const { classes, current_project, solution } = this.props;

        const { tab } = this.state;

        return (
            <div className={classes.root}>
                <Header />
                <Box sx={{ borderBottom: 1, borderColor: 'background.input' }}>
                    <Tabs sx={{
                        '& .MuiButtonBase-root': {
                            color: '#fafafa',
                            // width: '160px',
                            opacity: (theme) => theme.opacity.faded,

                            '&.Mui-selected': {
                                color: '#fafafa',
                                opacity: (theme) => theme.opacity.main,
                            },

                            '&:hover': {
                                opacity: (theme) => theme.opacity.main,
                            }
                        },
                    }}
                        value={tab}
                        variant={'scrollable'}
                        onChange={this.handleTabChange}
                        scrollButtons={'auto'}>
                        <Tab label={
                            <div id={'threeDrotationid'}>
                                <ThreeDRotationIcon id={'threeDrotationid'} className={classes.tabIcon}
                                    fontSize={'small'} />
                                Project
                            </div>} />
                        <Tab label={
                            <div id={'descriptionid'}>
                                <DescriptionIcon className={classes.tabIcon} fontSize={'small'} />
                                Materials
                            </div>} />
                        <Tab label={
                            <div id={'tablechartid'}>
                                <TableChartIcon className={classes.tabIcon} fontSize={'small'} />
                                Table
                            </div>} />
                        <Tab label={
                            <div>
                                <TuneIcon className={classes.tabIcon} fontSize={'small'} />
                                Parameters
                            </div>} />
                        <Tab label={
                            <div>
                                <BarChartIcon className={classes.tabIcon} fontSize={'small'} />
                                Scenarios
                            </div>} />
                        <Tab label={
                            <div id={'solverlogsid'}>
                                <ComputerIcon className={classes.tabIcon} fontSize={'small'} />
                                Solver Logs
                            </div>} />
                    </Tabs>
                </Box>
                <TabPanel value={tab} index={0} className={classes.projectPanel}>
                    <Grid container columns={20}>
                        {
                            current_project && current_project.id
                                ? <>
                                    <Grid item className={classes.asidePanel}>
                                        <AsidePanel tab={tab} />
                                    </Grid>

                                    <Grid item  sx={{ position: 'relative' }} className={classes.d3panel}>
                                        <MiddlePanel />
                                    </Grid>

                                    <Grid item className={classes.rightPanel}>
                                        <RightPanel />
                                    </Grid>
                                </>
                                : <Spinner />
                        }
                    </Grid>
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <MaterialsComponent current_project={current_project} classes={classes} />
                </TabPanel>
                <TabPanel value={tab} index={2}>
                    <ProjectTable current_project={current_project} solution={solution} />
                </TabPanel>
                <TabPanel value={tab} index={3}>
                    <ParametersComponent current_project={current_project} />
                </TabPanel>
                <TabPanel value={tab} index={4}>
                    <ScenariosComponent current_project={current_project} />
                </TabPanel>
                <TabPanel value={tab} index={5}>
                    <Logs project={current_project} />
                </TabPanel>
            </div>
        );
    }
}

Project.propTypes = {
    loadingTree: PropTypes.bool.isRequired,
    loadingSolution: PropTypes.bool.isRequired,
    current_project: PropTypes.object,
    solution: PropTypes.object,
    plane_solutions: PropTypes.arrayOf(PropTypes.object),
    planes: PropTypes.arrayOf(PropTypes.object),
    updateProject: PropTypes.func.isRequired,
    loadProjectSolution: PropTypes.func.isRequired,
    loadPlaneSolutions: PropTypes.func.isRequired,
    tree: PropTypes.array.isRequired,
    loadProjectTree: PropTypes.func.isRequired,
    mode: PropTypes.string,
    undoing: PropTypes.bool.isRequired,
    loadProjectObjectTransient: PropTypes.func.isRequired,
    loadProjectTransient: PropTypes.func.isRequired,
    selectedObject: PropTypes.object,

    task_id: PropTypes.number,
    task_status: PropTypes.string,
    task_status_time: PropTypes.number,
    loadTaskStatus: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    loadingTree: loadingTree(state),
    loadingSolution: loadingSolution(state),
    loadingPlaneSolutions: loadingPlaneSolutions(state),
    current_project: getData(state),
    solution: getSolution(state),
    plane_solutions: getPlaneSolutions(state),
    planes: getPlanes(state),
    tree: getTree(state),
    mode: getProjectMode(state),
    undoing: state.current_project.properties.undoing,
    task_id: getTaskId(state),
    task_status: getTaskStatus(state),
    task_status_time: getTaskStatusTime(state),
    selectedObject: getSelectedObject(state)
});

const mapDispatchToProps = {
    setProjectMode,
    loadProjectSolution, loadPlaneSolutions, loadProjectMaterials, loadProjectTask,
    updatePlane, updateProject,
    undoProject,
    loadTaskStatus,
    loadProjectTree,
    loadProjectObjectTransient, loadProjectTransient
};

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(Project);
