/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {
    LOAD_CLIP_FACE_SOLUTION_X0_SUCCESS, LOAD_CLIP_FACE_SOLUTION_X1_SUCCESS,
    LOAD_CLIP_FACE_SOLUTION_Y0_SUCCESS, LOAD_CLIP_FACE_SOLUTION_Y1_SUCCESS,
    LOAD_CLIP_FACE_SOLUTION_Z0_SUCCESS, LOAD_CLIP_FACE_SOLUTION_Z1_SUCCESS,
    LOAD_CLIP_FACE_SOLUTION_TIME_POINT_X0_SUCCESS, LOAD_CLIP_FACE_SOLUTION_TIME_POINT_X1_SUCCESS,
    LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Y0_SUCCESS, LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Y1_SUCCESS,
    LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Z0_SUCCESS, LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Z1_SUCCESS,
    STORE_CLIP_FACE_DATA, CLEAR_CLIP_FACE_SOLUTIONS,
    LOAD_PLANE_SOLUTIONS,
    LOAD_PLANE_SOLUTIONS_ERROR,
    LOAD_PLANE_SOLUTIONS_SUCCESS,
    LOAD_PROJECT_CASE_PLANE_SOLUTIONS,
    LOAD_PROJECT_CASE_PLANE_SOLUTIONS_ERROR,
    LOAD_PROJECT_CASE_PLANE_SOLUTIONS_SUCCESS,
} from '../actions/actions';

export const defaultState = {
    data: null,
    loading: false,
    solving: false,
    clipFaceSolutions: {},
    clipFacesData: {},
};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case LOAD_PLANE_SOLUTIONS:
        case LOAD_PROJECT_CASE_PLANE_SOLUTIONS:
            return {
                ...state,
                data: [],
                loading: true,
            };
        case LOAD_PLANE_SOLUTIONS_ERROR:
        case LOAD_PROJECT_CASE_PLANE_SOLUTIONS_ERROR:
            return {
                data: null,
                loading: false,
            };
        case LOAD_PLANE_SOLUTIONS_SUCCESS:
        case LOAD_PROJECT_CASE_PLANE_SOLUTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case LOAD_CLIP_FACE_SOLUTION_X0_SUCCESS:
        case LOAD_CLIP_FACE_SOLUTION_TIME_POINT_X0_SUCCESS:
            return {
                ...state,
                clipFaceSolutions: {
                    ...state.clipFaceSolutions,
                    x0: action.payload
                },
            };
        case LOAD_CLIP_FACE_SOLUTION_X1_SUCCESS:
        case LOAD_CLIP_FACE_SOLUTION_TIME_POINT_X1_SUCCESS:
            return {
                ...state,
                clipFaceSolutions: {
                    ...state.clipFaceSolutions,
                    x1: action.payload
                },
            };
        case LOAD_CLIP_FACE_SOLUTION_Y0_SUCCESS:
        case LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Y0_SUCCESS:
            return {
                ...state,
                clipFaceSolutions: {
                    ...state.clipFaceSolutions,
                    y0: action.payload
                },
            };
        case LOAD_CLIP_FACE_SOLUTION_Y1_SUCCESS:
        case LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Y1_SUCCESS:
            return {
                ...state,
                clipFaceSolutions: {
                    ...state.clipFaceSolutions,
                    y1: action.payload
                },
            };
        case LOAD_CLIP_FACE_SOLUTION_Z0_SUCCESS:
        case LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Z0_SUCCESS:
            return {
                ...state,
                clipFaceSolutions: {
                    ...state.clipFaceSolutions,
                    z0: action.payload
                },
            };
        case LOAD_CLIP_FACE_SOLUTION_Z1_SUCCESS:
        case LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Z1_SUCCESS:
            return {
                ...state,
                clipFaceSolutions: {
                    ...state.clipFaceSolutions,
                    z1: action.payload
                },
            };

        case CLEAR_CLIP_FACE_SOLUTIONS:
            return {
                ...state,
                clipFaceSolutions: {},
                clipFacesData: {}
            }

        case STORE_CLIP_FACE_DATA:
            const { axis, ...rest} = action.payload;
            return {
                ...state,
                clipFacesData: {
                    ...state.clipFacesData,
                    [axis]: rest
                }
            }
        default:
            return state;
    }
};

export const hasData = ({current_project}) => !!current_project.plane_solutions.data;
export const getData = ({current_project}) => current_project.plane_solutions.data;

export const clipFacePlaneSolutionsHasData = ({current_project}) => Object.values(current_project.plane_solutions.clipFaceSolutions).some(solutionPlane => solutionPlane.result.length > 0);
export const getClipFacePlaneSolutions = ({current_project}) => current_project.plane_solutions.clipFaceSolutions;
export const getClipFacesStoredData = ({current_project}) => current_project.plane_solutions.clipFacesData;

export const isLoading = ({current_project}) => current_project.plane_solutions.loading;


