/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';

import withStyles from '@mui/styles/withStyles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    Button,
    DialogContent,
    DialogActions,
    FormControl,
    FormControlLabel,
    Checkbox,
    FormGroup,
    Typography,
    InputAdornment,
    Tooltip,
    Box
} from '@mui/material';

import {showModal} from '../../../modals/actions';
import {
    createAssembly,
    deleteProjectAssembly,
    updateProjectAssembly,
    updateProjectAssemblyAndTree,
    updateProjectTree
} from '../../+store/actions/actions';
import {getData as getTree} from '../../+store/reducer/tree';
import {getValidationErrors} from '../../+store/reducer/validation_errors';
import {getData as getProject} from '../../+store/reducer/project';
import {TextValidator} from 'react-material-ui-form-validator';

import MyValidationForm from '../../../components/MyValidationForm';
import HeatTransferCoeff from '../../../components/HeatTranserCoeff';

import {projectUtils} from '../../utils';
import {ActiveInactiveStateIcon} from '../../../components/ActiveStateIcon';
import {FONT_AWESOME_ICONS_TYPE_MAP} from '../../../core/mappings';
import {CustomizedSwitch} from '../../../components/CustomizedSwitch';

const styles = (theme) => ({
    root: {
        '& > *': {
            marginBottom: theme.spacing(1),
            marginRight: theme.spacing(2),
        },
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    field: {
        margin: theme.spacing(1),
        width: '25ch',
    },
});

const message_floating_point = 'Enter a valid floating point number!';


class CreateAssemblySettings extends Component {

    constructor(props) {
        super(props);

        this.state = props.box || {
            name: '',
            active: true,
            visible: true,
            index: 0,
            lumped: false,
            hc: null,
            is_array: false,
            xcount: '0', ycount: '0', zcount: '0',
            xpitch: '0', ypitch: '0', zpitch: '0',
        };

        this.form = React.createRef();

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    handleChange(field_name, value) {
        this.setState({
            [field_name]: value,
        });
    }

    handleSubmit() {
        this.form.current.isFormValid(false)
            .then(is_valid => {
                if (is_valid) {
                    const {onClose, box, createAssembly, updateProjectAssemblyAndTree} = this.props;
                    if (box && box.id) {
                        updateProjectAssemblyAndTree({
                            project_id: this.props.project_id,
                            assembly: {
                                ...this.state,
                            },
                        });
                    } else {
                        createAssembly({
                            project_id: this.props.project_id,
                            assembly: {
                                ...this.state,
                                parent: null,
                                //new assembly should be expanded to enable adding/drag&drop child node
                                collapsed: false,
                            },
                        });
                    }

                }
            });
    }

    handleDelete() {
        const {box, deleteProjectAssembly, tree, updateProjectTree} = this.props;
        deleteProjectAssembly({
            project_id: this.props.project_id,
            box: {
                ...box,
                ...this.state,
            },
        });

        // updateProjectTree(projectUtils.updatedTreeAfterDeleteNode(tree, box));
    }

    renderInputFields = (field_names, units, ids) => {
        const {classes, validation_errors} = this.props;
        return field_names.map((prop_name) => (
            <TextValidator
                id={ids}
                key={prop_name}
                className={classes.root}
                label={prop_name.toUpperCase()}
                value={this.state[prop_name] || ''}
                onChange={(event) => this.handleChange(prop_name, event.target.value)}
                variant="standard"
                error={!!validation_errors[prop_name]}
                helperText={validation_errors[prop_name]}
                validators={['required', 'isCustomFloat']}
                errorMessages={['This field is required!', 'Enter a valid floating point number!']}
                InputProps={{
                    endAdornment: <InputAdornment position={'end'}>{units}</InputAdornment>,
                }}
            />
        ));
    };


    render() {
        const {classes, validation_errors, box} = this.props;

        return (
            <Fragment>
                <DialogContent dividers>
                    <MyValidationForm
                        ref={this.form}
                        onSubmit={this.handleSubmit}
                        className={'validation-form-sm'}
                    >
                        <div>
                            <FormGroup row sx={{alignSelf: 'center'}}>
                                <TextValidator
                                    id={'assemblyname'}
                                    key={'name'}
                                    className={classes.root}
                                    label={'Name'}
                                    value={this.state['name'] || ''}
                                    onChange={(event) => this.handleChange('name', event.target.value)}
                                    variant={'standard'}
                                    error={!!validation_errors['name']}
                                    helperText={validation_errors['name']}
                                    validators={['required']}
                                    errorMessages={['This field is required!']}
                                />
                                <Box sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    mt: '10px',
                                }}>
                                    <ActiveInactiveStateIcon active={this.state.active}
                                                             activeIconType={FONT_AWESOME_ICONS_TYPE_MAP.powerActive}
                                                             inactiveIconType={FONT_AWESOME_ICONS_TYPE_MAP.powerInactive}
                                                             titleActive={'Set Inactive Assembly'}
                                                             titleInactive={'Set Active Assembly'}
                                                             iconsSX={{fontSize: '1rem', mr: '10px'}}
                                                             onClickHandler={(event) => this.handleChange('active', !this.state.active)}/>
                                    <ActiveInactiveStateIcon active={this.state.visible}
                                                             activeIconType={FONT_AWESOME_ICONS_TYPE_MAP.eyeVisible}
                                                             inactiveIconType={FONT_AWESOME_ICONS_TYPE_MAP.eyeNotVisible}
                                                             titleActive={'Hide Assembly'}
                                                             titleInactive={'Show Assembly'}
                                                             iconsSX={{fontSize: '1rem', mr: '10px'}}
                                                             onClickHandler={(event) => this.handleChange('visible', !this.state.visible)}/>
                                </Box>
                            </FormGroup>
                        </div>

                        <Typography variant={'h6'} className={classes.title}>Solver options</Typography>
                        <FormControl variant={'standard'}>
                            <FormControlLabel
                                id={'lumpedmodel'}
                                control={
                                    // <CustomizedSwitch checked={!!this.state.lumped}
                                    //                   onChange={(event) => this.handleChange('lumped', !this.state.lumped)}/>
                                    <Checkbox
                                        checked={!!this.state.lumped}
                                        onChange={(event) => this.handleChange('lumped', !this.state.lumped)}
                                    />
                                }
                                label={
                                    <span>
                                    Quick Model
                                        <Tooltip title={
                                            <React.Fragment>
                                                <span style={{
                                                    fontSize: 14,
                                                }}>
                                                    Use a lumped model instead of detailed Via Array objects. This option is valid only when a Fill material has been specified.
                                                    <br/>
                                                    Quick Model will use approximated material properties in all 3 axes and will be created at a maximum resolution of 500&micro;m.
                                                    <br/>
                                                    NOTE: Quick Model will be automatically used if ball count exceeds 100K.
                                                </span>
                                            </React.Fragment>
                                        }>
                                            <InfoOutlinedIcon fontSize={'inherit'}/>
                                        </Tooltip>
                                    </span>
                                }
                            />
                        </FormControl>
                        <HeatTransferCoeff className={classes.field}
                                           sx={{mr: '10px'}}
                                           variant={'standard'}
                                           validators={[]}
                                           value={this.state.hc || ''}
                                           onChange={(event) => this.handleChange('hc', event.target.value)}
                                           error={!!validation_errors['hc']}
                                           helperText={validation_errors['hc']}
                                           errorMessages={[message_floating_point]}
                        />
                    </MyValidationForm>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleSubmit} id={'addassembly'} variant={'contained'} color={'primary'}
                            autoFocus>
                        Add
                    </Button>
                </DialogActions>
            </Fragment>
        );
    }
}

CreateAssemblySettings.propTypes = {
    createAssembly: PropTypes.func.isRequired,
    deleteProjectAssembly: PropTypes.func.isRequired,
    updateProjectAssembly: PropTypes.func.isRequired,
    updateProjectAssemblyAndTree: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    project_id: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
    validation_errors: getValidationErrors(state),
    project_id: getProject(state).id,
    tree: getTree(state),
});

const mapDispatchToProps = {
    createAssembly,
    deleteProjectAssembly,
    updateProjectAssembly,
    updateProjectAssemblyAndTree,
    updateProjectTree,
    showModal,
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(CreateAssemblySettings);
