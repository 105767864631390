/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React from 'react';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';


const DialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <MuiDialogTitle {...other}>
        <Typography variant="h6">{children}</Typography>
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: (theme) => theme.spacing(1),
            top:(theme) => theme.spacing(1),
            color:(theme) => theme.palette.grey[500],
          }}
          onClick={onClose}
          size="large">
            <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
    );
  };

export default DialogTitle  
