/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {confirmAlert} from 'react-confirm-alert';
import './context-menu.component.scss';
import withStyles from '@mui/styles/withStyles';
import {addNodeUnderParent, changeNodeAtPath, removeNodeAtPath} from '@nosferatu500/react-sortable-tree';
import {MenuItem, Menu} from '@mui/material';

import {
    createFolderAction,
    updateTreeDataAction,
    updateFolderAction,
    deleteFolderAction,
} from '../../../actions';
import {getProjectsTreeData, getResponseFolder} from '../../../reducer';

import {dashboardUtils} from '../../../../core/dashboardUtils';
import {INITIAL_FOLDER_DIALOG} from '../../../../core/constants/INITIAL_USE_STATE';

import FolderInputComponent from '../folder-input/folder-input.component';

const styles = (theme) => ({
    paper: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        marginTop: theme.spacing(3),
    },
});


const ContextMenuComponent = ({
                                  contextMenuInfo,
                                  setContextMenuInfo,
                                  createFolderAction,
                                  updateFolderAction,
                                  deleteFolderAction,
                                  updateTreeDataAction,
                                  treeData,
                                  responseFolder,
                              }) => {
    const [folderDialogAction, setFolderDialogAction] = useState(INITIAL_FOLDER_DIALOG);

    const currentPath = contextMenuInfo.path;

    function createSubFolder(name) {
        createFolderAction({
            name,
            parent_folder: contextMenuInfo.node.id,
            child_folders: [],
        });
    }

    function renameFolder(name) {
        updateFolderAction({
            folderId: contextMenuInfo.id,
            body: {
                name,
                parent_folder: contextMenuInfo.node.parent_folder,
                child_folders: [],
            },
        });
    }

    function deleteFolder() {
        deleteFolderAction({
            folderId: contextMenuInfo.id,
            body: {
                parent_folder: contextMenuInfo.node.parent_folder,
            },
        });

        const newTree = removeNodeAtPath({
            treeData,
            path: currentPath,
            getNodeKey: dashboardUtils.getNodeKey,
        });

        updateTreeDataAction({treeData: newTree, treeType: 'treeData'});
    }

    function closeDialog() {
        if (folderDialogAction.open) {
            setFolderDialogAction(INITIAL_FOLDER_DIALOG);
            setContextMenuInfo(null);
        }
    }

    useEffect(() => {
        if (responseFolder && folderDialogAction.open) {
            const {child_folders, ...restResponseData} = responseFolder;
            let newTreeData = {};

            if (folderDialogAction.actionType === 'New Subfolder') {
                const updatedTree = addNodeUnderParent({
                    treeData,
                    parentKey: currentPath[currentPath.length - 1],
                    expandParent: true,
                    getNodeKey: dashboardUtils.getNodeKey,
                    newNode: {
                        ...restResponseData,
                        children: child_folders,
                        isDirectory: true,
                        expanded: false,
                    },
                });

                newTreeData = updatedTree.treeData;
            } else if (folderDialogAction.actionType === 'Rename Folder') {
                newTreeData = changeNodeAtPath({
                    treeData,
                    path: currentPath,
                    getNodeKey: dashboardUtils.getNodeKey,
                    newNode: {
                        ...restResponseData,
                        children: child_folders,
                        isDirectory: true,
                    },
                });
            }

            updateTreeDataAction({treeData: newTreeData, treeType: 'treeData'});
            closeDialog();
        }
    }, [responseFolder]);

    useEffect(() => {
        if (folderDialogAction.open) {
            setContextMenuInfo(prev => {
                return {
                    ...prev,
                    isOpen: false,
                };
            });
        }
    }, [folderDialogAction]);


    return (
        <>
            <Menu
                id={contextMenuInfo.node.id}
                anchorEl={contextMenuInfo.anchor}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={contextMenuInfo.isOpen}
                onClose={(e) => {
                    e.stopPropagation();
                    setContextMenuInfo(null);
                }}>
                <MenuItem key={'New Subfolder'} onClick={(e) => {
                    e.stopPropagation();
                    setFolderDialogAction({open: true, actionType: 'New Subfolder'});
                }}>New Subfolder</MenuItem>
                {contextMenuInfo && contextMenuInfo.id !== null && (
                    [<MenuItem key={'Rename Folder'} onClick={(e) => {
                        e.stopPropagation();
                        setFolderDialogAction({open: true, actionType: 'Rename Folder'});
                    }}>Rename Folder</MenuItem>,
                        <MenuItem key={'deleteFolder'} onClick={(e) => {
                            e.stopPropagation();

                            setContextMenuInfo(prev => {
                                return {
                                    ...prev,
                                    isOpen: false,
                                };
                            });

                            confirmAlert({
                                title: `Delete folder ${contextMenuInfo.node.name}?`,
                                message: 'Are you sure?',
                                buttons: [
                                    {
                                        label: 'Delete',
                                        onClick: deleteFolder,
                                    },
                                    {
                                        label: 'Cancel',
                                        onClick: () => {
                                            setContextMenuInfo(null);
                                        },
                                    },
                                ],
                            });
                        }}>Delete Folder</MenuItem>]
                )}
            </Menu>
            {folderDialogAction.open && <FolderInputComponent folderDialogAction={folderDialogAction}
                                                              contextMenuInfo={contextMenuInfo}
                                                              onSubmitHandler={folderDialogAction.actionType === 'New Subfolder' ? createSubFolder : renameFolder}
                                                              closeDialog={closeDialog}/>}

        </>
    );
};

ContextMenuComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    contextMenuInfo: PropTypes.object.isRequired,
    setContextMenuInfo: PropTypes.func.isRequired,
    createFolderAction: PropTypes.func.isRequired,
    updateFolderAction: PropTypes.func.isRequired,
    deleteFolderAction: PropTypes.func.isRequired,
    updateTreeDataAction: PropTypes.func.isRequired,
    treeData: PropTypes.array.isRequired,
    responseFolder: PropTypes.object,
};

const mapStateToProps = state => ({
    treeData: getProjectsTreeData(state),
    responseFolder: getResponseFolder(state),
});

const mapDispatchToProps = {
    createFolderAction,
    updateFolderAction,
    deleteFolderAction,
    updateTreeDataAction,
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(ContextMenuComponent);
