/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {
    UPDATE_PROJECT_ASSEMBLY, UPDATE_PROJECT_ASSEMBLY_AND_TREE_SUCCESS,
    TRANSLATE_PROJECT_ASSEMBLY_SUCCESS,
    UPDATE_PROJECT_ASSEMBLY_SUCCESS,
    UPDATE_PROJECT_TREE,
    UPDATE_PROJECT_TREE_SUCCESS, UPDATE_PROJECT_UI,
    TOGGLE_PROJECT_TABLE,
    UPDATE_PROJECT_OBJECT_POSITION,
    SET_SELECTED_OBJ,
    TRANSLATE_PROJECT_ASSEMBLY, UPDATE_SELECTED_OBJECT,
    UPDATE_PROJECT_BOX_AND_TREE_SUCCESS, FORCE_UPDATE_SELECTED_OBJECT, TRANSLATE_PROJECT_ASSEMBLY_ERROR,
} from '../actions/actions';
import {
    CHANGE_PROJECT_TREE,
    LOAD_PROJECT,
    LOAD_PROJECT_TREE,
    LOAD_PROJECT_TREE_ERROR,
    LOAD_PROJECT_TREE_SUCCESS, UNDO_PROJECT_SUCCESS,
} from '../actions/project';
import {projectUtils} from '../../utils';

import {BigNumber} from 'bignumber.js';
import {UPDATE_PLANE_SUCCESS} from '../actions/planes';
import {UPDATE_PROJECT_CYLINDER_AND_TREE_SUCCESS} from '../actions/cylinder';
import {UPDATE_PROJECT_SOURCE_AND_TREE_SUCCESS} from '../actions/source';
import { UPDATE_PROJECT_TRANSIENT_SOURCE_AND_TREE_SUCCESS} from '../actions/transient_source';
import {UPDATE_PROJECT_POLYGON_AND_TREE_SUCCESS} from '../actions/polygon';
import {UPDATE_PROJECT_HEATSINK_AND_TREE_SUCCESS} from '../actions/heatsink';
import {UPDATE_PROJECT_BGA_AND_TREE_SUCCESS} from '../actions/bga';
import {UPDATE_PROJECT_VIA_ARRAY_AND_TREE_SUCCESS} from '../actions/via_array';
import {UPDATE_PROJECT_PCB_AND_TREE_SUCCESS} from '../actions/pcb';

function updateTreeWithAssembly(tree, assembly) {
    return tree.map(item => {
        if (item.id === assembly.id && item.type === assembly.type) {
            const result = {...item};
            Object.assign(result, assembly);
            return result;
        } else if (item.children) {
            return {
                ...item,
                children: updateTreeWithAssembly(item.children, assembly),
            };
        } else {
            return item;
        }
    });
}

function calculateFields(tree) {
    tree.filter(item => item.type === 'assembly').forEach(item => {
        const {x0, y0, z0, x1, y1, z1} = calculateFields(item.children);
        if (x0.isFinite()) {
            item.x_calc = x0.toString();
            item.dx_calc = BigNumber.sum(x1, -x0).toString();
        }
        if (y0.isFinite()) {
            item.y_calc = y0.toString();
            item.dy_calc = BigNumber.sum(y1, -y0).toString();
        }
        if (y0.isFinite()) {
            item.z_calc = z0.toString();
            item.dz_calc = BigNumber.sum(z1, -z0).toString();
        }
    });
    let x0 = BigNumber.min(...tree.filter(item => item.hasOwnProperty('x_calc')).map(item => BigNumber(item.x_calc))),
        y0 = BigNumber.min(...tree.filter(item => item.hasOwnProperty('y_calc')).map(item => BigNumber(item.y_calc))),
        z0 = BigNumber.min(...tree.filter(item => item.hasOwnProperty('z_calc')).map(item => BigNumber(item.z_calc))),
        x1 = BigNumber.max(...tree.filter(item => item.hasOwnProperty('x_calc')).map(item => BigNumber.sum(BigNumber(item.x_calc), item.type !== 'heatsink' && BigNumber(item.dx_calc) || BigNumber(item.base_dx_calc)))),
        y1 = BigNumber.max(...tree.filter(item => item.hasOwnProperty('y_calc')).map(item => BigNumber.sum(BigNumber(item.y_calc), item.type !== 'heatsink' && BigNumber(item.dy_calc) || BigNumber(item.base_dy_calc)))),
        z1 = BigNumber.max(...tree.filter(item => item.hasOwnProperty('z_calc')).map(item => BigNumber.sum(BigNumber(item.z_calc), item.type !== 'heatsink' && BigNumber(item.dz_calc) || BigNumber(item.base_dz_calc))));

    return {x0, y0, z0, x1, y1, z1};
}

function update3DObjectPosition(tree, node) {
    return tree.map(item => {
        if (item.id === node.id && item.type === node.type) {
            return node;
        } else if (item.children) {
            return {
                ...item,
                children: update3DObjectPosition(item.children, node),
            };
        }

        return item;
    });
}

function getAssemblyElements(result, element) {
    if (element.type !== 'assembly') {
        result[element.id] = element;
    }


    if (element.hasOwnProperty('children') && element.children.length) {
        element.children.forEach(childElement => {
            getAssemblyElements(result, childElement);
        });
    }

    return result;
}


const defaultState = {
    data: null,
    loading: false,
    updateProjectTreeUI: 'noUpdate',
    selectedObject: {},
    assemblySelection: {},
    updateSelectedObject: false,
    forceUpdatedSelectedObject: false,
    undo: false,
};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case LOAD_PROJECT_TREE:
            return {
                ...state,
                loading: true,
                data: state.data || [],
                updateProjectTreeUI: 'update',
            };
        case LOAD_PROJECT_TREE_ERROR:
            return {
                ...state,
                data: state.data,
                loading: false,
                loadProjectTree: true,
            };
        case UPDATE_PROJECT_BOX_AND_TREE_SUCCESS:
        case UPDATE_PROJECT_CYLINDER_AND_TREE_SUCCESS:
        case UPDATE_PROJECT_SOURCE_AND_TREE_SUCCESS:
        case UPDATE_PROJECT_TRANSIENT_SOURCE_AND_TREE_SUCCESS:
        case UPDATE_PROJECT_POLYGON_AND_TREE_SUCCESS:
        case UPDATE_PROJECT_HEATSINK_AND_TREE_SUCCESS:
        case UPDATE_PROJECT_BGA_AND_TREE_SUCCESS:
        case UPDATE_PROJECT_VIA_ARRAY_AND_TREE_SUCCESS:
        case UPDATE_PROJECT_PCB_AND_TREE_SUCCESS:
            const updatedState = {
                ...state,
            };

            if (state.selectedObject && state.selectedObject.id) {
                updatedState.forceUpdatedSelectedObject = true;

                if (state.selectedObject.id === action.payload.id) {
                    updatedState.selectedObject = action.payload;
                }
            }

            return updatedState;
        case UPDATE_PLANE_SUCCESS:
            if (
                state.selectedObject &&
                state.selectedObject.id &&
                state.selectedObject.type === 'plane' &&
                state.selectedObject.id === action.payload.id
            ) {
                return {
                    ...state,
                    selectedObject: action.payload,
                    forceUpdatedSelectedObject: true,
                };
            }

            return {
                ...state,
            };
        case LOAD_PROJECT_TREE_SUCCESS:
            const stateData = state.data;
            const payloadData = action.payload;
            const undoState = state.undo;
            calculateFields(payloadData);
            let shouldUpdate, selectedElement, assemblySelection;

            if (!stateData.length && !undoState) {
                return {
                    ...state,
                    loading: false,
                    data: payloadData,
                };
            } else {
                shouldUpdate = projectUtils.shouldUpdateTreeWithPayloadData(stateData, payloadData);

                if (state.selectedObject && state.selectedObject.id && shouldUpdate) {
                    for (let i = 0; i < payloadData.length; i++) {
                        selectedElement = projectUtils.findNode(payloadData[i], state.selectedObject.id);

                        if (selectedElement) {
                            if (selectedElement.type === 'assembly') {
                                assemblySelection = getAssemblyElements({}, selectedElement);
                            }

                            break;
                        }
                    }
                }
            }

            return {
                ...state,
                loading: false,
                data: undoState || shouldUpdate ? payloadData : stateData,
                selectedObject: selectedElement || {},
                assemblySelection: selectedElement && selectedElement.type === 'assembly' ? assemblySelection : state.assemblySelection,
                forceUpdatedSelectedObject: shouldUpdate || undoState,
                undo: undoState ? false : undoState
            };
        case TRANSLATE_PROJECT_ASSEMBLY_ERROR:
            let prevSelectedElement, prevAssemblySelection;

            if (state.selectedObject && state.selectedObject.id) {
                for (let i = 0; i < state.data.length; i++) {
                    prevSelectedElement = projectUtils.findNode(state.data[i], state.selectedObject.id);


                    if (prevSelectedElement) {
                        if (prevSelectedElement.type === 'assembly') {
                            prevAssemblySelection = getAssemblyElements({}, prevSelectedElement);
                        }

                        break;
                    }
                }
            }
            return {
                ...state,
                selectedObject: prevSelectedElement ? prevSelectedElement : state.selectedObject,
                assemblySelection: prevAssemblySelection ? prevAssemblySelection : state.assemblySelection,
                forceUpdatedSelectedObject: !!prevSelectedElement,
            };
        case UNDO_PROJECT_SUCCESS:
            return {
                ...state,
                undo: true
            };
        // case CREATE_PROJECT_ASSEMBLY_SUCCESS:
        // case CREATE_PROJECT_BOX_SUCCESS:
        //     return {
        //         ...state,
        //         data: state.data.concat(action.payload),
        //     };
        case UPDATE_PROJECT_TREE:
            return {
                ...state,
                // loading: true,
                data: action.payload,
            };
        // case UPDATE_PROJECT_TREE_SUCCESS:
        //     return {
        //         ...state,
        //         // loading: false,
        //     };
        // case UPDATE_PROJECT_ASSEMBLY:
        //     return {
        //         ...state,
        //     };
        // case UPDATE_PROJECT_ASSEMBLY_SUCCESS:
        case UPDATE_PROJECT_ASSEMBLY_AND_TREE_SUCCESS:
        case TRANSLATE_PROJECT_ASSEMBLY_SUCCESS:
            return {
                ...state,
                data: updateTreeWithAssembly(state.data, action.payload),
                updateProjectTreeUI: 'update',
                forceUpdatedSelectedObject: true,
            };
        case TOGGLE_PROJECT_TABLE:
            return {
                ...state,
                data: action.payload,
            };
        case UPDATE_PROJECT_UI:
            return {
                ...state,
                updateProjectTreeUI: 'noUpdate',
            };
        //clear the tree state when load project    
        case LOAD_PROJECT:
            return {
                data: null,
                // loading: false,
            };
        case CHANGE_PROJECT_TREE:
            return {
                ...state,
                data: action.payload,
            };
        case SET_SELECTED_OBJ:
            const result = {
                ...state,
                selectedObject: action.payload,
                assemblySelection: {},
            };

            if (action.payload.type === 'assembly') {
                result.assemblySelection = getAssemblyElements({}, action.payload);
            }

            return result;
        case UPDATE_SELECTED_OBJECT:
            return {
                ...state,
                updateSelectedObject: action.payload,
            };
        case FORCE_UPDATE_SELECTED_OBJECT:
            return {
                ...state,
                forceUpdatedSelectedObject: action.payload,
            };
        case UPDATE_PROJECT_OBJECT_POSITION:
        case TRANSLATE_PROJECT_ASSEMBLY:
            return {
                ...state,
                data: update3DObjectPosition(state.data, action.payload.box),
            };
        default:
            return state;
    }
};

export const hasData = ({current_project}) => !!current_project.tree.data;

export const getData = ({current_project}) => current_project.tree.data;

export const getSelectedObject = ({current_project}) => current_project.tree.selectedObject;

export const getUpdateSelectedObject = ({current_project}) => current_project.tree.updateSelectedObject;

export const getForceUpdatedSelectedObject = ({current_project}) => current_project.tree.forceUpdatedSelectedObject;

export const isLoading = ({current_project}) => current_project.tree.loading;

export const updateProjectTreeUI = ({current_project}) => current_project.tree.updateProjectTreeUI;

export const getAssemblySelectionState = ({current_project}) => current_project.tree.assemblySelection;