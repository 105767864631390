/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

export const UPDATE_PROJECT_CYLINDER = 'UPDATE_PROJECT_CYLINDER';
export const UPDATE_PROJECT_CYLINDER_ERROR = 'UPDATE_PROJECT_CYLINDER_ERROR';
export const UPDATE_PROJECT_CYLINDER_SUCCESS = 'UPDATE_PROJECT_CYLINDER_SUCCESS';

export const updateProjectCylinder =  payload => ({
    type: UPDATE_PROJECT_CYLINDER,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/cylinder/${payload.box.id}/`,
            method: 'PUT',
            body: JSON.stringify(payload.box),
        },
    },
});

export const UPDATE_PROJECT_CYLINDER_AND_TREE = 'UPDATE_PROJECT_CYLINDER_AND_TREE';
export const UPDATE_PROJECT_CYLINDER_AND_TREE_SUCCESS = 'UPDATE_PROJECT_CYLINDER_AND_TREE_SUCCESS';
export const UPDATE_PROJECT_CYLINDER_AND_TREE_ERROR = 'UPDATE_PROJECT_CYLINDER_AND_TREE_ERROR';

export const updateProjectCylinderAndTree =  payload => ({
    type: UPDATE_PROJECT_CYLINDER_AND_TREE,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/cylinder/${payload.box.id}/`,
            method: 'PUT',
            body: JSON.stringify(payload.box),
        },
    },
});


export const CREATE_PROJECT_CYLINDER = 'CREATE_PROJECT_CYLINDER';
export const CREATE_PROJECT_CYLINDER_ERROR = 'CREATE_PROJECT_CYLINDER_ERROR';
export const CREATE_PROJECT_CYLINDER_SUCCESS = 'CREATE_PROJECT_CYLINDER_SUCCESS';

export const createProjectCylinder =  payload => ({
    type: CREATE_PROJECT_CYLINDER,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/cylinder/`,
            method: 'POST',
            body: JSON.stringify(payload.box),
        },
    },
});


export const CLONE_PROJECT_CYLINDER = 'CLONE_PROJECT_CYLINDER';
export const CLONE_PROJECT_CYLINDER_ERROR = 'CLONE_PROJECT_CYLINDER_ERROR';
export const CLONE_PROJECT_CYLINDER_SUCCESS = 'CLONE_PROJECT_CYLINDER_SUCCESS';

export const cloneProjectCylinder =  payload => ({
    type: CLONE_PROJECT_CYLINDER,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/cylinder/${payload.box.id}/clone/`,
            method: 'POST',
            body: JSON.stringify(payload.box),
        },
    },
});


export const DELETE_PROJECT_CYLINDER = 'DELETE_PROJECT_CYLINDER';
export const DELETE_PROJECT_CYLINDER_ERROR = 'DELETE_PROJECT_CYLINDER_ERROR';
export const DELETE_PROJECT_CYLINDER_SUCCESS = 'DELETE_PROJECT_CYLINDER_SUCCESS';

export const deleteProjectCylinder = payload => ({
    type: DELETE_PROJECT_CYLINDER,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/cylinder/${payload.box.id}/`,
            method: 'DELETE',
        },
    }
});