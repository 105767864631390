/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import {connect} from 'react-redux';
import withStyles from '@mui/styles/withStyles';

import {getTasks, isLoading} from '../reducer';

import {showModal} from "../../modals/actions";
import {compose} from "redux";

import {clearOldTasks, loadTasks} from '../actions';
import {getUserInfo} from "../../authentication/actions";
import {CircularProgress, TableBody, TableCell} from "@mui/material";
import Header from "../../components/Header";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Link from "@mui/material/Link";
import LinkIcon from '@mui/icons-material/Link';
import {stopTask} from "../../project/+store/actions/actions";
import {FONT_AWESOME_ICONS_TYPE_MAP} from '../../core/mappings';
import {IconsFontAwesome} from '../../components/IconsFontAwesome';


const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
    },
    card: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
    },
    image: {
        backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    title: {
        marginTop: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    main: {
        margin: 20,
        flex: 1,
    },
    button: {
        margin: 5,
    }
});


class Tasks extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.loadTasks();
    }

    stopTask = (task) => {
        this.props.stopTask({project_id: task.project, task_id: task.id});
        setTimeout(() => {
            this.props.loadTasks();
        }, 300);
    }

    render() {
        const {classes, tasks, loadTasks, clearOldTasks, isLoading} = this.props;

        return <div className={classes.root}>
            <Header/>
            <div className={classes.main}>
                <Button
                    className={classes.button}
                    variant={"contained"} color={"primary"}
                    onClick={() => loadTasks()}>
                    <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.refresh} hasHover={false}/>
                    Refresh
                </Button>
                <Button
                    className={classes.button}
                    variant={"contained"} color={"secondary"}
                    onClick={() => clearOldTasks()}>
                    <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.delete} hasHover={false}/>
                    Clear
                </Button>
                {
                    isLoading && <CircularProgress
                        size={20} style={{color: 'white'}}
                        thickness={5}/>
                }
                <Table>
                    <colgroup>
                        <col width={"25%"}/>
                        <col width={"10%"}/>
                        <col width={"25%"}/>
                        <col width={"20%"}/>
                        <col width={"20%"}/>
                        <col width={"0%"}/>
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell>User</TableCell>
                            <TableCell>License</TableCell>
                            <TableCell>Project</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Started on</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            tasks
                                .sort((a, b) => {
                                    if (a.timestamp > b.timestamp) return -1;
                                    else if (a.timestamp < b.timestamp) return 1;
                                    return 0;
                                })
                                .map(task => (
                                    <TableRow key={task.id}>
                                        <TableCell>{task.user_name}</TableCell>
                                        <TableCell>{task.license}</TableCell>
                                        <TableCell>
                                            {task.project_name}
                                            <Link href={`/#/project/${task.project}`}>
                                                <LinkIcon/>
                                            </Link>
                                        </TableCell>
                                        <TableCell>{task.status}</TableCell>
                                        <TableCell>{
                                            (new Date(task.timestamp)).toLocaleString()
                                        }</TableCell>
                                        <TableCell>
                                            {(task.status === 'RUNNING' || task.status === 'PENDING') &&
                                            <Button
                                                size={"small"} color={"secondary"} variant={"contained"}
                                                onClick={() => this.stopTask(task)}
                                            >
                                                <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.stop} hasHover={false}/>
                                            </Button>
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))
                        }
                    </TableBody>
                </Table>
            </div>
        </div>;
    }
}

Tasks.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    tasks: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = state => ({
    isLoading: isLoading(state),
    tasks: getTasks(state),
});

const mapDispatchToProps = {
    showModal,
    loadTasks,
    stopTask,
    clearOldTasks,
    getUserInfo
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(Tasks);
