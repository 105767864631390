/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import background from '../../background.png';

import {clearPasswordReset, forgotPassword, resetPassword} from "../actions";
import Container from "@mui/material/Container";
import {Paper} from "@mui/material";
import logo from "../../logo.svg";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import {compose} from "redux";
import withStyles from '@mui/styles/withStyles';

const styles = (theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});


class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.props.clearPasswordReset();
        this.state = {
            username: '',
            password: '',
            token: props.match.params.token
        }
    }

    handleSubmit = () => {
        this.props.resetPassword(this.state);
    }

    handleChange = (field, value) => {
        this.setState({[field]: value});
    }

    render() {
        const {error, classes, passwordRequest} = this.props;
        const {username, password} = this.state;

        return (
            <div style={{
                width: "calc(100hh - 40px)",
                height: "calc(100vh - 40px)",
                margin: 20,
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover',
            }}>
                <Container maxWidth="sm">
                    <Paper style={{padding: 30}}>
                        <div className={classes.paper}>
                            <img src={logo} className="App-logo" width={150}/>
                            <Typography component="h1" variant="h5">
                                Reset password
                            </Typography>
                            {
                                passwordRequest ?
                                    <div>
                                        <p>
                                            The request to update your password has been accepted. If the information
                                            you provided was valid then your password has been reset.
                                        </p>
                                        <Button
                                            color={"primary"}
                                            fullWidth={true}
                                            href={"/#/login"}
                                            variant={"contained"}
                                        >
                                            Login
                                        </Button>
                                    </div> :
                                    <div>
                                        <p>
                                            Please provide your email address and the desired new password.
                                        </p>
                                        <TextField
                                            variant="standard"
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="username"
                                            label="Email Address"
                                            name="username"
                                            autoComplete="username"
                                            autoFocus
                                            value={username}
                                            onChange={event => this.handleChange("username", event.target.value)}
                                            validators={['required']}
                                            errorMessages={['This field is required!']}
                                        />
                                        <TextField
                                            variant="standard"
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="password"
                                            label="New password"
                                            type="password"
                                            id="password"
                                            value={password}
                                            onChange={event => this.handleChange("password", event.target.value)}
                                            autoComplete="current-password"
                                            validators={['required']}
                                            errorMessages={['This field is required!']}
                                        />
                                        <Button
                                            id="signin"
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}
                                            onClick={this.handleSubmit}
                                        >
                                            Set new password
                                        </Button>
                                    </div>
                            }
                        </div>
                    </Paper>
                </Container>
            </div>
        );
    }
}

ResetPassword.propTypes = {
    error: PropTypes.string,
};

const mapStateToProps = (state) => ({
    error: state.authentication.error,
    passwordRequest: state.authentication.resetPassword
});

const mapDispatchToProps = {
    resetPassword,
    clearPasswordReset
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(ResetPassword);
