/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */


export const SET_SCENARIO_TABS = 'SET_SCENARIO_TABS';

export const setScenarioTabs = (payload) => ({
    type: SET_SCENARIO_TABS,
    payload,
});