/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React from 'react';

import { Box, Grid, Typography } from '@mui/material';

import Box3DIcon from '../../../../assets/icons/Box3DIcon';
import PCB3DIcon from '../../../../assets/icons/PCB3DIcon';
import Source3DIcon from '../../../../assets/icons/Source3DIcon';
import TransientSource3DIcon from '../../../../assets/icons/TransientSource3DIcon';
import Heatsink3DIcon from '../../../../assets/icons/Heatsink3DIcon';
import ViaArray3DIcon from '../../../../assets/icons/ViaArray3DIcon';
import Cylinder3DIcon from '../../../../assets/icons/Cylinder3DIcon';
// import Polygon3DIcon from '../../../../assets/icons/Polygon3DIcon';
import BallArray3DIcon from '../../../../assets/icons/BallArray3DIcon';
// import Plane3DIcon from '../../../../assets/icons/Plane3DIcon';


const Elements = ({}) => {
    return (
        <Box sx={{ height: '130px' }}>
            <Box sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                height: '28px',
                p: '2px 0 4px 8px',
                position: 'relative',
            }}>
                <Typography variant={'faded'} size={'infoSmall'}>ELEMENTS</Typography>
            </Box>

            <Grid container>
                <Grid item xs={3}>
                    <Box3DIcon/>
                </Grid>
                <Grid item xs={3}>
                    <Heatsink3DIcon/>
                </Grid>
                <Grid item xs={3}>
                    <ViaArray3DIcon/>
                </Grid>
                <Grid item xs={3}>
                    <Cylinder3DIcon/>
                </Grid>
                <Grid item xs={3}>
                    <PCB3DIcon/>
                </Grid>
                <Grid item xs={3}>
                    <BallArray3DIcon/>
                </Grid>
                <Grid item xs={3}>
                    <Source3DIcon/>
                </Grid>
                <Grid item xs={3}>
                    <TransientSource3DIcon/>
                </Grid>
                {/*<Grid item xs={3}>*/}
                {/*    <Polygon3DIcon/>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={3}>*/}
                {/*    <Plane3DIcon/>*/}
                {/*</Grid>*/}
            </Grid>
        </Box>
    );
};


export default Elements;