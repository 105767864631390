/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React from 'react';


export default function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div role={'tabpanel'}
             hidden={value !== index}
             id={`simple-tabpanel-${index}`}
             aria-labelledby={`simple-tab-${index}`}
             style={{flex: '1 1 100%'}}
             {...other}>
            {(value === index || index === 0) && children}
        </div>
    );
}