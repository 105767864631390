/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputAdornment from '@mui/material/InputAdornment';
import ColorPicker from '../../components/ColorPicker';
import {TextValidator} from 'react-material-ui-form-validator';
import {createLibraryMaterial, updateLibraryMaterial} from '../actions';
import {getValidationErrors} from '../reducer';
import Typography from '@mui/material/Typography';

import MyValidationForm from '../../components/MyValidationForm';
import {Checkbox, FormControlLabel, FormGroup} from '@mui/material';
import FormControl from '@mui/material/FormControl';


class MaterialSettings extends Component {

    constructor(props) {
        super(props);

        const material = props.material || {
            name: '',
            color: '#000',
            density: null,
            specific_heat: null,
            anisotropic: false,
            conductivity: null,
            conductivity_y: null,
            conductivity_z: null,
        };

        this.form = React.createRef();

        const material_fields = ['name', 'color', 'density', 'specific_heat', 'anisotropic', 'conductivity', 'conductivity_y', 'conductivity_z'];

        const state = {};
        material_fields.forEach(field_name => {
            state[field_name] = material[field_name];
        });

        this.state = state;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(field_name, value) {
        this.setState({
            [field_name]: value,
        });
    }

    materialHasChange() {
        const {material} = this.props;
        return Object.keys(this.state).some((key) => this.state[key] !== material[key]);
    }

    handleSubmit() {
        this.form.current.isFormValid(false)
            .then(is_valid => {
                if (is_valid) {
                    const {onClose, library, material, createLibraryMaterial, updateLibraryMaterial} = this.props;

                    if (material) {
                        if (!this.materialHasChange()) {
                            return onClose();
                        }
                        updateLibraryMaterial({
                            library, material: {
                                ...material,
                                ...this.state,
                            },
                        });
                    } else {
                        createLibraryMaterial({library, material: this.state});
                    }
                }
            });
    }

    render() {
        const {classes, material, validation_errors} = this.props;
        const {
            name,
            color,
            density,
            specific_heat,
            anisotropic,
            conductivity,
            conductivity_y,
            conductivity_z,
        } = this.state;

        return (
            <Fragment>
                <DialogContent dividers>
                    <MyValidationForm
                        autoComplete={'off'}
                        ref={this.form}
                        onSubmit={this.handleSubmit}
                    >
                        <TextValidator
                            label={'Name'}
                            id={'materialname'}
                            value={name}
                            onChange={event => this.handleChange('name', event.target.value)}
                            variant={'standard'}
                            error={!!validation_errors.name}
                            helperText={validation_errors.name}
                            validators={['required']}
                            errorMessages={['This field is required!', 'No']}
                        />

                        <ColorPicker
                            label={'Color'}
                            id={'color'}
                            variant={'standard'}
                            disablealpha={'true'}
                            value={color}
                            onChange={color => this.handleChange('color', color)}
                            TextFieldProps={{value: color || '#000'}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                sx: {width: '80%'},
                                startAdornment:
                                    <InputAdornment position={'start'}>
                                        <div style={{
                                            background: this.state['color'],
                                            border: '1px solid black',
                                            width: '24px',
                                            height: '24px',
                                            marginRight: '5px',
                                        }}/>
                                    </InputAdornment>,
                                endAdornment:
                                    <InputAdornment position={'end'}>
                                        HEX
                                    </InputAdornment>
                            }}
                        />

                        <Typography variant={'h6'}>
                            Steady state parameters (required)
                        </Typography>
                        <FormControl fullWidth={true}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value={anisotropic}
                                        checked={anisotropic}
                                        onChange={() => this.setState({anisotropic: !anisotropic})}
                                    />
                                }
                                label={'Anisotropic'}
                            />
                        </FormControl>
                        <TextValidator
                            type={'number'}
                            step={'any'}
                            label={anisotropic ? 'Conductivity (X)' : 'Conductivity'}
                            id={'thermalconductivity'}
                            value={conductivity || ''}
                            onChange={event => this.handleChange('conductivity', +event.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment
                                    position={'start'}>W/m&#8729;K</InputAdornment>,
                            }}
                            variant={'standard'}
                            error={!!validation_errors.conductivity}
                            helperText={validation_errors.conductivity}
                            validators={['required']}
                            errorMessages={['This field is required!']}
                        />
                        {
                            anisotropic &&
                            <TextValidator
                                type={'number'}
                                step={'any'}
                                label={'Conductivity (Y)'}
                                id={'thermalconductivity_y'}
                                value={conductivity_y}
                                onChange={event => this.handleChange('conductivity_y', +event.target.value)}
                                InputProps={{
                                    endAdornment: <InputAdornment position={'start'}>W/m&#8729;K</InputAdornment>,
                                }}
                                variant={'standard'}
                                error={!!validation_errors.conductivity_y}
                                helperText={validation_errors.conductivity_y}
                                validators={['required']}
                                errorMessages={['This field is required!']}
                            />
                        }
                        {
                            anisotropic &&
                            <TextValidator
                                type={'number'}
                                step={'any'}
                                label={'Conductivity (Z)'}
                                id={'thermalconductivity_z'}
                                value={conductivity_z}
                                onChange={event => this.handleChange('conductivity_z', +event.target.value)}
                                InputProps={{
                                    endAdornment: <InputAdornment position={'start'}>W/m&#8729;K</InputAdornment>,
                                }}
                                variant={'standard'}
                                error={!!validation_errors.conductivity_z}
                                helperText={validation_errors.conductivity_z}
                                validators={['required']}
                                errorMessages={['This field is required!']}
                            />
                        }
                        <Typography variant={'h6'}>
                            Transient Parameters (optional)
                        </Typography>
                        <TextValidator
                            type={'number'}
                            step={'any'}
                            label={'Density'}
                            id={'density'}
                            value={density}
                            onChange={event => this.handleChange('density', +event.target.value || undefined)}
                            InputProps={{
                                endAdornment: <InputAdornment position={'end'}>kg/m&sup3;</InputAdornment>,
                            }}
                            variant={'standard'}
                            error={!!validation_errors.density}
                            helperText={validation_errors.density}
                            validators={['isCustomPositive']}
                            errorMessages={['This field is required!', 'Enter a positive number!']}
                        />
                        <TextValidator
                            type={'number'}
                            step={'any'}
                            label={'Specific Heat'}
                            id={'specificheat'}
                            value={specific_heat}
                            onChange={event => this.handleChange('specific_heat', +event.target.value || undefined)}
                            InputProps={{
                                endAdornment: <InputAdornment position={'end'}>J/kg&#8729;K</InputAdornment>,
                            }}
                            variant={'standard'}
                            error={!!validation_errors.specific_heat}
                            helperText={validation_errors.specific_heat}
                            validators={['isCustomPositive']}
                            errorMessages={['This field is required!', 'Enter a positive number!']}
                        />
                    </MyValidationForm>
                </DialogContent>
                <DialogActions>
                    <Button id={'addmaterial'} type={'submit'} onClick={this.handleSubmit} variant={'contained'}
                            color={'primary'}>
                        {material ? 'Update' : 'Add'}
                    </Button>
                </DialogActions>
            </Fragment>
        );
    }
}


MaterialSettings.propTypes = {
    onClose: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    validation_errors: getValidationErrors(state),
});

const mapDispatchToProps = {
    createLibraryMaterial,
    updateLibraryMaterial,
};

export default connect(mapStateToProps, mapDispatchToProps)(MaterialSettings);
