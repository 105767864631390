/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {getLibrary, isLoading} from '../reducer';
import {showModal} from '../../modals/actions';
import {deleteLibrary, deleteLibraryMaterial, loadLibrary} from '../actions';
import MaterialSettings from './MaterialSettings';
import withStyles from '@mui/styles/withStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import {confirmAlert} from 'react-confirm-alert';
import {Badge, Input} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import LibrarySettings from './LibrarySettings';
import TabPanel from '../../components/TabPanel';
import Permissions from './Permissions';
import Header from '../../components/Header';
import Grid from '@mui/material/Grid';
import {FONT_AWESOME_ICONS_TYPE_MAP} from '../../core/mappings';
import {IconsFontAwesome} from '../../components/IconsFontAwesome';


const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
    },
    container: {
        margin: theme.spacing(4),
    },
    tableContainer: {
        marginTop: theme.spacing(2),
        overflow: 'auto',
        maxHeight: 'calc(100vh - 365px)'
    },
    table: {
        minWidth: 650,
    },
    icon: {
        marginRight: theme.spacing(2),
        cursor: 'pointer'
    },
    search: {
        margin: theme.spacing(2),
    },
    badge: {
        padding: theme.spacing(1),
    }
});


class Materials extends Component {

    constructor(props) {
        super(props);

        this.props.loadLibrary();

        this.state = {
            filter: '',
            tab: 0,
        };
    }

    showMaterialModal = (library, material) => {
        this.props.showModal({
            type: 'custom',
            title: material ? 'Edit Material' : 'New Material',
            content: (props) => {
                return <MaterialSettings {...props} library={library} material={material}/>;
            },
        });
    };

    showLibraryModal = (library) => {
        this.props.showModal({
            type: 'custom',
            title: library ? 'Edit Library' : 'Create Library',
            content: (props) => {
                return <LibrarySettings {...props} library={library}/>;
            }
        });
    };

    showShareLibraryModal = (event, library) => {
        this.props.showModal({
            type: 'custom',
            title: 'Edit permissions',
            content: (props) => {
                return <Permissions {...props} library={library}/>;
            }
        });
    };

    handleTabChange = (event, value) => {
        this.setState({tab: value});
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.library.length !== prevProps.library.length)
            this.setState({
                tab: Math.max(0, Math.min(this.state.tab, this.props.library.length - 1))
            });
    }

    render() {
        const {classes, library, deleteLibraryMaterial, deleteLibrary} = this.props;
        const {filter, tab} = this.state;

        return (
            <div className={classes.root}>
                <Header/>
                <Grid className={classes.container}>
                    <Button id={'createlibrary'} variant={'contained'} color={'primary'} onClick={() => this.showLibraryModal()}>
                        <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.plus}
                                          secondClass={'smaller'}/>
                        Library
                    </Button>
                    <Input className={classes.search}
                           fullWidth={true}
                           onChange={(event) => this.setState({filter: event.target.value})}
                           startAdornment={
                               <InputAdornment position={'start'}>
                                   <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.search}
                                                     secondClass={'smaller'}/>
                               </InputAdornment>
                           }
                           placeholder={'Find material in libraries'}
                    />
                    <Tabs variant={'scrollable'}
                          scrollButtons={'auto'}
                          value={tab}
                          onChange={this.handleTabChange}
                    >
                        {
                            library && library
                                .sort((a, b) => {
                                    if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
                                    if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
                                    return 0;
                                })
                                .map(lib => (
                                    <Tab key={lib.id}
                                         wrapped={true}
                                         label={
                                             <Badge className={classes.badge}
                                                    badgeContent={lib.materials.filter(material => (!filter || material.name.toUpperCase().includes(filter.toUpperCase()))).length}
                                                    color={'primary'}
                                                    showZero>
                                                 {lib.name}
                                             </Badge>
                                         }/>
                                ))
                        }
                    </Tabs>
                    {
                        library && library
                            .sort((a, b) => {
                                if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
                                if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
                                return 0;
                            })
                            .map((lib, index) => (
                                <TabPanel key={lib.id} value={tab} index={index}>
                                    <h2>{lib.name}</h2>
                                    {
                                        (lib.owner || lib.edit) &&
                                        <Button id={'addmaterialbtn'} variant={'contained'} color={'primary'}
                                                onClick={() => this.showMaterialModal(lib)}>
                                            <AddIcon/>
                                            Material
                                        </Button>
                                    }
                                    {
                                        (lib.owner || lib.edit) &&
                                        <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.properties}
                                                          titleAccess={`Show ${lib.name} Properties`}
                                                          secondClass={'smaller'}
                                                          onClickHandler={() => this.showLibraryModal(lib)}/>
                                    }

                                    {
                                        lib.owner &&
                                        <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.share}
                                                          titleAccess={`Share ${lib.name}`}
                                                          secondClass={'smaller'}
                                                          onClickHandler={(event) => this.showShareLibraryModal(event, lib)}/>
                                    }
                                    {
                                        (lib.owner || lib.edit) &&
                                        <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.delete}
                                                          titleAccess={`Delete library ${lib.name}`}
                                                          secondClass={'smaller'}
                                                          id={'deletelibrary'}
                                                          onClickHandler={() => {
                                                              confirmAlert({
                                                                  title: 'Delete library?',
                                                                  message: 'Are you sure?',
                                                                  buttons: [
                                                                      {
                                                                          label: 'Delete',
                                                                          onClick: () => {
                                                                              deleteLibrary(lib);
                                                                          }
                                                                      },
                                                                      {
                                                                          label: 'Cancel',
                                                                          onClick: () => {
                                                                          }
                                                                      },
                                                                  ]
                                                              });
                                                          }}/>
                                    }
                                    <TableContainer component={Paper} className={classes.tableContainer}>
                                        <Table className={classes.table} aria-label={'simple table'} size={'small'}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell align={'right'}>Thermal Conductivity</TableCell>
                                                    <TableCell align={'right'}>Color</TableCell>
                                                    <TableCell align={'right'}>Density</TableCell>
                                                    <TableCell align={'right'}>Specific Heat</TableCell>
                                                    <TableCell align={'right'}></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {lib.materials
                                                    .filter(material => (!filter || material.name.toUpperCase().includes(filter.toUpperCase())))
                                                    .sort((a, b) => {
                                                        if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
                                                        if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
                                                        return 0;
                                                    })
                                                    .map((material) => (
                                                        <TableRow hover key={material.id}>
                                                            <TableCell component="th" scope="row">
                                                                {material.name}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {material.anisotropic ?
                                                                    [material.conductivity, material.conductivity_y, material.conductivity_z].join(' / ') :
                                                                    material.conductivity} W/m&#8729;K
                                                            </TableCell>
                                                            <TableCell align="right" style={{
                                                                background: material.color,
                                                                float: 'right',
                                                                border: '1px solid black',
                                                                width: '32px',
                                                                height: '16px'
                                                            }}>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {
                                                                    material.density &&
                                                                    <span>{material.density} kg/m&sup3;</span>
                                                                }
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {
                                                                    material.specific_heat &&
                                                                    <span>{material.specific_heat} J/kg&#8729;K</span>
                                                                }
                                                            </TableCell>
                                                            <TableCell align={'right'}>
                                                                {
                                                                    (lib.owner || lib.edit) &&
                                                                    <IconsFontAwesome
                                                                        iconType={FONT_AWESOME_ICONS_TYPE_MAP.properties}
                                                                        titleAccess={`Show ${material.name} Properties`}
                                                                        id={'updatematerial'}
                                                                        secondClass={'smaller'}
                                                                        onClickHandler={() => this.showMaterialModal(lib, material)}/>
                                                                }
                                                                {
                                                                    (lib.owner || lib.edit) &&
                                                                    <IconsFontAwesome
                                                                        iconType={FONT_AWESOME_ICONS_TYPE_MAP.delete}
                                                                        titleAccess={`Delete ${material.name}`}
                                                                        id={'deletematerial'}
                                                                        variantType={'secondary'}
                                                                        onClickHandler={() => {
                                                                            confirmAlert({
                                                                                title: `Delete ${material.name}?`,
                                                                                message: 'Are you sure?',
                                                                                buttons: [
                                                                                    {
                                                                                        label: 'Delete',
                                                                                        onClick: () => {
                                                                                            deleteLibraryMaterial({
                                                                                                library: lib,
                                                                                                material
                                                                                            });
                                                                                        }
                                                                                    },
                                                                                    {
                                                                                        label: 'Cancel',
                                                                                        onClick: () => {
                                                                                        }
                                                                                    },
                                                                                ]
                                                                            });
                                                                        }}/>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </TabPanel>
                            ))
                    }

                </Grid>
            </div>
        );
    }
}

Materials.propTypes = {
    loading: PropTypes.bool.isRequired,
    library: PropTypes.array.isRequired,
    showModal: PropTypes.func.isRequired,
    deleteLibraryMaterial: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    loading: isLoading(state),
    library: getLibrary(state),
});

const mapDispatchToProps = {
    showModal,
    deleteLibraryMaterial,
    deleteLibrary,
    loadLibrary
};


export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(Materials);
