/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {useState, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import './SceneViewAndControls.scss';

import {Icon, Typography, Box, Divider, Stack, Button, Menu, MenuItem, CircularProgress} from '@mui/material';

import {
    fitProjectObject, fitAll,
    setProjectMode, setGridSnap, setAxesLock,
    toggleObjectClipping,
} from '../../../../../+store/actions/project';
import {loadPlaneSolutions, loadProjectSolution, clearClipFaceSolutions} from '../../../../../+store/actions/actions';

import {getClipping, getGridSnap, getAxesLock, getProjectMode} from '../../../../../+store/reducer/project';

import SolveButton from '../../../../../../components/SolveButton';
import {SwitchToggle} from '../../../../../../components/SwitchToggle';

import {projectUtils} from '../../../../../utils';
import {FONT_AWESOME_ICONS, FONT_AWESOME_ICONS_TYPE_MAP} from '../../../../../../core/mappings';
import {IconsFontAwesome} from '../../../../../../components/IconsFontAwesome';
import {getSelectedObject} from '../../../../../+store/reducer/tree';

const GRID_SNAP_MAP = [
    {key: '1 mm', value: 1},
    {key: '0.1 mm', value: 0.1},
    {key: '0.01 mm', value: 0.01},
    {key: '0.001 mm', value: 0.001},
    {key: 'Free Mode', value: 0},
];
// const lockAxes = ['xy', 'xz', 'yz'];


const SceneViewAndControls = ({
                                  setProjectMode,
                                  current_project, tree, solution,
                                  isLoading,
                                  loadPlaneSolutions,
                                  plane_solutions,
                                  mode,
                                  fitAll, fitProjectObject, selectedObject,
                                  clipping, toggleObjectClipping,
                                  clearClipFaceSolutions,
                                  gridSnap, setGridSnap,
                                  axesLocks, setAxesLock,
                              }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const activeGridSnap = useMemo(() => {
        return GRID_SNAP_MAP.find(gridOption => gridOption.value === gridSnap);
    }, [gridSnap]);

    const openGridMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const closeGridMenu = () => {
        setAnchorEl(false);
    };

    useEffect(() => {
        if (mode === 'solution' && !solution) {
            loadProjectSolution({
                project_id: current_project.id,
            });
        }

        if (mode === 'plane' && !plane_solutions) {
            loadPlaneSolutions({
                project_id: current_project.id,
            });
        }
    }, [mode]);


    return (
        <Box className={'scene-view-wrapper'} sx={{height: '45px'}}>
            <Stack
                direction={'row'}
                divider={
                    <Divider orientation={'vertical'} flexItem
                             variant={'middle'}
                             sx={{borderColor: 'background.input'}}/>
                }
                spacing={2}>
                <SwitchToggle title={'Mode'}
                              titleClassName={'responsive-hidden'}
                              titleSX={{fontSize: (theme) => theme.fontSize.infoSmall}}
                              checked={mode}
                              options={
                                  [
                                      {key: 'Model', value: 'box'},
                                      {key: 'Solution', value: 'solution'},
                                      {key: 'Planes', value: 'plane'},
                                      // {key: 'Tree', value: 'adjacency'},
                                  ]
                              }
                              onChange={(mode) => setProjectMode({mode})}
                              boxSX={{width: 'auto'}}
                              groupSettings={{variant: 'mode'}}
                              groupSettingsSX={{height: '24px', marginLeft: '8px'}}
                              buttonSettingsSX={{
                                  lineHeight: '1',
                                  '&.Mui-selected:hover': {
                                      backgroundColor: (theme) => theme.palette.primary.main,
                                      border: `1px solid rgba(0,0,0,0.33)`,
                                  }
                              }}
                              selectedColor={'primary'}
                />
                <Box className={'view-controls grid-snap'}>
                    <Typography variant={'faded'} size={'infoSmall'} className={'responsive-hidden'}>GRID
                        SNAP</Typography>
                    <Box>
                        <Button id={'grid-snap-button'}
                                className={'selected-grid-snap'}
                                aria-controls={anchorEl ? 'grid-snap-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={anchorEl ? 'true' : undefined}
                                variant={'contained'}
                                disableElevation
                                onClick={openGridMenu}>
                            {
                                <Icon sx={{fontSize: '1.25rem', mr: '4px', width: '1.5rem'}}
                                      baseClassName={FONT_AWESOME_ICONS[FONT_AWESOME_ICONS_TYPE_MAP[activeGridSnap.key]].active.baseClassName} // main icon
                                      className={FONT_AWESOME_ICONS[FONT_AWESOME_ICONS_TYPE_MAP[activeGridSnap.key]].className}
                                      title={`${activeGridSnap.value ? `Snap by ${activeGridSnap.key}` : activeGridSnap.key}`}
                                      aria-label={`${activeGridSnap.value ? `Snap by ${activeGridSnap.key}` : activeGridSnap.key}`}
                                      onClick={(event) => setAnchorEl(event.currentTarget)}/>
                            }
                            {
                                anchorEl
                                    ? <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.caretArrowUp}
                                                        secondClass={'model-arrow-icon smaller1 noMR'}
                                                        titleAccess={'Hide Grid Snap Options'}
                                                        iconsSX={{mr: 0}}
                                                        onClickHandler={openGridMenu}/>
                                    : <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.caretArrowDown}
                                                        secondClass={'model-arrow-icon smaller1 noMR'}
                                                        titleAccess={'Show Grid Snap Options'}
                                                        iconsSX={{mr: '0'}}
                                                        onClickHandler={openGridMenu}/>
                            }
                        </Button>
                        <Menu id={'grid-snap-menu'}
                              MenuListProps={{
                                  'aria-labelledby': 'grid-snap-button',
                              }}
                              disableScrollLock={true}
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={closeGridMenu}
                        >
                            {GRID_SNAP_MAP.map(({key, value}, idx) => (
                                value !== activeGridSnap.value
                                    ? (
                                        <MenuItem key={key} value={idx} disableRipple
                                                  onClick={() => {
                                                      closeGridMenu();
                                                      setGridSnap(value);
                                                  }}>
                                            <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP[GRID_SNAP_MAP[idx].key]}
                                                              titleAccess={value ? `Snap by ${key}` : key}/>
                                        </MenuItem>
                                    )
                                    : null
                            ))}
                        </Menu>
                    </Box>
                </Box>

                <Box className={'view-controls view'}>
                    <Typography variant={'faded'} size={'infoSmall'} className={'responsive-hidden'}>VIEW</Typography>
                    <Box className={'actions'}
                         sx={{display: 'flex', alignSelf: 'center'}}>
                        {selectedObject?.id
                            ? <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.zoomToElement}
                                                secondClass={'smaller'}
                                                iconsSX={{width: 'auto', ml: '8px', mr: '8px'}}
                                                titleAccess={`Zoom to ${selectedObject.name}`}
                                                onClickHandler={(event) => {
                                                    projectUtils.stopPreventDefault(event);
                                                    fitProjectObject(selectedObject);
                                                }}/>
                            : <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.zoomToElement}
                                                secondClass={'smaller'}
                                                iconsSX={{
                                                    ml: '8px',
                                                    mr: '8px',
                                                    cursor: 'not-allowed',
                                                    opacity: '.45',
                                                    width: 'auto'
                                                }}
                                                titleAccess={'Please Select Object First'}
                                                hasHover={false}/>
                        }

                        <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.zoomAll}
                                          iconsSX={{fontSize: '0.9rem', width: 'auto', ml: '8px'}}
                                          titleAccess={'Zoom All'}
                                          onClickHandler={(event) => {
                                              projectUtils.stopPreventDefault(event);
                                              fitAll(true);
                                          }}/>
                    </Box>
                </Box>

                {/*<Box className={'view-controls lock'}>*/}
                {/*    <Typography variant={'faded'} size={'infoSmall'}>LOCK:</Typography>*/}
                {/*    <Box sx={{display: 'flex', alignSelf: 'center', ml: '5px'}}>*/}
                {/*        {lockAxes.map(axes => {*/}
                {/*                return (*/}
                {/*                    <CustomIconsSVG key={axes}*/}
                {/*                                    iconType={axes}*/}
                {/*                                    isActive={axesLocks[axes]}*/}
                {/*                                    onClickHandler={() => setAxesLock(axes)}/>*/}
                {/*                );*/}
                {/*            },*/}
                {/*        )}*/}
                {/*    </Box>*/}

                {/*</Box>*/}

                <Box className={'view-controls clip'}>
                    <Typography variant={'faded'} size={'infoSmall'} className={'responsive-hidden'}>CLIP</Typography>
                    {
                        !!tree.length
                            ? <SwitchToggle booleans={true}
                                            onChange={() => {
                                                toggleObjectClipping();
                                                clipping && clearClipFaceSolutions();
                                            }}
                                            checked={clipping}
                                            orientation={'vertical'}
                                            boxSX={{width: 'auto', alignSelf: 'center'}}
                                            groupSettings={{variant: 'mode'}}
                                            groupSettingsSX={{height: '24px', marginLeft: '8px'}}
                                            buttonSettingsSX={{lineHeight: '1'}}
                            />
                            : <Box sx={{color: (theme) => theme.palette.warning.main, letterSpacing: '0', ml: '10px'}}>
                                <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.warning}
                                                  hasHover={false}
                                                  secondClass={'smaller'}
                                                  iconsSX={{width: 'auto', mr: '10px'}}
                                                  titleAccess={'Zoom All'}/>
                                <Typography variant={'faded'} size={'info'}>No Elements</Typography>
                            </Box>
                    }
                </Box>
                <Box>
                    {isLoading && <CircularProgress color={'secondary'} size={24}/>}
                </Box>

            </Stack>
        </Box>
    );
};


SceneViewAndControls.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    clipping: PropTypes.bool.isRequired,
    current_project: PropTypes.object,
    fitAll: PropTypes.func.isRequired,
    fitProjectObject: PropTypes.func.isRequired,
    loadPlaneSolutions: PropTypes.any,
    mode: PropTypes.string,
    plane_solutions: PropTypes.arrayOf(PropTypes.object),
    selectedObject: PropTypes.any,
    setProjectMode: PropTypes.func.isRequired,
    solution: PropTypes.object,
    toggleObjectClipping: PropTypes.func.isRequired,
    clearClipFaceSolutions: PropTypes.func.isRequired,
    tree: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
    mode: getProjectMode(state),
    selectedObject: getSelectedObject(state),
    clipping: getClipping(state),
    gridSnap: getGridSnap(state),
    axesLocks: getAxesLock(state),
});

const mapDispatchToProps = {
    setProjectMode,
    loadProjectSolution,
    loadPlaneSolutions,
    toggleObjectClipping,
    clearClipFaceSolutions,
    fitProjectObject,
    fitAll,
    setGridSnap,
    setAxesLock
};

export default connect(mapStateToProps, mapDispatchToProps)(SceneViewAndControls);
