/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {combineReducers} from 'redux';
import {reducer as scenarios} from './scenarios';

export const reducer = combineReducers({
    scenarios,
});