/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {
    CREATE_PARAMETER_SUCCESS, UPDATE_PARAMETER_SUCCESS, DELETE_PARAMETER_SUCCESS,
    GET_PARAMETERS, GET_PARAMETERS_ERROR, GET_PARAMETERS_SUCCESS
} from '../actions/parameters';

export const defaultState = {
    loading: false,
    data: [],
    validation_errors: [],
};


export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case GET_PARAMETERS:
            return {
                ...state,
                loading: true,
            };
        case GET_PARAMETERS_ERROR:
            return {
                ...state,
                loading: false,
                data: [],
            };
        case GET_PARAMETERS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case CREATE_PARAMETER_SUCCESS:
            return {
                ...state,
                data: [...state.data, action.payload],
            };
        case UPDATE_PARAMETER_SUCCESS:
            return {
                ...state,
                data: state.data.map(parameter => parameter.id === action.payload.id ? action.payload : parameter),
            };
        case DELETE_PARAMETER_SUCCESS:
            return {
                ...state,
                data: state.data.filter(parameter => parameter.id !== action.payload.parameterid),
            };
        default:
            return state;
    }
};
