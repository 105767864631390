/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import {Link} from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import {logout} from '../authentication/actions';
import {isLogged} from '../authentication/reducer';
import {resetState} from '../dashboard/actions';

import AccountMenu from './AccountMenu';
import logo from '../logo.svg';
import {Box} from "@mui/material";

const styles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
    title: {
        flexGrow: 1,
    },
});


const Header = ({classes, isLogged, logout, resetState}) => {

    const handleLogout = () => {
        window.localStorage.removeItem('token');
        logout();
        resetState();
    };


    return (
        isLogged &&
        <AppBar position={'sticky'} enableColorOnDark>
            <Toolbar variant={'dense'}>
                    <img src={logo} className={'App-logo'} alt={'Anemoi Logo'} width={48} style={{marginRight: 20}}/>
                <Typography variant="h5"
                            style={{marginLeft: -24, padding: 4, marginRight: 10}}>
                    <Link id={'homebtn'} className={'navigation-dashboard'} to={'/dashboard'}
                          style={{paddingBottom: 10}}>DankaThermal<sup style={{fontSize: "56%"}}>™</sup></Link>
                    <div
                        style={{position: "absolute", color: "#fff", bottom: 0, left: 170, fontSize: "35%"}}>{process.env.REACT_APP_VERSION}</div>
                </Typography>
                <Typography variant="{h6}" className={classes.title}>
                    <Link id={'homebtn'} className={'navigation-link'} to={'/dashboard'}>Projects</Link>
                    <Link className={'navigation-link'} to={'/materials'}>Material libraries</Link>
                    <Link className={'navigation-link'} to={'/tasks'}>Task queue</Link>
                </Typography>
                <AccountMenu handleLogout={handleLogout}/>
            </Toolbar>
        </AppBar>
    );
};

Header.propTypes = {
    isLogged: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
    resetState: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    isLogged: isLogged(state),
});

const mapDispatchToProps = {
    logout,
    resetState,
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(Header);
