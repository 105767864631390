/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {TextValidator} from 'react-material-ui-form-validator';
import {TableBody, TableCell, Tooltip} from '@mui/material';
import React from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import withStyles from '@mui/styles/withStyles';

const HcTableCell = withStyles((theme) => ({
    head: {
        color: theme.palette.common.white,
        fontWeight: 'bold',
    },
    body: {
        color: theme.palette.common.white,
    },
}))(TableCell);


function HeatTransferCoeff(props) {

    const {onChange, value, validators, errorMessages, ...other} = props;

    function forcedAir(event) {
        let hc = '';
        if (+event.target.value >= 0) {
            hc = (12.12 - 1.16 * +event.target.value + 11.6 * Math.sqrt(+event.target.value)).toFixed(3);
        }
        onChange({
            target: {
                value: hc,
            }
        });
    }

    return (
        <>
            <TextValidator
                onChange={onChange}
                value={value}
                validators={validators}
                errorMessages={errorMessages}
                {...other}
                id={'coeffHT'}
                label={
                    <React.Fragment>
                        {'Heat transfer coeff'.toUpperCase()}
                        <Tooltip title={
                            <React.Fragment>
                                <Table size={'small'}>
                                    <TableHead>
                                        <TableRow>
                                            <HcTableCell>Medium</HcTableCell>
                                            <HcTableCell>Coefficient</HcTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <HcTableCell>Air free convection</HcTableCell>
                                            <HcTableCell>16.1 W/m&sup2;&#8729;&deg;C</HcTableCell>
                                        </TableRow>
                                        <TableRow style={{color: 'white'}}>
                                            <HcTableCell>Air forced @ 1 m/s</HcTableCell>
                                            <HcTableCell>22.5 W/m&sup2;&#8729;&deg;C</HcTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <HcTableCell>Air forced @ 2 m/s</HcTableCell>
                                            <HcTableCell>26.2 W/m&sup2;&#8729;&deg;C</HcTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <HcTableCell>Air forced @ 3 m/s</HcTableCell>
                                            <HcTableCell>28.7 W/m&sup2;&#8729;&deg;C</HcTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <HcTableCell>Air forced @ 4 m/s</HcTableCell>
                                            <HcTableCell>30.7 W/m&sup2;&#8729;&deg;C</HcTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <HcTableCell>Water free convection</HcTableCell>
                                            <HcTableCell>100+ W/m&sup2;&#8729;&deg;C</HcTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <HcTableCell>Water forced flow</HcTableCell>
                                            <HcTableCell>500+ W/m&sup2;&#8729;&deg;C</HcTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <HcTableCell>Oil free convection</HcTableCell>
                                            <HcTableCell>50+ W/m&sup2;&#8729;&deg;C</HcTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <HcTableCell>Oil forced flow</HcTableCell>
                                            <HcTableCell>300+ W/m&sup2;&#8729;&deg;C</HcTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </React.Fragment>
                        }>
                            <InfoOutlinedIcon fontSize={'inherit'}/>
                        </Tooltip>
                    </React.Fragment>
                }
                InputProps={{
                    endAdornment: <InputAdornment position={'end'}>W/m&sup2;&#8729;&deg;C</InputAdornment>,
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                placeholder={'-'}
            />
            <TextValidator
                label={"Forced air speed"}
                onChange={forcedAir}
                validators={validators && validators.filter(item => item !== "required")}
                errorMessages={errorMessages && errorMessages.filter((item, i) => validators[i] !== "required")}
                {...other}
                id={'coeffHTairspeed'}
                InputProps={{
                    endAdornment: <InputAdornment position={'end'}>m/s</InputAdornment>,
                }}
            >

            </TextValidator>
        </>
    );
}


export default HeatTransferCoeff;
