/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {combineReducers} from 'redux';
import {reducer as project} from './project';
import {reducer as solution} from './solution';
import {reducer as tree} from './tree';
import {reducer as planes} from './planes';
import {reducer as plane_solutions} from './plane_solutions';
import {reducer as parameters} from './parameters';
import {reducer as scenarios} from './scenarios';
import {reducer as validation_errors} from './validation_errors';


export const reducer = combineReducers({
    properties: project,
    solution,
    tree,
    planes,
    plane_solutions,
    parameters,
    scenarios,
    validation_errors,
});
