/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';
import withStyles from '@mui/styles/withStyles';

import {TextValidator} from 'react-material-ui-form-validator';

import {showModal} from "../../modals/actions";
import {createProject, setValidationErrors} from '../actions';

import {getData as getMaterials} from '../../materials/reducer';
import {getData as getProjects, getValidationErrors} from '../reducer';
import {getData as getCurrentProject} from '../../project/+store/reducer/project';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    Typography,
    InputAdornment,
    FormControlLabel,
    Checkbox,
    Tooltip,
    Box
} from '@mui/material';

import CreateMaterial from '../../materials/components/MaterialSettings';

import MyValidationForm from '../../components/MyValidationForm';
import HeatTransferCoeff from "../../components/HeatTranserCoeff";

const styles = (theme) => ({
    root: {
        '& > *': {
            marginBottom: theme.spacing(1),
            marginRight: theme.spacing(2),
        }
    },
    field: {
        margin: theme.spacing(1),
        width: '25ch',
    },
    selectEmpty: {
        minWidth: 180,
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    hc: {
        color: "white",
    },
    name: {
        width: '50ch'
    }
});

const message_required_field = 'This field is required!';
const message_floating_point = 'Enter a valid floating point number!';


class ProjectSettings extends Component {

    constructor(props) {
        super(props);

        if (props.project) {
            this.state = {
                name: props.project.name,
                ...props.project.world,
            }
        } else {
            this.state = {
                name: '',
                material: '',
                ambient: 25,
                hc: 16.1,
                resolution: 1,
                precision: 0.000001,
                iterations: 10000,
                absolute: false,
                abs_tol: 0.1,
                duration: 1,
                steps: 10,
                dxmin: "100",
                dxmax: "100",
                dymax: "100",
                dymin: "100",
                dzmax: "100",
                dzmin: "100",
                show_grid: true,
                show_outline: true,
                lump_limit: 10000,
                lumped: false,
                save_internal_temp: false,
                precondition: true,
            };
        }

        this.form = React.createRef();

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.showAddMaterialModal = this.showAddMaterialModal.bind(this);


        // clear validation error when open modal
        if (Object.keys(this.props.validation_errors).length) {
            this.props.setValidationErrors({});
        }
    }

    static getDerivedStateFromProps(props, current_state) {
        if (!current_state.material && props.materials.length > 0) {
            return {
                material: props.materials[0].id,
            }
        }
        return null;
    }


    componentDidUpdate(prev_props, prev_state) {
        if (this.props.projects !== prev_props.projects || this.props.current_project !== prev_props.current_project) {
            // close the modal
            this.props.onClose();
        }
    }

    showAddMaterialModal() {
        this.props.showModal({
            type: 'custom',
            title: 'Add Material',
            content: (props) => {
                return <CreateMaterial {...props} />
            },
        });
    }

    handleChange(field_name, value) {
        this.setState({
            [field_name]: value,
        })
    }

    projectHasChange() {
        const {project} = this.props;
        const {name, ...world} = this.state;

        if (project.name !== name) {
            return true;
        }
        return Object.keys(world).some((key) => this.state[key] !== project.world[key])
    }

    handleSubmit() {
        this.form.current.isFormValid(false)
            .then(is_valid => {
                if (is_valid) {
                    const {onClose, project, createProject, updateProject} = this.props;
                    const {name, ...rest} = this.state;
                    if (project) {
                        if (!this.projectHasChange()) {
                            return onClose();
                        }
                        updateProject({
                            ...project,
                            name,
                            world: {
                                ...rest
                            }
                        });
                    } else {
                        createProject({
                            name,
                            world: {
                                ...rest
                            }
                        });
                    }
                }
            });
    }

    render() {
        const {classes, materials, validation_errors} = this.props;
        const {name, material} = this.state;

        const world_validation_errors = validation_errors.world || {};

        return (
            <Fragment>
                <DialogContent dividers>
                    <MyValidationForm
                        ref={this.form}
                        onSubmit={this.handleSubmit}
                        className="validation-form-sm"
                    >
                        <TextValidator
                            className={classes.name}
                            id="projectname"
                            label="Name"
                            value={name}
                            onChange={event => this.handleChange('name', event.target.value)}
                            variant="standard"
                            error={!!this.props.validation_errors['name']}
                            helperText={this.props.validation_errors['name']}
                            validators={['required']}
                            errorMessages={[message_required_field]}
                        />
                        <FormControl variant={"standard"}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={"showgrid"}
                                        checked={this.state.show_grid}
                                        onChange={(event) => this.handleChange('show_grid', !this.state.show_grid)}
                                    />}
                                label={"Show world grid"}
                            />
                        </FormControl>
                        <FormControl variant={"standard"}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={"showoutline"}
                                        checked={this.state.show_outline}
                                        onChange={(event) => this.handleChange('show_outline', !this.state.show_outline)}
                                    />}
                                label={"Show object outlines"}
                            />
                        </FormControl>
                        <Typography variant="h6" className={classes.title}>Boundary conditions</Typography>
                        <TextValidator
                            className={classes.field}
                            id="ambient"
                            label={'ambient'.toUpperCase()}
                            value={this.state['ambient']}
                            onChange={(event) => this.handleChange('ambient', event.target.value)}
                            variant="standard"
                            error={!!world_validation_errors['ambient']}
                            helperText={world_validation_errors['ambient']}
                            validators={['required', 'isCustomFloat']}
                            errorMessages={[message_required_field, message_floating_point]}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">&deg;C</InputAdornment>,
                            }}
                        />
                        <HeatTransferCoeff
                            className={classes.field}
                            variant="standard"
                            validators={['required', 'isCustomFloat', 'minNumber:0']}
                            value={this.state.hc || ''}
                            onChange={(event) => this.handleChange('hc', event.target.value)}
                            error={!!world_validation_errors['hc']}
                            helperText={world_validation_errors['hc']}
                            errorMessages={[message_required_field, message_floating_point]}
                        />
                        <Typography variant="h6" className={classes.title}>Solver options</Typography>
                        <TextValidator
                            className={classes.field}
                            id="resolution"
                            label={'resolution'.toUpperCase()}
                            value={this.state['resolution']}
                            onChange={(event) => this.handleChange('resolution', event.target.value)}
                            variant="standard"
                            error={!!world_validation_errors['resolution']}
                            helperText={world_validation_errors['resolution']}
                            validators={['required', 'isCustomFloat']}
                            errorMessages={[message_required_field, message_floating_point]}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                            }}
                        />
                        <TextValidator
                            className={classes.field}
                            id="precision"
                            label={'precision'.toUpperCase()}
                            value={this.state['precision']}
                            onChange={(event) => this.handleChange('precision', event.target.value)}
                            variant="standard"
                            error={!!world_validation_errors['precision']}
                            helperText={world_validation_errors['precision']}
                            validators={['required', 'isCustomFloat']}
                            errorMessages={[message_required_field, message_floating_point]}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">&deg;C</InputAdornment>,
                            }}
                        />
                        <TextValidator
                            className={classes.field}
                            id="iterations"
                            label={'iterations'.toUpperCase()}
                            value={this.state['iterations']}
                            onChange={(event) => this.handleChange('iterations', event.target.value)}
                            variant="standard"
                            error={!!world_validation_errors['iterations']}
                            helperText={world_validation_errors['iterations']}
                            validators={['required', 'isCustomFloat']}
                            errorMessages={[message_required_field, message_floating_point]}
                        />
                        <h5></h5>
                        <TextValidator
                            className={classes.field}
                            id="abs_tol"
                            label={'Absolute Tolerance'.toUpperCase()}
                            value={this.state['abs_tol']}
                            onChange={(event) => this.handleChange('abs_tol', event.target.value)}
                            variant="standard"
                            error={!!world_validation_errors['abs_tol']}
                            helperText={world_validation_errors['abs_tol']}
                            validators={['required', 'isCustomFloat']}
                            errorMessages={[message_required_field, message_floating_point]}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">&deg;C</InputAdornment>,
                            }}
                        />
                        <TextValidator
                            className={classes.field}
                            id="iterations"
                            label={'Transient Duration'.toUpperCase()}
                            value={this.state['duration']}
                            onChange={(event) => this.handleChange('duration', event.target.value)}
                            variant="standard"
                            error={!!world_validation_errors['duration']}
                            helperText={world_validation_errors['duration']}
                            validators={['required']}
                            errorMessages={[message_required_field]}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">s</InputAdornment>,
                            }}
                        />
                        <TextValidator
                            className={classes.field}
                            id="steps"
                            label={'Transient Steps'.toUpperCase()}
                            value={this.state['steps']}
                            onChange={(event) => this.handleChange('steps', event.target.value)}
                            variant="standard"
                            error={!!world_validation_errors['steps']}
                            helperText={world_validation_errors['steps']}
                            validators={['required']}
                            errorMessages={[message_required_field]}
                        />
                        <h5></h5>
                        <TextValidator
                            className={classes.field}
                            id="lump_limit"
                            disabled={!!this.state.lumped}
                            label={'Quick Model Threshold'.toUpperCase()}
                            value={this.state['lump_limit']}
                            onChange={(event) => this.handleChange('lump_limit', event.target.value)}
                            variant="standard"
                            error={!!world_validation_errors['lump_limit']}
                            helperText={world_validation_errors['lump_limit']}
                            validators={['required']}
                            errorMessages={[message_required_field]}
                        />
                        <FormControl variant={"standard"}>
                            <FormControlLabel
                                id="lumpedmodel"
                                control={
                                    <Checkbox
                                        checked={!!this.state.lumped}
                                        onChange={(event) => this.handleChange('lumped', !this.state.lumped)}
                                    />}
                                label={
                                    <span>
                                    Quick Model
                                        <Tooltip title={
                                            <React.Fragment>
                                                <span style={{
                                                    fontSize: 14,
                                                }}>
                                                    Use a lumped model instead of detailed Via Array and Ball Array objects. This option is valid only when a Fill material has been specified for those objects.
                                                    <br/>
                                                    Quick Model will use approximated material properties in all 3 axes and will be created at a maximum resolution of 500&micro;m.
                                                    <br/>
                                                    NOTE: Quick Model will be automatically used if ball or via counts exceed 100K.
                                                </span>
                                            </React.Fragment>
                                        }>
                                            <InfoOutlinedIcon fontSize={"inherit"}/>
                                        </Tooltip>
                                    </span>
                                }
                            />
                        </FormControl>
                        <FormControl variant={"standard"}>
                            <FormControlLabel
                                id="saveinternal"
                                control={
                                    <Checkbox
                                        checked={!!this.state.save_internal_temp}
                                        onChange={(event) => this.handleChange('save_internal_temp', !this.state.save_internal_temp)}
                                    />}
                                label={
                                    <span>
                                    Save internal temperatures
                                        <Tooltip title={
                                            <React.Fragment>
                                                <span style={{
                                                    fontSize: 14,
                                                }}>
                                                    When this is checked the solver will save all internal temperatures. By default this is off.
                                                </span>
                                            </React.Fragment>
                                        }>
                                            <InfoOutlinedIcon fontSize={"inherit"}/>
                                        </Tooltip>
                                    </span>
                                }
                            />
                        </FormControl>
                        <FormControl variant={"standard"}>
                            <FormControlLabel
                                id="precondition"
                                control={
                                    <Checkbox
                                        checked={!!this.state.precondition}
                                        onChange={(event) => this.handleChange('precondition', !this.state.precondition)}
                                    />}
                                label={
                                    <span>
                                    Precondition transient
                                        <Tooltip title={
                                            <React.Fragment>
                                                <span style={{
                                                    fontSize: 14,
                                                }}>
                                                    Use steady state temperatures at time 0.
                                                </span>
                                            </React.Fragment>
                                        }>
                                            <InfoOutlinedIcon fontSize={"inherit"}/>
                                        </Tooltip>
                                    </span>
                                }
                            />
                        </FormControl>
                        <Typography variant="h6" className={classes.title}>World dimensions</Typography>
                        {
                            ['dxmin', 'dymin', 'dzmin'].map(name => {
                                return <TextValidator
                                    key={name}
                                    className={classes.field}
                                    id="dimensions"
                                    label={name.toUpperCase()}
                                    value={this.state[name]}
                                    onChange={(event) => this.handleChange(name, event.target.value)}
                                    variant="standard"
                                    error={!!world_validation_errors[name]}
                                    helperText={world_validation_errors[name]}
                                    validators={['required', 'isCustomFloat']}
                                    errorMessages={[message_required_field, message_floating_point]}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                                    }}
                                />
                            })
                        }
                        <h5></h5>
                        {
                            ['dxmax', 'dymax', 'dzmax'].map(name => {
                                return <TextValidator
                                    key={name}
                                    className={classes.field}
                                    id="dimensions"
                                    label={name.toUpperCase()}
                                    value={this.state[name]}
                                    onChange={(event) => this.handleChange(name, event.target.value)}
                                    variant="standard"
                                    error={!!world_validation_errors[name]}
                                    helperText={world_validation_errors[name]}
                                    validators={['required', 'isCustomFloat']}
                                    errorMessages={[message_required_field, message_floating_point]}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                                    }}
                                />
                            })
                        }
                    </MyValidationForm>
                </DialogContent>
                <DialogActions>
                    <Button id={'submitproject'} onClick={this.handleSubmit}
                            variant={'contained'} color={'primary'}>
                        Submit
                    </Button>
                </DialogActions>
            </Fragment>
        );
    }
}


ProjectSettings.propTypes = {
    createProject: PropTypes.func.isRequired,
    materials: PropTypes.array.isRequired,
    projects: PropTypes.array,
    current_project: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    updateProject: PropTypes.func.isRequired,
    setValidationErrors: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    showModal,
    createProject,
    setValidationErrors,
};

const mapStateToProps = state => ({
    materials: getMaterials(state) || [],
    projects: getProjects(state),
    validation_errors: getValidationErrors(state),
    current_project: getCurrentProject(state),
});

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(ProjectSettings);
