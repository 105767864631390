/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

export const UPDATE_PROJECT_SOURCE = 'UPDATE_PROJECT_SOURCE';
export const UPDATE_PROJECT_SOURCE_ERROR = 'UPDATE_PROJECT_SOURCE_ERROR';
export const UPDATE_PROJECT_SOURCE_SUCCESS = 'UPDATE_PROJECT_SOURCE_SUCCESS';

export const updateProjectSource =  payload => ({
    type: UPDATE_PROJECT_SOURCE,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/source/${payload.box.id}/`,
            method: 'PUT',
            body: JSON.stringify(payload.box),
        },
    },
});

export const UPDATE_PROJECT_SOURCE_AND_TREE = 'UPDATE_PROJECT_SOURCE_AND_TREE';
export const UPDATE_PROJECT_SOURCE_AND_TREE_SUCCESS = 'UPDATE_PROJECT_SOURCE_AND_TREE_SUCCESS';
export const UPDATE_PROJECT_SOURCE_AND_TREE_ERROR = 'UPDATE_PROJECT_SOURCE_AND_TREE_ERROR';

export const updateProjectSourceAndTree =  payload => ({
    type: UPDATE_PROJECT_SOURCE_AND_TREE,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/source/${payload.box.id}/`,
            method: 'PUT',
            body: JSON.stringify(payload.box),
        },
    },
});


export const CREATE_PROJECT_SOURCE = 'CREATE_PROJECT_SOURCE';
export const CREATE_PROJECT_SOURCE_ERROR = 'CREATE_PROJECT_SOURCE_ERROR';
export const CREATE_PROJECT_SOURCE_SUCCESS = 'CREATE_PROJECT_SOURCE_SUCCESS';

export const createProjectSource =  payload => ({
    type: CREATE_PROJECT_SOURCE,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/source/`,
            method: 'POST',
            body: JSON.stringify(payload.box),
        },
    },
});


export const CLONE_PROJECT_SOURCE = 'CLONE_PROJECT_SOURCE';
export const CLONE_PROJECT_SOURCE_ERROR = 'CLONE_PROJECT_SOURCE_ERROR';
export const CLONE_PROJECT_SOURCE_SUCCESS = 'CLONE_PROJECT_SOURCE_SUCCESS';

export const cloneProjectSource =  payload => ({
    type: CLONE_PROJECT_SOURCE,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/source/${payload.box.id}/clone/`,
            method: 'POST',
            body: JSON.stringify(payload.box),
        },
    },
});


export const DELETE_PROJECT_SOURCE = 'DELETE_PROJECT_SOURCE';
export const DELETE_PROJECT_SOURCE_ERROR = 'DELETE_PROJECT_SOURCE_ERROR';
export const DELETE_PROJECT_SOURCE_SUCCESS = 'DELETE_PROJECT_SOURCE_SUCCESS';

export const deleteProjectSource = payload => ({
    type: DELETE_PROJECT_SOURCE,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/source/${payload.box.id}/`,
            method: 'DELETE',
        },
    }
});