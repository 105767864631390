/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import ReactDOM from 'react-dom/client';
// import {render} from 'react-dom';
import {rootReducer} from './reducer';
import {configureStore} from './configureStore';
import {sagas} from './sagas';
import './index.css';

import {App} from './App';
// import 'normalize.css/normalize.css';
// import '@blueprintjs/core/lib/css/blueprint.css';
// import '@blueprintjs/core/lib/css/blueprint-hi-contrast.css';
// import '@blueprintjs/table/lib/css/table.css';
// import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";

export const {store, history} = configureStore(rootReducer, sagas);

ReactDOM.createRoot(document.getElementById('root'))
    .render(<App store={store} history={history}/>);

// render(
//     <App store={store} history={history}/>,
//     document.getElementById('root'),
// );

