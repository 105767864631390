/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

export const GET_PARAMETERS = 'GET_PARAMETERS';
export const GET_PARAMETERS_ERROR = 'GET_PARAMETERS_ERROR';
export const GET_PARAMETERS_SUCCESS = 'GET_PARAMETERS_SUCCESS';

export const getParameters = payload => ({
    type: GET_PARAMETERS,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/parameter/?_=` + Date.now(),
        },
    },
});


export const CREATE_PARAMETER = 'CREATE_PARAMETER';
export const CREATE_PARAMETER_ERROR = 'CREATE_PARAMETER_ERROR';
export const CREATE_PARAMETER_SUCCESS = 'CREATE_PARAMETER_SUCCESS';

export const createParameter = payload => ({
    type: CREATE_PARAMETER,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/parameter/`,
            method: 'POST',
            body: JSON.stringify(payload.parameter),
        },
    },
});


export const UPDATE_PARAMETER = 'UPDATE_PARAMETER';
export const UPDATE_PARAMETER_ERROR = 'UPDATE_PARAMETER_ERROR';
export const UPDATE_PARAMETER_SUCCESS = 'UPDATE_PARAMETER_SUCCESS';

export const updateParameter = payload => ({
    type: UPDATE_PARAMETER,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/parameter/${payload.parameter.id}/`,
            method: 'PUT',
            body: JSON.stringify(payload.parameter),
        },
    },
});


export const DELETE_PARAMETER = 'DELETE_PARAMETER';
export const DELETE_PARAMETER_ERROR = 'DELETE_PARAMETER_ERROR';
export const DELETE_PARAMETER_SUCCESS = 'DELETE_PARAMETER_SUCCESS';

export const deleteParameter = payload => ({
    type: DELETE_PARAMETER,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/parameter/${payload.parameterId}/`,
            method: 'DELETE',
        },
    },
});