/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';

import * as THREE from "three";
import {Tooltip} from "@mui/material";
import {startProjectDrag} from "../../../+store/actions/project";
import {connect} from "react-redux";
import Draggable3DIcon from './component/Draggable3DIcon';

export default function PCB3DIcon() {
    return (
        <Draggable3DIcon
            materials={[new THREE.MeshPhongMaterial({color: 0x00ff00}), new THREE.MeshPhongMaterial({color: 0xdb9828}), new THREE.MeshPhongMaterial({color: 0x00ff00})]}
            boxes={[new THREE.BoxBufferGeometry(20, 1, 20), new THREE.BoxBufferGeometry(20, 1, 18), new THREE.BoxBufferGeometry(20, 1, 16)]}
            lightPosition={{x: 10, y: 10, z: 10}}
            rotateObj={false}
            tooltipTitle={'Create Multi-layer object (PCB, substrate)'}
            objId={'MLOID'}
            dragType={'pcb'}
        />
    );
}