/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import createSagaMiddleware from "redux-saga";
import {routerMiddleware, routerReducer} from "react-router-redux";
import thunk from 'redux-thunk';

const sagaMiddleware = createSagaMiddleware();

export const configureStore = (reducer, sagas, initialState) => {
    const history = require("history").createHashHistory();
    const middlewares = applyMiddleware(
        thunk,
        sagaMiddleware,
        routerMiddleware(history)
    );

    const devtools =
        typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__
            ? window.__REDUX_DEVTOOLS_EXTENSION__()
            : (f) => f;

    const store = createStore(
        combineReducers({
            ...reducer,
            router: routerReducer
        }),
        initialState,
        compose(middlewares, devtools)
    );

    sagaMiddleware.run(sagas);

    return {store, history};
};
