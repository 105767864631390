import React, {useState} from 'react';
import {IconsFontAwesome} from "./IconsFontAwesome";
import {FONT_AWESOME_ICONS_TYPE_MAP} from "../core/mappings";
import {Alert, Slide, Snackbar} from "@mui/material";

export const CopyToClipboard = () => {
    const [open, setOpen] = useState(false)
    const copy = () => {
        const canvas = document.querySelector('#canvasID')?.firstChild;
        canvas && canvas.toBlob((blob) => navigator.clipboard.write([new ClipboardItem({'image/png': blob})]))
        setOpen(true)
    }
    return ( <>
        <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.clone}
                          titleAccess={`Copy to clipboard`}
                          secondClass={'smaller'}
                          onClickHandler={copy}/>
        <Snackbar
            open={open}
            autoHideDuration={3000}
            TransitionComponent={Slide}
            onClose={() => setOpen(false)}>
            <Alert variant="filled" severity={'info'}>
                <div>Copied to clipboard!</div>
            </Alert>
        </Snackbar>
        </>
    )
}