/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React from 'react';
import {getData as getProject, getProjectMaterials} from "../../../+store/reducer/project";
import {getValidationErrors} from "../../../+store/reducer/validation_errors";
import {getData as getTree, getSelectedObject} from "../../../+store/reducer/tree";
import {setSelectedObject, updateSelectedObject} from "../../../+store/actions/actions";
import {connect} from "react-redux";
import {withSettingsTemplate} from "./SettingsTemplate";
import PropTypes from "prop-types";
import {FormControl, FormGroup, FormHelperText, InputLabel, MenuItem, Select, Tooltip} from "@mui/material";
import {IconCheckBoxField, InputFields, SettingsTabPlane} from "../../../../components/BasicSettings";
import {WarningOutlined} from "@mui/icons-material";
import HeatTransferCoeff from "../../../../components/HeatTranserCoeff";
import BasicTabs from "../../../../components/BasicTabs";
import {LumpedModelCheckbox, renderMaterialListSelectOptions} from "./commonComponents";
import {message_floating_point} from "../../../utils";
import {
    createProjectViaArray, deleteProjectViaArray,
    updateProjectViaArray,
    updateProjectViaArrayAndTree
} from "../../../+store/actions/via_array";

const ViaArraySettingsTemplate = ({
                                      // passed from SettingsTemplate
                                      boxHasChange,
                                      handleRevert,
                                      handleCancel,
                                      handleSubmit,
                                      handleChange,
                                      box,
                                      state,
                                      // directly from store
                                      validation_errors,
                                      materials
                                  }) => {
    const {xcount, ycount, diameter, dx, dy, dz, is_plated, inner_diameter, hole_material} = state;
    const via_eff_diam = Math.sqrt(Math.PI) / 2 * (+diameter - is_plated ? +inner_diameter : 0) / 1000,
        via_volume = +xcount * +ycount * Math.PI * Math.pow(via_eff_diam / 2, 2) * +dz,
        box_volume = +dx * +dy * +dz;

    const tabs = [
        {
            label: 'Spatial', Component: <FormGroup style={{height: '100%', position: 'relative'}}>
                <SettingsTabPlane
                    hasChange={boxHasChange()}
                    onRevert={box.id !== 'creating' ? handleRevert : handleCancel}
                    onSave={handleSubmit}
                    object={box}>
                    <InputFields
                        field_names={['name']}
                        state={state}
                        ids={'viaarrayname'}
                        validation_errors={validation_errors}
                        handleChange={handleChange}/>
                    <InputFields
                        groupTitle={'Location'}
                        field_names={['x', 'y', 'z']}
                        units={'mm'}
                        state={state}
                        ids={'viaarraylocation'}
                        validation_errors={validation_errors}
                        handleChange={handleChange}/>
                    <InputFields
                        groupTitle={'Dimension'}
                        field_names={['dx', 'dy', 'dz']}
                        units={'mm'}
                        state={state}
                        ids={'viaarraydimension'}
                        validation_errors={validation_errors}
                        handleChange={handleChange}/>
                    <InputFields
                        groupTitle={'Array Properties'}
                        field_names={['xcount', 'ycount']}
                        state={state}
                        ids={'viaarraycount'}
                        validation_errors={validation_errors}
                        handleChange={handleChange}/>
                    <InputFields
                        field_names={['xpitch', 'ypitch', 'diameter']}
                        state={state}
                        units={'µm'}
                        ids={'viaarraypitch&diameter'}
                        validation_errors={validation_errors}
                        handleChange={handleChange}/>
                    <InputFields
                        disabled
                        field_names={['Eff._ball_volume', 'Total_box_volume', 'Ratio']}
                        state={{
                            'Eff._ball_volume': isNaN(via_volume) ? '—' : via_volume.toFixed(3),
                            'Total_box_volume': isNaN(box_volume) ? '—' : box_volume.toFixed(3),
                            'Ratio': (isNaN(box_volume) || isNaN(via_volume)) ? '—' : (via_volume / box_volume * 100).toFixed(2)
                        }}
                        units={{'Eff._ball_volume': 'mm³', 'Total_box_volume': 'mm³', 'Ratio': '%'}}/>
                    {
                        (xcount && ycount && (+xcount * +ycount > 15e3)) &&
                        <Tooltip
                            sx={{position: 'absolute', right: '50%', transform: 'translateX(50%)'}}
                            title={
                                <span>
                            WARNING: Due to large number of objects display
                            will be reduced to approximately 15,000 objects. Solver behavior is
                            unaffected.
                                </span>}>
                            <WarningOutlined fontSize={'inherit'} color={'warning'}/>
                        </Tooltip>
                    }
                </SettingsTabPlane>
            </FormGroup>

        },
        {
            label: 'Thermal', Component: <FormGroup style={{height: '100%', position: 'relative'}}>
                <SettingsTabPlane
                    hasChange={boxHasChange()}
                    onRevert={box.id !== 'creating' ? handleRevert : handleCancel}
                    onSave={handleSubmit}
                    object={box}>
                    <FormControl variant={'standard'} fullWidth>
                        <InputLabel error={!!validation_errors['material']}
                                    id={'materiallabel'}>
                            Via material
                        </InputLabel>
                        <Select
                            labelId={'materiallabel'}
                            id={'viaarraymaterial'}
                            value={state.material || ''}
                            onChange={(event) => handleChange('material', event.target.value)}
                        >
                            {renderMaterialListSelectOptions(materials)}
                        </Select>
                        {validation_errors['material'] &&
                            <FormHelperText>{validation_errors['material']}</FormHelperText>
                        }
                    </FormControl>
                    <FormControl variant={'standard'} fullWidth>
                        <InputLabel shrink
                                    error={!!validation_errors['fill_material']}
                                    id={'fillmateriallabel'}>
                            Fill material
                        </InputLabel>
                        <Select
                            id={'fillmaterial'}
                            labelId={'fillmateriallabel'}
                            displayEmpty={true}
                            value={state.fill_material || ''}
                            onChange={(event) => handleChange('fill_material', event.target.value)}
                            label={'Fill material'}
                        >
                            <MenuItem key={'None'} value={null}>-</MenuItem>
                            {renderMaterialListSelectOptions(materials)}
                        </Select>
                        <IconCheckBoxField id={'isPlatedVia'}
                                           checked={is_plated} field_name={'is_plated'}
                                           handleChange={handleChange}
                                           checkBoxLabel={'Plated via'}
                                           sx={{
                                               // color: pink[800],
                                               '&.Mui-checked': {
                                                   color: '#3f51b5',
                                               },
                                           }}/>
                        {
                            is_plated && <InputFields
                                field_names={['inner_diameter']}
                                state={state}
                                units={'µm'}
                                ids={'viaarrayinnerdiameter'}
                                validation_errors={validation_errors}
                                handleChange={handleChange}/>

                        }
                        {
                            is_plated && <FormControl variant={'standard'} fullWidth>
                                <InputLabel shrink
                                            error={!!validation_errors['hole_material']}
                                            id={'holemateriallabel'}>
                                    Hole material
                                </InputLabel>
                                <Select
                                    id={'holematerial'}
                                    labelId={'holemateriallabel'}
                                    displayEmpty={true}
                                    value={hole_material || ''}
                                    onChange={(event) => handleChange('hole_material', event.target.value)}
                                    label={'Hole material'}
                                >
                                    <MenuItem key={'None'} value={null}>-</MenuItem>
                                    {renderMaterialListSelectOptions(materials)}
                                </Select>
                            </FormControl>
                        }

                        {validation_errors['fill_material'] &&
                            <FormHelperText>{validation_errors['fill_material']}</FormHelperText>
                        }
                    </FormControl>
                    <HeatTransferCoeff
                        fullWidth
                        variant={'standard'}
                        validators={[]}
                        value={state.hc || ''}
                        onChange={(event) => handleChange('hc', event.target.value)}
                        error={!!validation_errors['hc']}
                        helperText={validation_errors['hc']}
                        errorMessages={[message_floating_point]}
                    />
                    <LumpedModelCheckbox handleChange={handleChange} state={state}/>
                </SettingsTabPlane>
            </FormGroup>
        }
    ];

    return <BasicTabs tabs={tabs} title={'Properties'} isSwitch={true}/>

}

ViaArraySettingsTemplate.propTypes = {
    boxHasChange: PropTypes.func.isRequired,
    handleRevert: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    box: PropTypes.object,
    state: PropTypes.object.isRequired,
    validation_errors: PropTypes.object,
    materials: PropTypes.array
};

const mapStateToProps = state => ({
    materials: getProjectMaterials(state) || [],
    validation_errors: getValidationErrors(state),
    project_id: getProject(state).id,
    tree: getTree(state),
    box: getSelectedObject(state),
});

const mapDispatchToProps = {
    createProjectObject: createProjectViaArray,
    updateProjectObject: updateProjectViaArray,
    updateProjectObjectAndTree: updateProjectViaArrayAndTree,
    deleteProjectObject: deleteProjectViaArray,
    setSelectedObject,
    updateSelectedObject
};

export default connect(mapStateToProps, mapDispatchToProps)(withSettingsTemplate(ViaArraySettingsTemplate));
