import {createTheme} from '@mui/material/styles';


const theme = createTheme({
    typography: {
        fontFamily: [
            'Roboto Condensed',
            'Roboto',
            'Helvetica Neue',
            'Arial',
            'sans-serif'
        ].join(',')
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#3f51b5',
        },
        secondary: {
            main: '#303030',
        },
        neutral: {
            main: '#FFFFFF',
        },
        background: {
            input: '#141414',
            paper: '#242424',
            primary: '#525252',
            main: '#303030'
        },
        solve: {
            main: '#3FB568',
            active: '#1EDE61',
        },
        warning: {
            main: '#FF9100'
        },
        error: {
            main: '#cd1f1f',
            active: '#e13c3c'
        }
    },
    spacing: 8,
    contrastThreshold: 10,
    fontSize: {
        main: '1.5rem',
        secondary: '1.25rem',
        info: '1rem',
        infoSmall: '0.75rem',
    },
    opacity: {
        main: 1,
        faded: 0.65,
    },
    components: {
        MuiButton: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiFilledInput: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiFormControl: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiFormHelperText: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiIconButton: {
            size: 'small',
        },
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
                margin: 'dense',
                autoComplete: 'off'
            },
        },
        MuiInput: {
            defaultProps: {
                autoComplete: 'off'
            },
        },
        MuiModal: {
            defaultProps: {
                autoComplete: 'off',
                disableScrollLock: true,
            },
        },
        MuiListItem: {
            defaultProps: {
                dense: true,
            },
        },
        MuiOutlinedInput: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiFab: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiTable: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiToolbar: {
            defaultProps: {
                variant: 'dense',
                // styleOverrides: {
                //     root: {
                //         paddingLeft: 0
                //     },
                // },
            },
        },
        MuiMenu: {
            defaultProps: {
                disableScrollLock: true,
            }
        },
        MuiMenuItem: {
            defaultProps: {
                disableRipple: true
            }
        },
    },
});

// to override styles using default theme
const customTheme = createTheme(theme, {
    components: {
        MuiInput: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.background.input,
                    '&::before': {
                        borderBottom: theme.palette.background.input,
                        opacity: 1,
                    },
                    paddingLeft: '8px',

                    '&.Mui-disabled': {
                        backgroundColor: 'transparent',
                        border: '1px solid black',

                        '&:before': {
                            border: 'none'
                        },

                        '.MuiInput-input': {
                            cursor: 'not-allowed',
                        },
                    },
                },

            },
            variants: [
                {
                    props: {variant: 'panel'},
                    style: {
                        width: '96px',
                        height: '32px',
                        fontFamily: 'Roboto Condensed',
                        margin: '0 16px 2px 4px',
                        fontWeight: '100',
                        alignItems: 'center',
                    },
                },
            ],
        },
        MuiInputBase: {
            defaultProps: {
                margin: 'dense',
                size: 'small',
            },
            styleOverrides: {
                root: {
                    borderRadius: '4px',
                    flex: 1,
                    padding: '5px',
                    paddingLeft: theme.spacing(1),
                    fontSize: '16px',
                },
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    letterSpacing: '0.75px'
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    letterSpacing: '1.25px'
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    marginLeft: theme.spacing(1),
                    fontSize: '16px',
                    color: theme.palette.neutral.main,
                    zIndex: 10,
                    letterSpacing: 1.2,
                    opacity: 0.65,
                    top: 'auto',
                    textTransform: 'uppercase',
                    '&.Mui-focused': { // increase the specificity for the pseudo class
                        color: theme.palette.text.secondary,
                        opacity: 0.65,
                    },
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    opacity: 1,
                    letterSpacing: 1.2,
                    '> p': {
                        fontSize: '0.8em',
                    },
                },
                positionEnd: {
                    position: 'absolute',
                    right: 5,
                    bottom: 10,
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                    backgroundColor: '#2a2a2a',
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    borderRadius: '4px',
                    margin: '8px 0',

                    '&.MuiTextField-root': {
                        marginRight: '10px'
                    }
                },
            },
        },
        MuiCollapse: {
            styleOverrides: {
                wrapperInner: {
                    display: 'flex',
                    justifyContent: 'space-between',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    '& .MuiButton-root:not(:last-child)': {
                        marginRight: '5px'
                    }
                },
            },

            variants: [
                {
                    props: {variant: 'faded', size: 'secondary'},
                    style: {
                        opacity: theme.opacity.faded,
                        fontSize: theme.fontSize.secondary,
                        alignSelf: 'center',
                        letterSpacing: '1.2px',
                        fontFamily: 'Roboto Condensed',
                        fontWeight: '400',

                        // '@media (max-width:1440px)': {
                        //     display: 'none',
                        // },
                    },
                },
                {
                    props: {variant: 'faded', size: 'info'},
                    style: {
                        opacity: theme.opacity.faded,
                        fontSize: theme.fontSize.info,
                        alignSelf: 'center',
                        letterSpacing: '1.2px',
                        fontFamily: 'Roboto Condensed',
                        fontWeight: '400',

                        // '@media (max-width:1440px)': {
                        //     display: 'none',
                        // },
                    },
                },
                {
                    props: {variant: 'faded', size: 'infoSmall'},
                    style: {
                        opacity: theme.opacity.faded,
                        fontSize: theme.fontSize.infoSmall,
                        alignSelf: 'center',
                        letterSpacing: '1.2px',
                        fontFamily: 'Roboto Condensed',
                        fontWeight: '400',

                        // '@media (max-width:1440px)': {
                        //     display: 'none',
                        // },
                    },
                },
            ],
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: theme.fontSize.info,

                    '& .MuiIcon-root': {
                        marginRight: '5px',
                    },

                    '&:not(:last-child)': {
                        marginRight: '10px',
                    },
                },
                'containedPrimary': {
                    backgroundColor: theme.palette.primary.main,
                    '&:hover': {
                        backgroundColor: '#4761F5',
                    },
                },
                'containedSecondary': {
                    backgroundColor: '#474747',
                    // minWidth: '112px',

                    '&:hover': {
                        backgroundColor: '#6a6a6a',
                        opacity: 1,
                    },
                },

            },
            variants: [
                {
                    props: {variant: 'solve'},
                    style: {
                        backgroundColor: theme.palette.solve.main,
                        borderRadius: 0,

                        '&:hover': {
                            backgroundColor: theme.palette.solve.active,
                        },
                    },
                },
            ],
        },
        MuiToggleButtonGroup: {
            variants: [
                {
                    props: {variant: 'mode'},
                    style: {
                        backgroundColor: '#373737',
                    },
                },
            ],
        },
        MuiBox: {
            variants: [
                {
                    props: {variant: 'flex'},
                    style: {
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    },
                },
            ],
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    '& .MuiIcon-root': {
                        marginBottom: '8px',

                        '&:not(:last-child)': {
                            marginRight: '10px',
                        },
                        '&:first-of-type': {
                            marginLeft: '8px',
                        },
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    '& .MuiIcon-root:not(:last-child)': {
                        marginRight: '5px'
                    }
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    borderBottom: '1px solid rgba(81, 81, 81, 1)',
                },
            },
        },
    },

});

export {customTheme as theme};