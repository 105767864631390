/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {BufferGeometry, Float32BufferAttribute, Vector2, Vector3} from "three";

class CylinderArrayGeometry extends BufferGeometry {

    constructor(start_x, start_y, start_z,
                count_x, count_y, count_z,
                pitch_x, pitch_y, pitch_z,
                radiusTop = 1, radiusBottom = 1, height = 1, radialSegments = 8, heightSegments = 1, openEnded = false, thetaStart = 0, thetaLength = Math.PI * 2) {

        super();
        this.type = 'CylinderGeometry';

        this.parameters = {
            radiusTop: radiusTop,
            radiusBottom: radiusBottom,
            height: height,
            radialSegments: radialSegments,
            heightSegments: heightSegments,
            openEnded: openEnded,
            thetaStart: thetaStart,
            thetaLength: thetaLength
        };

        const scope = this;

        radialSegments = Math.floor(radialSegments);
        heightSegments = Math.floor(heightSegments);

        // buffers

        const indices = [];
        const vertices = [];
        const normals = [];
        const uvs = [];

        // helper variables

        let index = 0;
        const indexArray = [];
        const halfHeight = height / 2;
        let groupStart = 0;

        // generate geometry
        for (let dx = 0; dx < count_x; ++dx) {
            for (let dy = 0; dy < count_y; ++dy) {
                for (let dz = 0; dz < count_z; ++dz) {

                    generateTorso(
                        start_x + dx * pitch_x,
                        start_y + dy * pitch_y,
                        start_z + dz * pitch_z,
                        dx * count_y * count_z + dy * count_z + dz);

                    if (openEnded === false) {

                        if (radiusTop > 0) generateCap(
                            start_x + dx * pitch_x, start_y + dy * pitch_y, start_z + dz * pitch_z,
                            dx * count_y * count_z + dy * count_z + dz, true);
                        if (radiusBottom > 0) generateCap(
                            start_x + dx * pitch_x, start_y + dy * pitch_y, start_z + dz * pitch_z,
                            dx * count_y * count_z + dy * count_z + dz, false);

                    }
                }
            }
        }
        // build geometry

        this.setIndex(indices);
        this.setAttribute('position', new Float32BufferAttribute(vertices, 3));
        this.setAttribute('normal', new Float32BufferAttribute(normals, 3));
        this.setAttribute('uv', new Float32BufferAttribute(uvs, 2));

        function generateTorso(pos_x, pos_y, pos_z, relation) {

            const normal = new Vector3();
            const vertex = new Vector3();

            let groupCount = 0;

            // this will be used to calculate the normal
            const slope = (radiusBottom - radiusTop) / height;

            // generate vertices, normals and uvs

            for (let y = 0; y <= heightSegments; y++) {

                const indexRow = [];

                const v = y / heightSegments;

                // calculate the radius of the current row

                const radius = v * (radiusBottom - radiusTop) + radiusTop;

                for (let x = 0; x <= radialSegments; x++) {

                    const u = x / radialSegments;

                    const theta = u * thetaLength + thetaStart;

                    const sinTheta = Math.sin(theta);
                    const cosTheta = Math.cos(theta);

                    // vertex

                    vertex.x = radius * sinTheta;
                    vertex.y = -v * height + halfHeight;
                    vertex.z = radius * cosTheta;
                    vertices.push(pos_x + vertex.x, pos_y + vertex.y, pos_z + vertex.z);

                    // normal

                    normal.set(sinTheta, slope, cosTheta).normalize();
                    normals.push(normal.x, normal.y, normal.z);

                    // uv

                    uvs.push(u, 1 - v);

                    // save index of vertex in respective row

                    indexRow.push(index++);

                }

                // now save vertices of the row in our index array

                indexArray.push(indexRow);

            }

            // generate indices

            let rel = (heightSegments + 1) * relation;
            for (let x = 0; x < radialSegments; x++) {

                for (let y = 0; y < heightSegments; y++) {

                    // we use the index array to access the correct indices

                    const a = indexArray[rel + y][x];
                    const b = indexArray[rel + y + 1][x];
                    const c = indexArray[rel + y + 1][x + 1];
                    const d = indexArray[rel + y][x + 1];

                    // faces

                    indices.push(a, b, d);
                    indices.push(b, c, d);

                    // update group counter

                    groupCount += 6;

                }

            }

            // add a group to the geometry. this will ensure multi material support

            scope.addGroup(groupStart, groupCount, 0);

            // calculate new start value for groups

            groupStart += groupCount;

        }

        function generateCap(pos_x, pos_y, pos_z, relation, top) {

            // save the index of the first center vertex
            const centerIndexStart = index;

            const uv = new Vector2();
            const vertex = new Vector3();

            let groupCount = 0;

            const radius = (top === true) ? radiusTop : radiusBottom;
            const sign = (top === true) ? 1 : -1;

            // first we generate the center vertex data of the cap.
            // because the geometry needs one set of uvs per face,
            // we must generate a center vertex per face/segment

            for (let x = 1; x <= radialSegments; x++) {

                // vertex

                vertices.push(pos_x + 0, pos_y + halfHeight * sign, pos_z + 0);

                // normal

                normals.push(0, sign, 0);

                // uv

                uvs.push(0.5, 0.5);

                // increase index

                index++;

            }

            // save the index of the last center vertex
            const centerIndexEnd = index;

            // now we generate the surrounding vertices, normals and uvs

            for (let x = 0; x <= radialSegments; x++) {

                const u = x / radialSegments;
                const theta = u * thetaLength + thetaStart;

                const cosTheta = Math.cos(theta);
                const sinTheta = Math.sin(theta);

                // vertex

                vertex.x = radius * sinTheta;
                vertex.y = halfHeight * sign;
                vertex.z = radius * cosTheta;
                vertices.push(pos_x + vertex.x, pos_y + vertex.y, pos_z + vertex.z);

                // normal

                normals.push(0, sign, 0);

                // uv

                uv.x = (cosTheta * 0.5) + 0.5;
                uv.y = (sinTheta * 0.5 * sign) + 0.5;
                uvs.push(uv.x, uv.y);

                // increase index

                index++;

            }

            // generate indices

            for (let x = 0; x < radialSegments; x++) {

                const c = centerIndexStart + x;
                const i = centerIndexEnd + x;

                if (top === true) {

                    // face top

                    indices.push(i, i + 1, c);

                } else {

                    // face bottom

                    indices.push(i + 1, i, c);

                }

                groupCount += 3;

            }

            // add a group to the geometry. this will ensure multi material support

            scope.addGroup(groupStart, groupCount, top === true ? 1 : 2);

            // calculate new start value for groups

            groupStart += groupCount;

        }

    }

}


export {CylinderArrayGeometry, CylinderArrayGeometry as CylinderArrayBufferGeometry};
