/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {getNodeAtPath, changeNodeAtPath} from '@nosferatu500/react-sortable-tree';
import {MAIN_FOLDER_NAMES} from './constants/PROJECTS_TREE';

const collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});

export const dashboardUtils = {
    getNodeKey({node, treeIndex}) {
        return treeIndex;
    },
    getNodeByPath: ({treeData, activeNodePath}) => {
        const {node} = getNodeAtPath({
            treeData,
            path: activeNodePath,
            getNodeKey: this.getNodeKey,
        });

        return node;
    },
    expandSelectedFolderImage: ({treeData, path, node}) => {
        return changeNodeAtPath({
            treeData,
            path,
            getNodeKey: this.getNodeKey,
            newNode: {
                ...node,
                expanded: !!node.children.length,
            },
        });
    },
    generateTreeData(data, path = [1]) {
        return data
            .sort((a, b) => collator.compare(a.name, b.name))
            .map(({id, name, parent_folder, child_folders}, idx) => {
                const currentNodePath = [...path, path[path.length - 1] + idx + 1];
                return {
                    id,
                    name,
                    parent_folder,
                    path: currentNodePath,
                    isDirectory: true,
                    shared: false,
                    rootType: MAIN_FOLDER_NAMES.myProjects,
                    rootFolder: true,
                    children: child_folders.length ? this.generateTreeData(child_folders, currentNodePath) : [],
                };
            });
    },
    sortAlphabeticallyTreeData({data, path = [1], parent_folder = null}) {
        return data
            .sort((a, b) => collator.compare(a.name, b.name))
            .map((folder, idx) => {
                const {children, id, ...rest} = folder;
                const currentChildren = folder.hasOwnProperty('children') ? folder.children : folder.child_folders;
                const currentNodePath = [...path, path[path.length - 1] + idx + 1];

                return {
                    ...rest,
                    id,
                    parent_folder,
                    path: currentNodePath,
                    children: currentChildren.length ? this.sortAlphabeticallyTreeData({
                        data: currentChildren,
                        path: currentNodePath,
                        parent_folder: id,
                    }) : [],
                };
            });
    },
    setPropsToSharedTreeData(data, Ids) {
        const sharedFolderIds = {...Ids};

        function getSharedTreeAndIds(data, sharedFolderIds) {
            return data.map(({id, name, parent_folder, children}) => {
                if (!sharedFolderIds[id]) {
                    sharedFolderIds[id] = id;
                }

                return {
                    id,
                    name,
                    parent_folder,
                    isDirectory: true,
                    shared: true,
                    rootType: MAIN_FOLDER_NAMES.sharedProjects,
                    rootFolder: false,
                    children: children.length ? getSharedTreeAndIds(children, sharedFolderIds) : [],
                };
            });
        }

        return {
            newChildren: getSharedTreeAndIds(data, sharedFolderIds)[0],
            sharedFolderIds,
        };
    },
    getSharedFolderIds(data, Ids) {
        const sharedFolderIds = {...Ids};

        function getSharedTreeAndIds(data, Ids) {
            data.forEach(({id, name, parent_folder, children}) => {
                if (!sharedFolderIds[id]) {
                    sharedFolderIds[id] = id;
                }

                if (children.length) {
                    getSharedTreeAndIds(children, Ids);
                }
            });
        }

        getSharedTreeAndIds(data, sharedFolderIds);

        return sharedFolderIds;
    },
};
