/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import background from '../../background.png';

import {login} from "../actions";
import {showModal} from "../../modals/actions";

import {SignInForm} from './SignInForm';


export class AuthenticationComponent extends Component {

    render() {
        const {error} = this.props;
        return (
            <div style={{
                width: "calc(100hh - 40px)",
                height: "calc(100vh - 40px)",
                margin: 20,
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover',
            }}>
                <SignInForm onSubmit={this.props.login} error_message={error}/>
            </div>
        );
    }
}

AuthenticationComponent.propTypes = {
    error: PropTypes.string,
    login: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    error: state.authentication.error
});

const mapDispatchToProps = {
    login,
    showModal,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthenticationComponent);
