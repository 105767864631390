/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {
    CREATE_SCENARIO_SUCCESS,
    UPDATE_SCENARIO_SUCCESS,
    DELETE_SCENARIO_SUCCESS,
    GET_SCENARIOS,
    GET_SCENARIOS_ERROR,
    GET_SCENARIOS_SUCCESS,
    UPDATE_SCENARIO_PARAM_SUCCESS,
    GET_SCENARIO_CASES, GET_SCENARIO_CASES_ERROR, GET_SCENARIO_CASES_SUCCESS,
} from '../actions/scenarios';

export const defaultState = {
    loading: false,
    loadingCases: 0,
    data: [],
    cases: {}
};


export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case GET_SCENARIOS:
            return {
                ...state,
                loading: true,
            };
        case GET_SCENARIOS_ERROR:
            return {
                ...state,
                loading: false,
                data: [],
            };
        case GET_SCENARIOS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };

        case GET_SCENARIO_CASES:
            return {
                ...state,
                loadingCases: state.loadingCases + 1,
                cases: {
                    ...state.cases,
                    [action.payload.scenario.id]: [],
                }
            };
        case GET_SCENARIO_CASES_ERROR:
            return {
                ...state,
                loadingCases: state.loadingCases - 1,
            };
        case GET_SCENARIO_CASES_SUCCESS:
            return {
                ...state,
                loadingCases: state.loadingCases - 1,
                cases: action.payload.length ? {
                    ...state.cases,
                    [action.payload[0].scenario]: action.payload,
                } : state.cases
            };


        case CREATE_SCENARIO_SUCCESS:
            return {
                ...state,
                data: [...state.data, action.payload],
            };
        case UPDATE_SCENARIO_SUCCESS:
            return {
                ...state,
                data: state.data.map(scenario => scenario.id === action.payload.id ? action.payload : scenario),
            };

        case UPDATE_SCENARIO_PARAM_SUCCESS:
            return {
                ...state,
                data: state.data.map(scenario => (
                    {
                        ...scenario,
                        parameters: scenario.parameters.map(param => (param.id === action.payload.id ? action.payload : param))
                    }
                ))
            };

        case DELETE_SCENARIO_SUCCESS:
            return {
                ...state,
                data: state.data.filter(scenario => scenario.id !== action.payload.scenarioid),
            };
        default:
            return state;
    }
};
