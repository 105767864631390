/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {TextValidator} from 'react-material-ui-form-validator';
import {createLibrary, updateLibrary} from '../actions';
import {getValidationErrors} from '../reducer';

import MyValidationForm from '../../components/MyValidationForm';

const styles = (theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '70ch',
        },
    },
});


class LibrarySettings extends Component {

    constructor(props) {
        super(props);

        const library = props.library || {
            name: '',
        };

        this.form = React.createRef();

        this.state = {
            name: library.name
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(field_name, value) {
        this.setState({
            [field_name]: value,
        });
    }

    handleSubmit() {
        this.form.current.isFormValid(false)
            .then(is_valid => {
                if (is_valid) {
                    const {onClose, library, createLibrary, updateLibrary} = this.props;

                    if (library) {
                        updateLibrary({
                            ...library,
                            ...this.state,
                        });
                    } else {
                        createLibrary(this.state);
                    }
                }
            });
    }

    render() {
        const {classes, library, validation_errors} = this.props;
        const {name, color, density, specific_heat, conductivity} = this.state;

        return (
            <Fragment>
                <DialogContent dividers>
                    <MyValidationForm
                        autoComplete={'off'}
                        ref={this.form}
                        onSubmit={this.handleSubmit}
                        className={'validation-form-sm'}
                    >
                        <TextValidator
                            className={classes.root}
                            label={'Name'}
                            id={'libraryname'}
                            value={name}
                            onChange={event => this.handleChange('name', event.target.value)}
                            variant={'standard'}
                            error={!!validation_errors.name}
                            helperText={validation_errors.name}
                            validators={['required']}
                            errorMessages={['This field is required!', 'No']}
                        />
                    </MyValidationForm>
                </DialogContent>
                <DialogActions>
                    <Button id={'addlibrary'} type={'submit'} variant={'contained'}
                            onClick={this.handleSubmit}
                            color={'secondary'} autoFocus>
                        {library ? 'Update' : 'Add'}
                    </Button>
                </DialogActions>
            </Fragment>
        );
    }
}


LibrarySettings.propTypes = {
    onClose: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    validation_errors: getValidationErrors(state),
});

const mapDispatchToProps = {
    createLibrary,
    updateLibrary
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(LibrarySettings);
