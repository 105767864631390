/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

export const GET_SCENARIOS = 'GET_SCENARIOS';
export const GET_SCENARIOS_ERROR = 'GET_SCENARIOS_ERROR';
export const GET_SCENARIOS_SUCCESS = 'GET_SCENARIOS_SUCCESS';

export const getScenarios = payload => ({
    type: GET_SCENARIOS,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/scenario/?_=` + Date.now(),
        },
    },
});


export const CREATE_SCENARIO = 'CREATE_SCENARIO';
export const CREATE_SCENARIO_ERROR = 'CREATE_SCENARIO_ERROR';
export const CREATE_SCENARIO_SUCCESS = 'CREATE_SCENARIO_SUCCESS';

export const createScenario = payload => ({
    type: CREATE_SCENARIO,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/scenario/`,
            method: 'POST',
            body: JSON.stringify(payload.scenario),
        },
    },
});


export const UPDATE_SCENARIO = 'UPDATE_SCENARIO';
export const UPDATE_SCENARIO_ERROR = 'UPDATE_SCENARIO_ERROR';
export const UPDATE_SCENARIO_SUCCESS = 'UPDATE_SCENARIO_SUCCESS';

export const updateScenario = payload => ({
    type: UPDATE_SCENARIO,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/scenario/${payload.scenario.id}/`,
            method: 'PUT',
            body: JSON.stringify(payload.scenario),
        },
    },
});


export const DELETE_SCENARIO = 'DELETE_SCENARIO';
export const DELETE_SCENARIO_ERROR = 'DELETE_SCENARIO_ERROR';
export const DELETE_SCENARIO_SUCCESS = 'DELETE_SCENARIO_SUCCESS';

export const deleteScenario = payload => ({
    type: DELETE_SCENARIO,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/scenario/${payload.scenario.id}/`,
            method: 'DELETE',
        },
    },
});


export const UPDATE_SCENARIO_PARAM = 'UPDATE_SCENARIO_PARAM';
export const UPDATE_SCENARIO_PARAM_ERROR = 'UPDATE_SCENARIO_PARAM_ERROR';
export const UPDATE_SCENARIO_PARAM_SUCCESS = 'UPDATE_SCENARIO_PARAM_SUCCESS';

export const updateScenarioParam = payload => ({
    type: UPDATE_SCENARIO_PARAM,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/scenario/${payload.scenario.id}/parameter/${payload.parameter.id}/`,
            method: 'PUT',
            body: JSON.stringify(payload.parameter),
        },
    },
});


export const GET_SCENARIO_CASES = 'GET_SCENARIO_CASES';
export const GET_SCENARIO_CASES_ERROR = 'GET_SCENARIO_CASES_ERROR';
export const GET_SCENARIO_CASES_SUCCESS = 'GET_SCENARIO_CASES_SUCCESS';

export const getScenarioCases = payload => {
    return {
        type: GET_SCENARIO_CASES,
        payload,
        meta: {
            request: {
                url: `/api/project/${payload.project_id}/scenario/${payload.scenario.id}/case/?_=` + Date.now(),
                method: 'GET',
            },
        },
    }
};


export const RUN_SCENARIO = 'RUN_SCENARIO';
export const RUN_SCENARIO_ERROR = 'RUN_SCENARIO_ERROR';
export const RUN_SCENARIO_SUCCESS = 'RUN_SCENARIO_SUCCESS';

export const runScenario = payload => {
    return {
        type: RUN_SCENARIO,
        payload,
        meta: {
            request: {
                url: `/api/project/${payload.project_id}/scenario/${payload.scenario.id}/run/`,
                method: 'PUT',
            },
        },
    }
};


export const STOP_SCENARIO_TASKS = 'STOP_SCENARIO_TASKS';
export const STOP_SCENARIO_TASKS_ERROR = 'STOP_SCENARIO_TASKS_ERROR';
export const STOP_SCENARIO_TASKS_SUCCESS = 'STOP_SCENARIO_TASKS_SUCCESS';

export const STOP_SCENARIOTasks = payload => {
    return {
        type: STOP_SCENARIO_TASKS,
        payload,
        meta: {
            request: {
                url: `/api/project/${payload.project_id}/scenario/${payload.scenario.id}/task/`,
                method: 'DELETE',
            },
        },
    }
};