/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import { createAction } from 'redux-actions';
import { requestController } from '../../../core/request.utils';

const activeRequestControllers = {};

export const UPDATE_PROJECT_BOX = 'UPDATE_PROJECT_BOX';
export const UPDATE_PROJECT_BOX_ERROR = 'UPDATE_PROJECT_BOX_ERROR';
export const UPDATE_PROJECT_BOX_SUCCESS = 'UPDATE_PROJECT_BOX_SUCCESS';

export const updateProjectBox = payload => {
    const { controller, currentRequest } = requestController(activeRequestControllers, `${payload.project_id}/box/${payload.box.id}`);

    return {
        type: UPDATE_PROJECT_BOX,
        payload,
        meta: {
            request: {
                url: `/api/project/${payload.project_id}/box/${payload.box.id}/`,
                method: 'PUT',
                body: JSON.stringify(payload.box),
                signal: controller.signal,
                currentRequest,
            },
        },
    };
};

export const UPDATE_PROJECT_BOX_AND_TREE = 'UPDATE_PROJECT_BOX_AND_TREE';
export const UPDATE_PROJECT_BOX_AND_TREE_ERROR = 'UPDATE_PROJECT_BOX_AND_TREE_ERROR';
export const UPDATE_PROJECT_BOX_AND_TREE_SUCCESS = 'UPDATE_PROJECT_BOX_AND_TREE_SUCCESS';

export const updateProjectBoxAndTree = payload => {
    const { controller, currentRequest } = requestController(activeRequestControllers, `${payload.project_id}/box/${payload.box.id}`);

    return {
        type: UPDATE_PROJECT_BOX_AND_TREE,
        payload,
        meta: {
            request: {
                url: `/api/project/${payload.project_id}/box/${payload.box.id}/`,
                method: 'PUT',
                body: JSON.stringify(payload.box),
                signal: controller.signal,
                currentRequest,
            },
        },
    };
};


export const CREATE_PROJECT_BOX = 'CREATE_PROJECT_BOX';
export const CREATE_PROJECT_BOX_ERROR = 'CREATE_PROJECT_BOX_ERROR';
export const CREATE_PROJECT_BOX_SUCCESS = 'CREATE_PROJECT_BOX_SUCCESS';

export const createProjectBox = payload => ({
    type: CREATE_PROJECT_BOX,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/box/`,
            method: 'POST',
            body: JSON.stringify(payload.box),
        },
    },
});


export const CLONE_PROJECT_BOX = 'CLONE_PROJECT_BOX';
export const CLONE_PROJECT_BOX_ERROR = 'CLONE_PROJECT_BOX_ERROR';
export const CLONE_PROJECT_BOX_SUCCESS = 'CLONE_PROJECT_BOX_SUCCESS';

export const cloneProjectBox = payload => ({
    type: CLONE_PROJECT_BOX,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/box/${payload.box.id}/clone/`,
            method: 'POST',
            body: JSON.stringify(payload.box),
        },
    },
});


export const DELETE_PROJECT_BOX = 'DELETE_PROJECT_BOX';
export const DELETE_PROJECT_BOX_ERROR = 'DELETE_PROJECT_BOX_ERROR';
export const DELETE_PROJECT_BOX_SUCCESS = 'DELETE_PROJECT_BOX_SUCCESS';

export const deleteProjectBox = payload => ({
    type: DELETE_PROJECT_BOX,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/box/${payload.box.id}/`,
            method: 'DELETE',
        },
    },
});


// export const SET_BOX_VALIDATION_ERRORS = 'SET_BOX_VALIDATION_ERRORS';

// export const setValidationErrors = createAction(SET_BOX_VALIDATION_ERRORS);


export const SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS';
export const setValidationErrors = createAction(SET_VALIDATION_ERRORS);

export const LOAD_PROJECT_SOLUTION = 'LOAD_PROJECT_SOLUTION';
export const LOAD_PROJECT_SOLUTION_ERROR = 'LOAD_PROJECT_SOLUTION_ERROR';
export const LOAD_PROJECT_SOLUTION_SUCCESS = 'LOAD_PROJECT_SOLUTION_SUCCESS';

export const loadProjectSolution = payload => ({
    type: LOAD_PROJECT_SOLUTION,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/solution/?_=` + Date.now(),
        },
    },
});


export const LOAD_PROJECT_TRANSIENT = 'LOAD_PROJECT_TRANSIENT';
export const LOAD_PROJECT_TRANSIENT_ERROR = 'LOAD_PROJECT_TRANSIENT_ERROR';
export const LOAD_PROJECT_TRANSIENT_SUCCESS = 'LOAD_PROJECT_TRANSIENT_SUCCESS';

export const loadProjectTransient = payload => {
    const {
        controller, currentRequest
    } = requestController(activeRequestControllers, `${payload.project_id}/transient`);
    return {
        type: LOAD_PROJECT_TRANSIENT,
        payload,
        meta: {
            request: {
                url: `/api/project/${payload.project_id}/transient/?_=` + Date.now(),
                signal: controller.signal,
                currentRequest,
            },
        }
    }
};

export const LOAD_PROJECT_TRANSIENT_SOLUTION = 'LOAD_PROJECT_TRANSIENT_SOLUTION';
export const LOAD_PROJECT_TRANSIENT_SOLUTION_ERROR = 'LOAD_PROJECT_TRANSIENT_SOLUTION_ERROR';
export const LOAD_PROJECT_TRANSIENT_SOLUTION_SUCCESS = 'LOAD_PROJECT_TRANSIENT_SOLUTION_SUCCESS';

export const loadProjectTransientSolution = payload => {
    const {
        controller, currentRequest
    } = requestController(activeRequestControllers, `${payload.project_id}/transient/${payload.solution_id}`);
    return {
        type: LOAD_PROJECT_TRANSIENT_SOLUTION,
        payload,
        meta: {
            request: {
                url: `/api/project/${payload.project_id}/transient/${payload.solution_id}/?_=` + Date.now(),
                signal: controller.signal,
                currentRequest,
            },
        }
    }
};

export const LOAD_PROJECT_OBJECT_TRANSIENT = 'LOAD_PROJECT_OBJECT_TRANSIENT';
export const LOAD_PROJECT_OBJECT_TRANSIENT_ERROR = 'LOAD_PROJECT_OBJECT_TRANSIENT_ERROR';
export const LOAD_PROJECT_OBJECT_TRANSIENT_SUCCESS = 'LOAD_PROJECT_OBJECT_TRANSIENT_SUCCESS';

export const loadProjectObjectTransient = payload => {
    const {
        controller, currentRequest
    } = requestController(activeRequestControllers, `${payload.project_id}/transient/${payload.id}/${payload.type_id}`);
    return {
        type: LOAD_PROJECT_OBJECT_TRANSIENT,
        payload,
        meta: {
            request: {
                url: `/api/project/${payload.project_id}/transient/${payload.id}/${payload.type_id}/?_=` + Date.now(),
                signal: controller.signal,
                currentRequest,
            },
        }
    }
};


export const LOAD_PROJECT_CASE_SOLUTION = 'LOAD_PROJECT_CASE_SOLUTION';
export const LOAD_PROJECT_CASE_SOLUTION_ERROR = 'LOAD_PROJECT_CASE_SOLUTION_ERROR';
export const LOAD_PROJECT_CASE_SOLUTION_SUCCESS = 'LOAD_PROJECT_CASE_SOLUTION_SUCCESS';

export const loadProjectScenarioCaseSolution = payload => ({
    type: LOAD_PROJECT_CASE_SOLUTION,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/scenario/${payload.scenario.id}/case/${payload.case.id}/solution/?_=` + Date.now(),
        },
    },
});


export const LOAD_PLANE_SOLUTIONS = 'LOAD_PLANE_SOLUTIONS';
export const LOAD_PLANE_SOLUTIONS_ERROR = 'LOAD_PLANE_SOLUTIONS_ERROR';
export const LOAD_PLANE_SOLUTIONS_SUCCESS = 'LOAD_PLANE_SOLUTIONS_SUCCESS';

export const loadPlaneSolutions = payload => ({
    type: LOAD_PLANE_SOLUTIONS,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/plane/solution/`,
        },
    },
});

export const STORE_CLIP_FACE_DATA = 'STORE_CLIP_FACE_DATA';

export const storeClipFaceData = payload => {
    return {
        type: STORE_CLIP_FACE_DATA,
        payload,
    };
};

export const LOAD_CLIP_FACE_SOLUTION_X0 = 'LOAD_CLIP_FACE_SOLUTION_X0';
export const LOAD_CLIP_FACE_SOLUTION_X0_ERROR = 'LOAD_CLIP_FACE_SOLUTION_X0_ERROR';
export const LOAD_CLIP_FACE_SOLUTION_X0_SUCCESS = 'LOAD_CLIP_FACE_SOLUTION_X0_SUCCESS';

export const loadClipFaceSolution_x0 = payload => {
    const {
        controller, currentRequest
    } = requestController(activeRequestControllers, `${payload.project_id}_${payload.plane}_0`);

    return {
        type: LOAD_CLIP_FACE_SOLUTION_X0,
        payload,
        meta: {
            request: {
                url: `/api/project/${payload.project_id}/solution/plane/?plane=${payload.plane}&coordinate=${payload.coordinate}`,
                signal: controller.signal,
                currentRequest,
            },
        },

    };
};

export const LOAD_CLIP_FACE_SOLUTION_X1 = 'LOAD_CLIP_FACE_SOLUTION_X1';
export const LOAD_CLIP_FACE_SOLUTION_X1_ERROR = 'LOAD_CLIP_FACE_SOLUTION_X1_ERROR';
export const LOAD_CLIP_FACE_SOLUTION_X1_SUCCESS = 'LOAD_CLIP_FACE_SOLUTION_X1_SUCCESS';

export const loadClipFaceSolution_x1 = payload => {
    const {
        controller, currentRequest
    } = requestController(activeRequestControllers, `${payload.project_id}_${payload.plane}_1`);

    return {
        type: LOAD_CLIP_FACE_SOLUTION_X1,
        payload,
        meta: {
            request: {
                url: `/api/project/${payload.project_id}/solution/plane/?plane=${payload.plane}&coordinate=${payload.coordinate}`,
                signal: controller.signal,
                currentRequest,
            },
        },

    };
};

export const LOAD_CLIP_FACE_SOLUTION_Y0 = 'LOAD_CLIP_FACE_SOLUTION_Y0';
export const LOAD_CLIP_FACE_SOLUTION_Y0_ERROR = 'LOAD_CLIP_FACE_SOLUTION_Y0_ERROR';
export const LOAD_CLIP_FACE_SOLUTION_Y0_SUCCESS = 'LOAD_CLIP_FACE_SOLUTION_Y0_SUCCESS';

export const loadClipFaceSolution_y0 = payload => {
    const {
        controller, currentRequest
    } = requestController(activeRequestControllers, `${payload.project_id}_${payload.plane}_0`);

    return {
        type: LOAD_CLIP_FACE_SOLUTION_Y0,
        payload,
        meta: {
            request: {
                url: `/api/project/${payload.project_id}/solution/plane/?plane=${payload.plane}&coordinate=${payload.coordinate}`,
                signal: controller.signal,
                currentRequest,
            },
        },

    };
};

export const LOAD_CLIP_FACE_SOLUTION_Y1 = 'LOAD_CLIP_FACE_SOLUTION_Y1';
export const LOAD_CLIP_FACE_SOLUTION_Y1_ERROR = 'LOAD_CLIP_FACE_SOLUTION_Y1_ERROR';
export const LOAD_CLIP_FACE_SOLUTION_Y1_SUCCESS = 'LOAD_CLIP_FACE_SOLUTION_Y1_SUCCESS';

export const loadClipFaceSolution_y1 = payload => {
    const {
        controller, currentRequest
    } = requestController(activeRequestControllers, `${payload.project_id}_${payload.plane}_1`);

    return {
        type: LOAD_CLIP_FACE_SOLUTION_Y1,
        payload,
        meta: {
            request: {
                url: `/api/project/${payload.project_id}/solution/plane/?plane=${payload.plane}&coordinate=${payload.coordinate}`,
                signal: controller.signal,
                currentRequest,
            },
        },

    };
};

export const LOAD_CLIP_FACE_SOLUTION_Z0 = 'LOAD_CLIP_FACE_SOLUTION_Z0';
export const LOAD_CLIP_FACE_SOLUTION_Z0_ERROR = 'LOAD_CLIP_FACE_SOLUTION_Z0_ERROR';
export const LOAD_CLIP_FACE_SOLUTION_Z0_SUCCESS = 'LOAD_CLIP_FACE_SOLUTION_Z0_SUCCESS';

export const loadClipFaceSolution_z0 = payload => {
    const {
        controller, currentRequest
    } = requestController(activeRequestControllers, `${payload.project_id}_${payload.plane}_0`);

    return {
        type: LOAD_CLIP_FACE_SOLUTION_Z0,
        payload,
        meta: {
            request: {
                url: `/api/project/${payload.project_id}/solution/plane/?plane=${payload.plane}&coordinate=${payload.coordinate}`,
                signal: controller.signal,
                currentRequest,
            },
        },

    };
};

export const LOAD_CLIP_FACE_SOLUTION_Z1 = 'LOAD_CLIP_FACE_SOLUTION_Z1';
export const LOAD_CLIP_FACE_SOLUTION_Z1_ERROR = 'LOAD_CLIP_FACE_SOLUTION_Z1_ERROR';
export const LOAD_CLIP_FACE_SOLUTION_Z1_SUCCESS = 'LOAD_CLIP_FACE_SOLUTION_Z1_SUCCESS';

export const loadClipFaceSolution_z1 = payload => {
    const {
        controller, currentRequest
    } = requestController(activeRequestControllers, `${payload.project_id}_${payload.plane}_1`);

    return {
        type: LOAD_CLIP_FACE_SOLUTION_Z1,
        payload,
        meta: {
            request: {
                url: `/api/project/${payload.project_id}/solution/plane/?plane=${payload.plane}&coordinate=${payload.coordinate}`,
                signal: controller.signal,
                currentRequest,
            },
        },

    };
};

export const LOAD_CLIP_FACE_SOLUTION_TIME_POINT_X0 = 'LOAD_CLIP_FACE_SOLUTION_TIME_POINT_X0';
export const LOAD_CLIP_FACE_SOLUTION_TIME_POINT_X0_ERROR = 'LOAD_CLIP_FACE_SOLUTION_TIME_POINT_X0_ERROR';
export const LOAD_CLIP_FACE_SOLUTION_TIME_POINT_X0_SUCCESS = 'LOAD_CLIP_FACE_SOLUTION_TIME_POINT_X0_SUCCESS';

export const loadClipFaceSolutionTimePoint_x0 = payload => {
    const {
        controller, currentRequest
    } = requestController(activeRequestControllers, `${payload.project_id}_${payload.solution_id}_${payload.plane}_0`);

    return {
        type: LOAD_CLIP_FACE_SOLUTION_TIME_POINT_X0,
        payload,
        meta: {
            request: {
                url: `/api/project/${payload.project_id}/transient/${payload.solution_id}/plane/?plane=${payload.plane}&coordinate=${payload.coordinate}`,
                signal: controller.signal,
                currentRequest,
            },
        },

    };
};

export const LOAD_CLIP_FACE_SOLUTION_TIME_POINT_X1 = 'LOAD_CLIP_FACE_SOLUTION_TIME_POINT_X1';
export const LOAD_CLIP_FACE_SOLUTION_TIME_POINT_X1_ERROR = 'LOAD_CLIP_FACE_SOLUTION_TIME_POINT_X1_ERROR';
export const LOAD_CLIP_FACE_SOLUTION_TIME_POINT_X1_SUCCESS = 'LOAD_CLIP_FACE_SOLUTION_TIME_POINT_X1_SUCCESS';

export const loadClipFaceSolutionTimePoint_x1 = payload => {
    const {
        controller, currentRequest
    } = requestController(activeRequestControllers, `${payload.project_id}_${payload.solution_id}_${payload.plane}_1`);

    return {
        type: LOAD_CLIP_FACE_SOLUTION_TIME_POINT_X1,
        payload,
        meta: {
            request: {
                url: `/api/project/${payload.project_id}/transient/${payload.solution_id}/plane/?plane=${payload.plane}&coordinate=${payload.coordinate}`,
                signal: controller.signal,
                currentRequest,
            },
        },

    };
};

export const LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Y0 = 'LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Y0';
export const LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Y0_ERROR = 'LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Y0_ERROR';
export const LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Y0_SUCCESS = 'LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Y0_SUCCESS';

export const loadClipFaceSolutionTimePoint_y0 = payload => {
    const {
        controller, currentRequest
    } = requestController(activeRequestControllers, `${payload.project_id}_${payload.solution_id}_${payload.plane}_0`);

    return {
        type: LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Y0,
        payload,
        meta: {
            request: {
                url: `/api/project/${payload.project_id}/transient/${payload.solution_id}/plane/?plane=${payload.plane}&coordinate=${payload.coordinate}`,
                signal: controller.signal,
                currentRequest,
            },
        },

    };
};

export const LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Y1 = 'LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Y1';
export const LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Y1_ERROR = 'LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Y1_ERROR';
export const LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Y1_SUCCESS = 'LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Y1_SUCCESS';

export const loadClipFaceSolutionTimePoint_y1 = payload => {
    const {
        controller, currentRequest
    } = requestController(activeRequestControllers, `${payload.project_id}_${payload.solution_id}_${payload.plane}_1`);

    return {
        type: LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Y1,
        payload,
        meta: {
            request: {
                url: `/api/project/${payload.project_id}/transient/${payload.solution_id}/plane/?plane=${payload.plane}&coordinate=${payload.coordinate}`,
                signal: controller.signal,
                currentRequest,
            },
        },

    };
};

export const LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Z0 = 'LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Z0';
export const LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Z0_ERROR = 'LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Z0_ERROR';
export const LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Z0_SUCCESS = 'LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Z0_SUCCESS';

export const loadClipFaceSolutionTimePoint_z0 = payload => {
    const {
        controller, currentRequest
    } = requestController(activeRequestControllers, `${payload.project_id}_${payload.solution_id}_${payload.plane}_0`);

    return {
        type: LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Z0,
        payload,
        meta: {
            request: {
                url: `/api/project/${payload.project_id}/transient/${payload.solution_id}/plane/?plane=${payload.plane}&coordinate=${payload.coordinate}`,
                signal: controller.signal,
                currentRequest,
            },
        },

    };
};

export const LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Z1 = 'LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Z1';
export const LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Z1_ERROR = 'LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Z1_ERROR';
export const LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Z1_SUCCESS = 'LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Z1_SUCCESS';

export const loadClipFaceSolutionTimePoint_z1 = payload => {
    const {
        controller, currentRequest
    } = requestController(activeRequestControllers, `${payload.project_id}_${payload.solution_id}_${payload.plane}_1`);

    return {
        type: LOAD_CLIP_FACE_SOLUTION_TIME_POINT_Z1,
        payload,
        meta: {
            request: {
                url: `/api/project/${payload.project_id}/transient/${payload.solution_id}/plane/?plane=${payload.plane}&coordinate=${payload.coordinate}`,
                signal: controller.signal,
                currentRequest,
            },
        },

    };
};

export const CLEAR_CLIP_FACE_SOLUTIONS = 'CLEAR_CLIP_FACE_SOLUTIONS';
export const CLEAR_CLIP_FACE_SOLUTIONS_ERROR = 'CLEAR_CLIP_FACE_SOLUTIONS_ERROR';
export const CLEAR_CLIP_FACE_SOLUTIONS_SUCCESS = 'CLEAR_CLIP_FACE_SOLUTIONS_SUCCESS';

export const clearClipFaceSolutions = payload => ({
    type: CLEAR_CLIP_FACE_SOLUTIONS,
});

export const LOAD_PROJECT_CASE_PLANE_SOLUTIONS = 'LOAD_PROJECT_CASE_PLANE_SOLUTIONS';
export const LOAD_PROJECT_CASE_PLANE_SOLUTIONS_ERROR = 'LOAD_PROJECT_CASE_PLANE_SOLUTIONS_ERROR';
export const LOAD_PROJECT_CASE_PLANE_SOLUTIONS_SUCCESS = 'LOAD_PROJECT_CASE_PLANE_SOLUTIONS_SUCCESS';

export const loadProjectScenarioCasePlaneSolutions = payload => ({
    type: LOAD_PROJECT_CASE_PLANE_SOLUTIONS,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/scenario/${payload.scenario.id}/case/${payload.case.id}/plane_solution/?_=` + Date.now(),
        },
    },
});


export const CLEAR_PROJECT_SOLUTION = 'CLEAR_PROJECT_SOLUTION';

export const clearProjectSolution = createAction(CLEAR_PROJECT_SOLUTION);


export const CREATE_PROJECT_ASSEMBLY = 'CREATE_PROJECT_ASSEMBLY';
export const CREATE_PROJECT_ASSEMBLY_ERROR = 'CREATE_PROJECT_ASSEMBLY_ERROR';
export const CREATE_PROJECT_ASSEMBLY_SUCCESS = 'CREATE_PROJECT_ASSEMBLY_SUCCESS';

export const createAssembly = payload => ({
    type: CREATE_PROJECT_ASSEMBLY,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/assembly/`,
            method: 'POST',
            body: JSON.stringify(payload.assembly),
        },
    },
});


export const CLONE_PROJECT_ASSEMBLY = 'CLONE_PROJECT_ASSEMBLY';
export const CLONE_PROJECT_ASSEMBLY_ERROR = 'CLONE_PROJECT_ASSEMBLY_ERROR';
export const CLONE_PROJECT_ASSEMBLY_SUCCESS = 'CLONE_PROJECT_ASSEMBLY_SUCCESS';

export const cloneAssembly = payload => ({
    type: CLONE_PROJECT_ASSEMBLY,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/assembly/${payload.box.id}/clone/`,
            method: 'POST',
        },
    },
});


export const UPDATE_PROJECT_ASSEMBLY = 'UPDATE_PROJECT_ASSEMBLY';
export const UPDATE_PROJECT_ASSEMBLY_ERROR = 'UPDATE_PROJECT_ASSEMBLY_ERROR';
export const UPDATE_PROJECT_ASSEMBLY_SUCCESS = 'UPDATE_PROJECT_ASSEMBLY_SUCCESS';

export const updateProjectAssembly = payload => {
    const { controller, currentRequest } = requestController(activeRequestControllers, payload.assembly.id);

    return {
        type: UPDATE_PROJECT_ASSEMBLY,
        payload,
        meta: {
            request: {
                url: `/api/project/${payload.project_id}/assembly/${payload.assembly.id}/`,
                method: 'PUT',
                body: JSON.stringify(payload.assembly),
                signal: controller.signal,
                currentRequest,
            },
        },
    };
};


export const TOGGLE_PROJECT_TABLE = 'TOGGLE_PROJECT_TABLE';

export const toggleProjectTable = payload => {
    return {
        type: TOGGLE_PROJECT_TABLE,
        payload,
    };
};


export const TRANSLATE_PROJECT_ASSEMBLY = 'TRANSLATE_PROJECT_ASSEMBLY';
export const TRANSLATE_PROJECT_ASSEMBLY_ERROR = 'TRANSLATE_PROJECT_ASSEMBLY_ERROR';
export const TRANSLATE_PROJECT_ASSEMBLY_SUCCESS = 'TRANSLATE_PROJECT_ASSEMBLY_SUCCESS';

export const translateProjectAssembly = payload => {
    const { controller, currentRequest } = requestController(activeRequestControllers, payload.assembly_id + 'translate');

    return {
        type: TRANSLATE_PROJECT_ASSEMBLY,
        payload,
        meta: {
            request: {
                url: `/api/project/${payload.project_id}/assembly/${payload.assembly_id}/translate/`,
                method: 'POST',
                body: JSON.stringify(payload.translate),
                signal: controller.signal,
                currentRequest,
            },
        },
    };
};


export const UPDATE_PROJECT_TREE = 'UPDATE_PROJECT_TREE';
export const UPDATE_PROJECT_TREE_ERROR = 'UPDATE_PROJECT_TREE_ERROR';
export const UPDATE_PROJECT_TREE_SUCCESS = 'UPDATE_PROJECT_TREE_SUCCESS';

export const updateProjectTree = payload => ({
    type: UPDATE_PROJECT_TREE,
    payload,
});


export const UPDATE_PROJECT_ASSEMBLY_AND_TREE = 'UPDATE_PROJECT_ASSEMBLY_AND_TREE';
export const UPDATE_PROJECT_ASSEMBLY_AND_TREE_ERROR = 'UPDATE_PROJECT_ASSEMBLY_AND_TREE_ERROR';
export const UPDATE_PROJECT_ASSEMBLY_AND_TREE_SUCCESS = 'UPDATE_PROJECT_ASSEMBLY_AND_TREE_SUCCESS';

export const updateProjectAssemblyAndTree = payload => {
    const { controller, currentRequest } = requestController(activeRequestControllers, payload.assembly.id);

    return {
        type: UPDATE_PROJECT_ASSEMBLY_AND_TREE,
        payload,
        meta: {
            request: {
                url: `/api/project/${payload.project_id}/assembly/${payload.assembly.id}/`,
                method: 'PUT',
                body: JSON.stringify(payload.assembly),
                signal: controller.signal,
                currentRequest,
            },
        },
    };
};

export const UPDATE_PROJECT_UI = 'UPDATE_PROJECT_UI';

export const toggleUpdateProjectUI = () => {
    return {
        type: UPDATE_PROJECT_UI,
    };
};


export const DELETE_PROJECT_ASSEMBLY = 'DELETE_PROJECT_ASSEMBLY';
export const DELETE_PROJECT_ASSEMBLY_ERROR = 'DELETE_PROJECT_ASSEMBLY_ERROR';
export const DELETE_PROJECT_ASSEMBLY_SUCCESS = 'DELETE_PROJECT_ASSEMBLY_SUCCESS';

export const deleteProjectAssembly = payload => ({
    type: DELETE_PROJECT_ASSEMBLY,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/assembly/${payload.box.id}/`,
            method: 'DELETE',
        },
    },
});


export const SOLVE_PROJECT = 'SOLVE_PROJECT';
export const SOLVE_PROJECT_ERROR = 'SOLVE_PROJECT_ERROR';
export const SOLVE_PROJECT_SUCCESS = 'SOLVE_PROJECT_SUCCESS';

export const solveProject = payload => ({
    type: SOLVE_PROJECT,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/solve/?transient=${payload.transient || 0}&_=` + Date.now(),
        },
    },
});

export const LOAD_PROJECT_TASK = 'LOAD_PROJECT_TASK';
export const LOAD_PROJECT_TASK_ERROR = 'LOAD_PROJECT_TASK_ERROR';
export const LOAD_PROJECT_TASK_SUCCESS = 'LOAD_PROJECT_TASK_SUCCESS';

export const loadProjectTask = payload => ({
    type: LOAD_PROJECT_TASK,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/task/?_=` + Date.now(),
        },
    },
});

export const TASK_STATUS = 'TASK_STATUS';
export const TASK_STATUS_ERROR = 'TASK_STATUS_ERROR';
export const TASK_STATUS_SUCCESS = 'TASK_STATUS_SUCCESS';

export const loadTaskStatus = payload => ({
    type: TASK_STATUS,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/task/${payload.task_id}/?_=` + Date.now(),
        },
    },
});

export const STOP_TASK = 'STOP_TASK';
export const STOP_TASK_ERROR = 'STOP_TASK_ERROR';
export const STOP_TASK_SUCCESS = 'STOP_TASK_SUCCESS';

export const stopTask = payload => ({
    type: STOP_TASK,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/task/${payload.task_id}/`,
            method: 'DELETE',
        },
    },
});

export const DOWNLOAD_PROJECT_STEP = 'DOWNLOAD_PROJECT_STEP';
export const DOWNLOAD_PROJECT_STEP_ERROR = 'DOWNLOAD_PROJECT_STEP_ERROR';
export const DOWNLOAD_PROJECT_STEP_SUCCESS = 'DOWNLOAD_PROJECT_STEP_SUCCESS';

export const downloadProjectStep = payload => ({
    type: DOWNLOAD_PROJECT_STEP,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/step/`,
            method: 'GET',
        },
    },
});

export const CLEAR_DOWNLOAD_PROJECT_STEP = 'CLEAR_DOWNLOAD_PROJECT_STEP';
export const CLEAR_DOWNLOAD_PROJECT_STEP_ERROR = 'CLEAR_DOWNLOAD_PROJECT_STEP_ERROR';
export const CLEAR_DOWNLOAD_PROJECT_STEP_SUCCESS = 'CLEAR_DOWNLOAD_PROJECT_STEP_SUCCESS';

export const clearDownloadProjectStep = payload => ({
    type: CLEAR_DOWNLOAD_PROJECT_STEP,
    payload,
});


export const SET_SELECTED_OBJ = 'SET_SELECTED_OBJ';

export const setSelectedObject = (obj = {}) => ({
    type: SET_SELECTED_OBJ,
    payload: obj,
});

export const UPDATE_SELECTED_OBJECT = 'UPDATE_SELECTED_OBJECT';

export const updateSelectedObject = (update = false) => ({
    type: UPDATE_SELECTED_OBJECT,
    payload: update,
});

export const FORCE_UPDATE_SELECTED_OBJECT = 'FORCE_UPDATE_SELECTED_OBJECT';

export const forceUpdateSelectedObjectAction = (update = false) => ({
    type: FORCE_UPDATE_SELECTED_OBJECT,
    payload: update,
});


export const UPDATE_PROJECT_OBJECT_POSITION = 'UPDATE_PROJECT_OBJECT_POSITION';

export const updateProjectObjectPosition = ({ project_id, box, type }) => {
    return {
        type: UPDATE_PROJECT_OBJECT_POSITION,
        payload: { box },
        meta: {
            request: {
                url: `/api/project/${project_id}/${type}/${box.id}/`,
                method: 'PUT',
                body: JSON.stringify(box),
            },
        },
    };
};