/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {
    CREATE_PROJECT_MATERIAL_SUCCESS,
    DELETE_PROJECT_MATERIAL_SUCCESS,
    FIT_OBJECT, FIT_ALL,
    LOAD_PROJECT,
    LOAD_PROJECT_ERROR, LOAD_PROJECT_LOGS, LOAD_PROJECT_LOGS_ERROR, LOAD_PROJECT_LOGS_SUCCESS,
    LOAD_PROJECT_MATERIAL_SUCCESS,
    LOAD_PROJECT_PERMS,
    LOAD_PROJECT_PERMS_SUCCESS,
    LOAD_PROJECT_SUCCESS,
    SET_PROJECT_MODE,
    START_DRAG, TOGGLE_OBJECT_CLIPPING, UNDO_PROJECT, UNDO_PROJECT_ERROR, UNDO_PROJECT_SUCCESS,
    UPDATE_PROJECT_MATERIAL_SUCCESS,
    UPDATE_PROJECT_PERMS_SUCCESS,
    UPDATE_PROJECT_THUMBNAIL, UPDATE_PROJECT_THUMBNAIL_SUCCESS,
    UPDATE_THUMBNAIL,
    SET_GRID_SNAP, SET_AXES_LOCK, LOAD_PROJECT_ADJACENCY_SUCCESS, LOAD_PROJECT_ADJACENCY, LOAD_PROJECT_ADJACENCY_ERROR,
} from '../actions/project';

import {
    UPDATE_PROJECT_AND_TREE_SUCCESS,
    UPDATE_PROJECT_SUCCESS,
} from '../../../dashboard/actions';
import {
    CLEAR_DOWNLOAD_PROJECT_STEP,
    DOWNLOAD_PROJECT_STEP,
    DOWNLOAD_PROJECT_STEP_ERROR,
    DOWNLOAD_PROJECT_STEP_SUCCESS
} from "../actions/actions";

export const defaultState = {
    data: null,
    loading: false,
    adjacency: null,
    loading_adjacency: false,
    fit_object: null,
    fit_all: false,
    mode: 'box',
    permissions: [],
    drag: null,
    materials: [],
    log: [],
    logs_loading: false,
    logs_last_load: null,
    updateThumbnail: false,
    clipping: false,
    undoing: false,
    axesLock: {
        xy: false,
        xz: false,
        yz: false,
    },
    gridSnap: 0.001,
    stepData: null,
    downloadingStepData: false,
};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case LOAD_PROJECT:
            return {
                ...state,
                mode: action.payload.mode || 'box',
                loading: true,
            };
        case LOAD_PROJECT_ADJACENCY: {
            return {
                ...state,
                loading_adjacency: true,
            };
        }
        case FIT_OBJECT:
            return {
                ...state,
                fit_object: action.payload,
            };
        case FIT_ALL:
            return {
                ...state,
                fit_all: !state.fit_all,
            };
        case LOAD_PROJECT_ERROR:
            return {
                data: {},
                loading: false,
            };
        case LOAD_PROJECT_SUCCESS:
        case UPDATE_PROJECT_SUCCESS:
        case UPDATE_PROJECT_AND_TREE_SUCCESS:
            const resetLog = state.data && state.data.id !== action.payload.id;
            return {
                ...state,
                loading: false,
                data: action.payload,
                log: resetLog ? [] : state.log,
                logs_last_load: resetLog ? null : state.logs_last_load,
            };
        case LOAD_PROJECT_ADJACENCY_SUCCESS:
            return {
                ...state,
                adjacency: action.payload,
                loading_adjacency: false,
            };
        case LOAD_PROJECT_ADJACENCY_ERROR:
            return {
                ...state,
                adjacency: null,
                loading_adjacency: false,
            };
        case SET_PROJECT_MODE:
            return {
                ...state,
                mode: action.payload.mode,
            };
        case LOAD_PROJECT_PERMS:
            return {
                ...state,
                permissions: [],
            };
        case LOAD_PROJECT_PERMS_SUCCESS:
            return {
                ...state,
                permissions: action.payload,
            };
        case UPDATE_PROJECT_PERMS_SUCCESS:
            return {
                ...state,
                validation_errors: [],
            };
        case START_DRAG:
            return {
                ...state,
                drag: action.payload,
            };
        case LOAD_PROJECT_MATERIAL_SUCCESS:
            return {
                ...state,
                materials: action.payload,
            };
        case CREATE_PROJECT_MATERIAL_SUCCESS:
            return {
                ...state,
                materials: state.materials.concat(action.payload),
            };
        case DELETE_PROJECT_MATERIAL_SUCCESS:
            return {
                ...state,
                materials: state.materials.filter(mat => mat.id !== action.payload.materialid),
            };
        case UPDATE_PROJECT_MATERIAL_SUCCESS:
            return {
                ...state,
                materials: state.materials.map(x => {
                    if (x.id === action.payload.id)
                        return action.payload;
                    return x;
                }),
            };
        case LOAD_PROJECT_LOGS:
            return {
                ...state,
                logs_loading: true,
            };
        case LOAD_PROJECT_LOGS_SUCCESS:
            return {
                ...state,
                log: action.payload,
                logs_loading: false,
                logs_last_load: new Date(),
            };
        case LOAD_PROJECT_LOGS_ERROR:
            return {
                ...state,
                logs_loading: false,
            };
        case UPDATE_THUMBNAIL:
        case UPDATE_PROJECT_THUMBNAIL:
            return {
                ...state,
                updateThumbnail: true,
            };
        case UPDATE_PROJECT_THUMBNAIL_SUCCESS:
            return {
                ...state,
                updateThumbnail: false,
            };
        case TOGGLE_OBJECT_CLIPPING:
            return {
                ...state,
                clipping: !state.clipping,
            };
        case UNDO_PROJECT:
            return {
                ...state,
                undoing: true,
            };
        case UNDO_PROJECT_ERROR:
        case UNDO_PROJECT_SUCCESS:
            return {
                ...state,
                undoing: false,
            };
        case SET_GRID_SNAP:
            return {
                ...state,
                gridSnap: action.payload,
            };
        case SET_AXES_LOCK:
            return {
                ...state,
                axesLock: {
                    ...state.axesLock,
                    [action.payload]: !state.axesLock[action.payload],
                },
            };
        case DOWNLOAD_PROJECT_STEP:
            return {
                ...state,
                downloadingStepData: true,
            }
        case DOWNLOAD_PROJECT_STEP_SUCCESS:
            return {
                ...state,
                stepData: action.payload.step || '',
                downloadingStepData: false,
            };
        case DOWNLOAD_PROJECT_STEP_ERROR:
            return {
                ...state,
                downloadingStepData: false,
            }
        case CLEAR_DOWNLOAD_PROJECT_STEP:
            return {
                ...state,
                stepData: null,
                downloadingStepData: false,
            };
        default:
            return state;
    }
};

export const hasData = ({current_project}) => !!current_project.properties.data;

export const getData = ({current_project}) => current_project.properties.data;

export const getAdjacency = ({current_project}) => current_project.properties.adjacency;

export const isLoading = ({current_project}) => current_project.properties.loading;
export const isLoadingAdjacency = ({current_project}) => current_project.properties.loading_adjacency;

export const fitObject = ({current_project}) => current_project.properties.fit_object;

export const getFitAll = ({current_project}) => current_project.properties.fit_all;

export const getClipping = ({current_project}) => current_project.properties.clipping;

export const getProjectMode = ({current_project}) => current_project.properties.mode;

export const getGridSnap = ({current_project}) => current_project.properties.gridSnap;

export const getAxesLock = ({current_project}) => current_project.properties.axesLock;

export const getProjectPermissions = ({current_project}) => current_project.properties.permissions;

export const getProjectDrag = ({current_project}) => current_project.properties.drag;

export const getProjectMaterials = ({current_project}) => current_project.properties.materials;

export const getProjectLog = ({current_project}) => current_project.properties.log;

export const isLoadingLogs = ({current_project}) => current_project.properties.logs_loading;

export const lastLogLoadedDate = ({current_project}) => current_project.properties.logs_last_load;

export const getProjectStepData = ({current_project}) => current_project.properties.stepData;
export const isDownloadingStepData = ({current_project}) => current_project.properties.downloadingStepData;
