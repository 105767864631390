/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {FormControl, MenuItem, Tooltip} from "@mui/material";
import React from "react";
import {SwitchToggle} from "../../../../components/SwitchToggle";
import {InfoOutlined} from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const renderMaterialListSelectOptions = (materials) => {
    return materials.sort((a, b) => {
        if (a.name < b.name) return -1; else if (a.name > b.name) return 1;
        return 0;
    }).map(({id, name, library_name}) => (<MenuItem key={id} value={id}>
        {library_name ? `${name} (${library_name})` : name}
    </MenuItem>))
}


export const BindToAmbientCheckbox = ({handleChange, state}) => <>
    <FormControl>
        <SwitchToggle
            id={'bindcheckbox'}
            booleans={true}
            onChange={() => handleChange('bound', !state.bound)}
            checked={!!state.bound}
            title={'Bind to ambient'}
            orientation={'vertical'}
        />
    </FormControl>
    <Tooltip title={<span>
           Forces all exposed object walls to ambient temperature.
        </span>}>
        <InfoOutlined fontSize={'inherit'}/>
    </Tooltip>
</>

export const LumpedModelCheckbox = ({state, handleChange}) => <>
    <FormControl>
        <SwitchToggle
            id={'lumpedmodel'}
            booleans={true}
            onChange={() => {
                handleChange('lumped', state.hasOwnProperty('fill_material') && !state.fill_material && !state.lumped || !state.lumped)
            }}
            checked={!!state.fill_material && (state.xcount * state.ycount > 1e5 || !!state.lumped)}
            title={'Quick Model'}
            orientation={'vertical'}
        />
    </FormControl>
    <Tooltip title={
        <span>
            Use a lumped model instead of detailed Ball Array objects. This option is valid only when a Fill material has been specified.
            <br/>
            Quick Model will use approximated material properties in all 3 axes and will be created at a maximum resolution of 500&micro;m.
            <br/>
            NOTE: Quick Model will be automatically used if ball count exceeds 100K.
        </span>}>
        <InfoOutlinedIcon fontSize={'inherit'}/>
    </Tooltip>
</>

export const HoleCheckbox = ({handleChange, state}) => <>
    <FormControl fullWidth>
        <SwitchToggle
            booleans={true}
            onChange={() => handleChange('hole', !state.hole)}
            checked={!!state.hole}
            title={'Hole'}
            orientation={'vertical'}
        />
    </FormControl>
</>
