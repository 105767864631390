/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

export const requestController = (activeRequestControllers, controllerId) => {
    const controller = new window.AbortController();
    let currentRequest = activeRequestControllers[controllerId];
    if (currentRequest) {
        currentRequest.aborted = true;
        currentRequest.controller.abort();
    }
    currentRequest = {
        controller,
        aborted: false,
    };
    activeRequestControllers[controllerId] = currentRequest;
    return {controller, currentRequest};
};
