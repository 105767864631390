import {IconsFontAwesome} from './IconsFontAwesome';
import PropTypes from 'prop-types';
import React from 'react';


export const ActiveInactiveStateIcon = ({
                                            active = false,
                                            activeIconType, inactiveIconType,
                                            titleActive, titleInactive,
                                            onClickHandler = () => {
                                            },
                                            secondClass = '',
                                            iconsSX, mainSX, hoveredSX,
                                            fontSize,
                                        }) => {
    return (
        active
            ? <IconsFontAwesome iconType={activeIconType}
                                secondClass={secondClass}
                                titleAccess={titleActive}
                                iconsSX={iconsSX}
                                mainSX={mainSX}
                                hoveredSX={hoveredSX}
                                fontSize={fontSize}
                                onClickHandler={onClickHandler}/>
            : <IconsFontAwesome iconType={inactiveIconType}
                                secondClass={secondClass}
                                titleAccess={titleInactive}
                                iconsSX={iconsSX}
                                mainSX={mainSX}
                                hoveredSX={hoveredSX}
                                fontSize={fontSize}
                                onClickHandler={onClickHandler}/>
    );
};

ActiveInactiveStateIcon.propTypes = {
    active: PropTypes.bool,
    activeIconType: PropTypes.string.isRequired,
    fontSize: PropTypes.string,
    hoveredSX: PropTypes.object,
    iconsSX: PropTypes.object,
    inactiveIconType: PropTypes.string.isRequired,
    mainSX: PropTypes.object,
    onClickHandler: PropTypes.func,
    secondClass: PropTypes.string,
    titleActive: PropTypes.string,
    titleInactive: PropTypes.string
};

ActiveInactiveStateIcon.defaultProps = {
    onClickHandler: () => {
    },
    secondClass: ''
};