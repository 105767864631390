/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {
    getData,
    getProjectLog,
    isLoadingLogs,
    lastLogLoadedDate,
} from '../../../+store/reducer/project';

import {loadProjectLogs} from '../../../+store/actions/project';

import {compose} from 'redux';
import withStyles from '@mui/styles/withStyles';
import {connect} from 'react-redux';

import {
    Table, TableRow, TableBody, TableCell,
    Button, CircularProgress,
    Input, InputAdornment
} from '@mui/material';

import {FONT_AWESOME_ICONS_TYPE_MAP} from '../../../../core/mappings';
import {IconsFontAwesome} from '../../../../components/IconsFontAwesome';

const styles = (theme) => ({
    table: {
        margin: theme.spacing(2),
    },
    timestamp: {
        margin: theme.spacing(1),
    },
    timestamp_cell: {
        whiteSpace: 'nowrap',
    },
    search: {
        margin: theme.spacing(2),
    },
});


const LogsComponent = ({project, log, loadingLogs, loadTimestamp, classes, loadProjectLogs}) => {
    const [filter, setFilter] = useState('');

    useEffect(() => {
        loadProjectLogs(project);
    }, []);


    return (
        <div className={classes.table}>
            <div>
                <Button
                    variant={'contained'} color={'primary'}
                    onClick={() => loadProjectLogs(project)}>
                    <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.refresh} hasHover={false}/>
                </Button>
                {
                    loadingLogs && <CircularProgress
                        size={20} style={{color: 'white'}}
                        thickness={5}/>
                }
                {
                    log &&
                    log.length === 0 &&
                    <div>
                        Solver logs will appear here when a project is solved.
                    </div>
                }
                {
                    loadTimestamp &&
                    <span className={classes.timestamp}>
                        Last loaded: {loadTimestamp.toLocaleString()}
                    </span>
                }
                <Input
                    className={classes.search}
                    fullWidth={true}
                    onChange={(event) => setFilter(event.target.value)}
                    startAdornment={
                        <InputAdornment position={'start'} attribute={'start'}>
                            <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.search}
                                              secondClass={'smaller'}
                                              hasHover={false}/>
                        </InputAdornment>
                    }
                    placeholder={'Find'}/>
            </div>
            {
                log &&
                log.length > 0 &&
                <Table>
                    <colgroup>
                        <col width="0%"/>
                        <col width="100%"/>
                    </colgroup>
                    <TableBody>
                        {
                            log
                                .filter(a => a.log.toUpperCase().includes(filter.toUpperCase()))
                                .sort((a, b) => {
                                    if (b.timestamp < a.timestamp) return -1;
                                    if (b.timestamp > a.timestamp) return 1;
                                    return 0;
                                })
                                .map(row => (
                                    <TableRow key={row.timestamp}>
                                        <TableCell
                                            className={classes.timestamp_cell}>{(new Date(row.timestamp)).toLocaleString()}</TableCell>
                                        <TableCell>{row.log}</TableCell>
                                    </TableRow>
                                ))
                        }
                    </TableBody>
                </Table>
            }
        </div>
    );
};

LogsComponent.propTypes = {
    project: PropTypes.object,
    log: PropTypes.arrayOf(PropTypes.object),
    loadingLogs: PropTypes.bool,
    loadTimestamp: PropTypes.object,
    loadProjectLogs: PropTypes.func,
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    project: getData(state),
    log: getProjectLog(state),
    loadingLogs: isLoadingLogs(state),
    loadTimestamp: lastLogLoadedDate(state),
});

const mapDispatchToProps = {
    loadProjectLogs,
};

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(LogsComponent);

