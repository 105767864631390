/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {put, takeLatest} from "redux-saga/effects";
import {ACTIVATE_USER_SUCCESS} from "../actions/actions";
import {push} from "react-router-redux";

export function* activateSuccess() {
    yield put(push('/login'));
}

export const sagas = function*() {
    yield takeLatest([ACTIVATE_USER_SUCCESS], activateSuccess);
}
