/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {reducer as authentication} from './authentication/reducer';
import {reducer as modals} from './modals/reducer';
import {reducer as projects} from './dashboard/reducer';
import {reducer as current_project} from './project/+store/reducer';
import {reducer as materials} from './materials/reducer';
import {reducer as users} from './user_setup/reducer';
import {reducer as uiState} from './core/+store-ui-state/reducers';

export const rootReducer = {
    authentication,
    modals,
    projects,
    materials,    
    current_project,
    users,
    ui: uiState
};
