/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {put, select, takeLatest} from 'redux-saga/effects';
import {LOCATION_CHANGE} from 'react-router-redux';
import {hideModal} from '../modals/actions';
import {CREATE_PROJECT_ERROR, setValidationErrors} from './actions';
import {UPDATE_PROJECT_PERMS_SUCCESS} from "../project/+store/actions/project";
import {getModals} from "../modals/reducer";

function* handelRequestError({payload}) {
    if (payload.response.status === 400) {
        const erros = yield payload.response.json();
        yield put(setValidationErrors(erros));
    }
}

function* handleUpdatePermSuccess() {
    const modals = yield select(getModals);
    if (modals.length) {
        yield put(hideModal({title: 'BOX_MODAL'}));
    }
}

export function* handleDashboardLoading() {
    yield takeLatest([CREATE_PROJECT_ERROR], handelRequestError);
    yield takeLatest([UPDATE_PROJECT_PERMS_SUCCESS], handleUpdatePermSuccess);
}

export const sagas = function* () {
    yield takeLatest(
        action =>
            action.type === LOCATION_CHANGE && action.payload.pathname === '/dashboard',
        handleDashboardLoading,
    );
};
