/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React from 'react';
import * as THREE from "three";

import Draggable3DIcon from './component/Draggable3DIcon';


export default function Box3DIcon() {
    return (
        <Draggable3DIcon materials={[new THREE.MeshPhongMaterial({color: 0xffffff})]}
                         boxes={[new THREE.BoxBufferGeometry(20, 2, 20)]}
                         lightPosition={{x: 10, y: 10, z: 10}}
                         rotateObj={false}
                         tooltipTitle={'Create Box object with optional heat source'}
                         objId={'boxID'}
                         dragType={'box'}
        />
    );
}