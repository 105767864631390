/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

export const INITIAL_FOLDER_DIALOG = {
    open: false,
    actionType: '',
};
