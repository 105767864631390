/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';


//Inspired by the former Facebook spinners.
const facebookStyles = (theme) =>  ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
  },
  top: {
    color: '#1a90ff',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
});

function FacebookCircularProgress(props) {
  const { size, classes } = props;

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={size || 30}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={30}
        thickness={4}
        {...props}
      />
    </div>
  );
}

export const FacebookSpinner = withStyles(facebookStyles)(FacebookCircularProgress);

export default function Spinner({ text, size }) {
  return (
    <span sx={{ display: 'flex' }} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
      <FacebookSpinner size={size} />
      <span sx={{ margin: '6px' }} >{text || "Loading..."}</span>
    </span>
  );
}
