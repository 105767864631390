/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */


import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {getValidationErrors} from '../../../+store/reducer/validation_errors';
import {getData as getProject} from '../../../+store/reducer/project';
import {getSelectedObject} from '../../../+store/reducer/tree';
import {
    createAssembly,
    deleteProjectAssembly,
    translateProjectAssembly,
    updateProjectAssemblyAndTree,
} from '../../../+store/actions/actions';
import {connect} from 'react-redux';
import {getDefaultValues} from './defaultValues';
import {message_floating_point, projectUtils} from '../../../utils';
import _ from 'lodash';
import {BigNumber} from 'bignumber.js';
import {confirmAlert} from 'react-confirm-alert';
import {
    IconCheckBoxField,
    InputFields,
    SettingsDivider,
    SettingsHeader,
    SettingsTabPlane
} from '../../../../components/BasicSettings';
import {FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, Tooltip} from '@mui/material';
import BasicTabs from '../../../../components/BasicTabs';
import HeatTransferCoeff from '../../../../components/HeatTranserCoeff';
import {SwitchToggle} from '../../../../components/SwitchToggle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MyValidationForm, {validations} from '../../../../components/MyValidationForm';
import {ActiveInactiveStateIcon} from '../../../../components/ActiveStateIcon';
import {FONT_AWESOME_ICONS_TYPE_MAP} from '../../../../core/mappings';
import {IconsFontAwesome} from '../../../../components/IconsFontAwesome';

const AssemblySettingsNew = ({
                                 createAssembly,
                                 deleteProjectAssembly,
                                 translateProjectAssembly,
                                 updateProjectAssemblyAndTree,
                                 project_id,
                                 box,
                                 validation_errors
                             }) => {
    const [defaultState] = useState(getDefaultValues[box.type]());
    const [fields] = useState(Object.keys(defaultState));
    const [state, setState] = useState({
        ...projectUtils.updateSettingsProps({
            fields,
            props: box,
            defaultState,
        }),
        ...box,
        x: box.x_calc,
        y: box.y_calc,
        z: box.z_calc,
    });

    const form = useRef();

    useEffect(() => {
        const newState = {
            ...projectUtils.updateSettingsProps({fields, props: box, defaultState}),
            x: box.x_calc,
            y: box.y_calc,
            z: box.z_calc,
        };
        setState((prevState) => !_.isEqual(prevState, newState) ? newState : prevState);
    }, [box]);

    function handleChange(field, value) {
        setState(prevState => {
            const newState = {
                ...prevState,
                [field]: value,
            };

            if (field === 'is_array') {
                newState.xcount = +newState.xcount < 1 ? 1 : +newState.xcount;
                newState.ycount = +newState.ycount < 1 ? 1 : +newState.ycount;
                newState.zcount = +newState.zcount < 1 ? 1 : +newState.zcount;
            }

            switch (newState.translate) {
                case 'absolute':
                    return {
                        ...newState,
                        dx: newState.x ? BigNumber.sum(BigNumber(newState.x), -BigNumber(box.x_calc || 0)).toString() : 0,
                        dy: newState.y ? BigNumber.sum(BigNumber(newState.y), -BigNumber(box.y_calc || 0)).toString() : 0,
                        dz: newState.z ? BigNumber.sum(BigNumber(newState.z), -BigNumber(box.z_calc || 0)).toString() : 0,
                    };
                case 'relative':
                    return {
                        ...newState,
                        x: newState.dx ? BigNumber.sum(BigNumber(box.x_calc || 0), BigNumber(newState.dx || 0)).toString() : 0,
                        y: newState.dy ? BigNumber.sum(BigNumber(box.y_calc || 0), BigNumber(newState.dy || 0)).toString() : 0,
                        z: newState.dz ? BigNumber.sum(BigNumber(box.z_calc || 0), BigNumber(newState.dz || 0)).toString() : 0,
                    };
            }

            return newState;
        });
    }

    function boxHasChange() {
        return Object.keys(state).some((key) => (key !== 'translate') && (!_.isEqual(state[key], box[key])));
    }

    function handleSubmit(e) {
        e.preventDefault();
        form.current.isFormValid(false)
            .then(is_valid => {
                if (is_valid) {
                    if (box && box.id) {
                        translateProjectAssembly({
                            project_id,
                            assembly_id: box.id,
                            box: {...state},
                            translate: {
                                dx: state.dx || 0,
                                dy: state.dy || 0,
                                dz: state.dz || 0,
                            },
                        });
                        updateProjectAssemblyAndTree({
                            project_id,
                            assembly: {
                                ...box,
                                ...state,
                            },
                        });
                    } else {
                        createAssembly({
                            project_id,
                            assembly: {
                                ...state,
                                parent: null,
                                //new assembly should be expanded to enable adding/drag&drop child node
                                collapsed: false,
                            },
                        });
                    }

                }
            });
    }

    function handleDelete() {
        confirmAlert({
            title: `Deleting ${box.name}`, message: 'Do you wish to proceed?', buttons: [
                {
                    label: 'Delete', onClick: () => {
                        deleteProjectAssembly({
                            project_id,
                            box: {
                                ...box,
                                ...state,
                            },
                        });
                    }
                }, {
                    label: 'Cancel', onClick: () => {
                    },
                },],
        });
    }

    function handleRevert() {
        setState({
            ...projectUtils.updateSettingsProps({fields, props: box, defaultState}),
            ...box,
            translate: 'absolute',
            x: box.x_calc,
            y: box.y_calc,
            z: box.z_calc,
            dx: '0',
            dy: '0',
            dz: '0',
        });
    }

    const translationTabs = [{
        label: 'Array',
        Component: (
            <>
                <IconCheckBoxField id={'isArrayCheckBox'}
                                   checked={state.is_array}
                                   field_name={'is_array'}
                                   handleChange={handleChange}
                                   checkBoxLabel={'Create Array'}
                                   sx={{
                                       // color: pink[800],
                                       '&.Mui-checked': {
                                           color: '#3f51b5',
                                       },
                                   }}/>
                {state.is_array
                    ? (
                        <>
                            <InputFields
                                groupTitle={'Count'}
                                type={'number'}
                                field_names={['xcount', 'ycount', 'zcount']}
                                state={state}
                                ids={'assemblyarrayCount'}
                                validation_errors={validation_errors}
                                handleChange={(field, value) => {
                                    if (!validations.isNumber(value) || +value < 0) {
                                        return;
                                    }

                                    handleChange(field, `${Math.max(Math.round(+value), 1)}`)
                                }}
                            />
                            <InputFields
                                groupTitle={'Pitch'}
                                units={'µm'}
                                field_names={['xpitch', 'ypitch', 'zpitch']}
                                state={state}
                                ids={'assemblyarraypich'}
                                validation_errors={validation_errors}
                                required={false}
                                handleChange={handleChange}/>
                        </>
                    )
                    : null}
            </>
        )
    },
        {
            label: 'Translation',
            Component: (
                <>
                    <RadioGroup row defaultValue={state.translate}
                                value={state.translate}
                                onChange={(event) => handleChange('translate', event.target.value)}>
                        <FormControlLabel value={'absolute'} control={<Radio/>} label={'Absolute'}/>
                        <FormControlLabel value={'relative'} control={<Radio/>} label={'Relative'}/>
                    </RadioGroup>
                    <InputFields
                        disabled
                        groupTitle={'Current Position'}
                        field_names={['x_calc', 'y_calc', 'z_calc']}
                        units={'mm'}
                        state={box}
                        validation_errors={validation_errors}
                        handleChange={handleChange}/>
                    {
                        state.translate !== 'relative'
                            ? (
                                <>
                                    <InputFields
                                        groupTitle={'New Position'}
                                        field_names={['x', 'y', 'z']}
                                        units={'mm'}
                                        state={state}
                                        ids={'boxdimensionid'}
                                        validation_errors={validation_errors}
                                        required={false}
                                        handleChange={handleChange}/>
                                    <InputFields
                                        disabled
                                        groupTitle={'Translation'}
                                        field_names={['dx', 'dy', 'dz']}
                                        units={'mm'}
                                        state={state}
                                        ids={'boxdimensionid'}
                                        validation_errors={validation_errors}
                                        required={false}
                                        handleChange={handleChange}/>
                                </>
                            )
                            : (
                                <>
                                    <InputFields
                                        disabled
                                        groupTitle={'New Position'}
                                        field_names={['x', 'y', 'z']}
                                        units={'mm'}
                                        state={state}
                                        ids={'boxdimensionid'}
                                        validation_errors={validation_errors}
                                        required={false}
                                        handleChange={handleChange}/>
                                    <InputFields
                                        groupTitle={'Translation'}
                                        field_names={['dx', 'dy', 'dz']}
                                        units={'mm'}
                                        state={state}
                                        ids={'boxdimensionid'}
                                        required={false}
                                        validation_errors={validation_errors}
                                        handleChange={handleChange}/>
                                </>
                            )
                    }
                </>
            )
        }
    ];

    const tabs = [{
        label: 'Translation',
        Component: <FormGroup style={{height: '100%'}}>
            <SettingsTabPlane
                hasChange={boxHasChange()}
                onRevert={handleRevert}
                onSave={handleSubmit}
                object={box}>
                <InputFields
                    field_names={['name']}
                    state={state}
                    ids={'assemblyname'}
                    validation_errors={validation_errors}
                    handleChange={handleChange}/>
                <BasicTabs id={'tabs'} tabs={translationTabs} title={''} isSwitch={true}
                           defaultValue={0}/>
            </SettingsTabPlane>
        </FormGroup>
    }, {
        label: 'Thermal',
        Component: <FormGroup style={{height: '100%'}}>
            <SettingsTabPlane hasChange={boxHasChange()}
                              onRevert={handleRevert}
                              onSave={handleSubmit}
                              object={box}>
                <HeatTransferCoeff
                    fullWidth
                    variant={'standard'}
                    validators={[]}
                    value={state.hc || ''}
                    onChange={(event) => handleChange('hc', event.target.value)}
                    error={!!validation_errors['hc']}
                    helperText={validation_errors['hc']}
                    errorMessages={[message_floating_point]}
                />
                <FormControl>
                    <SwitchToggle
                        id={'lumpedmodel'}
                        booleans={true}
                        onChange={() => handleChange('lumped', !state.lumped)}
                        checked={!!state.lumped}
                        title={'Quick Model'}
                        orientation={'vertical'}
                    />
                </FormControl>
                <Tooltip title={
                    <span>
                                Use a lumped model instead of detailed Ball Array objects. This option is valid only when a Fill material has been specified.
                                <br/>
                                Quick Model will use approximated material properties in all 3 axes and will be created at a maximum resolution of 500&micro;m.
                                <br/>
                                NOTE: Quick Model will be automatically used if ball count exceeds 100K.
                            </span>
                }>
                    <InfoOutlinedIcon fontSize={'inherit'}/>
                </Tooltip>
            </SettingsTabPlane>
        </FormGroup>
    }];

    return (
        <MyValidationForm
            ref={form}
            onSubmit={handleSubmit}
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                width: '100%',
                minHeight: '50%'
            }}
        >
            <FormGroup>
                <SettingsHeader name={box.type}>
                    <ActiveInactiveStateIcon active={state.active}
                                             activeIconType={FONT_AWESOME_ICONS_TYPE_MAP.powerActive}
                                             inactiveIconType={FONT_AWESOME_ICONS_TYPE_MAP.powerInactive}
                                             titleActive={'Set Inactive Assembly'}
                                             titleInactive={'Set Active Assembly'}
                                             iconsSX={{fontSize: '1rem', p: '6px'}}
                                             hoveredSX={{backgroundColor: 'background.paper'}}
                                             onClickHandler={() => handleChange('active', !state.active)}/>
                    <ActiveInactiveStateIcon active={state.visible}
                                             activeIconType={FONT_AWESOME_ICONS_TYPE_MAP.eyeVisible}
                                             inactiveIconType={FONT_AWESOME_ICONS_TYPE_MAP.eyeNotVisible}
                                             titleActive={'Hide Assembly'}
                                             titleInactive={'Show Assembly'}
                                             iconsSX={{fontSize: '1rem', p: '6px'}}
                                             hoveredSX={{backgroundColor: 'background.paper'}}
                                             onClickHandler={() => handleChange('visible', !state.visible)}/>
                    {
                        box &&
                        box.id &&
                        <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.delete}
                                          titleAccess={`Delete ${box.name}`}
                                          secondClass={'smaller'}
                                          hoveredSX={{backgroundColor: 'background.paper'}}
                                          iconsSX={{fontSize: '1rem', p: '6px'}}
                                          onClickHandler={handleDelete}/>
                    }
                </SettingsHeader>
            </FormGroup>
            <SettingsDivider withSideMargin/>
            <BasicTabs id={'properties'} tabs={tabs} title={'Properties'} isSwitch={true}/>
        </MyValidationForm>
    );
};

AssemblySettingsNew.propTypes = {
    createAssembly: PropTypes.func.isRequired,
    deleteProjectAssembly: PropTypes.func.isRequired,
    translateProjectAssembly: PropTypes.func.isRequired,
    updateProjectAssemblyAndTree: PropTypes.func.isRequired,
    validation_errors: PropTypes.object,
    tree: PropTypes.array,
    box: PropTypes.object,
};

const mapStateToProps = state => ({
    validation_errors: getValidationErrors(state),
    project_id: getProject(state).id,
    box: getSelectedObject(state),
});

const mapDispatchToProps = {
    createAssembly,
    deleteProjectAssembly,
    updateProjectAssemblyAndTree,
    translateProjectAssembly
};

export default connect(mapStateToProps, mapDispatchToProps)(AssemblySettingsNew);