/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {handleActions} from 'redux-actions';
import {decode} from 'jsonwebtoken';

import {
    CLEAR_PASSWORD_RESET, FORGOT_PASSWORD_SUCCESS, LICENSE_REQUEST_SUCCESS,
    LOGIN,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    LOGOUT, RESET_PASSWORD_SUCCESS,
    SET_TOKEN,
    USER_INFO_SUCCESS,
} from './actions';

export const defaultState = {
    error: null,
    isLogged: false,
    resetPassword: false,
    user: {},
};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                isLogged: false,
                user: {},
            };
        case LOGIN_ERROR:
            return {
                ...state,
                user: {},
                isLogged: false,
                disconnect: true,
            };
        case LOGIN_SUCCESS: {
            const decryptedPayload = decode(action.payload.access);
            return {
                ...state,
                user: {},
                isLogged: true,
                token: action.payload.access,
                ...decryptedPayload,
            };
        }
        case SET_TOKEN: {
            const decryptedPayload = decode(action.payload);
            return {
                ...state,
                isLogged: true,
                ...decryptedPayload,
                token: action.payload,
            };
        }
        case LOGOUT:
            return {
                ...state,
                isLogged: false,
                token: null,
            }
        case USER_INFO_SUCCESS:
            return {
                ...state,
                user: action.payload,
            }
        case LICENSE_REQUEST_SUCCESS: {
            let temp = {...state};
            let idx = temp.user.licenses.findIndex(x => x.feature === action.payload.feature);
            temp.user.licenses[idx] = action.payload;
            return temp;
        }
        case CLEAR_PASSWORD_RESET:
            return {
                ...state,
                resetPassword: false,
            }
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPassword: true,
            }
        case FORGOT_PASSWORD_SUCCESS:
            console.log(action);
            return {
                ...state,
                resetPassword: true,
            }
        default:
            return state;
    }
}

export const isLogged = state => state.authentication.isLogged;
export const hasUserInfo = state => state.authentication.user;
