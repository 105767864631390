/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {fork} from 'redux-saga/effects';

import {sagas as authenticationSagas} from './authentication/sagas';
import {sagas as fetchSagas} from './services/fetch/fetchSagas';
import {sagas as dashboardSagas} from './dashboard/sagas';
import {sagas as projectSagas} from './project/+store/sagas/sagas';
import {sagas as materialsSagas} from './materials/sagas';
import {sagas as userSetupSagas} from './user_setup/sagas/sagas';

export function* sagas() {
    yield fork(authenticationSagas);
    yield fork(fetchSagas);
    yield fork(dashboardSagas);
    yield fork(projectSagas);
    yield fork(materialsSagas);
    yield fork(userSetupSagas);
}
