/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {
    Alert, Input,
    Snackbar,
    Slide,
    InputAdornment,
    Button,
    Box,
    Grid,
    CircularProgress,
    IconButton
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import {confirmAlert} from 'react-confirm-alert';
import {BigNumber} from 'bignumber.js';
import {styles} from '../styles';
import './Dashboard.scss';

import {
    getActiveFolder,
    getData as getProjects,
    getDraggedProject,
    getProjectCartNextParentId, getProjectsTreeData, isImporting,
    isLoading,
} from '../reducer';
import {getLibrary} from '../../materials/reducer';
import {hasUserInfo} from '../../authentication/reducer';

import {getUserInfo} from '../../authentication/actions';
import {showModal} from '../../modals/actions';
import {loadLibrary} from '../../materials/actions';
import {
    createProjectFromCSVFile,
    createProjectFromJSONFile,
    loadActiveFolderProjectsAction,
    loadFolderAction,
    loadRootFolderProjectsAction,
    loadSharedFolderProjectsAction,
    projectCardDragEnd,
    setDraggedProjectNextParent,
} from '../actions';

import SideMenuProjectCardsTree from '../components/sideMenuProjectCardsTree/SideMenuProjectCardsTree';
import FileImport from '../../components/FileImport';
import Spinner from '../../components/Spinner';
import Header from '../../components/Header';
import CreateProject from './ProjectSettings';
import ProjectCard from './ProjectCard';
import {MAIN_FOLDER_NAMES} from '../../core/constants/PROJECTS_TREE';
import {IconsFontAwesome} from '../../components/IconsFontAwesome';
import {FONT_AWESOME_ICONS_TYPE_MAP} from '../../core/mappings';


const Dashboard = ({
                       isLoading,
                       isProjectCardsLoading,
                       isImporting,
                       loadLibrary,
                       getUserInfo,
                       user_info,
                       createProjectFromJSONFile,
                       materials,
                       classes,
                       showModal,
                       projects,
                       activeFolder,
                       setDraggedProjectNextParent,
                       draggedProjectNextParentId,
                       draggedProject,
                       projectCardDragEnd,
                       loadActiveFolderProjectsAction,
                       loadSharedFolderProjectsAction,
                       loadRootFolderProjectsAction,
                       loadFolderAction,
                       treeData,
                   }) => {
    const inputCSVRef = useRef();
    const inputJSONRef = useRef();
    const inputECXMLRef = useRef();

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [message, setMessage] = useState('');
    const [filter, setFilter] = useState('');
    const [mousePosition, setMousePosition] = useState({
        top: 0,
        left: 0,
    });

    document.title = 'DANKA THERMAL';

    function loadCSVFile(event) {
        let name = event.target.files[0].name.split('.').shift(),
            flat_materials = materials.map(lib => lib.materials).flat(2);

        event.preventDefault();

        const reader = new FileReader();

        function findParent(tree, hierarchy) {
            if (hierarchy.length === 0)
                return tree;

            let assyName = hierarchy.shift(),
                assy = tree.find(x => x.name === assyName && x.type === 'assembly');

            if (assy === undefined) {
                assy = {
                    name: assyName,
                    type: 'assembly',
                    children: [],
                    index: tree.length,
                };
                tree.push(assy);
            }
            return findParent(assy.children, hierarchy);
        }

        reader.onload = async (e) => {
            let lines = e.target.result.split('\n').slice(1).filter(line => line.length > 0),
                tree = [],
                proj_materials = [];

            try {
                lines.forEach((line, index) => {
                    let items = line.replace('\r', '').split(',');

                    if (items.length < 9) {
                        throw `Line ${index + 2}: Not enough values`;
                    }
                    let name = items.shift(),
                        type = items.shift(),
                        x = +items.shift(),
                        y = +items.shift(),
                        z = +items.shift(),
                        dx = +items.shift(),
                        dy = +items.shift(),
                        dz = +items.shift(),
                        material_name = items.shift(),
                        material_index = proj_materials.findIndex(mat => mat.name === material_name);

                    const hierarchy = name.split('/');
                    name = hierarchy.pop();
                    const parent = findParent(tree, hierarchy);

                    if (material_name && material_index < 0) {
                        let lib_material = flat_materials.find(mat => mat.name === material_name);
                        if (!lib_material)
                            throw `Line ${index + 2}: Did not find material '${material_name}' in your libraries.`;

                        material_index = proj_materials.length;

                        proj_materials.push({
                            ...lib_material,
                            id: proj_materials.length + 1,
                            material: lib_material,
                        });
                    }

                    switch (type) {
                        case 'box': {
                            let power = items.shift();
                            parent.push({
                                name,
                                index: parent.length,
                                type,
                                x, y, z, dx, dy, dz,
                                material: material_index + 1,
                                power,
                            });
                            break;
                        }
                        case 'cylinder': {
                            let power = items.shift(),
                                plane = items.shift();
                            parent.push({
                                name,
                                index: parent.length,
                                type,
                                x, y, z, dx, dy, dz,
                                material: material_index + 1,
                                power,
                                plane,
                            });
                            break;
                        }
                        case 'heatsink': {
                            let fin_axis = items.shift(),
                                base_dz = +items.shift(),
                                fin_thickness = +items.shift(),
                                fin_height = +items.shift(),
                                fin_count = +items.shift();
                            parent.push({
                                name,
                                index: parent.length,
                                type, x, y, z,
                                base_dx: dx,
                                base_dy: dy,
                                base_dz,
                                material: material_index + 1,
                                fin_axis,
                                fin_thickness,
                                fin_height,
                                fin_count,
                            });
                            break;
                        }
                        case 'ball_array': {
                            let xcount = +items.shift(),
                                ycount = +items.shift(),
                                pitch = +items.shift(),
                                diameter = +items.shift();
                            parent.push({
                                name, index: parent.length, type, x, y, z, dx, dy, dz, material: material_index + 1,
                                xcount, ycount, pitch, diameter,
                            });
                            break;
                        }
                        case 'via_array': {
                            let xcount = +items.shift(),
                                ycount = +items.shift(),
                                xpitch = +items.shift(),
                                ypitch = +items.shift(),
                                diameter = +items.shift();
                            parent.push({
                                name, index: parent.length, type, x, y, z, dx, dy, dz, material: material_index + 1,
                                xcount, ycount, xpitch, ypitch, diameter,
                            });
                            break;
                        }
                        case 'pcb': {
                            let pcb = {
                                    name, index: parent.length, type, x, y, z, dx, dy,
                                    layers: [],
                                },
                                pos = 9;
                            items = items.filter(item => item.length > 0);
                            while (items.length) {
                                let name = items.shift(),
                                    thickness = items.shift(),
                                    material_name = items.shift(),
                                    material_index = proj_materials.findIndex(mat => mat.name === material_name);

                                if (name === undefined)
                                    throw `Line ${index + 2}: Expecting layer name in column ${pos + 1}.`;

                                if (thickness === undefined)
                                    throw `Line ${index + 2}: Expecting thickness value in column ${pos + 2}.`;

                                if (material_name === undefined)
                                    throw `Line ${index + 2}: Expecting material name in column ${pos + 3}.`;

                                if (material_index < 0) {
                                    let lib_material = flat_materials.find(mat => mat.name === material_name);
                                    if (!lib_material)
                                        throw `Line ${index + 2}: Did not find material '${material_name}' in your libraries.`;
                                    material_index = proj_materials.length;

                                    proj_materials.push({
                                        ...lib_material,
                                        id: proj_materials.length + 1,
                                        material: lib_material,
                                    });
                                }

                                pcb.layers.push({
                                    name,
                                    thickness,
                                    material: material_index + 1,
                                });
                                pos += 3;
                            }
                            parent.push(pcb);
                            break;
                        }
                        case 'source': {
                            let power = items.shift(),
                                color = items.shift(),
                                plane = items.shift();
                            parent.push({
                                name,
                                index: parent.length,
                                type,
                                x, y, z, dx, dy, dz,
                                power,
                                plane,
                                color
                            });
                            break;
                        }
                        case 'transient_source': {
                            items = items.filter(item => item.length > 0);

                            let plane = items.pop(),
                                color = items.pop(),
                                pos = 9;

                            const transient_source = {
                                name,
                                index: parent.length,
                                type,
                                x, y, z, dx, dy, dz,
                                powers: [],
                                plane,
                                color
                            };

                            while (items.length > 2) {
                                let id = items.shift(),
                                    time = items.shift(),
                                    power = items.shift();

                                if (id === undefined)
                                    throw `Line ${index + 2}: Expecting id in column ${pos + 1}.`;

                                if (time === undefined)
                                    throw `Line ${index + 2}: Expecting time in column ${pos + 2}.`;

                                if (power === undefined)
                                    throw `Line ${index + 2}: Expecting power in column ${pos + 3}.`;

                                transient_source.powers.push({
                                    id,
                                    power,
                                    time,
                                    power_calc: power
                                });
                                pos += 3;
                            }

                            parent.push(transient_source);
                            break;
                        }
                        default:
                            throw `Line ${index + 2}: Invalid type "${type}"`;
                    }
                });
                createProjectFromJSONFile({
                    materials: proj_materials,
                    project: {
                        name,
                        folder: activeFolder.node.id,
                        world: {},
                    },
                    tree,
                });
            } catch (e) {
                confirmAlert({
                    title: 'Import error!',
                    message: e,
                    buttons: [
                        {
                            label: 'OK',
                            onClick: () => {
                            },
                        },
                    ],
                });
            }
        };
        reader.readAsText(event.target.files[0]);
        inputCSVRef.current.value = '';
    }

    function loadJSONFile(e) {
        e.preventDefault();

        const reader = new FileReader();
        reader.onload = async (e) => {
            const text = JSON.parse(e.target.result);
            createProjectFromJSONFile({
                ...text,
                project: {
                    ...text.project,
                    folder: activeFolder.node.id,
                },
            });
        };
        reader.readAsText(e.target.files[0]);
        inputJSONRef.current.value = '';
    }

    function loadECXMLFile(e) {
        let material_map = {};
        let nsResolver;

        function parseMaterials(iter) {
            let node, res = [], id = 1;
            while (node = iter.iterateNext()) {
                let name = node.ownerDocument.evaluate('./name', node, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                    color = null,
                    density = node.ownerDocument.evaluate('./density', node, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                    specific_heat = node.ownerDocument.evaluate('./specific_heat', node, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                    isotropic = node.ownerDocument.evaluate('./thermalConductivity/isotropic', node, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                    k = node.ownerDocument.evaluate('./thermalConductivity/isotropic/conductivity', node, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                    orthrotropic = node.ownerDocument.evaluate('./thermalConductivity/orthotropic', node, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                    kx = node.ownerDocument.evaluate('./thermalConductivity/orthotropic/x', node, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                    ky = node.ownerDocument.evaluate('./thermalConductivity/orthotropic/y', node, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                    kz = node.ownerDocument.evaluate('./thermalConductivity/orthotropic/z', node, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE);

                try {
                    color = node.ownerDocument.evaluate('./ats:color', node, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE);
                } catch (e) {
                }

                if (name.singleNodeValue == null)
                    throw 'Material has no <name> data';
                if (density.singleNodeValue == null)
                    throw 'Material has no <density> data';
                if (specific_heat.singleNodeValue == null)
                    throw 'Material has no <specific_heat> data';
                if (isotropic.singleNodeValue != null && k.singleNodeValue == null)
                    throw 'Material has invalid <isotropic> data';
                if (orthrotropic.singleNodeValue != null && (kx.singleNodeValue == null || ky.singleNodeValue == null || kz.singleNodeValue == null))
                    throw 'Material has invalid <orthotropic> data';
                if (isotropic.singleNodeValue)
                    res.push({
                        id: id,
                        name: name.singleNodeValue.textContent,
                        color: color && color.singleNodeValue && color.singleNodeValue.textContent || '#FFFFFF',
                        anisotropic: false,
                        conductivity: +k.singleNodeValue.textContent,
                        density: +density.singleNodeValue.textContent || null,
                        specific_heat: +specific_heat.singleNodeValue.textContent || null,
                    });
                else if (orthrotropic.singleNodeValue)
                    res.push({
                        id: id,
                        name: name.singleNodeValue.textContent,
                        color: color && color.singleNodeValue && color.singleNodeValue.textContent || '#FFFFFF',
                        anisotropic: true,
                        conductivity: (+kx.singleNodeValue.textContent).toExponential(),
                        conductivity_y: +ky.singleNodeValue.textContent,
                        conductivity_z: +kz.singleNodeValue.textContent,
                        density: +density.singleNodeValue.textContent || null,
                        specific_heat: +specific_heat.singleNodeValue.textContent || null,
                    });
                else
                    throw 'Material has no <isotropic> or <orthotropic> data';
                material_map[name.singleNodeValue.textContent] = id;
                ++id;
            }
            return res;
        }

        function parseGeometry(node) {
            let ret = [], child = null, index = 0,
                iter = node.ownerDocument.evaluate('./child::*', node, nsResolver, XPathResult.ORDERED_NODE_ITERATOR_TYPE);
            while (child = iter.iterateNext()) {
                let
                    name = child.ownerDocument.evaluate('./name', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                    active = child.ownerDocument.evaluate('./active', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                    visible = null;

                try {
                    visible = child.ownerDocument.evaluate('./ats:visible', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE);
                } catch (e) {
                }

                if (child.nodeName === 'assembly') {
                    let
                        geom = child.ownerDocument.evaluate('./geometry', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                        ats_type = child.attributes.getNamedItem('ats:type');

                    if (ats_type && ats_type.value === 'via_array') {
                        let
                            x = child.attributes.getNamedItem('ats:x'),
                            y = child.attributes.getNamedItem('ats:y'),
                            z = child.attributes.getNamedItem('ats:z'),
                            dx = child.attributes.getNamedItem('ats:dx'),
                            dy = child.attributes.getNamedItem('ats:dy'),
                            dz = child.attributes.getNamedItem('ats:dz'),
                            material = child.attributes.getNamedItem('ats:material'),
                            fill_material = child.attributes.getNamedItem('ats:fill_material'),
                            xcount = child.attributes.getNamedItem('ats:xcount'),
                            ycount = child.attributes.getNamedItem('ats:ycount'),
                            xpitch = child.attributes.getNamedItem('ats:xpitch'),
                            ypitch = child.attributes.getNamedItem('ats:ypitch'),
                            diam = child.attributes.getNamedItem('ats:diameter');

                        ret.push({
                            index: index,
                            type: 'via_array',
                            name: name.singleNodeValue.textContent,
                            active: active.singleNodeValue.textContent,
                            visible: visible && visible.singleNodeValue && visible.singleNodeValue.textContent || true,
                            material: material_map[material.value],
                            fill_material: fill_material && material_map[fill_material.value] || null,
                            x: BigNumber(x.value).times(1e3),
                            y: BigNumber(y.value).times(1e3),
                            z: BigNumber(z.value).times(1e3),
                            dx: BigNumber(dx.value).times(1e3),
                            dy: BigNumber(dy.value).times(1e3),
                            dz: BigNumber(dz.value).times(1e3),
                            xcount: +xcount.value,
                            ycount: +ycount.value,
                            xpitch: BigNumber(xpitch.value).times(1e6),
                            ypitch: BigNumber(ypitch.value).times(1e6),
                            diameter: BigNumber(diam.value).times(1e6),
                        });

                    } else if (ats_type && ats_type.value === 'ball_array') {
                        let
                            x = child.attributes.getNamedItem('ats:x'),
                            y = child.attributes.getNamedItem('ats:y'),
                            z = child.attributes.getNamedItem('ats:z'),
                            dx = child.attributes.getNamedItem('ats:dx'),
                            dy = child.attributes.getNamedItem('ats:dy'),
                            dz = child.attributes.getNamedItem('ats:dz'),
                            material = child.attributes.getNamedItem('ats:material'),
                            fill_material = child.attributes.getNamedItem('ats:fill_material'),
                            xcount = child.attributes.getNamedItem('ats:xcount'),
                            ycount = child.attributes.getNamedItem('ats:ycount'),
                            pitch = child.attributes.getNamedItem('ats:pitch'),
                            diam = child.attributes.getNamedItem('ats:diameter');

                        ret.push({
                            index: index,
                            type: 'ball_array',
                            name: name.singleNodeValue.textContent,
                            active: active.singleNodeValue.textContent,
                            visible: visible && visible.singleNodeValue && visible.singleNodeValue.textContent || true,
                            material: material_map[material.value],
                            fill_material: fill_material && material_map[fill_material.value] || null,
                            x: BigNumber(x.value).times(1e3),
                            y: BigNumber(y.value).times(1e3),
                            z: BigNumber(z.value).times(1e3),
                            dx: BigNumber(dx.value).times(1e3),
                            dy: BigNumber(dy.value).times(1e3),
                            dz: BigNumber(dz.value).times(1e3),
                            xcount: +xcount.value,
                            ycount: +ycount.value,
                            pitch: BigNumber(pitch.value).times(1e6),
                            diameter: BigNumber(diam.value).times(1e6),
                        });
                    } else if (ats_type && ats_type.value === 'heatsink') {
                        let
                            x = child.attributes.getNamedItem('ats:x'),
                            y = child.attributes.getNamedItem('ats:y'),
                            z = child.attributes.getNamedItem('ats:z'),
                            dx = child.attributes.getNamedItem('ats:base_dx'),
                            dy = child.attributes.getNamedItem('ats:base_dy'),
                            dz = child.attributes.getNamedItem('ats:base_dz'),
                            material = child.attributes.getNamedItem('ats:material'),
                            axis = child.attributes.getNamedItem('ats:fin_axis'),
                            count = child.attributes.getNamedItem('ats:fin_count'),
                            thickness = child.attributes.getNamedItem('ats:fin_thickness'),
                            height = child.attributes.getNamedItem('ats:fin_height');

                        ret.push({
                            index: index,
                            type: 'heatsink',
                            name: name.singleNodeValue.textContent,
                            active: active.singleNodeValue.textContent,
                            visible: visible && visible.singleNodeValue && visible.singleNodeValue.textContent || true,
                            material: material_map[material.value],
                            x: BigNumber(x.value).times(1e3),
                            y: BigNumber(y.value).times(1e3),
                            z: BigNumber(z.value).times(1e3),
                            base_dx: BigNumber(dx.value).times(1e3),
                            base_dy: BigNumber(dy.value).times(1e3),
                            base_dz: BigNumber(dz.value).times(1e3),
                            fin_axis: axis.value,
                            fin_count: +count.value,
                            fin_thickness: BigNumber(thickness.value).times(1e3),
                            fin_height: BigNumber(height.value).times(1e3),
                        });
                    } else if (ats_type) {
                        console.log('Unsupported type', ats_type);
                    } else if (geom.singleNodeValue) {
                        ret.push({
                            index: index,
                            type: 'assembly',
                            name: name.singleNodeValue.textContent,
                            active: active.singleNodeValue.textContent,
                            visible: visible && visible.singleNodeValue && visible.singleNodeValue.textContent || true,
                            children: parseGeometry(geom.singleNodeValue),
                        });
                    }
                } else if (child.nodeName === 'solid3dBlock') {
                    let
                        x = child.ownerDocument.evaluate('./location/x', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                        y = child.ownerDocument.evaluate('./location/y', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                        z = child.ownerDocument.evaluate('./location/z', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                        dx = child.ownerDocument.evaluate('./size/x', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                        dy = child.ownerDocument.evaluate('./size/y', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                        dz = child.ownerDocument.evaluate('./size/z', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                        material = child.ownerDocument.evaluate('./material', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                        power = child.ownerDocument.evaluate('./powerDissipation', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE);

                    ret.push({
                        index: index,
                        type: 'box',
                        name: name.singleNodeValue.textContent,
                        active: active.singleNodeValue.textContent,
                        visible: visible && visible.singleNodeValue && visible.singleNodeValue.textContent || true,
                        material: material_map[material.singleNodeValue.textContent],
                        x: BigNumber(x.singleNodeValue.textContent).times(1e3),
                        y: BigNumber(y.singleNodeValue.textContent).times(1e3),
                        z: BigNumber(z.singleNodeValue.textContent).times(1e3),
                        dx: BigNumber(dx.singleNodeValue.textContent).times(1e3),
                        dy: BigNumber(dy.singleNodeValue.textContent).times(1e3),
                        dz: BigNumber(dz.singleNodeValue.textContent).times(1e3),
                        power: (1e3 * +power.singleNodeValue.textContent).toFixed(6),
                    });
                } else if (child.nodeName === 'solid2dBlock') {
                    let
                        transient = null,
                        x = child.ownerDocument.evaluate('./location/x', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                        y = child.ownerDocument.evaluate('./location/y', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                        z = child.ownerDocument.evaluate('./location/z', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                        dx = child.ownerDocument.evaluate('./size/x', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                        dy = child.ownerDocument.evaluate('./size/y', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                        dz = child.ownerDocument.evaluate('./size/z', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                        plane = child.ownerDocument.evaluate('./plane', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                        color = null,
                        power = child.ownerDocument.evaluate('./powerDissipation', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                        powerNodess = child.ownerDocument.evaluate('./ats:transientPowerDissipation', child, nsResolver, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE),
                        powers = [];

                    try {
                        color = child.ownerDocument.evaluate('./ats:color', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE);
                    } catch (e) {
                    }

                    try {
                        transient = child.attributes.getNamedItem('ats:transient');
                    } catch (e) {
                    }

                    for (let i = 0; i < powerNodess.snapshotLength; ++i) {
                        console.log(powerNodess.snapshotItem(i).attributes);
                        powers.push({
                            time: +powerNodess.snapshotItem(i).attributes.getNamedItem('time').value,
                            power: +powerNodess.snapshotItem(i).attributes.getNamedItem('power').value * 1e3,
                        });
                    }

                    ret.push({
                        index: index,
                        type: transient && 'transient_source' || 'source',
                        name: name.singleNodeValue.textContent,
                        active: active.singleNodeValue.textContent,
                        visible: visible && visible.singleNodeValue && visible.singleNodeValue.textContent || true,
                        plane: plane.singleNodeValue.textContent.substr(1),
                        color: color && color.singleNodeValue && color.singleNodeValue.textContent || true,
                        x: BigNumber(x.singleNodeValue.textContent).times(1e3),
                        y: BigNumber(y.singleNodeValue.textContent).times(1e3),
                        z: BigNumber(z.singleNodeValue.textContent).times(1e3),
                        dx: BigNumber(dx.singleNodeValue.textContent).times(1e3),
                        dy: BigNumber(dy.singleNodeValue.textContent).times(1e3),
                        dz: BigNumber(dz.singleNodeValue.textContent).times(1e3),
                        power: (1e3 * +power.singleNodeValue.textContent).toFixed(6),
                        powers: powers,
                    });
                } else if (child.nodeName === 'solidCylinder') {
                    let
                        x = child.ownerDocument.evaluate('./location/x', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                        y = child.ownerDocument.evaluate('./location/y', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                        z = child.ownerDocument.evaluate('./location/z', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                        dx = child.ownerDocument.evaluate('./size/x', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                        dy = child.ownerDocument.evaluate('./size/y', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                        dz = child.ownerDocument.evaluate('./size/z', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                        plane = child.ownerDocument.evaluate('./plane', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                        material = child.ownerDocument.evaluate('./material', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                        power = child.ownerDocument.evaluate('./powerDissipation', child, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE);

                    ret.push({
                        index: index,
                        type: 'cylinder',
                        name: name.singleNodeValue.textContent,
                        active: active.singleNodeValue.textContent,
                        visible: visible && visible.singleNodeValue && visible.singleNodeValue.textContent || true,
                        material: material_map[material.singleNodeValue.textContent],
                        x: BigNumber(x.singleNodeValue.textContent).times(1e3),
                        y: BigNumber(y.singleNodeValue.textContent).times(1e3),
                        z: BigNumber(z.singleNodeValue.textContent).times(1e3),
                        dx: BigNumber(dx.singleNodeValue.textContent).times(1e3),
                        dy: BigNumber(dy.singleNodeValue.textContent).times(1e3),
                        dz: BigNumber(dz.singleNodeValue.textContent).times(1e3),
                        plane: plane.singleNodeValue.textContent.substr(1),
                        power: (1e3 * +power.singleNodeValue.textContent).toFixed(6),
                    });
                } else {
                    console.log('Unsupported geometry', child);
                }
                child = child.nextElementSibling;
                ++index;
            }
            ret.reverse();
            ret.forEach((item, index) => {
                item.index = index;
            });
            return ret;
        }

        e.preventDefault();
        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                let parser = new DOMParser(),
                    xmlDoc = parser.parseFromString(e.target.result, 'application/xml');

                nsResolver = new XPathEvaluator().createNSResolver(xmlDoc);

                let
                    nameNode = xmlDoc.evaluate('/neutralXML/name', xmlDoc.documentElement, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE),
                    materialNodes = xmlDoc.evaluate('/neutralXML/materials/material', xmlDoc.documentElement, nsResolver, XPathResult.ORDERED_NODE_ITERATOR_TYPE),
                    geometryNode = xmlDoc.evaluate('/neutralXML/geometry', xmlDoc.documentElement, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE);

                if (nameNode.singleNodeValue == null)
                    throw 'Did not find project name';

                let json = {
                    project: {
                        name: nameNode.singleNodeValue.textContent,
                        folder: activeFolder.node.id,
                        world: {},
                    },
                    materials: parseMaterials(materialNodes),
                    tree: parseGeometry(geometryNode.singleNodeValue),
                };
                createProjectFromJSONFile(json);

            } catch (e) {
                if (e instanceof DOMException) {
                    confirmAlert({
                        title: 'Import error!',
                        message: 'Error occurred while parsing the xml file. Please make sure it has valid syntax.',
                        buttons: [
                            {
                                label: 'OK',
                                onClick: () => {
                                },
                            },
                        ],
                    });
                } else {
                    confirmAlert({
                        title: 'Import error!',
                        message: e,
                        buttons: [
                            {
                                label: 'OK',
                                onClick: () => {
                                },
                            },
                        ],
                    });
                }
            }
        };
        reader.readAsText(e.target.files[0]);
        inputECXMLRef.current.value = '';
    }

    function handleSnackbarClose() {
        setOpen(false);
    }

    function onMouseMove(e) {
        if (draggedProject) {
            setMousePosition({left: e.pageX, top: e.pageY});
        }
    }

    function resetDragFolder() {
        if (draggedProjectNextParentId !== -1 || draggedProject) {
            projectCardDragEnd();
        }
    }

    useEffect(() => {
        loadLibrary();
        getUserInfo();

        if (!treeData[0].children.length) {
            loadFolderAction();
        }
    }, []);

    useEffect(() => {
        if (user_info.licenses) {
            user_info.licenses
                .filter(item => item.expiration)
                .forEach(item => {
                let delta = new Date(item.expiration) - Date.now(),
                    days = Math.ceil(delta / 24 / 60 / 60 / 1000);
                if (delta < 0) {
                    setOpen(true);
                    setSeverity('error');
                    setMessage('License has expired');
                } else if (days < 14) {
                    setOpen(true);
                    setSeverity('warning');
                    setMessage(`License will expire in ${days} days.`);
                }
            });
        }
    }, [user_info]);

    useEffect(() => {
        if (activeFolder.path === null && activeFolder.node.name === MAIN_FOLDER_NAMES.sharedProjects) {
            loadSharedFolderProjectsAction();
            return;
        }

        if (activeFolder.node.id === null) {
            loadRootFolderProjectsAction();
            return;
        }

        loadActiveFolderProjectsAction({id: activeFolder.node.id});
    }, [activeFolder]);


    return (
        <div className={classes.root}
             onMouseMove={onMouseMove}
             onMouseUp={resetDragFolder}>
            <Header/>

            <div className={classes.main}>
                <Snackbar
                    open={open}
                    autoHideDuration={3000}
                    TransitionComponent={Slide}
                    onClose={handleSnackbarClose}>
                    <Alert variant={'filled'} onClose={handleSnackbarClose} severity={severity}>
                        <div>{message}</div>
                    </Alert>
                </Snackbar>
                {isLoading
                    ? <Spinner/>
                    : <Grid item className={`projects-wrapper`}>
                        <SideMenuProjectCardsTree/>
                        <div style={{width: '100%'}}>
                            <Box sx={{m: 1}} style={{padding: '12px 15px 10px 15px', margin: 0}}>
                                <Button
                                    id={'createproject'}
                                    variant={'contained'}
                                    color={'primary'}
                                    disabled={activeFolder.path === null && activeFolder.node.id === 0 && activeFolder.node.name === MAIN_FOLDER_NAMES.sharedProjects}
                                    onClick={() => showModal({
                                        type: 'custom',
                                        title: 'Create Project',
                                        content: (props) => {
                                            return <CreateProject {...props} />;
                                        },
                                    })}
                                >
                                    <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.plus}
                                                      hasHover={false}
                                                      secondClass={'smaller'}/>
                                    Project
                                </Button>
                                {
                                    !isImporting &&
                                    <FileImport
                                        disabled={activeFolder.path === null && activeFolder.node.id === 0 && activeFolder.node.name === MAIN_FOLDER_NAMES.sharedProjects}
                                        handleCSV={() => inputCSVRef.current.click()}
                                        handleECXML={() => inputECXMLRef.current.click()}
                                        handleJSON={() => inputJSONRef.current.click()}>
                                    </FileImport>
                                }
                                {
                                    isImporting &&
                                    <IconButton disabled={true} color="inherit" size="large">
                                        <CircularProgress size={24}/>
                                    </IconButton>

                                }
                                <input type={'file'}
                                       id={'csvfile'}
                                       onChange={loadCSVFile}
                                       ref={inputCSVRef}
                                       style={{display: 'none'}}/>
                                <input type={'file'}
                                       id={'jsonfile'}
                                       onChange={loadJSONFile}
                                       ref={inputJSONRef}
                                       style={{display: 'none'}}/>
                                <input type={'file'}
                                       id={'ecxmlfile'}
                                       onChange={loadECXMLFile}
                                       ref={inputECXMLRef}
                                       style={{display: 'none'}}/>
                                <Input
                                    className={classes.search}
                                    fullWidth={true}
                                    onChange={(event) => setFilter(event.target.value)}
                                    startAdornment={
                                        <InputAdornment position={'start'}>
                                            <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.search}
                                                              hasHover={false}
                                                              secondClass={'smaller'}/>
                                        </InputAdornment>
                                    }
                                    placeholder={'Find project'}
                                />
                            </Box>
                            {isProjectCardsLoading
                                ? <Spinner/>
                                : <Grid className={'projectsContainer'}
                                        onMouseEnter={() => draggedProjectNextParentId !== 0 && setDraggedProjectNextParent(0)}
                                        onMouseUp={resetDragFolder}>
                                    {
                                        projects && projects.length
                                            ? projects
                                                .filter(a => (a.name.toUpperCase().includes(filter.toUpperCase())))
                                                .sort((a, b) => {
                                                    if (a.last_modified < b.last_modified)
                                                        return 1;
                                                    else if (a.last_modified > b.last_modified)
                                                        return -1;
                                                    return 0;
                                                })
                                                .map(project => <ProjectCard key={project.id}
                                                                             className={classes.card}
                                                                             resetDragFolder={resetDragFolder}
                                                                             {...project}/>,
                                                )
                                            : null
                                    }
                                </Grid>
                            }
                        </div>
                    </Grid>
                }
            </div>
            {draggedProject
                ? <img className={'dragged-element-thumbnail'}
                       alt={'thumbnail'}
                       src={draggedProject.thumbnail}
                       style={{
                           left: mousePosition.left + 10,
                           top: mousePosition.top + 10,
                           zIndex: draggedProject ? '99999' : '-99',
                           backGround: `url(${draggedProject.thumbnail})`,
                       }}/>
                : null}
        </div>
    );
};

Dashboard.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isProjectCardsLoading: PropTypes.bool.isRequired,
    loadLibrary: PropTypes.func.isRequired,
    getUserInfo: PropTypes.func.isRequired,
    user_info: PropTypes.object.isRequired,
    createProjectFromJSONFile: PropTypes.func.isRequired,
    materials: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    showModal: PropTypes.func.isRequired,
    projects: PropTypes.arrayOf(PropTypes.object),
    activeFolder: PropTypes.any,
    setDraggedProjectNextParent: PropTypes.func.isRequired,
    draggedProjectNextParentId: PropTypes.number,
    draggedProject: PropTypes.object,
    projectCardDragEnd: PropTypes.func.isRequired,
    loadActiveFolderProjectsAction: PropTypes.func.isRequired,
    loadSharedFolderProjectsAction: PropTypes.func.isRequired,
    loadRootFolderProjectsAction: PropTypes.func.isRequired,
    loadFolderAction: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    isLoading: isLoading(state),
    isProjectCardsLoading: state.projects.projectCardsLoading,
    isImporting: isImporting(state),
    projects: getProjects(state),
    materials: getLibrary(state),
    user_info: hasUserInfo(state),
    activeFolder: getActiveFolder(state),
    draggedProjectNextParentId: getProjectCartNextParentId(state),
    draggedProject: getDraggedProject(state),
    treeData: getProjectsTreeData(state),
});

const mapDispatchToProps = {
    showModal,
    createProjectFromJSONFile,
    createProjectFromCSVFile,
    // createProjectFromECXMLFile,
    loadLibrary,
    getUserInfo,
    setDraggedProjectNextParent,
    projectCardDragEnd,
    loadActiveFolderProjectsAction,
    loadSharedFolderProjectsAction,
    loadRootFolderProjectsAction,
    loadFolderAction,
};

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(Dashboard);
