/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

export const UPDATE_PROJECT_HEATSINK = 'UPDATE_PROJECT_HEATSINK';
export const UPDATE_PROJECT_HEATSINK_ERROR = 'UPDATE_PROJECT_HEATSINK_ERROR';
export const UPDATE_PROJECT_HEATSINK_SUCCESS = 'UPDATE_PROJECT_HEATSINK_SUCCESS';

export const updateProjectHeatsink = payload => ({
    type: UPDATE_PROJECT_HEATSINK,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/heatsink/${payload.box.id}/`,
            method: 'PUT',
            body: JSON.stringify(payload.box),
        },
    },
});


export const UPDATE_PROJECT_HEATSINK_AND_TREE = 'UPDATE_PROJECT_HEATSINK_AND_TREE';
export const UPDATE_PROJECT_HEATSINK_AND_TREE_ERROR = 'UPDATE_PROJECT_HEATSINK_AND_TREE_ERROR';
export const UPDATE_PROJECT_HEATSINK_AND_TREE_SUCCESS = 'UPDATE_PROJECT_HEATSINK_AND_TREE_SUCCESS';

export const updateProjectHeatsinkAndTree = payload => ({
    type: UPDATE_PROJECT_HEATSINK_AND_TREE,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/heatsink/${payload.box.id}/`,
            method: 'PUT',
            body: JSON.stringify(payload.box),
        },
    },
});


export const CREATE_PROJECT_HEATSINK = 'CREATE_PROJECT_HEATSINK';
export const CREATE_PROJECT_HEATSINK_ERROR = 'CREATE_PROJECT_HEATSINK_ERROR';
export const CREATE_PROJECT_HEATSINK_SUCCESS = 'CREATE_PROJECT_HEATSINK_SUCCESS';

export const createProjectHeatsink = payload => ({
    type: CREATE_PROJECT_HEATSINK,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/heatsink/`,
            method: 'POST',
            body: JSON.stringify(payload.box),
        },
    },
});


export const CLONE_PROJECT_HEATSINK = 'CLONE_PROJECT_HEATSINK';
export const CLONE_PROJECT_HEATSINK_ERROR = 'CLONE_PROJECT_HEATSINK_ERROR';
export const CLONE_PROJECT_HEATSINK_SUCCESS = 'CLONE_PROJECT_HEATSINK_SUCCESS';

export const cloneProjectHeatsink = payload => ({
    type: CLONE_PROJECT_HEATSINK,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/heatsink/${payload.box.id}/clone/`,
            method: 'POST',
        },
    },
});


export const DELETE_PROJECT_HEATSINK = 'DELETE_PROJECT_HEATSINK';
export const DELETE_PROJECT_HEATSINK_ERROR = 'DELETE_PROJECT_HEATSINK_ERROR';
export const DELETE_PROJECT_HEATSINK_SUCCESS = 'DELETE_PROJECT_HEATSINK_SUCCESS';

export const deleteProjectHeatsink = payload => ({
    type: DELETE_PROJECT_HEATSINK,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/heatsink/${payload.box.id}/`,
            method: 'DELETE',
        },
    },
});