/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

// import PlaneSettings from '../project/components/settings/PlaneSettings';
import BoxSettingsNew from "../project/components/settings/new-components/BoxSettingsNew";
import BGASettingsNew from "../project/components/settings/new-components/BGASettingsNew";
import CylinderSettingsNew from "../project/components/settings/new-components/CylinderSettingsNew";
import HeatsinkSettingsNew from "../project/components/settings/new-components/HeatsinkSettingsNew";
import ViaArraySettingsNew from "../project/components/settings/new-components/ViaArraySettingsNew";
import PCBSettingsNew from "../project/components/settings/new-components/PCBSettingsNew";
import SourceSettingsNew from "../project/components/settings/new-components/SourceSettingsNew";
import TransientSourceSettingsNew from "../project/components/settings/new-components/TransientSourceSettingsNew";
import PolygonSettingsNew from "../project/components/settings/new-components/PolygonSettingsNew";
import AssemblySettingsNew from "../project/components/settings/new-components/AssemblySettingsNew";
import PlaneSettingsNew from "../project/components/settings/new-components/PlaneSettingsNew";


export const MAPPINGS = {
    projectBoxSettings: {
        TreeComponents: {
            box: BoxSettingsNew,
            ball_array: BGASettingsNew,
            pcb: PCBSettingsNew,
            heatsink: HeatsinkSettingsNew,
            via_array: ViaArraySettingsNew,
            cylinder: CylinderSettingsNew,
            assembly: AssemblySettingsNew,
            source: SourceSettingsNew,
            transient_source: TransientSourceSettingsNew,
            polygon: PolygonSettingsNew,
        },
        dragComponents: {
            box: BoxSettingsNew,
            heatsink: HeatsinkSettingsNew,
            ball_array: BGASettingsNew,
            via_array: ViaArraySettingsNew,
            pcb: PCBSettingsNew,
            cylinder: CylinderSettingsNew,
            // No drag and drop creation for assembly
            // assembly: AssemblySettings,
            source: SourceSettingsNew,
            transient_source: TransientSourceSettingsNew,
            polygon: PolygonSettingsNew,
        },
        intersectComponents: {
            box: BoxSettingsNew,
            heatsink: HeatsinkSettingsNew,
            plane: PlaneSettingsNew,
            ball_array: BGASettingsNew,
            via_array: ViaArraySettingsNew,
            pcb: PCBSettingsNew,
            cylinder: CylinderSettingsNew,
            assembly: AssemblySettingsNew,
            source: SourceSettingsNew,
            transient_source: TransientSourceSettingsNew,
            polygon: PolygonSettingsNew,
        },
    },
    projectTableSettings: {
        TreeComponents: {
            box: BoxSettingsNew,
            ball_array: BGASettingsNew,
            pcb: PCBSettingsNew,
            heatsink: HeatsinkSettingsNew,
            via_array: ViaArraySettingsNew,
            cylinder: CylinderSettingsNew,
            assembly: AssemblySettingsNew,
            source: SourceSettingsNew,
            transient_source: TransientSourceSettingsNew,
        },
    },
    titles: {
        box: 'Box',
        ball_array: 'Ball Array',
        pcb: 'PCB',
        heatsink: 'Heatsink',
        via_array: 'Via Array',
        assembly: 'Assembly',
        plane: 'Plane',
        cylinder: 'Cylinder',
        source: 'Source',
        transient_source: 'Transient Source',
        polygon: 'Polygon',
    },
    updateProjectObjectAndTree: {
        'box': 'updateProjectBoxAndTree',
        'ball_array': 'updateProjectBGAAndTree',
        'pcb': 'updateProjectPCBAndTree',
        'heatsink': 'updateProjectHeatsinkAndTree',
        'via_array': 'updateProjectViaArrayAndTree',
        'cylinder': 'updateProjectCylinderAndTree',
        'assembly': 'updateProjectAssemblyAndTree',
        'source': 'updateProjectSourceAndTree',
        'transient_source': 'updateProjectTransientSourceAndTree',
        'polygon': 'updateProjectPolygonAndTree',
    },
    updateProjectObject: {
        'box': 'updateProjectBox',
        'ball_array': 'updateProjectBGA',
        'pcb': 'updateProjectPCB',
        'heatsink': 'updateProjectHeatsink',
        'via_array': 'updateProjectViaArray',
        'cylinder': 'updateProjectCylinder',
        'assembly': 'updateProjectAssembly',
        'source': 'updateProjectSource',
        'transient_source': 'updateProjectTransientSource',
        'polygon': 'updateProjectPolygon',
    },
    deleteProjectObject: {
        'box': 'deleteProjectBox',
        'ball_array': 'deleteProjectBGA',
        'pcb': 'deleteProjectPCB',
        'heatsink': 'deleteProjectHeatsink',
        'via_array': 'deleteProjectViaArray',
        'cylinder': 'deleteProjectCylinder',
        'assembly': 'deleteProjectAssembly',
        'source': 'deleteProjectSource',
        'transient_source': 'deleteProjectTransientSource',
        'polygon': 'deleteProjectPolygon',
    },
    cloneProjectObject: {
        'box': 'cloneProjectBox',
        'ball_array': 'cloneProjectBGA',
        'pcb': 'cloneProjectPCB',
        'heatsink': 'cloneProjectHeatsink',
        'via_array': 'cloneProjectViaArray',
        'cylinder': 'cloneProjectCylinder',
        'assembly': 'cloneAssembly',
        'source': 'cloneProjectSource',
        'transient_source': 'cloneProjectTransientSource',
        'polygon': 'cloneProjectPolygon',
    },
    boxTypes: {
        box: 'box',
        ball_array: 'bga',
        pcb: 'pcb',
        heatsink: 'heatsink',
        via_array: 'via_array',
        cylinder: 'cylinder',
        assembly: 'assembly',
        source: 'source',
        transient_source: 'transient_source',
        polygon: 'polygon',
    },
    drawObjectsByType: {
        box: 'drawBox',
        ball_array: 'drawBGA',
        pcb: 'drawPCB',
        heatsink: 'drawHeatsink',
        via_array: 'drawViaArray',
        cylinder: 'drawCylinder',
        source: 'drawSource',
        transient_source: 'drawTransientSource',
        polygon: 'drawPolygon',
        plane: 'drawPlane',
    }
};

export const FONT_AWESOME_ICONS_TYPE_MAP = {
    play: 'play',
    plus: 'plus',
    plusCircle: 'plusCircle',
    delete: 'delete',
    refresh: 'refresh',
    clone: 'clone',
    share: 'share',
    properties: 'properties',
    zoomToElement: 'zoomToElement',
    zoomAll: 'zoomAll',
    translate: 'translate',
    eyeVisible: 'eyeVisible',
    eyeNotVisible: 'eyeNotVisible',
    importArrow: 'importArrow',
    importCloud: 'importCloud',
    importCloudSolid: 'importCloudSolid',
    export: 'export',
    exportSolid: 'exportSolid',
    addFolder: 'addFolder',
    moreVertDots: 'moreVertDots',
    caretExpand: 'caretExpand',
    caretCollapse: 'caretCollapse',
    caretArrowDown: 'caretArrowDown',
    caretArrowUp: 'caretArrowUp',
    powerInactive: 'powerInactive',
    powerActive: 'powerActive',
    warning: 'warning',
    stop: 'stop',
    search: 'search',
    spinner: 'spinner',
    circleCheck: 'circleCheck',
    cameraRotate: 'cameraRotate',
    '1 mm': '1 mm',
    '0.1 mm': '0.1 mm',
    '0.01 mm': '0.01 mm',
    '0.001 mm': '0.001 mm',
    'Free Mode': 'Free Mode',
}
export const FONT_AWESOME_ICONS = {
    play: {
        main: {
            baseClassName: 'far',
        },
        hover: {
            baseClassName: 'fas',
        },
        className: 'fa-play',
    },
    plus: {
        main: {
            baseClassName: 'fal',
        },
        hover: {
            baseClassName: 'fas',
        },
        className: 'fa-plus',
    },
    plusCircle: {
        main: {
            baseClassName: 'fal',
        },
        hover: {
            baseClassName: 'fas',
        },
        className: 'fa-circle-plus',
    },
    delete: {
        main: {
            baseClassName: 'fal',
        },
        hover: {
            baseClassName: 'fas',
        },
        className: 'fa-trash',
    },
    refresh: {
        main: {
            baseClassName: 'fal',
        },
        hover: {
            baseClassName: 'fas',
        },
        className: 'fa-arrow-rotate-right',
    },
    clone: {
        main: {
            baseClassName: 'fal',
        },
        hover: {
            baseClassName: 'fas',
        },
        className: 'fa-clone',
    },
    share: {
        main: {
            baseClassName: 'fal',
        },
        hover: {
            baseClassName: 'fas',
        },
        className: 'fa-share-nodes',
    },
    properties: {
        main: {
            baseClassName: 'far',
        },
        hover: {
            baseClassName: 'fas',
        },
        className: 'fa-gear',
    },
    zoomToElement: {
        main: {
            baseClassName: 'fal',
        },
        hover: {
            baseClassName: 'far',
        },
        className: 'fa-expand',
    },
    zoomAll: {
        main: {
            baseClassName: 'fal',
        },
        hover: {
            baseClassName: 'far',
        },
        className: 'fa-arrow-up-right-and-arrow-down-left-from-center',
    },
    translate: {
        main: {
            baseClassName: 'fal',
        },
        hover: {
            baseClassName: 'far',
        },
        className: 'fa-arrows-up-down-left-right',
    },
    eyeVisible: {
        main: {
            baseClassName: 'fas',
        },
        hover: {
            baseClassName: 'fas',
        },
        className: 'fa-eye',
    },
    eyeNotVisible: {
        main: {
            baseClassName: 'fal',
        },
        hover: {
            baseClassName: 'fal',
        },
        className: 'fa-eye',
    },
    importArrow: {
        main: {
            baseClassName: 'fal',
        },
        hover: {
            baseClassName: 'fas',
        },
        className: 'fa-arrow-down-to-arc',
    },
    importCloud: {
        main: {
            baseClassName: 'fal',
        },
        hover: {
            baseClassName: 'fas',
        },
        className: 'fa-cloud-arrow-up',
    },
    importCloudSolid: {
        main: {
            baseClassName: 'fas',
        },
        hover: {
            baseClassName: 'fal',
        },
        className: 'fa-cloud-arrow-up',
    },
    export: {
        main: {
            baseClassName: 'fal',
        },
        hover: {
            baseClassName: 'fas',
        },
        className: 'fa-cloud-arrow-down',
    },
    exportSolid: {
        main: {
            baseClassName: 'fas',
        },
        hover: {
            baseClassName: 'fal',
        },
        className: 'fa-cloud-arrow-down',
    },
    addFolder: {
        main: {
            baseClassName: 'fal',
        },
        hover: {
            baseClassName: 'fas',
        },
        className: 'fa-folder-plus',
    },
    moreVertDots: {
        main: {
            baseClassName: 'fal',
        },
        hover: {
            baseClassName: 'fas',
        },
        className: 'fa-ellipsis-vertical',
    },
    caretExpand: {
        main: {
            baseClassName: 'fal',
        },
        hover: {
            baseClassName: 'fas',
        },
        className: 'fa-caret-down',
    },
    caretCollapse: {
        main: {
            baseClassName: 'fal',
        },
        hover: {
            baseClassName: 'fas',
        },
        className: 'fa-caret-up',
    },
    caretArrowDown: {
        main: {
            baseClassName: 'fal',
        },
        hover: {
            baseClassName: 'fas',
        },
        className: 'fa-angle-down',
    },
    caretArrowUp: {
        main: {
            baseClassName: 'fal',
        },
        hover: {
            baseClassName: 'fas',
        },
        className: 'fa-angle-up',
    },
    stop: {
        main: {
            baseClassName: 'fal',
        },
        hover: {
            baseClassName: 'fas',
        },
        className: 'fa-stop',
    },
    powerInactive: {
        main: {
            baseClassName: 'fal',
        },
        hover: {
            baseClassName: 'fal',
        },
        className: 'fa-plug',
    },
    powerActive: {
        main: {
            baseClassName: 'fas',
        },
        hover: {
            baseClassName: 'fas',
        },
        className: 'fa-plug',
    },
    warning: {
        main: {
            baseClassName: 'fas',
        },
        className: 'fa-triangle-exclamation',
    },
    search: {
        main: {
            baseClassName: 'fal',
        },
        className: 'fa-magnifying-glass',
    },
    spinner: {
        main: {
            baseClassName: 'fal',
        },
        className: 'fa-spinner-third',
    },
    circleCheck: {
        main: {
            baseClassName: 'far',
        },
        className: 'fa-circle-check',
    },
    cameraRotate: {
        main: {
            baseClassName: 'fal',
        },
        hover: {
            baseClassName: 'fas',
        },
        className: 'fa-camera-rotate',
    },
    //grid icons
    '1 mm': {
        main: {
            baseClassName: 'fal',
        },
        hover: {
            baseClassName: 'far',
        },
        active: {
            baseClassName: 'fas',
        },
        className: 'fa-grid-2',
    },
    '0.1 mm': {
        main: {
            baseClassName: 'fal',
        },
        hover: {
            baseClassName: 'far',
        },
        active: {
            baseClassName: 'fas',
        },
        className: 'fa-grid',
    },
    '0.01 mm': {
        main: {
            baseClassName: 'fal',
        },
        hover: {
            baseClassName: 'far',
        },
        active: {
            baseClassName: 'fas',
        },
        className: 'fa-grid-4',
    },
    '0.001 mm': {
        main: {
            baseClassName: 'fal',
        },
        hover: {
            baseClassName: 'far',
        },
        active: {
            baseClassName: 'fas',
        },
        className: 'fa-grid-5',
    },
    'Free Mode': {
        main: {
            baseClassName: 'fal',
        },
        hover: {
            baseClassName: 'far',
        },
        active: {
            baseClassName: 'fas',
        },
        className: 'fa-scribble',
    },
};