/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {useEffect, useState} from 'react';
import {compose} from 'redux';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {confirmAlert} from 'react-confirm-alert';

import withStyles from '@mui/styles/withStyles';
import {
    Button, Input, InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';

import {showModal} from '../../../../modals/actions';
import {deleteScenario, runScenario} from '../../../+store/actions/scenarios';
import {setScenarioTabs} from '../../../../core/+store-ui-state/actions/scenarios';

import {scenarioTabs} from '../../../../core/+store-ui-state/reducers/scenarios';

import {Tabs, Tab} from '@mui/material';

import ScenarioCaseComponent from './components/scenario-case.component';
import TabPanel from '../../../../components/TabPanel';
import ScenarioSettingsComponent from './components/scenario-settings.component';
import ScenarioParamSettingsComponent from './components/scenario-param-settings.component';
import {IconsFontAwesome} from '../../../../components/IconsFontAwesome';
import {FONT_AWESOME_ICONS_TYPE_MAP} from '../../../../core/mappings';
import {projectUtils} from '../../../utils';


const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        display: 'flex',
    },
    panel: {
        margin: 10,
    },
    parametersTab: {
        margin: theme.spacing(2),
        width: '100%',
    },
    casesTab: {
        margin: theme.spacing(2),
    },
    tableContainer: {
        margin: theme.spacing(2),
    },
    search: {
        margin: theme.spacing(2),
    },
    button: {
        marginRight: 10,
    },

});


const ScenariosComponent = ({
                                classes, scenarios: {data, loading}, current_project,
                                showModal, deleteScenario, scenarioTabs, setScenarioTabs,
                            }) => {
    const [tab, setTab] = useState(scenarioTabs.tab);
    const [itemTab, setItemTab] = useState(scenarioTabs.tabItem);
    const [filter, setFilter] = useState('');

    function showSettingsComponent({project_id, scenario = false}) {
        showModal({
            type: 'custom',
            title: `${scenario ? 'Edit' : 'Create'} Scenario`,
            content: (props) => {
                return <ScenarioSettingsComponent project_id={project_id} scenario={scenario} {...props}/>;
            },
        });
    }

    function handleTabChange(event, value) {
        setTab(value);
    }

    function handleItemTabChange(event, value, scenario) {
        setItemTab(value);
    }

    useEffect(() => {
        setScenarioTabs({
            tab,
            tabItem: itemTab,
        });
    }, [tab, itemTab]);


    return (
        <div className={classes.panel}>
            {
                current_project &&
                (current_project.owner || current_project.edit) &&
                <Button
                    variant={'contained'}
                    color={'primary'}
                    className={classes.button}
                    onClick={() => showSettingsComponent({project_id: current_project.id})}
                >
                    <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.plus} secondClass={'smaller'}
                                      hasHover={false}/>
                    Scenario
                </Button>
            }
            <div className={classes.root}>
                {data &&
                    <Tabs value={tab} onChange={(event, value) => handleTabChange(event, value)}
                          orientation={'vertical'}>
                        {
                            data
                                .sort((a, b) => {
                                    if (a.name < b.name) return -1;
                                    else if (a.name > b.name) return 1;
                                    return 0;
                                })
                                .map((scenario, idx) => {
                                    return <Tab key={idx} label={scenario.name}/>;
                                })
                        }
                    </Tabs>
                }
                {
                    data.map((scenario, idx) => {
                        return (
                            <TabPanel value={tab} index={idx} className={classes.casesTab}
                                      key={scenario.id}>
                                <Typography variant={'h4'} sx={{display: 'flex', alignItems: 'center'}}>
                                    Scenario {scenario.name}
                                    {
                                        current_project &&
                                        (current_project.owner || current_project.edit) &&
                                        <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.properties}
                                                          titleAccess={`Show ${scenario.name} Properties`}
                                                          id={'updatematerial'}
                                                          secondClass={'smaller'}
                                                          onClickHandler={() => showSettingsComponent({
                                                              project_id: current_project.id,
                                                              scenario,
                                                          })}/>
                                    }
                                    {
                                        current_project &&
                                        (current_project.owner || current_project.edit) &&
                                        <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.delete}
                                                          titleAccess={`Delete ${scenario.name}`}
                                                          id={'deletematerial'}
                                                          secondClass={'smaller'}
                                                          onClickHandler={() => {
                                                              confirmAlert({
                                                                  // overlayClassName: 'anemoi-confirm-alert-overlay',
                                                                  title: 'Delete scenario?',
                                                                  message: 'Are you sure?',
                                                                  buttons: [
                                                                      {
                                                                          label: 'Delete',
                                                                          onClick: () => {
                                                                              deleteScenario({
                                                                                  project_id: current_project.id,
                                                                                  scenario,
                                                                              });
                                                                              setTab(Math.max(0, Math.min(tab, data.length - 2)));
                                                                          },
                                                                      },
                                                                      {
                                                                          label: 'Cancel',
                                                                          onClick: () => {
                                                                          },
                                                                      },
                                                                  ],
                                                              });
                                                          }}/>
                                    }
                                </Typography>
                                <Tabs value={itemTab}
                                      onChange={(event, value) => handleItemTabChange(event, value, scenario)}>
                                    <Tab label={'Configuration'}/>
                                    <Tab label={'Cases'}/>
                                </Tabs>
                                <TabPanel value={itemTab} index={0}>
                                    <Input
                                        className={classes.search}
                                        value={filter}
                                        fullWidth={true}
                                        onChange={(event) => setFilter(event.target.value)}
                                        startAdornment={
                                            <InputAdornment position={'start'}>
                                                <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.search}
                                                                  secondClass={'smaller'} hasHover={false}/>
                                            </InputAdornment>
                                        }
                                        placeholder={'Find parameter'}
                                    />
                                    <TableContainer component={Paper} className={classes.tableContainer}>
                                        <Table>
                                            <colgroup>
                                                <col width={'20%'}/>
                                                <col width={'40%'}/>
                                                <col width={'40%'}/>
                                            </colgroup>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Parameter</TableCell>
                                                    <TableCell>Configuration</TableCell>
                                                    <TableCell>Description</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    scenario.parameters
                                                            .filter(a => (!filter || a.parameter_name.toUpperCase().includes(filter.toUpperCase())))
                                                            .sort((a, b) => {
                                                                if (a.parameter_name < b.parameter_name) return -1;
                                                                else if (a.parameter_name > b.parameter_name) return 1;
                                                                return 0;
                                                            })
                                                            .map(param => {
                                                                return <TableRow hover key={param.id}>
                                                                    <TableCell>
                                                                        {param.parameter_name}
                                                                        <IconsFontAwesome
                                                                            titleAccess={`Show ${param.parameter_name} Properties`}
                                                                            secondClass={'smaller1'}
                                                                            iconsSX={{ml: '5px'}}
                                                                            iconType={FONT_AWESOME_ICONS_TYPE_MAP.properties}
                                                                            onClickHandler={(event) => {
                                                                                projectUtils.stopPreventDefault(event);

                                                                                showModal({
                                                                                    type: 'custom',
                                                                                    title: `Edit parameter configuration`,
                                                                                    content: (props) => {
                                                                                        return (
                                                                                            <ScenarioParamSettingsComponent
                                                                                                param={param}
                                                                                                project_id={current_project.id}
                                                                                                scenario={scenario}
                                                                                                {...props}/>
                                                                                        );
                                                                                    },
                                                                                });
                                                                            }}/>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {param.default && `${param.default_value} (default)`}
                                                                        {param.values && `Values: ${param.values.join(', ')}`}
                                                                        {param.random && `Random values: Min: ${param.random.min} Max: ${param.random.max} Count: ${param.random.count}`}
                                                                        {param.normal_dist && `Normal distribution values: Mean: ${param.normal_dist.mean} Sigma: ${param.normal_dist.stddev} Count: ${param.normal_dist.count}`}
                                                                    </TableCell>
                                                                    <TableCell>{param.parameter_description}</TableCell>
                                                                </TableRow>;
                                                            })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </TabPanel>
                                <TabPanel value={itemTab} index={1}>
                                    {
                                        current_project &&
                                        <ScenarioCaseComponent current_project={current_project} scenario={scenario}/>
                                    }
                                </TabPanel>
                            </TabPanel>
                        );
                    })
                }
            </div>
        </div>
    );
};

ScenariosComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    current_project: PropTypes.object,
    showModal: PropTypes.func.isRequired,
    scenarios: PropTypes.object.isRequired,
    scenarioTabs: PropTypes.object.isRequired,
    setScenarioTabs: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    scenarios: state.current_project.scenarios,
    scenarioTabs: scenarioTabs(state),
});

const mapDispatchToProps = {
    showModal,
    deleteScenario,
    setScenarioTabs,
};

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(ScenariosComponent);
