/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';
import _ from 'lodash';
import './ProjectTable.scss';

import {BigNumber} from 'bignumber.js';
import withStyles from '@mui/styles/withStyles';

import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TableContainer,
    Typography,
    Button,
    Menu,
    MenuItem,
    TableBody,
    ButtonGroup,
    CircularProgress,
    Box,
} from '@mui/material';

import {showModal} from '../../../../modals/actions';
import {
    updateProjectAssembly,
    toggleProjectTable
} from '../../../+store/actions/actions';

import {getData} from '../../../+store/reducer/project';
import {getData as getTree, isLoading as loadingTree} from '../../../+store/reducer/tree';

import AssemblyTranslate from '../../settings/AssemblyTranslate';

import SolveButton from '../../../../components/SolveButton';
import {IconsFontAwesome} from '../../../../components/IconsFontAwesome';

import {FONT_AWESOME_ICONS_TYPE_MAP, MAPPINGS} from '../../../../core/mappings';
import {projectUtils} from '../../../utils';

const styles = (theme) => ({
    tablePanel: {
        margin: 10,
    }, tableCell: {
        whiteSpace: 'nowrap',
    }, cellEven: {
        // backgroundColor: '#383838'
    }, cellOdd: {
        // backgroundColor: '#303030'
    },
});


const ProjectTable = ({
                          classes,
                          solution,
                          current_project,
                          tree,
                          loading,
                          showModal,
                          updateProjectAssembly,
                          toggleProjectTable,
                      }) => {
    const [tableTree, setTableTree] = useState(tree);
    const [anchorEl, setAnchorEl] = useState(null);
    const [view, setView] = useState('eq');

    let power = 0;

    const debouncedUpdateProjectTree = useCallback(
        _.debounce((tableTree) => {
            toggleProjectTable(tableTree);
        }, 200),
        [],
    );

    const getSolutionMin = (solution, item) => {
        if (item.type !== 'assembly') {
            return solution &&
                solution.result &&
                solution.result.hasOwnProperty(`${item.id}.${item['type_id']}`) &&
                solution.result[`${item.id}.${item['type_id']}`].hasOwnProperty('min_t') &&
                solution.result[`${item.id}.${item['type_id']}`].min_t.toFixed(2);
        } else {
            let min = BigNumber.min(...item.children
                .map(child => getSolutionMin(solution, child))
                .filter(item => item)
                .map(item => BigNumber(item)));

            if (min.isFinite()) return min.toFixed(2); else return undefined;
        }
    };

    const getSolutionMax = (solution, item) => {
        if (item.type !== 'assembly') {
            return solution &&
                solution.result &&
                solution.result.hasOwnProperty(`${item.id}.${item['type_id']}`) &&
                solution.result[`${item.id}.${item['type_id']}`].hasOwnProperty('max_t') &&
                solution.result[`${item.id}.${item['type_id']}`].max_t.toFixed(2);
        } else {
            let max = BigNumber.max(...item.children
                .map(child => getSolutionMax(solution, child))
                .filter(item => item)
                .map(item => BigNumber(item)));

            if (max.isFinite()) return max.toFixed(2); else return undefined;
        }
    };

    const getSolutionDissipatedPower = (solution, item) => {
        if (item.type !== 'assembly') {
            return solution &&
                solution.result &&
                solution.result.hasOwnProperty(`${item.id}.${item['type_id']}`) &&
                solution.result[`${item.id}.${item['type_id']}`].hasOwnProperty('dissipated_power') &&
                solution.result[`${item.id}.${item['type_id']}`]['dissipated_power'].toFixed(2)
                || undefined;
        } else {
            let sum = item.children
                .map(child => getSolutionDissipatedPower(solution, child))
                .filter(item => item)
                .reduce((a, b) => BigNumber.sum(a, b), 0);
            if (sum) return sum.toFixed(2); else return undefined;
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const addObjectType = (type) => {
        const SettingsComponent = MAPPINGS.projectTableSettings.TreeComponents[type];

        showModal({
            type: 'custom', title: `Add ${MAPPINGS.titles[type]}`, content: (props) => {
                return (
                    <SettingsComponent assembly={{active: true, index: tree.length, parent: null,}}
                                       box={{active: true, index: tree.length, parent: null,}}
                                       inModal={true}
                                       {...props}/>
                );
            },
        });

        handleClose();
    };

    const downloadCSV = (csv) => {
        const csvFile = new Blob([csv], {type: 'text/csv'});

        // Download link
        const downloadLink = document.createElement('a');

        // File name
        downloadLink.download = `${current_project.name} table.csv`;

        // Create a link to the file
        downloadLink.href = window.URL.createObjectURL(csvFile);

        // Hide download link
        downloadLink.style.display = 'none';

        // Add the link to DOM
        document.body.appendChild(downloadLink);

        // Click download link
        downloadLink.click();
    };

    const exportTableToCSV = () => {
        const csv = [];
        const rows = document.querySelectorAll('#solutiontable tr');

        for (let i = 0; i < rows.length; i++) {
            const row = [], cols = rows[i].querySelectorAll('td, th');

            for (let j = 0; j < cols.length; j++) if (i === 0) {
                row[0] = 'Solution results';
                row[4] = 'Minimum';
                row[7] = 'Dimensions';
                row[10] = 'Maximum';
            } else {
                row.push(cols[j].innerText);
            }

            csv.push(row.join(','));
        }

        // Download CSV file
        downloadCSV(csv.join('\n'));
    };

    // function editBox(event, node) {
    //     const SettingsComponent = MAPPINGS.projectTableSettings.TreeComponents[node.type];
    //
    //     if (!SettingsComponent) {
    //         return console.log('Unknown box edit type', node);
    //     }
    //
    //     showModal({
    //         type: 'custom',
    //         title: `Edit ${MAPPINGS.titles[node.type]}`,
    //         content: (props) => {
    //             return <SettingsComponent {...props} assembly={node} box={node}/>;
    //         },
    //     });
    // }

    function toggleNodeCollapsed(node) {
        const {children, ...rest} = node;
        setTableTree(projectUtils.toggleProjectTableTree(tableTree, node));

        updateProjectAssembly(
            {
                project_id: current_project.id,
                assembly: {
                    ...rest,
                    collapsed: node.collapsed,
                },
            }
        );
    }

    // function translateAssembly(event, assembly) {
    //     showModal({
    //         type: 'custom',
    //         title: 'Translate assembly',
    //         content: (props) => {
    //             return <AssemblyTranslate {...props} assembly={assembly}/>;
    //         },
    //     });
    // }

    function renderChildren(children, indent, visible) {
        return (children.map((item, idx) => {
            const addToPower = solution &&
                solution.result &&
                solution.result.hasOwnProperty(`${item.id}.${item['type_id']}`) &&
                solution.result[`${item.id}.${item['type_id']}`].hasOwnProperty('dissipated_power') &&
                +solution.result[`${item.id}.${item['type_id']}`]['dissipated_power'];

            if (addToPower) {
                power += addToPower;
            }


            return <React.Fragment key={`${item.id}.${item['type_id']}.${idx}.${indent + idx}`}>
                {visible
                    ? <TableRow hover>
                        <TableCell className={classes.tableCell}>
                            <span style={{
                                marginLeft: 20 * indent,
                                opacity: item.visible ? '1' : '0.5',
                                textDecoration: item.active ? '' : 'line-through red',
                            }} className={'m-node'}>
                                {!item.collapsed && item.children && <span
                                    className={'caret-down'}
                                    onClick={() => toggleNodeCollapsed(item)}
                                    style={{marginRight: 5}}/>}
                                {item.collapsed && item.children && <span
                                    className={'caret-right'}
                                    onClick={() => toggleNodeCollapsed(item)}
                                    style={{marginRight: 5}}/>}
                                {item.name}
                                {/*<IconsFontAwesome id={'updatematerial'}*/}
                                {/*                  iconType={FONT_AWESOME_ICONS_TYPE_MAP.properties}*/}
                                {/*                  titleAccess={`Show ${item.name} Properties`}*/}
                                {/*                  secondClass={'smaller1'}*/}
                                {/*                  iconsSX={{ml: '8px'}}*/}
                                {/*                  onClickHandler={(event) => {*/}
                                {/*                      projectUtils.stopPreventDefault(event);*/}
                                {/*                      editBox(event, item);*/}
                                {/*                  }}/>*/}

                                {/*{*/}
                                {/*    item.type === 'assembly'*/}
                                {/*        ? <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.translate}*/}
                                {/*                            titleAccess={`Translate ${item.name}`}*/}
                                {/*                            secondClass={'smaller1'}*/}
                                {/*                            onClickHandler={(event) => {*/}
                                {/*                                projectUtils.stopPreventDefault(event);*/}
                                {/*                                translateAssembly(event, item);*/}
                                {/*                            }}/>*/}
                                {/*        : null*/}
                                {/*}*/}
                            </span>
                        </TableCell>
                        <TableCell align={'right'} className={classes.cellOdd}>
                            {getSolutionMin(solution, item)}
                        </TableCell>
                        <TableCell align={'right'} className={classes.cellEven}>
                            {getSolutionMax(solution, item)}
                        </TableCell>
                        <TableCell align={'right'} className={classes.cellOdd}>
                            {getSolutionDissipatedPower(solution, item)}
                        </TableCell>
                        <TableCell align={'right'}
                                   className={classes.cellEven}>{view === 'eq' ? item.x : item.x_calc}</TableCell>
                        <TableCell align={'right'}
                                   className={classes.cellOdd}>{view === 'eq' ? item.y : item.y_calc}</TableCell>
                        <TableCell align={'right'}
                                   className={classes.cellEven}>{view === 'eq' ? item.z : item.z_calc}</TableCell>
                        {item.type === 'heatsink' && <>
                            <TableCell align={'right'} className={classes.cellOdd}>
                                {view === 'eq' ? item.base_dx : item.base_dx_calc}
                            </TableCell>
                            <TableCell align={'right'} className={classes.cellEven}>
                                {view === 'eq' ? item.base_dy : item.base_dy_calc}
                            </TableCell>
                            <TableCell align={'right'} className={classes.cellOdd}>
                                {view === 'eq' ? (`${item.base_dz} + ${item.fin_height}`) : BigNumber.sum(item.base_dz_calc, item.fin_height_calc).toString()}
                            </TableCell>
                            <TableCell align={'right'} className={classes.cellEven}>
                                {view === 'eq' ? '' : BigNumber.sum(item.x_calc, item.base_dx_calc).toString()}
                            </TableCell>
                            <TableCell align={'right'} className={classes.cellOdd}>
                                {view === 'eq' ? '' : BigNumber.sum(item.y_calc, item.base_dy_calc).toString()}
                            </TableCell>
                            <TableCell align={'right'} className={classes.cellEven}>
                                {view === 'eq' ? '' : BigNumber.sum(item.z_calc, item.base_dz_calc, item.fin_height_calc).toString()}
                            </TableCell>
                        </>}
                        {item.type !== 'heatsink' && <>
                            <TableCell align={'right'}
                                       className={classes.cellOdd}>{view === 'eq' ? item.dx : item.dx_calc}</TableCell>
                            <TableCell align={'right'}
                                       className={classes.cellEven}>{view === 'eq' ? item.dy : item.dy_calc}</TableCell>
                            <TableCell align={'right'}
                                       className={classes.cellOdd}>{view === 'eq' ? item.dz : item.dz_calc}</TableCell>
                            <TableCell align={'right'}
                                       className={classes.cellEven}>{view === 'eq' ? '' : item.x_calc && BigNumber.sum(item.x_calc, item.dx_calc).toString()}</TableCell>
                            <TableCell align={'right'}
                                       className={classes.cellOdd}>{view === 'eq' ? '' : item.y_calc && BigNumber.sum(item.y_calc, item.dy_calc).toString()}</TableCell>
                            <TableCell align={'right'}
                                       className={classes.cellEven}>{view === 'eq' ? '' : item.z_calc && BigNumber.sum(item.z_calc, item.dz_calc).toString()}</TableCell>
                        </>}
                    </TableRow>
                    : null}
                {item.children && renderChildren(item.children, indent + 1, !item.collapsed && visible)}
            </React.Fragment>;
        }));
    }

    useEffect(() => {
        debouncedUpdateProjectTree(tableTree);
    }, [tableTree]);


    return (
        <Typography component={'div'} className={classes.tablePanel}>
            {current_project && (current_project.owner || current_project.edit) && <>
                <Button
                    id={'objectbtn'}
                    variant={'contained'}
                    color={'primary'}
                    onClick={(event) => setAnchorEl(event.currentTarget)}>
                    <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.plus} hasHover={false}/>
                    Object
                </Button>
                <Menu
                    id={'menu-appbar'}
                    anchorEl={anchorEl || false}
                    anchorOrigin={{
                        vertical: 'top', horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top', horizontal: 'left',
                    }}
                    open={!!anchorEl}
                    onClose={handleClose}
                >
                    <MenuItem id={'dropdownboxid'} onClick={() => addObjectType('box')}>Box</MenuItem>
                    <MenuItem id={'dropdownheatsinkid'} onClick={() => addObjectType('heatsink')}>Heatsink</MenuItem>
                    <MenuItem id={'dropdownballarrayid'} onClick={() => addObjectType('ball_array')}>
                        Ball Array
                    </MenuItem>
                    <MenuItem id={'dropdownsubstrateid'} onClick={() => addObjectType('pcb')}>PCB/Substrate</MenuItem>
                    <MenuItem id={'dropdownviaarrayid'} onClick={() => addObjectType('via_array')}>Via Array</MenuItem>
                    <MenuItem id={'dropdowncylinderid'} onClick={() => addObjectType('cylinder')}>Cylinder</MenuItem>
                </Menu>
            </>}
            <Box component={'span'} style={{marginLeft: 15}}>View</Box>
            <ButtonGroup style={{marginLeft: 10}}>
                <Button variant={view === 'eq' ? 'contained' : 'outlined'}
                        className={'noMR'}
                        onClick={() => setView('eq')}>
                    Raw
                </Button>
                <Button variant={view === 'calc' ? 'contained' : 'outlined'}
                        onClick={() => setView('calc')}>
                    Calculated
                </Button>
            </ButtonGroup>

            {
                <SolveButton current_project={current_project}
                             tree={tree}
                             btnVariant={''}
                             wrapperClass={'table'}
                             btnColor={'primary'}/>
            }
            {
                current_project && view === 'calc'
                    ? <Button
                        variant={'contained'}
                        onClick={exportTableToCSV}
                        sx={{ml: '8px'}}
                        title={'Download Current Table As CSV File'}>
                        <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.exportSolid} secondClass={'smaller'}
                                          hasHover={false}/>
                        CSV
                    </Button>
                    : null
            }
            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table id={'solutiontable'}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {loading && <CircularProgress
                                    size={20} style={{color: 'white'}}
                                    thickness={5}/>}
                            </TableCell>
                            <TableCell colSpan={2} align={'center'} sx={{color: (theme) => theme.palette.warning.main}}>
                                Solution results
                                {
                                    solution &&
                                    solution.timestamp &&
                                    (new Date(solution.timestamp)) < projectUtils.getTreeTimestamp(tree) &&
                                    <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.warning}
                                                      titleAccess={solution.result && !Object.keys(solution.result).length ? 'No solution' : 'Solution is stale'}
                                                      secondClass={'smaller'}
                                                      iconsSX={{ml: '5px'}}/>
                                }
                            </TableCell>
                            <TableCell colSpan={3} align={'center'}>Minimum</TableCell>
                            <TableCell colSpan={3} align={'center'}>Dimensions</TableCell>
                            <TableCell colSpan={3} align={'center'}>Maximum</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align={'right'}>Min T [&deg;C]</TableCell>
                            <TableCell align={'right'}>Max T [&deg;C]</TableCell>
                            <TableCell align={'right'}>Dissipated power [mW]</TableCell>
                            <TableCell align={'right'}>X [mm]</TableCell>
                            <TableCell align={'right'}>Y [mm]</TableCell>
                            <TableCell align={'right'}>Z [mm]</TableCell>
                            <TableCell align={'right'}>DX [mm]</TableCell>
                            <TableCell align={'right'}>DY [mm]</TableCell>
                            <TableCell align={'right'}>DZ [mm]</TableCell>
                            <TableCell align={'right'}>X [mm]</TableCell>
                            <TableCell align={'right'}>Y [mm]</TableCell>
                            <TableCell align={'right'}>Z [mm]</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderChildren(tableTree, 0, true)}
                        <TableRow>
                            <TableCell/>
                            <TableCell/>
                            <TableCell/>
                            <TableCell align={'right'} id={'totalpower'}>
                                {power.toFixed(2)}
                            </TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Typography>
    );
};


ProjectTable.propTypes = {
    current_project: PropTypes.object,
    tree: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    solution: PropTypes.object,
};

const mapStateToProps = state => ({
    current_project: getData(state),
    tree: getTree(state),
    loading: loadingTree(state),
});

const mapDispatchToProps = {
    showModal,
    updateProjectAssembly,
    toggleProjectTable,
};

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps),)(ProjectTable);
