/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React from 'react';

import * as THREE from "three";
import {CylinderArrayGeometry} from "../../geoms/CylinderArrayGeometry";

import Draggable3DIcon from './component/Draggable3DIcon';

export default function ViaArray3DIcon() {
    return <Draggable3DIcon materials={[new THREE.MeshPhongMaterial({color: 0xdb9828})]}
                            boxes={[new CylinderArrayGeometry(
                                -10, 0, -10,
                                4, 1, 4,
                                6, 6, 6, 1,
                                1, 8, 16
                            )]}
                            rotateObj={false}
                            lightPosition={{x: 1, y: 1, z: 1}}
                            tooltipTitle={'Create Via Array object'}
                            objId={'viaarrayID'}
                            dragType={'via_array'}
    />;
}