import React from 'react';
import { FONT_AWESOME_ICONS_TYPE_MAP } from "../core/mappings";
import { IconsFontAwesome } from './IconsFontAwesome';

const DownloadTransientData = ({ transient, project }) => {
  const exportTransientData = () => {
    const csv = [];
    const rows = document.querySelectorAll('#solutiontable tr');
    csv.push(['Time (s)', 'Max Temperature (C)']);

    transient.forEach((transientRow) => {
      const { time, max_t } = transientRow;
      csv.push([time, max_t]);
    })

    const csvFile = new Blob([csv.join('\n')], { type: 'text/csv' });

    const downloadLink = document.createElement('a');
    downloadLink.download = `${project.name} transient data.csv`;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };

  return (<>
    <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.export}
      titleAccess={`Download transient data as CSV`}
      secondClass={'smaller'}
      mainSX={{ marginLeft: '10px' }}
      hoveredSX={{ marginLeft: '10px' }}
      onClickHandler={exportTransientData} />
  </>
  )
};

export default DownloadTransientData;