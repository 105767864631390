/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

export const UPDATE_PROJECT_BGA = 'UPDATE_PROJECT_BGA';
export const UPDATE_PROJECT_BGA_ERROR = 'UPDATE_PROJECT_BGA_ERROR';
export const UPDATE_PROJECT_BGA_SUCCESS = 'UPDATE_PROJECT_BGA_SUCCESS';

export const updateProjectBGA = payload => ({
    type: UPDATE_PROJECT_BGA,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/bga/${payload.box.id}/`,
            method: 'PUT',
            body: JSON.stringify(payload.box),
        },
    },
});


export const UPDATE_PROJECT_BGA_AND_TREE = 'UPDATE_PROJECT_BGA_AND_TREE';
export const UPDATE_PROJECT_BGA_AND_TREE_ERROR = 'UPDATE_PROJECT_BGA_AND_TREE_ERROR';
export const UPDATE_PROJECT_BGA_AND_TREE_SUCCESS = 'UPDATE_PROJECT_BGA_AND_TREE_SUCCESS';

export const updateProjectBGAAndTree = payload => ({
    type: UPDATE_PROJECT_BGA_AND_TREE,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/bga/${payload.box.id}/`,
            method: 'PUT',
            body: JSON.stringify(payload.box),
        },
    },
});


export const CREATE_PROJECT_BGA = 'CREATE_PROJECT_BGA';
export const CREATE_PROJECT_BGA_ERROR = 'CREATE_PROJECT_BGA_ERROR';
export const CREATE_PROJECT_BGA_SUCCESS = 'CREATE_PROJECT_BGA_SUCCESS';

export const createProjectBGA = payload => ({
    type: CREATE_PROJECT_BGA,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/bga/`,
            method: 'POST',
            body: JSON.stringify(payload.box),
        },
    },
});


export const CLONE_PROJECT_BGA = 'CLONE_PROJECT_BGA';
export const CLONE_PROJECT_BGA_ERROR = 'CLONE_PROJECT_BGA_ERROR';
export const CLONE_PROJECT_BGA_SUCCESS = 'CLONE_PROJECT_BGA_SUCCESS';

export const cloneProjectBGA = payload => ({
    type: CLONE_PROJECT_BGA,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/bga/${payload.box.id}/clone/`,
            method: 'POST',
        },
    },
});


export const DELETE_PROJECT_BGA = 'DELETE_PROJECT_BGA';
export const DELETE_PROJECT_BGA_ERROR = 'DELETE_PROJECT_BGA_ERROR';
export const DELETE_PROJECT_BGA_SUCCESS = 'DELETE_PROJECT_BGA_SUCCESS';

export const deleteProjectBGA = payload => ({
    type: DELETE_PROJECT_BGA,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/bga/${payload.box.id}/`,
            method: 'DELETE',
        },
    },
});