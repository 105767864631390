/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {DialogContent, FormControl, Dialog, DialogActions, Button} from '@mui/material';
import {TextValidator} from 'react-material-ui-form-validator';

import {createFolderAction, updateTreeDataAction} from '../../../actions';

import MyValidationForm from '../../../../components/MyValidationForm';
import DialogTitle from '../../../../modals/components/DialogTitle';


const FolderInputComponent = ({contextMenuInfo, onSubmitHandler, folderDialogAction, closeDialog}) => {
    const formRef = useRef(null);

    const [folderName, setFolderName] = useState(folderDialogAction.actionType === 'Rename Folder' ? contextMenuInfo.node.name : '');
    const noChangeOfName = folderName !== contextMenuInfo?.createFolderName && folderName !== contextMenuInfo?.editFolderName;

    function onSubmit() {
        formRef.current
               .isFormValid(false)
               .then(is_valid => {
                   if (is_valid) {
                       if (noChangeOfName) {
                           onSubmitHandler(folderName);
                       }
                   }
               });
    }


    return (
        <Dialog onClick={(e) => e.stopPropagation()}
                open={folderDialogAction.open}
                onClose={(event, reason) => {
                    if (reason === 'backdropClick') {
                        return;
                    }

                    closeDialog();
                }}
                onContextMenu={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                aria-labelledby={'alert-dialog-title'}
                aria-describedby={'alert-dialog-description'}
                maxWidth={false}
        >
            <DialogTitle id="foldermodaltitle" onClose={closeDialog}>
                {folderDialogAction.actionType}
            </DialogTitle>
            <DialogContent dividers>
                <MyValidationForm ref={formRef}
                                  onSubmit={onSubmit}
                                  className={'validation-form-sm'}>
                    <FormControl variant={'standard'}>
                        <TextValidator id="foldernameid"
                                       style={{width: '50ch'}}
                                       label={'Folder Name'}
                                       value={folderName}
                                       onChange={e => setFolderName(e.target.value)}
                                       variant={'standard'}
                                       validators={['required']}
                                       errorMessages={['Folder name can not be empty']}
                        />
                    </FormControl>
                </MyValidationForm>
            </DialogContent>
            <DialogActions>
                <Button id="foldermodalbtn"
                        onClick={onSubmit}
                        variant={'contained'}
                        color={'secondary'}
                        disabled={!noChangeOfName}
                        autoFocus>
                    {folderDialogAction.actionType}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

FolderInputComponent.propTypes = {
    contextMenuInfo: PropTypes.object,
    onSubmitHandler: PropTypes.func.isRequired,
    folderDialogAction: PropTypes.object.isRequired,
    closeDialog: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
    createFolderAction,
    updateTreeDataAction,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
)(FolderInputComponent);
