/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {Fragment} from 'react';
import {MenuItem, Menu} from '@mui/material';
import {IconsFontAwesome} from './IconsFontAwesome';
import {FONT_AWESOME_ICONS_TYPE_MAP} from '../core/mappings';


export default function FileImport(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Fragment>
            <IconsFontAwesome aria-label={'account of current user'}
                              aria-controls={'menu-appbar'}
                              aria-haspopup={'true'}
                              onClickHandler={handleMenu}
                              secondClass={'import-menu-icon'}
                              iconType={FONT_AWESOME_ICONS_TYPE_MAP.importCloudSolid} disabled={!!props.disabled}/>
            <Menu
                id={'menu-appbar'}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={open}
                onClose={handleClose}>

                <MenuItem onClick={() => {
                    props.handleECXML();
                    handleClose();
                }}>
                    Import ECXML
                </MenuItem>
                <MenuItem onClick={() => {
                    props.handleJSON();
                    handleClose();
                }}>
                    Import JSON
                </MenuItem>
                <MenuItem onClick={() => {
                    props.handleCSV();
                    handleClose();
                }}>
                    Import CSV
                </MenuItem>
            </Menu>
        </Fragment>
    );
}
