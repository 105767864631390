/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {
    CREATE_PLANE_SUCCESS,
    DELETE_PLANE_SUCCESS,
    LOAD_PROJECT_PLANES,
    LOAD_PROJECT_PLANES_ERROR,
    LOAD_PROJECT_PLANES_SUCCESS,
    UPDATE_PLANE,
    UPDATE_PLANE_SUCCESS,
} from '../actions/planes';

export const defaultState = {
    data: [],
    loading: false,
};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case LOAD_PROJECT_PLANES:
            return {
                ...state,
                data: [],
                loading: true,
                validation_errors: [],
            };
        case LOAD_PROJECT_PLANES_ERROR:
            return {
                data: [],
                loading: false,
            };
        case LOAD_PROJECT_PLANES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case CREATE_PLANE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: (state.data || []).concat(action.payload),
            };
        case UPDATE_PLANE:
            return {
                ...state,
                data: state.data.map(x => x.id === action.payload.plane.id ? action.payload.plane : x),
            };
        case UPDATE_PLANE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: state.data.map(x => x.id === action.payload.id ? action.payload : x),
            };
        case DELETE_PLANE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: state.data.filter(x => x.id !== action.payload.planeid),
            }
        default:
            return state;
    }
};

export const hasData = ({current_project}) => !!current_project.planes.data;

export const getData = ({current_project}) => current_project.planes.data;

export const isLoading = ({current_project}) => current_project.planes.loading;