/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';

import withStyles from '@mui/styles/withStyles';
import {
    Button,
    DialogContent,
    DialogActions,
    InputAdornment,
    Typography,
    FormControl,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import {TextValidator} from 'react-material-ui-form-validator';
import {updateProjectMaterial, createProjectMaterial} from '../../+store/actions/project';
import {getValidationErrors} from '../../+store/reducer/validation_errors';

import ColorPicker from '../../../components/ColorPicker';
import MyValidationForm from '../../../components/MyValidationForm';
import {projectUtils} from '../../utils';

const styles = (theme) => ({
    root: {
        '& > *': {
            width: '25ch',
        },
    },
});

const material_fields = ['name', 'color', 'density', 'specific_heat', 'anisotropic', 'conductivity', 'conductivity_y', 'conductivity_z'];


class MaterialSettings extends Component {

    constructor(props) {
        super(props);

        const material = props.material || {
            name: '',
            color: '#000',
            density: null,
            specific_heat: null,
            anisotropic: false,
            conductivity: null,
            conductivity_y: null,
            conductivity_z: null,
        };

        this.form = React.createRef();

        this.state = projectUtils.updateSettingsProps({fields: material_fields, props: material});

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(field_name, value) {
        this.setState({
            [field_name]: value,
        });
    }

    materialHasChange() {
        const {material} = this.props;
        if (material)
            return Object.keys(this.state).some((key) => this.state[key] !== material[key]);
        return true;
    }

    handleSubmit() {
        this.form.current.isFormValid(false)
            .then(is_valid => {
                if (is_valid) {
                    const {onClose, project, material, updateProjectMaterial, createProjectMaterial} = this.props;

                    if (!this.materialHasChange()) {
                        return onClose();
                    }
                    if (material)
                        updateProjectMaterial({
                            project, material: {
                                ...material,
                                ...this.state,
                            },
                        });
                    else
                        createProjectMaterial({
                            project,
                            materials: [this.state],
                        });
                }
            });
    }


    render() {
        const {classes, material, validation_errors} = this.props;
        const {
            name,
            color,
            density,
            specific_heat,
            anisotropic,
            conductivity,
            conductivity_y,
            conductivity_z,
        } = this.state;

        return (
            <Fragment>
                <DialogContent dividers>
                    <MyValidationForm
                        autoComplete={'off'}
                        ref={this.form}
                        onSubmit={this.handleSubmit}
                        className={'validation-form-sm'}
                    >
                        <TextValidator
                            className={classes.root}
                            label={'Name'}
                            id={'materialname'}
                            value={name || ''}
                            onChange={event => this.handleChange('name', event.target.value)}
                            variant={'standard'}
                            error={!!validation_errors.name}
                            helperText={validation_errors.name}
                            validators={['required']}
                            errorMessages={['This field is required!', 'No']}
                        />
                        <div
                            style={{
                                display: 'inline-block',
                                position: 'relative',
                            }}
                        >
                            <ColorPicker
                                label={'Color'}
                                id={'color'}
                                variant={'standard'}
                                disablealpha={'true'}
                                className={classes.root}
                                value={color || ''}
                                onChange={color => this.handleChange('color', color)}
                                TextFieldProps={{value: color}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    sx: {width: '80%'},
                                    startAdornment:
                                        <InputAdornment position={'start'}>
                                            <div style={{
                                                background: this.state['color'],
                                                border: '1px solid black',
                                                width: '24px',
                                                height: '24px',
                                                marginRight: '5px',
                                            }}/>
                                        </InputAdornment>,
                                    endAdornment:
                                        <InputAdornment position={'end'}>
                                            HEX
                                        </InputAdornment>
                                }}
                            />
                        </div>
                        <Typography variant={'h6'} className={classes.title}>
                            Steady state parameters (required)
                        </Typography>
                        <FormControl fullWidth={true}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value={anisotropic}
                                        checked={anisotropic}
                                        onChange={() => this.setState({anisotropic: !anisotropic})}
                                    />
                                }
                                label={'Anisotropic'}
                            />
                        </FormControl>
                        <TextValidator
                            label={anisotropic ? 'Conductivity (X)' : 'Conductivity'}
                            id={'thermalconductivity'}
                            className={classes.root}
                            value={conductivity || ''}
                            onChange={event => this.handleChange('conductivity', event.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position={'start'}>W/m&#8729;K</InputAdornment>,
                            }}
                            variant={'standard'}
                            error={!!validation_errors.conductivity}
                            helperText={validation_errors.conductivity}
                            validators={['required']}
                            errorMessages={['This field is required!']}
                        />
                        {
                            anisotropic &&
                            <TextValidator
                                label="Conductivity (Y)"
                                id={'thermalconductivity'}
                                className={classes.root}
                                value={conductivity_y || ''}
                                onChange={event => this.handleChange('conductivity_y', event.target.value)}
                                InputProps={{
                                    endAdornment: <InputAdornment position={'start'}>W/m&#8729;K</InputAdornment>,
                                }}
                                variant={'standard'}
                                error={!!validation_errors.conductivity_y}
                                helperText={validation_errors.conductivity_y}
                                validators={['required']}
                                errorMessages={['This field is required!']}
                            />
                        }
                        {
                            anisotropic &&
                            <TextValidator
                                label="Conductivity (Z)"
                                id={'thermalconductivity'}
                                className={classes.root}
                                value={conductivity_z || ''}
                                onChange={event => this.handleChange('conductivity_z', event.target.value)}
                                InputProps={{
                                    endAdornment: <InputAdornment position={'start'}>W/m&#8729;K</InputAdornment>,
                                }}
                                variant={'standard'}
                                error={!!validation_errors.conductivity_z}
                                helperText={validation_errors.conductivity_z}
                                validators={['required']}
                                errorMessages={['This field is required!']}
                            />
                        }
                        <Typography variant={'h6'} className={classes.title}>
                            Transient Parameters (optional)
                        </Typography>
                        <TextValidator
                            type={'number'}
                            step={'any'}
                            label={'Density'}
                            id={'density'}
                            className={classes.root}
                            value={density || ''}
                            onChange={event => this.handleChange('density', event.target.value || undefined)}
                            InputProps={{
                                endAdornment: <InputAdornment position={'end'}>kg/m&sup3;</InputAdornment>,
                            }}
                            variant={'standard'}
                            error={!!validation_errors.density}
                            helperText={validation_errors.density}
                            validators={['isCustomPositive']}
                            errorMessages={['This field is required!', 'Enter a positive number!']}
                        />
                        <TextValidator
                            type={'number'}
                            step={'any'}
                            label={'Specific Heat'}
                            id={'specificheat'}
                            className={classes.root}
                            value={specific_heat}
                            onChange={event => this.handleChange('specific_heat', event.target.value || undefined)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">J/kg&#8729;K</InputAdornment>,
                            }}
                            variant={'standard'}
                            error={!!validation_errors.specific_heat}
                            helperText={validation_errors.specific_heat}
                            validators={['isCustomPositive']}
                            errorMessages={['This field is required!', 'Enter a positive number!']}
                        />
                    </MyValidationForm>
                </DialogContent>
                <DialogActions>
                    <Button id={'addmaterial'} type={'submit'} onClick={this.handleSubmit} variant={'contained'}
                            color={'primary'}>
                        {material ? 'Update' : 'Add'}
                    </Button>
                </DialogActions>
            </Fragment>
        );
    }
}


MaterialSettings.propTypes = {
    onClose: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    material: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    validation_errors: getValidationErrors(state),
});

const mapDispatchToProps = {
    updateProjectMaterial,
    createProjectMaterial,
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(MaterialSettings);
