/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {useState, useRef} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {TextValidator} from 'react-material-ui-form-validator';

import withStyles from '@mui/styles/withStyles';
import {
    DialogContent,
    Button,
    FormControl,
    DialogActions,
    FormLabel,
    RadioGroup,
    FormControlLabel, Radio
} from '@mui/material';

import MyValidationForm from '../../../../../components/MyValidationForm';

import {updateScenarioParam} from '../../../../+store/actions/scenarios';

import {getValidationErrors} from '../../../../+store/reducer/validation_errors';

const styles = (theme) => ({
    root: {
        '& > *': {
            width: '100ch',
        },
    },
    inputs: {
        '& > *': {
            marginBottom: theme.spacing(1),
            marginRight: theme.spacing(2),
            width: 150,
        },
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
});


const ScenarioParamSettingsComponent = ({
                                            classes, validation_errors, project_id, scenario, param,
                                            updateScenarioParam,
                                            ...rest
                                        }) => {
    const form = useRef();
    const [type, setType] = useState(param && (
        param.default && "default" ||
        param.values && "values" ||
        param.random && "random" ||
        param.normal_dist && "normal_dist"
    ) || "default")
    const [fields, setFields] = useState({
        default: param.default || false,
        values: param.values && param.values.join(', ') || '',
        random: param.random || {
            min: '',
            max: '',
            count: '',
        },
        normal_dist: param.normal_dist || {
            mean: '',
            stddev: '',
            min: null,
            max: null,
            count: '',
        },
    });

    function handleChange(name, value) {
        if (type === "values") {
            setFields({
                ...fields,
                values: value
            });
        } else if (type === "random") {
            setFields({
                ...fields,
                [type]: {
                    ...fields[type],
                    [name]: value,
                }
            });
        } else if (type === "normal_dist") {
            setFields({
                ...fields,
                [type]: {
                    ...fields[type],
                    [name]: value !=='' && value || null,
                }
            });
        }
    }

    function handleSubmit() {
        console.log(fields);
        form.current.isFormValid(false)
            .then(is_valid => {
                if (is_valid) {
                    updateScenarioParam({
                        project_id,
                        scenario: scenario,
                        parameter: {
                            id: param.id,
                            parameter: param.parameter,
                            default: type === "default",
                            values: (type === "values") && fields.values.split(',') || null,
                            random: (type === "random") && fields.random || null,
                            normal_dist: (type === "normal_dist") && fields.normal_dist || null,
                        },
                    });
                }
            });
    }


    return (
        <>
            <DialogContent dividers>
                <MyValidationForm
                    ref={form}
                    onSubmit={handleSubmit}
                    className={'validation-form-sm'}>
                    <FormControl component={"fieldset"}>
                        <FormLabel>Values</FormLabel>
                        <RadioGroup row value={type} onChange={event => setType(event.target.value)}>
                            <FormControlLabel control={<Radio/>} label={"Default"} value={"default"}/>
                            <FormControlLabel control={<Radio/>} label={"Values"} value={"values"}/>
                            <FormControlLabel control={<Radio/>} label={"Random"} value={"random"}/>
                            <FormControlLabel control={<Radio/>} label={"Normal distribution"} value={"normal_dist"}/>
                        </RadioGroup>
                    </FormControl>
                    {
                        (type === "default") && <p>
                            Parameter will use the default value in its definition
                        </p>
                    }
                    {
                        (type === "values") && <>
                            <p>
                                Parameter will use a list of comma separated values
                            </p>
                            <FormControl variant={'standard'} fullWidth={true}>
                                <TextValidator
                                    fullWidth={true}
                                    id={'values'}
                                    key={'values'}
                                    label={'Values'}
                                    value={fields.values || ''}
                                    onChange={(event) => handleChange('values', event.target.value)}
                                    variant={'standard'}
                                    error={!!validation_errors['values']}
                                    helperText={validation_errors['values']}
                                    validators={['required']}
                                    errorMessages={['One or more comma separated values are required!']}/>
                            </FormControl>
                        </>
                    }
                    {
                        (type === "random") && <>
                            <p>
                                Parameter values will be randomly generated
                            </p>
                            <FormControl variant={'standard'}>
                                <TextValidator
                                    id={'min'}
                                    key={'min'}
                                    label={'Min value'}
                                    className={classes.inputs}
                                    value={fields.random.min || ''}
                                    onChange={(event) => handleChange('min', event.target.value)}
                                    variant={'standard'}
                                    type={"number"}
                                    step={"any"}
                                    error={!!validation_errors['random.min']}
                                    helperText={validation_errors['random.min']}
                                    validators={['required']}
                                    errorMessages={['Min value is required!']}/>
                            </FormControl>
                            <FormControl variant={'standard'}>
                                <TextValidator
                                    id={'max'}
                                    key={'max'}
                                    label={'Max value'}
                                    className={classes.inputs}
                                    value={fields.random.max || ''}
                                    onChange={(event) => handleChange('max', event.target.value)}
                                    variant={'standard'}
                                    type={"number"}
                                    step={"any"}
                                    error={!!validation_errors['random.max']}
                                    helperText={validation_errors['random.max']}
                                    validators={['required']}
                                    errorMessages={['Max value is required!']}/>
                            </FormControl>
                            <FormControl variant={'standard'}>
                                <TextValidator
                                    id={'count'}
                                    key={'count'}
                                    label={'Number of values'}
                                    className={classes.inputs}
                                    value={fields.random.count || ''}
                                    onChange={(event) => handleChange('count', event.target.value)}
                                    variant={'standard'}
                                    type={"number"}
                                    step={"1"}
                                    error={!!validation_errors['random.count']}
                                    helperText={validation_errors['random.count']}
                                    validators={['required', 'minNumber:1']}
                                    errorMessages={['Max value is required!', 'A positive number is expected']}/>
                            </FormControl>
                        </>
                    }
                    {
                        (type === "normal_dist") && <>
                            <p>
                                Parameter values will be a Gaussian distribution
                            </p>
                            <FormControl variant={'standard'}>
                                <TextValidator
                                    id={'mean'}
                                    key={'mean'}
                                    label={'Mean value'}
                                    className={classes.inputs}
                                    value={fields.normal_dist.mean || ''}
                                    onChange={(event) => handleChange('mean', event.target.value)}
                                    variant={'standard'}
                                    type={"number"}
                                    step={"any"}
                                    error={!!validation_errors['normal_dist.mean']}
                                    helperText={validation_errors['normal_dist.mean']}
                                    validators={['required']}
                                    errorMessages={['Mean value is required!']}/>
                            </FormControl>
                            <FormControl variant={'standard'}>
                                <TextValidator
                                    id={'stddev'}
                                    key={'stddev'}
                                    label={'Variance'}
                                    className={classes.inputs}
                                    value={fields.normal_dist.stddev || ''}
                                    onChange={(event) => handleChange('stddev', event.target.value)}
                                    variant={'standard'}
                                    type={"number"}
                                    step={"any"}
                                    error={!!validation_errors['normal_dist.stddev']}
                                    helperText={validation_errors['normal_dist.stddev']}
                                    validators={['required']}
                                    errorMessages={['Variance value is required!']}/>
                            </FormControl>
                            <FormControl variant={'standard'}>
                                <TextValidator
                                    id={'count'}
                                    key={'count'}
                                    label={'Number of values'}
                                    className={classes.inputs}
                                    value={fields.normal_dist.count || ''}
                                    onChange={(event) => handleChange('count', event.target.value)}
                                    variant={'standard'}
                                    type={"number"}
                                    step={"1"}
                                    error={!!validation_errors['normal_dist.count']}
                                    helperText={validation_errors['normal_dist.count']}
                                    validators={['required', 'minNumber:1']}
                                    errorMessages={['Max value is required!', 'A positive number is expected']}/>
                            </FormControl>
                            <FormControl variant={'standard'}>
                                <TextValidator
                                    id={'min'}
                                    key={'min'}
                                    label={'Min value'}
                                    className={classes.inputs}
                                    value={fields.normal_dist.min || ''}
                                    onChange={(event) => handleChange('min', event.target.value)}
                                    variant={'standard'}
                                    type={"number"}
                                    step={"any"}
                                    error={!!validation_errors['random.min']}
                                    helperText={validation_errors['random.min']}
                                    validators={[]}
                                    errorMessages={[]}/>
                            </FormControl>
                            <FormControl variant={'standard'}>
                                <TextValidator
                                    id={'max'}
                                    key={'max'}
                                    label={'Max value'}
                                    className={classes.inputs}
                                    value={fields.normal_dist.max || ''}
                                    onChange={(event) => handleChange('max', event.target.value)}
                                    variant={'standard'}
                                    type={"number"}
                                    step={"any"}
                                    error={!!validation_errors['random.max']}
                                    helperText={validation_errors['random.max']}
                                    validators={[]}
                                    errorMessages={[]}/>
                            </FormControl>
                        </>
                    }
                </MyValidationForm>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit} id={'update_param'} variant={'contained'} color={'primary'} autoFocus>
                    Update
                </Button>
            </DialogActions>
        </>
    );
};


ScenarioParamSettingsComponent.propTypes = {};

const mapStateToProps = state => ({
    validation_errors: getValidationErrors(state),
});

const mapDispatchToProps = {
    updateScenarioParam,
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(ScenarioParamSettingsComponent);
