/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {Menu, MenuItem, Icon, Typography, Box, CircularProgress} from '@mui/material';

import {showModal} from '../../../../../../modals/actions';
import {updateProjectAndTree as updateProject} from '../../../../../../dashboard/actions';

import {getData as getTree} from '../../../../../+store/reducer/tree';
import {
    getData,
    getProjectMaterials,
    getProjectStepData,
    isDownloadingStepData
} from '../../../../../+store/reducer/project';

import {clearDownloadProjectStep, downloadProjectStep} from "../../../../../+store/actions/actions";

import {IconsFontAwesome} from '../../../../../../components/IconsFontAwesome';
import ProjectSettings from '../../../../../../dashboard/components/ProjectSettings';
import ProjectPermissions from '../../../../../../dashboard/components/ProjectPermissions';

import {FONT_AWESOME_ICONS, FONT_AWESOME_ICONS_TYPE_MAP} from '../../../../../../core/mappings';
import {downloadUtils, projectUtils} from '../../../../../utils';


const ProjectControls = ({
                             current_project, tree, showModal, updateProject,
                             materials, parameters, scenarios, planes,
                             downloadProjectStep, stepData, clearDownloadProjectStep,
                             isDownloadingStepData
                         }) => {
    const doECXMLDownloadRef = useRef('');
    const doJSONDownloadRef = useRef('');
    const doCSVDownloadRef = useRef('');

    const [anchorEl, setAnchorEl] = useState(null);
    const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(null);
    const [csvDownloadUrl, setCsvDownloadUrl] = useState(null);
    const [jsonDownloadUrl, setJsonDownloadUrl] = useState(null);
    const [ecxmlDownloadUrl, setEcxmlDownloadUrl] = useState(null);

    const downloadCSV = () => {
        const text = {csv: 'name,type,x0,y0,z0,dx,dy,dz,material,param1,param2,param3,param4,param5,\n'};
        downloadUtils.addText({items: tree, text});
        const fileDownloadUrl = downloadUtils.getFileDownloadUrl({blobParts: text.csv});

        setAnchorEl(null);
        setCsvDownloadUrl(fileDownloadUrl);
    };

    const downloadJSON = () => {
        const fileDownloadUrl = downloadUtils.getFileDownloadUrl({
            blobParts: JSON.stringify({
                parameters,
                materials,
                project: current_project,
                scenarios,
                tree,
                planes,
            }, null, 1),
        });

        setAnchorEl(null);
        setJsonDownloadUrl(fileDownloadUrl);
    };

    const downloadECXML = () => {
        const doc = document.implementation.createDocument('', '', null),
            composites = {};

        const rootElem = doc.createElement('neutralXML');
        doc.appendChild(rootElem);
        rootElem.setAttribute('xmlns:ats', 'https://anemoisoftware.com/');

        let tmp = doc.createElement('name');
        tmp.innerHTML = current_project.name;
        rootElem.appendChild(tmp);

        tmp = doc.createElement('producer');
        tmp.innerHTML = 'DankaThermal';
        rootElem.appendChild(tmp);

        tmp = doc.createElement('ats:timestamp');
        tmp.innerHTML = Date().toLocaleString();
        rootElem.appendChild(tmp);

        downloadUtils.writeSolutionDomain({node: rootElem, tree, current_project, doc});
        downloadUtils.writeMaterials({node: rootElem, tree, materials, composites, doc});
        downloadUtils.writeGeometry({
            node: rootElem,
            tree,
            hier_name: '/',
            lumped: current_project.world.lumped,
            composites,
            doc
        });

        const xsltDoc = new DOMParser().parseFromString([
            // describes how we want to modify the XML - indent everything
            '<xsl:stylesheet version="1.0.0" xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
            '  <xsl:strip-space elements="*"/>',
            '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
            '    <xsl:value-of select="normalize-space(.)"/>',
            '  </xsl:template>',
            '  <xsl:template match="node()|@*">',
            '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
            '  </xsl:template>',
            '  <xsl:output indent="yes"/>',
            '</xsl:stylesheet>',
        ].join('\n'), 'application/xml');

        const xsltProcessor = new XSLTProcessor();
        xsltProcessor.importStylesheet(xsltDoc);
        const resultDoc = xsltProcessor.transformToDocument(doc);

        let str = new XMLSerializer().serializeToString(resultDoc);
        str = '<?xml version="1.0" encoding="UTF-8" standalone="no"?>\n' + str;

        const fileDownloadUrl = downloadUtils.getFileDownloadUrl({blobParts: str});

        setAnchorEl(null);
        setEcxmlDownloadUrl(fileDownloadUrl);
    };

    const handleToggleMoreMenu = (event, show = false) => {
        setMoreMenuAnchorEl(show ? event.currentTarget : null);
    };

    const showShareProjectModal = (event) => {
        projectUtils.stopPreventDefault(event);

        showModal({
            type: 'custom',
            title: 'Share Project',
            content: (props) => {
                return <ProjectPermissions project={current_project} {...props} />;
            },
        });
    };

    const showUpdateProjectModal = (event) => {
        projectUtils.stopPreventDefault(event);

        showModal({
            type: 'custom',
            title: 'Update Project',
            content: (props) => {
                return <ProjectSettings project={current_project} updateProject={updateProject} {...props} />;
            },
        });
    };

    useEffect(() => {
        if (ecxmlDownloadUrl && doECXMLDownloadRef.current) {
            doECXMLDownloadRef.current.click();
            URL.revokeObjectURL(ecxmlDownloadUrl);  // free up storage--no longer needed.

            setEcxmlDownloadUrl(null);
        }
    }, [ecxmlDownloadUrl]);

    useEffect(() => {
        if (csvDownloadUrl && doCSVDownloadRef.current) {
            doCSVDownloadRef.current.click();
            URL.revokeObjectURL(csvDownloadUrl);  // free up storage--no longer needed.

            setCsvDownloadUrl(null);
        }
    }, [csvDownloadUrl]);

    useEffect(() => {
        if (jsonDownloadUrl && doJSONDownloadRef.current) {
            doJSONDownloadRef.current.click();
            URL.revokeObjectURL(jsonDownloadUrl);  // free up storage--no longer needed.

            setJsonDownloadUrl(null);
        }
    }, [jsonDownloadUrl]);

    useEffect(() => {
        if (stepData) {
            downloadUtils.downloadFileAs({
                string: stepData,
                fileName: `${current_project && current_project.name}.stp`,
                fileType: 'application/step',
            })

            setAnchorEl(null);
            clearDownloadProjectStep();
        }
    }, [stepData, current_project]);


    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                mb: '10px',
                height: '28px',
                p: '2px 4px 4px 14px',
            }}
            onMouseEnter={(e) => handleToggleMoreMenu(e, true)}
            onMouseLeave={handleToggleMoreMenu}>
            <Typography variant={'faded'} size={'info'}
                        sx={{opacity: 1}}
                        onMouseEnter={(e) => handleToggleMoreMenu(e, true)}>
                {current_project && current_project.name.toUpperCase()}
            </Typography>

            <Box sx={{
                alignItems: 'center', display: 'flex', float: 'right', height: '21px',
                position: 'relative',
            }}>
                <div className={'more-menu-icons '}
                     style={{
                         zIndex: !moreMenuAnchorEl ? -1 : 0,
                         opacity: !moreMenuAnchorEl ? 0 : 1,
                         transform: !moreMenuAnchorEl ? 'translateX(25px)' : 'translateX(0)',
                     }}>
                    {
                        current_project && current_project.owner &&
                        <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.share}
                                          titleAccess={'Share'}
                                          onClickHandler={showShareProjectModal}/>
                    }
                    <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.export}
                                      titleAccess={'Download'}
                                      onClickHandler={(event) => setAnchorEl(event.currentTarget)}/>
                    <Menu
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                    >
                        <MenuItem onClick={(event) => downloadECXML(event)}>
                            Download ECXML
                            <a className={'hidden'}
                               download={current_project && current_project.name + '.xml'}
                               href={ecxmlDownloadUrl}
                               ref={doECXMLDownloadRef}
                            />
                        </MenuItem>
                        <MenuItem onClick={downloadJSON}>
                            Download JSON
                            <a className={'hidden'}
                               download={current_project && current_project.name + '.json'}
                               href={jsonDownloadUrl}
                               ref={doJSONDownloadRef}
                            />
                        </MenuItem>
                        <MenuItem onClick={(event) => downloadCSV(event)}>
                            Download CSV
                            <a className={'hidden'}
                               download={current_project && current_project.name + '.csv'}
                               href={csvDownloadUrl}
                               ref={doCSVDownloadRef}
                            />
                        </MenuItem>
                        <MenuItem
                            onClick={() => !isDownloadingStepData && downloadProjectStep({project_id: current_project.id})}>
                            <Typography component={'span'} sx={{marginRight: isDownloadingStepData ? 1.5 : 0}}>Download STEP</Typography>
                            {
                                isDownloadingStepData
                                    ? <CircularProgress style={{marginLeft: 1}} color={'secondary'} size={24}/>
                                    : null
                            }
                        </MenuItem>
                    </Menu>
                </div>
                {
                    current_project &&
                    (current_project.owner || current_project.edit) &&
                    <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.properties}
                                      titleAccess={'Properties'}
                                      onClickHandler={showUpdateProjectModal}/>
                }
                <Icon title={'Show More'}
                      aria-label={'Show More'}
                      baseClassName={FONT_AWESOME_ICONS[FONT_AWESOME_ICONS_TYPE_MAP.moreVertDots].main.baseClassName} // main icon
                      className={FONT_AWESOME_ICONS[FONT_AWESOME_ICONS_TYPE_MAP.moreVertDots].className}
                />
            </Box>
        </Box>
    );
};


ProjectControls.propTypes = {
    current_project: PropTypes.object,
    tree: PropTypes.array.isRequired,
    showModal: PropTypes.func.isRequired,
    updateProject: PropTypes.func.isRequired,
    materials: PropTypes.array.isRequired,
    parameters: PropTypes.array.isRequired,
    scenarios: PropTypes.array.isRequired,
    planes: PropTypes.array.isRequired,
    downloadProjectStep: PropTypes.func.isRequired,
    clearDownloadProjectStep: PropTypes.func.isRequired,
    stepData: PropTypes.any,
    isDownloadingStepData: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    current_project: getData(state),
    tree: getTree(state),
    materials: getProjectMaterials(state),
    parameters: state.current_project.parameters.data,
    scenarios: state.current_project.scenarios.data,
    planes: state.current_project.planes.data,
    stepData: getProjectStepData(state),
    isDownloadingStepData: isDownloadingStepData(state),
});

const mapDispatchToProps = {showModal, updateProject, downloadProjectStep, clearDownloadProjectStep};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectControls);
