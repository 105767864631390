/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import { ConnectedRouter } from 'react-router-redux';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import ModalRoot from './modals/components/ModalRoot';
import NotFound from './components/NotFound';
import Authentication from './authentication/components/Authentication';
import Dashboard from './dashboard/components/Dashboard';
import Project from './project/Project';
import Materials from './materials/components/Materials';
import Activate from './user_setup/components/Activate';
import MyAccount from './account/components/account';
import Home from './home/components/NewHome3';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme.js';
import Tasks from './dashboard/components/Tasks';
import ForgotPassword from './authentication/components/ForgotPassword';
import ResetPassword from './authentication/components/ResetPassword';


export const App = ({ store, history }) => (
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <ConnectedRouter history={history}>
                <>
                    <Switch>
                        <Route exact path="/"><Home /></Route>
                        <Route path={'/dashboard'}><Dashboard /></Route>
                        <Route path={'/materials'}><Materials /></Route>
                        <Route path={'/tasks'}><Tasks /></Route>
                        <Route path={'/project/:project_id'} component={(props) => <Project {...props} />} />
                        <Route path={'/login'}><Authentication /></Route>
                        <Route path={'/user/activate/:token'} component={(props) => <Activate {...props} />} />
                        <Route path={'/account'}><MyAccount /></Route>
                        <Route path={'/forgot-password'}><ForgotPassword /></Route>
                        <Route path={'/reset-password/:token'} component={(props) => <ResetPassword {...props} />} />
                        <Route><NotFound /></Route>
                    </Switch>
                    <ModalRoot />
                </>
            </ConnectedRouter>
        </ThemeProvider>
    </Provider>
);

App.propTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};
