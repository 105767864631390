/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Link} from 'react-router-dom';
import {confirmAlert} from 'react-confirm-alert';
import PropTypes from 'prop-types';
import {Box, Card, CardMedia, CardContent, Typography, CardActions} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {
    DragIndicator as DragIndicatorIcon,
} from '@mui/icons-material';

import {showModal} from '../../modals/actions';
import {cloneProject, deleteProject, projectCardDragStart, updateProject} from '../actions';

import {getActiveFolder, getDraggedProject} from '../reducer';

import ProjectSettings from './ProjectSettings';
import ProjectPermissions from './ProjectPermissions';

import {projectUtils} from '../../project/utils';
import {IconsFontAwesome} from '../../components/IconsFontAwesome';
import {FONT_AWESOME_ICONS_TYPE_MAP} from '../../core/mappings';

function formatSince(interval, period) {
    const _interval = Math.floor(interval);
    if (_interval > 1) {
        return Math.abs(_interval) + ` ${period}s`;
    }
    return Math.abs(_interval) + ` ${period}`;
}

function timeSince(date) {
    let seconds = Math.floor((new Date() - new Date(date)) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) {
        return formatSince(interval, 'year');
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return formatSince(interval, 'month');
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return formatSince(interval, 'day');
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return formatSince(interval, 'hour');
    }
    interval = seconds / 60;
    if (interval > 1) {
        return formatSince(interval, 'minute');
    }
    return formatSince(seconds, 'second');
}

const styles = {
    root: {
        maxWidth: 225,
        margin: 10,
    },
    media: {
        height: 0,
        paddingTop: '66.25%', // 16:9
    },
    actions: {
        width: 192,
    },
};


const ProjectCard = ({
                         id, name, created, thumbnail, last_modified, deleteProject, cloneProject, classes, owner, edit,
                         view, showModal, world, updateProject, folder, projectCardDragStart, resetDragFolder,
                         draggedProject, activeFolder,
                     }) => {
    const isProjectCardDragging = draggedProject && draggedProject.id === id;
    const isSharedWithMeProjectCard = activeFolder.path === null;

    function showProjectSettingsModal(event) {
        projectUtils.stopPreventDefault(event);

        showModal({
            type: 'custom', title: 'Update Project', content: (props) => {
                return <ProjectSettings project={{id, name, thumbnail, last_modified, folder, world}}
                                        updateProject={updateProject}
                                        {...props}/>;
            },
        });
    }

    function showShareProjectModal(event) {
        projectUtils.stopPreventDefault(event);

        showModal({
            type: 'custom', title: 'Share Project', content: (props) => {
                return <ProjectPermissions project={{id, name, thumbnail, last_modified}}
                                           {...props}/>;
            },
        });
    }


    return (
        <Box sx={{p: 2, height: 300}}
             className={`${isProjectCardDragging ? 'dragged-card' : ''}`}
             style={{
                 opacity: isProjectCardDragging ? '0.2' : '1',
             }}>
            <Card id={'projectcard'}
                  className={classes.root}>
                {
                    !isSharedWithMeProjectCard
                        ? (
                            <div draggable
                                 className={'drag-handle'}
                                 onDragStart={() => {
                                     activeFolder.path !== null &&
                                     projectCardDragStart({
                                         project: {
                                             id, name, created, thumbnail, folder,
                                             last_modified, world, owner, edit, view,
                                         },
                                     });
                                 }}
                                 onMouseUp={() => {
                                     if (draggedProject !== null) {
                                         resetDragFolder();
                                     }
                                 }}>
                                <DragIndicatorIcon fontSize={'small'}/>
                            </div>
                        )
                        : null
                }
                <Link to={`/project/${id}`}>
                    <CardMedia className={classes.media} image={thumbnail}/>
                </Link>
                <CardContent>
                    <Link to={`/project/${id}`} style={{textDecoration: 'none'}}>
                        <Typography variant={'body2'}
                                    color={'textSecondary'}
                                    component={'p'}
                                    style={{
                                        maxWidth: '170px', overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                    }}>
                            {name}
                        </Typography>
                    </Link>
                    <Typography variant={'body2'} color={'textSecondary'} component={'p'}>
                        {timeSince(last_modified)} ago
                    </Typography>
                </CardContent>
                <CardActions disableSpacing className={classes.actions}>
                    {
                        (owner || edit) &&
                        <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.properties}
                                          titleAccess={`Show ${name} Properties`}
                                          secondClass={'smaller'}
                                          onClickHandler={showProjectSettingsModal}/>
                    }
                    {
                        owner &&
                        <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.share}
                                          titleAccess={`Share Project ${name}`}
                                          secondClass={'smaller'}
                                          onClickHandler={showShareProjectModal}/>
                    }
                    <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.clone}
                                      titleAccess={`Duplicate Project ${name}`}
                                      secondClass={'smaller'}
                                      onClickHandler={() => {
                                          cloneProject({id: id});
                                      }}/>
                    {
                        owner &&
                        <IconsFontAwesome iconType={FONT_AWESOME_ICONS_TYPE_MAP.delete}
                                          titleAccess={`Delete Project ${name}`}
                                          secondClass={'smaller'}
                                          onClickHandler={() => {
                                              confirmAlert({
                                                  title: 'Delete project?', message: 'Are you sure?', buttons: [{
                                                      label: 'Delete', onClick: () => {
                                                          deleteProject({id: id});
                                                      },
                                                  }, {
                                                      label: 'Cancel', onClick: () => {
                                                      },
                                                  }],
                                              });
                                          }}/>
                    }
                </CardActions>
            </Card>
        </Box>);
};

ProjectCard.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired,
    last_modified: PropTypes.string.isRequired,
    deleteProject: PropTypes.func.isRequired,
    cloneProject: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    owner: PropTypes.bool.isRequired,
    edit: PropTypes.bool.isRequired,
    view: PropTypes.bool.isRequired,
    showModal: PropTypes.func.isRequired,
    world: PropTypes.object.isRequired,
    updateProject: PropTypes.func.isRequired,
    folder: PropTypes.number,
    projectCardDragStart: PropTypes.func.isRequired,
    resetDragFolder: PropTypes.func.isRequired,
    draggedProject: PropTypes.object,
};

const mapStateToProps = state => ({
    draggedProject: getDraggedProject(state),
    activeFolder: getActiveFolder(state),
});

const mapDispatchToProps = {
    deleteProject,
    cloneProject,
    updateProject,
    showModal,
    projectCardDragStart,
};

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(ProjectCard);
