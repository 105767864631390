/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {requestController} from '../../../core/request.utils';

const activeRequestControllers = {};

export const UPDATE_PROJECT_VIA_ARRAY = 'UPDATE_PROJECT_VIA_ARRAY';
export const UPDATE_PROJECT_VIA_ARRAY_ERROR = 'UPDATE_PROJECT_VIA_ARRAY_ERROR';
export const UPDATE_PROJECT_VIA_ARRAY_SUCCESS = 'UPDATE_PROJECT_VIA_ARRAY_SUCCESS';

export const updateProjectViaArray = payload => ({
    type: UPDATE_PROJECT_VIA_ARRAY,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/via_array/${payload.box.id}/`,
            method: 'PUT',
            body: JSON.stringify(payload.box),
        },
    },
});


export const UPDATE_PROJECT_VIA_ARRAY_AND_TREE = 'UPDATE_PROJECT_VIA_ARRAY_AND_TREE';
export const UPDATE_PROJECT_VIA_ARRAY_AND_TREE_SUCCESS = 'UPDATE_PROJECT_VIA_ARRAY_AND_TREE_SUCCESS';
export const UPDATE_PROJECT_VIA_ARRAY_AND_TREE_ERROR = 'UPDATE_PROJECT_VIA_ARRAY_AND_TREE_ERROR';

export const updateProjectViaArrayAndTree = payload => {
    const {controller, currentRequest} = requestController(activeRequestControllers, payload.box.id);

    return {
        type: UPDATE_PROJECT_VIA_ARRAY_AND_TREE,
        payload,
        meta: {
            request: {
                url: `/api/project/${payload.project_id}/via_array/${payload.box.id}/`,
                method: 'PUT',
                body: JSON.stringify(payload.box),
                signal: controller.signal,
                currentRequest,
            },
        },
    };
};


export const CREATE_PROJECT_VIA_ARRAY = 'CREATE_PROJECT_VIA_ARRAY';
export const CREATE_PROJECT_VIA_ARRAY_ERROR = 'CREATE_PROJECT_VIA_ARRAY_ERROR';
export const CREATE_PROJECT_VIA_ARRAY_SUCCESS = 'CREATE_PROJECT_VIA_ARRAY_SUCCESS';

export const createProjectViaArray = payload => ({
    type: CREATE_PROJECT_VIA_ARRAY,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/via_array/`,
            method: 'POST',
            body: JSON.stringify(payload.box),
        },
    },
});


export const CLONE_PROJECT_VIA_ARRAY = 'CLONE_PROJECT_VIA_ARRAY';
export const CLONE_PROJECT_VIA_ARRAY_ERROR = 'CLONE_PROJECT_VIA_ARRAY_ERROR';
export const CLONE_PROJECT_VIA_ARRAY_SUCCESS = 'CLONE_PROJECT_VIA_ARRAY_SUCCESS';

export const cloneProjectViaArray = payload => ({
    type: CLONE_PROJECT_VIA_ARRAY,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/via_array/${payload.box.id}/clone/`,
            method: 'POST',
        },
    },
});


export const DELETE_PROJECT_VIA_ARRAY = 'DELETE_PROJECT_VIA_ARRAY';
export const DELETE_PROJECT_VIA_ARRAY_ERROR = 'DELETE_PROJECT_VIA_ARRAY_ERROR';
export const DELETE_PROJECT_VIA_ARRAY_SUCCESS = 'DELETE_PROJECT_VIA_ARRAY_SUCCESS';

export const deleteProjectViaArray = payload => ({
    type: DELETE_PROJECT_VIA_ARRAY,
    payload,
    meta: {
        request: {
            url: `/api/project/${payload.project_id}/via_array/${payload.box.id}/`,
            method: 'DELETE',
        },
    },
});