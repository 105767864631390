/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import './MiddlePanel.scss';


import {showModal} from '../../../../modals/actions';
import {loadProjectSolution} from '../../../+store/actions/actions';

import {getData as getPlanes} from '../../../+store/reducer/planes';
import {getAdjacency, getData, isLoadingAdjacency} from '../../../+store/reducer/project';
import {getData as getTree, isLoading as loadingTree} from '../../../+store/reducer/tree';
import {
    getData as getSolution,
    isLoading as loadingSolution,
} from '../../../+store/reducer/solution';
import {
    getData as getPlaneSolutions,
    isLoading as loadingPlaneSolutions,
} from '../../../+store/reducer/plane_solutions';

import Boxes from './components/scene/Boxes';
import SceneViewAndControls from './components/scene-view-control/SceneViewAndControls';
import {loadProjectAdjacency} from "../../../+store/actions/project";

const MiddlePanel = ({
                         tree, solution, current_project,
                         loadingSolution, loadingTree, loadingPlaneSolutions, loadingAdjacency,
                         plane_solutions, planes,
                         showModal
                     }) => {
    return (
        <>
            <SceneViewAndControls
                tree={tree}
                isLoading={loadingTree || loadingSolution || loadingPlaneSolutions || loadingAdjacency}
                current_project={current_project}
                solution={solution}
                plane_solutions={plane_solutions}/>
            <Boxes
                isLoading={loadingTree || loadingSolution || loadingPlaneSolutions || loadingAdjacency}
                current_project={current_project}
                solution={solution}
                plane_solutions={plane_solutions}
                planes={planes}
                tree={tree}
                showModal={showModal}
            />
        </>
    );
};


MiddlePanel.propTypes = {
    showModal: PropTypes.func.isRequired,
    tree: PropTypes.array.isRequired,
    loadingTree: PropTypes.bool.isRequired,
    loadingSolution: PropTypes.bool.isRequired,
    loadingPlaneSolutions: PropTypes.bool.isRequired,
    loadingAdjacency: PropTypes.bool.isRequired,
    current_project: PropTypes.object,
    adjacency: PropTypes.array,
    solution: PropTypes.object,
    plane_solutions: PropTypes.arrayOf(PropTypes.object),
    planes: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = state => ({
    tree: getTree(state),
    loadingTree: loadingTree(state),
    loadingSolution: loadingSolution(state),
    loadingPlaneSolutions: loadingPlaneSolutions(state),
    loadingAdjacency: isLoadingAdjacency(state),
    current_project: getData(state),
    adjacency: getAdjacency(state),
    solution: getSolution(state),
    plane_solutions: getPlaneSolutions(state),
    planes: getPlanes(state),
});

const mapDispatchToProps = {showModal, loadProjectSolution};

export default connect(mapStateToProps, mapDispatchToProps)(MiddlePanel);
