/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import React from 'react';
import BasicTabs from '../../../../components/BasicTabs';
import PropTypes from 'prop-types';
import { getData as getProject, getProjectMaterials } from '../../../+store/reducer/project';
import { getValidationErrors } from '../../../+store/reducer/validation_errors';
import { getData as getTree, getSelectedObject } from '../../../+store/reducer/tree';
import { setSelectedObject, updateSelectedObject } from '../../../+store/actions/actions';
import { connect } from 'react-redux';
import { withSettingsTemplate } from './SettingsTemplate';
import {
    createProjectSource,
    deleteProjectSource,
    updateProjectSource,
    updateProjectSourceAndTree
} from '../../../+store/actions/source';
import {
    FormControl,
    FormGroup,
    FormHelperText,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Tooltip
} from '@mui/material';
import { InputFields, SettingsSubtitle, SettingsTabPlane } from '../../../../components/BasicSettings';
import ColorPicker from '../../../../components/ColorPicker';
import HeatTransferCoeff from '../../../../components/HeatTranserCoeff';
import { SwitchToggle } from '../../../../components/SwitchToggle';
import { InfoOutlined } from '@mui/icons-material';
import { message_floating_point, projectUtils } from '../../../utils';

const SourceSettingsTemplate = ({
                                    // passed from SettingsTemplate
                                    boxHasChange,
                                    handleRevert,
                                    handleCancel,
                                    handleChange,
                                    box,
                                    state,
                                    // specially for source
                                    form,
                                    createProjectObject,
                                    updateProjectObjectAndTree,
                                    project_id,
                                    // directly from store
                                    validation_errors,
                                }) => {
    function handleSubmit(e) {
        e.preventDefault();
        form.current.isFormValid(false)
            .then(is_valid => {
                if (is_valid) {
                    if (box && (box.id !== 'creating')) {
                        if (!boxHasChange()) {
                            return;
                        }
                        updateProjectObjectAndTree({
                            project_id,
                            box: {
                                ...box,
                                ...state,
                                dx: state.plane !== 'YZ' && state.dx || 0,
                                dy: state.plane !== 'XZ' && state.dy || 0,
                                dz: state.plane !== 'XY' && state.dz || 0,
                            },
                        });
                    } else {
                        createProjectObject({
                            project_id,
                            box: {
                                ...state,
                                dx: state.plane !== 'YZ' && state.dx || 0,
                                dy: state.plane !== 'XZ' && state.dy || 0,
                                dz: state.plane !== 'XY' && state.dz || 0,
                            },
                        });
                    }
                }
            });
    }

    const dimensions = projectUtils.sourceDimensions({ state });

    const tabs = [
        {
            label: 'Spatial',
            Component: <FormGroup style={{ height: '100%' }}>
                <SettingsTabPlane
                    hasChange={boxHasChange()}
                    onRevert={box.id !== 'creating' ? handleRevert : handleCancel}
                    onSave={handleSubmit}
                    object={box}>
                    <InputFields
                        field_names={['name']}
                        state={state}
                        ids={'sourcename'}
                        validation_errors={validation_errors}
                        handleChange={handleChange}/>
                    <InputFields
                        groupTitle={'Location'}
                        field_names={['x', 'y', 'z']}
                        units={'mm'}
                        state={state}
                        ids={'sourcelocationid'}
                        validation_errors={validation_errors}
                        handleChange={handleChange}/>
                    <InputFields
                        inline
                        groupTitle={'Dimension'}
                        field_names={dimensions}
                        units={'mm'}
                        state={state}
                        ids={'sourcedimensionid'}
                        validation_errors={validation_errors}
                        handleChange={handleChange}/>
                    <FormControl variant="standard" sx={{ width: '32.5%' }}>
                        <InputLabel error={!!validation_errors['plane']}
                                    id={'planelabel'}>
                            Plane
                        </InputLabel>
                        <Select
                            id="planeid"
                            labelId="planelabel"
                            value={state.plane || 'XY'}
                            onChange={(event) => handleChange('plane', event.target.value)}
                            label={'Plane'}
                        >
                            <MenuItem key={'XY'} value={'XY'}>XY</MenuItem>
                            <MenuItem key={'XZ'} value={'XZ'}>XZ</MenuItem>
                            <MenuItem key={'YZ'} value={'YZ'}>YZ</MenuItem>
                        </Select>
                        {validation_errors['plane'] &&
                            <FormHelperText>{validation_errors['plane']}</FormHelperText>
                        }
                    </FormControl>

                    <SettingsSubtitle title={'Source Properties'}/>
                    <ColorPicker
                        label={'Color'}
                        id={'color'}
                        variant={'standard'}
                        value={state['color'] || ''}
                        onChange={color => handleChange('color', color)}
                        TextFieldProps={{ value: state['color'] }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            sx: { width: '80%' },
                            startAdornment:
                                <InputAdornment position={'start'}>
                                    <div style={{
                                        background: state['color'],
                                        border: '1px solid black',
                                        width: '24px',
                                        height: '24px',
                                        marginRight: '5px',
                                    }}>

                                    </div>
                                </InputAdornment>,
                            endAdornment:
                                <InputAdornment position={'end'}>
                                    HEX
                                </InputAdornment>
                        }}
                    />
                </SettingsTabPlane>
            </FormGroup>
        },
        {
            label: 'Thermal',
            Component: <FormGroup style={{ height: '100%' }}>
                <SettingsTabPlane hasChange={boxHasChange()}
                                  onRevert={box.id !== 'creating' ? handleRevert : handleCancel}
                                  onSave={handleSubmit}
                                  object={box}>
                    <InputFields
                        inline
                        field_names={['power', 'heat_flux']}
                        units={{ power: 'mW', heat_flux: 'W/m²' }}
                        disabled={['heat_flux']}
                        state={{
                            power: state.power,
                            heat_flux:
                                (isFinite(state.power)
                                    && isFinite(state.dx)
                                    && isFinite(state.dy))
                                && (+state.power / 1e3 / (+state.dx * +state.dy / 1e6)).toFixed(2)
                        }}
                        ids={'sourcepowerid'}
                        validation_errors={validation_errors}
                        handleChange={handleChange}/>
                    <HeatTransferCoeff
                        fullWidth
                        variant="standard"
                        validators={[]}
                        value={state.hc || ''}
                        onChange={(event) => handleChange('hc', event.target.value)}
                        error={!!validation_errors['hc']}
                        helperText={validation_errors['hc']}
                        errorMessages={[message_floating_point]}
                    />
                    <FormControl>
                        <SwitchToggle
                            id={'bindcheckbox'}
                            booleans={true}
                            onChange={() => handleChange('bound', !state.bound)}
                            checked={state.bound}
                            title={'Bind to ambient'}
                            orientation={'vertical'}
                        />
                    </FormControl>
                    <Tooltip title={
                        <span>
                            Forces all exposed object walls to ambient temperature.
                            </span>
                    }>
                        <InfoOutlined fontSize={'inherit'}/>
                    </Tooltip>
                </SettingsTabPlane>
            </FormGroup>
        }
    ];

    return <BasicTabs tabs={tabs} title={'Properties'} isSwitch={true}/>;
};

SourceSettingsTemplate.propTypes = {
    boxHasChange: PropTypes.func.isRequired,
    handleRevert: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    form: PropTypes.object,
    createProjectObject: PropTypes.func.isRequired,
    updateProjectObjectAndTree: PropTypes.func.isRequired,
    box: PropTypes.object,
    state: PropTypes.object.isRequired,
    validation_errors: PropTypes.object,
    materials: PropTypes.array
};

const mapStateToProps = state => ({
    materials: getProjectMaterials(state) || [],
    validation_errors: getValidationErrors(state),
    project_id: getProject(state).id,
    tree: getTree(state),
    box: getSelectedObject(state),
});

const mapDispatchToProps = {
    createProjectObject: createProjectSource,
    updateProjectObject: updateProjectSource,
    updateProjectObjectAndTree: updateProjectSourceAndTree,
    deleteProjectObject: deleteProjectSource,
    setSelectedObject,
    updateSelectedObject
};

export default connect(mapStateToProps, mapDispatchToProps)(withSettingsTemplate(SourceSettingsTemplate));
