/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {
    CLEAR_PROJECT_SOLUTION,
    LOAD_PROJECT_CASE_SOLUTION,
    LOAD_PROJECT_CASE_SOLUTION_ERROR,
    LOAD_PROJECT_CASE_SOLUTION_SUCCESS,
    LOAD_PROJECT_OBJECT_TRANSIENT,
    LOAD_PROJECT_OBJECT_TRANSIENT_ERROR,
    LOAD_PROJECT_OBJECT_TRANSIENT_SUCCESS,
    LOAD_PROJECT_SOLUTION,
    LOAD_PROJECT_SOLUTION_ERROR,
    LOAD_PROJECT_SOLUTION_SUCCESS,
    LOAD_PROJECT_TASK,
    LOAD_PROJECT_TASK_ERROR,
    LOAD_PROJECT_TASK_SUCCESS,
    LOAD_PROJECT_TRANSIENT,
    LOAD_PROJECT_TRANSIENT_ERROR,
    LOAD_PROJECT_TRANSIENT_SOLUTION,
    LOAD_PROJECT_TRANSIENT_SOLUTION_ERROR,
    LOAD_PROJECT_TRANSIENT_SOLUTION_SUCCESS,
    LOAD_PROJECT_TRANSIENT_SUCCESS,
    SOLVE_PROJECT,
    SOLVE_PROJECT_ERROR,
    SOLVE_PROJECT_SUCCESS,
    STOP_TASK_SUCCESS,
    TASK_STATUS_SUCCESS,
} from '../actions/actions';
import { projectUtils } from '../../utils';
import { LOAD_PROJECT_ERROR } from "../actions/project";

export const defaultState = {
    data: null,
    transient: [],
    loading: false,
    task_id: null,
    task_status: null,
    task_status_time: 0,
};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case LOAD_PROJECT_SOLUTION:
        case LOAD_PROJECT_CASE_SOLUTION:
        case LOAD_PROJECT_TRANSIENT_SOLUTION:
            return {
                ...state,
                loading: true,
            };
        case LOAD_PROJECT_SOLUTION_ERROR:
        case LOAD_PROJECT_CASE_SOLUTION_ERROR:
        case LOAD_PROJECT_TRANSIENT_SOLUTION_ERROR:
            return {
                ...state,
                data: {},
                loading: false,
            };
        case LOAD_PROJECT_SOLUTION_SUCCESS:
        case LOAD_PROJECT_CASE_SOLUTION_SUCCESS:
        case LOAD_PROJECT_TRANSIENT_SOLUTION_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    timestamp: action.payload.timestamp,
                    result: JSON.parse(action.payload.result.replace(/nan/g, 'null')),
                },
                loading: false,
            };
        case CLEAR_PROJECT_SOLUTION:
            return {
                ...defaultState,
                task_status_time: Date.now(),
            };
        case SOLVE_PROJECT:
            return {
                ...state,
            };
        case SOLVE_PROJECT_ERROR:
            return {
                ...state,
                task_id: null,
                task_status: null,
            };
        case SOLVE_PROJECT_SUCCESS:
            return {
                ...state,
                task_id: action.payload.task,
                task_status: action.payload.state,
                task_status_time: Date.now(),
            };
        case LOAD_PROJECT_TASK:
            return {
                ...state,
                task_state_time: 0,
            }
        case LOAD_PROJECT_TASK_ERROR:
            return {
                ...state,
                task_state_time: Date.now(),
            }
        case LOAD_PROJECT_TASK_SUCCESS:
            return {
                ...state,
                task_id: action.payload.task,
                task_status: action.payload.state,
                task_status_time: Date.now(),
            };
        case TASK_STATUS_SUCCESS:
            projectUtils.updateStorageTaskStatus({ taskId: state.task_id, status: action.payload.state })

            return {
                ...state,
                task_status_time: Date.now(),
                task_status: action.payload.state,
            }
        case STOP_TASK_SUCCESS:
            return {
                ...state,
                task_id: null,
                task_status: null,
                task_status_time: Date.now(),
            }
        // case LOAD_PROJECT_TRANSIENT:
        // case LOAD_PROJECT_OBJECT_TRANSIENT:
        case LOAD_PROJECT_TRANSIENT_ERROR:
        case LOAD_PROJECT_OBJECT_TRANSIENT_ERROR:
        case LOAD_PROJECT_ERROR:
            return {
                ...state,
                transient: [],
            }
        case LOAD_PROJECT_TRANSIENT_SUCCESS:
        case LOAD_PROJECT_OBJECT_TRANSIENT_SUCCESS:
            return {
                ...state,
                transient: action.payload,
            }
        default:
            return state;
    }
};

export const hasData = ({ current_project }) => !!current_project.solution.data;

export const getData = ({ current_project }) => current_project.solution.data;

export const getTransient = ({ current_project }) => current_project.solution.transient;

export const isLoading = ({ current_project }) => current_project.solution.loading;

export const getTaskId = ({ current_project }) => current_project.solution.task_id;

export const getTaskStatus = ({ current_project }) => current_project.solution.task_status;

export const getTaskStatusTime = ({ current_project }) => current_project.solution.task_status_time;
